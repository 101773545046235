import { alpha } from '@mui/material';
import theme from 'src/theme';

const styles = {
  paper: {
    marginLeft: '-2px',
  },
  menuPadding: {
    padding: '0px',
  },
  menuActionContainer: {
    fontSize: 12,
    color: theme.palette.text.primary,
    border: '0px solid #E1E4E8',
    borderTopWidth: 1
  },
  menuActionContainerTop: {
    fontSize: 12,
    color: theme.palette.text.primary
  },
  titleContainer: {
    backgroundColor: '#ffffff',
    padding: '12px 30px',
    boxShadow: '0px 1px 0px rgba(51, 77, 110, 0.1)',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08))'
  },
  backBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    color: theme.palette.button.action,
    cursor: 'pointer',
    paddingLeft: 0,
    marginBottom: 16,
    textTransform: 'none'
  },
  icon: {
    marginRight: 12
  },
  assistantIDBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  assistantID: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.palette.text.header,
  },
  duplicate: {
    backgroundColor: alpha(theme.palette.statusbg.tovalidate, 0.3),
    color: theme.palette.statusbg.tovalidate,
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 8px 2px',
    position: 'relative',
    fontWeight: 600
  },
  errorReason: {
    backgroundColor: alpha(theme.palette.statusbg.errored, 0.3),
    color: theme.palette.statusbg.errored,
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 6px 2px',
    position: 'relative',
    fontWeight: 600,
    cursor: 'pointer'
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '36px',
    color: theme.palette.text.primary,
  },
  exportFailed: {
    height: '50%',
    backgroundColor: theme.palette.statusbg.errored,
    color: '#fff',
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 6px 2px',
    position: 'relative',
    fontWeight: 600,
  },
  exportFailedReasonContainer: {
    width: '100%',
    backgroundColor: alpha(theme.palette.statusbg.errored, 0.3),
    marginBottom: 10,
    padding: 6,
    borderRadius: 2,
  },
  exportFailedReason: {
    color: theme.palette.statusbg.errored,
    fontSize: 11,
    fontWeight: 500,
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  subtitle: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.palette.text.header,
    paddingBottom: 6
  },
  userChip: {
    height: '19px',
    marginLeft: '6px'
  },
  chipAvatar: {
    height: '19px!important',
    width: '19px!important',
    marginLeft: '0px!important'
  },
  chipLabel: {
    fontSize: '11px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    lineHeight: '16px',
    paddingBottom: '1px'
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    width: 108,
    height: 42,
  },
  ublButton: {
    height: 32,
    width: 150,
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal'
  },
  groupBtn: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    width: '100%',
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    borderTopWidth: 1,
    border: '0px solid #E1E4E8',
  },
  subtitleOwnedInfo: {
    display: 'flex',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.palette.text.header,
    paddingBottom: 6
  },
  subtitleOwned: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.palette.text.header,
    paddingBottom: 6,
  },
  subtitleChip: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.header
  },
  moreRightIcon: {
    cursor: 'pointer',
    color: '#334d6f',
    height: 16,
    width: 16,
    marginBottom: -4
  },
  uploading: {
    color: theme.palette.status.digitized,
    backgroundColor: alpha(theme.palette.statusbg.digitized, 0.3)
  },
  digitized: {
    color: theme.palette.status.digitized,
    backgroundColor: alpha(theme.palette.statusbg.digitized, 0.3)
  },
  toreview: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  reviewed: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  tovalidate: {
    color: theme.palette.status.tovalidate,
    backgroundColor: alpha(theme.palette.statusbg.tovalidate, 0.3)
  },
  toexport: {
    color: theme.palette.status.toexport,
    backgroundColor: alpha(theme.palette.statusbg.toexport, 0.3)
  },
  exported: {
    color: theme.palette.status.exported,
    backgroundColor: alpha(theme.palette.statusbg.exported, 0.3)
  },
  rejected: {
    color: theme.palette.status.rejected,
    backgroundColor: alpha(theme.palette.statusbg.rejected, 0.3)
  },
  archived: {
    color: theme.palette.status.archived,
    backgroundColor: alpha(theme.palette.statusbg.archived, 0.3)
  },
  outoflimit: {
    color: theme.palette.status.outoflimit,
    backgroundColor: alpha(theme.palette.statusbg.outoflimit, 0.3)
  },
  errored: {
    color: theme.palette.status.errored,
    backgroundColor: alpha(theme.palette.statusbg.errored, 0.3)
  },
};

export default styles;
