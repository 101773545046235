import React from 'react';

import {
  Box, Button, Dialog, Grid, IconButton, Typography
} from '@mui/material';
import {
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import styles from './style';

interface PropTypes {
  open: boolean;
  title: string;
  subtitle?: string;
  cancelText: string;
  submitText: string;
  children: React.ReactNode;
  showSubmitIcon?: boolean;
  width?: string;
  handleClose: () => void;
  onSubmit: () => void;
  onCancel?: () => void;
  footer?: React.ReactNode;
}

const CommonModal = ({
  open,
  title,
  subtitle,
  cancelText,
  submitText,
  children,
  showSubmitIcon,
  width,
  handleClose,
  onSubmit,
  onCancel,
  footer,
}: PropTypes) => {

  return (
    <Dialog
      open={open}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Grid sx={{ width: width ? width : '600px' }}>
        <Box sx={styles.modalHeader}>
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.headerTitle}>
              {title}
            </Typography>
            <IconButton sx={styles.closeBtn} onClick={handleClose}>
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </Box>
          <Typography sx={styles.headerSubTitle}>{subtitle}</Typography>
        </Box>
        <Grid sx={styles.modalContent}>
          {children}
        </Grid>
        {footer ? (footer) : (
          <Grid sx={styles.modalFooter}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                sx={styles.cancelBtn}
                onClick={onCancel ? onCancel : handleClose}
              >
                {cancelText}
              </Button>
              <Button
                variant="contained"
                sx={styles.submitBtn}
                onClick={onSubmit}
                endIcon={showSubmitIcon ? <ArrowForwardIcon sx={styles.submitIcon} /> : null}
              >
                {submitText}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default CommonModal;
