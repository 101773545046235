import { useTranslation } from 'react-i18next';

import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import styles from './style';

const BackToProcysConfirmation = ({
  openConfirmation,
  onClose,
  handleStatusChange
}) => {
  const { t, ready: tReady } = useTranslation();

  return (
    <Modal
      style={styles.modal}
      open={openConfirmation}
      onClose={onClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={openConfirmation}>
        <div style={styles.paperModal}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {tReady && t('BRAND_BACK_TO_PROCYS_CONF_TITLE')}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid style={styles.deleteMessageText}>
            <Typography variant="caption">
              {tReady && t('BRAND_BACK_TO_PROCYS_CONF_MESSAGE')}
            </Typography>
          </Grid>
          <Grid style={styles.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                style={styles.closeBtn}
                onClick={onClose}
              >
                {tReady && t('BRAND_BACK_TO_PROCYS_CONF_CANCEL')}
              </Button>
              <Button
                variant="contained"
                style={styles.submitBtn}
                onClick={() => handleStatusChange('cancelled')}
              >
                {tReady && t('BRAND_BACK_TO_PROCYS_CONFIRM')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

// BackToProcysConfirmation.propTypes = {
//   openConfirmation: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
//   handleStatusChange: PropTypes.func.isRequired
// };

export default BackToProcysConfirmation;
