interface PropTypes {
  width: number
}

function VatESDetailsTopLineComponent(props: PropTypes) {
  const  { width, ...rest } = props;
  return (
    <svg
      width={width}
      height={10}
      viewBox="0 0 359 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M.5 9H180l10-8 10 8h158.5"
        stroke="#D8D8DD"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default VatESDetailsTopLineComponent
