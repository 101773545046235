import {
  ClickAwayListener, Grid, Grow, MenuList, Paper, Popper, Typography
} from '@mui/material';
import {
  AttachFile as AttachmentIcon, FiberManualRecord as DotIcon
} from '@mui/icons-material';

import styles from './style';

const AttachedDocuments = (props) => {
  const {
    t,
    handleSplitBtnClick,
    splitButtonRef,
    setMouseLeftSplitScrnBtn,
    handleMouseLeaveSplitScrnBtn,
    invoice,
    attachmentsOpen,
    setMouseLeftAttachmentsTooltip,
    handleMouseLeaveAttachmentsTooltip,
    handleAttachmentsClose,
    handleAttachmentClick
  } = props;
  return (
    <>
      <Grid
        variant="outlined"
        style={styles.splitScreenBtn}
        onClick={handleSplitBtnClick}
        ref={splitButtonRef}
        onMouseEnter={() => setMouseLeftSplitScrnBtn(false)}
        onMouseLeave={handleMouseLeaveSplitScrnBtn}
      >
        <AttachmentIcon style={styles.splitScreenIcon} />
      </Grid>
      <Grid style={styles.circle} anchorEl={splitButtonRef.current}>
        <Typography style={styles.count}>{invoice.attachedDocuments.length}</Typography>
      </Grid>
      {attachmentsOpen && (
        <Popper
          open={attachmentsOpen}
          anchorEl={splitButtonRef.current}
          role={undefined}
          transition
          style={{ zIndex: 111111, width: '90%', maxWidth: 200, }}
          placement="top-start"
          onMouseEnter={() => setMouseLeftAttachmentsTooltip(false)}
          onMouseLeave={handleMouseLeaveAttachmentsTooltip}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'top' ? 'right top' : 'right bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleAttachmentsClose}>
                  <MenuList id="split-button-menu" autoFocusItem style={styles.attachmentsContainer}>
                    <Typography style={styles.attachmentTitle}>
                      {t('INVOICE_EDIT_FORM_LINKED_DOCUMENTS')}
                    </Typography>
                    {invoice.attachedDocuments.map((attachment) => (
                      <Grid style={styles.itemContainer}>
                        <DotIcon style={styles.dotIcon} />
                        <Typography
                          key={attachment.name}
                          noWrap
                          style={styles.attachmentLabel}
                          onClick={() => handleAttachmentClick(attachment)}
                        >
                          {attachment.label}
                        </Typography>
                      </Grid>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default AttachedDocuments;
