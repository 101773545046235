import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Grid, Menu, TextField, Typography
} from '@mui/material';

import styles from './style';

const CustomPeriodFilter = (props) => {
  const { ready, t } = useTranslation();

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (`0${currentDate.getMonth() + 1}`).slice(-2);
  const day = (`0${currentDate.getDate()}`).slice(-2);
  const hours = (`0${currentDate.getHours()}`).slice(-2);
  const minutes = (`0${currentDate.getMinutes()}`).slice(-2);

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  let timeZone = new Date().toString().split(' ')[5];
  timeZone = `${timeZone.slice(0, timeZone.length - 2)}:${timeZone.slice(timeZone.length - 2)}`;

  const { anchorElCustomPeriod, handleCustomPeriodClose, handleCustomPeriodApply } = props;
  const [from, setFrom] = useState(formattedDateTime);
  const [to, setTo] = useState(formattedDateTime);

  return (
    <Menu
      open={Boolean(anchorElCustomPeriod)}
      anchorEl={anchorElCustomPeriod}
      onClose={handleCustomPeriodClose}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Grid style={styles.itemRow}>
        <TextField
          id="datetime-local"
          label={t('DASHBOARD_INVOICES_FROM')}
          type="datetime-local"
          value={from}
          variant="standard"
          style={styles.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setFrom(e.target.value)}
        />
      </Grid>
      <Grid style={styles.itemRow}>
        <TextField
          id="datetime-local"
          label={t('DASHBOARD_INVOICES_TO')}
          type="datetime-local"
          value={to}
          variant="standard"
          style={styles.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setTo(e.target.value)}
        />
      </Grid>
      <Grid style={styles.itemRow}>
        <Typography style={styles.text}>{timeZone}</Typography>
        <Button
          variant="outlined"
          style={styles.cancelButtonSup}
          type="submit"
          onClick={handleCustomPeriodClose}
        >
          {ready && t('DASHBOARD_INVOICES_CANCEL')}
        </Button>
        <Button
          style={styles.applyButtonSup}
          variant="contained"
          onClick={() => handleCustomPeriodApply(from, to)}
        >
          {ready && t('DASHBOARD_INVOICES_APPLY')}
        </Button>
      </Grid>
    </Menu>
  );
};

// CustomPeriodFilter.propTypes = {
//   anchorElCustomPeriod: PropTypes.object,
//   handleCustomPeriodClose: PropTypes.func,
//   handleCustomPeriodApply: PropTypes.func
// };

export default CustomPeriodFilter;
