import theme from "src/theme";

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 30
  },
  topContainer: {
    paddingLeft: 40,
    paddingRight: 40
  }
};

export default styles;
