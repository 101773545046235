import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

import {
  Button, Grid, TextField, Typography
} from '@mui/material';

import { axiosHeaders } from 'src/utils/helpers';
import styles from './style';

function ConnectionSettings(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { ready, t } = useTranslation();

  const { brandDetails } = props;

  const [domains, setDomains] = useState([]);

  useEffect(() => {
    if (!brandDetails.domain) {
      return;
    }
    setDomains([
      {
        id: 1,
        domainPrefix: `https://auth.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 2,
        domainPrefix: `https://login.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 3,
        domainPrefix: `https://app.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 4,
        domainPrefix: `https://provider.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 5,
        domainPrefix: `https://app-api.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 6,
        domainPrefix: `https://docs.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
    ]);
  }, [brandDetails]);

  const handleCheckConnection = async () => {
    let connSuccess = false;
    for (let i = 0; i < 2; i++) {
      const domain = domains[i];
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await Axios.get(
          domain.domainPrefix,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );

        if (response.status === 200) {
          connSuccess = true;
          enqueueSnackbar(t('BRAND_DOMAIN_CONNECTION_SUCCESS'), {
            variant: 'success'
          });
          break;
        }
      } catch (error) {
        //
      }
    }
    if (!connSuccess) {
      enqueueSnackbar(t('BRAND_DOMAIN_CONNECTION_FAILURE'), {
        variant: 'error'
      });
    }
  };

  return (
    (brandDetails.status === 'active') && (
      <Grid style={styles.container}>
        <Typography style={styles.titleText}>{ready && t('BRAND_CONNECTION_SETTINGS_TITLE')}</Typography>
        <Typography style={styles.description}>{ready && t('BRAND_CONNECTION_SETTINGS_DESCR')}</Typography>
        <Grid style={styles.domainContainer}>
          <Grid container style={styles.domainRow}>
            <Grid item xs={12} sm={5} style={styles.domainItem}>
              <Typography variant="h5" style={styles.columnLabel}>
                {ready && t('BRAND_CONNECTION_SETTINGS_DOMAIN_PREFIX')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3} style={styles.domainItem}>
              <Typography variant="h5" style={styles.columnLabel}>
                {ready && t('BRAND_CONNECTION_SETTINGS_RECORD_TYPE')}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4} style={styles.domainItem}>
              <Typography variant="h5" style={styles.columnLabel}>
                {ready && t('BRAND_CONNECTION_SETTINGS_DESTINATION_IP')}
              </Typography>
            </Grid>
          </Grid>
          {domains.map((domain) => (
            <Grid container style={styles.domainRow} key={domain.id}>
              <Grid item xs={12} sm={5} style={styles.domainItem}>
                <TextField
                  fullWidth
                  error={false}
                  helperText=""
                  name="domainPrefix"
                  value={domain.domainPrefix}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} sm={3} style={styles.domainItem}>
                <TextField
                  fullWidth
                  error={false}
                  helperText=""
                  name="recordType"
                  value={domain.recordType}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={8} sm={4} style={styles.domainItem}>
                <TextField
                  fullWidth
                  error={false}
                  helperText=""
                  name="destinationIP"
                  value={domain.destinationIP}
                  disabled
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid style={styles.footer}>
          <Button
            variant="outlined"
            style={styles.checkConnectionButton}
            disabled={!brandDetails.domain}
            onClick={handleCheckConnection}
          >
            {ready && t('BRAND_CONNECTION_SETTINGS_CHECK_CONNECTION')}
          </Button>
        </Grid>
      </Grid>
    )
  );
}

// ConnectionSettings.propTypes = {
//   brandDetails: PropTypes.object
// };

export default ConnectionSettings;
