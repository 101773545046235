import theme from "src/theme";

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px 12px',
    margin: '0px -8px',
    backgroundColor: '#ffffff'
  },
  saveButton: {
    width: 108,
    height: 42,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
  },
  sendToValidateButton: {
    width: 155,
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
    backgroundColor: theme.palette.button.action,
  },
  validateButton: {
    width: 108,
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
    backgroundColor: theme.palette.button.action,
  },
  ublButton: {
    width: 155,
    height: 42,
    marginLeft: 10,
    textTransform: 'none',
    backgroundColor: theme.palette.button.action,
    borderWidth: '0px !important'
  },
  groupBtn: {
    color: '#334D6E',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
  },
};

export default styles;
