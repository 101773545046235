import theme from 'src/theme';
const styles = {
  root:{
    backgroundColor: theme.palette.background.default,
    display:'flex',
    flexDirection:'column',
  },
  head:{
    display:'flex',
    alignItems:'center',
    padding:'7px 20px',
    borderBlock:'1px solid #4C4E641F'
  },
  headTitle:{
    fontSize:'14px',
    color:'#4C4E64',
    fontWeight:'500'
  },
  optionsWrapper:{
    marginLeft:'auto',
    display:'flex',
    alignItem:'center',
  },
  option:{
    display:'flex',
    alignItems:'center',
  },
  chartWrapper:{
    width:'100%',
    display:'flex',
    padding:'20px 20px 0',
    '& svg':{
      position:'relative',
      left:'-10px',
      transform:'scale(1.05)',
      bottom:'-10px',
    }
  }
}

export default styles;