import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Grid, Typography, Card, Button
} from '@mui/material';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import BrandCompanyInfo from 'src/account/components/BrandCompanyInfo/BrandCompanyInfo';
import ConnectionSettings from 'src/account/components/ConnectionSettings/ConnectionSettings';
import BackToProcysConfirmation from 'src/account/components/BackToProcysConfirmation/BackToProcysConfirmation';
import styles from './style';

function BrandSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const { ready, t } = useTranslation();
  
  const { API } = useContext(ConfigContext);

  const [isEditable, setIsEditable] = useState(false);
  const [brandDetails, setBrandDetails] = useState({
    commercialName: '',
    domain: '',
    supportEmail: '',
  });
  const [openBackToProcysConfirmation, setOpenBackToProcysConfirmation] = useState(false);

  const fetchBrandDetails = async () => {
    try {
      const response = await Axios.get(
        API.brand,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (response.data.success) {
        if (response.data.data) {
          const { status } = response.data.data;
          setIsEditable(status !== 'active');
          setBrandDetails(response.data.data);
        } else {
          setIsEditable(true);
        }
      }
    } catch (error) {
      setIsEditable(false);
      setBrandDetails({
        commercialName: '',
        domain: '',
        supportEmail: '',
      });
    }
  };

  useEffect(() => {
    fetchBrandDetails();
  }, []);

  const closeBackToProcysConfirmation = () => {
    setOpenBackToProcysConfirmation(false);
  };

  const handleStatusChange = async (status) => {
    try {
      const response = await Axios.post(
        API.brandStatus,
        {
          status,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (response.data.success) {
        enqueueSnackbar(t('BRANDING_INITIATION_SUCCESS'), {
          variant: 'success'
        });
        fetchBrandDetails();
        closeBackToProcysConfirmation();
      }
    } catch (error) {
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'BRANDING_INITIATION_FAILURE', t),
        t
      ), {
        variant: 'error'
      });
    }
  };

  return (
    <Grid
      style={styles.root}
      container
      spacing={4}
    >
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        style={styles.cardContainer}
      >
        <Card style={styles.card}>
          <Typography style={styles.headerTitle}>
            {ready && t('ACCOUNT_SETTINGS_BRAND_TITLE')}
          </Typography>
          {/* <Typography style={styles.descriptionText}>
            {ready && (
              <Trans i18nKey="ACCOUNT_SETTINGS_BRAND_INSTRUCTION">
                <Link style={styles.descriptionLink} href={BRAND_URL} rel="noreferrer" target="_blank">Here you can read the instructions</Link>
                on how to setup your own brand for Procys app.
              </Trans>
            )}
          </Typography> */}
          <BrandCompanyInfo isEditable={isEditable} brandDetails={brandDetails} fetchBrandDetails={fetchBrandDetails} />
          <ConnectionSettings brandDetails={brandDetails} />
          <Grid style={styles.footer}>
            {brandDetails.status === 'active' && (
              <Button
                variant="outlined"
                style={styles.backToProcysBtn}
                onClick={() => setOpenBackToProcysConfirmation(true)}
              >
                {ready && t('BRAND_SETTINGS_BACK_TO_PROCYS')}
              </Button>
            )}
            {(brandDetails.status === 'active') && (
              <Button
                variant="outlined"
                style={styles.backToProcysBtn}
                onClick={() => handleStatusChange('cancelled')}
              >
                {ready && t('BRAND_SETTINGS_EDIT')}
              </Button>
            )}
            {!brandDetails.status && (
              <Button
                variant="contained"
                style={styles.saveBtn}
                disabled={!isEditable}
                onClick={() => handleStatusChange('active')}
              >
                {ready && t('BRAND_SETTINGS_BRAND_MY_APP')}
              </Button>
            )}
            {brandDetails.status !== 'active' && (
              <Button
                variant="contained"
                style={styles.saveBtn}
                disabled={!isEditable}
                onClick={() => handleStatusChange('active')}
              >
                {ready && t('BRAND_SETTINGS_SAVE')}
              </Button>
            )}
          </Grid>
        </Card>
      </Grid>
      <BackToProcysConfirmation
        openConfirmation={openBackToProcysConfirmation}
        onClose={closeBackToProcysConfirmation}
        handleStatusChange={handleStatusChange}
      />
    </Grid>
  );
}

export default BrandSettings;
