import heic2any from 'heic2any';
import { ResponseType } from 'axios';

import { Box } from '@mui/material';
import {
  Check as TrustedIcon,
  ErrorOutline as NotSureIcon,
  WarningAmber as ErrorIcon,
} from '@mui/icons-material';

import AnnotatedIcon from 'src/document-edit/icons/AnnotatedIcon';
import { invoiceStatusesKeys } from 'src/config';

export const statusFilters = [
  {
    id: invoiceStatusesKeys.all,
    label: 'DASHBOARD_INVOICES_ALL',
  },
  {
    id: invoiceStatusesKeys.duplicated,
    label: 'DASHBOARD_INVOICES_DUPLICATED',
  },
  {
    id: invoiceStatusesKeys.toreview,
    label: 'DASHBOARD_INVOICES_REVIEW',
  },
  {
    id: invoiceStatusesKeys.reviewed,
    label: 'DASHBOARD_INVOICES_REVIEWED',
  },
  {
    id: invoiceStatusesKeys.tovalidate,
    label: 'DASHBOARD_INVOICES_VALIDATE',
  },
  {
    id: invoiceStatusesKeys.toexport,
    label: 'DASHBOARD_INVOICES_TO_EXPORT',
  },
  {
    id: invoiceStatusesKeys.exported,
    label: 'DASHBOARD_INVOICES_EXPORTED',
  },
  {
    id: invoiceStatusesKeys.rejected,
    label: 'DASHBOARD_INVOICES_REJECTED',
  },
  {
    id: invoiceStatusesKeys.errored,
    label: 'DASHBOARD_INVOICES_ERRORED',
  },
  {
    id: invoiceStatusesKeys.archived,
    label: 'DASHBOARD_INVOICES_ARCHIVED',
  },
  {
    // rename the i18n key
    id: invoiceStatusesKeys.splitRequest,
    label: 'DASHBOARD_INVOICES_SPLT',
  },
];

export const getHeader = (e: ProgressEvent<FileReader>) => {
  const res = e.target?.result;
  let header = '';
  if (res) {
    const arr = (new Uint8Array(res as ArrayBufferLike)).subarray(0, 4);
    for (let j = 0; j < arr.length; j++) {
      header += arr[j].toString(16);
    }
  }

  return header;
};

export const getConvertedFile = async (file: File) => {
  const res = await fetch(URL.createObjectURL(file));
  const blob = await res.blob();
  const conversionResult = await heic2any({ blob, toType: 'image/jpeg' });

  return conversionResult as Blob;
};

export const readFileAsync = async (file: File) => new Promise((resolve, reject) => {
  const fileReader = new FileReader();
  fileReader.onloadend = async (e) => {
    const header = getHeader(e);
    resolve(header);
  };
  fileReader.onerror = reject;
  fileReader.readAsArrayBuffer(file);
});

export const kbLinks: Record<string, string> = {
  Procys: 'https://procys.com__LANG__/help-center',
  Extrable: 'https://extrable.es/#FormularioExtrable'
};

export const invoiceStatusCodesList = [500, 501, 502, 503, 510, 600, 900];

export const documentStatusLabels = {
  all: 'INVOICE_STATUS_ALL',
  uploading: 'INVOICE_STATUS_UPLOADING',
  digitized: 'INVOICE_STATUS_DIGITIZED',
  toreview: 'INVOICE_STATUS_TO_REVIEW',
  reviewed: 'INVOICE_STATUS_TO_REVIEWED',
  tovalidate: 'INVOICE_STATUS_TO_VALIDATE',
  toexport: 'INVOICE_STATUS_TO_EXPORT',
  exported: 'INVOICE_STATUS_EXPORTED',
  rejected: 'INVOICE_STATUS_REJECTED',
  archived: 'INVOICE_STATUS_ARCHIVED',
  outOfLimit: 'INVOICE_STATUS_OUT_OF_LIMIT',
  deleted: 'INVOICE_STATUS_DELETED',
  errored: 'INVOICE_STATUS_ERRORED',
  splitRequest: 'DASHBOARD_INVOICES_SPLT',
  possibleDuplicate: 'DASHBOARD_INVOICES_DUPLICATED'
};

export const getDocumentStatusLabel = (status: number) => {
  if (!status || status < 16) {
    return documentStatusLabels.uploading;
  }

  switch (status) {
    case 16:
      return documentStatusLabels.digitized;
    case 500:
      return documentStatusLabels.toreview;
    case 509:
      return documentStatusLabels.reviewed;
    case 501:
      return documentStatusLabels.tovalidate;
    case 502:
      return documentStatusLabels.toexport;
    case 503:
      return documentStatusLabels.exported;
    case 510:
      return documentStatusLabels.rejected;
    case 600:
      return documentStatusLabels.archived;
    case 999:
      return documentStatusLabels.outOfLimit;
    case 900:
      return documentStatusLabels.deleted;
    case 200:
      return documentStatusLabels.splitRequest;
    case 201:
      return documentStatusLabels.possibleDuplicate;

    default:
      return documentStatusLabels.errored;
  }
};

export const getIconOnTrustScore = (trustScore: number, isError: boolean, isDashboard?: boolean) => {
  if (isError) {
    return <ErrorIcon sx={styles.errorIcon} />;
  }
  if (trustScore === 0) {
    return <NotSureIcon sx={styles.notSureIcon} />;
  }
  if (trustScore === 1) {
    return <TrustedIcon sx={styles.trustedIcon} />
  }
  if (trustScore === 2) {
    return <AnnotatedIcon />
  }
  return isDashboard ? null : <Box sx={styles.labelIconPlaceholder} />;
};

const styles = {
  labelIconPlaceholder: {
    width: '18px',
    height: '18px',
  },
  errorIcon: {
    width: '18px',
    height: '18px',
    color: '#FF4D49',
  },
  notSureIcon: {
    width: '18px',
    height: '18px',
    color: '#FDB528',
  },
  trustedIcon: {
    width: '18px',
    height: '18px',
    color: '#3E8AFF',
  },
};

export const axiosHeadersWithArrayBuffer = (token: string | null) => (
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    responseType: 'arraybuffer' as ResponseType
  }
);

export const periodFilterOptions = [
  {
    key: 100,
    value: 'all',
    label: 'DASHBOARD_INVOICES_PERIOD_ALL'
  },
  {
    key: 1,
    value: 'tweek',
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_WEEK'
  },
  {
    key: 2,
    value: 'lweek',
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_WEEK'
  },
  {
    key: 3,
    value: 'tmonth',
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_MONTH'
  },
  {
    key: 4,
    value: 'lmonth',
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_MONTH'
  },
  {
    key: 5,
    value: 'tquater',
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_QUARTER'
  },
  {
    key: 6,
    value: 'lquater',
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_QUARTER'
  },
  {
    key: 7,
    value: 'tyear',
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_YEAR'
  },
  {
    key: 8,
    value: 'lyear',
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_YEAR'
  },
  {
    key: 99,
    value: 'custom',
    label: 'DASHBOARD_INVOICES_PERIOD_CUSTOM'
  }
];
