import React, { useState, useCallback, useEffect, useRef } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AnnounceKit from "announcekit-react";
import Axios from 'axios';

import {
    Grid, AppBar, Button, Box, Tooltip, IconButton, Avatar, Menu, MenuItem, Typography,
		Badge
} from '@mui/material';
import {
	People as PeopleIcon,
	ArrowDropDown as DownIcon,
	Description as ReceiptIcon,
	PermIdentityOutlined as ProfileIcon,
	Settings as SettingsIcon,
	Mail as MailIcon,
	CalendarToday as PlanIcon,
	Logout as LogoutIcon,
  HelpOutlineRounded as HelpCenterIcon,
  NotificationsNone as NotificationsNoneIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import authService from 'src/utils/authService';
import { useConfig } from 'src/hooks/useConfig';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { CustomError } from 'src/types';
import { externalLinks } from 'src/config';
import styles from './style';

type AccountListType = {
	id: number;
	label: string;
	href: string;
	icon?: any,
}

const accounts: AccountListType[] = [
	{
		id: 1,
		label: 'User',
		href: '',
		icon: <ProfileIcon />
	},
	{
		id: 2,
		label: 'Email',
		href: '',
		icon: <MailIcon />
	},
	{
		id: 3,
		label: '',
		href: '/account',
		icon: <SettingsIcon />
	},
	{
		id: 4,
		label: '',
		href: '/account/subscription',
		icon: <PlanIcon />
	},
	{
		id: 5,
		label: '',
		href: '',
		icon: <LogoutIcon />
	}

]

const Topbar = () => {
	const { ready, t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth() ;
  const history = useHistory();
  const { LOGIN_PANEL_URL, API, SUPPORT_EMAIL, IS_BRAND_PROCYS } = useConfig();
  const [companies,setCompanies] = useState<any>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElCompany, setAnchorElCompany] = useState<null | HTMLElement>(null);
	const announceKitRef = useRef<any>(null);
  const [unread, setUnread] = useState(0);

  const getOffices = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API.getCompaniesByUser}${user?.email}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          const companiesResp = response.data.data;
          companiesResp.sort((a:any, b:any) => {
            if (a.name > b.name) {
              return 1;
            }
            return -1;
          });
          const listCompanies = [];
          for (let i = 0; i < companiesResp.length; i++) {
            const company = companiesResp[i];
            if (company?.companyID?.toString() !== user?.companyID && company.isMember) {
              listCompanies.push(company);
            }
          }
          setCompanies(listCompanies);
        }
      }
    } catch (e) {
      console.error('Error getting companies: ', e);
    }
  }, [API.getCompaniesByUser, user?.companyID, user?.email]);

  const handleClickSubscription = () => {
    if (((user?.isSubCompany || user?.isPartnerSubCompany) && user?.parent) || user?.customisations.includes('hideBilling')) {
      return;
    }
    history.push('/account/subscription');
  }

  const changeOffice = async (id: string) => {
    try {
      const response = await Axios.get(
        `${API.changeCompany}/${encodeURIComponent(id)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_CHANGE_SUCCESS'), {
          variant: 'success',
        });
        setTimeout(() => {
          if (window.location.pathname.indexOf('documents/') >= 0) {
            window.location.href = '/documents';
          } else {
            window.location.reload();
          }
        }, 1000);
      }
    } catch (error) {
			const e  = error as CustomError;
      enqueueSnackbar(appendContactSupport(
        SUPPORT_EMAIL,
        getLocalisedErrorString(e?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_CHANGE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };

  const handleSelectOffice = (office:any) => {
    if (office.companyID !== user?.companyID) {
      changeOffice(office.companyID);
    }
    handleCloseCompanyMenu();
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  }

  const handleOpenCompanyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCompany(event.currentTarget);
  };

  const handleCloseCompanyMenu = () => {
    setAnchorElCompany(null);
  };

  const handleLogout = () => {
    authService.logout(LOGIN_PANEL_URL);
  };

	useEffect(() => {
    if (announceKitRef.current) {
      announceKitRef.current.unread().then((unread: number) => setUnread(unread));
    }
  }, [announceKitRef]);

  const openAnnounceKitWidget = async () => {
    if (announceKitRef.current) {
      await announceKitRef.current.open();
    }
  };

  const onClickKBLink = () => {
    // Update the URL with the query parameter chat=support
    const currentUrlParams = new URLSearchParams(window.location.search);
    if (!currentUrlParams.has('chat')) {
      currentUrlParams.append('chat', 'support');
      history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
    }

    if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
      // Reference to the below here:
      // https://developers.hubspot.com/beta-docs/reference/api/conversations/chat-widget-sdk#widget.load?uuid=cf3ef811-dd96-4c1a-8fd6-f51ef9deac91
      window.HubSpotConversations.widget.load({ widgetOpen: true });
    } else {
      window.open(externalLinks.knoldgeBase.replace('__LANG__', i18n.language.split('-')[0] !== 'en'
      ? `/${i18n.language.split('-')[0]}` : ''), '_blank')
    }
  };

  const renderCompanyMenu = (): React.ReactNode => {
    return(
			<Box>
				<Button onClick={handleOpenCompanyMenu} sx={styles.companyBtn}>
					<span style={{fontSize:'14px'}}>{user?.company}</span>
					<DownIcon />
				</Button>
				<Menu
					id="user-menu"
					anchorEl={anchorElCompany}
					anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					open={Boolean(anchorElCompany)}
					onClose={handleCloseCompanyMenu}
					sx={{
						".MuiMenu-paper": styles.menuContainer,
								mt: '45px'
					}}
				>
					<MenuItem sx={styles.defaultMenu} disabled>
						<Box sx={styles.eachMenu}>
							<span style={{fontSize:'10px'}}>{ready && t('ACCOUNT_SETTINGS_MY_COMPANY')}</span>
						</Box>
					</MenuItem>
					{companies && companies.length > 0 ? companies.map((office:any) => {
						if (office.companyID?.toString() !== user?.companyID && office?.isMember) {
							return (
								<MenuItem key={office.companyID} onClick={() => handleSelectOffice(office)}>
									<Box sx={styles.eachMenu}>
										<span>{office.name}</span>
									</Box>
								</MenuItem>
							)}
							return null;
						})
						: <span></span>
					}
				</Menu>
			</Box>
    )
  };

  useEffect(() => {
    if (user && user.role === 'user') {
			getOffices();
		}
  },[user?.role])

  return (
		<AppBar position='relative' sx={styles.appBar}>
			<Grid  sx={{flexGrow:1,display:{xs:'flex',md:'flex'},...styles.desktopBar}} >
				{renderCompanyMenu()}
				<Tooltip
					title={t('TOPBAR_SUBSCRIPTION_TOOLTIP')}
					disableHoverListener={!((user?.isSubCompany || user?.isPartnerSubCompany) && user?.parent)}
				>
					<Button onClick={handleClickSubscription} sx={styles.subBtn}>{user?.subscription}</Button>
				</Tooltip>
				<Tooltip
					title={user?.maxInvoices && user?.maxInvoices > 0
						? t('TOPBAR_DOCUMENTS_UPLOADED', { invoicesCount: user?.invoicesCount || 0, maxInvoices: user?.maxInvoices || 0 })
						: t('TOPBAR_DOCUMENTS_UPLOADED_CUSTOM', { invoicesCount: user?.invoicesCount || 0 })}
				>
					<Grid sx={styles.statsContainer}>
						<ReceiptIcon sx={styles.icon} />
						<span>
						{
							user?.maxInvoices && user?.maxInvoices > 0 ?
							<>
								{user?.invoicesCount || 0}
								<span style={{...styles.fadeText}}>/{user?.maxInvoices || 0}</span>
							</>
							: `${user?.invoicesCount || 0}`
						}
						</span>
					</Grid>
				</Tooltip>
				<Tooltip title={t('TOPBAR_USERS_USED', { usersCount: user?.usersCount || 0, maxUsers: user?.maxUsers || 0 })}>
					<Grid sx={styles.statsContainer}>
						<PeopleIcon sx={styles.icon} />
						<span>
							{user?.usersCount || 0}
							<span style={{...styles.fadeText}}>/{user?.maxUsers || 0}</span>
						</span>
					</Grid>
				</Tooltip>
				<Box sx={styles.rightContainer}>
					{IS_BRAND_PROCYS && (
						<Tooltip title={t('DASHBOARD_NAV_KB_LINK')}>
							<IconButton
								sx={styles.kbIcon}
								onClick={() => onClickKBLink()}
							>
								<HelpCenterIcon />
							</IconButton>
						</Tooltip>
					)}
					{IS_BRAND_PROCYS && (
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<AnnounceKit
								ref={announceKitRef}
								user={{
									id: user?.id.toString() as string,
									company_id: user?.companyID,
									subscription_name: user?.subscription,
									parent_company_id: user?.parent,
									email: user?.email,
									name: `${user?.firstname} ${user?.lastname}`,
									company: user?.company,
									parent_company_name: user?.parentName,
								}}
								widget="https://announcekit.co/widgets/v2/qewhO"
							>
								<Badge className="ak-badge" badgeContent={unread} color="primary" max={9}>
									<IconButton sx={styles.notificationIcon} onClick={async () => await openAnnounceKitWidget()}>
										<NotificationsNoneIcon />
									</IconButton>
								</Badge>
							</AnnounceKit>
						</div>
					)}
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						<Avatar alt={`${user?.firstname} ${user?.lastname}`} src={user?.avatar} />
					</IconButton>
					<Menu
						id="user-menu"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
						sx={{
							".MuiMenu-paper": styles.menuContainer,
							mt: '45px'
						}}
					>
					{accounts.map((setting:AccountListType) => (
						<MenuItem key={setting.id} >
							<Box sx={styles.eachMenu}>
								{setting.icon?setting.icon:<></>}
								{setting?.href ?
								<Link style={{textDecoration:'none',color:'inherit', overflow:'hidden',
									textOverflow:'ellipsis',maxWidth:'161px',fontSize:'15px',
									textWrap:'nowrap'}}
									to={setting.href}>{setting.id === 4?(ready &&
									t('DASHBOARD_NAV_PLAN_N_BILLING')):setting.id ===3 ?
									(ready && t('DASHBOARD_NAV_ACCOUNT_SETTING'))
									:setting.label}
								</Link>:
								<Typography onClick={setting.id ===5?handleLogout:()=>{}} sx={{ textAlign: 'center',...styles.textWrapper }}>
									{setting.id === 1?`${user?.firstname} ${user?.lastname}`:
									setting.id === 2?user?.email:
									setting?.id===5?(ready && t('DASHBOARD_ACTION_LOGOUT')):
									setting.label}
								</Typography>}
							</Box>
						</MenuItem>
					))}
					</Menu>
				</Box>
			</Grid>
		</AppBar>
	)
}

export default Topbar
