import { useState, useEffect, useRef } from 'react';
import { Container } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { lineAmountRelatedFields, amountRelatedFields, annotationSpecialCharacters } from 'src/config';
import { formatAmountFields } from 'src/utils/helpers';
import styles from './style';

const Canvas = (props) => {
  const {user} = useAuth();
  const decimalSeparator = user.monetaryDecimalSeparator;
  
  const canvasRef0 = useRef(null);
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);
  const contextRef2 = useRef(null);
  const [shiftPressed, setShiftPressed] = useState(false);
  const [xmlSelectedBox, setXmlSelectedBox] = useState([]);

  const startX = useRef(null);
  const startY = useRef(null);
  const endX = useRef(null);
  const endY = useRef(null);

  const [isDrawing, setIsDrawing] = useState(false);

  const {
    image, words, page, pageNumber, xmlSelectedValue, setXmlSelectedValue, candidates,
    textFieldSelectedValue, scrollXMLViewBy, isLine, lineItems, isOverlapping, topBarHeight,
    handleGotoPage, textFieldSelectedKey, handleSelectColumnForReprocessing, linesReprocessingData
  } = props;

  const pageOriginalHeight = page[pageNumber]?.getAttribute('height');
  const pageOriginalWidth = page[pageNumber]?.getAttribute('width');

  const handleTextFieldSelect = () => {
    const canvas1 = canvasRef1.current;
    const context1 = canvas1.getContext('2d');
    const { width, height } = context1.canvas;
    const heightRatio = height / pageOriginalHeight;
    const widthRatio = width / pageOriginalWidth;
    const rect = canvas1.getBoundingClientRect();
    const rectWidth = rect.width;
    const rectHeight = rect.height;
    const possibleCandidates = [];
    let possibleXmlSelectedVals = '';

    if (textFieldSelectedValue === '' || textFieldSelectedValue === ' ') {
      setXmlSelectedBox(possibleCandidates);
      setXmlSelectedValue('');
      return;
    }

    if (!isLine) {
      candidates.forEach((candidate) => {
        let candVal = candidate.value;
        if (amountRelatedFields.includes(textFieldSelectedKey)) {
          candVal = formatAmountFields(candidate.value, decimalSeparator);
        } else {
          candVal = candidate.value.toLowerCase();
        }
        if (candVal === textFieldSelectedValue?.toLowerCase()) {
          const midx = (parseFloat(candidate.xmin) + parseFloat(candidate.xmax)) / 2;
          const midy = (parseFloat(candidate.ymin) + parseFloat(candidate.ymax)) / 2;
          if (!possibleCandidates.length || possibleCandidates[possibleCandidates.length - 1].proba < candidate.proba) {
            possibleXmlSelectedVals = candidate.value;
            possibleCandidates.push({
              x: (midx * widthRatio * rectWidth) / width,
              y: (midy * heightRatio * rectHeight) / height,
              width: rectWidth,
              height: rectHeight,
              minY: (candidate.ymin * heightRatio * rectHeight) / height,
              xmin: candidate.xmin,
              ymin: candidate.ymin,
              value: candidate.value,
              proba: candidate.proba,
              page: candidate.page
            });
          }
        }
      });
    } else {
      lineItems.forEach((block) => {
        let blockVal = block.value;
        if (lineAmountRelatedFields.includes(textFieldSelectedKey)) {
          blockVal = formatAmountFields(block.value, decimalSeparator);
        } else {
          blockVal = block.value.toLowerCase();
        }
        if (blockVal === textFieldSelectedValue?.toLowerCase()) {
          const midx = (parseFloat(block.xmin) + parseFloat(block.xmax)) / 2;
          const midy = (parseFloat(block.ymin) + parseFloat(block.ymax)) / 2;
          if (!possibleCandidates.length || possibleCandidates[possibleCandidates.length - 1].proba < block.proba) {
            possibleXmlSelectedVals = block.value;
            possibleCandidates.push({
              x: (midx * widthRatio * rectWidth) / width,
              y: (midy * heightRatio * rectHeight) / height,
              width: rectWidth,
              height: rectHeight,
              minY: (block.ymin * heightRatio * rectHeight) / height,
              xmin: block.xmin,
              ymin: block.ymin,
              value: blockVal,
              proba: block.proba,
              page: block.page
            });
          }
        }
      });
    }

    if (!possibleCandidates.length) {
      words.forEach((word) => {
        let wordVal = word.value;
        if (amountRelatedFields.includes(textFieldSelectedKey)) {
          wordVal = formatAmountFields(word.value, decimalSeparator);
        } else {
          wordVal = word.value.toLowerCase();
        }
        if (!possibleCandidates.some((c) => c.value.toLowerCase() === wordVal)
          && textFieldSelectedValue?.toLowerCase().split(' ').includes(wordVal)) {
          const midx = (parseFloat(word.xmin) + parseFloat(word.xmax)) / 2;
          const midy = (parseFloat(word.ymin) + parseFloat(word.ymax)) / 2;
          possibleCandidates.push({
            x: (midx * widthRatio * rectWidth) / width,
            y: (midy * heightRatio * rectHeight) / height,
            width: rectWidth,
            height: rectHeight,
            minY: (word.ymin * heightRatio * rectHeight) / height,
            xmin: word.xmin,
            ymin: word.ymin,
            value: word.value,
            proba: 0.5,
            page: word.page
          });
        }
      });
    }

    setXmlSelectedBox(possibleCandidates);
    if (possibleXmlSelectedVals) {
      setXmlSelectedValue(possibleXmlSelectedVals);
    }

    if (possibleCandidates.length) {
      const candidate = possibleCandidates[possibleCandidates.length - 1];
      if (candidate.page !== pageNumber) {
        handleGotoPage(candidate.page + 1);
      }

      const topHeight = topBarHeight + 60;
      const rectTop = -rect.top + topHeight;
      const viewPortHalf = (window.innerHeight / 2);

      const isCandidateTop = rectTop - candidate.minY;
      if (isCandidateTop > -50) {
        scrollXMLViewBy(-parseInt(rectTop - candidate.minY + viewPortHalf, 10));
        return;
      }

      const isCandidateBottom = (candidate.minY + topHeight) - (rectTop + window.innerHeight);
      if (isCandidateBottom > -50) {
        scrollXMLViewBy(parseInt(candidate.minY + topHeight - (rectTop + viewPortHalf), 10));
      }
    }
  };

  useEffect(() => {
    handleTextFieldSelect();
  }, [textFieldSelectedKey]);

  const handleClickOnCanvas = (x, y) => {
    if (x == null && y == null) {
      return;
    }
    const context = canvasRef1.current.getContext('2d');
    const { width, height } = context.canvas;

    const heightRatio = height / page[pageNumber]?.getAttribute('height');
    const widthRatio = width / page[pageNumber]?.getAttribute('width');

    const rect = canvasRef1.current.getBoundingClientRect();

    let selectedItem = null;
    const alreadySelectedBoxes = [];

    const selectedX = x * (width / rect.width);
    const selectedY = y * (height / rect.height);

    // First go through candidates
    candidates.forEach((item) => {
      // If candidate belongs to the current page
      if (item.page === pageNumber) {
        const xmin = parseInt(item.xmin, 10);
        const ymin = parseInt(item.ymin, 10);
        const xmax = parseInt(item.xmax, 10);
        const ymax = parseInt(item.ymax, 10);

        // Check if candidate is already selected
        const isSelected = xmlSelectedBox.some((elem) => {
          const elemX = elem.x * (width / rect.width);
          const elemY = elem.y * (height / rect.height);

          return (xmin * widthRatio < elemX && elemX < xmax * widthRatio)
          && (ymin * heightRatio < elemY && elemY < ymax * heightRatio);
        });

        // Save it only if it is of a high probability
        if (isSelected && item.proba > 0.5) {
          alreadySelectedBoxes.push(item);
        }

        // Check if candidate includes clicked point
        const isNewlySelected = (xmin * widthRatio < selectedX && selectedX < xmax * widthRatio)
        && (ymin * heightRatio < selectedY && selectedY < ymax * heightRatio);

        // Save it only if the probability is higher than already saved candidate
        if (isNewlySelected && (!selectedItem || item.proba > selectedItem?.proba)) {
          selectedItem = item;
        }
      }
    });

    // Go through words if clicked point does not belong to a candidate
    if (!selectedItem) {
      words.forEach((item) => {
        if (item.page === pageNumber) {
          const xmin = parseInt(item.xmin, 10);
          const ymin = parseInt(item.ymin, 10);
          const xmax = parseInt(item.xmax, 10);
          const ymax = parseInt(item.ymax, 10);

          // Check if word is already selected
          const isSelected = xmlSelectedBox.some((elem) => {
            const elemX = elem.x * (width / rect.width);
            const elemY = elem.y * (height / rect.height);

            return (xmin * widthRatio < elemX && elemX < xmax * widthRatio)
            && (ymin * heightRatio < elemY && elemY < ymax * heightRatio);
          });

          if (isSelected) {
            alreadySelectedBoxes.push(item);
          }

          // Check if word includes clicked point
          const isNewlySelected = (xmin * widthRatio < selectedX && selectedX < xmax * widthRatio)
          && (ymin * heightRatio < selectedY && selectedY < ymax * heightRatio);

          if (isNewlySelected) {
            selectedItem = item;
          }
        }
      });
    }

    // Check if selected candidate/word was already selected
    const isBoxAlreadySelected = alreadySelectedBoxes.some((box) => (box.xmin === selectedItem?.xmin)
        && (box.ymin === selectedItem?.ymin)
        && (box.xmax === selectedItem?.xmax)
        && (box.ymax === selectedItem?.ymax));

    let selectedValue = selectedItem && selectedItem.value ? selectedItem.value : '';
    if (textFieldSelectedKey.includes('line') && selectedItem?.innerVal) {
      selectedValue = selectedItem.innerVal;
    }

    // If shift key was pressed, handle multi select
    if (shiftPressed && !isBoxAlreadySelected) {
      setXmlSelectedBox([...xmlSelectedBox, {
        x, y, height: rect.height, width: rect.width
      }]);
      let space = ' ';
      if (annotationSpecialCharacters.includes(selectedValue) || annotationSpecialCharacters.includes(xmlSelectedValue.slice(-1))) {
        space = '';
      }
      setXmlSelectedValue(xmlSelectedValue.concat(space).concat(selectedValue));
    } else if (!isBoxAlreadySelected) {
      setXmlSelectedBox([{
        x, y, height: rect.height, width: rect.width
      }]);
      // Clear textFieldSelectedValue if clicked point does not belong to a candidate or word
      if (xmlSelectedValue === '' && selectedValue === '') {
        setXmlSelectedValue(' ');
      } else {
        setXmlSelectedValue(selectedValue);
      }
    } else {
      setXmlSelectedBox([]);
      if (xmlSelectedValue === '' && selectedValue === '') {
        setXmlSelectedValue(' ');
      } else {
        setXmlSelectedValue(selectedValue);
      }
    }
  };

  const handleDragSelect = (x1, x2, y1, y2) => {
    setXmlSelectedBox([]);
    setXmlSelectedValue('');
    const context = canvasRef1.current.getContext('2d');
    const { width, height } = context.canvas;

    const heightRatio = height / page[pageNumber]?.getAttribute('height');
    const widthRatio = width / page[pageNumber]?.getAttribute('width');

    const rect = canvasRef1.current.getBoundingClientRect();

    const selectedXmin = x1 * (width / rect.width);
    const selectedXmax = x2 * (width / rect.width);
    const selectedYmin = y1 * (height / rect.height);
    const selectedYmax = y2 * (height / rect.height);

    if (textFieldSelectedKey.includes('invoiceLineColumn')) {
      handleSelectColumnForReprocessing(
        selectedXmin / widthRatio,
        selectedXmax / widthRatio,
        selectedYmin / heightRatio,
        selectedYmax / heightRatio
      );
      return;
    }

    const selectedBoxes = [];
    let selectedValue = '';

    words.forEach((item) => {
      if (item.page === pageNumber) {
        const xmin = parseFloat(item.xmin) * widthRatio;
        const ymin = parseFloat(item.ymin) * heightRatio;
        const xmax = parseFloat(item.xmax) * widthRatio;
        const ymax = parseFloat(item.ymax) * heightRatio;

        const isSelected = isOverlapping(selectedXmin, selectedYmin, selectedXmax, selectedYmax, xmin, ymin, xmax, ymax);

        if (isSelected) {
          const x = ((xmin + xmax) * rect.width) / (2 * width);
          const y = ((ymin + ymax) * rect.height) / (2 * height);
          selectedBoxes.push({
            x,
            y,
            height: rect.height,
            width: rect.width
          });
          let space = ' ';
          if (annotationSpecialCharacters.includes(item.value) || annotationSpecialCharacters.includes(selectedValue.slice(-1))) {
            space = '';
          }
          selectedValue = selectedValue.concat(space).concat(item.value);
        }
      }
    });
    setXmlSelectedBox(selectedBoxes);
    setXmlSelectedValue(selectedValue);
  };

  const startDrawingRectangle = ({ nativeEvent }) => {
    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();

    const canvas2 = canvasRef2.current;
    const canvasOffSet2 = canvas2.getBoundingClientRect();

    contextRef2.current.clearRect(0, 0, canvas2.width, canvas2.height);

    startX.current = nativeEvent.clientX - canvasOffSet2.left;
    startY.current = nativeEvent.clientY - canvasOffSet2.top;
    endX.current = nativeEvent.clientX - canvasOffSet2.left;
    endY.current = nativeEvent.clientY - canvasOffSet2.top;

    setIsDrawing(true);
  };

  const drawRectangle = ({ nativeEvent }) => {
    if (!isDrawing) {
      return;
    }

    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();

    const { width, height } = contextRef2.current.canvas;

    const canvas2 = canvasRef2.current;
    const canvasOffSet2 = canvas2.getBoundingClientRect();

    const newMouseX = nativeEvent.clientX - canvasOffSet2.left;
    const newMouseY = nativeEvent.clientY - canvasOffSet2.top;

    endX.current = newMouseX;
    endY.current = newMouseY;

    const rectWidth = newMouseX - startX.current;
    const rectHeight = newMouseY - startY.current;

    const widthRatio = width / canvasOffSet2.width;
    const heightRatio = height / canvasOffSet2.height;

    contextRef2.current.clearRect(0, 0, canvas2.width, canvas2.height);
    contextRef2.current.strokeRect(
      startX.current * widthRatio,
      startY.current * heightRatio,
      rectWidth * widthRatio,
      rectHeight * heightRatio
    );
  };

  const stopDrawingRectangle = () => {
    if (!isDrawing) {
      return;
    }
    setIsDrawing(false);
    const canvas2 = canvasRef2.current;
    contextRef2.current.clearRect(0, 0, canvas2.width, canvas2.height);
    if (startX.current > endX.current) {
      const temp = startX.current;
      startX.current = endX.current;
      endX.current = temp;
    }
    if (startY.current > endY.current) {
      const temp = startY.current;
      startY.current = endY.current;
      endY.current = temp;
    }
    handleDragSelect(startX.current, endX.current, startY.current, endY.current);
  };

  const stopDrawingRectangleAndHandleClick = () => {
    if (startX.current === endX.current && startY.current === endY.current) {
      setIsDrawing(false);
      handleClickOnCanvas(endX.current, endY.current);
    } else {
      stopDrawingRectangle();
    }
  };

  const drawBoundingBoxes = (canvas, heightRatio, widthRatio) => {
    const ctx = canvas.getContext('2d');
    const { width, height } = ctx.canvas;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let boxes = words;
    if (!isLine) {
      boxes = [...candidates, ...words];
    } else {
      boxes = [...lineItems, ...words];
    }
    boxes.forEach((item) => {
      if (item.page === pageNumber) {
        const xmin = parseInt(item.xmin, 10);
        const ymin = parseInt(item.ymin, 10);
        const xmax = parseInt(item.xmax, 10);
        const ymax = parseInt(item.ymax, 10);

        const isSelected = xmlSelectedBox.some((elem) => {
          const selectedX = elem.x * (width / elem.width);
          const selectedY = elem.y * (height / elem.height);

          return (xmin * widthRatio < selectedX && selectedX < xmax * widthRatio)
            && (ymin * heightRatio < selectedY && selectedY < ymax * heightRatio);
        });

        ctx.beginPath();
        ctx.rect(
          xmin * widthRatio,
          ymin * heightRatio,
          (xmax - xmin) * widthRatio,
          (ymax - ymin) * heightRatio
        );
        ctx.lineWidth = isSelected ? 3 : 1;
        ctx.strokeStyle = isSelected ? 'red' : '#3E8AFF';
        ctx.stroke();
      }
    });
    linesReprocessingData.forEach((item) => {
      if (item.page - 1 === pageNumber) {
        const xmin = parseInt(item.x1, 10);
        const ymin = parseInt(item.y1, 10);
        const xmax = parseInt(item.x2, 10);
        const ymax = parseInt(item.y2, 10);
  
        ctx.beginPath();
        ctx.rect(
          xmin * widthRatio,
          ymin * heightRatio,
          (xmax - xmin) * widthRatio,
          (ymax - ymin) * heightRatio
        );
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'red';
        ctx.stroke();
      }
    });
  };

  const imageDrw = (ctx0, ctx1, ctx2, img) => {
    img.onload = () => {
      ctx0.canvas.height = img.height;
      ctx0.canvas.width = img.width;
      ctx1.canvas.height = img.height;
      ctx1.canvas.width = img.width;
      ctx2.canvas.height = img.height;
      ctx2.canvas.width = img.width;

      ctx2.lineCap = 'round';
      ctx2.strokeStyle = 'red';
      ctx2.lineWidth = 1;
      contextRef2.current = ctx2;

      // Inject image into canvas
      ctx0.drawImage(img, 0, 0, img.width, img.height);
    };

    img.src = image;
  };

  useEffect(() => {
    const canvas0 = canvasRef0.current;
    const context0 = canvas0.getContext('2d');
    const canvas1 = canvasRef1.current;
    const context1 = canvas1.getContext('2d');
    const canvas2 = canvasRef2.current;
    const context2 = canvas2.getContext('2d');
    const img = new Image();

    imageDrw(context0, context1, context2, img);
  }, [image]);

  useEffect(() => {
    // Draw boxes after entire image loads.
    const canvas1 = canvasRef1.current;
    const ctx = canvas1.getContext('2d');
    setTimeout(() => {
      const { width, height } = ctx.canvas;

      const heightRatio = height / pageOriginalHeight;
      const widthRatio = width / pageOriginalWidth;

      drawBoundingBoxes(canvas1, heightRatio, widthRatio);
    }, 100);
  }, [image, xmlSelectedBox]);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.shiftKey) {
        setShiftPressed(true);
      }
    });
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Shift') {
        setShiftPressed(false);
      }
    });
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setXmlSelectedBox([]);
        setXmlSelectedValue('');
      }
    });
  }, []);

  // Here we use 3 canvases. One for the invoice image, one to draw bounding boxes and one to draw the rectangle on click and drag
  return (
    <Container style={styles.container}>
      <canvas
        id="canvasId0"
        style={styles.canvas0}
        ref={canvasRef0}
        tabIndex="-1"
      />
      <canvas
        id="canvasId1"
        style={styles.canvas1}
        ref={canvasRef1}
        tabIndex="-1"
      />
      <canvas
        id="canvasId2"
        style={styles.canvas2}
        ref={canvasRef2}
        onMouseDown={startDrawingRectangle}
        onMouseMove={drawRectangle}
        onMouseUp={stopDrawingRectangleAndHandleClick}
        onMouseLeave={stopDrawingRectangle}
      />
    </Container>
  );
};

export default Canvas;
