import { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { countries } from 'countries-list';
import cc from 'currency-codes/data';
import Axios from 'axios';

import { useAuth } from 'src/hooks/useAuth';
import {
  ERRORS, supplierData,
} from 'src/config';
import {
  axiosHeaders, getLocalisedErrorString
} from 'src/utils/helpers';
import authService from 'src/utils/authService';
import ConfigContext from 'src/contexts/ConfigContext';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import GLAccountModal from 'src/dimensions/glas/components/GLAccountModal/GLAccountModal';
import VatCodeModal from 'src/dimensions/vatcodes/components/VatCodeModal/VatCodeModal';
import CostCenterModal from 'src/dimensions/costcenters/components/CostCenterModal/CostCenterModal';
import ManageFields from 'src/shared/components/ManageFields/ManageFields';
import EditSupplierStandard from 'src/dimensions/suppliers/components/EditSupplierForm/EditSupplierStandard';
import EditSupplierTwinfield from 'src/dimensions/suppliers/components/EditSupplierForm/EditSupplierTwinfield';

function EditSupplierForm({
  supplier,
  handleEditSupplierClose,
  getAllSuppliers,
  isInvoiceEditForm,
  xmlSelectedValue,
  textFieldSelectedKey,
  textFieldSelectedValueRef,
  setTextFieldSelectedKey,
  setTextFieldSelectedValue,
}) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const glAccounts = useSelector((state) => [...supplierData.defaultGLA, ...state.gla.glAccounts]);
  const vatGroups = useSelector((state) => [...supplierData.defaultVatGroup, ...state.vatcode.vatCodes]);
  const costCenters = useSelector((state) => [...supplierData.defaultGLA, ...state.costcenter.costCenters]);

  const auth = useAuth();
  const {user} = auth;

  const [glaYears, setGlaYears] = useState([]);
  const [glaPeriods, setGlaPeriods] = useState([]);

  const [currencies, setCurrencies] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [projects, setProjects] = useState(supplierData.defaultProjects);
  const [isTwinfield, setIsTwinfield] = useState(false);

  const [supplierFields, setSupplierFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);

  const [gLAccountModalOpen, setGLAccountModalOpen] = useState(false);
  const [vatCodeModalOpen, setVatCodeModalOpen] = useState(false);
  const [ccModalOpen, setCCModalOpen] = useState(false);
  const [selectedGLA, setSelectedGLA] = useState(null);
  const [selectedVATGroupCode, setSelectedVATGroupCode] = useState(null);
  const [selectedCostCenterCode, setSelectedCostCenterCode] = useState(null);

  const [newlyAddingProp, setNewlyAddingProp] = useState(null);
  const [newlyAddedVal, setNewlyAddedVal] = useState(null);

  const [preferredFieldsLoading, setPreferredFieldsLoading] = useState(false);

  const [supplierProducts, setSupplierProducts] = useState(supplier.inventories || []);

  const updateSupplierProducts = (products) => {
    setSupplierProducts(products);
  };

  const getSupplierFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/supplier/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setSupplierFields(response.data.data.filter((f) => f.isAvailable));
          setPreferredFieldsLoading(false);
        } else {
          setSupplierFields([]);
          setPreferredFieldsLoading(false);
        }
      }
    } catch (error) {
      setSupplierFields([]);
      setPreferredFieldsLoading(false);
    }
  };

  const getGLAYears = async () => {
    try {
      const response = await Axios.get(
        `${API.glAYearsByCompany}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setGlaYears(response.data.data);
      }
    } catch (err) {
      //
    }
  };

  const getGLAPeriods = async () => {
    try {
      const response = await Axios.get(
        `${API.glAPeriodsByCompany}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setGlaPeriods(response.data.data);
      }
    } catch (err) {
      //
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  const getAllGLAccounts = async () => {
    dispatch(fetchGlas(user.companyID));
  };

  const getAllVatGroups = () => {
    dispatch(fetchVatCodes(user.companyID));
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(user.companyID));
  };

  const getAllProjects = async () => {
    try {
      const response = await Axios.get(
        `${API.projectsByCompany}/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setProjects([...supplierData.defaultProjects, ...response.data.data]);
        }
      }
    } catch (error) {
      //
    }
  };

  const onGLAModalClose = () => {
    setGLAccountModalOpen(false);
    setSelectedGLA(null);
  };

  const handleEditGLA = (id) => {
    if (id !== supplierData.defaultGLA[0].code) {
      setSelectedGLA(glAccounts.find((gla) => gla.code === id));
      setGLAccountModalOpen(true);
    }
  };

  const handleAddGLA = (prop) => {
    setSelectedGLA(null);
    setNewlyAddedVal(null);
    setGLAccountModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onGLACreated = (val) => {
    getAllGLAccounts();
    setNewlyAddedVal(val);
  };

  const onVatCoddeModalClose = () => {
    setVatCodeModalOpen(false);
    setSelectedVATGroupCode(null);
  };

  const handleEditVATCode = (id) => {
    if (id !== supplierData.defaultVatGroup[0].code) {
      setSelectedVATGroupCode(vatGroups.find((v) => v.code === id));
      setVatCodeModalOpen(true);
    }
  };

  const handleAddVATCode = (prop) => {
    setSelectedVATGroupCode(null);
    setNewlyAddedVal(null);
    setVatCodeModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onVatCodeCreated = (val) => {
    setNewlyAddedVal(val);
  };

  const onCCModalClose = () => {
    setCCModalOpen(false);
    setSelectedCostCenterCode(null);
  };

  const handleEditCostCenter = (id) => {
    if (id !== supplierData.defaultCostCenters[0].code) {
      setSelectedCostCenterCode(id);
      setCCModalOpen(true);
    }
  };

  const handleAddCostCenter = (prop) => {
    setCCModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onCCCreated = (val) => {
    getAllCostCenters();
    setNewlyAddedVal(val);
  };

  useEffect(() => {
    setIsTwinfield(user.accountingSystem === 'twinfield');

    getAllProjects();
    getSupplierFields();
    getGLAYears();
    getGLAPeriods();

    const allCountries = Object.keys(countries).map((key) => ({
      isoCode: key,
      name: countries[key]?.name,
    }));
    setCountriesList(allCountries);

    setCurrencies(
      cc.sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        return 1;
      })
    );
  }, []);

  const hasError = (fields, errors) => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        return true;
      }
    }

    return false;
  };

  const handleChangeAutocomplete = (e, prop, value, changeFunc) => {
    if (value && (value.code || value.isoCode)) {
      changeFunc({ target: { name: prop, value: value.isoCode ? value.isoCode : value.code } });
    }
  };

  const getLabel = (entities, code) => {
    for (let i = 0; i < entities?.length; i++) {
      if (code === entities[i].code) {
        let namePart = '';
        if (entities[i].name) {
          namePart = ` | ${entities[i].name}`;
        }
        return `${entities[i].code}${namePart}`;
      }
    }

    return code;
  };

  return (
    <>
      <Formik
        initialValues={{
          skipSupplierReview: supplier.skipSupplierReview,
          creditorCode: supplier.creditorCode,
          creditorName: supplier.creditorName,
          fiscalName: supplier.fiscalName,
          autoPaymentStatus: supplier.autoPaymentStatus || supplierData.apStatuses[2].code,
          dueDays: supplier.dueDays,
          website: supplier.website,
          comment: supplier.comment,
          companyRegNumber: supplier.companyRegNumber,
          vatNumber: supplier.vatNumber,
          referenceCode: supplier.referenceCode,
          status: supplier.status || '',
          generalLedgerAccount: supplier.generalLedgerAccount || supplierData.defaultGLA[0].code,
          generalLedgerAccLine: supplier.generalLedgerAccLine || supplierData.defaultGLA[0].code,
          vatGroup: supplier.vatGroup || supplierData.defaultVatGroup[0].code,
          vatGroupLine: supplier.vatGroupLine || supplierData.defaultVatGroup[0].code,
          costCenter: supplier.costCenter || supplierData.defaultCostCenters[0].code,
          project: supplier.project || supplierData.defaultProjects[0].code,
          invoiceDescriptionRule: supplier.invoiceDescriptionRule,
          invoiceLineDescriptionRule: supplier.invoiceLineDescriptionRule,
          defaultCurrency: supplier.defaultCurrency || '',
          bankCountry: supplier.bankCountry,
          ascription: supplier.ascription,
          iban: supplier.iban,
          bankAccNumber: supplier.bankAccNumber,
          bicSwift: supplier.bicSwift,
          nationalBankCode: supplier.nationalBankCode,
          bankName: supplier.bankName,
          bankCity: supplier.bankCity,
          bankStreet: supplier.bankStreet,
          bankHouseNumber: supplier.bankHouseNumber,
          country: supplier.country,
          city: supplier.city,
          postalCode: supplier.postalCode,
          address1: supplier.address1,
          address2: supplier.address2,
          poBox: supplier.poBox,
          poBoxPostalCode: supplier.poBoxPostalCode,
          poBoxCity: supplier.poBoxCity,
          contactPersonName: supplier.contactPersonName,
          telephoneNumber: supplier.telephoneNumber,
          faxNumber: supplier.faxNumber,
          mobileTelNumber: supplier.mobileTelNumber,
          emailAddress: supplier.emailAddress,
          postcode: supplier.postcode,
          state: supplier.state,
          addressName: supplier.addressName,
          shortname: supplier.shortname,
          discountDays: supplier.discountDays,
          discountPercentage: supplier.discountPercentage,
          matchType: supplier.matchType,
          custom1: supplier?.custom1 || '',
          custom2: supplier?.custom2 || '',
          custom3: supplier?.custom3 || '',
        }}
        validationSchema={Yup.object().shape({
          creditorCode: Yup.string().max(255),
          // .required('SUPPLIERS_CRED_CODE_REQUIRED'),
          creditorName: Yup.string()
            .max(80, 'SUPPLIERS_CREDITOR_CODE_MAX_VALIDATION')
            .required('SUPPLIERS_CRED_NAME_REQUIRED'),
          fiscalName: Yup.string().max(255, 'SUPPLIERS_FISCAL_NAME_MAX_VALIDATION'),
          autoPaymentStatus: Yup.string().max(255),
          // .required('SUPPLIERS_AUTO_PAYMENT_STATUS_REQUIRED'),
          dueDays: Yup.number()
            .integer('SUPPLIERS_DUE_DAYS_TYPE_VALIDATION')
            .typeError('SUPPLIERS_DUE_DAYS_TYPE_VALIDATION')
            .min(0),
          // .required('SUPPLIERS_DUE_DAYS_REQUIRED'),
          website: Yup.string().max(40, 'SUPPLIERS_WEBSITE_MAX_VALIDATION'),
          // comment: Yup.string().max(255).required('comment is required'),
          companyRegNumber: Yup.string().max(128, 'SUPPLIERS_COMPANY_REG_NUMBER_MAX_VALIDATION'),
          vatNumber: Yup.string().max(128, 'SUPPLIERS_VAT_NUMBER_MAX_VALIDATION'),
          referenceCode: Yup.string().max(40, 'SUPPLIERS_REFERENCE_CODE_MAX_VALIDATION'),
          // status: Yup.string().max(255).required('status is required'),
          // generalLedgerAccount: Yup.string().max(255).required('General Ledge Account is required'),
          // generalLedgerAccLine: Yup.string().max(255).required('General Ledge Account is required'),
          // vatGroup: Yup.string().max(255).required('VAT Group is required'),
          invoiceDescriptionRule: Yup.string()
            .max(100, 'SUPPLIERS_INVOICE_DESCRIPTION_RULE_MAX_VALIDATION'),
          // .required('SUPPLIERS_INVOICE_DESCRIPTION_RULE_REQUIRED'),
          defaultCurrency: Yup.string().max(255),
          // .required('SUPPLIERS_DEFAULT_CURRENCY_REQUIRED'),
          bankCountry: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus !== supplierData.apStatuses[2].code,
              then: Yup.string().max(255).required('SUPPLIERS_BANK_COUNTRY_REQUIRED')
            }) : Yup.string().max(255),
          ascription: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[0].code || autoPaymentStatus === supplierData.apStatuses[1].code,
              then: Yup.string().required('SUPPLIERS_ASCRIPTION_REQUIRED')
            })
            .max(40, 'SUPPLIERS_ASCRIPTION_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_ASCRIPTION_MAX_VALIDATION'),
          iban: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[0].code,
              then: Yup.string().required('SUPPLIERS_IBAN_REQUIRED')
            })
            .max(40, 'SUPPLIERS_IBAN_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_IBAN_MAX_VALIDATION'),
          bankAccNumber: Yup.string().max(40, 'SUPPLIERS_BANK_ACC_NUMBER_MAX_VALIDATION'),
          bicSwift: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: Yup.string().required('SUPPLIERS_BIC_SWIFT_REQUIRED')
            })
            .max(16, 'SUPPLIERS_BIC_SWIFT_MAX_VALIDATION') : Yup.string().max(16, 'SUPPLIERS_BIC_SWIFT_MAX_VALIDATION'),
          nationalBankCode: Yup.string().max(20, 'SUPPLIERS_NATIONAL_BANK_CODE_MAX_VALIDATION'),
          bankName: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: Yup.string().required('SUPPLIERS_BANK_NAME_REQUIRED')
            })
            .max(40, 'SUPPLIERS_BANK_NAME_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_BANK_NAME_MAX_VALIDATION'),
          bankCity: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: Yup.string().required('SUPPLIERS_BANK_CITY_REQUIRED')
            })
            .max(40, 'SUPPLIERS_BANK_CITY_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_BANK_CITY_MAX_VALIDATION'),
          bankStreet: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: Yup.string().required('SUPPLIERS_BANK_STREET_REQUIRED')
            })
            .max(128, 'SUPPLIERS_BANK_STREET_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_BANK_STREET_MAX_VALIDATION'),
          bankHouseNumber: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: Yup.string().required('SUPPLIERS_BANK_HOUSE_NUMBER_REQUIRED')
            })
            .max(128, 'SUPPLIERS_BANK_HOUSE_NUMBER_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_BANK_HOUSE_NUMBER_MAX_VALIDATION'),
          postcode: Yup.string().max(16, 'SUPPLIERS_BANK_POSTCODE_MAX_VALIDATION'),
          state: Yup.string().max(40, 'SUPPLIERS_BANK_STATE_MAX_VALIDATION'),
          country: Yup.string().max(255),
          // .required('SUPPLIERS_COUNTRY_REQUIRED'),
          city: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: Yup.string().required('SUPPLIERS_CITY_REQUIRED')
            })
            .max(40, 'SUPPLIERS_CITY_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_CITY_MAX_VALIDATION'),
          postalCode: Yup.string().max(16, 'SUPPLIERS_POSTAL_CODE_MAX_VALIDATION'),
          address1: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: Yup.string().required('SUPPLIERS_ADDRESS1_REQUIRED')
            })
            .max(128, 'SUPPLIERS_ADDRESS1_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_ADDRESS1_MAX_VALIDATION'),
          address2: Yup.string().max(128, 'SUPPLIERS_ADDRESS2_MAX_VALIDATION'),
          // poBox: Yup.string().max(255).required('poBox is required'),
          // poBoxPostalCode: Yup.string().max(255).required('poBoxPostalCode is required'),
          // poBoxCity: Yup.string().max(255).required('poBoxCity is required'),
          contactPersonName: Yup.string().max(128, 'SUPPLIERS_CONTACT_PERSON_MAX_VALIDATION'),
          telephoneNumber: Yup.string().max(25, 'SUPPLIERS_TELEPHONE_NUMBER_MAX_VALIDATION'),
          faxNumber: Yup.string().max(25, 'SUPPLIERS_FAX_NUMBER_MAX_VALIDATION'),
          mobileTelNumber: Yup.string().max(128, 'SUPPLIERS_MOBILE_TEL_NUMBER_MAX_VALIDATION'),
          emailAddress: Yup.string().max(200, 'SUPPLIERS_EMAIL_ADDRESS_MAX_VALIDATION'),
          discountDays: Yup.number()
            .integer('SUPPLIERS_DISCOUNT_DAYS_TYPE_VALIDATION')
            .typeError('SUPPLIERS_DISCOUNT_DAYS_TYPE_VALIDATION'),
          discountPercentage: Yup.number().typeError('SUPPLIERS_DISCOUNT_PERCENTAGE_TYPE_VALIDATION'),
          custom1: Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_01_MAX_VALIDATION'),
          custom2: Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_02_MAX_VALIDATION'),
          custom3: Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_03_MAX_VALIDATION'),
        })}
        onSubmit={async (values, {
          resetForm,
          setErrors,
          setStatus,
          setSubmitting
        }) => {
          try {
            if (!authService.validateToken()) {
              enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                variant: 'error',
              });
              setTimeout(() => {
                authService.logout(LOGIN_PANEL_URL);
              }, 2000);
              return;
            }

            const body = {
              skipSupplierReview: values.skipSupplierReview || false,
              creditorCode: values.creditorCode,
              creditorName: values.creditorName,
              fiscalName: values.fiscalName,
              autoPaymentStatus: values.autoPaymentStatus,
              dueDays: values.dueDays,
              website: values.website,
              comment: values.comment,
              companyRegNumber: values.companyRegNumber,
              vatNumber: values.vatNumber,
              referenceCode: values.referenceCode,
              status: values.status,
              generalLedgerAccount: (values.generalLedgerAccount === supplierData.defaultGLA[0].code && isTwinfield)
                ? '1600'
                : values.generalLedgerAccount === supplierData.defaultGLA[0].code
                  ? ''
                  : values.generalLedgerAccount,
              generalLedgerAccLine: (values.generalLedgerAccLine === supplierData.defaultGLA[0].code && isTwinfield)
                ? '1600'
                : values.generalLedgerAccLine === supplierData.defaultGLA[0].code
                  ? ''
                  : values.generalLedgerAccLine,
              vatGroup: values.vatGroup === supplierData.defaultVatGroup[0].code ? '' : values.vatGroup,
              vatGroupLine: values.vatGroupLine === supplierData.defaultVatGroup[0].code ? '' : values.vatGroupLine,
              costCenter: values.costCenter === supplierData.defaultCostCenters[0].code ? '' : values.costCenter,
              project: values.project === supplierData.defaultProjects[0].code ? '' : values.project,
              invoiceDescriptionRule: values.invoiceDescriptionRule,
              invoiceLineDescriptionRule: values.invoiceLineDescriptionRule,
              defaultCurrency: values.defaultCurrency,
              bankCountry: values.bankCountry,
              ascription: values.ascription,
              iban: values.iban,
              bankAccNumber: values.bankAccNumber,
              bicSwift: values.bicSwift,
              nationalBankCode: values.nationalBankCode,
              bankName: values.bankName,
              bankCity: values.bankCity,
              bankStreet: values.bankStreet,
              bankHouseNumber: values.bankHouseNumber,
              country: values.country,
              city: values.city,
              postalCode: values.postalCode,
              address1: values.address1,
              address2: values.address2,
              poBox: values.poBox,
              poBoxPostalCode: values.poBoxPostalCode,
              poBoxCity: values.poBoxCity,
              contactPersonName: values.contactPersonName,
              telephoneNumber: values.telephoneNumber,
              faxNumber: values.faxNumber,
              mobileTelNumber: values.mobileTelNumber,
              emailAddress: values.emailAddress,
              postcode: values.postcode,
              state: values.state,
              addressName: values.addressName,
              shortname: values.shortname,
              discountDays: values.discountDays,
              discountPercentage: values.discountPercentage,
              matchType: values.matchType,
              custom1: values.custom1,
              custom2: values.custom2,
              custom3: values.custom3,
              inventories: supplierProducts
            };

            // Validate DueDays and DiscountDays
            const dueDays = parseInt(values.dueDays, 10);
            const discountDays = parseInt(values.discountDays, 10);
            if (dueDays > 0 && discountDays > 0 && dueDays < discountDays) {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors({
                discountDays: 'SUPPLIERS_DISCOUNT_DAYS_DUEDAYS_VALIDATION'
              });
              return;
            }

            const response = await Axios.put(
              `${API.suppliers}`,
              body,
              axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
            );
            if (response.data.success) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar(ready && t('SUPPLIERS_UPDATE_SUCCESS'), {
                variant: 'success',
                style: { maxWidth: 400 }
              });
              getAllSuppliers();
              handleEditSupplierClose();
            }
          } catch (error) {
            setStatus({ success: false });
            setSubmitting(false);
            if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
              const errParts = error.response.data.i18n.split('__TFMSG__');
              setErrors({
                [errParts[0]]: errParts[1]
              });
            } else {
              setErrors({
                submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
              });
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          user.interfacePreference === 'twinfield_interface' ? (
            <EditSupplierTwinfield
              isInvoiceEditForm={isInvoiceEditForm}
              hasError={hasError}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              handleEditSupplierClose={handleEditSupplierClose}
              handleChange={handleChange}
              handleChangeAutocomplete={handleChangeAutocomplete}
              getLabel={getLabel}
              handleEditGLA={handleEditGLA}
              handleAddGLA={handleAddGLA}
              glAccounts={glAccounts}
              handleEditVATCode={handleEditVATCode}
              handleAddVATCode={handleAddVATCode}
              vatGroups={vatGroups}
              handleEditCostCenter={handleEditCostCenter}
              handleAddCostCenter={handleAddCostCenter}
              costCenters={costCenters}
              projects={projects}
              currencies={currencies}
              countries={countriesList}
              xmlSelectedValue={xmlSelectedValue}
              textFieldSelectedKey={textFieldSelectedKey}
              textFieldSelectedValueRef={textFieldSelectedValueRef}
              setTextFieldSelectedKey={setTextFieldSelectedKey}
              setTextFieldSelectedValue={setTextFieldSelectedValue}
              newlyAddedVal={newlyAddedVal}
              newlyAddingProp={newlyAddingProp}
            />
          ) : (
            <EditSupplierStandard
              isInvoiceEditForm={isInvoiceEditForm}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              handleEditSupplierClose={handleEditSupplierClose}
              handleChange={handleChange}
              handleChangeAutocomplete={handleChangeAutocomplete}
              getLabel={getLabel}
              handleEditGLA={handleEditGLA}
              handleAddGLA={handleAddGLA}
              glAccounts={glAccounts}
              handleEditVATCode={handleEditVATCode}
              handleAddVATCode={handleAddVATCode}
              vatGroups={vatGroups}
              handleEditCostCenter={handleEditCostCenter}
              handleAddCostCenter={handleAddCostCenter}
              costCenters={costCenters}
              projects={projects}
              currencies={currencies}
              countries={countriesList}
              supplierFields={supplierFields}
              preferredFieldsLoading={preferredFieldsLoading}
              setShowManageFields={setShowManageFields}
              xmlSelectedValue={xmlSelectedValue}
              textFieldSelectedKey={textFieldSelectedKey}
              textFieldSelectedValueRef={textFieldSelectedValueRef}
              setTextFieldSelectedKey={setTextFieldSelectedKey}
              setTextFieldSelectedValue={setTextFieldSelectedValue}
              newlyAddedVal={newlyAddedVal}
              newlyAddingProp={newlyAddingProp}
              products={supplierProducts}
              updateProducts={updateSupplierProducts}
              supplier={supplier}
            />
          ))}
      </Formik>
      <ManageFields
        open={showManageFields}
        entity="supplier"
        fields={supplierFields}
        fetchFields={getSupplierFields}
        closeModal={handleCloseManageFields}
        parameterCompany={user.companyID}
      />
      <GLAccountModal
        gLAccountModalOpen={gLAccountModalOpen}
        handleClose={onGLAModalClose}
        getAllGLAccounts={getAllGLAccounts}
        getVatCodes={getAllVatGroups}
        onSuccess={onGLACreated}
        selectedGLA={selectedGLA}
        glaYears={glaYears}
        glaPeriods={glaPeriods}
      />
      <VatCodeModal
        vatCodeModalOpen={vatCodeModalOpen}
        handleClose={onVatCoddeModalClose}
        getAllVatCodes={getAllVatGroups}
        onSuccess={onVatCodeCreated}
        selectedVatCode={selectedVATGroupCode}
      />
      <CostCenterModal
        costCenterModalOpen={ccModalOpen}
        handleClose={onCCModalClose}
        getAllCostCenters={getAllCostCenters}
        onSuccess={onCCCreated}
        selectedCostCenter={selectedCostCenterCode}
      />
    </>
  );
}

// EditSupplierForm.propTypes = {
//   className: PropTypes.string,
//   history: PropTypes.shape({
//     push: PropTypes.func
//   }),
//   user: PropTypes.object,
//   supplier: PropTypes.shape({
//     skipSupplierReview: PropTypes.bool,
//     creditorCode: PropTypes.string,
//     creditorName: PropTypes.string,
//     fiscalName: PropTypes.string,
//     autoPaymentStatus: PropTypes.string,
//     dueDays: PropTypes.string,
//     website: PropTypes.string,
//     comment: PropTypes.string,
//     companyRegNumber: PropTypes.string,
//     vatNumber: PropTypes.string,
//     referenceCode: PropTypes.string,
//     status: PropTypes.string,
//     generalLedgerAccount: PropTypes.string,
//     generalLedgerAccLine: PropTypes.string,
//     vatGroup: PropTypes.string,
//     vatGroupLine: PropTypes.string,
//     costCenter: PropTypes.string,
//     project: PropTypes.string,
//     invoiceDescriptionRule: PropTypes.string,
//     invoiceLineDescriptionRule: PropTypes.string,
//     defaultCurrency: PropTypes.string,
//     bankCountry: PropTypes.string,
//     ascription: PropTypes.string,
//     iban: PropTypes.string,
//     bankAccNumber: PropTypes.string,
//     bicSwift: PropTypes.string,
//     nationalBankCode: PropTypes.string,
//     bankName: PropTypes.string,
//     bankCity: PropTypes.string,
//     bankStreet: PropTypes.string,
//     bankHouseNumber: PropTypes.string,
//     country: PropTypes.string,
//     city: PropTypes.string,
//     postalCode: PropTypes.string,
//     address1: PropTypes.string,
//     address2: PropTypes.string,
//     poBox: PropTypes.string,
//     poBoxPostalCode: PropTypes.string,
//     poBoxCity: PropTypes.string,
//     contactPersonName: PropTypes.string,
//     telephoneNumber: PropTypes.string,
//     faxNumber: PropTypes.string,
//     mobileTelNumber: PropTypes.string,
//     emailAddress: PropTypes.string,
//     postcode: PropTypes.string,
//     state: PropTypes.string,
//     addressName: PropTypes.string,
//     shortname: PropTypes.string,
//     discountDays: PropTypes.string,
//     discountPercentage: PropTypes.string,
//     matchType: PropTypes.string,
//     custom1: PropTypes.string,
//     custom2: PropTypes.string,
//     custom3: PropTypes.string,
//     inventories: PropTypes.array
//   }),
//   handleEditSupplierClose: PropTypes.func,
//   getAllSuppliers: PropTypes.func,
//   isInvoiceEditForm: PropTypes.bool,
//   xmlSelectedValue: PropTypes.string,
//   textFieldSelectedKey: PropTypes.string,
//   textFieldSelectedValueRef: PropTypes.object,
//   setTextFieldSelectedKey: PropTypes.func,
//   setTextFieldSelectedValue: PropTypes.func,
// };

export default EditSupplierForm;
