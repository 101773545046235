/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Button, Typography, SvgIcon, Grid, Tooltip, useMediaQuery, TextField, InputAdornment, MenuItem, Menu
} from '@mui/material';
import {
  Search as SearchIcon,
  CalendarToday as CalendarIcon,
} from '@mui/icons-material';

import ConfigContext from 'src/contexts/ConfigContext';
import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders } from 'src/utils/helpers';
import { filterTimePeriod, recentFilterOptions } from 'src/config';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import PartnerStatsTable from 'src/account/components/PartnerStatsTable/PartnerStatsTable';
import CustomPeriodFilter from 'src/shared/components/CustomPeriodFilter/CustomPeriodFilter';
import theme from 'src/theme';
import styles from './style';

function PartnerStats() {
  const { t, ready } = useTranslation();

  const { API } = useContext(ConfigContext);
  const auth = useAuth();
  const {user} = auth;

  const [loading, setLoading] = useState(true);
  const [partnerStats, setPartnerStats] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [total, setTotal] = useState(0);
  const [start, setStart] = useState((new Date(0).getTime() * 1000000).toString());
  const [end, setEnd] = useState((new Date().getTime() * 1000000).toString());
  const [time, setTime] = useState('all');

  const maxWidth = useMediaQuery('(max-width: 480px)');
  const minWidth = useMediaQuery('(min-width: 730px)');
  const maxWidthRows = useMediaQuery('(max-width: 635px)');

  const [anchorElPeriod, setAnchorElPeriod] = useState(null);
  const [anchorElCustomPeriod, setAnchorElCustomPeriod] = useState(null);

  const getPartnerStats = async (pageNo = page, pageLimit = limit, search = query, startTimeStamp = start, endTimeStamp = end) => {
    setLoading(true);
    try {
      const url = `${API.partnerStats}?page=${pageNo + 1}&limit=${pageLimit}&search=${search}`;
      const body = {
        start: startTimeStamp,
        end: endTimeStamp
      };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data.stats) {
        setPartnerStats(response.data.data.stats);
        setTotal(response.data.total || response.data.data.stats.length);
      } else {
        setPartnerStats([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.email) {
      getPartnerStats();
    }
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    getPartnerStats(newPage, limit, query);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
    getPartnerStats(0, event.target.value, query);
  };

  const handleQueryChange = (e) => {
    e.persist();
    setQuery(e.target.value);
    getPartnerStats(0, limit, e.target.value);
  };

  const handlePeriodClick = (event) => {
    setAnchorElPeriod(event.currentTarget);
  };

  const handlePeriodClose = () => {
    setAnchorElPeriod(null);
  };

  const filterSelected = (index) => {
    for (let i = 0; i < filterTimePeriod.length; i++) {
      if (filterTimePeriod[i].value === time && filterTimePeriod[i].index === index) {
        return true;
      }
    }

    return false;
  };

  const handleCustomPeriodClick = () => {
    setAnchorElCustomPeriod(document.getElementById('period-filter'));
  };

  const handleCustomPeriodClose = () => {
    setAnchorElCustomPeriod(null);
  };

  const handleCustomPeriodApply = (from, to) => {
    const fromTimeStamp = Math.floor(new Date(from).getTime() * 1000000).toString();
    const toTimeStamp = Math.floor(new Date(to).getTime() * 1000000).toString();
    setStart(fromTimeStamp);
    setEnd(toTimeStamp);
    setTime(filterTimePeriod.find((f) => f.index === 99).value);

    getPartnerStats(0, limit, query, fromTimeStamp, toTimeStamp);

    handleCustomPeriodClose();
  };

  const getPeriodTimestamps = (option) => {
    const currentDate = new Date();
    let startDate;
    let endDate;

    switch (option) {
      case 1:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (7 - currentDate.getDay()));
        break;
      case 2:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() - 7);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
        break;
      case 3:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        break;
      case 4:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        break;
      case 5:
        startDate = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3, 1);
        endDate = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3 + 3, 1);
        break;
      case 6:
        startDate = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3 - 3, 1);
        endDate = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3, 1);
        break;
      case 7:
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date(currentDate.getFullYear(), 12, 1);
        break;
      case 8:
        startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        endDate = new Date(currentDate.getFullYear() - 1, 12, 1);
        break;
      default:
        startDate = new Date(0);
        endDate = currentDate;
        break;
    }

    return { startTimestamp: (startDate.getTime() * 1000000).toString(), endTimestamp: (endDate.getTime() * 1000000).toString() };
  };

  const handleRecentFilterChange = (filter) => {
    if (filter === 99) {
      handleCustomPeriodClick();
      handlePeriodClose();
      return;
    }

    const { startTimestamp, endTimestamp } = getPeriodTimestamps(filter);
    setStart(startTimestamp);
    setEnd(endTimestamp);

    filterTimePeriod.forEach((item) => {
      if (item.index === filter) {
        setTime(item.value);
        getPartnerStats(0, limit, query, startTimestamp, endTimestamp);
      }
    });

    handlePeriodClose();
  };

  const titleText = (
    <Typography style={styles.headerTitle}>
      {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS')}
    </Typography>
  );

  const searchBar = (
    <Grid className="search-bar" style={styles.searchBar}>
      <Tooltip title={t('ACCOUNT_SETTINGS_PARTNER_STATS_SEARCH')}>
        <TextField
          style={styles.queryField}
          sx={{ '&.MuiTextFieldRoot': styles.searchBarInput }}
          onChange={handleQueryChange}
          placeholder={ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: theme.palette.icon.gray }}
                >
                  <SearchIcon style={{ color: theme.palette.icon.gray }} />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </Grid>
  );

  const periodFilter = (
    <Grid>
      <Button
        id="period-filter"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handlePeriodClick}
        style={time !== 'all' ? styles.filterBtnSelected : styles.filterBtn}
      >
        <SvgIcon style={{ marginRight: 8, fontSize: 24 }}>
          <CalendarIcon style={styles.filterIcon} />
        </SvgIcon>
        {ready && t('DASHBOARD_INVOICES_PERIOD')}
      </Button>
      <Menu
        id="period-simple-menu"
        anchorEl={anchorElPeriod}
        elevation={0}
        getcontentanchorel={null}
        keepMounted
        open={Boolean(anchorElPeriod)}
        onClose={handlePeriodClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          ".MuiMenu-paper": styles.filterMenu,
        }}
      >
        {
          [...recentFilterOptions, {
            value: 99,
            label: 'DASHBOARD_INVOICES_PERIOD_CUSTOM'
          }].map((o) => (
            <MenuItem
              key={o.value}
              style={{ color: filterSelected(o.value) ? theme.palette.secondary.main : '#192A3E', ...styles.filterMenuItem }}
              onClick={() => handleRecentFilterChange(o.value)}
            >
              {t(o.label)}
            </MenuItem>
          ))
        }
      </Menu>
      <CustomPeriodFilter
        anchorElCustomPeriod={anchorElCustomPeriod}
        handleCustomPeriodClose={handleCustomPeriodClose}
        handleCustomPeriodApply={handleCustomPeriodApply}
      />
    </Grid>
  );

  return (
    <>
      <Grid style={styles.header}>
        {
          minWidth ? (
            <Grid container style={styles.header}>
              {titleText}
              {searchBar}
              {periodFilter}
            </Grid>
          ) : (
            <Grid container style={styles.headerColumn}>
              {titleText}
              {searchBar}
              {periodFilter}
            </Grid>
          )
        }
      </Grid>
      {
        (partnerStats && partnerStats.length > 0) || loading
          ? (
            <>
              <PartnerStatsTable
                loading={loading}
                stats={partnerStats}
                maxWidth={maxWidth}
                total={total}
                page={page}
                limit={limit}
                maxWidthRows={maxWidthRows}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
              />
              <span style={styles.captionTextContainer}>
                <Typography style={styles.captionText} variant="caption">
                  {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_NOTE')}
                </Typography>
                <Typography style={styles.captionText} variant="caption">
                  {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_NOTE_2')}
                </Typography>
              </span>
            </>
          )
          : <EmptyText str="ACCOUNT_SETTINGS_PARTNER_STATS_NO_DATA" />
      }
    </>
  );
}

export default PartnerStats;
