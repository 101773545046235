/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 15c-.458 0-.85-.163-1.177-.49a1.606 1.606 0 01-.49-1.177v-10c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49H15c.458 0 .85.164 1.177.49.327.327.49.719.49 1.177v10c0 .459-.163.851-.49 1.178-.326.326-.719.489-1.177.489H7.5zm-3.333 3.333c-.459 0-.851-.163-1.178-.489a1.607 1.607 0 01-.489-1.177V5.833c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.595.24c.159.16.239.357.239.593v10.834H12.5c.236 0 .434.08.594.24.16.16.24.357.24.593s-.08.434-.24.593a.807.807 0 01-.594.24H4.167z"
        fill="#F2994A"
      />
    </svg>
  )
}

export default SvgComponent