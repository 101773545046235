import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import theme from 'src/theme';

function EmptyText({ str }) {
  const { t, ready } = useTranslation();

  return (
    <div style={styles.root}>
      <Box width={800}>
        <Typography
          align="center"
          variant="subtitle1"
        >
          {ready && t(str)}
        </Typography>
      </Box>
    </div>
  );
}

export default EmptyText;

const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}
