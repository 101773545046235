const styles = {
  root:{
    display:'flex',
    minWidth:'485px',
    maxWidth:'485px',
    padding:'16px 20px',
    borderRadius:'4px',
    backgroundColor:'#596274',
    boxShadow: '0px 6px 18px -8px #4C4E648F',
    gap:'24px',
    justifyContent:'space-between',
    '& > div':{
      cursor:'pointer',
    }
  },
  range:{
    color:'#fff',
    display:'flex',
    alignItems:'center',
    width:'185px',
    gap:'8px',
    '& > input[type="range"]':{
      accentColor: 'white',
      WebkitAppearance: 'nine',
      width: '115px',
      height: '5px',
      background: 'red',
      borderRadius: '5px',
    },
  },
  zoom:{
    color:'#fff',
    fontSize:'14px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'35px'
  },
  iconContainer:{
    padding:'0',
    margin:'0',
    width:'fit-content',
    backgroundColor:'transparent',
    color:'#fff',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  icon:{
    width:'24px',
    height:'24px'
  },
  pages:{
    display:'flex',
    fontSize:'14px',
    alignItems:'center',
    gap:'2px',
    color:'#fff',
  },
  pageInput:{
    width:'17px',
    maxWidth:'25px',
    minWidth: '20px',
    border:'none',
    outline:'none',
    backgroundColor:'#777F8D',
    fontSize:'14px',
    borderRadius:'2px',
  },
  pageSpan:{
    width:'17px',
    color:'#fff',
    fontSize:'14px'
  }
}

export default styles;