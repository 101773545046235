import theme from 'src/theme';

const styles = {
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 10px',
    margin: '10px 0px 0px 0px'
  },
  text: {
    fontSize: '13px',
    color: theme.palette.text.primary,
    lineHeight: '32px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  cancelButtonSup: {
    width: '40%',
    height: 32,
    textTransform: 'none',
    color: theme.palette.text.primary,
    marginLeft: 10,
    border: theme.palette.border.cancel,
  },
  applyButtonSup: {
    width: '40%',
    height: 32,
    textTransform: 'none',
    marginLeft: 10,
    backgroundColor: theme.palette.button.action,
  },
};

export default styles;
