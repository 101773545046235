import { useRef, useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory, useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'src/hooks/useAuth';
import authService from 'src/utils/authService';
import ConfigContext from 'src/contexts/ConfigContext';
import {
  appendContactSupport, axiosDeleteHeaders, axiosHeaders, getLocalisedErrorString, sendFeedback,
} from 'src/utils/helpers';
import Page from 'src/shared/screens/Page';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import UserCreateForm from 'src/account/components/UserCreateForm/UserCreateForm';
import InvoiceEditForm from 'src/invoice-edit/components/InvoiceEditForm/InvoiceEditForm';
import ChooseValidatorModal from 'src/documents/components/ChooseValidatorModal/ChooseValidatorModal';
import DeleteDocumentModal from 'src/documents/components/DeleteDocumentModal/DeleteDocumentModal';
import DocumentHeader from 'src/invoice-edit/components/DocumentHeader/DocumentHeader';
import styles from './style';

function InvoiceEdit() {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const match = useRouteMatch();

  const {API, LOGIN_PANEL_URL, BRAND_NAME} = useContext(ConfigContext);
  const { user, setUser } = useAuth();

  const [invoice, setInvoice] = useState(null);
  const [users, setUsers] = useState([]);
  const [xml, setXml] = useState(null);
  const [openDeleteConf, setOpenDeleteConf] = useState(false);
  const [invLoaded, setInvLoaded] = useState(false);
  const [xmlLoaded, setXmlLoaded] = useState(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const [usersMenuOpen, setUsersMenuOpen] = useState(false);
  const [usersMenuAnchor, setUsersMenuAnchor] = useState(null);
  const [chooseValidatorOpen, setChooseValidatorOpen] = useState(false);
  const [validatorOptionsOpen, setValidatorOptionsOpen] = useState(false);
  const [selectedValidator, setSelectedValidator] = useState({});
  const [newlyAddedUser, setNewlyAddedUser] = useState(null);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [previousInvoice, setPreviousInvoice] = useState(null);
  const [nextInvoice, setNextInvoice] = useState(null);
  const [isSupplierLabelWithVAT, setIsSupplierLabelWithVAT] = useState(false);
  const [isToValidateStatusAllowed, setIsToValidateStatusAllowed] = useState(false);
  const [isAutoNavigationAllowed, setIsAutoNavigationAllowed] = useState(false);
  const [customReceivers, setCustomReceivers] = useState([]);
  const [parameterCompany] = useState(user.companyID);
  const [parameterDocCompany, setParameterDocCompany] = useState(user.companyID);

  const statusMenuRef = useRef(null);
  const validatorOptionsRef = useRef(null);
  const statusChangedRef = useRef(null);

  useEffect(() => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setIsSupplierLabelWithVAT(user.customisations.includes('supplierLabelWithVAT'));
    setIsToValidateStatusAllowed(user.customisations.includes('toValidateStatus'));
    setIsAutoNavigationAllowed(user.customisations.includes('autoNavigation'));
    
    statusChangedRef.current = false;
    loadData();
  }, []);

  const updateSelectedValidator = (newValidator) => {
    setSelectedValidator(newValidator);
    setNewlyAddedUser(null);
  };

  const setStatusChangedRef = (val) => {
    statusChangedRef.current = val;
  };

  const loadData = async () => {
    getXML();
    await getInvoice();
    fetchPrevAndNext();

    if (user.customisations.includes('receiverVatLimitedOptions')) {
      await getCustomReceivers();
    }

    await getUsers();
  }

  const getInvoice = async () => {
    const id = match && match.params ? match.params.id : 0;
    try {
      const resp = await Axios.get(
        `${API.getInvoiceByID}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        setParameterDocCompany(resp.data.data.company);

        setInvoice(resp.data.data);
        setInvLoaded(true);

        if (id !== resp.data?.data?.assistantID?.toString()) {
          window.location = window.location.pathname.replace(id, resp.data.data.assistantID);
          return;
        }

        if (resp.data.data.status === 500) {
          sendFeedback(API.feedbackLogs, 'work with invoice', user.email, user.companyID);
        }
      } else {
        history.push('/documents');
      }
    } catch (error) {
      if (error?.response?.data?.i18n) {
        enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
          variant: 'error',
        });
      }
      history.push('/documents');
    }
  };

  const getInvoiceAfterStatusChange = async () => {
    const id = match && match.params ? match.params.id : 0;
    try {
      const resp = await Axios.get(
        `${API.getInvoiceByID}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        const newInvoice = resp.data.data;
        setInvoice({
          ...invoice,
          status: newInvoice.status,
          owner: newInvoice.owner,
          ownerName: newInvoice.ownerName,
          exportFailReason: newInvoice.exportFailReason,
        });
      } else {
        history.push('/documents');
      }
    } catch (error) {
      if (error?.response?.data?.i18n) {
        enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
          variant: 'error',
        });
      }
      history.push('/documents');
    }
  };

  const getXML = async () => {
    const id = match && match.params ? match.params.id : 0;
    try {
      const xmlPage = await Axios.get(
        `${API.getXmlInvoice}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (xmlPage.data.success && xmlPage.data.data) {
        setXml(xmlPage.data.data.xml);
        setXmlLoaded(true);
      } else {
        setXml(false);
        setXmlLoaded(true);
      }
    } catch (error) {
      setXml(false);
      setXmlLoaded(true);
    }
  };

  const getCustomReceivers = async () => {
    try {
      const response = await Axios.get(
        API.fieldsCustomReceivers,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCustomReceivers(response.data.data);
        }
      }
    } catch (error) {
      setCustomReceivers([]);
    }
  };

  const getUsers = async () => {
    try {
      const url = `${API.getUsersByCompanies}${encodeURIComponent(user.companyID)}?permission=invoice_validate`;
      const resp = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (resp.data.success) {
        setUsers(resp.data.data);
        const newUser = resp.data.data?.find((u) => u.email === newlyAddedUser?.email);
        const invoiceOwner = resp.data.data?.find((u) => u.email === invoice?.owner);
        if (newlyAddedUser && newUser) {
          updateSelectedValidator(newUser);
        } else if (invoiceOwner) {
          updateSelectedValidator(invoiceOwner);
        } else {
          updateSelectedValidator(resp.data.data[0]);
        }
      }
    } catch (error) {
      //
    }
  };

  const getAllUsers = async (user) => {
    setNewlyAddedUser(user);
    if (users.length === 1) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('feedback-first-user', 'success');
      history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
    }
    await getUsers();
  };

  const fetchPrevAndNext = async () => {
    const queryVal = new URLSearchParams(history.location.search);
    const invoicePage = queryVal.get('document_page');
    const total = queryVal.get('total');
    if (invoicePage) {
      const currentInvoice = parseInt(invoicePage, 10);
      if (currentInvoice > 1) {
        const previousInvoice = await getOtherInvoice(currentInvoice - 1);
        setPreviousInvoice(previousInvoice);
      }
      if (currentInvoice < total) {
        const nextInvoice = await getOtherInvoice(currentInvoice + 1);
        setNextInvoice(nextInvoice);
      }
    }
  };

  const getOtherInvoice = async (invoicePage) => {
    const queryVal = new URLSearchParams(history.location.search);
    const thisSearch = queryVal.get('query');
    const thisFilter = queryVal.get('status');
    const thisTime = queryVal.get('time');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter'));
    const suppliersList = JSON.parse(window.sessionStorage.getItem('selectedSuppliers'));
    const ownerFilter = JSON.parse(queryVal.get('owner_filter'));
    const ownersList = JSON.parse(window.sessionStorage.getItem('selectedOwners'));
    const companyFilter = JSON.parse(queryVal.get('company_filter'));
    const companiesList = JSON.parse(window.sessionStorage.getItem('selectedCompanies'));
    const thisStartTime = queryVal.get('start_time');
    const thisEndTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export'));
    const sortBy = queryVal.get('sort_by');
    const order = queryVal.get('order');
    let thisSuppliers = '';
    if (supplierFilter && Array.isArray(suppliersList)) {
      thisSuppliers = suppliersList.map((supplier) => supplier.creditorCode).join(',');
    } else if (supplierFilter && suppliersList) {
      thisSuppliers = suppliersList;
    }
    let thisOwners = '';
    if (ownerFilter && Array.isArray(ownersList)) {
      thisOwners = ownersList.map((owner) => owner.email).join(',');
    } else if (ownerFilter && ownersList) {
      thisOwners = ownersList;
    }
    let thisCompanies = [];
    if (companyFilter && companiesList.length > 0 && companiesList[0].companyId) {
      thisCompanies = companiesList.map((company) => company.companyId);
    } else if (companyFilter && companiesList.length > 0) {
      thisCompanies = companiesList;
    }
    try {
      let url = `${API.getAllInvoices}/${encodeURIComponent(user.companyID)}?page=${invoicePage}&limit=1${thisSearch !== null
        ? `&search=${thisSearch}` : ''}&order=${sortBy
        ? `${order}&sortby=${sortBy}` : 'desc'}${thisFilter !== null
        ? `&filter=${thisFilter}` : ''}${failedToExport
        ? '&failedToExport=true' : ''}`;
      if (thisStartTime !== null && thisEndTime !== null) {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime !== null ? `&time=${thisTime}` : ''}`;
      }
      const body = { suppliers: thisSuppliers, owners: thisOwners, companies: thisCompanies };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data.length > 0) {
        return response.data.data[0];
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const gotoInvoiceList = () => {
    history.push(`/documents${history.location.search}`);
  };

  const handleUserMenuOpen = (event) => {
    setUsersMenuOpen(true);
    setUsersMenuAnchor(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUsersMenuOpen(false);
    setUsersMenuAnchor(null);
  };

  const handleChooseValidatorModalOpen = () => {
    setChooseValidatorOpen(!chooseValidatorOpen);
  };

  const handleValidatorOptionsToggle = () => {
    setValidatorOptionsOpen(!validatorOptionsOpen);
  };

  const handleValidatorOptionsClose = (event) => {
    if (validatorOptionsRef.current && validatorOptionsRef.current.contains(event.target)) {
      return;
    }
    setValidatorOptionsOpen(false);
  };

  const handleValidatorClick = (validator) => {
    setSelectedValidator(validator);
    setValidatorOptionsOpen(false);
  };

  const handleAddUserClick = () => {
    if (user.usersCount < user.maxUsers) {
      setAddUserOpen(true);
    }
  }

  const onSendToValidate = () => {
    handleStatusChange(501, invoice.assistantID);
  };

  const navigateInvoice = (navTo, increment) => {
    const statusChanged = statusChangedRef.current;
    const queryVal = new URLSearchParams(history.location.search);
    const viewJira = queryVal.get('viewJira');
    const status = queryVal.get('status');
    const filters = queryVal.get('filters');
    const query = queryVal.get('query');
    const time = queryVal.get('time');
    const thisPage = queryVal.get('page');
    const limit = queryVal.get('limit');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter'));
    const ownerFilter = JSON.parse(queryVal.get('owner_filter'));
    const companyFilter = JSON.parse(queryVal.get('company_filter'));
    const startTime = queryVal.get('start_time');
    const endTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export'));
    const documentsSelected = JSON.parse(queryVal.get('documents_selected'));
    const invoicePage = JSON.parse(queryVal.get('document_page')) + increment + (increment > 0 && statusChanged && status !== 'INVOICE_STATUS_ALL' ? -1 : 0);
    const total = JSON.parse(queryVal.get('total')) + (statusChanged && status !== 'INVOICE_STATUS_ALL' ? -1 : 0);
    history.push(
      `/doc/${navTo.assistantID}/edit?${viewJira !== null ? `viewJira=${viewJira}` : ''}${status !== null
        ? `&status=${status}` : ''}${filters !== null ? `&filters=${filters}` : ''}${query !== null
        ? `&query=${query}` : ''}${time !== null ? `&time=${time}` : ''}${thisPage !== null
        ? `&page=${thisPage}` : ''}${limit !== null ? `&limit=${limit}` : ''}${supplierFilter
        ? `&supplier_filter=${supplierFilter}` : ''}${companyFilter ? `&company_filter=${companyFilter}` : ''}${ownerFilter
        ? `&owner_filter=${ownerFilter}` : ''}${startTime !== null ? `&start_time=${startTime}` : ''}${endTime !== null
        ? `&end_time=${endTime}` : ''}${failedToExport ? '&failed_to_export=true' : ''}${documentsSelected
        ? '&documents_selected=true' : ''}&document_page=${invoicePage}&total=${total}`
    );
    history.go(0);
  };

  const handleStatusChange = async (status, id, event) => {
    handleStatusMenuClose(event);

    if (status === 900) {
      setOpenDeleteConf(true);
      return;
    }

    if (status === 501 && !chooseValidatorOpen) {
      setChooseValidatorOpen(true);
      return;
    }
    if (chooseValidatorOpen) {
      setChooseValidatorOpen(false);
    }

    setStatusChangeLoading(true);

    try {
      const body = {
        assistantID: parseInt(id, 10),
        toStatus: status.toString()
      };

      if (status === 501 && selectedValidator && selectedValidator.email && selectedValidator.email !== invoice.owner) {
        body.assignee = selectedValidator.email;
      }

      const response = await Axios.post(
        API.documentStatus,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        await getInvoice();
        enqueueSnackbar(status === 501
          ? t('INVOICE_STATUS_CHANGE_TO_VALIDATE')
          : (user.isDocunecta && status === 503) ? t('DOCUNECTA_EXPORT_SUCCESS')
            : t('PROCYS_STATUS_CHANGE_SUCCESS'), {
          variant: 'success',
        });
        if (status === 501) {
          sendFeedback(API.feedbackLogs, 'send to validate', user.email, user.companyID);
        }
        if (status === 502) {
          sendFeedback(API.feedbackLogs, 'validate invoice', user.email, user.companyID);
        }
        if (status === 503) {
          sendFeedback(API.feedbackLogs, 'invoice export', user.email, user.companyID);
          const currentUrlParams = new URLSearchParams(window.location.search);
          if (!user.exported || user.exported === 0) {
            currentUrlParams.set('feedback-first-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
          if (user.exported === 9) {
            currentUrlParams.set('feedback-tenth-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
        }
        statusChangedRef.current = true;
        if (isAutoNavigationAllowed && nextInvoice) {
          navigateInvoice(nextInvoice, 1);
        }
      }

      setStatusChangeLoading(false);
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
        || appendContactSupport(window.config.support_email, t((user.isDocunecta && status === 503)
          ? 'DOCUNECTA_EXPORT_FAILURE' : 'INVOICE_STATUS_CHANGE_FAILED'), t),
      {
        variant: 'error',
      });
      getInvoiceAfterStatusChange();
      setStatusChangeLoading(false);
    }
  };

  const onDeleteInvoice = async () => {
    try {
      const body = {
        company: user.companyID,
        IDs: [invoice.id.toString()]
      };

      const response = await Axios.delete(
        API.updateInvoice,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body)
      );
      if (response.data.success) {
        enqueueSnackbar(t('INVOICE_DELETE_SUCCESS'), {
          variant: 'success',
        });
        history.push('/documents');
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('INVOICE_STATUS_CHANGE_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const onCancelDelete = () => {
    setOpenDeleteConf(false);
  };

  const handleStatusMenuToggle = () => {
    setStatusMenuOpen(!statusMenuOpen);
  };

  const handleStatusMenuClose = (event) => {
    if (statusMenuRef.current && statusMenuRef.current.contains(event?.target)) {
      return;
    }
    setStatusMenuOpen(false);
  };

  const handleChooseValidatorModalClose = () => {
    setChooseValidatorOpen(false);
  }

  const renderHeader = () => {
    return (
      <DocumentHeader
        invoice={invoice}
        statusMenuOpen={statusMenuOpen}
        users={users}
        statusChangeLoading={statusChangeLoading}
        isToValidateStatusAllowed={isToValidateStatusAllowed}
        gotoInvoiceList={gotoInvoiceList}
        statusMenuRef={statusMenuRef}
        handleStatusMenuToggle={handleStatusMenuToggle}
        handleStatusMenuClose={handleStatusMenuClose}
        handleStatusChange={handleStatusChange}
        handleUserMenuOpen={handleUserMenuOpen}
        usersMenuOpen={usersMenuOpen}
        usersMenuAnchor={usersMenuAnchor}
        handleUserMenuClose={handleUserMenuClose}
        getInvoice={getInvoice}
      />
    );
  };

  if (invLoaded) {
    return (
      <Page
        style={styles.root}
        title={ready && `${t('INVOICE_EDIT_PAGE_TITLE_XML')} | ${BRAND_NAME}`}
      >
        <InvoiceEditForm
          renderHeader={renderHeader}
          invoice={invoice}
          history={history}
          xml={xml}
          setInvoice={setInvoice}
          gotoInvoiceList={gotoInvoiceList}
          xmlReceived={xmlLoaded}
          getInvoice={getInvoice}
          users={users}
          handleSelectStatus={handleStatusChange}
          statusChangeLoading={statusChangeLoading}
          updateUser={setUser}
          handleChooseValidatorModalOpen={handleChooseValidatorModalOpen}
          isSupplierLabelWithVAT={isSupplierLabelWithVAT}
          isToValidateStatusAllowed={isToValidateStatusAllowed}
          isAutoNavigationAllowed={isAutoNavigationAllowed}
          previousInvoice={previousInvoice}
          nextInvoice={nextInvoice}
          navigateInvoice={navigateInvoice}
          parameterCompany={parameterCompany}
          parameterDocCompany={parameterDocCompany}
          setStatusChangedRef={setStatusChangedRef}
          customReceivers={customReceivers}
        />
        <ChooseValidatorModal
          chooseValidatorModalOpen={chooseValidatorOpen}
          handleChooseValidatorModalClose={handleChooseValidatorModalClose}
          validatorOptionsRef={validatorOptionsRef}
          handleValidatorOptionsToggle={handleValidatorOptionsToggle}
          selectedValidator={selectedValidator}
          validatorOptionsOpen={validatorOptionsOpen}
          handleValidatorOptionsClose={handleValidatorOptionsClose}
          users={users}
          handleValidatorClick={handleValidatorClick}
          handleAddUserClick={handleAddUserClick}
          statusChangeLoading={statusChangeLoading}
          onSendToValidate={onSendToValidate}
        />
        <DeleteDocumentModal
          openDeleteConf={openDeleteConf}
          onCancelDelete={onCancelDelete}
          onDeleteInvoice={onDeleteInvoice}
        />
        <UserCreateForm
          addUserOpen={addUserOpen}
          handleClose={() => setAddUserOpen(false)}
          onGetAllUsers={getAllUsers}
          user={user}
          updateUser={setUser}
        />
      </Page>
    );
  }
  return <LoadingScreen text={t('LOADING_PAGE_TEXT')} />;
  
}

export default InvoiceEdit;
