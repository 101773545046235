import theme from 'src/theme';

const styles = {
  root: {
    backgroundColor: '#f1f1f3',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxHeight: '100vh',
    minHeight: '100%',
    width:'100dvw'
  },
  documentDataContainer: {
    backgroundColor: theme.palette.background.default,
    width: '400px',
    paddingTop: '12px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    border: '1px solid #ffffff',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    overflow: 'hidden',
  },
  documentDisplayContainer: {
    flex: 1,
    height: 'calc(95vh + 12px)',
    width:'calc(100dvw - 465px)',
    backgroundColor: '#f1f1f3',
  },
  backdrop: {
    zIndex: 1111111,
    color: '#ffffff',
  },
}

export default styles;
