const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px',
    margin: '0px',
    backgroundColor: '#ffffff',
    borderTop: '1px #e4e4e4 solid',
    height: '66px'
  },
  saveButton: {
    height: '32px',
    minWidth: '32px',
    paddingTop: 0,
    paddingBottom: 0,
    padding: '5px',
    backgroundColor: '#FFFFFF',
    color: '#4C4E64',
    border: '1px #e4e4e4 solid',
    fontSize: '14px',
    textTransform: 'none' as const,
    '&:hover': {
      boxShadow: '0px 4px 10px #4C4E646B',
      border: '1px #e4e4e4 solid',
      backgroundColor: '#FFFFFF',
    },
    '&:disabled': {
      border: '1px #e4e4e4 solid',
    }
  },
  saveButtonIcon: {
    color: '#4C4E64',
    height: '20px',
    width: '20px'
  },
  sendToValidateButton: {
    height: '32px',
    marginLeft: '10px',
    backgroundColor: '#3E8AFF',
    fontSize: '14px',
    textTransform: 'none' as const,
    boxShadow: 'none',
    border: 'none',
    color: '#FFFFFF',
    '&:hover': {
      boxShadow: '0px 4px 10px #4C4E646B',
      border: 'none',
      backgroundColor: '#3E8AFF',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: '#3E8AFF',
      color: '#FFFFFF',
    }
  },
  arrowForwardIcon: {
    color: '#FFFFFF',
    height: '16px',
    width: '16px'
  },
  validateButton: {
    height: '32px',
    marginLeft: '8px',
    backgroundColor: '#3E8AFF',
    fontSize: '14px',
    textTransform: 'none' as const,
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
      boxShadow: '0px 4px 10px #4C4E646B',
      border: 'none',
      backgroundColor: '#3E8AFF',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: '#3E8AFF',
      color: '#FFFFFF',
    }
  },
  exportBtn: {
    width: '108px',
    height: '32px',
    marginLeft: '8px',
    backgroundColor: '#3E8AFF',
    borderWidth: '0px !important',
    fontSize: '14px',
    textTransform: 'none' as const,
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
      border: 'none',
      backgroundColor: '#3E8AFF',
      boxShadow: '0px 4px 10px #4C4E646B'
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: '#3E8AFF',
      color: '#FFFFFF',
    }
  },
  exportOptionBtn: {
    color: '#4C4E64',
    width: '100%',
    justifyContent: 'space-between',
    fontSize: '13px'
  },
  rejectBtn: {
    height: '32px',
    backgroundColor: '#FF4D4933',
    color: '#FF4D49',
    border: 'none',
    marginLeft: '8px',
    fontSize: '14px',
    textTransform: 'none' as const,
    '&:hover': {
      border: 'none',
      backgroundColor: '#FF4D4933',
      boxShadow: '0px 4px 10px #4C4E646B'
    },
    minWidth: '32px',
    padding: '5px',
    '&:disabled': {
      border: '1px #e4e4e4 solid',
    }
  },
  rejectIcon: {
    color: '#FF4D49',
    height: '20px',
    width: '20px'
  },
  sendToEmailBtn: {
    color: '#3E8AFF',
    backgroundColor: '#3E8AFF33',
    border: 'none',
    marginLeft: '8px',
    height: '32px',
    fontSize: '14px',
    boxShadow: 'none',
    textTransform: 'none' as const,
    '&:hover': {
      border: 'none',
      backgroundColor: '#3E8AFF33',
    },
    minWidth: '32px',
    padding: '5px',
    '&:disabled': {
      border: '1px #e4e4e4 solid',
      backgroundColor: '#3E8AFF33',
      color: '#3E8AFF',
      opacity: 0.5
    }
  },
  sendToEmailIcon: {
    color: '#3E8AFF',
    height: '20px',
    width: '20px'
  },
  archiveBtn: {
    color: '#4C4E64',
    backgroundColor: '#C2CFE099',
    border: 'none',
    marginLeft: '8px',
    height: '32px',
    fontSize: '14px',
    boxShadow: 'none',
    textTransform: 'none' as const,
    '&:hover': {
      border: 'none',
      backgroundColor: '#C2CFE099',
      boxShadow: '0px 4px 10px #4C4E646B'
    },
    minWidth: '32px',
    padding: '5px',
    '&:disabled': {
      border: '1px #e4e4e4 solid',
      color: '#4C4E64',
      backgroundColor: '#C2CFE099',
      opacity: 0.5
    }
  },
  archiveIcon: {
    color: '#4C4E64',
    height: '18px',
    width: '18px'
  },
  deleteBtn: {
    color: '#FF4D49',
    backgroundColor: '#FF4D4933',
    border: 'none',
    marginLeft: '8px',
    height: '32px',
    fontSize: '14px',
    boxShadow: 'none',
    textTransform: 'none' as const,
    '&:hover': {
      border: 'none',
      backgroundColor: '#FF4D4933',
      boxShadow: '0px 4px 10px #4C4E646B'
    },
    minWidth: '32px',
    padding: '5px',
    '&:disabled': {
      border: '1px #e4e4e4 solid',
      color: '#FF4D49',
      backgroundColor: '#FF4D4933',
      opactiy: 0.5
    }
  },
  deleteIcon: {
    color: '#FF4D49',
    height: '20px',
    width: '20px'
  },
  navButton: {
    height: 32,
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    padding: '0px 15px',
    border: '1px #e4e4e4 solid',
    borderRadius: '4px',
    margin: '0px 8px 0px 0px',
    '&:disabled': {
      opacity: 0.5,
      backgroundColor: '#FFFFFF',
      color: '#334D6E',
    }
  },
  btnIconArrowLeft: {
    height: 22,
    width: 22,
    marginLeft: -16,
    marginRight: -16,
    color: '#334D6E',
  },
  btnIconArrowRight: {
    height: 22,
    width: 22,
    marginRight: -16,
    marginLeft: -16,
    color: '#334D6E',
  },
};

export default styles;
