import theme from "src/theme";

const styles = {
  root: {},
  header: {
    fontSize: '11px!important',
    fontWeight: 500,
    color: theme.palette.text.header,
    padding: '16px 20px 13px',
    lineHeight: '16.5px'
  },
  formContainer: {
    padding: '16px 20px'
  },
  formInput: {
    paddingTop: '13px!important',
    paddingBottom: '13px!important',
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  updateBtnContainer: {
    paddingBottom: 28,
    paddingTop: 9
  },
  updateBtn: {
    width: 99,
    height: 41,
    textTransform: 'none',
    backgroundColor: theme.palette.button.action,
  },
};

export default styles;
