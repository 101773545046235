import { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import {
  Backdrop, Grid, Modal, Typography, IconButton, Button, Switch, TextField, Box, CircularProgress, Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import PdfView from 'src/documents/components/PdfView/PdfView';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

const SplitMenu = (props) => {
  const { API } = useContext(ConfigContext);
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    splitMenuOpen, handleClose, invoice, getInvoices
  } = props;

  const [loading, setLoading] = useState(false);
  const [fileResponse, setFileResponse] = useState(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const [imgName, setImgName] = useState('');

  const [fixedRange, setFixedRange] = useState(false);
  const [customRanges, setCustomRanges] = useState([{
    id: 0, start: 1, end: 1, error: null
  }]);
  const [fixedRangeValue, setFixedRangeValue] = useState(2);
  const [aiSplit, setAiSplit] = useState(false);
  const [numPages, setNumPages] = useState(0);

  const closeSplitMenu = () => {
    handleClose();
    setFixedRange(false);
    setCustomRanges([{
      id: 0, start: 1, end: 1, error: null
    }]);
    setFixedRangeValue(2);
    setAiSplit(false);
  };

  const getImage = async (name) => {
    if (!name) {
      return;
    }
    setLoading(true);
    setFetchingFile(true);
    try {
      const response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
      setFetchingFile(false);
      setLoading(false);
    } catch (err) {
      setFileResponse(null);
      setFetchingFile(false);
      setLoading(false);
    }
  };

  const cleanUp = () => {
    setFileResponse(null);
    setImgName('');
    setFixedRange(false);
    setCustomRanges([{
      id: 0, start: 1, end: 1, error: null
    }]);
    setFixedRangeValue(2);
    setAiSplit(false);
    setNumPages(0);
  };

  useEffect(() => {
    const nameParts = invoice?.documentFile?.split('.');
    let imageName = '';
    if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
      imageName = `${nameParts[0]}-display.pdf`;
      setImgName(`${nameParts[0]}-display.pdf`);
    } else {
      imageName = invoice.documentFile;
      setImgName(invoice.documentFile);
    }

    getImage(imageName);

    return cleanUp;
  }, [invoice]);

  const handleRangeChange = (e, i, type) => {
    e.preventDefault();
    const newRanges = customRanges.map((r) => {
      const updatedRange = r;
      if (Number(r.end) >= Number(r.start)) {
        updatedRange.error = null;
      }
      return updatedRange;
    });
    if (i !== null || i !== undefined || i !== -1) {
      newRanges[i][type] = e.target.value;
      newRanges[i].error = null;
    }
    if (type === 'start') {
      if (newRanges[i].end !== '' && Number(e.target.value) > Number(newRanges[i].end)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (newRanges[i - 1] && Number(e.target.value) <= Number(newRanges[i - 1].end)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      }
    }
    if (type === 'end') {
      if (newRanges[i].start !== '' && Number(e.target.value) < Number(newRanges[i].start)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (newRanges[i + 1] && Number(e.target.value) >= Number(newRanges[i + 1].start)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (Number(e.target.value) > numPages) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      }
    }
    setCustomRanges(newRanges);
  };

  const addNewRange = () => {
    const newRanges = [...customRanges];
    if (Number(newRanges[newRanges.length - 1].end) === numPages) {
      return;
    }
    newRanges.push({
      id: newRanges.length,
      start: Number(newRanges[newRanges.length - 1].end) + 1,
      end: Number(newRanges[newRanges.length - 1].end) + 1,
      error: null,
    });
    setCustomRanges(newRanges);
  };

  const handleRangeDelete = (i) => {
    if (customRanges.length === 1) {
      return;
    }
    const newRanges = [...customRanges];
    newRanges.splice(i, 1);
    setCustomRanges(newRanges);
  };

  const aiSplitClick = async () => {
    setAiSplit(false);
    setFixedRange(false);
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.aiSplit}${invoice.id.toString()}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        setAiSplit(true);
        const newRanges = [];
        response.data.data.split.forEach((r, i) => {
          newRanges.push({
            id: i,
            start: parseInt(r.start, 10) + 1,
            end: parseInt(r.end, 10),
            error: null,
          });
        });
        setCustomRanges(newRanges);
        setAiSplit(true);
      } else {
        setCustomRanges([{
          id: 0, start: 1, end: 1, error: null
        }]);
      }
    } catch (error) {
      setLoading(false);
      setCustomRanges([{
        id: 0, start: 1, end: 1, error: null
      }]);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t) || appendContactSupport(window.config.support_email, t('AI_SPLIT_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const handleDocumentSplit = async (splitType, pageCount, pageRange) => {
    setLoading(true);
    try {
      const body = {
        documentID: invoice.id.toString(),
        splitType,
        pageCount: pageCount?.toString() || '1',
        pageRange: pageRange || [],
      };

      const response = await Axios.post(
        API.splitDocument,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        getInvoices();
        enqueueSnackbar(t('SPLIT_DOCUMENT_SUCCESS'), {
          variant: 'success',
        });
        closeSplitMenu();
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('SPLIT_DOCUMENT_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const handleConfirmClick = () => {
    if (fixedRange) {
      handleDocumentSplit('fixed', fixedRangeValue);
    } else {
      const ranges = customRanges.map((r) => ({
        start: parseInt(r.start, 10) - 1,
        end: parseInt(r.end, 10)
      }));
      handleDocumentSplit('range', '', ranges);
    }
  };

  const renderInvoicePDF = () => (
    <>
      <Box hidden={!isMobile} style={{ height: '100%' }}>
        <PdfView
          t={t}
          ready={ready}
          fileResponse={fileResponse}
          fetchingFile={fetchingFile}
          imgName={imgName}
          canOpenInNewTab
          setNumPages={setNumPages}
        />

      </Box>
      <Box hidden={isMobile} style={{ height: '100%' }}>
        <iframe src={fileResponse} title="pdf" style={styles.pdfFrame} />
      </Box>
    </>
  );

  const splitRequestButtons = (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="outlined"
        style={styles.dontSplitBtn}
        onClick={() => handleDocumentSplit('no')}
      >
        {t('INVOICE_SPLIT_REQUEST_DONT_SPLIT')}
      </Button>
      <Button
        variant="outlined"
        style={styles.dontSplitBtn}
        onClick={() => handleDocumentSplit('fixed', 1)}
      >
        {t('INVOICE_SPLIT_REQUEST_SPLIT_PER_PAGE')}
      </Button>
      <Button
        style={styles.confirmBtn}
        variant="contained"
        onClick={() => handleConfirmClick()}
      >
        {t('SPLIT_MENU_CONFIRM')}
      </Button>
    </Box>
  );

  return (
    <>
      <Modal
        style={styles.modal}
        open={splitMenuOpen}
        onClose={() => closeSplitMenu()}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <div style={styles.paper}>
          <Grid container style={styles.modalHeader}>
            <Grid item style={styles.modalHeaderLeft}>
              <Typography style={styles.headerTitle}>
                {ready && t('SPLIT_MENU_TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={1} md={8} style={styles.modalHeaderRight}>
              <IconButton onClick={() => closeSplitMenu()}>
                <CloseIcon style={styles.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container style={styles.modalContainer}>
            <Grid item xs={12} md={4} style={styles.leftContainer}>
              <Grid style={styles.optionsContainer}>
                <Grid style={styles.lineRow}>
                  <Grid style={styles.documentNameContainer}>
                    <Typography style={styles.documentNameLabel}>{t('SPLIT_MENU_DOCUMENT_NAME')}</Typography>
                    <Typography style={styles.documentName}>{invoice.fileName}</Typography>
                  </Grid>
                  <Grid style={styles.aiSplitContainer}>
                    <Button
                      variant="outlined"
                      style={styles.aiSplitBtn}
                      onClick={aiSplitClick}
                    >
                      {t('SPLIT_MENU_AI_SPLIT_BUTTON')}
                      <span style={styles.newLabel}>{ready && t('SPLIT_MENU_BETA')}</span>
                    </Button>
                  </Grid>
                </Grid>
                <Grid style={styles.lineRow}>
                  <Grid style={styles.lineLeftContainer}>
                    <Typography style={styles.rangeTitleText}>{ready && t('SPLIT_MENU_CUSTOM_RANGES')}</Typography>
                  </Grid>
                  <Switch
                    checked={fixedRange}
                    onChange={() => setFixedRange(!fixedRange)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Grid style={styles.lineRightContainer}>
                    <Typography style={styles.rangeTitleText}>{ready && t('SPLIT_MENU_FIXED_RANGES')}</Typography>
                  </Grid>
                </Grid>
                <Grid style={styles.contentContainer}>
                  {fixedRange ? (
                    <Grid style={styles.rangesContainer}>
                      <Grid style={styles.rangeRow}>
                        <Typography style={styles.rangeText}>{ready && t('SPLIT_MENU_FIXED_RANGE_TITLE')}</Typography>
                        <TextField
                          value={fixedRangeValue}
                          onChange={(e) => setFixedRangeValue(e.target.value)}
                          variant="outlined"
                          style={styles.rangeInput}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid style={styles.rangesContainer}>
                      {aiSplit && (
                      <Grid style={styles.aiSplittedMsgContainer}>
                        <Typography style={styles.aiSplittedMsg}>{ready && t('SPLIT_MENU_AI_MSG')}</Typography>
                      </Grid>
                      )}
                      {customRanges.map((range, i) => (
                        <Grid style={styles.rangeItem} key={range.id}>
                          <Grid style={styles.rangeRow}>
                            <Typography style={styles.rangeText}>{i + 1}</Typography>
                            <Typography style={styles.rangeText}>{ready && t('SPLIT_MENU_FROM_PAGE')}</Typography>
                            <TextField
                              value={range.start}
                              onChange={(e) => handleRangeChange(e, i, 'start')}
                              variant="outlined"
                              style={styles.rangeInput}
                            />
                            <Typography style={styles.rangeText}>{ready && t('SPLIT_MENU_TO_PAGE')}</Typography>
                            <TextField
                              value={range.end}
                              onChange={(e) => handleRangeChange(e, i, 'end')}
                              variant="outlined"
                              style={styles.rangeInput}
                            />
                            <IconButton onClick={() => handleRangeDelete(i)}>
                              <CloseIcon style={styles.closeIcon} />
                            </IconButton>
                          </Grid>
                          {range.error && <Typography style={styles.rangeErrorText}>{ready && t(range.error)}</Typography>}
                        </Grid>
                      ))}
                      <Grid style={styles.lineRow}>
                        <Tooltip title={Number(customRanges[customRanges.length - 1].end) === numPages
                          ? ready && t('SPLIT_MENU_ADD_RANGE_BUTTON_DISABLED_TOOLTIP')
                          : ''}
                        >
                          <span>
                            <Button
                              style={styles.addRangeBtn}
                              variant="contained"
                              onClick={addNewRange}
                              disabled={Number(customRanges[customRanges.length - 1].end) === numPages}
                            >
                              {t('SPLIT_MENU_ADD_RANGE')}
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid style={styles.modalLeftFooter}>
                {splitRequestButtons}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} style={styles.invoiceContainer}>
              {renderInvoicePDF()}
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SplitMenu;
