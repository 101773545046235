import {
  FETCH_GLAS_FAIL,
  FETCH_GLAS_START,
  FETCH_GLAS_SUCCESS,
  FETCH_GLA_LIST_FAIL,
  FETCH_GLA_LIST_START,
  FETCH_GLA_LIST_SUCCESS
} from "src/actionTypes";

const initialState = {
  glAccounts: [],
  glaList: [],
  total: 0,
  loading: false,
  error: null
};

const glaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GLAS_START:
      return {...state, loading: true, error: null};
    case FETCH_GLAS_SUCCESS:
      return {...state, glAccounts: action.payload.glas, total: action.payload.total, loading: false, error: null};
    case FETCH_GLAS_FAIL:
      return {...state, loading: false, error: action.payload.error};
    case FETCH_GLA_LIST_START:
      return {...state, loading: true, error: null};
    case FETCH_GLA_LIST_SUCCESS:
      return {...state, glaList: action.payload.glaList, total: action.payload.total, loading: false, error: null};
    case FETCH_GLA_LIST_FAIL:
      return {...state, loading: false, error: action.payload.error};
    default:
      return state;
  }
};

export default glaReducer;
