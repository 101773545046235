/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.666 5.833V4.167h6.667v1.666h-6.666zm0 3.334V7.5h6.667v1.667h-6.666zm0 3.333v-1.667h6.667V12.5h-6.666zm-5-.833a2.411 2.411 0 01-1.77-.73 2.41 2.41 0 01-.73-1.77c0-.695.244-1.285.73-1.771a2.41 2.41 0 011.77-.73 2.41 2.41 0 011.771.73 2.41 2.41 0 01.73 1.77 2.41 2.41 0 01-.73 1.771 2.411 2.411 0 01-1.77.73zm-5 5v-1.584c0-.291.07-.57.209-.833a1.58 1.58 0 01.583-.625 7.728 7.728 0 011.99-.844 8.557 8.557 0 012.218-.281c.778 0 1.518.094 2.22.281a7.728 7.728 0 011.989.844c.25.153.444.361.583.625.14.264.208.542.208.833v1.584h-10z"
        fill="#3E8AFF"
      />
    </svg>
  )
}

export default SvgComponent
