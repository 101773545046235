import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

import { Box, Button, Tooltip, Typography } from '@mui/material';
import ForwardIcon from '@mui/icons-material/ArrowForward';
import { Close as CloseIcon, CheckCircle as CheckIcon} from '@mui/icons-material';

import { 
  appendContactSupport, axiosHeaders, getLocalisedErrorString 
} from 'src/utils/helpers';
import AiSplitIcon from 'src/dashboard-new/icons/AiSplitIcon';
import CustomRangeIcon from 'src/dashboard-new/icons/CustomRangeIcon';
import FixedRangeIcon from 'src/dashboard-new/icons/FixedRangeIcon';
import { useConfig } from 'src/hooks/useConfig';
import styles from './style';

type PropsType = { 
  onClose:Function; 
  totalPages:number; 
  invoice:any;
  setLoading:Function;
  getInvoices:Function;
 }

const SideMenu = ({ onClose, totalPages, invoice, setLoading, getInvoices} : PropsType) => {
  const { API } = useConfig();
  const { ready, t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [activeBtn,setActiveBtn] = useState<number>(0);
  const [fixedRange,setFixedRange] = useState<any>(2);
  const [,setRangeSetReady] = useState<boolean>(false);
  const [activeRange,setActiveRange] = useState<number>(0);

  const [rangePage,setRanges] = useState<any>([
    {
      id:Math.floor(Math.random() * 1000),
      start:1,
      end:1,
      error:null
    }
  ]);

  const [aIRangePage,setAIRanges] = useState<any>([
    {
      id:Math.floor(Math.random() * 1000),
      start:'',
      end:'',
      error:null
    }
  ]);

  const handleCustomFixedRange = (num:number)=>{
    setActiveBtn(num);
    setActiveRange(num);    
  }

  const handleAiRange = async (num:number) =>{
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.aiSplit}${invoice.id.toString()}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        const newRanges:any = [];
        response.data.data.split.forEach((r:any, i:number) => {
          newRanges.push({
            id: i,
            start: parseInt(r.start, 10) + 1,
            end: parseInt(r.end, 10),
            error: null,
          });
        });
        setAIRanges(newRanges);
      } else {
        setAIRanges([{
          id: 0, start: 1, end: 1, error: null
        }]);
      }
    } catch (error:any) {
      setLoading(false);
      setAIRanges([{
        id: 0, start: 1, end: 1, error: null
      }]);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t) || appendContactSupport(window.config.support_email, t('AI_SPLIT_FAILED'), t), {
        variant: 'error',
      });
    }
    setActiveRange(num)
  }

  const renderCustomRange = () => {
    return ( <RangeComponent ranges={rangePage} 
      activeRange={activeRange} totalPages={totalPages} 
      setRanges={setRanges} setRangeSetReady={setRangeSetReady}
     /> )
  };

  const aiSplitRange = () => {
    return ( <RangeComponent ranges={aIRangePage} 
      activeRange={activeRange} totalPages={totalPages} 
      setRanges={setAIRanges} setRangeSetReady={setRangeSetReady} 
    />)
  }

  const handleChangeFixedRange = ( e:React.ChangeEvent<HTMLInputElement> ) =>{
    let value = e.target.value;
    setFixedRange(value);
  }

  const renderFixedRange = () =>{
    return (
      <Box sx={styles.fRangeWrapper}>
        <label>
          <span>{ready && t('SPLIT_MENU_FIXED_RANGE_TITLE')}</span>
          <input
            value={fixedRange}
            min={1}
            max={totalPages}  
            onChange={handleChangeFixedRange}
            maxLength={10}
            type="number"
          />
        </label>
        <Box sx={styles.error}>
          {(totalPages > 0 && fixedRange > totalPages)
            ? t('SPLIT_MENU_RANGE_ERROR')
            : !fixedRange
              ? t('SPLIT_MENU_RANGE_ERROR')
              : ''
          }
        </Box>
      </Box>
    )
  }

  const handleActiveRange = () => {
    return activeRange === 0
      ? renderCustomRange()
      : activeRange === 1
        ? renderFixedRange()
        : aiSplitRange();
  }

  const handleDocumentSplit = async ( splitType:string, pageCount:string='', pageRange:any=[] ) => {
    setLoading(true);
    try {
      const body = {
        documentID: invoice.id.toString(),
        splitType,
        pageCount: pageCount?.toString() || '1',
        pageRange: pageRange || [],
      };

      const response = await Axios.post(
        API.splitDocument,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        getInvoices();
        enqueueSnackbar(t('SPLIT_DOCUMENT_SUCCESS'), {
          variant: 'success',
        });
        onClose();
      }
    } catch (error:any) {
      setLoading(false);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('SPLIT_DOCUMENT_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const handleDontSplit = () =>{
    handleDocumentSplit('no');

  }

  const handleSplitPerPage = ()=>{
    handleDocumentSplit('fixed', (1).toString())
  }

  const handleConfirmSplit = () =>{
    if(activeRange === 1){
      if(totalPages > 0 && fixedRange <= totalPages && fixedRange && parseInt(fixedRange) && !isNaN(parseInt(fixedRange)))
        handleDocumentSplit('fixed',fixedRange.toString());
    }
    else if(activeRange === 0){
      let error = false;
      for(let i = 0; i < rangePage.length; i++){
        if(rangePage.error){
          error = true;
          break;
        }
      }
      if(!error){
        let cRange = rangePage.map((e:any) => ({
          // start is exclusive in the backend
          start: parseInt(e.start) - 1,
          end: parseInt(e.end)
        }))
        handleDocumentSplit('range','',cRange)
      }
      
    }
    else if (activeRange === 2){
      let error = false;
      for(let i = 0; i < aIRangePage.length; i++){
        if(aIRangePage.error){
          error = true;
          break;
        }
      }
      if(!error){
        let cRange = aIRangePage.map((e:any) => ({
          // start is exclusive in the backend
          start: parseInt(e.start) - 1,
          end: parseInt(e.end)
        }))
        handleDocumentSplit('range','',cRange)
      }
    }
  }

  const setActiveConfirmBtn = ():boolean=>{
    let result:boolean = true;
    if(activeRange === 1){
      if(fixedRange >= 1 && fixedRange <= totalPages)result = false;
    }
    else if(activeRange === 0){
      for(let i = 0; i < rangePage.length; i++){
        if(rangePage[i].error != null){
          result = true;
          break;
        }
        if(rangePage.length - 1 === i){
          result = false;
        }
      }
    }
    else if(activeRange === 2){
      for(let i = 0; i < aIRangePage.length; i++){
        if(aIRangePage[i].error != null){
          result = true;
          break;
        }
        if(aIRangePage.length - 1 === i){
          result = false;
        }
      }
    }
    return result;
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.head}>
        <Typography sx={styles.headTitle}>{ready && t('SPLIT_MENU_TITLE')}</Typography>
        <Button sx={styles.aiBtn} onClick={()=> handleAiRange(2)}>
          <AiSplitIcon style={{color:'#3E8AFF'}} />
          {ready && t('SPLIT_AI_SPLIT_BUTTON')}
        </Button>
      </Box>
      <Box sx={styles.switchHead}>
        <Button sx={styles.switchBtn} className={activeBtn === 0?'active':''} 
          onClick={() => handleCustomFixedRange(0)}>
          {ready && t('SPLIT_MENU_CUSTOM_RANGES')}
          <CustomRangeIcon />
        </Button>
        <Button sx={styles.switchBtn} className={activeBtn === 1?'active':''} 
          onClick={() => handleCustomFixedRange(1)}>
          {ready && t('SPLIT_MENU_FIXED_RANGES')}
          <FixedRangeIcon />
        </Button>
      </Box>
      <Box sx={styles.content} className="y-scroll">
        <Box sx={{boxSizing:'border-box',width:'100%'}}>{handleActiveRange()}</Box>
        <Box sx={styles.rangeFoot}>
          <Button onClick={handleDontSplit}>
            {ready && t('INVOICE_SPLIT_REQUEST_DONT_SPLIT')}
          </Button>
          <Button onClick={handleSplitPerPage}>
            {ready && t('INVOICE_SPLIT_REQUEST_SPLIT_PER_PAGE')}
          </Button>
        </Box>
      </Box>
      <Box sx={styles.bottom}>
        <Button sx={styles.cancelBtn} onClick={()=>onClose()}>
          {ready && t('INVOICE_SPLIT_CANCEL')}
        </Button>
        <Button disabled={setActiveConfirmBtn()} sx={styles.confirmBtn} onClick={handleConfirmSplit} >
          {ready && t('SPLIT_MENU_CONFIRM')} 
          <ForwardIcon sx={styles.confirmIcon} />
        </Button>
      </Box>
    </Box>
  )
}

export default SideMenu;

const RangeComponent = (props:any) =>{
  const { ready, t } = useTranslation();
  const totalPages = props?.totalPages;
  const setRanges:Function = props?.setRanges || function(){};
  const setRangeSetReady:Function = props?.setRangeSetReady || function(){};
  const ranges:any = props?.ranges || [];
  const activeRange = props?.activeRange || 1;
  const [addStatus,setAddStatus] = useState<boolean>(true);
  
  const handleAddRange = () =>{
    setRangeSetReady(false);
    let num = parseInt(ranges?.at(-1)?.end) || parseInt(ranges?.at(-1)?.start);
    let newNum = isNaN(num)?1:num+1;
    const tempRanges = [
      ...ranges,{
        id:Math.floor(Math.random() * 1000),
        start:newNum,
        end:newNum,
        error:null,
      }
    ]
    if(newNum === totalPages) setAddStatus(false);
    setRanges(tempRanges);  
  }

  const removeRange = (id: string,i:number) =>{
    let lastIndex = ranges.length - 1;
    if(lastIndex === i){
      let check = ranges[i].end <= totalPages;
      if(check) setAddStatus(true);
    }
    let tempRanges = ranges.filter((eRange:any) => eRange?.id != id);
    setRanges(tempRanges);
  }

  const handleChangeRange = (e:React.ChangeEvent<HTMLInputElement>, i:any, type:string) =>{
    e.preventDefault();
    const newRanges = ranges.map((r:any) => {
      const updatedRange = r;
      if (Number(r.end) >= Number(r.start)) {
        updatedRange.error = null;
      }
      return updatedRange;
    });
    if (i !== null || i !== undefined || i !== -1) {
      newRanges[i][type] = e.target.value;
      newRanges[i].error = null;
    }
    if (type === 'start') {
      if (newRanges[i].end !== '' && Number(e.target.value) > Number(newRanges[i].end)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (newRanges[i - 1] && Number(e.target.value) <= Number(newRanges[i - 1].end)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      }
    }
    if (type === 'end') {
      if (newRanges[i].start !== '' && Number(e.target.value) < Number(newRanges[i].start)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (newRanges[i + 1] && Number(e.target.value) >= Number(newRanges[i + 1].start)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (Number(e.target.value) > totalPages) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      }
    }
    if(i === newRanges.length -1){
      let range = newRanges[i];
      if(!range.error){
        if(range.end >= range.start){
          if(range.end < totalPages){
            setAddStatus(true);
          }
          else{
            setAddStatus(false);
          }
        }
      }
    }
    setRanges(newRanges);
  }

  return (
    <Box sx={styles.rangeWrapper}>
      {activeRange === 2 ? 
      <Box sx={styles.aiNotice}>
        <span><CheckIcon style={{width:'20px',height:'20px'}} /></span>
        <p>{ready && t('AI_SPLIT_NOTICE')}</p>
      </Box>:
      <></>}
      {ranges && ranges.length > 0?
        ranges.map((eRange:any,index:number) =>
          <Box key={eRange?.id}>
            <Box sx={styles.eachRange}>
              <span style={styles.num}>{index+1}.</span>
              <label style={styles.rangeLabel}>
                <span style={styles.rangeSpan}>{ready && t('SPLIT_MENU_FROM_PAGE')}</span>
                <input 
                  min={1} max={totalPages}
                  onChange={(e:React.ChangeEvent<HTMLInputElement>)=>handleChangeRange(e,index,'start')}
                  style={styles.rangeInput} 
                  type="number" value={eRange?.start} 
                />
              </label>
              <label style={styles.rangeLabel2}>
                <span style={styles.rangeSpan}>{ready && t('SPLIT_MENU_TO_PAGE')}</span>
                <input 
                  min={1} max={totalPages}
                  onChange={(e:React.ChangeEvent<HTMLInputElement>)=>handleChangeRange(e,index,'end')} 
                  style={styles.rangeInput} 
                  type="number" value={eRange?.end} 
                />
              </label>
              <span onClick={()=>removeRange(eRange?.id,index)} style={styles.cancel}>
                <CloseIcon sx={styles.closeIcon} />
              </span>
            </Box>
            <Box sx={styles.error}>{t(eRange?.error)}</Box>
          </Box>
        ):
        <>{ready && t('INVOICE_NO_PAGE_RANGE_AVAILABLE')}</>
      }
      <span>
        <Tooltip title={t('SPLIT_MENU_ADD_RANGE_BUTTON_DISABLED_TOOLTIP')}
          disableHoverListener={addStatus}
        >
          <span>
            <Button disabled={!addStatus} sx={styles.addRange} onClick={handleAddRange}>
            {ready && t('SPLIT_MENU_ADD_RANGE')}
          </Button>
          </span>
        </Tooltip>
      </span>
      
      
    </Box>
)}