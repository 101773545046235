import {
  useState, useEffect, createRef, useContext
} from 'react';
import axios from 'axios';

import {
  Backdrop, Button, CircularProgress, Container, Grid, Typography
} from '@mui/material';

import Page from 'src/shared/screens/Page';
import { getMaskedText } from 'src/utils/helpers';
import { statsPageRefreshTimer } from 'src/config';
import ConfigContext from 'src/contexts/ConfigContext';

const StatsDashboardView = () => {
  const {
    BRAND_NAME, BRAND_DOMAIN, BRAND_URL, API
  } = useContext(ConfigContext);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(null);
  const [timeSaved, setTimeSaved] = useState(null);

  const intervalID = createRef();

  const assignValues = (value) => {
    const iTotal = value;
    const sTotal = getMaskedText(iTotal?.toString(), 3);

    const iTime = Math.round((iTotal * 2.5) / 60);
    const sTime = getMaskedText(iTime.toString(), 3);

    setTotal(sTotal);
    setTimeSaved(sTime);
  };

  const assignPrevValue = () => {
    const lTotal = localStorage.getItem('PROCYS_APP_STATS_DASHBOARD_TOTAL');
    if (!lTotal || lTotal === 'null') {
      setTotal(0);
      setTimeSaved(0);
    } else {
      assignValues(lTotal);
    }
  };

  const getDashboardStats = async () => {
    const lTotal = localStorage.getItem('PROCYS_APP_STATS_DASHBOARD_TOTAL');
    if (!lTotal || lTotal === 'null') {
      setLoading(true);
    }

    try {
      const response = await axios.get(API.getDashboardStats);
      if (response.data.success) {
        assignValues(response.data.data.total);
        localStorage.setItem('PROCYS_APP_STATS_DASHBOARD_TOTAL', response.data.data.total);
      } else {
        assignPrevValue();
      }
      setLoading(false);
    } catch (error) {
      assignPrevValue();
      setLoading(false);
    }
  };

  const onStartInterval = () => {
    intervalID.current = setInterval(() => {
      getDashboardStats();
    }, statsPageRefreshTimer);
  };

  useEffect(() => {
    getDashboardStats();
    onStartInterval();

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <>
      <Page
        style={styles.root}
        title={`${BRAND_NAME} Statistics`}
      >
        <Container style={styles.container}>
          <Grid style={styles.innerContainer}>
            <Button onClick={() => window.open(BRAND_URL, '_blank')} style={styles.logoBtn}>
              <img src={`${API.brandLogo}${BRAND_DOMAIN}`} alt="" style={styles.logo} />
            </Button>
            {
              !loading && (
                <>
                  <Typography style={styles.countLabel}>Invoices processed</Typography>
                  <Typography style={styles.countStat}>{total}</Typography>
                  <Typography style={styles.timeLabel}>Time saved (hours)</Typography>
                  <Typography style={styles.timeStat}>{timeSaved}</Typography>
                </>
              )
            }
          </Grid>
          <Grid style={styles.copyrightContainer}>
            <Typography>
              Copyright © 2024
              {' '}
              <a
                href={BRAND_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={styles.copyrightLink}
              >
                {BRAND_NAME}
              </a>
              , All rights reserved.
            </Typography>
          </Grid>
          <Backdrop style={styles.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </Page>
    </>
  );
};

export default StatsDashboardView;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100vh',
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContainer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  countLabel: {
    marginTop: 60,
    fontFamily: 'Poppins',
    fontSize: 40,
    color: '#333333',
    fontWeight: 300,
    textAlign: 'center',
  },
  countStat: {
    fontFamily: 'Poppins',
    fontSize: 100,
    color: '#C9312E',
    fontWeight: 500,
    textAlign: 'center',
  },
  timeLabel: {
    marginTop: 60,
    fontFamily: 'Poppins',
    fontSize: 40,
    color: '#333333',
    fontWeight: 300,
    textAlign: 'center',
  },
  timeStat: {
    fontFamily: 'Poppins',
    fontSize: 100,
    color: '#C9312E',
    fontWeight: 500,
    textAlign: 'center',
  },
  logoBtn: {
    marginTop: 60,
  },
  logo: {
    width: 200,
    height: 50,
  },
  copyrightContainer: {
    textAlign: 'center',
    lineHeight: 24,
    fontStyle: 'italic',
    fontSize: 14,
    color: '#6a6a6a',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 36
  },
  copyrightLink: {
    textDecoration: 'none',
    color: '#38A0F1',
  },
  backdrop: {
    zIndex: 1111,
    color: '#ffffff',
  },
};
