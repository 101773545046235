const styles = {
  root: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    padding:'20px',
    backgroundColor:'#FFFFFF',
    height: '76px',
  },
  selectedCount: {
    color: '#4C4E64',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
  },
  actionBtn: {
    backgroundColor: '#3E8AFF1A',
    padding: '4px 12px',
    borderRadius: '4px',
    height: '36px',
    textTransform: 'none',
    color: '#3E8AFF',
    fontWeight: '500',
    fontSize: '14px',
    marginLeft: '10px',
    '&:hover': {
      color: '#3E8AFF',
      backgroundColor: '#3E8AFF1A',
      boxShadow: '0px 8px 16px -8px #4C4E646B',
    },
    '&:disabled': {
      color: '#3E8AFF',
      backgroundColor: '#3E8AFF1A',
      opacity: '0.5',
    }
  },
  actionIcon: {
    color: '#3E8AFF',
    width: '20px',
    height: '20px',
  },
  statusIcon: {
    size: '20px',
    width: '20px',
    height: '20px'
  },
  changeStatusLabel: {
    height: '24px',
    fontSize: '10px',
    fontWeight: '500',
    color: '#4C4E64',
    opacity: 0.5,
    textTransform: 'uppercase' as const,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    }
  },
  changeStatusOptions: {
    padding: '6px 10px',
    color: '#4C4E64',
    fontSize: '14px',
    fontWeight: '500',
    '&:hover': {
      background: '#C2CFE033',
    },
  },
  changeStatusIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default styles;