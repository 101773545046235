import Axios from "axios";
let aController = {
  current:null,
}
import {
  FETCH_GLAS_FAIL,
  FETCH_GLAS_START,
  FETCH_GLAS_SUCCESS,
  FETCH_GLA_LIST_FAIL,
  FETCH_GLA_LIST_START,
  FETCH_GLA_LIST_SUCCESS
} from "../../actionTypes";
import { axiosHeaders } from "src/utils/helpers";

const fetchGlasStart = () => {
  return {
    type: FETCH_GLAS_START,
  };
};

const fetchGlasSuccess = (glas, total) => {
  return {
    type: FETCH_GLAS_SUCCESS,
    payload: { glas, total },
  };
};

const fetchGlasFail = (error) => {
  return {
    type: FETCH_GLAS_FAIL,
    payload: { error },
  };
}

export const fetchGlas = (companyID, search = '') => {
  const abortControllerRef = aController;
  return async dispatch => {
    dispatch(fetchGlasStart());
    try {
      if(abortControllerRef && abortControllerRef?.current && abortControllerRef?.current?.abort)abortControllerRef.current.abort('');
      abortControllerRef.current = new AbortController();
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/dimensions/glaccounts/companies/${companyID}?search=${search}`,
        {...axiosHeaders(localStorage.getItem('PROCYS_accessToken')),
          signal: abortControllerRef.current.signal,
        }
      );
      if (response.data.success) {
        dispatch(fetchGlasSuccess(response.data.data, response.data.total));
      } else {
        dispatch(fetchGlasFail(response.data.message));
      }
    } catch (error) {
      dispatch(fetchGlasFail(error?.response?.data?.message));
    }
  };
};

const fetchGlaListStart = () => {
  return {
    type: FETCH_GLA_LIST_START,
  };
};

const fetchGlaListSuccess = (glaList, total) => {
  return {
    type: FETCH_GLA_LIST_SUCCESS,
    payload: { glaList, total },
  };
};

const fetchGlaListFail = (error) => {
  return {
    type: FETCH_GLA_LIST_FAIL,
    payload: { error },
  };
}

export const fetchGlaList = (companyID, page = 0, limit = 10, search = '') => {
  return async dispatch => {
    dispatch(fetchGlaListStart());
    try {
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/dimensions/glaccounts/companies/${companyID}?page=${page + 1}&limit=${limit}&search=${search}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        dispatch(fetchGlaListSuccess(response.data.data, response.data.total));
      } else {
        dispatch(fetchGlaListFail(response.data.message));
      }
    } catch (error) {
      dispatch(fetchGlaListFail(error.response.data.message));
    }
  };
};
