import theme from "src/theme";

const styles = {
  root: {},
  queryField: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: 0,
  },
  headerStyle: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.header,
    align: 'center'
  },
  searchBar: {
    flex: 3,
    marginRight: 24,
    marginTop: 12,
    marginBottom: 12,
  },
  searchBarInput: {
    height: '36px',
    width: '100%',
  },
  arrow: {
    paddingLeft: 4
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px',
    marginRight: 24
  },
  action: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    backgroundColor: theme.palette.button.action
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  checkbox: {
    width: 16,
    height: 16,
    color: '#D5D5D5',
    paddingLeft: 17
  },
  caption: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: theme.palette.text.header,
    paddingLeft: 24
  },
  paginationComp: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  paginationActions: {
    marginLeft: 0
  },
};

export default styles;
