const styles = {
  field: {
    margin: '4px 0'
  },
  fieldLabelContainer: {
    width: '100%',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  fieldValueContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  labelIconPlaceholder: {
    width: '18px',
    height: '18px',
  },
  fieldLabel: {
    color: '#4C4E64DE',
    fontSize: '13px',
    fontWeight: '400',
    marginLeft: '4px',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid #C2CFE099',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C2CFE099',
      }
    },
  },
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid #C2CFE099',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C2CFE099',
      },
    }
  },
  spreadInvoiceContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex',
    margin: '12px 0'
  },
  confirmSpreadBtn: {
    border: 'none',
    backgroundColor: '#C2CFE099',
    color: '#4C4E64',
    fontSize: 13,
    height: '32px',
    textTransform: 'none' as const,
    boxShadow: 'none',
  },
};

export default styles;
