import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Grid, Typography, ToggleButtonGroup, ToggleButton
} from '@mui/material';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import { useAuth } from 'src/hooks/useAuth';
import styles from './style';

const OverviewStats = ({
  totalErrored, totalUploading, totalDigitized, totalRejected, totalArchived,
  totalToreview, totalTovalidate, totalToexport, totalExported
}) => {
  const { user } = useAuth();
  const isToValidateStatusAllowed = user.customisations.includes('toValidateStatus');
  const { ready, t } = useTranslation();
  const [menuValue, setMenuValue] = React.useState(user.role !== 'company' ? 0 : 1);

  const handleChangeDisplay = (e, a) => {
    setMenuValue(a);
  };

  return (
    <Grid style={styles.summaryStats}>
      <Grid style={styles.topLine}>
        {
          user.role === 'company' && (
            <Grid style={styles.overviewText}>
              {ready && t('DASHBOARD_OVERVIEW')}
            </Grid>
          )
        }
        {
          user.role !== 'company' && (
            <Grid style={styles.overviewTabs}>
              <ToggleButtonGroup
                color="primary"
                value={menuValue}
                exclusive
                onChange={handleChangeDisplay}
              >
                <ToggleButton value={0} style={styles.toggleBtn}>{ready && t('OVERVIEW_STATUS_APPROVAL_SHORT')}</ToggleButton>
                <ToggleButton value={1} style={styles.toggleBtn}>{ready && t('OVERVIEW_STATUS_SUMMARY_SHORT')}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )
        }
        {/* <Grid style={styles.overviewActions}>
          <Grid>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              // onClick={handleClick}
              style={styles.menuBtn}
            >
              {ready && t('DASHBOARD_OVERVIEW_ALLTIME')}
              <SvgIcon fontSize="small">
                <ExpandMoreIcon style={styles.moreIcon} />
              </SvgIcon>
            </Button>
          </Grid>
        </Grid> */}
      </Grid>
      {
        user.role === 'company' && (
          <Grid style={styles.statsCompany}>
            <Grid style={styles.statsColumn}>
              {
                menuValue === 1 && (
                  <Grid>
                    <Typography style={styles.dullText}>
                      {ready && t('DASHBOARD_OVERVIEW_TOTAL')}
                    </Typography>
                    <Typography style={styles.overviewNumber}>
                      {totalDigitized + totalErrored + totalArchived}
                    </Typography>
                  </Grid>
                )
              }
              {
                menuValue === 1 && (
                  <Grid>
                    <Typography style={styles.dullText}>
                      {ready && t('DASHBOARD_OVERVIEW_DIGITIZED')}
                    </Typography>
                    <Typography style={styles.overviewNumber}>{totalDigitized}</Typography>
                  </Grid>
                )
              }
            </Grid>
            <Grid style={styles.statsColumn}>
              {
                menuValue === 1 && (
                  <Grid>
                    <Typography style={styles.dullText}>
                      {ready && t('DASHBOARD_OVERVIEW_UPLOAD')}
                    </Typography>
                    <Typography style={styles.overviewNumber}>{totalUploading}</Typography>
                  </Grid>
                )
              }
              {
                menuValue === 1 && (
                  <Grid>
                    <Grid style={styles.errorLabel}>
                      <ReportProblemOutlinedIcon style={styles.errorIcon} />
                      <Typography style={styles.redText}>
                        {ready && t('DASHBOARD_OVERVIEW_ERRORED')}
                      </Typography>
                    </Grid>
                    <Typography style={styles.overviewErrorNumber}>{totalErrored}</Typography>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        )
      }
      {
        user.role !== 'company' && (
          <Grid style={styles.stats}>
            <Grid style={styles.statsColumn}>
              {
                menuValue === 1 && (
                  <>
                    <Grid>
                      <Typography style={styles.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_TOTAL')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>
                        {totalDigitized + totalErrored + totalArchived}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid style={styles.errorLabel}>
                        <ReportProblemOutlinedIcon style={styles.errorIcon} />
                        <Typography style={styles.redText}>
                          {ready && t('DASHBOARD_OVERVIEW_ERRORED')}
                        </Typography>
                      </Grid>
                      <Typography style={styles.overviewErrorNumber}>{totalErrored}</Typography>
                    </Grid>
                  </>
                )
              }
              {
                menuValue === 0 && (
                  <>
                    <Grid>
                      <Typography style={styles.blueText}>
                        {ready && t('DASHBOARD_OVERVIEW_REVIEW')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>{totalToreview}</Typography>
                    </Grid>
                    <Grid style={styles.statsColumn}>
                      <Grid>
                        <Typography style={styles.darkGreenText}>
                          {ready && t('DASHBOARD_OVERVIEW_EXPORTED')}
                        </Typography>
                        <Typography style={styles.overviewNumber}>{totalExported}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              }
            </Grid>
            <Grid style={styles.statsColumn}>
              {
                menuValue === 1 && (
                  <>
                    <Grid>
                      <Typography style={styles.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_UPLOAD')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>{totalUploading}</Typography>
                    </Grid>
                    <Grid>
                      <Typography style={styles.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_ARCHIVED')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>{totalArchived}</Typography>
                    </Grid>
                  </>
                )
              }
              {
                menuValue === 0 && (
                  <>
                    {isToValidateStatusAllowed && (
                      <Grid>
                        <Typography style={styles.yellowText}>
                          {ready && t('DASHBOARD_OVERVIEW_VALIDATE')}
                        </Typography>
                        <Typography style={styles.overviewNumber}>{totalTovalidate}</Typography>
                      </Grid>
                    )}
                    <Grid>
                      <Typography style={styles.orangeText}>
                        {ready && t('DASHBOARD_OVERVIEW_REJECTED')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>{totalRejected}</Typography>
                    </Grid>
                    {!isToValidateStatusAllowed && (<Grid style={styles.placeholder} />)}
                  </>
                )
              }
            </Grid>
            <Grid style={styles.statsColumn}>
              {
                menuValue === 1 && (
                  <>
                    <Grid>
                      <Typography style={styles.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_DIGITIZED')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>{totalDigitized}</Typography>
                    </Grid>
                    <Grid style={styles.hidden}>
                      <Typography style={styles.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_DIGITIZED')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>{totalDigitized}</Typography>
                    </Grid>
                  </>
                )
              }
              {
                menuValue === 0 && (
                  <>
                    <Grid>
                      <Typography style={styles.greenText}>
                        {ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>{totalToexport}</Typography>
                    </Grid>
                    <Grid style={styles.hidden}>
                      <Typography style={styles.greenText}>
                        {ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')}
                      </Typography>
                      <Typography style={styles.overviewNumber}>{totalToexport}</Typography>
                    </Grid>
                  </>
                )
              }
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
};

export default OverviewStats;
