import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, Box } from '@mui/material';
import { 
  Cancel as CancelIcon,
  AddCircle as AddIcon,
  Inventory as InventoryIcon,
  Timelapse as ToReviewIcon,
  Block as RejectedIcon,
  PlaylistAddCheck as ToValidateIcon,
  CloudDownload as ExportedIcon,
  Description as TotalIcon,
  Task as DigitizedIcon,
  DocumentScanner as ProcessingIcon,
  Error as ErroredIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ToExportIcon from 'src/document-edit/icons/ExportNotes';
import styles from 'src/dashboard-new/components/StatsDashboard/style';

const StatsDashboard = (
  { setShowChart, showChart, overview_stats }: { setShowChart: (_showChart: boolean) => void, showChart: boolean, overview_stats: any }
) => {
  const { ready, t } = useTranslation();
  const { user } = useAuth();
  const isToValidateStatusAllowed = user?.customisations.includes('toValidateStatus') || false;
  const [activeBtn, setActiveBtn] = useState<number>(0);

  return (
    <Grid sx={styles.root}>
      <Grid sx={styles.head}>
        <Button sx={styles.btn} onClick={ ()=> setActiveBtn(0) } className={ activeBtn === 0 ? 'active' : '' }>
          {ready && t('OVERVIEW_STATUS_APPROVAL_SHORT')}
        </Button>
        <Button sx={styles.btn} onClick={ ()=> setActiveBtn(1) } className={ activeBtn === 1 ? 'active' : '' }>
          {ready && t('OVERVIEW_STATUS_SUMMARY_SHORT')}
        </Button>
        <Button sx={styles.farBtn} onClick={ () => setShowChart(!showChart)}>
          <span style={styles.showChartIcon}>{showChart === false ? <AddIcon /> : <CancelIcon />}</span>
          <span style={{fontSize:'13px'}}>{ready && t('DASHBOARD_VIEW_DOCUMENTS_STATISTICS')}</span>
        </Button>
      </Grid>
      <Grid>
        {activeBtn === 0?
          <Grid sx={styles.statsGroup}>
            <Box sx={styles.eachApprovalStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #3E8AFF'
               }}>
                <ToReviewIcon sx={ { ...styles.icon, color: '#3E8AFF' }} />
              </Box>
              <Box>
                <span>{overview_stats?.toreviewTot || 0}</span>
                <span>{ready && t('DASHBOARD_OVERVIEW_REVIEW')}</span>
              </Box>
            </Box>
            {isToValidateStatusAllowed && (
              <Box sx={styles.eachApprovalStats}>
                <Box sx={{
                  ...styles.iconContainer,
                  background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #FDB528'
                }}>
                  <ToValidateIcon sx={ { ...styles.icon, color: '#FDB528' }} />
                </Box>
                <Box>
                  <span>{overview_stats?.tovalidateTot || 0}</span>
                  <span>{ready && t('DASHBOARD_OVERVIEW_VALIDATE')}</span>
                </Box>
              </Box>
            )}
            <Box sx={styles.eachApprovalStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #FF4D49'
              }}>
                <RejectedIcon sx={ { ...styles.icon, color: '#FF4D49' }} />
              </Box>
              <Box>
                <span>{overview_stats?.rejectedTot || 0}</span>
                <span>{ready && t('DASHBOARD_OVERVIEW_REJECTED')}</span>
              </Box>
            </Box>
            <Box sx={styles.eachApprovalStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #27AE60'
              }}>
                <ToExportIcon scale={0.8} />
              </Box>
              <Box>
                <span>{overview_stats?.toexportTot || 0}</span>
                <span>{ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')}</span>
              </Box>
            </Box>
            <Box sx={styles.eachApprovalStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #3E8AFF'
              }}>
                <ExportedIcon sx={ { ...styles.icon, color: '#3E8AFF' }} />
              </Box>
              <Box>
                <span>{overview_stats?.exportedTot || 0}</span>
                <span>{ready && t('DASHBOARD_OVERVIEW_EXPORTED')}</span>
              </Box>
            </Box>
          </Grid>:
          <Grid sx={styles.statsGroup}>
            <Box sx={styles.eachSummaryStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #3E8AFF'
              }}>
                <TotalIcon sx={ { ...styles.icon, color: '#3E8AFF' }} />
              </Box>
              <Box>
                <span>{overview_stats?.total || 0}</span>
                <span>{ready && t('DASHBOARD_OVERVIEW_TOTAL')}</span>
              </Box>
            </Box>
            <Box sx={styles.eachSummaryStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #FDB528'
              }}>
                <ProcessingIcon sx={ { ...styles.icon, color: '#FDB528' }} />
              </Box>
              <Box>
                <span>{overview_stats?.uploadingTot || 0}</span>
                <span>{ready && t('DASHBOARD_PROCESSING_STATS')}</span>
              </Box>
            </Box>
            <Box sx={styles.eachSummaryStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #27AE60'
              }}>
                <DigitizedIcon sx={ { ...styles.icon, color: '#27AE60' }} />
              </Box>
              <Box>
                <span>{overview_stats?.digitizedTot || 0}</span>
                <span>{ready && t('DASHBOARD_OVERVIEW_DIGITIZED')}</span>
              </Box>
            </Box>
            <Box sx={styles.eachSummaryStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #FF4D49'
              }}>
                <ErroredIcon sx={ { ...styles.icon, color: '#FF4D49' }} />
              </Box>
              <Box>
                <span>{overview_stats?.erreredTot || 0}</span>
                <span>{ready && t('DASHBOARD_OVERVIEW_ERRORED')}</span>
              </Box>
            </Box>
            <Box sx={styles.eachSummaryStats}>
              <Box sx={{
                ...styles.iconContainer,
                background: 'linear-gradient(0deg, #ffffffe0, #ffffffe0), #6D788D'
              }}>
                <InventoryIcon sx={ { ...styles.icon, color: '#6D788D' }} />
              </Box>
              <Box>
                <span>{overview_stats?.archivedTot || 0}</span>
                <span>{ready && t('DASHBOARD_OVERVIEW_ARCHIVED')}</span>
              </Box>
            </Box>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default StatsDashboard