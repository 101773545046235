import {
  // LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  LabelList,
  ComposedChart,
  Area,
  ResponsiveContainer
} from "recharts";
import { useTranslation } from 'react-i18next';

const CustomizedLabel = (props) => {
  const { x, y, stroke, 
    // value 
  } = props;
  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {/* {value} */}
    </text>
  );
};

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
    <text
     x={10}
     y={0}
     dy={8}
     dx={8}
     textAnchor="end"
     fill="#666"
     fontSize={11}
     transform="rotate(0)"
   >
     {payload.value}
   </text>
   </g>
  );
};

const CustomizedYAxisTick = (props) => {
  const { x, y, 
    payload
   } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-10}
        textAnchor="end"
        fill="#666"
        fontSize={12}
        transform="rotate(0)"
      >
        {payload.value.toString().includes('.')?'':payload.value}
      </text>      
    </g>
  );
};
const CustomTooltip = (props) =>{
   const { ready, t } = useTranslation();
   const {active,payload,
    // label,
    type} = props;
   if(active && payload?.length){
    return (
      <div style={{
        display:'flex',flexDirection:'column',
        backgroundColor:'rgba(255,255,255,.85)',
        border:'1px solid #3E8AFF',
        padding:'10px',borderRadius:'10px'
      }}>
        {/* <span>{label}</span> */}
        <span>
          {payload?.slice(0,1)?.map((ele,index) =>(
            <small key={index}>
              {
                `${ready && t('DASHBOARD_CHART_INVOICES')} ${
                  type === 0 ? ready && t('DASHBOARD_OVERVIEW_DIGITIZED')
                  : type === 1 ? ready && t('DASHBOARD_OVERVIEW_UPLOADING')
                    : type === 2 ? ready && t('DASHBOARD_OVERVIEW_REVIEW')
                      : type === 3 ? ready && t('DASHBOARD_OVERVIEW_VALIDATE')
                        : type === 4 ? ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')
                          : type === 5 ? ready && t('DASHBOARD_OVERVIEW_EXPORTED')
                            : type === 6 ? ready && t('DASHBOARD_OVERVIEW_REJECTED')
                              : t('DASHBOARD_OVERVIEW_ERRORED')
                }`
              // ele.name
              }:<span style={{display:'inline-block',marginLeft:'10px'}}>{ele.value}</span>
            </small>
          ))}
        </span>

      </div>
    )
   }
   return null;
}

const Chart = ({chart_data,type,invoiceType}) => {
  const { t } = useTranslation();
  let x_values = type === 0?'Date':type === 1?'Week':type=== 2?'Month':type===3?'Quarter':'Year';
  return(
    <ResponsiveContainer height={320} width="100%">
      <ComposedChart
        width={700}
        height={280}
        data={chart_data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="6 6" vertical={false} />
        <XAxis dataKey={x_values} height={60} padding={{ left: 5, right: 5 }} tick={<CustomizedXAxisTick type={type} />} />
        <YAxis dataKey={`${t('DASHBOARD_CHART_Y_AXIS_LABEL')}`} height={60}  tick={<CustomizedYAxisTick />} />
        <Tooltip content={<CustomTooltip type={invoiceType} active={false} payload={[]} label={""} />} />
        {/* <Legend /> */}
        <Area type="monotone" stroke="#3E8AFF" dataKey={`${t('DASHBOARD_CHART_Y_AXIS_LABEL')}`} fill="#8fb9f7" >
          {/* <LabelList content={<CustomizedLabel />} /> */}
        </Area>
        <Line type="monotone" dataKey={`${t('DASHBOARD_CHART_Y_AXIS_LABEL')}`} stroke="#3E8AFF">
          <LabelList content={<CustomizedLabel />} />
        </Line>
        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default Chart