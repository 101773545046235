const styles = {
  main: {
    padding: '4px 16px 0px 16px',
    height: '108px',
    borderBottom: '1px solid #4C4E641F',
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  backBtn: {
    border: '1px solid #4C4E6438',
    borderRadius: '4px',
    padding: '4px',
  },
  backIcon: {
    height: '20px',
    width: '20px',
    color: '#6D788D',
  },
  headerInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0px 0px 0px 8px',
  },
  docName: {
    fontSize: 16,
    fontWeight: 600,
    color: '#4C4E64'
  },
  infoIcon: {
    color: '#6D788D',
    size: 20,
  },
  tagsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: '600',
    width: '108px',
    height: '32px',
  },
  statusChangeBtn: {
    padding: '4px 8px',
    height: 32,
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    border: '1px solid #4C4E6438',
    borderRadius: '4px',
    textTransform: 'none' as const,
    justifyContent: 'space-between',
    '&:hover': {
      background: '#FFFFFF',
    },
    '& .MuiButton-startIcon': {
      marginLeft: '0px',
    }
  },
  statusIcon: {
    size: 20,
    width: '20px',
    height: '20px'
  },
  statusChangeText: {
    color: '#4C4E64',
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dropDownIcon: {
    color: '#4C4E648A',
    size: '24px',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
  },
  changeStatusLabel: {
    height: '24px',
    fontSize: '10px',
    fontWeight: 500,
    color: '#4C4E64',
    opacity: 0.5,
    textTransform: 'uppercase' as const,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    }
  },
  changeStatusOptions: {
    padding: '6px 10px',
    color: '#4C4E64',
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      background: '#C2CFE033',
    },
  },
  changeStatusIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  manageFieldsBtn: {
    height: 32,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '8px',
    paddingRight: '8px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: '4px',
    marginLeft: '12px',
  },
  manageFieldsIcon: {
    height: 20,
    width: 20,
    color: '#6D788D',
  },
  addTagBtn: {
    borderRadius: '4px',
    height: '20px',
    backgroundColor: '#C2CFE033',
    marginRight: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    fontSize: '11px',
    fontWeight: 500,
    color: '#4C4E64DE',
    textTransform: 'none',
  },
  editTagsIcon: {
    color: '#6D788D',
    height: '12px',
    width: '12px',
  },
  errorReasonIcon: {
    color: '#FF4D49',
    height: '20px',
    width: '20px',
    marginLeft: '12px',
  }
};

export default styles;
