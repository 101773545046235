import theme from "src/theme";

const styles = {
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    alignItems: 'center',
    paddingBottom: 80,
    padding: theme.spacing(3)
  }
};

export default styles;