import Axios from "axios";
import {
  FETCH_VATCODES_FAIL,
  FETCH_VATCODES_START,
  FETCH_VATCODES_SUCCESS,
  FETCH_VATCODE_LIST_FAIL,
  FETCH_VATCODE_LIST_START,
  FETCH_VATCODE_LIST_SUCCESS
} from "src/actionTypes";
import { axiosHeaders } from "src/utils/helpers";

const fetchVatCodesStart = () => {
  return {
    type: FETCH_VATCODES_START,
  };
};

const fetchVatCodesSuccess = (vatCodes, total) => {
  return {
    type: FETCH_VATCODES_SUCCESS,
    payload: { vatCodes, total },
  };
};

const fetchVatCodesFail = (error) => {
  return {
    type: FETCH_VATCODES_FAIL,
    payload: { error },
  };
}

export const fetchVatCodes = (companyID, search = '') => {
  return async dispatch => {
    dispatch(fetchVatCodesStart());
    try {
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/dimensions/vatcodes/companies/${companyID}?search=${search}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        dispatch(fetchVatCodesSuccess(response.data.data, response.data.total));
      } else {
        dispatch(fetchVatCodesFail(response.data.message));
      }
    } catch (error) {
      dispatch(fetchVatCodesFail(error.response.data.message));
    }
  };
};

const fetchVatCodeListStart = () => {
  return {
    type: FETCH_VATCODE_LIST_START,
  };
};

const fetchVatCodeListSuccess = (vatCodeList, total) => {
  return {
    type: FETCH_VATCODE_LIST_SUCCESS,
    payload: { vatCodeList, total },
  };
};

const fetchVatCodeListFail = (error) => {
  return {
    type: FETCH_VATCODE_LIST_FAIL,
    payload: { error },
  };
}

export const fetchVatCodeList = (companyID, page = 0, limit = 10, search = '') => {
  return async dispatch => {
    dispatch(fetchVatCodeListStart());
    try {
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/dimensions/vatcodes/companies/${companyID}?page=${page + 1}&limit=${limit}&search=${search}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        dispatch(fetchVatCodeListSuccess(response.data.data, response.data.total));
      } else {
        dispatch(fetchVatCodeListFail(response.data.message));
      }
    } catch (error) {
      dispatch(fetchVatCodeListFail(error.response.data.message));
    }
  };
};
