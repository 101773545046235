import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

import {
  Box, IconButton, Typography, SvgIcon, Table, TableBody,
  TableCell, TableHead, TableRow, Grid, Avatar,
  Hidden, Menu, MenuItem, Tooltip, Card, TablePagination
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import {
  permissions, isActionPermitted, getDocumentType
} from 'src/utils/helpers';
import styles from './style';

function CompaniesTable({
  loading, menuCompanyID, menuAnchorEl, handleOpenMenu, handleCloseMenu, companies, onEditCompany,
  onSwitchToCompany, onDeleteCompany, maxWidth, total, page, limit, maxWidthRows, handlePageChange, handleLimitChange
}) {
  const { t, ready } = useTranslation();
  
  const auth = useAuth();
  const {user} = auth;

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Card style={styles.root}>
      <PerfectScrollbar>
        <Box minWidth="100%">
          <Table size="small">
            <TableHead>
              <TableRow style={styles.row}>
                <TableCell style={styles.headerStyle} align="left">
                  {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
                </TableCell>
                <Hidden only={['xs', 'sm']}>
                  <TableCell style={styles.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  <TableCell style={styles.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_CONNECTED_TO')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  <TableCell style={styles.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_DOC_TYPE')}
                  </TableCell>
                </Hidden>
                <TableCell style={styles.headerStyle} align="left">
                  {ready && t('ACCOUNT_SETTINGS_COMPANIES_INVOICE_LIMIT')}
                </TableCell>
                <Hidden only={['xs', 'sm', 'md']}>
                  <TableCell style={styles.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_OWNER')}
                  </TableCell>
                </Hidden>
                <TableCell style={styles.headerStyle} />
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((c) => (
                <TableRow
                  hover
                  key={c.id}
                  style={styles.pointer}
                >
                  <TableCell style={styles.cellStyle} align="left">
                    {c.name}
                  </TableCell>
                  <Hidden only={['xs', 'sm']}>
                    <TableCell style={styles.cellStyle} align="left">
                      <Grid style={styles.centerCell}>
                        <Hidden only={['xs']}>
                          <Grid style={{...styles[c.isSubCompany ? 'sub' : 'main'], ...styles.statusLabel}}>
                            {c.isSubCompany ? 'subsidiary' : 'main'}
                          </Grid>
                        </Hidden>
                        <Hidden smUp>
                          <Grid style={{...styles[`${c.isSubCompany ? 'sub' : 'main'}Dot`], ...styles.statusDot}} />
                        </Hidden>
                      </Grid>
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'sm', 'md']}>
                    <TableCell style={styles.cellStyle} align="left">
                      {c.parentName}
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'sm', 'md']}>
                    <TableCell style={styles.cellStyle} align="left">
                      {t(getDocumentType(c.documentType))}
                    </TableCell>
                  </Hidden>
                  <TableCell style={styles.cellStyle} align="left">
                    {c.invoicesLimit ? `${c.invoicesUsed || 0}/${c.invoicesLimit || 0}` : c.invoicesUsed || '-'}
                  </TableCell>
                  <Hidden only={['xs', 'sm', 'md']}>
                    <TableCell style={styles.cellStyle} align="left">
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Avatar
                          alt="user"
                          style={styles.userImage}
                          src={c.avatar}
                        />
                        <Box ml={2} mr={2} display="flex" flexDirection="column">
                          <Typography
                            color="inherit"
                            to="#"
                            variant="h6"
                            style={styles.cellStyle}
                          >
                            {`${c.firstname} ${c.lastname}`}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </Hidden>
                  <TableCell width={56} align="center">
                    <IconButton
                      onClick={(e) => handleOpenMenu(e, c.id)}
                    >
                      <SvgIcon style={{ display: 'flex' }}>
                        <MoreVertIcon style={styles.moreIcon} />
                      </SvgIcon>
                    </IconButton>
                    <Menu
                      id="user-actions"
                      anchorEl={menuAnchorEl}
                      keepMounted
                      open={Boolean(menuAnchorEl) && menuCompanyID === c.id}
                      onClose={handleCloseMenu}
                    >
                      {
                        <Tooltip
                          title={isActionPermitted(permissions.companyManage, user.permissions)
                            ? ''
                            : t('ACCOUNT_SETTINGS_COMPANIES_EDIT_PERMISSION')}
                        >
                          <span>
                            <MenuItem onClick={(e) => onEditCompany(e, c)} disabled={!isActionPermitted(permissions.companyManage, user.permissions)}>
                              {ready && t('ACCOUNT_SETTINGS_COMPANIES_EDIT_COMPANY')}
                            </MenuItem>
                          </span>
                        </Tooltip>
                      }
                      {
                        c?.companyID?.toString() !== user?.companyID && c?.isMember && (
                        <MenuItem onClick={(e) => onSwitchToCompany(e, c)}>
                          {ready && t('ACCOUNT_SETTINGS_COMPANIES_SWITCH_TO_COMPANY')}
                        </MenuItem>
                        )
                      }
                      {
                        c?.companyID?.toString() !== user?.companyID && c?.canManageCompany && c?.isSubCompany && (
                          <MenuItem onClick={(e) => onDeleteCompany(e, c)}>
                            {ready && t('ACCOUNT_SETTINGS_COMPANIES_DELETE_BTN')}
                          </MenuItem>
                        )
                      }
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <div style={styles.caption}>
        {
          !maxWidth && `${total} compan${total !== 1 ? 'ies' : 'y'} available`
        }
        <TablePagination
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
        />
      </div>
    </Card>
  );
}

// CompaniesTable.propTypes = {
//   className: PropTypes.string,
//   rest: PropTypes.object,
//   loading: PropTypes.bool,
//   menuCompanyID: PropTypes.object,
//   menuAnchorEl: PropTypes.object,
//   handleOpenMenu: PropTypes.func,
//   handleCloseMenu: PropTypes.func,
//   onEditCompany: PropTypes.func,
//   onSwitchToCompany: PropTypes.func,
//   onDeleteCompany: PropTypes.func,
//   companies: PropTypes.array,
//   maxWidth: PropTypes.bool,
//   total: PropTypes.number,
//   page: PropTypes.number,
//   limit: PropTypes.number,
//   maxWidthRows: PropTypes.bool,
//   handlePageChange: PropTypes.func,
//   handleLimitChange: PropTypes.func
// };

export default CompaniesTable;
