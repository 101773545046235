import { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

const styles = {
  root: {
    paddingBottom: '80px !important'
  },
};

const Page = forwardRef(({
  title,
  children,
  style,
  ...rest
}, ref) => {

  return (
    <div
      ref={ref}
      style={{...styles.root, ...style}}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

export default Page;
