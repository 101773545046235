import { useEffect, useState, useRef } from 'react';
import Axios from 'axios';

import { Box, Grid } from '@mui/material';

import StatsDashboard from 'src/dashboard-new/components/StatsDashboard/StatsDashboard';
import ChartDashboard from 'src/dashboard-new/components/ChartDashboard/ChartDashboard';
import { useConfig } from 'src/hooks/useConfig';
import { axiosHeaders } from 'src/utils/helpers';
import Spinner from 'src/shared/components/Spinner/Spinner';
import { statsPageRefreshTimerNew } from 'src/config';
import styles from './style';

let apiTimer: any = null;

const DashboardOverview = () => {
  const { API } = useConfig();
  const [showChart, setShowChart] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [overviewStats, setOverviewStats] = useState({
    uploadingTot: 0,
    digitizedTot: 0,
    toreviewTot: 0,
    tovalidateTot: 0,
    toexportTot: 0,
    exportedTot: 0,
    rejectedTot: 0,
    archivedTot: 0,
    erroredTot: 0,
    total:0,
  })
  const [graphData, setGraphData] = useState({})
  const statsLastModifiedTime = useRef<number>(0);
  const graphLastModifiedTime = useRef<number>(0);

  const handleShowChart = (show: boolean) => {
    if (show) {
      fetchGraphData();
    }
    setShowChart(show);
  };

  const onGetNewAppInvoiceStats = async () => {
    try {
      const response = await Axios.get(
        `${API.getStatsOverview}?modifiedTime=${statsLastModifiedTime.current}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (response.status === 304) return;
      if (response.data.success) {
        setOverviewStats((overview_stats)=>({
          ...overview_stats,
          uploadingTot: response.data.data?.processing || 0,
          digitizedTot: response.data.data?.digitised || 0,
          toreviewTot: response.data.data?.toreview || 0,
          tovalidateTot: response.data.data?.tovalidate || 0,
          toexportTot: response.data.data?.toexport || 0,
          exportedTot: response.data.data?.exported || 0,
          rejectedTot: response.data.data?.rejected|| 0,
          archivedTot: response.data.data?.archived || 0,
          erroredTot: response.data.data?.errored || 0,
          total:response.data.data?.total || 0
        }))
        if (response.data.modifiedTime) statsLastModifiedTime.current = response.data.modifiedTime;
      }
      else {
        console.error("error ocurred")
      }
      setDataLoaded(true);
    }
    catch (error) {
      setDataLoaded(true);
    }
  }

  const fetchGraphData = async () => {
    try {
      const response = await Axios.get(
        `${API.getStatsGraph}?modifiedTime=${graphLastModifiedTime.current}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.status === 304) return;
      if (response.data.success) {
        setGraphData(response.data.data);
        if (response.data.modifiedTime) graphLastModifiedTime.current = response.data.modifiedTime;
      }
    }
    catch (error) {
      //
    }
  };

  const onStartInterval = () => {
    apiTimer = setInterval(async () => {
      onGetNewAppInvoiceStats();
    }, statsPageRefreshTimerNew);
  };

  useEffect(() => {
    onGetNewAppInvoiceStats();
    onStartInterval();
    return () => {
      clearInterval(apiTimer)
    }
  }, []);

  return (
    <Grid sx={styles.container}>
      <Box sx={styles.statsContent}>
        {!dataLoaded ? <Spinner height="128px" /> :
          <>
            <StatsDashboard overview_stats={overviewStats} setShowChart={handleShowChart} showChart={showChart}  />
            {
              showChart
              ? <ChartDashboard graph_data={{...graphData}} />
              : <></>
            }
          </>
        }
      </Box>
    </Grid>
  )
}

export default DashboardOverview
