const styles = {
  root: {
    zIndex: 111111,
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 3px 14px 2px #4C4E641F',
    overflow: 'hidden'
  },
  fieldsContainer: {
    borderRadius: '10px',
    maxHeight: '400px',
  },
  fieldItem: {
    color: '#4C4E64DE',
    fontSize: '14px',
    fontWeight: 400,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px'
  },
  icon: {
    color: '#4C4E648A',
    height: '20px',
    width: '20px',
  },
  checkBox: {
    padding: '0px',
    marginRight: '8px',
    color: '#C2CFE099',
    '&.Mui-checked': {
      borderRadius: '4px',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px',
    boxShadow: '0px -4px 8px -4px #4C4E646B',
  },
  cancelButton: {
    height: '32px',
    padding: '0px 10px',
    marginRight: '8px',
    color: '#4C4E64',
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    border: '1px solid #4C4E6433',
    borderRadius: '4px',
    boxShadow: '0px 4px 8px -4px #4C4E646B',
  },
  saveButton: {
    height: '32px',
    padding: '0px 10px',
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: '#3E8AFF',
    borderRadius: '4px',
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    '&:hover': {
      backgroundColor: '#3E8AFF',
    }
  }
}

export default styles;