import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
  Box, Container, Typography, LinearProgress, Grid, Button
} from '@mui/material';

import Page from 'src/shared/screens/Page';
import authService from 'src/utils/authService';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import theme from 'src/theme';

const InviteUserCallback = (props) => {
  const { BRAND_NAME, LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const { t, ready } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [loginReq, setLoginReq] = useState(false);

  useEffect(() => {
    const { match } = props;
    const token = match?.params?.token || '';

    const executeCallback = async () => {
      setLoading(true);
      setMessage('');
      setLoginReq(false);

      try {
        const response = await Axios.get(
          `${API.inviteUserCallback}${token}`,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          // Check if the user is already logged in.
          if (authService.isAuthenticated()) {
            authService.setSession(token, 'user', null);
            window.location.href = '/account';
          } else {
            authService.setSession(null);
            setLoginReq(true);
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.data) {
          setMessage(
            appendContactSupport(
              window.config.support_email,
              getLocalisedErrorString(e?.response?.data?.i18n || 'INVITE_USER_CALL_BACK_ERROR', t), t
            )
          );
        }
      }
    };

    executeCallback();
  }, []);

  const login = () => {
    window.location.href = `${LOGIN_PANEL_URL}?language=${i18next.language.split('-')[0]}`;
  };

  return (
    <Page
      style={styles.root}
      title={ready && t('INVITE_VERIFICATION_PAGE_TITLE', { brand: BRAND_NAME })}
    >
      {
        loading && ready && (
          <Container maxWidth="md" style={styles.containerTopPadding}>
            <Box
              mb={8}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Box mb={4} display="flex" alignItems="center" style={{ paddingTop: 60 }}>
                <Typography variant="h2" color="textPrimary" align="center">
                  {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_PROCESSING')}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="textSecondary" align="center">
                {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_PROCESSING_MESSAGE')}
              </Typography>
              <Box maxWidth={400} width="75%" style={styles.loadingIndicator}>
                <LinearProgress />
              </Box>
            </Box>
          </Container>
        )
      }
      {
        (message && !loginReq) && ready && (
          <Grid style={styles.containerTopPadding}>
            <Typography style={styles.failTitle} align="center">
              {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_FAILED')}
            </Typography>
            <Typography style={styles.failMessage} align="center">
              {message}
            </Typography>
          </Grid>
        )
      }
      {
        (loginReq && !message) && ready && (
          <Grid style={styles.successContainer}>
            <Typography style={styles.successTitle} align="center">
              {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_SUCCESS')}
            </Typography>
            <Typography style={styles.successMessage} align="center">
              {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_SUCCESS_MESSAGE')}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              style={styles.action}
              onClick={login}
            >
              {ready && t('INVITE_LOGIN')}
            </Button>
          </Grid>
        )
      }
    </Page>
  );
};

export default InviteUserCallback;

const styles = {
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    padding: 0
  },
  loadingIndicator: {
    marginTop: 30
  },
  failTitle: {
    fontSize: 20
  },
  failMessage: {
    fontSize: 13,
    marginTop: 16,
    color: '#EB5757'
  },
  successTitle: {
    fontSize: 20
  },
  successMessage: {
    fontSize: 13,
    marginTop: 16
  },
  action: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    marginTop: 16,
    backgroundColor: theme.palette.primary.main,
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 60
  },
  containerTopPadding: {
    paddingTop: 60
  }
};
