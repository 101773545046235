import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, FormControl,
  FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Autocomplete
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  AddBox as PlusIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import {
  isActionPermitted, permissions
} from 'src/utils/helpers';
import {
  supplierData, vatCodeFields, vatCodeTypes
} from 'src/config';
import theme from 'src/theme';
import styles from './style';

const VatCodeTwinfield = (props) => {
  const { t, ready } = useTranslation();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
    touched,
    values,
    vatCode,
    handleClose,
    hasError,
    handleChangeAutocomplete,
    getLabel,
    costCenters,
    glaccounts,
    handleEditCostCenter,
    handleAddCostCenter,
    newlyAddedVal,
    newlyAddingProp,
  } = props;
  
  const auth = useAuth();
  const {user} = auth;

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } });
    }
  }, [newlyAddedVal]);

  return (
    <form style={styles.root}>
      <Card>
        <CardContent style={{ padding: 32 }}>
          <Grid container spacing={3}>
            {
                vatCodeFields.map((m) => (
                  <Accordion
                    key={m.title}
                    style={styles.accordionGroup}
                    defaultExpanded
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={m.title}
                      id={m.title}
                    >
                      <Grid style={styles.accordionHeading}>
                        {ready && t(m.title)}
                        {
                          hasError(m.fields, errors) && (
                            <Chip
                              color="error"
                              variant="default"
                              size="small"
                              label="Error"
                              style={styles.errorChip}
                            />
                          )
                        }
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3}>
                        {
                          m.fields.map((f) => {
                            switch (f.key) {
                              case 'type':
                                return (
                                  <Grid item lg={4} md={6} xs={12} key={f.key}>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                      <Select
                                        labelId={`${f.key}-label`}
                                        id={f.key}
                                        error={Boolean(touched[f.key] && errors[f.key])}
                                        helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                        value={values[f.key]}
                                        onClick={() => setErrors({})}
                                        onChange={handleChange}
                                        name={f.key}
                                        label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                        required={f.required}
                                        sx={{
                                          '& .MuiSelect-select': styles.selectStyle
                                        }}
                                      >
                                        {
                                          vatCodeTypes && vatCodeTypes.length > 0
                                            && vatCodeTypes.map((s) => {
                                              if (s) {
                                                return (
                                                  <MenuItem key={s.name} value={s.name}>
                                                    {t(s.label)}
                                                  </MenuItem>
                                                );
                                              }

                                              return null;
                                            })
                                        }
                                      </Select>
                                      <FormHelperText error>
                                        {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                );
                              case 'ruleCostCenter':
                                return (
                                  <Grid item lg={4} md={6} xs={12} key={f.key}>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <Autocomplete
                                        fullWidth
                                        name="ruleCostCenter"
                                        onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'ruleCostCenter', v, handleChange)}
                                        value={getLabel(costCenters, values.ruleCostCenter)}
                                        options={costCenters}
                                        getOptionLabel={
                                          (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                                        }
                                        getOptionSelected={(option) => option.code === (values.ruleCostCenter ? values.ruleCostCenter : ' ')}
                                        renderInput={(params) => (
                                          <TextField
                                            fullWidth
                                            {...params}
                                            label={ready && t(f.label)}
                                            variant="outlined"
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <>
                                                  {values[f.key] !== supplierData.defaultCostCenters[0].code && (
                                                    <InputAdornment
                                                      position="end"
                                                      onClick={() => handleEditCostCenter(values[f.key])}
                                                      style={styles.editIconContainer}
                                                    >
                                                      <EditIcon style={styles.editDimensionIcon} />
                                                    </InputAdornment>
                                                  )}
                                                  <InputAdornment position="end" style={styles.plusIconContainer}>
                                                    <PlusIcon style={styles.plusIcon} onClick={() => handleAddCostCenter(f.key)} />
                                                  </InputAdornment>
                                                </>
                                              ),
                                            }}
                                            style={styles.selectStyle}
                                          />
                                        )}
                                      />
                                      <FormHelperText error>
                                        {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                );
                              case 'ruleAccount':
                                return (
                                  <Grid item lg={4} md={6} xs={12} key={f.key}>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <Autocomplete
                                        fullWidth
                                        name="ruleAccount"
                                        onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'ruleAccount', v, handleChange)}
                                        value={getLabel(glaccounts, values.ruleAccount)}
                                        options={glaccounts}
                                        getOptionLabel={
                                          (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                                        }
                                        getOptionSelected={(option) => option.code === (values.ruleAccount ? values.ruleAccount : ' ')}
                                        renderInput={(params) => (
                                          <TextField
                                            fullWidth
                                            {...params}
                                            label={ready && t(f.label)}
                                            variant="outlined"
                                            InputProps={{
                                              ...params.InputProps
                                            }}
                                            style={styles.selectStyle}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                );
                              default:
                                return (
                                  <Tooltip title={(!vatCode || f.key !== 'code') ? '' : t('DIMENSION_CODE_CANNOT_EDIT')}>
                                    <Grid item lg={4} md={6} xs={12} key={f.key}>
                                      <TextField
                                        error={Boolean(touched[f.key] && errors[f.key])}
                                        fullWidth
                                        helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                        label={ready && t(f.label)}
                                        name={f.key}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required={f.required}
                                        value={values[f.key]}
                                        variant="outlined"
                                        style={styles.inputField}
                                        disabled={vatCode && f.key === 'code'}
                                      />
                                    </Grid>
                                  </Tooltip>
                                );
                            }
                          })
                        }
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            <Grid
              item
              xs={12}
            >
              {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
              )}
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  style={{ backgroundColor: theme.palette.button.action }}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user.permissions)}
                  onClick={handleSubmit}
                >
                  {ready && t('VAT_CODES_SAVE_CHANGES')}
                </Button>
                <Button
                  variant="text"
                  onClick={handleClose}
                  style={styles.yearField}
                >
                  {ready && t('VAT_CODES_CANCEL')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

// VatCodeTwinfield.propTypes = {
//   values: PropTypes.object,
//   errors: PropTypes.object,
//   touched: PropTypes.object,
//   setErrors: PropTypes.func,
//   handleBlur: PropTypes.func,
//   handleSubmit: PropTypes.func,
//   isSubmitting: PropTypes.bool,
//   handleEditSupplierClose: PropTypes.func,
//   handleChange: PropTypes.func,
//   handleClose: PropTypes.func,
//   className: PropTypes.string,
//   vatCode: PropTypes.object,
//   hasError: PropTypes.func,
//   handleChangeAutocomplete: PropTypes.func,
//   getLabel: PropTypes.func,
//   costCenters: PropTypes.array,
//   glaccounts: PropTypes.array,
//   onSuccess: PropTypes.func,
//   handleEditCostCenter: PropTypes.func,
//   handleAddCostCenter: PropTypes.func,
//   newlyAddedVal: PropTypes.string,
//   newlyAddingProp: PropTypes.string,
// };

export default VatCodeTwinfield;
