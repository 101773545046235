const styles = {
  passwordHelper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  strengthBar: {
    marginTop: '-13px',
    textAlign: 'left!important',
    '& > div': {
      width: '100%',
      textAlign: 'left',
    },
    '& > div > div': {
      height: '4px !important',
      width: '0px!important',
      marginLeft: '0px',
      textAlign: 'left',
    },
  },
  scoreWordsStyle: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    textAlign: 'left',
  },
  helperText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '16px'
  },
  helperIcon: {
    width: 18,
    height: 18,
    color: '#949499',
    fontSize: '11px',
    fontWeight: '400',
    padding: 2,
    marginLeft: 20,
    marginRight: 6,
    border: '1px solid #E1E2E3',
    borderRadius: 2,
    boxSizing: 'border-box',
  }
};

export default styles;
