import {
  FETCH_VATCODES_FAIL,
  FETCH_VATCODES_START,
  FETCH_VATCODES_SUCCESS,
  FETCH_VATCODE_LIST_FAIL,
  FETCH_VATCODE_LIST_START,
  FETCH_VATCODE_LIST_SUCCESS
} from "../../actionTypes";

const initialState = {
  vatCodes: [],
  vatCodeList: [],
  total: 0,
  loading: false,
  error: null
};

const vatCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VATCODES_START:
      return {...state, loading: true, error: null};
    case FETCH_VATCODES_SUCCESS:
      return {...state, vatCodes: action.payload.vatCodes, total: action.payload.total, loading: false, error: null};
    case FETCH_VATCODES_FAIL:
      return {...state, loading: false, error: action.payload.error};
    case FETCH_VATCODE_LIST_START:
      return {...state, loading: true, error: null};
    case FETCH_VATCODE_LIST_SUCCESS:
      return {...state, vatCodeList: action.payload.vatCodeList, total: action.payload.total, loading: false, error: null};
    case FETCH_VATCODE_LIST_FAIL:
      return {...state, loading: false, error: action.payload.error};
    default:
      return state;
  }
};

export default vatCodeReducer;
