const styles = {
  summaryStats: {
    padding: '16px 20px',
    border: '1px solid #DDDDDD',
    borderRadius: 4,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
  },
  topLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 12
  },
  overviewText: {
    flex: 1,
    fontSize: 11,
    lineHeight: '16.5px',
    fontWeight: 500,
    color: 'rgba(51,77,110,0.5)'
  },
  overviewTabs: {
    flex: 1
  },
  toggleBtn: {
    textTransform: 'none',
    padding: '6px 16px',
    borderWidth: 0,
  },
  overviewActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    height: 16,
    color: '#C2CFE0',
    cursor: 'pointer'
  },
  menuBtn: {
    textTransform: 'none',
    fontSize: 11,
    fontWeight: 500,
    color: '#334D6E',
    border: '1px solid rgba(144,160,183,0.2)',
    paddingRight: 2,
    borderRadius: 2,
    marginRight: 4
  },
  moreIcon: {
    height: 16,
    color: '#334D6E'
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 217,
  },
  statsCompany: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 248,
  },
  statsColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  statsColumnSingle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  emptyStat: {
    height: 61
  },
  emptyStatHv: {
    width: 120,
  },
  errorLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dullText: {
    fontSize: 13,
    lineHeight: '19.5px',
    color: '#334D6E'
  },
  blueText: {
    fontSize: 13,
    lineHeight: '19.5px',
    color: '#3E8AFF'
  },
  greenText: {
    fontSize: 13,
    lineHeight: '19.5px',
    color: '#3CB573'
  },
  darkGreenText: {
    fontSize: 13,
    lineHeight: '19.5px',
    color: '#2A7E50'
  },
  yellowText: {
    fontSize: 13,
    lineHeight: '19.5px',
    color: '#F5B140'
  },
  orangeText: {
    fontSize: 13,
    lineHeight: '19.5px',
    color: '#EF6C00'
  },
  redText: {
    fontSize: 13,
    lineHeight: '19.5px',
    color: '#EB5757'
  },
  errorIcon: {
    height: 12,
    width: 12,
    color: '#EB5757',
    marginRight: 6
  },
  overviewNumber: {
    color: '#334D6E',
    fontSize: 28
  },
  overviewErrorNumber: {
    color: '#EB5757',
    fontSize: 28
  },
  hidden: {
    visibility: 'hidden'
  },
  placeholder: {
    height: '61px'
  }
};

export default styles;
