import { useTranslation } from 'react-i18next';

import { Select, MenuItem, Typography } from '@mui/material';

import theme from "src/theme";

const SimpleSelect = ({
  filter, filterOptions, setFilter, label
}) => {
  const { t } = useTranslation();

  return (
    <>
      {
        label
          && <Typography style={styles.selectLabel}>{label}</Typography>
      }
      <Select
        style={styles.root}
        value={filter}
        onChange={setFilter}
        sx={{'& > div':{paddingRight:'32px !important',fontWeight:'500'},paddingRight:'0 !important','& > fieldset':{border:'none'}}}
      >
        {
          filterOptions && filterOptions.length > 0 && filterOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} style={styles.option}>
              {t(option.label)}
            </MenuItem>
          ))
        }
      </Select>
    </>
  );
};

export default SimpleSelect;

const styles = {
  root: {
    margin: '0px 10px',
    color: theme.palette.text.highlight2,
    fontSize: 14,
    paddingRight: 32
  },
  option: {
    fontSize: 14
  },
  selectLabel: {
    color: theme.palette.text.caption,
    fontSize: 14
  }
};
