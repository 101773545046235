const styles = {
  modal:{
    width:'100dvw',
    height:'100%',
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff',
  },
  root:{
    display:'flex',
    padding:'0',
    margin:'0',
    height:'100%',
    width:'100%',
    position:'relative',
    zIndex:'1',
  },
}

export default styles;