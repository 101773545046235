import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import {
  axiosHeaders, isActionPermitted, permissions
} from 'src/utils/helpers';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import ConfigContext from 'src/contexts/ConfigContext';
import CostCenterTwinfield from 'src/dimensions/costcenters/components/CostCenterModal/CostCenterTwinfield';
import CostCenterStandard from 'src/dimensions/costcenters/components/CostCenterModal/CostCenterStandard';
import styles from './style';

function CostCenterModal(props) {
  const { t } = useTranslation();

  const {
    costCenterModalOpen,
    selectedCostCenter,
    handleClose,
    getAllCostCenters,
    onSuccess,
    className,
  } = props;

  const { API } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;

  const [costCenter, setCostCenter] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCostCenterByCode = async (code) => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.costCenterByCode}/${encodeURIComponent(code)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setCostCenter(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user.permissions)) {
      if (selectedCostCenter) {
        getCostCenterByCode(selectedCostCenter);
      } else {
        setCostCenter(null);
      }
    }
  }, [selectedCostCenter]);

  const hasError = (fields, errors) => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        // Returning true until Twinfield integration
        return false;
      }
    }

    return false;
  };

  return (
    <Modal
      style={styles.modal}
      open={costCenterModalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={costCenterModalOpen}>
        <div style={styles.halfPaper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {!loading
                && (costCenter?.name
                  ? `${t('COST_CENTER')} - ${costCenter ? costCenter.name : ''} (${costCenter?.code})`
                  : t('COST_CENTER_ADD_NEW_COST_CENTER'))}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          {loading ? <LoadingScreen /> : (
            user.interfacePreference === 'twinfield_interface'
              ? (
                <CostCenterTwinfield
                  costCenter={costCenter}
                  getAllCostCenters={getAllCostCenters}
                  handleClose={handleClose}
                  hasError={hasError}
                  style={className}
                  onSuccess={onSuccess}
                />
              ) : (
                <CostCenterStandard
                  costCenter={costCenter}
                  getAllCostCenters={getAllCostCenters}
                  handleClose={handleClose}
                  hasError={hasError}
                  style={className}
                  onSuccess={onSuccess}
                />
              )
          )}
        </div>
      </Fade>
    </Modal>
  );
}

// CostCenterModal.propTypes = {
//   costCenterModalOpen: PropTypes.bool,
//   selectedCostCenter: PropTypes.string,
//   handleClose: PropTypes.func,
//   getAllCostCenters: PropTypes.func,
//   onSuccess: PropTypes.func,
//   className: PropTypes.string,
// };

export default withRouter(CostCenterModal);
