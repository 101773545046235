import theme from 'src/theme';

const styles = {
  container: {
    margin: '24px 0px'
  },
  titleText: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  inputsContainer: {
    padding: '16px 0px',
  },
  formInput: {
    padding: '0px 16px',
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    margin: '8px 0px',
    fontWeight: 500
  },
  uploadsContainer: {
    padding: '16px 0px',
    display: 'flex',
    flexDirection: 'row',
  },
  uploadItem: {
    width: '25%',
    padding: '0px 16px',
  },
  brandLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    padding: '12px 0px',
    height: '60px'
  },
  brandFavicon: {
    maxWidth: 40,
    maxHeight: 40
  },
  brandLogo: {
    maxWidth: 120,
    maxHeight: 40
  },
  logoUploadTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  uploadBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: theme.palette.button.cancel,
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    border: theme.palette.border.cancel,
    margin: '6px 0px 6px 0px'
  },
  logoUploadDescr: {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
  btnIcon: {
    height: 24,
    width: 24,
    marginLeft: 8,
    color: theme.palette.text.primary,
  },
  btnIconDisabled: {
    height: 24,
    width: 24,
    marginLeft: 8,
    color: 'rgba(0, 0, 0, 0.26)',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0px 24px'
  },
};

export default styles;
