import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button, Container, Typography, useTheme, useMediaQuery, alpha } from '@mui/material';

import Page from 'src/shared/screens/Page';
import theme from 'src/theme';
import { useConfig } from 'src/hooks/useConfig';

const Error404 = () => {
  const { t, ready } = useTranslation();
  const { BRAND_NAME } = useConfig();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page
      style={styles.root}
      title={ready && `${t('404_TITLE')} | ${BRAND_NAME}`}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h6' : 'h4'}
          color="textPrimary"
          fontWeight={500}
        >
          {ready && t('404_PAGE_TITLE')}
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          color="textSecondary"
        >
          {ready && t('404_PAGE_DESCRIPTION')}
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="Under development"
            style={styles.image}
            src="/static/images/page_not_found.svg"
          />
        </Box>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <Button
            style={styles.btn}
            component={RouterLink}
            to="/"
            variant="outlined"
          >
            {ready && t('404_PAGE_BACK_HOME')}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default Error404;

const styles = {
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(3),
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  btn: {
    color: theme.palette.button.action,
    border: `1px ${alpha(theme.palette.secondary.main, 0.5)} solid`,
  }
};
