const styles = {
  splitScreenBtn: {
    height: 32,
    width: 32,
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    padding: 0,
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: '4px',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '12px',
  },
  splitScreenIcon: {
    height: 20,
    width: 20,
    color: '#6D788D',
  },
  badge: {
    '& .MuiBadge-badge': {
      width: 14,
      height: 20,
      borderRadius: 7,
      backgroundColor: '#e0e0e0',
      fontSize: '11px'
    }
  },
  count: {
    color: '#334D6E',
    fontSize: 8,
  },
  attachmentsContainer: {
    maxWidth: '200px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 8
  },
  attachmentTitle: {
    fontSize: 15,
    color: '#334D6E',
    fontWeight: '500'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 0px 2px 0px'
  },
  dotIcon: {
    color: '#334D6E',
    fontSize: 13
  },
  attachmentLabel: {
    fontSize: 13,
    color: '#3E8AFF',
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: '0px 0px 0px 5px'
  },
};

export default styles;
