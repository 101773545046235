import { useTranslation } from 'react-i18next';

import Page from 'src/shared/screens/Page';
import { useConfig } from 'src/hooks/useConfig';
import DocumentsList from './DocumentsList/DocumentsList';
import theme from "src/theme";

const DocumentsListContainer = () => {
  const { t, ready } = useTranslation();
  const { BRAND_NAME } = useConfig();

  return (
    <Page
      style={styles.root}
      title={ready && `${t('INVOICE_LIST_PAGE_TITLE')} | ${BRAND_NAME}`}
    >
      <DocumentsList />
    </Page>
  );
};

export default DocumentsListContainer;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 30
  },
};
