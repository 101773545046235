import theme from "src/theme";

const styles = {
  root: {},
  form: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  paper: {
    maxWidth: 600,
    width: '90%',
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px',
    marginRight: 24
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: theme.palette.icon.close,
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
  },
  snackAction: {
    color: 'white'
  },
  userForm: {
    width: '100%'
  },
  fieldContainer: {
    marginTop: 10
  },
  submitBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    textTransform: 'none',
    background: theme.palette.button.action,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    borderRadius: 4
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    color: theme.palette.icon.close,
    textTransform: 'none',
    marginRight: 24
  },
  rowItem: {
    marginTop: 25,
  },
  rowItemComp: {
    width: '100%'
  },
  btnGroup: {
    width: '100%',
  },
  groupBtn: {
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    width: '100%',
    justifyContent: 'space-between',
  },
  optionTitle: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 10,
    textTransform: 'capitalize',
  },
  optionDescription: {
    color: theme.palette.text.primary,
    fontSize: 14,
    width: '100%',
    maxWidth: 500
  },
  menuItem: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
    whiteSpace: 'break-spaces',
    maxWidth: 500
  },
  fieldContainerRow: {
    marginTop: 20,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  topFieldContainer: {
    marginTop: 12
  },
  checkBox: {
    color: '#A0AEC1',
    paddingBottom: 0,
    paddingTop: 0
  },
  helpIconBtn: {
    padding: '0px 0px',
    color: '#c8c8c8',
    fontSize: '12px'
  },
  checkboxContainer: {
    marginRight: '8px',
  },
  checkBoxLabel: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  interfaceIcon: {
    fontSize: '14px'
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
  },
  scPaper: {
    padding: '12px',
    borderRadius: '15px',
    borderColor: '#d3d3d3',
    borderWidth: '2.3px',
    width: '400px',
    boxShadow: '0px 0px 0px #d3d3d3'
  },
  scTitle: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '8px'
  },
  scSubTitle: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  list: {
    marginBottom: '0px',
    color: theme.palette.text.primary,
  },
  listItem: {
    margin: '0px',
    padding: '0px'
  },
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  bulletPoint: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    marginRight: '10px',
    marginBottom: '23px',
  },
};

export default styles;
