import React, { useState } from 'react';

import { Box } from '@mui/material';

import {
  Download as DownloadIcon,
  RemoveCircleOutline as ZoomoutIcon, 
  ZoomOutMap as ZoomFitIcon,
  ZoomInMap as ZoomOutIcon,
  AddCircleOutline as ZoominIcon, 
  RotateRight as RotateIcon
} from '@mui/icons-material';

import styles from './style';

type ActionType ={
  zoom: number;
  setZoom: Function;
  totalPages:number;
  currentPage:number,
  setCurrentPage:Function;
  rotate:[number, Function];
  document:any;
  handleScrollToView:Function;
  handleZoomFit:Function;
}

const PdfActions = ({
  zoom, setZoom, document, totalPages, 
  rotate, currentPage, setCurrentPage,
  handleScrollToView, handleZoomFit}: ActionType) => {
  const [deg,setDeg] = rotate;
  const [docRotate,setDocRotate] = useState<number>(deg);
  const [isOut,setIsOut] = useState<boolean>(false);
  const handleZoom = (e:string)=>{
    setZoom(parseInt(e));
  } 
  const handleRotate = () =>{
     if(docRotate + 90 > 360){
        setDeg(0);
        setDocRotate(0)
     }
     else{
       setDeg(90 + docRotate);
       setDocRotate(90+ docRotate);
     }
  }
  
  const handleChangePage = (e:React.ChangeEvent<HTMLInputElement>) => {
    let val:string | number = e.target.value;
    if(val){
      if(Number(val.toString()) > totalPages) val = totalPages;
      else if(Number(val.toString()) < 1) val = 1;
      setCurrentPage(val);
      handleScrollToView(parseInt(val.toString())-1)
    }
    else{
      setCurrentPage(val);
    }    
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.range}>
        <ZoomoutIcon onClick={()=>{
          if(zoom > 40){
            handleZoom((zoom - 10).toString())
          }
        }}/>
        <input type='range' value={zoom}  
          onChange={(e:React.ChangeEvent<HTMLInputElement>)=> handleZoom(e.target.value)}
          min={1} max={100} step={1} 
        />
        <ZoominIcon onClick={()=>{
          if(zoom < 100){
            handleZoom((zoom + 10).toString())
          }
        }} />
      </Box>
      <Box sx={styles.zoom}>
        {zoom < 9 ?'0'+zoom:zoom}%
      </Box>
      <Box sx={styles.iconContainer}>
        <RotateIcon onClick={handleRotate} style={styles.icon} />
      </Box>
      <Box sx={styles.iconContainer}>
        {
          !isOut ?
          <ZoomFitIcon onClick={()=> { handleZoomFit('100'); setIsOut(!isOut) }}  style={styles.icon} />:
          <ZoomOutIcon onClick={()=> { handleZoomFit('50'); setIsOut(!isOut) }}  style={styles.icon} />
        }
      </Box>
      <Box sx={styles.iconContainer}>
        <a href={document} download style={{color:'white',
          display:'flex',justifyContent:'center',alignItems:'center'}}>
          <DownloadIcon style={styles.icon} />
        </a>
      </Box>
      <Box style={styles.pages}>
        <input type='text' style={styles.pageInput} className='white-text center-text'
      value={currentPage} onChange={handleChangePage} />/<span>{totalPages}</span>
      </Box>
    </Box>
  )
}

export default PdfActions