import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, Chip, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

import styles from './style';

interface PropTypes {
  open: boolean;
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  handleClose: () => void;
}

function EditTagsModal(props: PropTypes) {
  const { t } = useTranslation();

  const {
    open: showInfoModal,
    tags,
    setTags,
    handleClose,
  } = props;

  const [newTag, _setNewTag] = useState<string>('');
  const newTagRef = useRef(newTag);
  const setNewTag = (val: string) => {
    newTagRef.current = val;
    _setNewTag(val);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.key === 'Enter' || e.key === ',') && newTagRef.current.trim().length > 0) {
        setTags((prev) => {
          const updatedTags = [...prev, newTagRef.current];
          setNewTag('');
          return updatedTags;
        });
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleChange = (val: string) => {
    if (val.includes(',')) {
      return;
    }
    setNewTag(val);
  };

  const handleDelete = (index: number) => {
    setTags((prev) => {
      if (index >= 0 && index < prev.length) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  return (
    <Dialog
      open={showInfoModal}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Grid sx={styles.container}>
        <Grid sx={styles.headerContainer}>
          <Typography sx={styles.title}>{t('DOCUMENT_TAGS_MODAL_TITLE')}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={styles.closeIcon} />
          </IconButton>
        </Grid>
        <Grid sx={styles.detailContainer}>
          <Stack direction="row" spacing={1} sx={{ margin: '0px 0px 10px 0px', flexWrap: 'wrap' }}>
            {tags.map((tag, i) => (
              <Chip
                key={`${tag}_${i}`}
                label={tag}
                onDelete={() => handleDelete(i)}
                sx={styles.tag}
              />
            ))}
          </Stack>
          <TextField
            autoFocus
            fullWidth
            value={newTag}
            onChange={(e) => handleChange(e.target.value)}
            helperText={t('DOCUMENT_HEADER_EDIT_TAGS_HELPER_TEXT')}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default EditTagsModal;
