import { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { countries, getEmojiFlag } from 'countries-list';
import Axios from 'axios';

import { Autocomplete, Box, Button, Card, CardContent, FormHelperText, Grid, TextField, Typography } from '@mui/material';

import ConfigContext from 'src/contexts/ConfigContext';
import { useAuth } from 'src/hooks/useAuth';
import authService from 'src/utils/authService';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import styles from './style';

function ProfileOverview() {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const auth = useAuth();
  const {user, setUser} = auth;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    setCountriesList(Object.keys(countries).map((key) => ({
      isoCode: key,
      name: countries[key]?.name
    })));
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        country: user.country || '',
        email: user.email || '',
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        phone: user.phone || '',
        city: user.city || '',
      }}
      validationSchema={Yup.object().shape({
        country: Yup.string().max(255),
        email: Yup.string().email(t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')).max(255).required(t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')),
        firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
        lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
        phone: Yup.string().matches(/^([+\d]\d*)$/, t('ACCOUNT_SETTINGS_MY_PROFILE_PHONE_INVALID'))
          .min(9, t('ACCOUNT_SETTINGS_MY_PROFILE_PHONE_MIN')).max(16, t('ACCOUNT_SETTINGS_MY_PROFILE_PHONE_MAX'))
      })}
      onSubmit={async (values) => {
        setLoading(true);
        if (!authService.validateToken()) {
          enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
            variant: 'error',
          });
          setTimeout(() => {
            authService.logout(LOGIN_PANEL_URL);
          }, 2000);
          return;
        }
        try {
          const response = await Axios.put(
            `${API.userProfile}?&application=procys`,
            {
              ...values,
              interfacePreference: user.interfacePreference
            },
            axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
          );
          if (response.data.success) {
            enqueueSnackbar(t('ACCOUNT_SETTINGS_MY_PROFILE_UPDATE_SUCCESS'), {
              variant: 'success',
            });
            setUser({
              ...user,
              ...values
            });
            setLoading(false);
          } else {
            setLoading(false);
            enqueueSnackbar(t('ACCOUNT_SETTINGS_MY_PROFILE_UPDATE_FAILURE'), {
              variant: 'error',
            });
          }
        } catch (e) {
          setLoading(false);
          setError(appendContactSupport(
            window.config.support_email,
            getLocalisedErrorString(e?.response?.data?.i18n || 'ACCOUNT_SETTINGS_MY_PROFILE_UPDATE_FAILURE', t),
            t
          ));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card style={styles.root}>
            <Typography sx={styles.header}>
              {ready && t('ACCOUNT_SETTINGS_MY_PROFILE_OVERVIEW')}
            </Typography>
            <CardContent style={styles.formContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12} style={styles.formInput}>
                  <TextField
                    error={Boolean(touched.firstname && errors.firstname)}
                    fullWidth
                    helperText={touched.firstname && errors.firstname}
                    label={ready && t('ACCOUNT_SETTINGS_MY_PROFILE_FIRST_NAME')}
                    name="firstname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="firstname"
                    value={values.firstname}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} style={styles.formInput}>
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label={ready && t('ACCOUNT_SETTINGS_MY_PROFILE_LAST_NAME')}
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="lastname"
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={styles.formInput}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={
                    touched.email && errors.email
                      ? errors.email
                      : (ready && t('ACCOUNT_SETTINGS_MY_PROFILE_WE_WILL_USE_THIS_EMAIL_TO_CONTACT_YOU'))
                    }
                    label={ready && t('ACCOUNT_SETTINGS_MY_PROFILE_EMAIL')}
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                    type="email"
                    value={values.email}
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={styles.formInput}>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    label={ready && t('ACCOUNT_SETTINGS_MY_PROFILE_PHONE')}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={styles.formInput}>
                  <TextField
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    label={ready && t('ACCOUNT_SETTINGS_MY_PROFILE_CITY')}
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={styles.formInput}>
                  <Autocomplete
                    fullWidth
                    name="country"
                    onChange={(e, v) => v && handleChange({ target: { name: 'country', value: v.name } })}
                    value={values.country ? Object.keys(countries).map((key) => ({
                      isoCode: key,
                      name: countries[key]?.name
                    })).find((o) => o.name === values.country) : null}
                    options={countriesList}
                    getOptionLabel={(option) => `${getEmojiFlag(option.isoCode)} ${option.name}`}
                    isOptionEqualToValue={(option, value) => option.isoCode === value.isoCode}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label={ready && `${t('ACCOUNT_SETTINGS_MY_PROFILE_COUNTRY')}`}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        style={styles.selectStyle}
                      />
                    )}
                  />
                  <FormHelperText error>
                    {touched.country && errors.country && ready ? t(errors.country) : ''}
                  </FormHelperText>
                </Grid>
              </Grid>
              {error && (
                <Box mt={3}>
                  <FormHelperText error>
                    {error}
                  </FormHelperText>
                </Box>
              )}
            </CardContent>
            <Box
              p={2}
              display="flex"
              justifyContent="flex-end"
              style={styles.updateBtnContainer}
            >
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                style={styles.updateBtn}
              >
                {ready && t('ACCOUNT_SETTINGS_MY_PROFILE_UPDATE')}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

export default ProfileOverview;
