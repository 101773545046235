import { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import {
  Backdrop, Grid, Modal, Typography, IconButton, Button, Box, CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import PdfView from 'src/documents/components/PdfView/PdfView';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

const DuplicateMenu = (props) => {
  const { API } = useContext(ConfigContext);
  const { t, ready } = useTranslation();
  const {
    duplicateMenuOpen, handleClose, invoice, handleProcessDocument,
  } = props;

  const [loading, setLoading] = useState(false);
  const [fileResponse, setFileResponse] = useState(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const [imgName, setImgName] = useState('');

  const closeDuplicateMenu = () => {
    handleClose();
  };

  const getImage = async (name) => {
    if (!name) {
      return;
    }
    setLoading(true);
    setFetchingFile(true);
    try {
      const response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
      setFetchingFile(false);
      setLoading(false);
    } catch (err) {
      setFileResponse(null);
      setFetchingFile(false);
      setLoading(false);
    }
  };

  const cleanUp = () => {
    setFileResponse(null);
    setImgName('');
  };

  useEffect(() => {
    const nameParts = invoice?.documentFile?.split('.');
    let imageName = '';
    if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
      imageName = `${nameParts[0]}-display.pdf`;
      setImgName(`${nameParts[0]}-display.pdf`);
    } else {
      imageName = invoice.documentFile;
      setImgName(invoice.documentFile);
    }

    getImage(imageName);

    return cleanUp;
  }, [invoice]);

  const handleUnmarkDuplicate = async () => {
    handleProcessDocument(invoice, 'proceed');
    closeDuplicateMenu();
  };

  const renderInvoicePDF = () => (
    <>
      <Box hidden={!isMobile} style={{ height: '100%' }}>
        <PdfView
          t={t}
          ready={ready}
          fileResponse={fileResponse}
          fetchingFile={fetchingFile}
          imgName={imgName}
          canOpenInNewTab
        />

      </Box>
      <Box hidden={isMobile} style={{ height: '100%' }}>
        <iframe src={fileResponse} title="pdf" style={styles.pdfFrame} />
      </Box>
    </>
  );

  const duplicateRequestButton = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="outlined"
        style={styles.dontSplitBtn}
        disabled={loading}
        onClick={() => handleUnmarkDuplicate(invoice)}
      >
        {t('INVOICE_DUPLICATE_NO_DUPLICATE')}
      </Button>
      <Button
        variant="outlined"
        style={styles.deleteDuplicateBtn}
        disabled={loading}
        onClick={() => handleProcessDocument(invoice, 'delete')}
      >
        {t('INVOICE_DUPLICATE_DELETE')}
      </Button>
    </Box>
  );

  return (
    <>
      <Modal
        style={styles.modal}
        open={duplicateMenuOpen}
        onClose={() => closeDuplicateMenu()}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <div style={styles.paper}>
          <Grid container style={styles.modalHeader}>
            <Grid item style={styles.modalHeaderLeft}>
              <Typography style={styles.headerTitle}>
                {ready && t('DUPLICATE_MENU_TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={1} md={8} style={styles.modalHeaderRight}>
              <IconButton onClick={() => closeDuplicateMenu()}>
                <CloseIcon style={styles.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container style={styles.modalContainer}>
            <Grid item xs={12} md={4} style={styles.leftContainer}>
              <Grid style={styles.optionsContainer}>
                <Grid style={styles.lineRow}>
                  <Grid style={styles.documentNameContainer}>
                    <Typography style={styles.documentNameLabel}>{t('DUPLICATE_MENU_DOCUMENT_NAME')}</Typography>
                    <Typography style={styles.documentName}>{invoice.fileName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={styles.modalLeftFooter}>
                {duplicateRequestButton()}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} style={styles.invoiceContainer}>
              {renderInvoicePDF()}
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default DuplicateMenu;
