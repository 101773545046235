import { Dispatch } from 'redux';
import Axios from "axios";
import {
  FETCH_HEADER_FIELDS_START,
  FETCH_HEADER_FIELDS_SUCCESS,
  FETCH_HEADER_FIELDS_FAIL,
  FETCH_LINE_FIELDS_START,
  FETCH_LINE_FIELDS_SUCCESS,
  FETCH_LINE_FIELDS_FAIL,
  FETCH_DOC_OVERVIEW_FIELDS_START,
  FETCH_DOC_OVERVIEW_FIELDS_SUCCESS,
  FETCH_DOC_OVERVIEW_FIELDS_FAIL,
} from "src/actionTypes";
import { FieldType } from "src/types";
import { axiosHeaders } from "src/utils/helpers";

const fetchHeaderFieldsStart = (entity: string) => {
  return {
    type: FETCH_HEADER_FIELDS_START,
    payload: entity,
  };
};

const fetchHeaderFieldsSuccess = (fields: FieldType[]) => {
  return {
    type: FETCH_HEADER_FIELDS_SUCCESS,
    payload: fields,
  };
};

const fetchHeaderFieldsFail = () => {
  return {
    type: FETCH_HEADER_FIELDS_FAIL,
  };
}

export const fetchHeaderFields = (companyID: string, headerEntity: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchHeaderFieldsStart(headerEntity));
    try {
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/fields/${headerEntity || 'invoice'}/${encodeURIComponent(companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        dispatch(fetchHeaderFieldsSuccess(response.data.data));
        localStorage.setItem('headerFields', JSON.stringify(response.data.data));
      } else {
        dispatch(fetchHeaderFieldsFail());
      }
    } catch (error) {
      dispatch(fetchHeaderFieldsFail());
    }
  };
};

const fetchLineFieldsStart = () => {
  return {
    type: FETCH_LINE_FIELDS_START,
  };
};

const fetchLineFieldsSuccess = (fields: FieldType[]) => {
  return {
    type: FETCH_LINE_FIELDS_SUCCESS,
    payload: fields,
  };
};

const fetchLineFieldsFail = () => {
  return {
    type: FETCH_LINE_FIELDS_FAIL,
  };
}

export const fetchLineFields = (companyID: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchLineFieldsStart());
    try {
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/fields/invoice-line/${encodeURIComponent(companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        dispatch(fetchLineFieldsSuccess(response.data.data));
        localStorage.setItem('lineFields', JSON.stringify(response.data.data));
      } else {
        dispatch(fetchLineFieldsFail());
      }
    } catch (error) {
      dispatch(fetchLineFieldsFail());
    }
  };
};

const fetchDocOverviewFieldsStart = () => {
  return {
    type: FETCH_DOC_OVERVIEW_FIELDS_START,
  };
};

const fetchDocOverviewFieldsSuccess = (fields: FieldType[]) => {
  return {
    type: FETCH_DOC_OVERVIEW_FIELDS_SUCCESS,
    payload: fields,
  };
};

const fetchDocOverviewFieldsFail = () => {
  return {
    type: FETCH_DOC_OVERVIEW_FIELDS_FAIL,
  };
}

export const fetchDocOverviewFields = (companyID: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchDocOverviewFieldsStart());
    try {
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/fields/document-overview/${encodeURIComponent(companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        const data = response.data.data as FieldType[];
        const activeFields: FieldType[] = [];
        const inactiveFields: FieldType[] = [];
        data.forEach((field) => {
          if (field.isActive) {
            activeFields.push(field);
          } else {
            inactiveFields.push(field);
          }
        });
        dispatch(fetchDocOverviewFieldsSuccess([ ...activeFields, ...inactiveFields ]));
        localStorage.setItem('docOverviewFields', JSON.stringify([ ...activeFields, ...inactiveFields ]));
      } else {
        dispatch(fetchDocOverviewFieldsFail());
      }
    } catch (error) {
      dispatch(fetchDocOverviewFieldsFail());
    }
  };
};
