const styles = {
  root:{
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    marginTop: '24px',
    backgroundColor: '#FFFFFF',
    height: '100%',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    borderRadius: '10px',
  },
  title: {
    padding: '20px 20px 0px 20px',
    fontSize: '20px',
    fontWeight: '500',
    color: '#4C4E64',
    lineHeight: '32px',
    letter: '-0.25px',
  },
  bulkUploadSuccessText: {
    fontSize: '14px',
  },
  bulkUploadSuccessLink: {
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#FFF'
  },
  snackbarContainer: {
    maxWidth: '75%'
  },
}

export default styles;