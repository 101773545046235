import { useContext, useEffect, useCallback } from 'react';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Box, Typography, LinearProgress } from '@mui/material';

import Page from 'src/shared/screens/Page';
import authService from 'src/utils/authService';
import ConfigContext from 'src/contexts/ConfigContext';
import { loginVerificationFields } from 'src/config';
import theme from 'src/theme';

function VerifyLogin() {
  const history = useHistory();
  const { API, LOGIN_PANEL_URL, BRAND_NAME } = useContext(ConfigContext);
  const { t, ready } = useTranslation();

  const checkToken = useCallback(async () => {
    if (history.location.search) {
      const search = history.location.search.substring(1);
      const paramsAr = search.split('&');
      const query = {};

      for (let i = 0; i < loginVerificationFields.length; i += 1) {
        for (let j = 0; j < paramsAr.length; j += 1) {
          if (paramsAr[j].indexOf('=') > 0) {
            const [key, val] = paramsAr[j].split('=');
            if (key === loginVerificationFields[i]) {
              query[loginVerificationFields[i]] = val;
            }
          }
        }
      }

      if (query.language) {
        i18next.changeLanguage(query.language);
      }

      if (query.token && query.token.trim() !== '') {
        const response = await authService.loginInWithToken(API.verifyToken, query);
        if (response.success && response.data) {
          authService.setSession(query.token, query.role, query.userRef);
        } else {
          authService.logout(LOGIN_PANEL_URL);
        }

        setTimeout(() => {
          const persistUrl = localStorage.getItem('PROCYS_PERSIST_URL');
          if (persistUrl) {
            window.location.href = persistUrl;
          }
          history.push('/');
        }, 1000);
      } else {
        authService.logout(LOGIN_PANEL_URL);
      }
    } else {
      authService.logout(LOGIN_PANEL_URL);
    }
  }, [history, API.verifyToken, LOGIN_PANEL_URL]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <Page
      style={styles.root}
      title={t('PROCYS_LOGIN_VERIFICATION_PAGE_TITLE')}
    >
      <Container maxWidth="md">
        <Box
          mb={8}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Box mb={4} display="flex" alignItems="center">
            <Typography variant="h4" align="center" sx={styles.text}>
              {ready && t('PROCYS_LOGIN_VERIFICATION_TITLE')}
            </Typography>
          </Box>
          <Typography variant="subtitle1" align="center" sx={styles.text}>
            {ready && t('PROCYS_LOGIN_VERIFICATION_TEXT', { brand: BRAND_NAME })}
          </Typography>
          <Box maxWidth={400} width="75%" style={styles.loadingIndicator}>
            <LinearProgress />
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default VerifyLogin;

const styles = {
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  loadingIndicator: {
    marginTop: 30,
  },
  text: {
    color: theme.palette.text.primary,
  }
};
