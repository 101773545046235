import { useContext, useState } from 'react';
import Axios from 'axios';

import { Button, Grid, TextField, Autocomplete } from '@mui/material';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

const SpreadInvoice = (props) => {
  const { API } = useContext(ConfigContext);

  const {
    enqueueSnackbar, t, ready, invoice, details, noWhiteSpace, validation, handleBlur, glAccounts,
    isSpreadFieldsNotEmpty, spreadSuccess, setSpreadSuccess
  } = props;

  const [spreadFrom, setSpreadFrom] = useState(invoice.spreadFrom || details.period);
  const [spreadTo, setSpreadTo] = useState(invoice.spreadTo || '');
  const [glaSpread, setGlaSpread] = useState(invoice.gla || '');
  const [glaSpreadOpen, setGlaSpreadOpen] = useState(false);
  const [spreadFromError, setSpreadFromError] = useState(false);
  const [spreadToError, setSpreadToError] = useState(false);
  const [spreadGlaError, setSpreadGlaError] = useState(false);
  const [spreadInfoSubmitting, setSpreadInfoSubmitting] = useState(false);

  const handleGlaSpreadChange = (e, v) => {
    const val = v?.code || e.target.value;
    if (val) {
      setSpreadGlaError(false);
    }
    setGlaSpread(val);
  };

  const getGlaLabel = (entities, code) => {
    for (let i = 0; i < entities?.length; i++) {
      if (code === entities[i].code) {
        let namePart = '';
        if (entities[i].name) {
          namePart = ` | ${entities[i].name}`;
        }
        return `${entities[i].code}${namePart}`.trim();
      }
    }

    return code;
  };

  const submitSpreadInfo = async () => {
    setSpreadInfoSubmitting(true);
    const isSpreadToError = validation(spreadTo, noWhiteSpace) || spreadTo === '';
    const isSpreadFromError = validation(spreadFrom, noWhiteSpace) || spreadFrom === '';
    const isSpreadGlaError = validation(glaSpread, noWhiteSpace) || glaSpread === '';

    setSpreadToError(isSpreadToError);
    setSpreadFromError(isSpreadFromError);
    setSpreadGlaError(isSpreadGlaError);

    if (isSpreadToError || isSpreadFromError || isSpreadGlaError) {
      setSpreadInfoSubmitting(false);
      return;
    }
    try {
      const response = await Axios.post(
        `${API.invoices}${invoice.assistantID}/actions/spread`,
        {
          spreadTo,
          spreadFrom,
          gla: glaSpread
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSpreadSuccess(true);
        enqueueSnackbar(t('INVOICE_EDIT_FORM_SPREAD_SUCCESS'), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t('INVOICE_EDIT_FORM_SPREAD_FAIL'), {
          variant: 'error',
        });
      }
      setSpreadInfoSubmitting(false);
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(
        error.response ? error.response.data.i18n : appendContactSupport(window.config.support_email, error.message, t), t
      ), {
        variant: 'error',
      });
      setSpreadInfoSubmitting(false);
    }
  };

  return (
    <>
      <Grid item xs={12} style={styles.inputItem}>
        <TextField
          id="spreadFrom"
          fullWidth
          error={spreadFromError || validation(
            spreadFrom,
            noWhiteSpace
          )}
          helperText={(spreadFromError || validation(
            spreadFrom,
            noWhiteSpace
          )) && t('NO_WHITE_SPACE_HELPER_TEXT')}
          label={ready && t('INVOICE_EDIT_FORM_SPREAD_FROM')}
          name="spreadFrom"
          onBlur={handleBlur}
          onChange={(e) => { setSpreadFrom(e.target.value); setSpreadFromError(false); }}
          style={styles.root}
          value={spreadFrom || ''}
          variant="outlined"
          disabled={isSpreadFieldsNotEmpty() || spreadSuccess}
          required
        />
      </Grid>
      <Grid item xs={12} style={styles.inputItem}>
        <TextField
          id="spreadTo"
          fullWidth
          error={spreadToError || validation(
            spreadTo,
            noWhiteSpace
          )}
          helperText={(spreadToError || validation(
            spreadTo,
            noWhiteSpace
          )) && t('NO_WHITE_SPACE_HELPER_TEXT')}
          label={ready && t('INVOICE_EDIT_FORM_SPREAD_TO')}
          name="spreadTo"
          onBlur={handleBlur}
          onChange={(e) => { setSpreadTo(e.target.value); setSpreadToError(false); }}
          style={styles.root}
          value={spreadTo || ''}
          variant="outlined"
          disabled={isSpreadFieldsNotEmpty() || spreadSuccess}
          required
        />
      </Grid>
      <Grid item xs={12} style={styles.inputItem}>
        <Autocomplete
          id="glaSpread"
          open={glaSpreadOpen && !validation(
            glaSpread,
            noWhiteSpace
          )}
          onOpen={() => setGlaSpreadOpen(true)}
          onClose={() => setGlaSpreadOpen(false)}
          fullWidth
          name="glaSpread"
          onChange={(e, v) => handleGlaSpreadChange(e, v)}
          onBlur={handleBlur}
          value={getGlaLabel(glAccounts, glaSpread)}
          options={glAccounts}
          getOptionLabel={(option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)}
          getOptionSelected={(option) => option.code === (glaSpread || ' ')}
          disabled={isSpreadFieldsNotEmpty() || spreadSuccess}
          renderInput={(params) => (
            <TextField
              fullWidth
              required
              error={spreadGlaError || validation(
                glaSpread,
                noWhiteSpace
              )}
              helperText={(spreadGlaError || validation(
                glaSpread,
                noWhiteSpace
              )) && t('NO_WHITE_SPACE_HELPER_TEXT')}
              {...params}
              label={ready && t('INVOICE_EDIT_FORM_SPREAD_GLA')}
              variant="outlined"
              InputProps={{
                ...params.InputProps
              }}
              style={styles.root}
            />
          )}
        />
      </Grid>
      {(!isSpreadFieldsNotEmpty() && !spreadSuccess) && (
        <Grid style={styles.spreadInvoiceContainer}>
          <Button
            variant="outlined"
            style={styles.confirmSpreadBtn}
            onClick={submitSpreadInfo}
            disabled={spreadInfoSubmitting}
          >
            {ready && t('INVOICE_EDIT_FORM_SPREAD_CONFIRM')}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default SpreadInvoice;
