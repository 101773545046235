import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Checkbox, ClickAwayListener, FormControlLabel, FormGroup, Grow, InputAdornment, MenuItem,
  MenuList, Paper, Popper, SvgIcon, TextField
} from '@mui/material';
import {
  Search as SearchIcon,
} from '@mui/icons-material';

import { CompanyType } from 'src/types';
import styles from './style';

interface PropTypes {
  open: boolean;
  filterRef: any;
  btnWidth: number;
  searchQuery: string;
  filteredList: CompanyType[];
  selectedList: CompanyType[];
  handleClose: () => void;
  handleSearch: (_val: string) => void;
  handleClick: (_e: React.MouseEvent<HTMLLIElement, MouseEvent>, _company: CompanyType) => void;
}

const CompanyFilter = (props: PropTypes) => {
  const { t } = useTranslation();

  const {
    open,
    filterRef,
    btnWidth,
    searchQuery,
    filteredList,
    selectedList,
    handleClose,
    handleSearch,
    handleClick,
  } = props;

  return (
    <Popper
      open={open}
      anchorEl={filterRef.current}
      role={undefined}
      transition
      style={{
        zIndex: 111111, width: btnWidth, padding: '0px',
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Box sx={styles.supplierFilterContainer}>
              <TextField
                sx={styles.supplierSearch}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder={t('DASHBOARD_INVOICES_OWNERS_SEARCH_PLACEHOLDER')}
                value={searchQuery}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: '0px' }}>
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        style={{ fill: '#C2CFE0' }}
                      >
                        <SearchIcon style={{ color: '#C2CFE0' }} />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  sx: {
                    '& .MuiOutlinedInput-notchedOutline': styles.searchInput,
                    '& .MuiOutlinedInput-input': styles.searchBarInput
                  }
                }}
              />
              <MenuList
                id="owners-simple-menu"
                className="xy-scroll"
                sx={{
                  '& .MuiMenu-paper': styles.filterMenu,
                  '& .MuiMenu-list': styles.filterMenuRoot
                }}
              >
                {filteredList.map((company) => (
                  <MenuItem
                    key={company.companyId}
                    sx={styles.filterMenuItem}
                    onClick={(e) => handleClick(e, company)}
                    disabled={selectedList.length === 5 && !selectedList.some((c) => c.companyId === company.companyId)}
                  >
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          '& .MuiFormControlLabel-label': styles.supplierLabel,
                          '& .MuiFormControlLabel-root': styles.supplierLabelRoot,
                        }}
                        control={(
                          <Checkbox
                            checked={selectedList.some((s) => s.companyId === company.companyId)}
                            sx={{
                              ...styles.checkbox,
                              '& .MuiSvgIcon-root': styles.checkboxRoot
                            }}
                          />
                        )}
                        label={company.name}
                      />
                    </FormGroup>
                  </MenuItem>
                ))}
              </MenuList>
            </Box>
          </ClickAwayListener>
        </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default CompanyFilter;
