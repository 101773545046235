import { alpha } from "@mui/material";
import theme from "src/theme";

const styles = {
  actionSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    color: '#D5D5D5'
  },
  checkboxExportFailFilter: {
    color: '#D5D5D5',
    marginLeft: 8,
  },
  checkboxIcon: {
    width: 16,
    height: 16,
    border: '2px solid #D5D5D5',
    borderRadius: 3,
  },
  checkboxLabel: {
    fontSize: '12px !important',
    fontWeight: '500',
    color: `${theme.palette.text.header} !important`,
  },
  bulkText: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.header,
    marginLeft: 15,
  },
  bulkTextLink: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    marginLeft: 6,
    cursor: 'pointer'
  },
  ublButton: {
    width: 150,
    height: 36,
    textTransform: 'none',
    marginLeft: 10,
    fontWeight: 500,
  },
  sendToEmailBtn: {
    color: '#ffffff',
    backgroundColor: '#FBA102',
    borderColor: '#FBA102',
    textTransform: 'none',
    marginLeft: 10,
    width: 155,
    height: 36,
  },
  bulkDelete: {
    color: '#F7685B',
    '&:disabled': {
      color: '#F7685B',
      opacity: 0.5,
    }
  },
  deleteDuplicates: {
    color: theme.palette.statusbg.tovalidate,
    '&:disabled': {
      color: theme.palette.statusbg.tovalidate,
      opacity: 0.5,
    }
  },
  bulkDownload: {
    color: theme.palette.text.primary,
    marginLeft: '8px ',
    '&:disabled': {
      color: theme.palette.text.primary,
      marginLeft: '8px',
      opacity: 0.5,
    }
  },
  bulkArchieve: {
    color: '#4E342E',
    '&:disabled': {
      color: '#4E342E',
      opacity: 0.5,
    }
  },
  groupBtn: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    width: '100%',
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    borderTopWidth: 1,
    border: '0px solid #E1E4E8',
  },
  digitized: {
    color: theme.palette.status.digitized,
    backgroundColor: alpha(theme.palette.statusbg.digitized, 0.3)
  },
  toreview: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  reviewed: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  tovalidate: {
    color: theme.palette.status.tovalidate,
    backgroundColor: alpha(theme.palette.statusbg.tovalidate, 0.3)
  },
  toexport: {
    color: theme.palette.status.toexport,
    backgroundColor: alpha(theme.palette.statusbg.toexport, 0.3)
  },
  exported: {
    color: theme.palette.status.exported,
    backgroundColor: alpha(theme.palette.statusbg.exported, 0.3)
  },
  rejected: {
    color: theme.palette.status.rejected,
    backgroundColor: alpha(theme.palette.statusbg.rejected, 0.3)
  },
  archived: {
    color: theme.palette.status.archived,
    backgroundColor: alpha(theme.palette.statusbg.archived, 0.3)
  },
  outoflimit: {
    color: theme.palette.status.outoflimit,
    backgroundColor: alpha(theme.palette.statusbg.outoflimit, 0.3)
  },
  errored: {
    color: theme.palette.status.errored,
    backgroundColor: alpha(theme.palette.statusbg.errored, 0.3)
  },
  dontSplitBtn: {
    height: 36,
    textTransform: 'none',
    marginLeft: 10,
    fontSize: 13,
    minWidth: 132,
    color: theme.palette.text.primary,
  },
  formInputSelect: {
    width: 136,
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 600,
    marginLeft: 10,
    height: 36
  },
  userImage: {
    flexShrink: 0,
    height: 28,
    width: 28,
    fontWeight: 500
  },
  usernameText: {
    fontSize: 15,
    lineHeight: '42.5px',
    fontWeight: 400,
    color: '#334D6E !important'
  },
  assignUserText: {
    fontSize: 15,
    lineHeight: '22.5px',
    fontWeight: 500,
    color: '#707683',
    marginTop: 2
  },
  formInputSelected: {
    width: 240,
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 600,
    marginLeft: 10,
    height: 36
  },
};

export default styles;
