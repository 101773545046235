import { useTranslation } from 'react-i18next';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ComposedChart,
  ResponsiveContainer
} from "recharts";

import {formatChartNumber} from 'src/utils/helpers';

const CustomizedLabel = (props:any) => {
  const { x, y, stroke } = props;
  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    </text>
  );
};

const CustomizedXAxisTick = (props:any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
         x={10}
         y={0}
         dy={8}
         dx={8}
         textAnchor="end"
         fill="#666"
         fontSize={11}
         transform="rotate(0)"
       >
         {payload.value}
       </text>
    </g>
  );
};

const CustomizedYAxisTick = (props:any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-5}
        dy={3}
        textAnchor="end"
        fill="#666"
        fontSize={12}
        transform="rotate(0)"
      >
        {payload.value.toString().includes('.')?'':formatChartNumber(payload.value)}
      </text>      
    </g>
  );
};

const CustomTooltip = (props:any) =>{
   const { ready, t } = useTranslation();
   const { active, payload, date, type } = props;
   if (active && payload?.length) {
    return (
      <div style={{
        display:'flex',flexDirection:'column',
        backgroundColor:'rgba(255,255,255,1)',
        borderRadius:'10px',boxShadow:'2px 2px 5px 1px rgba(0,0,0,0.15)'
      }}>
        <span style={{fontWeight:'500',padding:'10px 10px 5px',
          borderBottom:'1px solid #4C4E641F',fontSize:'12px'}}>
          {
            date === 0 ? payload[0]?.payload?.Date:
            date === 1 ? payload[0]?.payload?.Week:
            date === 2 ? payload[0]?.payload?.Month:
            date === 3 ? payload[0]?.payload?.Quarter:
            payload[0]?.payload?.Year          
          }
        </span>
        <span style={{padding:'5px 10px', display:'flex',alignItems:'center',gap:'3px'}}>
          <span style={{
            display:'inline-block',width:'12px',height:'5px',borderRadius:'4px',
            backgroundColor:'#3E8AFF'
          }}></span>
          {payload?.slice(0,1)?.map((ele:any,index:number) =>(
            <small key={index}>
              {
                `${ready && t('DASHBOARD_CHART_INVOICES')} ${
                  type === 0 ? ready && t('DASHBOARD_OVERVIEW_DIGITIZED')
                  : type === 1 ? ready && t('DASHBOARD_OVERVIEW_UPLOADING')
                    : type === 2 ? ready && t('DASHBOARD_OVERVIEW_REVIEW')
                      : type === 3 ? ready && t('DASHBOARD_OVERVIEW_VALIDATE')
                        : type === 4 ? ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')
                          : type === 5 ? ready && t('DASHBOARD_OVERVIEW_EXPORTED')
                            : type === 6 ? ready && t('DASHBOARD_OVERVIEW_REJECTED')
                              : t('DASHBOARD_OVERVIEW_ERRORED')
                }`
              }:<span style={{display:'inline-block',marginLeft:'5px',fontWeight:'500'}}>{formatChartNumber(ele.value)}</span>
            </small>
          ))}
        </span>
      </div>
    )
   }
   return null;
}

const Chart = ({ chart_data, type, invoiceType }: { chart_data: any, type: number, invoiceType: number }) => {
  const { t } = useTranslation();
  let x_values = type === 0 ? 'Date' : type === 1 ? 'Week' :type === 2 ? 'Month' : type === 3 ? 'Quarter' : 'Year';
  return(
    <ResponsiveContainer height={320} width="100%">
      <ComposedChart
        width={700}
        height={280}
        data={chart_data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="6 6" vertical={false} />
        <XAxis dataKey={x_values} height={60} padding={{ left: 5, right: 5 }} tick={<CustomizedXAxisTick type={type} />} />
        <YAxis axisLine={false} tickLine={false} dataKey={`${t('DASHBOARD_CHART_Y_AXIS_LABEL')}`} height={60} tick={<CustomizedYAxisTick />} />
        <Tooltip content={<CustomTooltip date={type} type={invoiceType} active={false} payload={[]} label={""} />} />
        <Line dataKey={`${t('DASHBOARD_CHART_Y_AXIS_LABEL')}`} activeDot={{stroke:'#3E8AFF'}} dot={{r:4}} strokeWidth={2} stroke="#3E8AFF">
          <LabelList content={<CustomizedLabel />} />
        </Line>
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default Chart