import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Hidden, Card, TablePagination, Grid
} from '@mui/material';

import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import { getDocumentType } from 'src/utils/helpers';
import styles from './style';

function PartnerStatsTable({
  loading, stats, maxWidth, total, page, limit, maxWidthRows, handlePageChange, handleLimitChange
}) {
  const { t, ready } = useTranslation();

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Card style={styles.root}>
      <PerfectScrollbar>
        <Box minWidth="100%">
          <Table size="small">
            <TableHead>
              <TableRow style={styles.row}>
                <TableCell style={styles.headerStyle} align="left">
                  {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_COMPANY_NAME')}
                </TableCell>
                <Hidden only={['xs', 'sm']}>
                  <TableCell style={styles.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  <TableCell style={styles.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_CONNECTED_TO')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm']}>
                  <TableCell style={styles.headerStyle} align="center">
                    {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_DOC_TYPE')}
                  </TableCell>
                </Hidden>
                <TableCell style={styles.headerStyle} align="center">
                  {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_DOCUMENTS_USED')}
                </TableCell>
                <Hidden only={['xs']}>
                  <TableCell style={styles.headerStyle} align="center">
                    {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_PAGES_PROCESSED')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs']}>
                  <TableCell style={styles.headerStyle} align="center">
                    {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_USERS')}
                  </TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              {stats.map((c) => (
                <TableRow
                  hover
                  key={c.companyID}
                  style={styles.pointer}
                >
                  <TableCell style={styles.cellStyle} align="left">
                    {c.name}
                    {c.isTest && ' *'}
                  </TableCell>
                  <Hidden only={['xs', 'sm']}>
                    <TableCell style={styles.cellStyle} align="left">
                      <Grid style={styles.centerCell}>
                        <Hidden only={['xs']}>
                          <Grid style={{...styles[c.isSubCompany ? 'sub' : 'main'], ...styles.statusLabel}}>
                            {c.isSubCompany ? 'subsidiary' : 'main'}
                          </Grid>
                        </Hidden>
                        <Hidden smUp>
                          <Grid style={{...styles[`${c.isSubCompany ? 'sub' : 'main'}Dot`], ...styles.statusDot}} />
                        </Hidden>
                      </Grid>
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'sm', 'md']}>
                    <TableCell style={styles.cellStyle} align="left">
                      {c.parentName}
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'sm']}>
                    <TableCell style={styles.cellStyle} align="center">
                      {t(getDocumentType(c.documentType)) || '-'}
                    </TableCell>
                  </Hidden>
                  <TableCell style={styles.cellStyle} align="center">
                    {c.documentsUsed || '-'}
                  </TableCell>
                  <Hidden only={['xs']}>
                    <TableCell style={styles.cellStyle} align="center">
                      {c.pagesProcessed || '-'}
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs']}>
                    <TableCell style={styles.cellStyle} align="center">
                      {c.usersUsed || '-'}
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <div style={styles.caption}>
        {
          !maxWidth && `${total} compan${total !== 1 ? 'ies' : 'y'} available`
        }
        <TablePagination
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50]}
          labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
        />
      </div>
    </Card>
  );
}

// PartnerStatsTable.propTypes = {
//   loading: PropTypes.bool,
//   stats: PropTypes.array,
//   maxWidth: PropTypes.bool,
//   total: PropTypes.number,
//   page: PropTypes.number,
//   limit: PropTypes.number,
//   maxWidthRows: PropTypes.bool,
//   handlePageChange: PropTypes.func,
//   handleLimitChange: PropTypes.func
// };

export default PartnerStatsTable;
