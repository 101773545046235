import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Button, Grid
} from '@mui/material';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from '../style';

const Quickbooks = () => {
  const { API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [appError, setAppError] = useState(false);

  const [oauth2URL, setOauth2URL] = useState('');

  const getSavedDetails = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.savedDetailsQuickbooks}/${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setOauth2URL(response.data.data.oauth2URL);
        setLoading(false);
      } else {
        setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
    }
  };

  useEffect(() => {
    getSavedDetails();
  }, []);

  const onCheckConnection = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.checkQuickbooksConnection}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_GSTOCK_CONN_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('SOMETHING_WENT_WRONG'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onSync = async () => {
    setLoading(true);
    try {
      await Axios.get(
        API.syncQuickbooks,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setTimeout(() => {
        enqueueSnackbar(
          t('INTEGRATIONS_SYNC_STARTED_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onOAuth2Connect = () => {
    window.open(oauth2URL, '_blank');
  };

  if (appError) {
    return (
      <Grid style={styles.errorContainer}>
        <span style={styles.labelTextError}>
          {appError}
        </span>
      </Grid>
    );
  }

  return (
    <Grid style={styles.detailsContainer}>
      <Grid style={styles.detailRow}>
        <span style={styles.qbConnectText}>{ready && t('QUICKBOOKS_CLICK_HERE')}</span>
        <Button
          variant="outlined"
          style={styles.qbConnectBtn}
          onClick={onOAuth2Connect}
          disabled={loading}
        >
          {ready && t('QUICKBOOKS_CONNECT')}
        </Button>
      </Grid>
      <Grid style={styles.checkContainer}>
        <Button
          variant="outlined"
          style={styles.checkConnectionBtn}
          onClick={onCheckConnection}
          disabled={loading}
        >
          {ready && t('INTEGRATIONS_CHECK_CONNECTION')}
        </Button>
        <Button
          variant="outlined"
          style={styles.syncBtn}
          onClick={onSync}
          disabled={loading}
        >
          {ready && t('INTEGRATIONS_QUICKBOOKS_SYNC')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Quickbooks;
