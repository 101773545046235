import Axios from 'axios';
import cc from 'currency-codes/data';
import { countries, TCountries } from 'countries-list';
import { TFunction } from 'i18next';

import { IHighlight, Scaled } from 'src/pdf-highligher';
import {
  invoiceRegex, secondaryDocumentTypes
} from 'src/config';
import {
  CostCenterType, DocumentLineType, FieldType, GLAccountType, DocType, ProjectType, SupplierType,
  UserDataType, VatGroupType
} from 'src/types';
import {
  DocumentEditFormRegexType, DocumentEditFormVatEsLineType
} from './documentTypes';
import {
  appendContactSupport,
  axiosHeaders, calculateLineTaxForVat, getMonthShortString, isAmountTallyingWithLines, isVatNumberInvalid
} from 'src/utils/helpers';

const dateFormat = /^((0[1-9]|[12][0-9]|3[01])([/\-.])(0[1-9]|1[0-2])\3\d{4}|\d{4}([/\-.])(0[1-9]|1[0-2])\5(0[1-9]|[12][0-9]|3[01]))$/;
const noWhiteSpace = /.*\S.*$/;

const currencyCodes: string[] = cc.map((c: { code: string; }) => c.code);
const countryCodes: string[] = Object.keys(countries);

export const senderRelatedFields = [
  'doNotPay',
  'senderCompany',
  'senderVatNumber',
  'senderAddress',
  'senderCity',
  'senderPostalCode',
  'senderStreet',
  'senderWebsite',
  'senderEmail',
  'senderKvk',
  'senderIban',
  'senderPhone',
  'senderFax',
  'senderTaxNumber'
];

export const receiverRelatedFields = [
  'receiverCompany',
  'receiverVatNumber',
  'receiverAddress',
  'receiverCity',
  'receiverPostalCode',
  'receiverStreet',
  'receiverTaxNumber'
];

export const selectLabelPrefixes: Record<string, string> = {
  documentType: 'INVOICE_EDIT_FORM_INVOICE_TYPE_',
  secondaryDocumentType: 'INVOICE_EDIT_FORM_SECONDARY_INVOICE_TYPE_',
  typeOfTransaction: 'INVOICE_EDIT_FORM_TYPE_OF_TRANSACTION_',
  paymentForm: 'INVOICE_EDIT_FORM_PAYMENT_FORM_'
};

export const lineField: FieldType = {
  key: 'lines',
  appDbField: 'lines',
  assistantKey: 'lines',
  label: 'DOCUMENT_EDIT_LINE_ITEMS',
  dataType: 'lines',
  exportMapKey: 'DOCUMENT_LINES',
  isAvailable: true,
  isActive: true,
  position: -1,
};

export const validation = (item: string | undefined, regex: RegExp) => {
  let isError = false;
  if (typeof item === 'string' && item !== '' && !!regex) {
    isError = !regex.test(item);
  }

  return isError;
};

export const getIsError = (key: string, dataType: string, value: string | undefined, user: UserDataType | null) => {
  const decimalSeparator = user?.monetaryDecimalSeparator || '.';
  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);

  if (key.includes('CountryCode')) {
    return !countryCodes.some((c) => c === value);
  }

  if (key === 'currency') {
    return !currencyCodes.some((c) => c === value);
  }

  let regexp = invoiceRegex[key as keyof DocumentEditFormRegexType] || noWhiteSpace;
  if (dataType === 'float') {
    regexp = amountRegex;
  }
  if (dataType === 'date') {
    regexp = dateFormat;
  }
  const fieldRule = user?.fieldRules.find((fr) => fr.field === key);
  if (fieldRule && fieldRule.regex) {
    regexp = new RegExp(fieldRule.regex);
  }
  const isError = validation(
    value,
    regexp,
  );
  return isError;
};

export const getDocumentEditFormValues = (user: UserDataType | null, document: DocType, fields: FieldType[]) => {
  const documentEditFormValues: Record<string, string> = {};
  fields.forEach((field) => {
    const val = document[field.key as keyof DocType];
    if (typeof val === 'string' || typeof val === 'undefined') {
      documentEditFormValues[field.key] = val || '';
    } else {
      documentEditFormValues[field.key] = '';
    }
  });

  documentEditFormValues.documentDate = document.date || '';
  documentEditFormValues.documentType = document.documentType
    ? document.documentType
    : (user?.documentType === 'id' ? documentTypes.id : documentTypes.invoice);
  documentEditFormValues.secondaryDocumentType = document.secondaryDocumentType || secondaryDocumentTypes.invoice;

  return documentEditFormValues;
};

export const getDocumentEditFormXMLValues = (fields: FieldType[], xmlVals?: Record<string, string>) => {
  const documentEditFormXMLValues: Record<string, string[]> = {};
  fields.forEach((field) => {
    let vals: string[] = [];
    if (xmlVals && xmlVals[field.assistantKey]) {
      vals = [...xmlVals[field.assistantKey]];
    }
    if (field.assistantKey) documentEditFormXMLValues[field.assistantKey] = vals;
  });
  documentEditFormXMLValues.currency = [...currencyCodes];
  documentEditFormXMLValues.receiver_country_code = [...countryCodes];
  documentEditFormXMLValues.sender_country_code = [...countryCodes];
  documentEditFormXMLValues.country = [...countryCodes];
  return documentEditFormXMLValues;
};

export const getDocumentEditFormCandidates = (
  fields: FieldType[],
  detailsXML: Record<string, string[]>,
  details: Record<string, string>,
) => {
  const candidates = {...detailsXML};
  Object.keys(detailsXML).forEach((attr) => {
    const assistantKey = attr;
    const procysKey = fields.find(f => f.assistantKey === assistantKey)?.key;
    if (procysKey) {
      const val = details[procysKey];
      const found = candidates[assistantKey].some(c => c === val);
      if (!found) {
        candidates[assistantKey] = [val, ...candidates[assistantKey]];
      }
    }
  });
  return candidates;
};

export const initializeSupplierCandidates = (candidates: string[], supplier: string | undefined) => {
  const found = candidates.some(c => c === supplier);
  if (supplier && !found) {
    candidates.unshift(supplier);
  }
  return candidates; 
};

export const getFieldTrustScores = (fields: FieldType[], trustScoresFromAsst?: Record<string, number>) => {
  const trustScores: Record<string, number> = {};
  for (const i in fields) {
    const key = fields[i].key;
    if (trustScoresFromAsst) {
      trustScores[key] = trustScoresFromAsst[key] || -1;
    }
  }
  return trustScores;
};

export const formatFieldTrustScores = (trustScores: Record<string, number>) => {
  const formattedTrustScores = { ...trustScores };
  Object.keys(trustScores).forEach((key) => {
    if (trustScores[key] === -1) {
      delete formattedTrustScores[key];
    }
  });
  return formattedTrustScores;
}

export const initializeAnnotateFieldTimes = (fields: FieldType[]) => {
  const annotateFieldTimes: Record<string, number> = {};
  for (let i = 0; i < fields.length; i++) {
    const { key } = fields[i];
    annotateFieldTimes[`${key}Time`] = 0;
  }
  annotateFieldTimes.vatLineBaseAmountTime = 0;
  annotateFieldTimes.vatLineVatRateTime = 0;
  annotateFieldTimes.vatLineVatAmountTime = 0;
  annotateFieldTimes.vatLineTaxNameTime = 0;
  annotateFieldTimes.esLineBaseAmountTime = 0;
  annotateFieldTimes.esLineEsRateTime = 0;
  annotateFieldTimes.esLineEsAmountTime = 0;
  return annotateFieldTimes;
};

export const annotateTimeVatEsMap: Record<string, string> = {
  VATLinebaseAmount: 'vatLineBaseAmountTime',
  VATLinerate: 'vatLineVatRateTime',
  VATLineamount: 'vatLineVatAmountTime',
  VATLinetaxName: 'vatLineTaxNameTime',
  ESLinebaseAmount: 'esLineBaseAmountTime',
  ESLinerate: 'esLineEsRateTime',
  ESLineamount: 'esLineEsAmountTime',
};

export const calculateVAT = async (vatGroup: string, amount: string, url: string, decimalSeparator: string) => {
  try {
    const response = await Axios.get(
      `${url}/${vatGroup}`,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    if (response.data.success) {
      const vatRate = response.data.data.ratePercentage;
      return calculateLineTaxForVat(amount, vatRate, decimalSeparator);
    }

    return '0.00'.replace('.', decimalSeparator);
  } catch (err) {
    return '0.00'.replace('.', decimalSeparator);
  }
};

export const getDescriptionFromTFSupplier = (descriptionRule: string, details: Record<string, string>, supplier: SupplierType | null) => {
  let description = descriptionRule;
  let descriptionChanged = false;
  const documentDate = details.documentDate || '';

  if (description) {
    if (description.indexOf('<month>') >= 0 && documentDate !== '' && documentDate.indexOf('-') >= 0) {
      const dateParts = documentDate.split('-');
      if (dateParts.length >= 3) {
        description = description.replaceAll('<month>', getMonthShortString(dateParts[1]));
        descriptionChanged = true;
      }
    }

    if (description.indexOf('<year>') >= 0 && documentDate !== '' && documentDate.indexOf('-') >= 0) {
      const dateParts = documentDate.split('-');
      if (dateParts.length >= 3 && documentDate.indexOf('-') >= 4) {
        description = description.replaceAll('<year>', dateParts[0]);
        descriptionChanged = true;
      }

      if (dateParts.length >= 3 && documentDate.indexOf('-') === 2) {
        description = description.replaceAll('<year>', dateParts[2]);
        descriptionChanged = true;
      }
    }

    if (description.indexOf('<supplier code>') >= 0 && supplier?.creditorCode !== '') {
      description = description.replaceAll('<supplier code>', supplier?.creditorCode || '');
      descriptionChanged = true;
    }

    if (description.indexOf('<country>') >= 0 && supplier?.country !== '') {
      description = description.replaceAll('<country>', supplier?.country || '');
      descriptionChanged = true;
    }

    return descriptionChanged ? description : details.description || '';
  }

  return details.description || '';
};

export const getCurrencyLabel = (code: string) => {
  let label = code;
  const curr = cc.find((c: { code: string, currency: string }) => c.code === code);
  if (curr !== undefined) {
    label = `${curr.code} | ${curr.currency}`;
  }
  return label;
};

export const getCountryLabel = (code: string) => {
  let label = code;
  const country = countries[code as keyof TCountries];
  if (country !== undefined) {
    label = `${code} | ${country.name}`;
  }
  return label;
};

export const getSupplierLabel = (supplier: SupplierType | string, isSupplierLabelWithVAT: boolean) => {
  if (typeof supplier === 'string') {
    return supplier;
  }
  if (isSupplierLabelWithVAT) {
    if (supplier.vatNumber) {
      return `${supplier.vatNumber} | ${supplier.creditorName}`;
    } else {
      return supplier.creditorName;
    }
  } else {
    return `${supplier.creditorCode} | ${supplier.creditorName}`;
  }
};

export const getTermsLabel = (code: string, qbTerms: {code: string, name: string}[]) => {
  for (let i = 0; i < qbTerms?.length; i++) {
    if (code === qbTerms[i].code) {
      return `${qbTerms[i].code} | ${qbTerms[i].name}`;
    }
  }

  return code;
};

export const newLineDetails = (lineID: number | undefined, line: DocumentLineType, lineTax: string) => {
  return {
    id: lineID,
    line: line.line,
    lineDescription: line.lineDescription || '',
    lineGLAccount: line.lineGLAccount || '',
    lineVatGroup: line.lineVatGroup || '',
    lineCostCenter: line.lineCostCenter || '',
    lineProject: line.lineProject || '',
    lineUnitPrice: line.lineUnitPrice || '',
    lineQuantity: line.lineQuantity || '',
    lineDiscount: line.lineDiscount || '',
    linePrice: line.linePrice || '',
    lineVatrate: line.lineVatrate || '',
    lineArticle: line.lineArticle || '',
    lineArticleClient: line.lineArticleClient || '',
    lineVatcode: line.lineVatcode || '',
    lineDiscountedPrice: line.lineDiscountedPrice || '',
    lineDiscountAmountOnUnitPrice: line.lineDiscountAmountOnUnitPrice || '',
    lineDiscountAmountOnTotalPrice: line.lineDiscountAmountOnTotalPrice || '',
    lineTax: lineTax,
    lineDeposit: line.lineDeposit || '',
    lineAmount: line.lineAmount || '',
    lineExtraCost: line.lineExtraCost,
    lineReferenceNumber: line.lineReferenceNumber || '',
    lineProductCount: line.lineProductCount || '',
    lineUnitMeasurement: line.lineUnitMeasurement || '',
    lineBaseAmount: line.lineBaseAmount || '',
    lineRetentionAmount: line.lineRetentionAmount || '',
    lineRetentionPercentage: line.lineRetentionPercentage || '',
    lineCategory: line.lineCategory || '',
    lineProductCode: line.lineProductCode || '',
    lineTotalPV: line.lineTotalPV || '',
    lineMeasureType: line.lineMeasureType || '',
  };
};

function isGLAccountType(dimension: any): dimension is GLAccountType {
  return (dimension as GLAccountType).code !== undefined;
}

function isVatGroupType(dimension: any): dimension is VatGroupType {
  return (dimension as VatGroupType).code !== undefined;
}

function isCostCenterType(dimension: any): dimension is CostCenterType {
  return (dimension as CostCenterType).code !== undefined;
}

function isProjectType(dimension: any): dimension is ProjectType {
  return (dimension as ProjectType).code !== undefined;
}

export const createDimensionMap = (dimensions: (GLAccountType | VatGroupType | CostCenterType | ProjectType)[]) => {
  return dimensions.reduce((map: Record<string, string>, dimension: GLAccountType | VatGroupType | CostCenterType | ProjectType) => {
    if (isGLAccountType(dimension) || isVatGroupType(dimension) || isCostCenterType(dimension) || isProjectType(dimension)) {
      map[dimension.code] = dimension.name || '';
    }
    return map;
  }, {} as Record<string, string>);
}

export const createSupplierMap = (suppliers: SupplierType[]) => {
  return suppliers.reduce((map: Record<string, SupplierType>, dimension: SupplierType) => {
    map[dimension.creditorCode] = dimension;
    return map;
  }, {} as Record<string, SupplierType>);
}

export const fieldsDataTypes = [
  {
    key: 'text',
    label: 'FIELD_TYPE_TEXT',
  },
  {
    key: 'float',
    label: 'FIELD_TYPE_NUMBER',
  },
  {
    key: 'date',
    label: 'FIELD_TYPE_DATE',
  },
  {
    key: 'boolean',
    label: 'FIELD_TYPE_BOOLEAN',
  },
];

export const getManageFieldsHeader = (entity: string = '') => {
  switch (entity) {
    case 'invoice-line':
      return 'MANAGE_FIELDS_LINES_HEADER';
    default:
      return 'MANAGE_FIELDS_HEADER';
  }
};

export const createFieldMap = (fields: FieldType[]): Record<string, {active: boolean, position: number}> => {
  return fields
  .map((f, i) => ({...f, position: i + 1}))
  .reduce((map: Record<string, {active: boolean, position: number}>, field: FieldType) => {
    map[field.key] = { active: Boolean(field.isActive), position: field.position };
    return map;
  }, {});
};

export const getVATLineTooltip = (vl: DocumentEditFormVatEsLineType) => {
  if (vl.invalid) {
    return 'INVALID_NUMERIC_INPUT_FOR_INVOICE';
  }
  if (vl.error) {
    return 'INVOICE_EDIT_FORM_VAT_DETAILS_INVALID_LINE';
  }
  return '';
};

export const vatLineLabels: Record<string, string> = {
  baseAmount: 'VAT_LINE_BASE_AMOUNT',
  rate: 'VAT_LINE_VAT_RATE',
  amount: 'VAT_LINE_VAT_AMOUNT',
  taxName: 'VAT_LINE_TAX_NAME'
};

export const esLineLabels: Record<string, string> = {
  baseAmount: 'VAT_LINE_BASE_AMOUNT',
  rate: 'SURCHARGE_LINE_SURCHARGE_RATE',
  amount: 'SURCHARGE_LINE_SURCHARGE_AMOUNT'
};

export const supplierFieldMapping: Record<string, string> = {
  creditorCode: 'SUPPLIER_CODE',
  creditorName: 'SUPPLIER_NAME',
  fiscalName: 'SUPPLIER_FISCAL_NAME',
  autoPaymentStatus: 'SUPPLIER_AUTO_PAYMENT_STATUS',
  dueDays: 'SUPPLIER_DUE_DAYS',
  website: 'SUPPLIER_WEBSITE',
  comment: 'SUPPLIER_COMMENT',
  companyRegNumber: 'SUPPLIER_COMPANY_REG_NUMBER',
  vatNumber: 'SUPPLIER_VAT_NUMBER',
  referenceCode: 'SUPPLIER_REFERENCE_CODE',
  status: 'SUPPLIER_STATUS',
  generalLedgerAccount: 'SUPPLIER_GENERAL_LEDGER_ACCOUNT',
  vatGroup: 'SUPPLIER_VAT_GROUP',
  generalLedgerAccLine: 'SUPPLIER_GENERAL_LEDGER_ACC_LINE',
  vatGroupLine: 'SUPPLIER_VAT_GROUP_LINE',
  invoiceDescriptionRule: 'SUPPLIER_INVOICE_DESCRIPTION_RULE',
  invoiceLineDescriptionRule: 'SUPPLIER_INVOICE_LINE_DESCRIPTION_RULE',
  defaultCurrency: 'SUPPLIER_DEFAULT_CURRENCY',
  bankCountry: 'SUPPLIER_BANK_COUNTRY',
  ascription: 'SUPPLIER_ASCRIPTION',
  iban: 'SUPPLIER_IBAN',
  bankAccNumber: 'SUPPLIER_BANK_ACC_NUMBER',
  bicSwift: 'SUPPLIER_BIC_SWIFT',
  nationalBankCode: 'SUPPLIER_NATIONAL_BANK_CODE',
  bankName: 'SUPPLIER_BANK_NAME',
  bankCity: 'SUPPLIER_BANK_CITY',
  bankStreet: 'SUPPLIER_BANK_STREET',
  bankHouseNumber: 'SUPPLIER_BANK_HOUSE_NUMBER',
  country: 'SUPPLIER_COUNTRY',
  city: 'SUPPLIER_CITY',
  postalCode: 'SUPPLIER_POSTAL_CODE',
  address1: 'SUPPLIER_ADDRESS_1',
  address2: 'SUPPLIER_ADDRESS_2',
  poBox: 'SUPPLIER_PO_BOX',
  poBoxPostalCode: 'SUPPLIER_PO_BOX_POSTAL_CODE',
  poBoxCity: 'SUPPLIER_PO_BOX_CITY',
  contactPersonName: 'SUPPLIER_CONTACT_PERSON_NAME',
  telephoneNumber: 'SUPPLIER_TELEPHONE_NUMBER',
  faxNumber: 'SUPPLIER_FAX_NUMBER',
  mobileTelNumber: 'SUPPLIER_MOBILE_TEL_NUMBER',
  emailAddress: 'SUPPLIER_EMAIL_ADDRESS',
  postcode: 'SUPPLIER_POSTCODE',
  state: 'SUPPLIER_STATE',
  discountDays: 'SUPPLIER_DISCOUNT_DAYS',
  discountPercentage: 'SUPPLIER_DISCOUNT_PERCENTAGE',
  matchType: 'SUPPLIER_MATCH_TYPE',
  custom1: 'SUPPLIER_CUSTOM_1',
  custom2: 'SUPPLIER_CUSTOM_2',
  custom3: 'SUPPLIER_CUSTOM_3'
};

export const vatLineFieldMapping: Record<string, string> = {
  taxName: 'INVOICE_VAT_DETAIL_TAX_NAME',
  baseAmount: 'INVOICE_VAT_DETAIL_BASE_AMOUNT',
  vatRate: 'INVOICE_VAT_DETAIL_RATE',
  vatAmount: 'INVOICE_VAT_DETAIL_AMOUNT',
};

export const esLineFieldMapping: Record<string, string> = {
  baseAmount: 'INVOICE_ES_DETAIL_BASE_AMOUNT',
  esRate: 'INVOICE_ES_DETAIL_RATE',
  esAmount: 'INVOICE_ES_DETAIL_AMOUNT'
};

export const vatLineLabelsForExport: Record<string, string> = {
  baseAmount: 'VAT_LINE_EXPORT_BASE_AMOUNT',
  vatRate: 'VAT_LINE_EXPORT_VAT_RATE',
  vatAmount: 'VAT_LINE_EXPORT_VAT_AMOUNT',
  taxName: 'VAT_LINE_EXPORT_TAX_NAME'
};

export const esLineLabelsForExport: Record<string, string> = {
  baseAmount: 'ES_LINE_EXPORT_BASE_AMOUNT',
  esRate: 'ES_LINE_EXPORT_ES_RATE',
  esAmount: 'ES_LINE_EXPORT_ES_AMOUNT'
};

export const vatLineFields: FieldType[] = [
  {
    key: 'taxName',
    appDbField: 'tax_name',
    assistantKey: 'tax_name',
    dataType: 'text',
    exportMapKey: vatLineLabelsForExport.taxName,
    label: vatLineLabels.taxName,
    isAvailable: true,
    position: 1
  },
  {
    key: 'baseAmount',
    appDbField: 'base_amount',
    assistantKey: 'base_amount',
    dataType: 'float',
    exportMapKey: vatLineLabelsForExport.baseAmount,
    label: vatLineLabels.baseAmount,
    isAvailable: true,
    position: 2
  },
  {
    key: 'vatRate',
    appDbField: 'tax_rate',
    assistantKey: 'vat_rate',
    dataType: 'float',
    exportMapKey: vatLineLabelsForExport.vatRate,
    label: vatLineLabels.rate,
    isAvailable: true,
    position: 3
  },
  {
    key: 'vatAmount',
    appDbField: 'tax_amount',
    assistantKey: 'vat_amount',
    dataType: 'float',
    exportMapKey: vatLineLabelsForExport.vatAmount,
    label: vatLineLabels.amount,
    isAvailable: true,
    position: 4
  },
];

export const esLineFields: FieldType[] = [
  {
    key: 'baseAmount',
    appDbField: 'base_amount',
    assistantKey: 'base_amount',
    dataType: 'float',
    exportMapKey: esLineLabelsForExport.baseAmount,
    label: esLineLabels.baseAmount,
    isAvailable: true,
    position: 1,
  },
  {
    key: 'esRate',
    appDbField: 'es_rate',
    assistantKey: 'es_rate',
    dataType: 'float',
    exportMapKey: esLineLabelsForExport.esRate,
    label: esLineLabels.rate,
    isAvailable: true,
    position: 2,
  },
  {
    key: 'esAmount',
    appDbField: 'es_amount',
    assistantKey: 'es_amount',
    dataType: 'float',
    exportMapKey: esLineLabelsForExport.esAmount,
    label: esLineLabels.amount,
    isAvailable: true,
    position: 3,
  }
];

export const truncateFileName = (fileName: string) => {
  const fileNameParts = fileName.split('.');
  const extension = fileNameParts.pop();
  const name = fileNameParts.join('.');
  if (name.length > 24) {
    return `${name.slice(0, 24)}... .${extension}`;
  }
  return `${name}.${extension}`;
};

export const isOverlapping = (box1: Scaled, box2: Scaled) => {
  // Check for a separating axis along the x-axis
  if (box1.x2 <= box2.x1 || box1.x1 >= box2.x2) {
    return false;
  }

  // Check for a separating axis along the y-axis
  if (box1.y2 <= box2.y1 || box1.y1 >= box2.y2) {
    return false;
  }

  return true;
}

export const isWithin = (box1: Scaled, box2: Scaled) => {
  // Check if box2 is completely within box1
  if (
    box2.x1 >= box1.x1 && // box2's left edge is to the right of box1's left edge
    box2.x2 <= box1.x2 && // box2's right edge is to the left of box1's right edge
    box2.y1 >= box1.y1 && // box2's top edge is below box1's top edge
    box2.y2 <= box1.y2    // box2's bottom edge is above box1's bottom edge
  ) {
    return true;
  }

  return false;
};

export const invoiceHelperText: Record<string, string> = {
  senderVatNumber: 'INVALID_VAT_NUMBER_HELPER_TEXT',
  paymentRefOGMVCS: 'INVALID_PAYMENT_REFERENCE_HELPER_TEXT',
  senderWebsite: 'INVALID_URL_HELPER_TEXT',
  email: 'INVALID_EMAIL_ADDRESS_HELPER_TEXT',
  senderKvk: 'INVOICE_EDIT_FORM_SENDER_KVK_ONLY_DIGITS'
};

export const getFieldTooltip = (
  key: string,
  dataType: string,
  details: Record<string, string>,
  user: UserDataType | null,
  t: TFunction<"translation", undefined>
) => {
  const val = details[key];
  if (getIsError(key, dataType, val, user)) {
    if (dataType === 'float') {
      return t('INVALID_NUMERIC_INPUT_FOR_INVOICE');
    }
    if (dataType === 'date') {
      const fieldRule = user?.fieldRules.find((fr) => fr.field === key);
      if (fieldRule && fieldRule.format && fieldRule.rule) {
        return t('INVALID_DATE_FORMAT_HELPER_TEXT_NEW', { dateFormat: fieldRule.format, example: fieldRule.rule });
      }
      return t('INVALID_DATE_FORMAT_HELPER_TEXT_NEW_2');
    }
    return t(invoiceHelperText[key] || 'NO_WHITE_SPACE_HELPER_TEXT');
  }
  if (key === 'amount' && !isAmountTallyingWithLines(details)) {
    return t('INVOICE_EDIT_FORM_AMOUNT_NOT_TALLYING_WITH_LINES');
  }
  const senderVatNumberInvalid = !isVatNumberInvalid(details.senderVatNumber, details.senderCountryCode);
  if (key === 'senderVatNumber' && senderVatNumberInvalid) {
    return t('INVALID_VAT_NUMBER_HELPER_TEXT');
  }
  if (key === 'senderCountryCode' && senderVatNumberInvalid) {
    return ' ';
  }
  const receiverVatNumberInvalid = !isVatNumberInvalid(details.receiverVatNumber, details.receiverCountryCode);
  if (key === 'receiverVatNumber' && receiverVatNumberInvalid) {
    return t('INVALID_VAT_NUMBER_HELPER_TEXT');
  }
  if (key === 'receiverCountryCode' && receiverVatNumberInvalid) {
    return ' ';
  }
  return '';
};

export const isElementInViewport = (el: HTMLElement | null) => {
  if (!el) return false;
  const rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export const formatLinesReprocessingData = (data: Record<string, IHighlight[]>) => {
  const formattedData: Record<string, Record<string, number>[]> = {};
  Object.keys(data).forEach((key) => {
    const columnData = data[key];
    const newColumnData = columnData.map((highlight) => ({
      x1: parseInt(highlight.position.boundingRect.x1.toString()),
      x2: parseInt(highlight.position.boundingRect.x2.toString()),
      y1: parseInt(highlight.position.boundingRect.y1.toString()),
      y2: parseInt(highlight.position.boundingRect.y2.toString()),
      page: highlight.position.pageNumber
    }));
    formattedData[key] = newColumnData;
  });
  return formattedData;
};

export const formatExportFailReason = (t: TFunction<"translation", undefined>, reason: string) => {
  const str = `${t('FAILED_TO_EXPORT_DOCUMENT')}:`;
  if (reason.includes('__MESSAGE__')) {
    const arr = reason.split('__MESSAGE__');
    if (arr.length === 2) {
      return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${arr[1]}`, t);
    }
    if (arr.length === 3) {
      return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${arr[1]}, ${arr[2]}`, t);
    }
  }
  if (reason.includes('__BR__')) {
    const arr = reason.split('__BR__');
    if (arr.length === 2) {
      return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}`, t);
    }
    if (arr.length === 3) {
      return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])}`, t);
    }
    if (arr.length === 4) {
      if (arr[2].includes('LINE')) {
        return appendContactSupport(
          window.config.support_email,
          `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])} - ${t('DOCUMENT_EXPORT_ERROR_LINE_NO')} ${arr[3]}`,
          t
        );
      }
      return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])} ${t(arr[3])}`, t);
    }
  }
  return appendContactSupport(window.config.support_email, str, t);
};

export const documentTypes: Record<string, string> = {
  invoice: 'INVOICE',
  'credit_note': 'CREDIT_INVOICE',
  attachment: 'ATTACHMENT',
  'purchase_order': 'PURCHASE_ORDER',
  receipt: 'RECEIPT',
  id: 'PASSPORT_OR_ID'
};
