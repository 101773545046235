import { useState } from "react";

import { Box, Grid, Backdrop, CircularProgress } from "@mui/material";

import DimensionsLayout from "src/dimensions-new/DimensionsNew";

const ImportView = () => {
  const [loading] = useState(false);
  return (
    <DimensionsLayout showImport={true}>
      <Grid sx={styles.root} className="x-scroll">
        <Box sx={styles.wrapper}>
         Import View
        </Box>
      </Grid>
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DimensionsLayout>
        
  )
}

const styles={
  root:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-start',
    padding:'20px',
    
  },
  wrapper:{
    backgroundColor:'#fff',
    minWidth:'660px',
  },
  top:{
    display:'flex',
    alignItems:'center',
    width:'100%',
    padding:'5px 0',
    gap:'10px',
    marginBlock:'0 20px'
  },
  topLeft:{
    fontSize:'16px',
    color:'#4C4E64',
    fontWeight:'500',
    marginInlineEnd:'auto'
  },
  btns:{
    gap:'8px',
    color:'#3E8AFF',
    textTransform:'Capitalize',
    fontSize:'13px',
    paddingInline:'10px',
  },
  addBtn:{
    background:'#3E8AFF',
    color:'#fff',
    paddingInline:'15px',
    gap:'8px',
    textTransform:'Capitalize',
    fontSize:'13px',
    '&:hover':{
      backgroundColor:'#3E8AFF',
      color:'#fff'
    }
  },
  menuContainer:{
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    width:'200px',
  },
  exportIconContainer:{
    display:'flex',
    alignItems:'center',
    gap:'10px',
    fontSize:'14px',
    color:'#4C4E64DE'
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff',
  },
}
export default ImportView