import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import styles from './style';

const DeleteDocumentModal = ({
  openDeleteConf,
  onCancelDelete,
  onDeleteInvoice,
}) => {
  const { t, ready: tReady } = useTranslation();

  return (
    <Modal
      style={styles.modal}
      open={openDeleteConf}
      onClose={onCancelDelete}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={openDeleteConf}>
        <div style={styles.paperModal}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {tReady && t('INVOICE_DELETE_INVOICE')}
            </Typography>
            <IconButton onClick={onCancelDelete}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid style={styles.deleteMessageText}>
            <Typography variant="caption">
              {tReady && t('INVOICE_DELETE_CONFIRMATION_MESSAGE')}
            </Typography>
          </Grid>
          <Grid style={styles.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                style={styles.closeBtn}
                onClick={onCancelDelete}
              >
                {tReady && t('INVOICE_DELETE_CANCEL')}
              </Button>
              <Button
                variant="contained"
                style={styles.submitBtn}
                onClick={() => onDeleteInvoice()}
              >
                {tReady && t('INVOICE_DELETE_CONFIRM')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default DeleteDocumentModal;
