import { FETCH_COSTCENTERS_FAIL, FETCH_COSTCENTERS_START, FETCH_COSTCENTERS_SUCCESS } from "../../actionTypes";

const initialState = {
  costCenters: [],
  total: 0,
  loading: false,
  error: null
};

const costCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COSTCENTERS_START:
      return {...state, loading: true, error: null};
    case FETCH_COSTCENTERS_SUCCESS:
      return {...state, costCenters: action.payload.costCenters, total: action.payload.total, loading: false, error: null};
    case FETCH_COSTCENTERS_FAIL:
      return {...state, loading: false, error: action.payload.error};
    default:
      return state;
  }
};

export default costCenterReducer;
