import theme from "src/theme";

const styles = {
  userImage: {
    flexShrink: 0,
    height: 32,
    width: 32,
    color: '#C2CFE0',
    backgroundColor: '#EFF4FA',
    '& svg': {
      width: 16,
      height: 16
    }
  },
  pointer: {
    height: 64,
  },
  headerStyle: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.header,
    align: 'center'
  },
  cellStyle: {
    fontSize: 15,
    color: theme.palette.text.primary
  },
  row: {
    height: 48
  },
  statusLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    textTransform: 'capitalize',
    width: 96,
    fontSize: 14,
    fontWeight: 600,
  },
  statusDot: {
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    width: 29,
    cursor: 'pointer',
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
  sub: {
    color: '#ffc400',
    backgroundColor: 'rgba(255, 196, 0, 0.3)'
  },
  main: {
    color: '#3CB573',
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
  subDot: {
    backgroundColor: 'rgba(255, 196, 0, 0.3)'
  },
  mainDot: {
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  caption: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: theme.palette.text.header,
    paddingLeft: 24
  },
};

export default styles;
