import theme from 'src/theme';

const styles = {
  rootSubcompany: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paperModal: {
    width: 600,
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  descriptionMessageText: {
    color: theme.palette.text.primary,
    fontSize: 12,
    paddingTop: 16
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 16
  },
  submitBtn: {
    backgroundColor: theme.palette.button.action,
  }
};

export default styles;
