const styles = {
  root: {
    height: '100vh',
    backgroundColor: 'gray'
  },
  pdfFrame: {
    width: '100%',
    height: '100%',
    borderWidth: 0
  }
};

export default styles;
