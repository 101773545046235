import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Box, Card, IconButton, Typography, SvgIcon, Table, TableBody,
  TableCell, TableHead, TablePagination, TableRow, Grid, Avatar, Button,
  Hidden, Menu, MenuItem, Tooltip, useMediaQuery, Link, Alert
} from '@mui/material';
import {
  AddCircle as PlusCircleIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import LoadingScreen from 'src/shared/screens/LoadingScreen';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import UserCreateForm from 'src/account/components/UserCreateForm/UserCreateForm';
import UserEditForm from 'src/account/components/UserEditForm/UserEditForm';
import NewUserInviteLinkPopup from 'src/account/components/NewUserInviteLinkPopup/NewUserInviteLinkPopup';
import { useAuth } from 'src/hooks/useAuth';
import { userEditOptions } from 'src/config';
import authService from 'src/utils/authService';
import {
  applySort, applyPagination, applyUserFilters, axiosHeaders, isActionPermitted, permissions,
  isUserManageOptionValid, getLocalisedErrorString, userEditOptionLabels, axiosDeleteHeaders, isBranded
} from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

function Results() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t, ready } = useTranslation();

  const auth = useAuth();
  const {user, setUser} = auth;

  const { API, BRAND_NAME, LOGIN_PANEL_URL } = useContext(ConfigContext);

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const [addUserOpen, setAddUserOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query] = useState('');
  const [sortBy] = useState('');
  const [sortOrder] = useState('');
  const [filters] = useState({
    status: null
  });

  const [menuUserID, setMenuUserID] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [roles, setRoles] = useState([]);

  const [userInviteLinkOpen, setUserInviteLinkOpen] = useState(false);
  const [userInviteLink, setUserInviteLink] = useState('');

  const maxWidth = useMediaQuery('(max-width: 470px)');
  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  // Usually query is done on backend with indexing solutions
  const sortedUsers = applySort(users, sortBy, sortOrder);
  const filteredUsers = applyUserFilters(sortedUsers, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);

  const handleOpenMenu = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuUserID(id);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuUserID(null);
    setMenuAnchorEl(null);
  };

  const getAllUsers = async () => {
    setLoading(true);
    if (user && user.companyID) {
      try {
        // NOTE: Not going with pagination in V1
        const url = `${API.getUsersByCompanies}${encodeURIComponent(user.companyID)}`;
        const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setUsers(response.data.data.map((u) => ({ ...u, name: `${u.firstname} ${u.lastname}` })));
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const getAllRoles = async () => {
    try {
      const url = `${API.getUserRoles}`;
      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setRoles(response.data.data);
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  const closeUserInviteLinkPopup = () => {
    setUserInviteLinkOpen(false);
    getAllUsers();
  };

  const onEditUser = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    setEditUser(u);
    setEditUserOpen(true);
  };

  const addNewUser = async (event, body) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    if ((user.usersCount + 1) > user.maxUsers) {
      enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT'), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
      return;
    }

    try {
      const response = await Axios.post(
        `${API.userProfile}`,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (isBranded(BRAND_NAME)) {
          setUserInviteLink(response.data.data.link);
          setUserInviteLinkOpen(true);
        } else {
          enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_SUCCESS', { email: body.email }), {
            variant: 'success',
            style: { maxWidth: 400 }
          });
          getAllUsers();
        }
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const revokeUserInvitation = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    try {
      const response = await Axios.post(
        `${API.revokeUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          const updatedUser = {
            ...user,
            usersCount: user.usersCount - 1
          };
          setUser(updatedUser);
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_REVOKE_SUCCESS', { email: u.email }), {
          variant: 'success',
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const removeUser = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    try {
      const response = await Axios.post(
        `${API.removeUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          const updatedUser = {
            ...user,
            usersCount: user.usersCount - 1
          };
          setUser(updatedUser);
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_REMOVE_SUCCESS', { email: u.email }), {
          variant: 'success',
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const restoreUser = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    if ((user.usersCount + 1) > user.maxUsers) {
      enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT'), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
      return;
    }

    try {
      const response = await Axios.post(
        `${API.restoreUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          const updatedUser = {
            ...user,
            usersCount: user.usersCount + 1
          };
          setUser(updatedUser);
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_RESTORE_SUCCESS', { email: u.email }), {
          variant: 'success',
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const deleteUser = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    try {
      const response = await Axios.delete(
        `${API.deleteUser}`,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), { email: u.email })
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_DELETE_SUCCESS', { email: u.email }), {
          variant: 'success',
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const handleUserManageMenuClick = (event, u, option) => {
    switch (option) {
      case 'change':
        onEditUser(event, u);
        break;

      case 'resend':
        addNewUser(event, u);
        break;

      case 'revoke':
        revokeUserInvitation(event, u);
        break;

      case 'remove':
        removeUser(event, u);
        break;

      case 'restore':
        restoreUser(event, u);
        break;

      case 'delete':
        deleteUser(event, u);
        break;

      default:
        break;
    }
  };

  const addUserCallback = async () => {
    await getAllUsers();
    if (!paginatedUsers || paginatedUsers.length === 0) {
      history.push('/account?feedback-first-user=success', { tab: 2 });
    } else {
      history.push('/account', { tab: 2 });
    }
  };

  useEffect(() => {
    getAllUsers();
    getAllRoles();
  }, []); // eslint-disable-line

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handleAddNewUser = () => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setAddUserOpen(true);
  };

  const isNotRemoveCurrentUser = (option, u) => !(u.email === user.email && option === 'remove');

  if (loading) {
    return <Grid style={styles.loadingContainer}><LoadingScreen /></Grid>;
  }

  return (
    <>
      <Grid container style={styles.header}>
        <Grid item xs={12} sm={4}>
          <Typography style={styles.headerTitle}>
            {ready && t('ACCOUNT_SETTINGS_USERS')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={styles.headerActions}>
          {
            user && (
              <Tooltip
                title={
                  user.usersCount >= user.maxUsers // eslint-disable-line
                    ? t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT')
                    : isActionPermitted(permissions.userAdd, user.permissions)
                      ? t('ACCOUNT_SETTINGS_USERS_ADD_TOOLTIP')
                      : t('ACCOUNT_SETTINGS_USERS_ADD_TOOLTIP_PERMISSION')
                }
              >
                <span>
                  <Button
                    variant="contained"
                    disabled={user.usersCount >= user.maxUsers || !isActionPermitted(permissions.userAdd, user.permissions)}
                    style={styles.action}
                    onClick={handleAddNewUser}
                  >
                    <SvgIcon
                      fontSize="small"
                      style={styles.actionIcon}
                    >
                      <PlusCircleIcon />
                    </SvgIcon>
                    {ready && t('ACCOUNT_SETTINGS_USERS_ADD_USER')}
                  </Button>
                </span>
              </Tooltip>
            )
          }
        </Grid>
      </Grid>
      {
        user.usersCount >= user.maxUsers && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={styles.addUserDisabled}
          >
            <Alert severity="warning" style={styles.warningBanner}>
              <Typography>
                {ready && t('ACCOUNT_SETTINGS_ADD_USER_DISABLED')}
                <strong>
                  <Link href="/account/subscription">
                    {ready && t('ACCOUNT_SETTINGS_ADD_USER_UPDATE_LINK')}
                  </Link>
                </strong>
              </Typography>
            </Alert>
          </Grid>
        )
      }
      {
        paginatedUsers && paginatedUsers.length > 0
          ? (
            <Card style={styles.root}>
              <PerfectScrollbar>
                <Box minWidth="100%">
                  <Table size="small">
                    <TableHead>
                      <TableRow style={styles.row}>
                        <TableCell style={styles.headerStyle}>
                          {ready && t('ACCOUNT_SETTINGS_USERS_USER')}
                        </TableCell>
                        <Hidden only={['xs', 'sm']}>
                          <TableCell style={styles.headerStyle}>
                            {ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')}
                          </TableCell>
                        </Hidden>
                        <Hidden only={['xs', 'sm', 'md']}>
                          <TableCell style={styles.headerStyle}>
                            {ready && t('ACCOUNT_SETTINGS_USERS_ROLE')}
                          </TableCell>
                        </Hidden>
                        <TableCell style={styles.headerStyle}>
                          {ready && t('ACCOUNT_SETTINGS_USERS_STATUS')}
                        </TableCell>
                        <TableCell style={styles.headerStyle} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedUsers.map((u) => (
                        <TableRow
                          hover
                          key={u.id}
                          style={styles.pointer}
                        >
                          <TableCell style={styles.cellStyle}>
                            <Box
                              display="flex"
                              alignItems="center"
                            >
                              <Avatar
                                alt="user"
                                style={styles.userImage}
                                src={u.avatar}
                              />
                              <Box ml={2} mr={2} display="flex" flexDirection="column">
                                <Typography
                                  color="inherit"
                                  to="#"
                                  variant="h6"
                                  style={styles.cellStyle}
                                >
                                  {u.name}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <Hidden only={['xs', 'sm']}>
                            <TableCell style={styles.cellStyle}>
                              {u.email}
                            </TableCell>
                          </Hidden>
                          <Hidden only={['xs', 'sm', 'md']}>
                            <TableCell style={styles.cellStyle}>
                              <Grid style={styles.roleLabel}>
                                {u.userRole}
                              </Grid>
                            </TableCell>
                          </Hidden>
                          <TableCell style={styles.cellStyle}>
                            <Hidden only={['xs']}>
                              <Grid style={{...styles[u.status.toLowerCase()], ...styles.statusLabel}}>
                                {u.status}
                              </Grid>
                            </Hidden>
                            <Hidden smUp>
                              <Grid style={{...styles[`${u.status.toLowerCase()}Dot`], ...styles.statusDot}} />
                            </Hidden>
                          </TableCell>
                          <TableCell width={56} align="center">
                            <Tooltip
                              title={isActionPermitted(permissions.userLock, user.permissions)
                                  && isActionPermitted(permissions.userPermissionManage, user.permissions)
                                  && isActionPermitted(permissions.userRoleManage, user.permissions)
                                ? ''
                                : t('ACCOUNT_SETTINGS_USERS_EDIT_TOOLTIP_PERMISSION')}
                            >
                              <span>
                                <IconButton
                                  disabled={!isActionPermitted(permissions.userLock, user.permissions)
                                      || !isActionPermitted(permissions.userPermissionManage, user.permissions)
                                      || !isActionPermitted(permissions.userRoleManage, user.permissions)}
                                  onClick={(e) => handleOpenMenu(e, u.id)}
                                >
                                  <SvgIcon style={{ display: 'flex' }}>
                                    <MoreVertIcon style={styles.moreIcon} />
                                  </SvgIcon>
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Menu
                              id="user-actions"
                              anchorEl={menuAnchorEl}
                              keepMounted
                              open={Boolean(menuAnchorEl) && menuUserID === u.id}
                              onClose={handleCloseMenu}
                            >
                              {userEditOptions.map((option) => {
                                if (isUserManageOptionValid(option, u.status.toLowerCase()) && isNotRemoveCurrentUser(option, u)) {
                                  return (
                                    <MenuItem key={option} onClick={(e) => handleUserManageMenuClick(e, u, option)}>
                                      {userEditOptionLabels[option]}
                                    </MenuItem>
                                  );
                                }
                                return null;
                              })}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <div style={styles.caption}>
                {
                  !maxWidth && `${filteredUsers.length} user${filteredUsers.length !== 1 ? 's' : ''} available`
                }
                <TablePagination
                  component="div"
                  count={filteredUsers.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
                />
              </div>
            </Card>
          )
          : <EmptyText str="ACCOUNT_SETTINGS_USERS_NO_USERS" />
      }
      <UserCreateForm
        addUserOpen={addUserOpen}
        handleClose={() => setAddUserOpen(false)}
        onGetAllUsers={addUserCallback}
        user={user}
        updateUser={setUser}
      />
      <UserEditForm
        editUserOpen={editUserOpen}
        user={user}
        roles={roles}
        editUser={editUser}
        handleClose={() => setEditUserOpen(false)}
        onGetAllUsers={getAllUsers}
      />
      <NewUserInviteLinkPopup
        isOpen={userInviteLinkOpen}
        handleClose={closeUserInviteLinkPopup}
        link={userInviteLink}
      />
    </>
  );
}

export default Results;
