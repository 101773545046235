import {
  useState, useEffect, createRef, useContext
} from 'react';
import Axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Card, Grid, Hidden, SvgIcon, Table, TableBody,
  TableCell, TablePagination, TableRow, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import {
  AddCircle as PlusCircleIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import authService from 'src/utils/authService';
import ConfigContext from 'src/contexts/ConfigContext';
import {
  axiosHeaders, isActionPermitted, permissions
} from 'src/utils/helpers';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import styles from './style';

function Billing(props) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  
  const {
    header, pageLimit, subscription
  } = props;
  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;

  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [defaultCard, setDefaultCard] = useState();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(pageLimit || 10);

  const maxWidth = useMediaQuery('(max-width: 470px)');
  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const intervalID = createRef();

  const getAllCards = async (id) => {
    setLoading(true);
    try {
      const response = await Axios.get(
        API.userCards,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        let defCard = null;
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].id === id) {
            defCard = response.data.data[i];
          }
        }

        if (defCard) {
          setCards([defCard]);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const paymentProfileMethod = async () => {
    try {
      const response = await Axios.get(
        API.subscriptionDetails,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getAllCards(response.data.data[0].creditCard.id);
        setDefaultCard(response.data.data[0].creditCard.id);
      } else {
        getAllCards(null);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    paymentProfileMethod();
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const onStartInterval = () => {
    let counter = 0;
    intervalID.current = setInterval(async () => {
      counter++;
      paymentProfileMethod();

      if (counter === 120) {
        clearInterval(intervalID.current);
      }
    }, 10000);
  };

  const handleAddCard = () => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const width = screenWidth > 700 ? 700 : screenWidth - 20;
    const height = screenHeight > 700 ? 700 : screenHeight - 20;

    const excessH = screenHeight - height - 100;
    const top = excessH > 0 ? excessH / 2 : 10;

    const excessW = screenWidth - width;
    const left = excessW > 0 ? excessW / 2 : 10;

    window.open(subscription.selfServiceUrl, 'ProcysBillingInformation', `height=${height},width=${width},top=${top},left=${left}`);
    onStartInterval();
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Grid
        container
        style={styles.header}
      >
        <Grid item xs={12} sm={4}>
          <Typography style={styles.headerTitle}>
            {ready && t(header || 'ACCOUNT_SETTINGS_BILLING_INVOICES_MY_CARDS')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} style={styles.headerActions}>
          {
            cards && cards.length > 0 ? (
              <Tooltip title={isActionPermitted(permissions.subscriptionsEditCard, user.permissions)
                ? ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TOOLTIP')
                : ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TOOLTIP_PERMISSION')}
              >
                <span>
                  <Button
                    type="submit"
                    variant="contained"
                    style={styles.addCardBtn}
                    onClick={handleAddCard}
                    disabled={!isActionPermitted(permissions.subscriptionsAddCard, user.permissions)}
                  >
                    {ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TITLE')}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={isActionPermitted(permissions.subscriptionsAddCard, user.permissions)
                ? ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD_TOOLTIP')
                : ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD_TOOLTIP_PERMISSION')}
              >
                <span>
                  <Button
                    type="submit"
                    variant="contained"
                    style={styles.addCardBtn}
                    onClick={handleAddCard}
                    disabled={!isActionPermitted(permissions.subscriptionsAddCard, user.permissions)}
                  >
                    <SvgIcon
                      fontSize="small"
                      style={styles.actionIcon}
                    >
                      <PlusCircleIcon />
                    </SvgIcon>
                    {ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD')}
                  </Button>
                </span>
              </Tooltip>
            )
          }
        </Grid>
      </Grid>
      {
        cards && cards.length > 0
          ? (
            <Card style={styles.root}>
              <PerfectScrollbar>
                <Box minWidth="100%">
                  <Table size="small">
                    <TableBody>
                      {cards.map((card) => (
                        <TableRow key={card.id} style={styles.row}>
                          <TableCell style={styles.cellText}>
                            <Grid style={styles.cardNumberRow}>
                              {card.cardType === 'master' && <img src="/static/images/master.svg" alt="" style={styles.cardType} />}
                              {card.cardType === 'visa' && <img src="/static/images/visa.svg" alt="" style={styles.cardType} />}
                              {card.cardType === 'american_express' && <img src="/static/images/amex.svg" alt="" style={styles.cardType} />}
                              {card.maskedCardNumber}
                              {
                                defaultCard === card.id
                                  ? (
                                    <Grid style={styles.defaultLabel}>
                                      <Hidden xsDown>
                                        {ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_DEFAULT')}
                                      </Hidden>
                                    </Grid>
                                  )
                                  : null
                              }
                            </Grid>
                          </TableCell>
                          <Hidden smDown>
                            <TableCell style={styles.cellText}>
                              {`${card.firstName || ''} ${card.lastName || ''}`}
                            </TableCell>
                          </Hidden>
                          <Hidden smDown>
                            <TableCell align="center" style={styles.cellText}>
                              {
                                ready && `${t('Exp.')}${card.expirationMonth.toString().length !== 2
                                  ? `0${card.expirationMonth}`
                                  : card.expirationMonth}/${card.expirationYear}`
                              }
                            </TableCell>
                          </Hidden>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <div style={styles.caption}>
                {
                  !maxWidth && `${cards.length} card${cards.length !== 1 ? 's' : ''} available`
                }
                <TablePagination
                  component="div"
                  count={cards.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
                />
              </div>
            </Card>
          )
          : <EmptyText str="ACCOUNT_SETTINGS_BILLING_NO_CARDS" />
      }
    </>
  );
}

// Billing.propTypes = {
//   className: PropTypes.string,
//   history: PropTypes.shape({
//     push: PropTypes.func
//   }),
//   subscription: PropTypes.shape({
//     selfServiceUrl: PropTypes.string
//   }),
//   parentPadding: PropTypes.bool,
//   header: PropTypes.string,
//   pageLimit: PropTypes.number
// };

export default Billing;
