/* eslint-disable no-nested-ternary */
import {
  useEffect, useState, useRef, useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import FileSaver from 'file-saver';

import {
  Button, Grid, InputAdornment, SvgIcon, TextField, Tooltip, Typography,
  IconButton, useMediaQuery
} from '@mui/material';
import LogsIcon from '@mui/icons-material/CardTravel';
import AddCircle from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import ImportIcon from '@mui/icons-material/SystemUpdateAlt';
import ExportIcon from '@mui/icons-material/OpenInBrowser';

import Page from 'src/shared/screens/Page';
import VatCodesTable from 'src/dimensions/vatcodes/components/VatCodesTable/VatCodesTable';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import authService from 'src/utils/authService';
import {
  axiosHeaders, isActionPermitted, permissions
} from 'src/utils/helpers';
import VatCodeModal from 'src/dimensions/vatcodes/components/VatCodeModal/VatCodeModal';
import DimensionExportPopper from 'src/dimensions/components/DimensionExportPopper/DimensionExportPopper';
import DimensionImportModal from 'src/dimensions/components/DimensionImportModal/DimensionImportModal';

import { useAuth } from 'src/hooks/useAuth';
import { fetchVatCodeList } from 'src/dimensions/vatcodes/actions';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';
import theme from "src/theme";

function VatCodes() {
  const { LOGIN_PANEL_URL, API, BRAND_NAME } = useContext(ConfigContext);
  const history = useHistory();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const {
    vatCodeList, total, loading
  } = useSelector((state) => state.vatcode);

  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPage] = useState(0);
  const [query, setQuery] = useState('');

  const [vatCodeModalOpen, setVatCodeModalOpen] = useState(false);
  const [selectedVatCode, setSelectedVatCode] = useState(null);

  const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
  const exportBtnRef = useRef(null);

  const [importModalOpen, setImportModalOpen] = useState(false);

  const enabled = true;
  const isTwinfield = user.accountingSystem === 'twinfield';

  const maxWidth = useMediaQuery('(min-width: 830px)');
  const maxWidth2 = useMediaQuery('(min-width: 530px)');

  const onVatCodeModalClose = () => {
    setVatCodeModalOpen(false);
    setTimeout(() => setSelectedVatCode(null), 150);
  };

  const gotoItem = (vatCode) => {
    if (!isTwinfield) {
      setSelectedVatCode(vatCode);
      setVatCodeModalOpen(true);
    }
  };

  const getAllVatCodes = async (page = 0, limit = 10, search = query, loadFullScreen) => {
    dispatch(fetchVatCodeList(user.companyID, page, limit, search, loadFullScreen));
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user.permissions)) {
      getAllVatCodes();
    }
  }, []);

  const handleAdd = () => {
    setSelectedVatCode(null);
    if (!authService.validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setVatCodeModalOpen(true);
  };

  const handleQueryChange = (e) => {
    e.persist();
    setPage(0);
    setQuery(e.target.value);
    getAllVatCodes(0, pageLimit, e.target.value, false);
  };

  const exportOptionsClose = (event) => {
    if (exportBtnRef.current && exportBtnRef.current.contains(event.target)) {
      return;
    }
    setExportOptionsOpen(false);
  };

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  const handleDimensionExport = async (format) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}vatcodes/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user.company} VAT Rates.${format}`;
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      setExportOptionsOpen(false);
    } catch (error) {
      enqueueSnackbar(t('DIMENSION_EXPORT_FAIL'), {
        variant: 'error',
        persist: false
      });
    }
  };

  const searchBar = (
    <Grid className={'search-bar'} style={styles.searchBar}>
      <Tooltip title={t('DASHBOARD_SEARCH_VAT_CODE_SEARCH_TOOLTIP')}>
        <TextField
          style={styles.queryField}
          onChange={handleQueryChange}
          placeholder={ready && t('DASHBOARD_SEARCH_VAT_CODE_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: theme.palette.icon.gray }}
                >
                  <SearchIcon style={{ color: theme.palette.icon.gray }} />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': styles.searchBarInput,
          }}
        />
      </Tooltip>
    </Grid>
  );

  const logsList = (
    <Tooltip title={t('VAT_CODES_LIST_VIEW_LOGS_TOOLTIP')}>
      <IconButton
        style={styles.logsIconContainer}
        onClick={() => history.push(`/dimensions/logs/vatcodes/company/${user.companyID}`)}
      >
        <LogsIcon
          style={{ ...styles.logsIcon, fontSize: '1.8rem' }}
        />
      </IconButton>
    </Tooltip>
  );

  const titleText = (
    <Typography style={styles.title}>
      {ready && t('DASHBOARD_VAT_CODES')}
    </Typography>
  );

  const addButton = (
    <Tooltip
      title={isActionPermitted(permissions.dimensionAdd, user.permissions)
        ? ready && t('VAT_CODES_ADD_NEW_TOOLTIP')
        : ready && t('VAT_CODES_ADD_NEW_TOOLTIP_PERMISSION')}
    >
      <span>
        <Button
          color="secondary"
          type="submit"
          variant="contained"
          style={styles.addBtn}
          onClick={handleAdd}
          disabled={!isActionPermitted(permissions.dimensionAdd, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            style={styles.actionIcon}
          >
            <AddCircle />
          </SvgIcon>
          {ready && t('VAT_CODES_ADD_NEW')}
        </Button>
      </span>
    </Tooltip>
  );

  const importBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : ''}
    >
      <span>
        <Button
          variant="outlined"
          style={styles.importExportBtn}
          onClick={() => setImportModalOpen(true)}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            style={styles.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ImportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_IMPORT_BTN')}
        </Button>
      </span>
    </Tooltip>
  );

  const exportBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : vatCodeList.length === 0
          ? ready && t('VAT_NO_DATA_TO_EXPORT')
          : ''}
    >
      <span>
        <Button
          ref={exportBtnRef}
          variant="outlined"
          style={styles.importExportBtn}
          onClick={() => { setExportOptionsOpen(true); }}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions) || vatCodeList.length === 0}
        >
          <SvgIcon
            fontSize="small"
            style={styles.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ExportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_EXPORT_BTN')}
        </Button>
        <DimensionExportPopper
          exportOptionsOpen={exportOptionsOpen}
          exportBtnRef={exportBtnRef}
          exportOptionsClose={exportOptionsClose}
          t={t}
          onOptionClick={handleDimensionExport}
        />
      </span>
    </Tooltip>
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Page
      style={styles.root}
      title={ready && `${t('VAT_CODE_LIST_PAGE_TITLE')} | ${BRAND_NAME}`}
    >
      <Grid style={{ ...styles.header, ...styles.headerMargin }}>
        {
          maxWidth ? (
            <Grid style={styles.header}>
              {titleText}
              {searchBar}
              {importBtn}
              {exportBtn}
              {
                !isTwinfield && (
                  <>
                    {enabled && logsList}
                    {addButton}
                  </>
                )
              }
            </Grid>
          ) : maxWidth2 ? (
            <Grid style={styles.headerColumn}>
              {titleText}
              <Grid style={styles.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              <Grid style={styles.importExportRow}>
                {importBtn}
                {exportBtn}
                {
                  !isTwinfield && addButton
                }
              </Grid>
            </Grid>
          ) : (
            <Grid style={styles.headerColumn}>
              {titleText}
              <Grid style={styles.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              <Grid style={styles.importExportRow}>
                {importBtn}
                {exportBtn}
              </Grid>
              {
                !isTwinfield && addButton
              }
            </Grid>
          )
        }
      </Grid>
      <VatCodesTable
        vatCodes={vatCodeList}
        total={total}
        query={query}
        isTwinfield={isTwinfield}
        limit={pageLimit}
        setLimit={setPageLimit}
        page={pageNo}
        setPage={setPage}
        gotoItem={gotoItem}
        getAllVatCodes={getAllVatCodes}
      />
      <VatCodeModal
        vatCodeModalOpen={vatCodeModalOpen}
        selectedVatCode={selectedVatCode}
        handleClose={onVatCodeModalClose}
        getAllVatCodes={getAllVatCodes}
      />
      <DimensionImportModal
        dimensionImportModalOpen={importModalOpen}
        handleClose={handleImportModalClose}
        dimension="vat"
        fetchDimenstions={getAllVatCodes}
      />
    </Page>
  );
}

export default VatCodes;
