const styles = {
  container: {
    borderRadius: '4px',
    height: '20px',
    backgroundColor: '#C2CFE033',
    marginRight: '4px',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
  },
  duplicateContainer: {
    borderRadius: '4px',
    height: '20px',
    backgroundColor: '#3E8AFF0D',
    marginRight: '4px',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
  },
  name: {
    fontSize: '11px',
    fontWeight: 500,
    color: '#4C4E64DE',
  },
  duplicateName: {
    fontSize: '11px',
    fontWeight: 500,
    color: '#3E8AFF',
  },
};

export default styles;
