import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Table, TableBody, TableHead, Box, TableRow, TableCell } from '@mui/material';
import { Delete as DeleteIcon, Book as ViewIcon, Edit as EditIcon } from '@mui/icons-material';

import CommonModal from 'src/shared/components/CommonModal/CommonModal';

type PropType = {
  data:any[],
  type:string;
  onDelete?: Function;
  deleteText?:string;
}

const DimensionsTable = (props: PropType) => {
  const { ready, t} = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [actionData, setActionData] = useState<any>(null);
  const {data,type} = props;
  const onDelete = props?.onDelete ? props.onDelete : function(){};
  const deleteText = props?.deleteText? props?.deleteText : 'DIMENSION_DELETE_CLIENT_BTN'
  const handleShowModal = (eData:any)=>{
    setOpenModal(true);
    setActionData(eData);
  }
  const onSubmit = ()=>{
    if(actionData){
      console.log('delete')
      onDelete(actionData);
      setOpenModal(false);
    }
    
  }
  return (
    <>
      <Grid sx={styles.tableWrapper} className={'y-scroll'}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}>
              {
                type === 'clients'?<>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}
                      </span>
                  </TableCell>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DIMENSIONS_LIST_VIEW_HEADER_COUNTRY')}
                      </span>
                  </TableCell>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
                      </span>
                  </TableCell>
                </> :
                type === 'glas'?
                <>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}
                      </span>
                  </TableCell>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
                      </span>
                  </TableCell>
                
                </>:
                type === 'vatrate'?
                <>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}
                      </span>
                  </TableCell>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('VAT_CODE_RATE_PERCENTAGE')}
                      </span>
                  </TableCell>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
                      </span>
                  </TableCell>
                </>:
                <>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}
                      </span>
                  </TableCell>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                      <span>
                        {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
                      </span>
                  </TableCell>      
                </>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              type === 'clients' && data && data?.length > 0 ?
                data.map((eData:any,index:number) => 
                  <TableRow key={index} sx={styles.tableBodyRow}>
                    <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                      borderInlineEnd:'2px solid #4C4E641F'
                    }}>
                        <span style={styles.text}>
                          {eData.creditorName}
                        </span>
                    </TableCell>
                    <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                      borderInlineEnd:'2px solid #4C4E641F'
                    }}>
                        <span style={styles.text}>
                          {eData.country}
                        </span>
                    </TableCell>
                    <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                      borderInlineEnd:'2px solid #4C4E641F'
                    }}>
                        <span style={styles.actionIconContainer}>
                          <EditIcon style={styles.actionIcon} />
                          <ViewIcon style={styles.actionIcon}/>
                          <DeleteIcon onClick={()=>handleShowModal(eData)} style={styles.actionIcon} />
                        </span>
                    </TableCell>
                  </TableRow>
                ):
              type === 'glas' && data && data?.length > 0 ?
                data.map((eData:any,index:number) =>
                  <TableRow key={index} sx={styles.tableBodyRow}>
                    <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                      borderInlineEnd:'2px solid #4C4E641F'
                    }}>
                        <span style={styles.text}>
                          {eData.name}
                        </span>
                    </TableCell>
                    <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                      borderInlineEnd:'2px solid #4C4E641F'
                    }}>
                        <span style={styles.actionIconContainer}>
                          <EditIcon style={styles.actionIcon} />
                          <ViewIcon style={styles.actionIcon}/>
                          <DeleteIcon onClick={()=>handleShowModal(eData)} style={styles.actionIcon} />
                        </span>
                    </TableCell>
                  </TableRow> 
                )
              :
              type === 'vatrate' && data && data?.length > 0 ?
              data.map((eData:any,index:number) =>
                <TableRow key={index} sx={styles.tableBodyRow}>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                    <span style={styles.text}>
                      {eData.name}
                    </span>
                  </TableCell>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                    <span style={styles.text}>
                      {eData.ratePercentage}%
                    </span>
                  </TableCell>
                  <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                    borderInlineEnd:'2px solid #4C4E641F'
                  }}>
                    <span style={styles.actionIconContainer}>
                      <EditIcon style={styles.actionIcon} />
                      <ViewIcon style={styles.actionIcon}/>
                      <DeleteIcon onClick={()=>handleShowModal(eData)} style={styles.actionIcon} />
                    </span>
                  </TableCell>
                </TableRow> 
              ):
                data.map((eData:any,index:number) =>
                  <TableRow key={index} sx={styles.tableBodyRow}>
                    <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                      borderInlineEnd:'2px solid #4C4E641F'
                    }}>
                        <span style={styles.text}>
                          {eData.name}
                        </span>
                    </TableCell>
                    <TableCell sx={{...styles.tableRowDisplay,width:'110px',flex:'1', 
                      borderInlineEnd:'2px solid #4C4E641F'
                    }}>
                        <span style={styles.actionIconContainer}>
                          <EditIcon style={styles.actionIcon} />
                          <ViewIcon style={styles.actionIcon}/>
                          <DeleteIcon onClick={()=>handleShowModal(eData)} style={styles.actionIcon} />
                        </span>
                    </TableCell>
                  </TableRow> 
                )
              }
          </TableBody>
        </Table>
      </Grid>
      <CommonModal 
        open={openModal}
        title={t('CONFIRM_DELETE_DIMENSIONS_TITLE')}
        cancelText={t('SUPPLIERS_CANCEL') || 'Cancel'}
        submitText={t(deleteText) || 'Delete'}
        handleClose={()=>setOpenModal(false)}
        onSubmit={onSubmit}
      >
        <Box>
        {t('CONFIRM_DELETE_DIMENSIONS')}
        </Box>

      </CommonModal>
    </>
  )
}

const styles = {
  tableWrapper:{
    display:'flex'
  },
  table:{
    marginBlockStart:'15px',
  },
  tableHeadRow:{
    display:'flex',
    width:'100%',
    backgroundColor:'#C2CFE033',
    padding:'19px 15px 19px 0',
    alignItems:'center',
    borderRadius:'0'
  },
  tableBodyRow:{
    display:'flex',
    width:'100%',
    backgroundColor:'#fff',
    padding:'15px 15px 15px 0',
    alignItems:'center',
    borderRadius:'8px 8px 0 0'
  },
  tableRowDisplay:{
    display:'flex',
    alignItems:'center',
    border:'none',
    paddingBlock:'0px',
    flexShrink:'1',
    lineHeight:'1.2',
    flex:'1',
    height:'18px'
  },
  text:{
    color:'#4C4E64',
    fontSize:'14px',
    fontWeight:'500'
  },
  actionIconContainer:{
    display:'flex',
    alignItems:'center',
    gap:'16px',
    width:'100%',
    justifyContent:'flex-end',
    paddingRight:'10px'
  },
  actionIcon:{
    color:'#6D788D',
    width:'20px',
    height:'20px',
    cursor:'pointer'
  }
}


export default DimensionsTable