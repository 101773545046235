import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import {
  Grid, IconButton
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';

import { useAuth } from 'src/hooks/useAuth';
import styles from '../style';

const EmailIntegration = () => {
  const { ready, t } = useTranslation();
  const { user } = useAuth();

  const [coppied, setCoppied] = useState(false);

  const copyToClipboard = () => {
    copy(user.ocrEmail, {
      onCopy: setCoppied(true)
    });
  };

  return (
    <Grid style={styles.detailsContainer}>
      <Grid style={styles.detailRow}>
        <span style={styles.labelText}>{ready && t('INTEGRATIONS_EMAIL_FORWARDING_EMAIL')}</span>
        <input
          fullWidth
          type="text"
          value={user.ocrEmail}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={copyToClipboard}>
          <CopyIcon />
        </IconButton>
        {coppied && <CheckIcon style={styles.checkIcon} />}
      </Grid>
    </Grid>
  );
};

export default EmailIntegration;
