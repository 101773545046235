import { useEffect, useState, RefObject } from 'react';

function useWidth(ref: RefObject<HTMLDivElement>, status: number) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current !== null) {
      setWidth(ref.current.clientWidth);
    } else {
      setWidth(0);
    }
  }, [ref.current, status]);

  return width;
}

export default useWidth;
