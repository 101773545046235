import React, { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import FileSaver from 'file-saver';
import Axios from 'axios';

import {
  Backdrop, CircularProgress, Container,
} from '@mui/material';

import Page from 'src/shared/screens/Page';
import { axiosHeadersWithArrayBuffer } from 'src/utils/helpers';
import authService from 'src/utils/authService';
import ConfigContext from 'src/contexts/ConfigContext';

function DownloadExportFile() {
  const { LOGIN_PANEL_URL, API, BRAND_NAME } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const match = useRouteMatch();

  const downloadFile = async (filename) => {
    try {
      const response = await Axios.get(
        `${API.downloadExportedFile}/${filename}`,
        axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken'))
      );
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, filename);
      window.location.href = '/documents/export/logs';
    } catch {
      enqueueSnackbar(t('EXPORT_LOGS_DOWNLOAD_FAILED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        window.location.href = '/documents/export/logs';
      }, 3000);
    }
  };

  useEffect(() => {
    if (authService.isAuthenticated()) {
      const filename = match && match.params ? match.params.filename : '';
      if (filename) {
        downloadFile(filename);
      } else {
        enqueueSnackbar(t('EXPORT_FILE_DOWNLOAD_ERROR'), {
          variant: 'error',
          persist: true
        });
        setTimeout(() => {
          window.location.href = '/documents/export/logs';
        }, 3000);
      }
    } else {
      authService.logout(LOGIN_PANEL_URL);
    }
  }, []);

  return (
    <Page
      style={styles.root}
      title={ready && `${t('EXPORT_FILE_DOWNLOAD_PAGE_TITLE')} | ${BRAND_NAME}`}
    >
      <Container maxWidth="md">
        <Backdrop style={styles.backdrop} open>
          <CircularProgress color="secondary" />
        </Backdrop>
      </Container>
    </Page>
  );
}

export default DownloadExportFile;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
  },
  backdrop: {
    zIndex: 1111,
    color: '#ffffff',
  }
};
