const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelText: {
    color: '#4C4E64DE',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '32px',
    letter: '-0.25px',
  },
  select: {
    '&.MuiInputBase-root': {
      height: '32px',
      borderRadius: '4px',
      border: '1px solid #4C4E6438',
      padding: '0px 8px !important',
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-select': {
      padding: '4px !important',
      borderWidth: '0px',
    },
    '& .MuiSelect-icon': {
      position: 'unset',
      color: '#4C4E648A'
    },
  },
  ownerMenuContainer: {
    zIndex: 99999999,
    '& .MuiPaper-root': {
      borderRadius: '4px',
      boxShadow: '0px 3px 14px 2px #4C4E641F',
      border: '1px solid #4C4E6438',
    }
  },
  userImage: {
    flexShrink: 0,
    height: '20px',
    width: '20px',
    fontWeight: '500',
    fontSize: '14px',
  },
  usernameText: {
    marginLeft: '8px',
    color: '#4C4E64',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
  },
};

export default styles;
