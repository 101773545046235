import { useTranslation } from 'react-i18next';

import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import styles from './style';

const DeleteDuplicatesModal = ({
  openDeleteDuplicatesConf,
  onCancelDeleteDuplicates,
  onDeleteDuplicates
}) => {
  const { t, ready: tReady } = useTranslation();

  return (
    <Modal
      style={styles.modal}
      open={openDeleteDuplicatesConf}
      onClose={onCancelDeleteDuplicates}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={openDeleteDuplicatesConf}>
        <div style={styles.paperModal}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {tReady && t('DELETE_DUPLICATES_MODAL_TITLE')}
            </Typography>
            <IconButton onClick={onCancelDeleteDuplicates}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid style={styles.deleteMessageText}>
            <Typography variant="caption">
              {tReady && t('DELETE_DUPLICATES_CONFIRMATION_MESSAGE')}
            </Typography>
          </Grid>
          <Grid style={styles.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                style={styles.closeBtn}
                onClick={onCancelDeleteDuplicates}
              >
                {tReady && t('DELETE_DUPLICATES_CANCEL')}
              </Button>
              <Button
                variant="contained"
                style={styles.submitBtn}
                onClick={onDeleteDuplicates}
              >
                {tReady && t('DELETE_DUPLICATES_CONFIRM')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default DeleteDuplicatesModal;
