const styles = {
  textNormal: {
    color: '#4C4E64',
    fontSize: '13px',
    marginTop: '8px'
  },
  list: {
    marginBottom: '0px',
    color: '#4C4E64',
  },
  listItem: {
    margin: '0px',
    padding: '0px'
  },
  listItemText: {
    color: '#4C4E64',
    fontSize: '13px',
  },
  bulletPoint: {
    fontSize: '6px',
    color: '#4C4E64',
    marginRight: '10px',
  },
  link: {
    color: '#3E8AFF',
    fontWeight: '500',
  }
};

export default styles;
