import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Box } from '@mui/material';

import { invoiceFilterOptions, invoiceFilterOptionsNew, timelyFilterOptions } from 'src/config';
import { arrangeChartData,chartDataTitle } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import Chart from 'src/dashboard-new/components/ChartDashboard/Chart';
import SimpleSelect from 'src/shared/components/SimpleSelect/SimpleSelect';
import styles from 'src/dashboard-new/components/ChartDashboard/style';


const ChartDashboard = ({ graph_data }: { graph_data: any }) => {
  const [dateFilter, setDateFilter] = useState<number>(0);
  const [invoiceFilter, setInvoiceFilter] = useState<number>(0);
  const [allData, setAllData]=useState<any>([]);
  const [currentData, setCurrentData] = useState<any>([]);
  const { t, ready } = useTranslation();
  const { user } = useAuth();
  const dataTitle:{[index: number]: {[index: number]: string}} = chartDataTitle();

  const isToValidateStatusAllowed = user?.customisations.includes('toValidateStatus');
  const newInvoiceFilterOptionsNew = isToValidateStatusAllowed ?
  invoiceFilterOptionsNew : invoiceFilterOptionsNew.filter((o) => o.value !== 3);

  const handleDateFilter = (str: string) => {
    setDateFilter(Number(str) || 0);
    setCurrentData(allData[Number(str)][invoiceFilter].toReversed());
  }
  const handleInvoiceFilter = (str: string) => {
    setInvoiceFilter(Number(str) || 0);
    setCurrentData(allData[dateFilter][Number(str)].toReversed());
  }
  useEffect(() => {
    if (graph_data && Object.keys(graph_data).length) {
      let data: any = arrangeChartData(graph_data,t);
      setAllData(data);
      setCurrentData(data[dateFilter][invoiceFilter].toReversed());
    }
  },[graph_data])

  return (
    <Grid sx={styles.root}>
      <Grid sx={styles.head}>
        <span style={styles.headTitle}>{ready && t(dataTitle[dateFilter][invoiceFilter])}</span>
        <Box sx={styles.optionsWrapper}>
          <Grid sx={styles.option}>
            <SimpleSelect
              label={ready && t('DASHBOARD_CHART_STATUS')}
              filter={invoiceFilter}
              filterOptions={user?.role === 'company' ? invoiceFilterOptions : newInvoiceFilterOptionsNew}
              setFilter={(e: any) => handleInvoiceFilter(e.target.value)}
            />
          </Grid>
          <Grid sx={styles.option}>
            <SimpleSelect
              label={ready && t('DASHBOARD_CHART_SHOW')}
              filter={dateFilter}
              filterOptions={timelyFilterOptions}
              setFilter={(e: any) => handleDateFilter(e.target.value)}
            />
          </Grid>
        </Box>
      </Grid>
      <Grid sx={styles.chartWrapper}>
        {
          graph_data && currentData && currentData.length > 0 ?
          <Chart
            chart_data={currentData}
            type={dateFilter}
            invoiceType={invoiceFilter}
          />
          :
          <Chart
            chart_data={[]}
            type={dateFilter}
            invoiceType={invoiceFilter}
          />
        }
      </Grid>
    </Grid>
  )
}

export default ChartDashboard;
