import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText,
  Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Autocomplete
} from '@mui/material';
import {
  Edit as EditIcon,
  AddBox as PlusIcon,
  SettingsOutlined as SettingsIcon,
  BusinessCenter as ProductIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { allFields, supplierData, userRoles } from 'src/config';
import { isShowManageFields } from 'src/utils/helpers';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import ConfigContext from 'src/contexts/ConfigContext';
import SupplierProducts from 'src/dimensions/suppliers/components/SupplierProducts/SupplierProducts';
import theme from 'src/theme';
import styles from './style';

const EditSupplierStandard = (props) => {
  const { t, ready } = useTranslation();
  
  const {
    isInvoiceEditForm,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    isSubmitting,
    handleEditSupplierClose,
    handleChange,
    handleChangeAutocomplete,
    getLabel,
    handleEditGLA,
    handleAddGLA,
    glAccounts,
    handleEditVATCode,
    handleAddVATCode,
    vatGroups,
    handleEditCostCenter,
    handleAddCostCenter,
    costCenters,
    projects,
    currencies,
    countries,
    xmlSelectedValue,
    textFieldSelectedKey,
    textFieldSelectedValueRef,
    setTextFieldSelectedKey,
    setTextFieldSelectedValue,
    supplierFields,
    preferredFieldsLoading,
    setShowManageFields,
    newlyAddedVal,
    newlyAddingProp,
    products,
    updateProducts,
    supplier,
  } = props;

  const { BRAND_NAME } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;
  
  const [supplierProductsOpen, setSupplierProductsOpen] = useState(false);

  const handleSupplierProductsOpen = () => {
    setSupplierProductsOpen(true);
  };

  const handleSupplierProductsClose = () => {
    setSupplierProductsOpen(false);
  };

  const supplierFieldIds = allFields.supplier.map((f) => `${f.key}Id`);

  const isFieldAvailable = (field) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (textFieldSelectedKey && xmlSelectedValue?.trim() && xmlSelectedValue.trim() !== values[textFieldSelectedKey]) {
      handleChange({ target: { name: textFieldSelectedKey, value: xmlSelectedValue.trim() } });
    } else if (xmlSelectedValue?.trim()) {
      let selectedKey = '';
      Object.keys(values).forEach((k) => {
        if (values[k] === xmlSelectedValue.trim()) {
          if (selectedKey) {
            selectedKey = selectedKey.concat(' ').concat(k);
          } else {
            selectedKey = k;
          }
        }
      });
      setTextFieldSelectedKey(selectedKey);
    } else if (!xmlSelectedValue && setTextFieldSelectedKey) {
      setTextFieldSelectedKey('');
    }
  }, [xmlSelectedValue]);

  useEffect(() => {
    if (isInvoiceEditForm) {
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          setTextFieldSelectedKey('');
        }
      });
      document.addEventListener('click', (e) => {
        if (![
          'canvasId2',
          'creditorNameId',
          'generalLedgerAccount',
          ...supplierFieldIds
        ].some((o) => o === e.target.id)) {
          setTextFieldSelectedKey('');
          if (textFieldSelectedValueRef.current === '') {
            setTextFieldSelectedValue(' ');
          } else {
            setTextFieldSelectedValue('');
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } });
    }
  }, [newlyAddedVal]);

  return (
    <>
      <form style={styles.root}>
        {preferredFieldsLoading ? (<LoadingScreen />) : (
          <Card style={{ boxShadow: 'none' }}>
            <CardContent style={styles.cardContent}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} xs={12} key="skipSupplierReview">
                  <Tooltip title={t('TOOLTIP_SKIP_REVIEW_FOR_SUPPLIER', { brand: BRAND_NAME })}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          style={styles.checkBox}
                          checked={values.skipSupplierReview}
                          onChange={handleChange}
                          name="skipSupplierReview"
                        />
                    )}
                      label={ready && t('SKIP_REVIEW_FOR_SUPPLIER_LABEL')}
                      labelPlacement="end"
                      sx={{
                        '&.MuiFormControlLabel-root': styles.checkboxContainer,
                        '&.MuiFormControlLabel-label': styles.checkBoxLabel
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key="creditorName">
                  <TextField
                    id="creditorNameId"
                    error={Boolean(touched.creditorName && errors.creditorName)}
                    fullWidth
                    helperText={touched.creditorName && (ready && t(errors.creditorName))}
                    label={ready && t('SUPPLIERS_CREDITOR_NAME')}
                    name="creditorName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.creditorName}
                    variant="outlined"
                    style={(textFieldSelectedKey === 'creditorName' && isInvoiceEditForm) ? styles.inputFieldSelected : styles.inputField}
                    onClick={() => { setTextFieldSelectedKey('creditorName'); setTextFieldSelectedValue(values.creditorName); }}
                  />
                </Grid>
                <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key="generalLedgerAccount">
                  <FormControl variant="outlined" style={styles.formControl}>
                    <Autocomplete
                      fullWidth
                      name="generalLedgerAccount"
                      onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'generalLedgerAccount', v, handleChange)}
                      value={getLabel(glAccounts, values.generalLedgerAccount)}
                      options={glAccounts}
                      getOptionLabel={
                      (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                    }
                      getOptionSelected={(option) => option.code === (values.generalLedgerAccount ? values.generalLedgerAccount : ' ')}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label={ready && `${t('SUPPLIERS_GENERAL_LEDGER_ACCOUNT')}`}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {values.generalLedgerAccount !== supplierData.defaultGLA[0].code && (
                                  <InputAdornment
                                    position="end"
                                    onClick={() => handleEditGLA(values.generalLedgerAccount)}
                                    style={styles.editIconContainer}
                                  >
                                    <EditIcon style={styles.editDimensionIcon} />
                                  </InputAdornment>
                                )}
                                <InputAdornment position="end" style={styles.plusIconContainer}>
                                  <PlusIcon style={styles.plusIcon} onClick={() => handleAddGLA('generalLedgerAccount')} />
                                </InputAdornment>
                              </>
                            ),
                          }}
                          style={(textFieldSelectedKey === 'generalLedgerAccount' && isInvoiceEditForm) ? styles.selectStyleSelected : styles.selectStyle}
                          onClick={() => { setTextFieldSelectedKey('generalLedgerAccount'); setTextFieldSelectedValue(values.generalLedgerAccount); }}
                        />
                      )}
                    />
                    <FormHelperText error>
                      {touched.generalLedgerAccount && errors.generalLedgerAccount && ready ? t(errors.generalLedgerAccount) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key="vatGroup">
                  <FormControl variant="outlined" style={styles.formControl} sx={{ '& .MuiFormControl-root': styles.formControlRoot }}>
                    <InputLabel id="vatGroup-label">{ready && `${t('SUPPLIERS_VAT_GROUP')}`}</InputLabel>
                    <Select
                      labelId="vatGroup-label"
                      id="vatGroup"
                      error={Boolean(touched.vatGroup && errors.vatGroup)}
                      helperText={touched.vatGroup && (ready && t(errors.vatGroup))}
                      value={values.vatGroup}
                      onChange={handleChange}
                      name="vatGroup"
                      label={ready && `${t('SUPPLIERS_VAT_GROUP')}`}
                      sx={{
                        '& .MuiSelect-select': styles.selectStyle
                      }}
                      IconComponent={() => (
                        <>
                          {values.vatGroup !== supplierData.defaultVatGroup[0].code && (
                            <EditIcon style={styles.editDimensionIcon} onClick={() => handleEditVATCode(values.vatGroup)} />
                          )}
                          <PlusIcon style={styles.plusIconSelect} onClick={() => handleAddVATCode('vatGroup')} />
                        </>
                      )}
                    >
                      {
                      vatGroups && vatGroups.length > 0
                      && vatGroups.map((s) => (
                        <MenuItem key={s.code} value={s.code}>
                          {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                        </MenuItem>
                      ))
                    }
                    </Select>
                    <FormHelperText error>
                      {touched.vatGroup && errors.vatGroup && ready ? t(errors.vatGroup) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key="autoPaymentStatus">
                  <FormControl variant="outlined" style={styles.formControl}>
                    <InputLabel id="autoPaymentStatus-label">{ready && `${t('SUPPLIERS_AUTO_PAYMENT_STATUS')}`}</InputLabel>
                    <Select
                      labelId="autoPaymentStatus-label"
                      id="autoPaymentStatus"
                      error={Boolean(touched.autoPaymentStatus && errors.autoPaymentStatus)}
                      helperText={touched.autoPaymentStatus && (ready && t(errors.autoPaymentStatus))}
                      value={values.autoPaymentStatus}
                      onChange={handleChange}
                      name="autoPaymentStatus"
                      label={ready && `${t('SUPPLIERS_AUTO_PAYMENT_STATUS')}`}
                      sx={{
                        '& .MuiSelect-select': styles.selectStyle
                      }}
                    >
                      {
                      supplierData.apStatuses && supplierData.apStatuses.length > 0
                      && supplierData.apStatuses.map((s) => (
                        <MenuItem key={s.code} value={s.code}>
                          {s.name}
                        </MenuItem>
                      ))
                    }
                    </Select>
                    <FormHelperText error>
                      {touched.autoPaymentStatus && errors.autoPaymentStatus && ready ? t(errors.autoPaymentStatus) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key="country">
                  <FormControl variant="outlined" style={styles.formControl}>
                    <Autocomplete
                      fullWidth
                      name="country"
                      onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'country', v, handleChange)}
                      value={(values.country && countries.length > 0) ? countries.find((o) => o.isoCode.toLowerCase() === values.country.toLowerCase()) : null}
                      options={countries}
                      getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
                      getOptionSelected={(option) => option.isoCode.toLowerCase() === (values.country ? values.country.toLowerCase() : null)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label={ready && `${t('SUPPLIERS_COUNTRY')}`}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          style={styles.selectStyle}
                        />
                      )}
                    />
                    <FormHelperText error>
                      {touched.country && errors.country && ready ? t(errors.country) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {
                  supplierFields.map((f) => {
                    if (!isFieldAvailable(f)) {
                      return null;
                    }
                    switch (f.key) {
                      case 'status':
                        return (
                          <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key={f.key}>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                                style={styles.capitalizeText}
                              >
                                {
                                  supplierData.statuses && supplierData.statuses.length > 0
                                    && supplierData.statuses.map((s) => (
                                      <MenuItem key={s} value={s} style={styles.capitalizeText}>
                                        {s}
                                      </MenuItem>
                                    ))
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'generalLedgerAccLine':
                        return (
                          <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key={f.key}>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <Autocomplete
                                fullWidth
                                name={f.key}
                                onChange={(e, v) => handleChangeAutocomplete(e.target.value, f.key, v, handleChange)}
                                value={getLabel(glAccounts, values[f.key])}
                                options={glAccounts}
                                getOptionLabel={
                                  (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                                }
                                getOptionSelected={(option) => option.code === (values[f.key] ? values[f.key] : ' ')}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {values.generalLedgerAccLine !== supplierData.defaultGLA[0].code && (
                                            <InputAdornment
                                              position="end"
                                              onClick={() => handleEditGLA(values.generalLedgerAccLine)}
                                              style={styles.editIconContainer}
                                            >
                                              <EditIcon style={styles.editDimensionIcon} />
                                            </InputAdornment>
                                          )}
                                          <InputAdornment position="end" style={styles.plusIconContainer}>
                                            <PlusIcon style={styles.plusIcon} onClick={() => handleAddGLA(f.key)} />
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    style={
                                      (textFieldSelectedKey === 'generalLedgerAccLine' && isInvoiceEditForm) ? styles.selectStyleSelected : styles.selectStyle
                                    }
                                    onClick={() => { setTextFieldSelectedKey('generalLedgerAccLine'); setTextFieldSelectedValue(values.generalLedgerAccLine); }}
                                    required={f.isRequired}
                                  />
                                )}
                              />
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'vatGroupLine':
                        return (
                          <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key={f.key}>
                            <FormControl variant="outlined" style={styles.formControl} sx={{ '& .MuiFormControl-root': styles.formControlRoot }}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                                IconComponent={() => (
                                  <>
                                    {values.vatGroupLine !== supplierData.defaultVatGroup[0].code && (
                                      <EditIcon style={styles.editDimensionIcon} onClick={() => handleEditVATCode(values.vatGroupLine)} />
                                    )}
                                    <PlusIcon style={styles.plusIconSelect} onClick={() => handleAddVATCode('vatGroupLine')} />
                                  </>
                                )}
                              >
                                {
                                  vatGroups && vatGroups.length > 0
                                    && vatGroups.map((s) => (
                                      <MenuItem key={s.code} value={s.code}>
                                        {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                      </MenuItem>
                                    ))
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'costCenter':
                        return (
                          <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key={f.key}>
                            <FormControl variant="outlined" style={styles.formControl} sx={{ '& .MuiFormControl-root': styles.formControlRoot }}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                                IconComponent={() => (
                                  <>
                                    {values.costCenter !== supplierData.defaultCostCenters[0].code && (
                                      <EditIcon style={styles.editDimensionIcon} onClick={() => handleEditCostCenter(values.costCenter)} />
                                    )}
                                    <PlusIcon style={styles.plusIconSelect} onClick={() => handleAddCostCenter('costCenter')} />
                                  </>
                                )}
                              >
                                {
                                  costCenters && costCenters.length > 0
                                    && costCenters.map((s) => {
                                      if (s) {
                                        return (
                                          <MenuItem key={s.code} value={s.code}>
                                            {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                          </MenuItem>
                                        );
                                      }

                                      return null;
                                    })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'project':
                        return (
                          <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key={f.key}>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                              >
                                {
                                  projects && projects.length > 0
                                    && projects.map((s) => {
                                      if (s) {
                                        return (
                                          <MenuItem key={s.code} value={s.code}>
                                            {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                          </MenuItem>
                                        );
                                      }

                                      return null;
                                    })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'defaultCurrency':
                        return (
                          <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key={f.key}>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                              >
                                {
                                  currencies && currencies.length > 0
                                    && currencies.map((s) => (
                                      <MenuItem key={s.code} value={s.code}>
                                        {`${s.code} | ${s.currency}`}
                                      </MenuItem>
                                    ))
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'bankCountry':
                        let isRequired = f.isRequired; // eslint-disable-line
                        if (f.key === 'bankCountry' && values.autoPaymentStatus === supplierData.apStatuses[2].code) {
                          isRequired = false;
                        }

                        return (
                          <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key={f.key}>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <Autocomplete
                                fullWidth
                                name={f.key}
                                onChange={(e, v) => handleChangeAutocomplete(e.target.value, f.key, v, handleChange)}
                                value={(values[f.key] && countries.length > 0) ? countries.find((o) => o.isoCode === values[f.key]) : null}
                                options={countries}
                                getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
                                getOptionSelected={(option) => option.isoCode === (values[f.key] ? values[f.key] : null)}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    label={ready && `${t(f.label)}${isRequired ? '*' : ''}`}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                    style={styles.selectStyle}
                                  />
                                )}
                              />
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      default:
                        return (
                          <Tooltip key={f.key} title={f.key === 'creditorCode' ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}>
                            <Grid item lg={isInvoiceEditForm ? 12 : 6} md={12} xs={12} key={f.key}>
                              <TextField
                                id={`${f.key}Id`}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                fullWidth
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                label={ready && t(f.label)}
                                name={f.key}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required={
                                  f.isRequired
                                  || ((f.key === 'ascription' || f.key === 'bicSwift')
                                    && (
                                      values.autoPaymentStatus === supplierData.apStatuses[0].code
                                    || values.autoPaymentStatus === supplierData.apStatuses[1].code
                                    )
                                  )
                                  || (f.key === 'iban' && values.autoPaymentStatus === supplierData.apStatuses[0].code)
                                  || ((f.key === 'bankName' || f.key === 'bankCity' || f.key === 'bankStreet'
                                    || f.key === 'bankHouseNumber' || f.key === 'city' || f.key === 'address1')
                                    && values.autoPaymentStatus === supplierData.apStatuses[1].code
                                  )
                                }
                                value={values[f.key]}
                                variant="outlined"
                                disabled={f.key === 'creditorCode'}
                                style={(textFieldSelectedKey === f.key && isInvoiceEditForm) ? styles.inputFieldSelected : styles.inputField}
                                onClick={() => { setTextFieldSelectedKey(f.key); setTextFieldSelectedValue(values[f.key]); }}
                              />
                            </Grid>
                          </Tooltip>
                        );
                    }
                  })
                }
                {errors.submit && (
                  <Grid mt={2} item xs={12}>
                    <Box mt={3}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {isShowManageFields(user, 'applyParentFieldsSupplier') && (
                    <Button
                      variant="outlined"
                      style={styles.manageFieldsBtn}
                      onClick={() => setShowManageFields(true)}
                    >
                      <SettingsIcon style={styles.btnIcon} />
                      {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    style={styles.manageProductsBtn}
                    onClick={handleSupplierProductsOpen}
                  >
                    <ProductIcon style={styles.productBtnIcon} />
                    {t('SUPPLIER_FORM_MANAGE_PRODUCTS')}
                  </Button>
                </Grid>
                {user.userRole.toLowerCase() !== userRoles.annotator && (
                  <Grid item xs={12} style={{ marginBottom: isInvoiceEditForm ? 10 : 0 }}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        style={{ backgroundColor: theme.palette.button.action }}
                        variant="contained"
                        onClick={() => handleSubmit()}
                        disabled={isSubmitting}
                      >
                        {ready && t('SUPPLIERS_SAVE_CHANGES')}
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => handleEditSupplierClose()}
                        style={styles.yearField}
                      >
                        {ready && t('SUPPLIERS_CANCEL')}
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
      </form>
      <SupplierProducts
        supplierProductsOpen={supplierProductsOpen}
        handleClose={handleSupplierProductsClose}
        products={products}
        updateProducts={updateProducts}
        supplier={supplier}
      />
    </>
  );
};

// EditSupplierStandard.propTypes = {
//   isInvoiceEditForm: PropTypes.bool,
//   className: PropTypes.string,
//   values: PropTypes.object,
//   errors: PropTypes.object,
//   touched: PropTypes.object,
//   handleBlur: PropTypes.func,
//   handleSubmit: PropTypes.func,
//   isSubmitting: PropTypes.bool,
//   handleEditSupplierClose: PropTypes.func,
//   handleChange: PropTypes.func,
//   handleChangeAutocomplete: PropTypes.func,
//   getLabel: PropTypes.func,
//   handleEditGLA: PropTypes.func,
//   handleAddGLA: PropTypes.func,
//   glAccounts: PropTypes.array,
//   handleEditVATCode: PropTypes.func,
//   handleAddVATCode: PropTypes.func,
//   vatGroups: PropTypes.array,
//   handleEditCostCenter: PropTypes.func,
//   handleAddCostCenter: PropTypes.func,
//   costCenters: PropTypes.array,
//   projects: PropTypes.array,
//   currencies: PropTypes.array,
//   countries: PropTypes.array,
//   supplierFields: PropTypes.array,
//   preferredFieldsLoading: PropTypes.bool,
//   setShowManageFields: PropTypes.func,
//   xmlSelectedValue: PropTypes.string,
//   textFieldSelectedKey: PropTypes.string,
//   textFieldSelectedValueRef: PropTypes.object,
//   setTextFieldSelectedKey: PropTypes.func,
//   setTextFieldSelectedValue: PropTypes.func,
//   newlyAddedVal: PropTypes.string,
//   newlyAddingProp: PropTypes.string,
//   products: PropTypes.array,
//   updateProducts: PropTypes.func,
//   supplier: PropTypes.object,
//   rest: PropTypes.object
// };

export default EditSupplierStandard;
