import {
  useState, useEffect, useRef, useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Button, Grid, TextField, Typography
} from '@mui/material';
import { Publish as UploadIcon } from '@mui/icons-material';

import { appendContactSupport, axiosHeaders, axiosHeadersFormData, getLocalisedErrorString } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

function BrandCompanyInfo(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { ready, t } = useTranslation();
  
  const { API } = useContext(ConfigContext);

  const { isEditable, brandDetails, fetchBrandDetails } = props;

  const [values, setValues] = useState({
    name: '',
    domain: '',
    supportEmail: '',
    terms: '',
    privacyPolicy: '',
  });

  const [imgValues] = useState({
    favicon: '',
    logo: '',
  });

  const [uploadingImage, setUploadingImage] = useState(false);

  const validations = {
    name: /.*\S.*$/,
    domain: /^[-a-zA-Z0-9@:/%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
    supportEmail: /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/,
    terms: /^[-a-zA-Z0-9@:/%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
    privacyPolicy: /^[-a-zA-Z0-9@:/%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
  };

  const [errors, setErrors] = useState({
    name: false,
    domain: false,
    supportEmail: false,
    favicon: false,
    logo: false,
    terms: false,
    privacyPolicy: false,
  });

  const [brandFavicon, setBrandFavicon] = useState(null);
  const [brandLogo, setBrandLogo] = useState(null);

  const helperTexts = {
    name: ready && t('BRAND_COMPANY_COM_NAME_HELPER_TEXT'),
    domain: ready && t('BRAND_COMPANY_DOMAIN_HELPER_TEXT'),
    supportEmail: ready && t('BRAND_COMPANY_SUPPORT_EMAIL_HELPER_TEXT'),
    favicon: ready && t('BRAND_COMPANY_SMALL_ICON_ERROR'),
    logo: ready && t('BRAND_COMPANY_LOGO_ERROR'),
    terms: ready && t('BRAND_COMPANY_TERMS_HELPER_TEXT'),
    privacyPolicy: ready && t('BRAND_COMPANY_PRIVACY_POLICY_HELPER_TEXT'),
  };

  const imageSizes = {
    favicon: 2,
    logo: 5,
  };

  const resolutionRatios = {
    favicon: {
      min: 0.8,
      max: 1.2,
    },
    logo: {
      min: 0.12,
      max: 0.45,
    },
  };

  const imageErrors = {
    fileSizes: {
      favicon: ready && 'BRAND_COMPANY_SMALL_ICON_FILE_SIZE',
      logo: ready && 'BRAND_COMPANY_LOGO_FILE_SIZE',
    },
    resolutionRatios: {
      favicon: ready && 'BRAND_COMPANY_SMALL_ICON_FILE_RES',
      logo: ready && 'BRAND_COMPANY_LOGO_FILE_RES',
    },
    success: {
      favicon: ready && 'BRAND_COMPANY_SMALL_ICON_UPLOAD_SUCCESS',
      logo: ready && 'BRAND_COMPANY_LOGO_UPLOAD_SUCCESS',
    },
    error: {
      favicon: ready && 'BRAND_COMPANY_SMALL_ICON_UPLOAD_ERROR',
      logo: ready && 'BRAND_COMPANY_LOGO_UPLOAD_ERROR',
    },
  };

  const lastValues = useRef({
    name: brandDetails.name || '',
    domain: brandDetails.domain || '',
    supportEmail: brandDetails.supportEmail || '',
    terms: brandDetails.terms || '',
    privacyPolicy: brandDetails.privacyPolicy || '',
  });

  useEffect(() => {
    setValues({
      name: brandDetails.name || '',
      domain: brandDetails.domain || '',
      supportEmail: brandDetails.supportEmail || '',
      terms: brandDetails.terms || '',
      privacyPolicy: brandDetails.privacyPolicy || '',
    });
    lastValues.current = {
      name: brandDetails.name || '',
      domain: brandDetails.domain || '',
      supportEmail: brandDetails.supportEmail || '',
      terms: brandDetails.terms || '',
      privacyPolicy: brandDetails.privacyPolicy || '',
    };
  }, [brandDetails]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    if (!validations[event.target.name].test(event.target.value)) {
      setErrors({
        ...errors,
        [event.target.name]: true
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false
      });
    }
  };

  const handleBlur = async (event) => {
    const prop = event.target.name;
    const val = event.target.value;

    if (!errors[event.target.name] && val !== '' && val !== lastValues.current[prop]) {
      lastValues.current = {
        ...lastValues.current,
        [prop]: val
      };
      const data = new FormData();
      data.append(prop, val);
      if (prop !== 'name') {
        data.append('name', values.name);
      }

      try {
        const response = await Axios.post(
          API.brand,
          data,
          axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
        );

        if (response.data.success) {
          enqueueSnackbar(t('BRAND_COMPANY_INFO_UPDATE_SUCCESS'), {
            variant: 'success',
            ClickAwayListenerProps: {
              mouseEvent: 'onMouseDown'
            }
          });
        }
      } catch (error) {
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'BRAND_COMPANY_INFO_UPDATE_FAILURE', t),
          t
        ), {
          variant: 'error',
          ClickAwayListenerProps: {
            mouseEvent: 'onMouseDown'
          }
        });
      }
    }
  };

  const uploadImage = (event) => {
    setUploadingImage(true);
    const file = event.target.files[0];
    const prop = event.target.name;

    if (values.name === '') {
      enqueueSnackbar(t('BRAND_COMPANY_COM_NAME_EMPTY'), {
        variant: 'error'
      });
      setUploadingImage(false);
      return;
    }

    if (values.domain === '') {
      enqueueSnackbar(t('BRAND_COMPANY_COM_DOMAIN_EMPTY'), {
        variant: 'error'
      });
      setUploadingImage(false);
      return;
    }

    if (file.size > imageSizes[prop] * 1024 * 1024) {
      enqueueSnackbar(t(imageErrors.fileSizes[prop]), {
        variant: 'error'
      });
      setUploadingImage(false);
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    let resOkay = true;

    img.onload = async () => {
      const width = parseFloat(img.naturalWidth);
      const height = parseFloat(img.naturalHeight);

      const resolutionRatio = parseFloat((height / width).toFixed(2));

      if (!(resolutionRatio >= resolutionRatios[prop]?.min && resolutionRatio <= resolutionRatios[prop]?.max)) {
        enqueueSnackbar(t(imageErrors.resolutionRatios[prop]), {
          variant: 'error'
        });
        resOkay = false;
      }

      if (!resOkay) {
        setUploadingImage(false);
        return;
      }

      const data = new FormData();
      data.append('name', values.name);
      data.append(prop, file);

      try {
        const response = await Axios.post(
          API.brand,
          data,
          axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
        );

        if (response.data.success) {
          enqueueSnackbar(t(imageErrors.success[prop]), {
            variant: 'success'
          });
          const url = URL.createObjectURL(file);
          setUploadingImage(false);
          fetchBrandDetails();
          if (prop === 'favicon') {
            setBrandFavicon(url);
          } else {
            setBrandLogo(url);
          }
        }
      } catch (error) {
        setUploadingImage(false);
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || imageErrors.error[prop], t),
          t
        ), {
          variant: 'error'
        });
      }
    };
  };

  const handleSave = async () => {
    let erroredKey;
    if (Object.keys(errors).some((key) => {
      if (errors[key] === true) {
        erroredKey = key;
        return true;
      }
      return false;
    })) {
      enqueueSnackbar(helperTexts[erroredKey], {
        variant: 'error'
      });
      return;
    }
    if (Object.keys(values).some((key) => values[key] === '') || brandDetails.favicon === '' || brandDetails.logo === '') {
      enqueueSnackbar(t('BRAND_COMPANY_INFO_EMPTY'), {
        variant: 'error'
      });
      return;
    }
    enqueueSnackbar(t('BRAND_COMPANY_INFO_UPDATE_SUCCESS'), {
      variant: 'success'
    });

    await fetchBrandDetails();

    if (brandDetails.status !== 'active') {
      try {
        const response = await Axios.post(
          API.brandStatus,
          {
            status: 'active'
          },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );

        if (response.data.success) {
          enqueueSnackbar(t('BRANDING_INITIATION_SUCCESS'), {
            variant: 'success'
          });
        }
      } catch (error) {
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'BRANDING_INITIATION_FAILURE', t),
          t
        ), {
          variant: 'error'
        });
      }
    }
  };

  return (
    <Grid style={styles.container}>
      <Typography style={styles.titleText}>{ready && t('BRAND_COMPANY_INFO_TITLE')}</Typography>
      <Grid container style={styles.inputsContainer}>
        <Grid
          item
          xs={12}
          sm={6}
          style={styles.formInput}
        >
          <Typography variant="h5" style={styles.inputLabel}>
            {ready && t('BRAND_COMPANY_COM_NAME')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            error={errors.name}
            helperText={errors.name ? ready && helperTexts.name : ''}
            disabled={!isEditable}
            name="name"
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={styles.formInput}
        >
          <Typography variant="h5" style={styles.inputLabel}>
            {ready && t('BRAND_COMPANY_DOMAIN')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            error={errors.domain}
            helperText={errors.domain ? ready && helperTexts.domain : ''}
            disabled={!isEditable}
            name="domain"
            onChange={handleChange}
            value={values.domain}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={styles.formInput}
        >
          <Typography variant="h5" style={styles.inputLabel}>
            {ready && t('BRAND_COMPANY_SUPPORT_EMAIL')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            error={errors.supportEmail}
            helperText={errors.supportEmail ? ready && helperTexts.supportEmail : ''}
            disabled={!isEditable}
            name="supportEmail"
            onChange={handleChange}
            value={values.supportEmail}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={styles.formInput}
        >
          <Typography variant="h5" style={styles.inputLabel}>
            {ready && t('BRAND_COMPANY_TERMS')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            error={errors.terms}
            helperText={errors.terms ? ready && helperTexts.terms : ''}
            disabled={!isEditable}
            name="terms"
            onChange={handleChange}
            value={values.terms}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={styles.formInput}
        >
          <Typography variant="h5" style={styles.inputLabel}>
            {ready && t('BRAND_COMPANY_PRIVACY_POLICY')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            error={errors.privacyPolicy}
            helperText={errors.privacyPolicy ? ready && helperTexts.privacyPolicy : ''}
            disabled={!isEditable}
            name="privacyPolicy"
            onChange={handleChange}
            value={values.privacyPolicy}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <Grid style={styles.uploadsContainer}>
        <Grid style={styles.uploadItem}>
          {values.domain && (brandDetails.favicon || brandFavicon) && (
            <Grid style={styles.brandLogoContainer}>
              <img
                src={brandFavicon || `${API.brandFavicon}${values.domain}`}
                alt=""
                style={styles.brandFavicon}
              />
            </Grid>
          )}
          <Typography style={styles.logoUploadTitle}>
            {ready && t('BRAND_COMPANY_SMALL_ICON')}
          </Typography>
          <Button
            variant="outlined"
            style={styles.uploadBtn}
            component="label"
            disabled={!isEditable || uploadingImage}
          >
            <input
              id="small-icon-input"
              name="favicon"
              accept="image/png,image/jpeg,image/jpg"
              type="file"
              value={imgValues.favicon}
              onChange={uploadImage}
              style={{ display: 'none' }}
            />
            {ready && t('BRAND_COMPANY_UPLOAD')}
            <UploadIcon style={(isEditable && !uploadingImage) ? styles.btnIcon : styles.btnIconDisabled} />
          </Button>
          <Typography style={styles.logoUploadDescr}>
            {ready && t('BRAND_COMPANY_SMALL_ICON_DESCR')}
          </Typography>
        </Grid>
        <Grid style={styles.uploadItem}>
          {values.domain && (brandDetails.logo || brandLogo) && (
            <Grid style={styles.brandLogoContainer}>
              <img
                src={brandLogo || `${API.brandLogo}${values.domain}`}
                alt=""
                style={styles.brandLogo}
              />
            </Grid>
          )}
          <Typography style={styles.logoUploadTitle}>
            {ready && t('BRAND_COMPANY_LOGO')}
          </Typography>
          <Button
            variant="outlined"
            style={styles.uploadBtn}
            component="label"
            disabled={!isEditable || uploadingImage}
          >
            <input
              id="logo-input"
              name="logo"
              accept="image/png,image/jpeg,image/jpg"
              type="file"
              value={imgValues.logo}
              onChange={uploadImage}
              style={{ display: 'none' }}
            />
            {ready && t('BRAND_COMPANY_UPLOAD')}
            <UploadIcon style={(isEditable && !uploadingImage) ? styles.btnIcon : styles.btnIconDisabled} />
          </Button>
          <Typography style={styles.logoUploadDescr}>
            {ready && t('BRAND_COMPANY_SMALL_ICON_DESCR')}
          </Typography>
        </Grid>
      </Grid>
      <Grid style={styles.footer}>
        <Button
          variant="outlined"
          style={styles.uploadBtn}
          disabled={!isEditable}
          onClick={handleSave}
        >
          {ready && t('BRAND_COMPANY_SAVE')}
        </Button>
      </Grid>
    </Grid>
  );
}

// BrandCompanyInfo.propTypes = {
//   isEditable: PropTypes.bool,
//   brandDetails: PropTypes.object,
//   fetchBrandDetails: PropTypes.func,
// };

export default BrandCompanyInfo;
