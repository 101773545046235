import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

const defaultPathname = 'dimensions'

const Sidebar = ({showImport = false}:{showImport?:boolean}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathnames = location?.pathname?.split('/');
  const pathname = pathnames[1];
  const checkPathname = pathnames?.length === 2 || pathnames?.length === 3 && !pathnames[2];
  return (
    <Box sx={styles.root} className="y-scroll">
      <NavLink style={(isActive)=> ({
          ...styles.link,
          color:isActive || checkPathname && pathname === defaultPathname?'#3E8AFF':'#4C4E64DE',
          backgroundColor:isActive || checkPathname && pathname === defaultPathname?'#3E8AFF26':'#fff',
        })}
        to="/dimensions/suppliers">{t('DASHBOARD_NAV_CLIENTS')}</NavLink>
      <NavLink style={(isActive)=> ({
          ...styles.link,
          color:isActive?'#3E8AFF':'#4C4E64DE',
          backgroundColor:isActive?'#3E8AFF26':'#fff',
        })}
        to="/dimensions/glaccounts">{t('DASHBOARD_NAV_GLACCOUNTS')}</NavLink>
      <NavLink style={(isActive)=> ({
          ...styles.link,
          color:isActive?'#3E8AFF':'#4C4E64DE',
          backgroundColor:isActive?'#3E8AFF26':'#fff',
        })}
        to="/dimensions/vatcodes">{t('DASHBOARD_NAV_VAT_CODES')}</NavLink>
      <NavLink style={(isActive)=> ({
          ...styles.link,
          color:isActive?'#3E8AFF':'#4C4E64DE',
          backgroundColor:isActive?'#3E8AFF26':'#fff',
        })}
        to="/dimensions/costcenters">{t('DASHBOARD_NAV_COST_CENTERS')}</NavLink>
      {showImport ?
        <NavLink style={(isActive)=> ({
          ...styles.link,
          color:isActive?'#3E8AFF':'#4C4E64DE',
          backgroundColor:isActive?'#3E8AFF26':'#fff',
        })}
        to="/dimensions-new/import">{t('DIMENSION_IMPORT_BTN')}</NavLink>:
        <></>
        }
    </Box>
  )
}
const styles={
  root:{
    width:'290px',
    flexShrink:'0',
    borderInlineEnd:'1px solid #4C4E641F',
    padding:'20px 12px 8px 12px',
    display:'flex',
    flexDirection:'column',
    gap:'6px',
    minHeight:'100%'
  },
  link:{
    textDecoration:'none',
    fontWeight:'500',
    fontSize:'14px',
    lineHeight:'24px',
    padding:'8px 12px 8px 16px',
    borderRadius:'8px'
  }

}

export default Sidebar;
