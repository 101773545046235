const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      // maxWidth: '50vw',
      borderRadius: '10px'
    }
  },
  container: {
    width: '600px',
    padding: '20px 24px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    color: '#4C4E64'
  },
  closeIcon: {
    color: '#6D788D',
    height: '16px',
    width: '16px',
  },
  detailContainer: {
    padding: '15px 0px 10px 0px'
  },
  detailLabel: {
    fontSize: 12,
    color: '#4C4E6499',
    fontWeight: 500,
    textTransform: 'uppercase' as const
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0px 0px 0px'
  },
  icon: {
    color: '#4C4E64DE',
    size: 20,
    marginRight: '10px'
  },
  detailText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#4C4E64',
  }
}

export default styles;
