import { useContext, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, TableHead, IconButton
} from '@mui/material';
import {
  GetApp as DownloadIcon
} from '@mui/icons-material';

import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import { axiosHeadersWithArrayBuffer } from 'src/utils/helpers';
import Axios from 'axios';
import FileSaver from 'file-saver';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from '../style';

const InvoiceExportLogsTable = ({
  paginatedLogs
}) => {
  const { API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [downloading, setDownloading] = useState(null);

  const today = new Date().setHours(0, 0, 0, 0) / 1000;

  const statusMap = {
    success: 'EXPORT_LOGS_STATUS_COMPLETED',
    failed: 'EXPORT_LOGS_STATUS_FAILED',
    'in-progress': 'EXPORT_LOGS_STATUS_IN_PROGRESS',
  };

  const handleDownload = async (log) => {
    setDownloading(log.id);
    try {
      const response = await Axios.get(
        `${API.downloadExportedFile}/${log.filename}`,
        axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken'))
      );
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, log.filename);
      setDownloading(null);
    } catch {
      setDownloading(null);
      enqueueSnackbar(t('EXPORT_LOGS_DOWNLOAD_FAILED'), {
        variant: 'error',
        persist: true
      });
    }
  };

  return (
    <Box minWidth="100%">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={styles.columnName}>
              {ready && t('EXPORT_LOGS_DATE')}
            </TableCell>
            <TableCell style={styles.columnName}>
              {ready && t('EXPORT_LOGS_STATUS')}
            </TableCell>
            <Hidden mdDown>
              <TableCell style={styles.columnName}>
                {ready && t('EXPORT_LOGS_COMMENTS')}
              </TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="center" style={styles.columnName}>
                {ready && t('EXPORT_LOGS_EXTRACTED_FILE_TYPE')}
              </TableCell>
            </Hidden>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            paginatedLogs && paginatedLogs.length > 0
              ? paginatedLogs.map((log) => {
                const isDocumentLogToday = log.timestamp / 1000000000 >= today;
                const fileNameSplit = log.filename?.split('.');
                const todayTextStyle = isDocumentLogToday ? styles.todayText : {};
                const todayTimeStyle = isDocumentLogToday ? styles.todayText : styles.timeBox;

                return (
                  <TableRow
                    hover
                    key={log.id}
                    style={isDocumentLogToday ? { backgroundColor: '#F9FAFC', height: 64 } : { height: 64 }}
                  >
                    <TableCell
                      align="left"
                      style={{ ...todayTimeStyle, fontSize: 15, lineHeight: '22.5px' }}
                    >
                      {moment.unix(log.createdAt).format(isDocumentLogToday ? 'hh:mm' : 'YYYY-MM-DD HH:mm')}
                    </TableCell>
                    <TableCell
                      style={{ ...todayTextStyle, fontSize: 13, lineHeight: '24px' }}
                    >
                      {t(statusMap[log.status])}
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell style={todayTextStyle}>
                        {log.error && `${t('EXPORT_LOGS_ERROR_WHILE_EXPORTING')}: ${t(log.error)}`}
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell
                        align="center"
                        style={todayTextStyle}
                      >
                        {fileNameSplit[1]}
                      </TableCell>
                    </Hidden>
                    <TableCell>
                      <IconButton
                        variant="text"
                        style={styles.downloadIcon}
                        disabled={log.status !== 'success' || downloading === log.id}
                        onClick={() => handleDownload(log)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
              : <EmptyText str="INVOICE_LOGS_LIST_VIEW_NO_LOGS" />
          }
        </TableBody>
      </Table>
    </Box>
  );
};

export default InvoiceExportLogsTable;
