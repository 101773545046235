import { Box, CircularProgress } from '@mui/material';

interface PropTypes {
  height?: string;
  size?: number;
  thickness?: number;
}

const Spinner = (props: PropTypes) => {
  const { height, size, thickness } = props;

  return (
    <Box sx={{ ...styles.container, height: height ? height : '100%' }}>
      <CircularProgress sx={styles.spinner} size={size ? size : 24} thickness={thickness ? thickness : 5} />
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 2,
    backgroundColor: '#FFFFFF',
  },
  spinner: {
    color: '#3E8AFF',
  }
};

export default Spinner;
