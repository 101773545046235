import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

import {
  Backdrop,
  Button, Checkbox, Fade, FormControlLabel, Grid, IconButton, MenuItem, Modal, Select, SvgIcon, TextField, Typography
} from '@mui/material';
import { Close as CloseIcon, AddCircle as PlusCircleIcon, Reorder as ReorderIcon } from '@mui/icons-material';

import {
  dataFormatLabels, esLineFieldMapping, esLineFields, esLineLabelsForExport, exportMappingEntities,
  vatLineFieldMapping, vatLineFields, vatLineLabelsForExport, supplierFieldMapping
} from 'src/config';
import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';
import { useAuth } from 'src/hooks/useAuth';

const linkToDocument = {
  key: 'linkToDocument',
  appDbField: 'link_to_document',
  assistantKey: 'link_to_document',
  dataType: 'text',
  label: 'LINK_TO_DOCUMENT',
  exportMapKey: 'LINK_TO_DOCUMENT',
  isAvailable: true,
  position: -1,
};

const linkToDocumentPdf = {
  key: 'linkToDocumentPdf',
  appDbField: 'link_to_document_pdf',
  assistantKey: 'link_to_document_pdf',
  dataType: 'text',
  exportMapKey: 'LINK_TO_DOCUMENT_PDF',
  label: 'LINK_TO_DOCUMENT_PDF',
  isAvailable: true,
  position: -1,
};

const documentOwner = {
  key: 'documentOwner',
  appDbField: 'document_owner',
  assistantKey: 'document_owner',
  dataType: 'text',
  exportMapKey: 'DOCUMENT_OWNER',
  label: 'DOCUMENT_OWNER',
  isAvailable: true,
  position: -1,
};

const companyCode = {
  key: 'companyCode',
  appDbField: 'company_code',
  assistantKey: 'company_code',
  dataType: 'text',
  exportMapKey: 'INTEGRATION_COMPANY_CODE',
  label: 'INTEGRATION_COMPANY_CODE',
  isAvailable: true,
  position: -1,
};

const metaDataFields = [
  'LINK_TO_DOCUMENT',
  'LINK_TO_DOCUMENT_PDF',
  'DOCUMENT_OWNER'
];

const ManageExportFieldMappings = props => {
  const {
    format,
    exportFieldMappingsOpen,
    onModalClose,
    loading,
    fields,
    lineFields,
    supplierFields,
    excelFieldMapping,
    datFieldMapping,
    csvFieldMapping
  } = props;

  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { API } = useContext(ConfigContext);
  const auth = useAuth();
  const {user} = auth;

  const [dataFormat, setDataFormat] = useState(format?.key || 'excel');
  const [isEdited, setIsEdited] = useState(!!format);

  const [mappings, setMappings] = useState([]);
  const [headerMappings, setHeaderMappings] = useState(
    mappings.filter(m => m.entity === 'header')
  );
  const [lineMappings, setLineMappings] = useState(
    mappings.filter(m => m.entity === 'line')
  );
  const [vatMappings, setVatMappings] = useState(
    mappings.filter(m => m.entity === 'vat')
  );
  const [esMappings, setEsMappings] = useState(
    mappings.filter(m => m.entity === 'es')
  );
  const [supplierMappings, setSupplierMappings] = useState(
    mappings.filter(m => m.entity === 'supplier')
  );

  const [isApplyToAll, setIsApplyToAll] = useState(false);

  const isPassportOrID = user.documentType === 'id';
  const isParentDocTypeSame = user.documentType === user.parentDocType;
  
  const [headerFieldMapping, setHeaderFieldMapping] = useState([
    ...fields,
    linkToDocument,
    linkToDocumentPdf,
    documentOwner,
    companyCode
  ].reduce((acc, item) => {
    acc[item.key] = item.exportMapKey;
    return acc;
  }, {}));
  const [lineFieldMapping, setLineFieldMapping] = useState(lineFields.reduce((acc, item) => {
    acc[item.key] = item.exportMapKey;
    return acc;
  }, {}));

  useEffect(() => {
    if (dataFormat) {
      setIsApplyToAll(user.customisations?.some((c) => c === `applyParentMappings${dataFormat[0].toUpperCase() + dataFormat.slice(1)}`));
    }
  }, [dataFormat, user]);

  useEffect(() => setDataFormat(format ? format.key : 'excel'), [format]);

  useEffect(() => {
    setHeaderMappings(mappings.filter((m) => m.entity === 'header'));
    setLineMappings(mappings.filter((m) => m.entity === 'line'));
    setVatMappings(mappings.filter((m) => m.entity === 'vat'));
    setEsMappings(mappings.filter((m) => m.entity === 'es'));
    setSupplierMappings(mappings.filter((m) => m.entity === 'supplier'));
  }, [mappings]);

  useEffect(() => {
    let isFieldsEmpty = false;
    if (format?.key === 'excel' || dataFormat === 'excel') {
      setMappings(excelFieldMapping);
      if (excelFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    } else if (format?.key === 'dat' || dataFormat === 'dat') {
      setMappings(datFieldMapping);
      if (datFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    } else if (format?.key === 'csv' || dataFormat === 'csv') {
      setMappings(csvFieldMapping);
      if (csvFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    }
    if (isFieldsEmpty) {
      let newFields = fields.filter((f) => f.isAvailable && f.isActive).map((f) => ({
        procysField: f.exportMapKey,
        field: t(f.label),
        dataType: 'string',
        format: '',
        entity: 'header'
      }));
      if (!isPassportOrID) {
        newFields = [...newFields,...supplierFields.filter(f => f.isAvailable && f.isActive).map(f => ({
          procysField: supplierFieldMapping[f.key],
          field: t(f.label),
          dataType: 'string',
          format: '',
          entity: 'supplier'
        }))];
        newFields = [...newFields, ...lineFields.filter((f) => f.isAvailable && f.isActive).map((f) => ({
          procysField: f.exportMapKey,
          field: t(f.label),
          dataType: 'string',
          format: '',
          entity: 'line'
        }))];
        newFields = [...newFields, ...vatLineFields.map((f) => ({
          procysField: vatLineFieldMapping[f.key],
          field: t(vatLineLabelsForExport[f.key]),
          dataType: 'string',
          format: '',
          entity: 'vat'
        }))];
        newFields = [...newFields, ...esLineFields.map((f) => ({
          procysField: esLineFieldMapping[f.key],
          field: t(esLineLabelsForExport[f.key]),
          dataType: 'string',
          format: '',
          entity: 'es'
        }))];
      }
      setMappings(newFields);
    }
    setHeaderFieldMapping([
      ...fields,
      linkToDocument,
      linkToDocumentPdf,
      documentOwner,
      companyCode
    ].reduce((acc, item) => {
      acc[item.key] = item.exportMapKey;
      return acc;
    }, {}));
    setLineFieldMapping(lineFields.reduce((acc, item) => {
      acc[item.key] = item.exportMapKey;
      return acc;
    }, {}));
  }, [excelFieldMapping, datFieldMapping, csvFieldMapping, format, dataFormat, fields, lineFields, supplierFields, isPassportOrID, t]);

  const handleFormatChange = event => {
    if (isEdited) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPING_SAVE_BEFORE_FORMAT_CHANGE'), {
        variant: 'error'
      });
      return;
    }
    setDataFormat(event.target.value);
  };

  const handleAddMapping = entity => {
    switch (entity) {
      case 'header':
        setHeaderMappings([
          ...headerMappings,
          {
            id: headerMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'supplier':
        setSupplierMappings([
          ...supplierMappings,
          {
            id: supplierMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'line':
        setLineMappings([
          ...lineMappings,
          {
            id: lineMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'vat':
        setVatMappings([
          ...vatMappings,
          {
            id: vatMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'es':
        setEsMappings([
          ...esMappings,
          {
            id: esMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      default:
    }
  };

  const removeMapping = (index, entity) => {
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          newMappings.splice(index, 1);
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier':
        {
          const newMappings = [...supplierMappings];
          newMappings.splice(index, 1);
          setSupplierMappings(newMappings);
        }
        break
      case 'line':
        {
          const newMappings = [...lineMappings];
          newMappings.splice(index, 1);
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          newMappings.splice(index, 1);
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          newMappings.splice(index, 1);
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const handleChange = (i, prop, val, entity) => {
    setIsEdited(true);
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          newMappings[i][prop] = val;
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier':
        {
          const newMappings = [...supplierMappings];
          newMappings[i][prop] = val;
          setSupplierMappings(newMappings);
        }
        break;
      case 'line':
        {
          const newMappings = [...lineMappings];
          newMappings[i][prop] = val;
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          newMappings[i][prop] = val;
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          newMappings[i][prop] = val;
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const handleReorder = (source, destination, entity) => {
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier':
        {
          const newMappings = [...supplierMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setSupplierMappings(newMappings);
        }
        break;
      case 'line':
        {
          const newMappings = [...lineMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const handleSaveMappings = async () => {
    const hasEmpty = [
      ...headerMappings,
      ...supplierMappings,
      ...lineMappings,
      ...vatMappings,
      ...esMappings
    ].some(m => !m.procysField || !m.field);
    if (hasEmpty) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_EMPTY'), {
        variant: 'error',
      });
      return;
    }
    let reqBody = {
      mappings: [
        ...headerMappings,
        ...supplierMappings,
        ...lineMappings,
        ...vatMappings,
        ...esMappings
      ]
    };
    try {
      const response = await Axios.post(
        `${API.exportFieldMapping}${dataFormat}`,
        reqBody,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setIsEdited(false);
        enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_SUCCESS'), {
          variant: 'success',
        });
        onModalClose(true, format);
      } else {
        enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_FAILED'), {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_FAILED'), {
        variant: 'error',
      });
    }
  };

  const onChangeApplyToAll = async e => {
    e.persist();
    const { checked } = e.target;
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        {
          customisation: `applyParentMappings${dataFormat[0].toUpperCase() + dataFormat.slice(1)}`,
          enabled: checked
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t(checked ? 'EXPORT_MAPPINGS_APPLY_TO_ALL_SUCCESS' : 'EXPORT_MAPPINGS_UNAPPLY_TO_ALL_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 3000
          }
        );
        setIsApplyToAll(checked);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(
        window.config.support_email,
        t('EXPORT_MAPPINGS_APPLY_TO_ALL_FAILURE'),
        t
      );
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || error.response.data.message);
      }

      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errorMessage, t),
        {
          variant: 'error',
          autoHideDuration: 3000
        }
      );
    }
  };

  const data = {
    title: {
      header: 'EXPORT_FIELD_MAPPINGS_HEADER_FIELDS',
      supplier: 'EXPORT_FIELD_MAPPINGS_SUPPLIER_FIELDS',
      line: 'EXPORT_FIELD_MAPPINGS_LINE_FIELDS',
      vat: 'EXPORT_FIELD_MAPPINGS_VAT_LINE_FIELDS',
      es: 'EXPORT_FIELD_MAPPINGS_ES_LINE_FIELDS'
    },
    addBtn: {
      header: 'EXPORT_FIELD_MAPPINGS_ADD_HEADER_FIELD',
      supplier: 'EXPORT_FIELD_MAPPINGS_ADD_SUPPLIER_FIELD',
      line: 'EXPORT_FIELD_MAPPINGS_ADD_LINE_FIELD',
      vat: 'EXPORT_FIELD_MAPPINGS_ADD_VAT_FIELD',
      es: 'EXPORT_FIELD_MAPPINGS_ADD_ES_FIELD'
    },
    mappings: {
      header: headerMappings,
      supplier: supplierMappings,
      line: lineMappings,
      vat: vatMappings,
      es: esMappings
    },
    fields: {
      header: [...fields, linkToDocument, linkToDocumentPdf, documentOwner, companyCode],
      supplier: supplierFields,
      line: lineFields,
      vat: vatLineFields,
      es: esLineFields
    },
    fieldMapping: {
      header: headerFieldMapping,
      supplier: supplierFieldMapping,
      line: lineFieldMapping,
      vat: vatLineFieldMapping,
      es: esLineFieldMapping
    }
  };

  const mappingContent = () => {
    if (
      user.isSubCompany &&
      user.parent &&
      isParentDocTypeSame &&
      ((dataFormat === 'excel' && user.customisations.includes('applyParentMappingsExcel')) ||
        (dataFormat === 'csv' && user.customisations.includes('applyParentMappingsCsv')) ||
        (dataFormat === 'dat' && user.customisations.includes('applyParentMappingsDat')))
    ) {
      return (
        <Grid style={styles.managedByParentContainer}>
          <Typography style={styles.managedByParent}>
            {t('EXPORT_FIELD_MAPPINGS_MANAGED_BY_PARENT', {
              dataFormat: dataFormatLabels[dataFormat]
            })}
          </Typography>
        </Grid>
      );
    }
    return exportMappingEntities.map((entity) => {
      if (isPassportOrID && entity !== 'header') return null;
      return (
        <Grid style={styles.entityContainer} key={entity}>
          <Grid style={styles.entityTitleContainer}>
            <Typography style={styles.entityTitle}>
              {t(data.title[entity])}
            </Typography>
            <Button
              variant="contained"
              style={styles.action}
              onClick={() => handleAddMapping(entity)}
            >
              <SvgIcon fontSize="small" style={styles.actionIcon}>
                <PlusCircleIcon />
              </SvgIcon>
              {ready && t(data.addBtn[entity])}
            </Button>
          </Grid>
          <DragDropContext onDragEnd={(e) => handleReorder(e?.source?.index, e?.destination?.index, entity)}>
            <Droppable droppableId="droppable" direction="vertical">
              {droppableProvided => (
                <Grid
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {data.mappings[entity].map((item, i) => {
                    const { procysField, field } = item;
                    if (metaDataFields.includes(procysField)) return null;
                    return (
                      <Draggable
                        key={procysField ? `${procysField}_${i}` : i.toString()}
                        draggableId={
                          procysField ? `${procysField}_${i}` : i.toString()
                        }
                        index={i}
                      >
                        {draggableProvided => (
                          <Grid
                            container
                            style={styles.modalItemRow}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                          >
                            <Grid columns={1} {...draggableProvided.dragHandleProps} style={styles.dragIconContainer}>
                              <ReorderIcon />
                            </Grid>
                            <Grid item columns={5} sx={styles.inputItem} >
                              <TextField
                                value={field}
                                onChange={(e) => handleChange(i, 'field', e.target.value, entity)}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                            <Grid item columns={5} sx={styles.inputItem}>
                              <Select
                                id={`${entity}-procys-field`}
                                variant="outlined"
                                onChange={(e) => handleChange(i, 'procysField', e.target.value, entity)}
                                style={styles.selectInput}
                                value={ready && procysField}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                  },
                                }}
                              >
                                {data.fields[entity].filter((f) => f.isAvailable).map(f => (
                                  <MenuItem style={styles.inputText} key={f.key} value={data.fieldMapping[entity][f.key]}>
                                    {ready && t(f.label)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item display="flex" alignItems="center" columns={1}>
                              <IconButton onClick={() => removeMapping(i, entity)}>
                                <CloseIcon style={styles.closeIcon} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    );
                  })}
                  {entity === 'header' && (
                    <Grid style={styles.metaFieldsContainer}>
                      {data.mappings[entity].map((item, i) => {
                        const { procysField, field } = item;
                        if (metaDataFields.includes(procysField)) {
                          return (
                            <Draggable
                              key={procysField ? `${procysField}_${i}` : i.toString()}
                              draggableId={procysField ? `${procysField}_${i}` : i.toString()}
                              index={i}
                            >
                              {(draggableProvided) => (
                                <Grid
                                  container
                                  style={styles.modalItemRow}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  <Grid columns={1} {...draggableProvided.dragHandleProps} style={styles.dragIconContainer}>
                                    <ReorderIcon />
                                  </Grid>
                                  <Grid item columns={5} sx={styles.inputItem}>
                                    <TextField
                                      value={field}
                                      onChange={(e) => handleChange(i, 'field', e.target.value, entity)}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item columns={5} sx={styles.inputItem}>
                                    <Select
                                      id={`${entity}-procys-field`}
                                      variant="outlined"
                                      onChange={(e) => handleChange(i, 'procysField', e.target.value, entity)}
                                      style={styles.selectInput}
                                      value={ready && procysField}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left'
                                        },
                                        transformOrigin: {
                                          vertical: 'top',
                                          horizontal: 'left'
                                        },
                                      }}
                                    >
                                      {data.fields[entity].filter((f) => f.isAvailable).map((f) => (
                                        <MenuItem style={styles.inputText} key={f.key} value={data.fieldMapping[entity][f.key]}>
                                          {ready && t(f.label)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                  <Grid item display="flex" alignItems="center" columns={1}>
                                    <IconButton onClick={() => removeMapping(i, entity)}>
                                      <CloseIcon style={styles.closeIcon}/>
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              )}
                            </Draggable>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Grid>
                  )}
                  {droppableProvided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      );
    });
  };

  return (
    <Modal
      style={styles.modal}
      open={exportFieldMappingsOpen}
      onClose={() => onModalClose()}
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={exportFieldMappingsOpen}>
        <div style={styles.halfPaper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {t('EXPORT_FIELD_MAPPINGS_MODAL_TITLE')}
            </Typography>
            <IconButton onClick={() => onModalClose()} disabled={loading}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid style={styles.modalContainer}>
            <Grid style={styles.formatChangeContainer}>
              <Typography style={styles.dataFormatText}>
                {t('EXPORT_FIELD_MAPPING_DATA_FORMAT')}
              </Typography>
              <Select
                id="format-change"
                variant="outlined"
                onChange={e => handleFormatChange(e)}
                style={styles.dataFormatSelect}
                value={dataFormat}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                }}
              >
                {['excel', 'dat', 'csv'].map(f => (
                  <MenuItem style={styles.inputText} key={f} value={f}>
                    {dataFormatLabels[f]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {mappingContent()}
          </Grid>
          {!user.isSubCompany && (
            <Grid style={styles.applyToAllContainer}>
              <FormControlLabel
                key="apply-to-all"
                value="top"
                control={
                  <Checkbox
                    style={styles.checkBox}
                    onChange={e => onChangeApplyToAll(e)}
                    checked={isApplyToAll}
                    disabled={loading}
                  />
                }
                label={ready && t('EXPORT_MAPPINGS_APPLY_TO_ALL')}
                labelPlacement="end"
                sx={{
                  '&.MuiFormControlLabel-root': styles.checkboxContainer,
                }}
              />
            </Grid>
          )}
          <Grid style={styles.modalFooter}>
            <Button
              variant="outlined"
              style={styles.cancelBtn}
              onClick={() => onModalClose()}
            >
              {ready && t('EXPORT_FIELD_MAPPINGS_CANCEL')}
            </Button>
            <Button
              variant="contained"
              style={styles.saveBtn}
              onClick={handleSaveMappings}
            >
              {ready && t('EXPORT_FIELD_MAPPINGS_SAVE')}
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default ManageExportFieldMappings;
