import Axios from 'axios';
import { euVat } from 'stdnum';

import {
  invoiceStatuses, invoiceStatusesForDropdown, invoiceStatusesKeys, subscriptionTypes
} from 'src/config';
import validations from 'src/utils/validations';

export const getInvoiceStatus = (status) => {
  if (!status || status < 16) {
    return invoiceStatuses.uploading;
  }

  switch (status) {
    case 16:
      return invoiceStatuses.digitized;
    case 500:
      return invoiceStatuses.toreview;
    case 509:
      return invoiceStatuses.reviewed;
    case 501:
      return invoiceStatuses.tovalidate;
    case 502:
      return invoiceStatuses.toexport;
    case 503:
      return invoiceStatuses.exported;
    case 510:
      return invoiceStatuses.rejected;
    case 600:
      return invoiceStatuses.archived;
    case 999:
      return invoiceStatuses.outOfLimit;
    case 900:
      return invoiceStatuses.deleted;
    case 200:
      return invoiceStatuses.splitRequest;
    case 201:
      return invoiceStatuses.possibleDuplicate;

    default:
      return invoiceStatuses.errored;
  }
};

export const getInvoiceStatusForDropdown = (status) => {
  if (!status || status < 16) {
    return invoiceStatusesForDropdown.uploading;
  }

  switch (status) {
    case 16:
      return invoiceStatusesForDropdown.digitized;
    case 500:
      return invoiceStatusesForDropdown.toreview;
    case 501:
      return invoiceStatusesForDropdown.tovalidate;
    case 502:
      return invoiceStatusesForDropdown.toexport;
    case 503:
      return invoiceStatusesForDropdown.exported;
    case 510:
      return invoiceStatusesForDropdown.rejected;
    case 600:
      return invoiceStatusesForDropdown.archived;
    case 999:
      return invoiceStatusesForDropdown.outOfLimit;
    case 900:
      return invoiceStatusesForDropdown.deleted;

    default:
      return invoiceStatusesForDropdown.errored;
  }
};

export const getInvoiceStatusKey = (status) => {
  if (!status || status < 16) {
    return invoiceStatusesKeys.uploading;
  }

  switch (status) {
    case 16:
      return invoiceStatusesKeys.digitized;
    case 500:
      return invoiceStatusesKeys.toreview;
    case 509:
      return invoiceStatusesKeys.reviewed;
    case 501:
      return invoiceStatusesKeys.tovalidate;
    case 502:
      return invoiceStatusesKeys.toexport;
    case 503:
      return invoiceStatusesKeys.exported;
    case 510:
      return invoiceStatusesKeys.rejected;
    case 600:
      return invoiceStatusesKeys.archived;
    case 999:
      return invoiceStatusesKeys.outOfLimit;
    case 200:
      return invoiceStatusesKeys.splitRequest;
    case 201:
      return invoiceStatusesKeys.possibleDuplicate;

    default:
      return invoiceStatusesKeys.errored;
  }
};
export const getRecentFilterRange = (key) => {
  let today = new Date();
  const startAr = [];
  const endAr = [];

  let start = new Date();
  let end = new Date();

  let startDate = new Date();
  let endDate = new Date();

  let year = new Date();
  let month = new Date();

  let monthQuarter = 0;

  switch (key) {
    case 1: // This week
      start = today.getDate() - today.getDay(); // eslint-disable-line
      end = start + 6; // eslint-disable-line

      startDate = new Date(today.setDate(today.getDate() - today.getDay())); // eslint-disable-line
      startDate = new Date(startDate.setHours(0, 0, 0, 0));

      endDate = new Date(today.setDate(today.getDate() + 6)); // eslint-disable-line
      endDate = new Date(endDate.setHours(23, 59, 59, 999));

      startAr.push(startDate);
      endAr.push(endDate);
      today = new Date(startDate);
      for (let i = 0; i < 2; i++) {
        startDate = new Date(today.setDate(today.getDate() - 7));
        startDate = new Date(startDate.setHours(0, 0, 0, 0));

        endDate = new Date(today.setDate(today.getDate() + 6));
        endDate = new Date(endDate.setHours(23, 59, 59, 999));

        startAr.push(startDate);
        endAr.push(endDate);

        today = new Date(startDate);
      }

      return {
        s: startAr[0],
        e: endAr[0]
      };

    case 2: // Last week
      start = today.getDate() - today.getDay();
      end = start + 6;

      startDate = new Date(today.setDate(today.getDate() - today.getDay()));
      startDate = new Date(startDate.setHours(0, 0, 0, 0));

      endDate = new Date(today.setDate(today.getDate() + 6));
      endDate = new Date(endDate.setHours(23, 59, 59, 999));

      startAr.push(startDate);
      endAr.push(endDate);
      today = new Date(startDate);
      for (let i = 0; i < 2; i++) {
        startDate = new Date(today.setDate(today.getDate() - 7));
        startDate = new Date(startDate.setHours(0, 0, 0, 0));

        endDate = new Date(today.setDate(today.getDate() + 6));
        endDate = new Date(endDate.setHours(23, 59, 59, 999));

        startAr.push(startDate);
        endAr.push(endDate);

        today = new Date(startDate);
      }

      return {
        s: startAr[1],
        e: endAr[1]
      };

    case 3: // This Month
      year = today.getFullYear(); // eslint-disable-line
      month = today.getMonth(); // eslint-disable-line

      start = new Date(year, month, 1);
      end = new Date(year, month + 1, 0);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 1; i < 12; i++) {
        start = new Date(year, month - i, 1);
        end = new Date(year, month + 1 - i, 0);
        end = new Date(end.setHours(23, 59, 59, 999));

        startAr.push(start);
        endAr.push(end);
      }

      return {
        s: startAr[0],
        e: endAr[0]
      };

    case 4: // Last Month
      year = today.getFullYear();
      month = today.getMonth();

      start = new Date(year, month, 1);
      end = new Date(year, month + 1, 0);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 1; i < 2; i++) {
        start = new Date(year, month - i, 1);
        end = new Date(year, month + 1 - i, 0);
        end = new Date(end.setHours(23, 59, 59, 999));

        startAr.push(start);
        endAr.push(end);
      }

      return {
        s: startAr[1],
        e: endAr[1]
      };

    case 5: // This Quarter
      year = today.getFullYear();
      month = today.getMonth();

      monthQuarter = Math.floor(month / 3) + 1; // eslint-disable-line

      start = new Date(year, (monthQuarter - 1) * 3, 1);
      end = new Date(year, monthQuarter * 3, 0);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 0; i < 2; i++) {
        startDate = new Date(start);
        endDate = new Date(start);

        start = new Date(startDate.setMonth(startDate.getMonth() - 3));
        end = new Date(endDate.setDate(endDate.getDate() - 1));
        end = new Date(end.setHours(23, 59, 59, 999));

        if (start.getFullYear() >= 2020) {
          startAr.push(start);
          endAr.push(end);
        }
      }

      return {
        s: startAr[0],
        e: endAr[0]
      };

    case 6: // Last Quarter
      year = today.getFullYear();
      month = today.getMonth();

      monthQuarter = Math.floor(month / 3) + 1;

      start = new Date(year, (monthQuarter - 1) * 3, 1);
      end = new Date(year, monthQuarter * 3, 0);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 0; i < 2; i++) {
        startDate = new Date(start);
        endDate = new Date(start);

        start = new Date(startDate.setMonth(startDate.getMonth() - 3));
        end = new Date(endDate.setDate(endDate.getDate() - 1));
        end = new Date(end.setHours(23, 59, 59, 999));

        if (start.getFullYear() >= 2020) {
          startAr.push(start);
          endAr.push(end);
        }
      }

      return {
        s: startAr[1],
        e: endAr[1]
      };

    case 7: // This year
      year = today.getFullYear();

      start = new Date(year, 0, 1);
      end = new Date(year, 11, 31);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 1; year - i >= 2017 && i < 11; i++) {
        start = new Date(year - i, 0, 1);
        end = new Date(year - i, 11, 31);
        end = new Date(end.setHours(23, 59, 59, 999));

        startAr.push(start);
        endAr.push(end);
      }

      return {
        s: startAr[0],
        e: endAr[0]
      };

    case 8: // Last year
      year = today.getFullYear();

      start = new Date(year, 0, 1);
      end = new Date(year, 11, 31);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 1; year - i >= 2017 && i < 11; i++) {
        start = new Date(year - i, 0, 1);
        end = new Date(year - i, 11, 31);
        end = new Date(end.setHours(23, 59, 59, 999));

        startAr.push(start);
        endAr.push(end);
      }

      return {
        s: startAr[1],
        e: endAr[1]
      };

    default:
      return null;
  }
};

export const axiosHeaders = (token) => (
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    }
  }
);

export const axiosHeadersWithArrayBuffer = (token) => (
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    responseType: 'arraybuffer'
  }
);

export const axiosDeleteHeaders = (token, data) => (
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    data
  }
);

export const axiosHeadersFormData = (token, data) => (
  {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`, // eslint-disable-line
      Authorization: `bearer ${token}`
    }
  }
);

export const getLocalisedErrorString = (str, t) => {
  if (str && str.indexOf('__BR__') >= 0) {
    const strAr = str.split('__BR__');
    // NOTE: String manipulation done based on the number of occurrences of '__BR__'
    // This solution may need to change if and when the BEs support proper i18n support
    switch (strAr.length) {
      case 2:
        return t(strAr[0], { ref: strAr[1] });
      case 3:
        return t(strAr[0], { ref: strAr[1], var: strAr[2] });
      case 4:
        return t(strAr[0], { ref: strAr[1], var: strAr[2], var1: strAr[3] });
      case 5:
        return t(strAr[0], {
          ref: strAr[1], var: strAr[2], var1: strAr[3], var2: strAr[4]
        });
      case 6:
        return t(strAr[0], {
          ref: strAr[1], var: strAr[2], var1: strAr[3], var2: strAr[4], var3: strAr[5]
        });
      default:
        return t(strAr[0]);
    }
  }
  return t(str);
};

const regAlp = /[^a-zA-Z]/g;
const regNum = /[^0-9]/g;

const sortbyInvoiceId = (a, b) => {
  const aAlp = a.replace(regAlp, '');
  const bAlp = b.replace(regAlp, '');
  if (aAlp === bAlp) {
    const aNum = parseInt(a.replace(regNum, ''), 10);
    const bNum = parseInt(b.replace(regNum, ''), 10);
    return aNum > bNum ? 1 : -1;
  }
  return aAlp > bAlp ? 1 : -1;
};

export const applySort = (invoices, sortBy, sortOrder) => {
  if (sortBy && sortOrder) {
    let sortedInvoices = [];
    if (sortBy === 'id') {
      // Any alpha-numeric dor is carried out here
      sortedInvoices = invoices.sort(
        (a, b) => sortbyInvoiceId(a[sortBy].toLowerCase(), b[sortBy].toLowerCase())
      );
    } else {
      sortedInvoices = invoices.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
    }
    return sortOrder === 'asc' ? sortedInvoices : sortedInvoices.reverse();
  }
  return invoices;
};

export const applyPagination = (users, page, limit) => users.slice(
  page * limit, page * limit + limit
);

export const applyCostcenterFilters = (items, query) => items.filter((item) => {
  let matches = true;

  if (query) {
    const properties = ['code', 'name'];
    let containsQuery = false;

    properties.forEach((property) => {
      if (item[property]?.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }
    });

    if (!containsQuery) {
      matches = false;
    }
  }

  return matches;
});

export const applyLogsFilters = (logs, filters) => logs.filter((log) => {
  let matches = true;
  const timestamp = log.timestamp ? log.timestamp / 1000000 : log.uploadedAt * 1000;

  if (filters.status && getInvoiceStatusKey(log.status) !== filters.status) {
    matches = false;
  }

  if (filters.recent) {
    const range = getRecentFilterRange(filters.recent);
    if (range && (timestamp < range.s.getTime() || timestamp > range.e.getTime())) {
      matches = false;
    }
  }

  return matches;
});

export const applyUserFilters = (users, query, filters) => users.filter((user) => {
  let matches = true;

  if (query) {
    const properties = ['email', 'name'];
    let containsQuery = false;

    properties.forEach((property) => {
      if (user[property].toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }
    });

    if (!containsQuery) {
      matches = false;
    }
  }

  Object.keys(filters).forEach((key) => {
    const value = filters[key];

    if (value && user[key] !== value) {
      matches = false;
    }
  });

  return matches;
});

export const permissions = {
  invoiceUpload: 'invoice_upload',
  invoiceSave: 'invoice_save',
  invoiceReview: 'invoice_review',
  invoiceValidate: 'invoice_validate',
  invoiceExport: 'invoice_export',
  invoiceArchive: 'invoice_archive',
  invoiceDelete: 'invoice_delete',
  dimensionRead: 'dimension_read',
  dimensionAdd: 'dimension_add',
  dimensionEdit: 'dimension_edit',
  dimensionDelete: 'dimension_delete',
  appsInstall: 'apps_install',
  appsManage: 'apps_manage',
  appsView: 'apps_view',
  subscriptionsUpgrade: 'subscriptions_upgrade',
  subscriptionsDowngrade: 'subscriptions_downgrade',
  subscriptionsCancel: 'subscriptions_cancel',
  subscriptionsBuyExtra: 'subscriptions_buy_extra',
  subscriptionsAddCard: 'subscriptions_add_card',
  subscriptionsEditCard: 'subscriptions_edit_card',
  subscriptionsDeleteCard: 'subscriptions_delete_card',
  userAdd: 'user_add',
  userLock: 'user_lock',
  userRoleManage: 'user_role_manage',
  userPermissionManage: 'user_permission_manage',
  companyAdd: 'company_add',
  companyManage: 'company_manage',
  companyDelete: 'company_delete'
};

export const isBranded = (brand) => brand && brand.toLowerCase() !== 'procys';

export const isActionPermitted = (action, permissionsList) => permissionsList.includes(action);

export const getDocumentType = (documentType) => {
  switch (documentType) {
    case 'invoice':
      return 'INVOICE_EDIT_FORM_INVOICE_TYPE_INVOICE';
    case 'purchase_order':
      return 'INVOICE_EDIT_FORM_INVOICE_TYPE_PURCHASE_ORDER';
    case 'id':
      return 'INVOICE_EDIT_FORM_INVOICE_TYPE_PASSPORT_OR_ID';

    default:
      return 'INVOICE_EDIT_FORM_INVOICE_TYPE_INVOICE';
  }
};

export const formatAmountInvoicesTable = (_amount, decimalSeparator) => {
  const amount = _amount.replace(decimalSeparator, '.');
  if (Number.isNaN(amount)) {
    return '';
  }
  return parseFloat(amount).toFixed(2).toString().replace('.', decimalSeparator);
};

export const getMaskedCurrencyText = (input) => {
  const tAr = [];

  for (let i = 0; i < input.length; i++) {
    tAr.push(input[input.length - i - 1]);
    if (i === 1) {
      tAr.push(',');
    }

    if (i > 2 && i < input.length - 1 && (i - 2) % 3 === 2) {
      tAr.push('.');
    }
  }

  return tAr.reverse().join('');
};

export const getMaskedText = (input, mask) => {
  const tAr = [];

  for (let i = 0; i < input.length; i++) {
    tAr.push(input[input.length - i - 1]);
    if (i % mask === 2) {
      tAr.push(' ');
    }
  }

  return tAr.reverse().join('');
};

export const getSubscriptionTitle = (key) => {
  if (!key) {
    return '';
  }

  const lowerKey = key.toLowerCase();
  switch (lowerKey) {
    case subscriptionTypes.free.label.toLowerCase():
      return subscriptionTypes.free.i18n;

    case subscriptionTypes.starter.label.toLowerCase():
      return subscriptionTypes.starter.i18n;

    case subscriptionTypes.business.label.toLowerCase():
      return subscriptionTypes.business.i18n;

    case subscriptionTypes.enterprise.label.toLowerCase():
    case 'custom':
      return subscriptionTypes.enterprise.i18n;

    default:
      break;
  }

  return '';
};

const parseDDMMYYYYDateString = (dateString) => {
  if (dateString === '') {
    return '';
  }

  let parts = [];
  if (dateString.includes('/')) {
    parts = dateString.split(/[/ :]/);
  } else {
    parts = dateString.split(/[- :]/);
  }

  // Extracting day, month, year
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are zero-based
  const year = parseInt(parts[2], 10);

  // Default time values if not provided
  let hours = 0; let minutes = 0; let seconds = 0;

  // If time is provided in the string
  if (parts.length > 3) {
    hours = parseInt(parts[3], 10);
    minutes = parseInt(parts[4], 10);
    seconds = parseInt(parts[5], 10);
  }

  // Creating the Date object
  const date = new Date(year, month, day, hours, minutes, seconds);

  return date;
};

export const formatDateCustomFormat = (dateString, format) => {
  if (!dateString) {
    return '';
  }

  let date = new Date(dateString);

  if (Number.isNaN(date.getTime())) {
    const parsedDate = parseDDMMYYYYDateString(dateString);
    if (Number.isNaN(parsedDate.getTime())) {
      return dateString;
    }
    date = parsedDate;
  }

  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0');
  if (month <= 12 && day <= 12) {
    day = String(date.getMonth() + 1).padStart(2, '0');
    month = String(date.getDate()).padStart(2, '0');
  }
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const formattedDate = format
    .replace('DD', day)
    .replace('MM', month)
    .replace('YYYY', year)
    .replace('hh', hours)
    .replace('mm', minutes)
    .replace('ss', seconds);

  return formattedDate;
};

export const subscriptionLabel = {
  Free: 'MY_PLAN_FREE_LABEL',
  Starter: 'MY_PLAN_STARTER_LABEL',
  Business: 'MY_PLAN_BUSINESS_LABEL',
  Enterprise: 'MY_PLAN_ENTERPRISE_LABEL'
};

/**
 * Email address field validation
 *  - 2 parts separated by '@'
 *  - Local part should be less than 63 characters
 *  - Total length should be less than 254 characters
 *  - No dangerous characters (characters that could manipulate code (example: sql queries or inject js or echo statements))
 *  - Domain should contain only letters, numbers, hyphens and periods
 *  - Validation performed to contain only ==> a-z0-9._- characters and no consecutive ._-
 */
const MINIMUM_EMAIL_LENGTH = 8;
const MAXIMUM_EMAIL_LENGTH = 255;
const MINIMUM_USERNAME_PART = 1;
const MAXIMUM_USERNAME_PART = 63;
const MINIMUM_DOMAIN_PART = 3;
const MAXIMUM_DOMAIN_PART = 191; // 255-63-1
const EMAIL_USERNAME_PATTERN = /^[a-z0-9]+(?:[._][a-z0-9]+)*(?:\+[0-9]+)*$/;
const EMAIL_DOMAIN_PATTERN = /^([a-z0-9]+?(-[a-z0-9]+)*(?:[a-z0-9]*[a-z0-9])?\.)+[a-z0-9]+$/;

export const validateEmail = (email) => {
  if (!email) {
    return {
      isValid: false,
      errorMessage: 'Email can not be empty'
    };
  }

  if (validations.isValidLength(email, MINIMUM_EMAIL_LENGTH, MAXIMUM_EMAIL_LENGTH)) {
    if (validations.containsSymbol(email, '@')) {
      const emailParts = email.trim().split('@');
      if (
        validations.isValidLength(emailParts[0], MINIMUM_USERNAME_PART, MAXIMUM_USERNAME_PART)
        && validations.isValidLength(emailParts[1], MINIMUM_DOMAIN_PART, MAXIMUM_DOMAIN_PART)
      ) {
        if (emailParts[0].match(EMAIL_USERNAME_PATTERN) && emailParts[1].match(EMAIL_DOMAIN_PATTERN)) {
          return {
            isValid: true,
            errorMessage: null
          };
        }
      }
    }
  }

  return {
    isValid: false,
    errorMessage: 'Invalid email address'
  };
};

export const getDeliverFormat = (value, exportFormatTypes) => exportFormatTypes.find((format) => format.key === value)
|| { key: '', label: 'None' };

export const applyFilters = (invoices, filters) => invoices.filter((invoice) => {
  let matches = true;

  // Done via the BE, but keeping it commented in case we revert [CLEAN]
  // if (query) {
  //   const properties = ['appUsername', 'assistantID', 'invoiceID', 'amount', 'currency'];
  //   let containsQuery = false;
  //   properties.forEach((property) => {
  //     if (invoice[property] && invoice[property].toString().toLowerCase().includes(query.toLowerCase())) {
  //       containsQuery = true;
  //     }
  //   });

  //   if (!containsQuery) {
  //     matches = false;
  //   }
  // }

  if (filters.type && invoice.type !== filters.type) {
    matches = false;
  }

  if (filters.status && getInvoiceStatusKey(invoice.status) !== filters.status) {
    matches = false;
  }

  if (filters.recent) {
    const range = getRecentFilterRange(filters.recent);
    if (range && (invoice.appUploadedDate * 1000 < range.s.getTime() || invoice.appUploadedDate * 1000 > range.e.getTime())) {
      matches = false;
    }
  }

  return matches;
});

export const sleep = (t) => new Promise((r) => setTimeout(r, t));

export const isFileMIMETypeHEIC = (header, mime) => {
  if (
    header.indexOf('000') === 0 && (
      mime === 'image/png' || mime === 'image/jpg' || mime === 'image/jpeg'
      || mime === 'image/heic' || mime === 'image/heif'
    )
  ) {
    return true;
  }

  return false;
};



export const getPermissionByInvoiceStatus = (status) => {
  switch (status) {
    case 500: case 509:
      return permissions.invoiceReview;
    case 501:
      return permissions.invoiceValidate;
    case 502:
      return permissions.invoiceExport;
    case 503:
      return permissions.invoiceExport;
    case 510:
      return permissions.invoiceReview;
    case 600:
      return permissions.invoiceDelete;

    default:
      return permissions.invoiceSave;
  }
};

export const processByStatus = (status) => {
  switch (status) {
    case 500:
      return 'to-review';
    case 509:
      return 'reviewed';
    case 501:
      return 'to-validate';
    case 502:
      return 'to-export';
    case 503:
      return 'exported';
    case 510:
      return 'rejected';
    case 600:
      return 'archived';

    default:
      return 'processing';
  }
};

export const actionByStatus = (status) => {
  switch (status) {
    case 500:
      return 'Review';
    case 509:
      return 'Reviewed';
    case 501:
      return 'Validate';
    case 502:
      return 'Export';
    case 503:
      return 'Export';
    case 510:
      return 'Reject';
    case 600:
      return 'Delete';

    default:
      return 'Save';
  }
};

export const i18nByStatus = (status) => {
  switch (status) {
    case 500:
      return 'REVIEW';
    case 509:
      return 'REVIEWED';
    case 501:
      return 'VALIDATE';
    case 502:
      return 'TO_EXPORT';
    case 503:
      return 'EXPORT';
    case 510:
      return 'REJECT';
    case 600:
      return 'ARCHIVED';

    default:
      return 'SAVE';
  }
};

export const userEditOptionLabels = {
  change: 'Change',
  resend: 'Resend invitation',
  revoke: 'Revoke invitation',
  remove: 'Remove',
  restore: 'Restore',
  delete: 'Delete'
};

export const isUserManageOptionValid = (option, status) => {
  if (status !== 'revoked' && option === 'change') {
    return true;
  }
  if (status === 'invited' && (option === 'resend' || option === 'revoke')) {
    return true;
  }
  if (status === 'active' && option === 'remove') {
    return true;
  }
  if (status === 'removed' && (option === 'restore' || option === 'delete')) {
    return true;
  }
  if (status === 'revoked' && option === 'delete') {
    return true;
  }

  return false;
};

export const appendContactSupport = (supportEmail, str, t) => {
  if (str) {
    const lastChar = str.charAt(str.length - 1);
    const fullStop = lastChar !== '.' && lastChar !== '!' ? '.' : '';
    return `${str}${fullStop} ${t('CONTACT_SUPPORT', { email: supportEmail })}`;
  }
  return `${t('SOMETHING_WENT_WRONG')} ${t('CONTACT_SUPPORT', { email: supportEmail })}`;
};

export const sendFeedback = async (url, action, email, company) => {
  await Axios.post(
    url,
    { action, email, company },
    axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
  );
};

const amountValidChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-'];

export const formatAmountFields = (str, _decimalSeparator = '.') => {
  let res = str.replace(' ', '');
  let validChars = amountValidChars;

  const decimalSeparator = _decimalSeparator !== '.' ? _decimalSeparator : ',';
  validChars = [...amountValidChars, decimalSeparator];

  let temp = '';
  let commaCount = 0;
  let dotCount = 0;
  for (let i = 0; i < res.length; i++) {
    const element = res.charAt(i);
    if (validChars.includes(element)) {
      temp += element;
      if (element === decimalSeparator) {
        commaCount += 1;
      } else if (element === '.') {
        dotCount += 1;
      }
    }
  }

  res = temp;
  if (!res) {
    return str;
  }

  // Format only if there is one decimal separator and thousand separator present
  if (commaCount === 1) {
    if (dotCount === 0) {
      return _decimalSeparator === '.' ? res.replace(decimalSeparator, '.') : res;
    }
    if (dotCount === 1) {
      if (res.indexOf('.') < res.indexOf(decimalSeparator)) {
        res = res.replace('.', '').replace(decimalSeparator, '.');
        return _decimalSeparator === '.' ? res : res.replace('.', decimalSeparator);
      }
      res = res.replace(decimalSeparator, '');
      return _decimalSeparator === '.' ? res : res.replace('.', decimalSeparator);
    }
  }
  if (dotCount === 1) {
    return _decimalSeparator === '.' ? res : res.replace('.', decimalSeparator);
  }

  return res;
};

export const calculateSubtotal = (lines, decimalSeparator) => {
  const subtotalArr = lines.map((l) => {
    const linePrice = l.linePrice?.replace(decimalSeparator, '.');
    return parseFloat(linePrice);
  });
  let subtotal = 0;
  subtotalArr.forEach((st) => {
    if (!Number.isNaN(st)) {
      subtotal += st;
    }
  });

  return subtotal.toFixed(2).toString().replace('.', decimalSeparator);
};

export const isInvoiceFieldAvailable = (user, field, isActive) => {
  if (user?.interfacePreference === 'twinfield_interface') {
    return isActive || ['documentType', 'doNotPay', 'discountAmount'].includes(field);
  }

  return isActive;
};

export const truncateFileName = (str, n) => {
  if (!str) {
    return '';
  }
  const sep = str.split('.');
  if (sep.length > 0) {
    return (str.length > n) ? `${sep[0].slice(0, n - 1)}... .${sep[1]}` : str;
  }
  return (str.length > n) ? `${str.slice(0, n - 1)}...` : str;
};

export const truncateString = (str, num) => {
  if (!str) {
    return '';
  }

  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

// We use the Separating Axis Theorem to check if two bounding boxes overlap
export const isOverlapping = (xmin1, ymin1, xmax1, ymax1, xmin2, ymin2, xmax2, ymax2) =>
    !((xmax1 < xmin2) || (xmin1 > xmax2) || (ymax1 < ymin2) || (ymax2 < ymin1));

export const isAmountTallyingWithLines = (details) => {
  if (!details.lines || details.lines.length === 0) {
    return true;
  }
  let totalAmount = 0.00;
  for (let i = 0; i < details.lines.length; i++) {
    if (!details.lines[i].lineExtraCost) {
      totalAmount += parseFloat(details.lines[i].lineAmount) || 0.00;
    }
  }
  totalAmount = parseFloat(totalAmount.toFixed(2));

  if (parseFloat(details.amount) === totalAmount) {
    return true;
  }

  if (parseFloat(details.amount) === (totalAmount + parseFloat(details.vatAmount))) {
    return true;
  }

  return false;
};

export const isShowManageFields = (user, entityCustomisation) => {
  if (user?.interfacePreference === 'twinfield_interface') {
    return false;
  }
  if (!user.isSubCompany) {
    return true;
  }
  if (user.customisations.includes(entityCustomisation)) {
    return false;
  }
  return true;
};

export const caclulateLineDiscountedPrice = (_linePrice, _lineDiscountAmountOnTotalPrice, decimalSeparator) => {
  const linePrice = _linePrice.replace(decimalSeparator, '.');
  const lineDiscountAmountOnTotalPrice = _lineDiscountAmountOnTotalPrice.replace(decimalSeparator, '.');
  const lineDiscountedPrice = ((parseFloat(linePrice) || 0) - (parseFloat(lineDiscountAmountOnTotalPrice) || 0)).toFixed(2);
  if (Number.isNaN(lineDiscountedPrice)) {
    return '';
  }
  return lineDiscountedPrice.toString().replace('.', decimalSeparator);
};

export const calculateLineAmount = (_linePrice, _lineTax, decimalSeparator) => {
  const linePrice = _linePrice.replace(decimalSeparator, '.');
  const lineTax = _lineTax.replace(decimalSeparator, '.');
  const lineAmount = (parseFloat(linePrice) + parseFloat(lineTax)).toFixed(2);
  if (Number.isNaN(lineAmount)) {
    return '';
  }
  return lineAmount.toString().replace('.', decimalSeparator);
};

export const calculateLinePrice = (_lineUnitPrice, _lineQuantity, _lineProductCount, decimalSeparator) => {
  const lineUnitPrice = _lineUnitPrice.replace(decimalSeparator, '.');
  const lineQuantity = _lineQuantity.replace(decimalSeparator, '.');
  const lineProductCount = _lineProductCount.replace(decimalSeparator, '.');
  const linePrice = (parseFloat(lineUnitPrice) * parseFloat(lineQuantity) * parseFloat(lineProductCount)).toFixed(2);
  if (Number.isNaN(linePrice)) {
    return '';
  }
  return linePrice.toString().replace('.', decimalSeparator);
};

export const calculateLineTax = (_linePrice, _lineVatrate, decimalSeparator) => {
  const linePrice = _linePrice.replace(decimalSeparator, '.');
  const lineVatrate = _lineVatrate.replace(decimalSeparator, '.');
  const lineTax = ((parseFloat(linePrice) || 0) * (parseFloat(lineVatrate) / 100)).toFixed(2);
  if (Number.isNaN(lineTax)) {
    return '';
  }
  return lineTax.toString().replace('.', decimalSeparator);
};

export const calculateLineUnitPrice = (_lineDiscountedPrice, _lineQuantity, decimalSeparator) => {
  const lineDiscountedPrice = _lineDiscountedPrice.replace(decimalSeparator, '.');
  const lineQuantity = _lineQuantity.replace(decimalSeparator, '.');
  const lineUnitPrice = (parseFloat(lineDiscountedPrice) / parseFloat(lineQuantity)).toFixed(2);
  if (Number.isNaN(lineUnitPrice)) {
    return '';
  }
  return lineUnitPrice.toString().replace('.', decimalSeparator);
};

export const formatDateDDMMYYYY = (str) => {
  if (!str) {
    return '';
  }
  let date = new Date(str);

  if (Number.isNaN(date.getTime())) {
    const parsedDate = parseDDMMYYYYDateString(str);
    if (Number.isNaN(parsedDate.getTime())) {
      return str;
    }
    date = parsedDate;
  }

  // Format the date to dd-mm-yyyy
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  if (month <= 12 && day <= 12) {
    return `${month}-${day}-${year}`;
  }

  return `${day}-${month}-${year}`;
};

export const addDays = (date, days, dueDaysAsDayOfWeek) => {
  let dateF = parseDDMMYYYYDateString(date);
  if (dueDaysAsDayOfWeek) {
    const dueDays = Array.from(String(days), (num) => Number(num));
    const daysToAddArr = [];
    dueDays.forEach((day) => {
      const dayOfWeek = dateF.getDay();
      let daysToAdd = day - dayOfWeek;
      if (daysToAdd <= 0) {
        daysToAdd += 7;
      }
      daysToAddArr.push(daysToAdd);
    });
    dateF.setDate(dateF.getDate() + Math.min(...daysToAddArr));
  } else {
    dateF.setDate(dateF.getDate() + days);
  }
  const offset = dateF.getTimezoneOffset();
  dateF = new Date(dateF.getTime() - (offset * 60 * 1000)).toISOString();

  const formattedDate = formatDateDDMMYYYY(dateF);

  return formattedDate;
};

export const calculateBaseAmount = (_amount, _vatAmount, decimalSeparator) => {
  const amount = _amount.replace(decimalSeparator, '.');
  const vatAmount = _vatAmount.replace(decimalSeparator, '.');
  const baseAmount = (parseFloat(amount) - parseFloat(vatAmount)).toFixed(2);
  if (Number.isNaN(baseAmount)) {
    return '';
  }
  return baseAmount.toString().replace('.', decimalSeparator);
};

export const calculateDiscountRate = (_discountAmount, _baseAmount, decimalSeparator) => {
  const discountAmount = _discountAmount.replace(decimalSeparator, '.');
  const baseAmount = _baseAmount.replace(decimalSeparator, '.');
  const discountRate = (parseFloat(discountAmount) / parseFloat(baseAmount)).toFixed(2);
  if (Number.isNaN(discountRate)) {
    return '';
  }
  return discountRate.toString().replace('.', decimalSeparator);
};

export const calculateESAmount = (_baseAmount, _vatRate, decimalSeparator) => {
  const baseAmount = _baseAmount.replace(decimalSeparator, '.');
  const vatRate = _vatRate.replace(decimalSeparator, '.');
  const vatAmount = (parseFloat(baseAmount) * (parseFloat(vatRate) / 100)).toFixed(2);
  if (Number.isNaN(vatAmount)) {
    return '';
  }
  return vatAmount.toString().replace('.', decimalSeparator);
};

export const calculateLineAmountExtraCost1 = (_amount, _lineAmount, decimalSeparator) => {
  const amount = _amount.replace(decimalSeparator, '.');
  const lineAmount = _lineAmount.replace(decimalSeparator, '.');
  const newAmount = (parseFloat(amount) + parseFloat(lineAmount)).toFixed(2);
  if (Number.isNaN(newAmount)) {
    return '';
  }
  return newAmount.toString().replace('.', decimalSeparator);
};

export const calculateLineAmountExtraCost2 = (_amount, _lineAmount, decimalSeparator) => {
  const amount = _amount.replace(decimalSeparator, '.');
  const lineAmount = _lineAmount.replace(decimalSeparator, '.');
  const newAmount = (parseFloat(amount) - parseFloat(lineAmount)).toFixed(2);
  if (Number.isNaN(newAmount)) {
    return '';
  }
  return newAmount.toString().replace('.', decimalSeparator);
};

export const calculateLineAmountExtraCost3 = (_amount, _lineAmount, _newLineAmount, decimalSeparator) => {
  const amount = _amount.replace(decimalSeparator, '.');
  const lineAmount = _lineAmount.replace(decimalSeparator, '.');
  const newLineAmount = _newLineAmount.replace(decimalSeparator, '.');
  const newAmount = (parseFloat(amount) + parseFloat(lineAmount) - parseFloat(newLineAmount)).toFixed(2);
  if (Number.isNaN(newAmount)) {
    return '';
  }
  return newAmount.toString().replace('.', decimalSeparator);
};

export const calculateLineAmountExtraCost4 = (_amount, _newLineAmount, decimalSeparator) => {
  const amount = _amount.replace(decimalSeparator, '.');
  const newLineAmount = _newLineAmount.replace(decimalSeparator, '.');
  const newAmount = (parseFloat(amount) - parseFloat(newLineAmount)).toFixed(2);
  if (Number.isNaN(newAmount)) {
    return '';
  }
  return newAmount.toString().replace('.', decimalSeparator);
};

export const calculateLineTaxForVat = (_amount, _vatRate, decimalSeparator) => {
  const amount = _amount.replace(decimalSeparator, '.');
  const vatRate = _vatRate.replace(decimalSeparator, '.');
  const vatAmount = (parseFloat(amount) / (100 + parseFloat(vatRate))) * 100;
  const lineTax = (parseFloat(amount) - vatAmount).toFixed(2);
  if (Number.isNaN(lineTax)) {
    return '';
  }
  return lineTax.toString().replace('.', decimalSeparator);
};

export const calculateSumOfVATLines = (vatLines, decimalSeparator) => {
  const sum = vatLines.reduce((acc, vatLine) => {
    const amount = vatLine.vatAmount?.replace(decimalSeparator, '.');
    return acc + parseFloat(amount);
  }, 0);
  if (Number.isNaN(sum)) {
    return '';
  }
  return sum.toFixed(2).toString().replace('.', decimalSeparator);
};

export const calculateSumOfESLines = (vatLines, decimalSeparator) => {
  const sum = vatLines.reduce((acc, vatLine) => {
    const amount = vatLine.esAmount?.replace(decimalSeparator, '.');
    return acc + parseFloat(amount);
  }, 0);
  if (Number.isNaN(sum)) {
    return '';
  }
  return sum.toFixed(2).toString().replace('.', decimalSeparator);
};

export const calculateVatAmount = (_baseAmount, _vatRate, decimalSeparator) => {
  const baseAmount = _baseAmount.replace(decimalSeparator, '.');
  const vatRate = _vatRate.replace(decimalSeparator, '.');
  const vatAmount = (parseFloat(baseAmount) * (parseFloat(vatRate) / 100)).toFixed(2);
  if (Number.isNaN(vatAmount)) {
    return '';
  }
  return vatAmount.toString().replace('.', decimalSeparator);
};

export const getMonthShortString = (month) => {
  switch (month) {
    case '01': case '1':
      return 'Jan';
    case '02': case '2':
      return 'Feb';
    case '03': case '3':
      return 'Mar';
    case '04': case '4':
      return 'Apr';
    case '05': case '5':
      return 'May';
    case '06': case '6':
      return 'Jun';
    case '07': case '7':
      return 'Jul';
    case '08': case '8':
      return 'Aug';
    case '09': case '9':
      return 'Sep';
    case '10':
      return 'Oct';
    case '11':
      return 'Nov';
    case '12':
      return 'Dec';
    default:
      return '';
  }
};

export const isTaxNumberSwitchAvailable = (key, renderedFields) => {
  if (key === 'senderTaxNumber' && !renderedFields.some((field) => field.key === 'senderVatNumber')) {
    return true;
  }
  if (key === 'receiverTaxNumber' && !renderedFields.some((field) => field.key === 'receiverVatNumber')) {
    return true;
  }
  if (key === 'senderVatNumber' && !renderedFields.some((field) => field.key === 'senderTaxNumber')) {
    return true;
  }
  if (key === 'receiverVatNumber' && !renderedFields.some((field) => field.key === 'receiverTaxNumber')) {
    return true;
  }
  return false;
};

export const isVatNumberInvalid = (vatNumber, countryCode) => {
  if (vatNumber) {
    let country = countryCode?.toUpperCase();
    if (country === '' && vatNumber.length > 2) {
      country = vatNumber.slice(0, 2).toUpperCase();
    }
    if (country !== '' && euVat[country]) {
      const { isValid } = euVat[country][0].validate(vatNumber);
      return !isValid;
    }
    return true;
  }
  return false;
};

export const getAggregate = (ar, s, e) => {
  let t = 0;
  if (s >= 0) {
    for (let i = s; i <= e; i++) {
      t += ar[i];
    }
  }
  return t;
};

export const filterProcessedInvoices = (
  filter, labels, uploading, digitized, toreview, tovalidate, toexport, exported, errored, rejected, amountExported, t
) => {
  let today = new Date();
  const startAr = [];
  const endAr = [];
  const newLabels = [];
  const newUploading = [];
  const newDigitized = [];
  const newToreview = [];
  const newTovalidate = [];
  const newToexport = [];
  const newExported = [];
  const newRejected = [];
  const newErrored = [];
  const newAmountExported = [];

  const year = today.getFullYear();
  const month = today.getMonth();

  let start = new Date();
  let end = new Date();

  let startDate = new Date();
  let endDate = new Date();

  let monthQuarter = 0;
  let date = null;

  switch (filter) {
    case 1: // Weekly
      start = today.getDate() - today.getDay();
      end = start + 6;

      startDate = new Date(today.setDate(today.getDate() - today.getDay()));
      startDate = new Date(startDate.setHours(0, 0, 0, 0));

      endDate = new Date(today.setDate(today.getDate() + 6));
      endDate = new Date(endDate.setHours(23, 59, 59, 999));

      startAr.push(startDate);
      endAr.push(endDate);
      today = new Date(startDate);
      for (let i = 0; i < 11; i++) {
        startDate = new Date(today.setDate(today.getDate() - 7));
        startDate = new Date(startDate.setHours(0, 0, 0, 0));

        endDate = new Date(today.setDate(today.getDate() + 6));
        endDate = new Date(endDate.setHours(23, 59, 59, 999));

        startAr.push(startDate);
        endAr.push(endDate);

        today = new Date(startDate);
      }

      for (let i = 0; i < startAr.length; i++) {
        let startval = -1;
        let endval = -1;
        for (let j = 0; j < labels.length; j++) {
          const labelDate = new Date(labels[j]);
          if (labelDate.getTime() >= startAr[i].getTime() && labelDate.getTime() <= endAr[i].getTime()) {
            if (startval === -1) {
              startval = j;
            }
            endval = j;
          }
        }

        // if (startval !== -1 && endval !== -1) {
        const s = startAr[i];
        const e = endAr[i];
        newLabels.push([
          `${s.getMonth() < 9 ? `0${s.getMonth() + 1}` : s.getMonth() + 1}-${s.getDate() < 10 ? `0${s.getDate()}` : s.getDate()}`,
          t('DASHBOARD_CHART_WEEKLY_TO'),
          `${e.getMonth() < 9 ? `0${e.getMonth() + 1}` : e.getMonth() + 1}-${e.getDate() < 10 ? `0${e.getDate()}` : e.getDate()}`
        ]);
        newUploading.push(getAggregate(uploading, startval, endval));
        newDigitized.push(getAggregate(digitized, startval, endval));
        newToreview.push(getAggregate(toreview, startval, endval));
        newTovalidate.push(getAggregate(tovalidate, startval, endval));
        newToexport.push(getAggregate(toexport, startval, endval));
        newExported.push(getAggregate(exported, startval, endval));
        newAmountExported.push(getAggregate(amountExported, startval, endval));
        newRejected.push(getAggregate(rejected, startval, endval));
        newErrored.push(getAggregate(errored, startval, endval));
        // }
      }

      return {
        labels: newLabels.reverse(),
        uploading: newUploading.reverse(),
        digitized: newDigitized.reverse(),
        toreview: newToreview.reverse(),
        tovalidate: newTovalidate.reverse(),
        toexport: newToexport.reverse(),
        exported: newExported.reverse(),
        rejected: newRejected.reverse(),
        amountExported: newAmountExported.reverse(),
        errored: newErrored.reverse()
      };

    case 2: // Monthly
      start = new Date(year, month, 1);
      end = new Date(year, month + 1, 0);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 1; i < 12; i++) {
        start = new Date(year, month - i, 1);
        end = new Date(year, month + 1 - i, 0);
        end = new Date(end.setHours(23, 59, 59, 999));

        startAr.push(start);
        endAr.push(end);
      }

      for (let i = 0; i < startAr.length; i++) {
        let startval = -1;
        let endval = -1;
        for (let j = 0; j < labels.length; j++) {
          const labelDate = new Date(labels[j]);
          if (labelDate.getTime() >= startAr[i].getTime() && labelDate.getTime() <= endAr[i].getTime()) {
            if (startval === -1) {
              startval = j;
            }
            endval = j;
          }
        }

        // if (startval !== -1 && endval !== -1) {
        const s = startAr[i];
        newLabels.push(`${s.getFullYear()}-${s.getMonth() + 1 < 10 ? `0${s.getMonth() + 1}` : s.getMonth() + 1}`);
        newUploading.push(getAggregate(uploading, startval, endval));
        newDigitized.push(getAggregate(digitized, startval, endval));
        newToreview.push(getAggregate(toreview, startval, endval));
        newTovalidate.push(getAggregate(tovalidate, startval, endval));
        newToexport.push(getAggregate(toexport, startval, endval));
        newExported.push(getAggregate(exported, startval, endval));
        newAmountExported.push(getAggregate(amountExported, startval, endval));
        newRejected.push(getAggregate(rejected, startval, endval));
        newErrored.push(getAggregate(errored, startval, endval));
        // }
      }

      return {
        labels: newLabels.reverse(),
        uploading: newUploading.reverse(),
        digitized: newDigitized.reverse(),
        toreview: newToreview.reverse(),
        tovalidate: newTovalidate.reverse(),
        toexport: newToexport.reverse(),
        exported: newExported.reverse(),
        amountExported: newAmountExported.reverse(),
        rejected: newRejected.reverse(),
        errored: newErrored.reverse()
      };

    case 3: // Quarterly
      monthQuarter = Math.floor(month / 3) + 1;

      start = new Date(year, (monthQuarter - 1) * 3, 1);
      end = new Date(year, monthQuarter * 3, 0);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 0; i < 11; i++) {
        startDate = new Date(start);
        endDate = new Date(start);

        start = new Date(startDate.setMonth(startDate.getMonth() - 3));
        end = new Date(endDate.setDate(endDate.getDate() - 1));
        end = new Date(end.setHours(23, 59, 59, 999));

        if (start.getFullYear() >= 2020) {
          startAr.push(start);
          endAr.push(end);
        }
      }

      for (let i = 0; i < startAr.length; i++) {
        let startval = -1;
        let endval = -1;
        for (let j = 0; j < labels.length; j++) {
          const labelDate = new Date(labels[j]);
          if (labelDate.getTime() >= startAr[i].getTime() && labelDate.getTime() <= endAr[i].getTime()) {
            if (startval === -1) {
              startval = j;
            }
            endval = j;
          }
        }

        // if (startval !== -1 && endval !== -1) {
        const s = startAr[i];
        newLabels.push(`${s.getFullYear()}-Q${Math.floor(s.getMonth() / 3) + 1}`);
        newUploading.push(getAggregate(uploading, startval, endval));
        newDigitized.push(getAggregate(digitized, startval, endval));
        newToreview.push(getAggregate(toreview, startval, endval));
        newTovalidate.push(getAggregate(tovalidate, startval, endval));
        newToexport.push(getAggregate(toexport, startval, endval));
        newExported.push(getAggregate(exported, startval, endval));
        newAmountExported.push(getAggregate(amountExported, startval, endval));
        newRejected.push(getAggregate(rejected, startval, endval));
        newErrored.push(getAggregate(errored, startval, endval));
        // }
      }

      return {
        labels: newLabels.reverse(),
        uploading: newUploading.reverse(),
        digitized: newDigitized.reverse(),
        toreview: newToreview.reverse(),
        tovalidate: newTovalidate.reverse(),
        toexport: newToexport.reverse(),
        exported: newExported.reverse(),
        amountExported: newAmountExported.reverse(),
        rejected: newRejected.reverse(),
        errored: newErrored.reverse()
      };

    case 4: // Anually
      start = new Date(year, 0, 1);
      end = new Date(year, 11, 31);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 1; year - i >= 2017 && i < 11; i++) {
        start = new Date(year - i, 0, 1);
        end = new Date(year - i, 11, 31);
        end = new Date(end.setHours(23, 59, 59, 999));

        startAr.push(start);
        endAr.push(end);
      }

      for (let i = 0; i < startAr.length; i++) {
        let startval = -1;
        let endval = -1;
        for (let j = 0; j < labels.length; j++) {
          const labelDate = new Date(labels[j]);
          if (labelDate.getTime() >= startAr[i].getTime() && labelDate.getTime() <= endAr[i].getTime()) {
            if (startval === -1) {
              startval = j;
            }
            endval = j;
          }
        }

        // if (startval !== -1 && endval !== -1) {
        const s = startAr[i];
        newLabels.push(`${s.getFullYear()}`);
        newUploading.push(getAggregate(uploading, startval, endval));
        newDigitized.push(getAggregate(digitized, startval, endval));
        newToreview.push(getAggregate(toreview, startval, endval));
        newTovalidate.push(getAggregate(tovalidate, startval, endval));
        newToexport.push(getAggregate(toexport, startval, endval));
        newExported.push(getAggregate(exported, startval, endval));
        newAmountExported.push(getAggregate(amountExported, startval, endval));
        newRejected.push(getAggregate(rejected, startval, endval));
        newErrored.push(getAggregate(errored, startval, endval));
        // }
      }

      return {
        labels: newLabels.reverse(),
        uploading: newUploading.reverse(),
        digitized: newDigitized.reverse(),
        toreview: newToreview.reverse(),
        tovalidate: newTovalidate.reverse(),
        toexport: newToexport.reverse(),
        exported: newExported.reverse(),
        amountExported: newAmountExported.reverse(),
        rejected: newRejected.reverse(),
        errored: newErrored.reverse()
      };

    default:
      date = today.getDate();

      start = new Date(year, month, date);
      end = new Date(year, month, date);
      end = new Date(end.setHours(23, 59, 59, 999));

      startAr.push(start);
      endAr.push(end);
      for (let i = 1; i < 12; i++) {
        start = new Date(year, month, date - i);
        end = new Date(year, month, date - i);
        end = new Date(end.setHours(23, 59, 59, 999));

        startAr.push(start);
        endAr.push(end);
      }

      for (let i = 0; i < startAr.length; i++) {
        let startval = -1;
        let endval = -1;
        for (let j = 0; j < labels.length; j++) {
          const labelDate = new Date(labels[j]);
          if (labelDate.getTime() >= startAr[i].getTime() && labelDate.getTime() <= endAr[i].getTime()) {
            if (startval === -1) {
              startval = j;
            }
            endval = j;
          }
        }

        // if (startval !== -1 && endval !== -1) {
        const s = startAr[i];
        newLabels.push(`${s.getMonth() < 9 ? `0${s.getMonth() + 1}` : s.getMonth() + 1}-${s.getDate() < 10 ? `0${s.getDate()}` : s.getDate()}`);
        newUploading.push(getAggregate(uploading, startval, endval));
        newDigitized.push(getAggregate(digitized, startval, endval));
        newToreview.push(getAggregate(toreview, startval, endval));
        newTovalidate.push(getAggregate(tovalidate, startval, endval));
        newToexport.push(getAggregate(toexport, startval, endval));
        newExported.push(getAggregate(exported, startval, endval));
        newAmountExported.push(getAggregate(amountExported, startval, endval));
        newRejected.push(getAggregate(rejected, startval, endval));
        newErrored.push(getAggregate(errored, startval, endval));
        // }
      }

      return {
        labels: newLabels.reverse(),
        uploading: newUploading.reverse(),
        digitized: newDigitized.reverse(),
        toreview: newToreview.reverse(),
        tovalidate: newTovalidate.reverse(),
        toexport: newToexport.reverse(),
        exported: newExported.reverse(),
        amountExported: newAmountExported.reverse(),
        rejected: newRejected.reverse(),
        errored: newErrored.reverse()
      };
  }
};

export const arrangeChartData = (graph_data,t)=>{
  const digitized=0, uploading=1, toreview=2, tovalidate=3, toexport=4, exported=5, errored=6, rejected=7;
  const daily=0, weekly=1, monthly=2, quarterly=3, annualy=4;
  let yAxis = t('DASHBOARD_CHART_Y_AXIS_LABEL');
  let data = {
    [daily]:{
      [digitized]:[],
      [uploading]:[],
      [toreview]:[],
      [tovalidate]:[],
      [toexport]:[],
      [exported]:[],
      [errored]:[],
      [rejected]:[],
    },
    [weekly]:{
      [digitized]:[],
      [uploading]:[],
      [toreview]:[],
      [tovalidate]:[],
      [toexport]:[],
      [exported]:[],
      [errored]:[],
      [rejected]:[],
    },
    [monthly]:{
      [digitized]:[],
      [uploading]:[],
      [toreview]:[],
      [tovalidate]:[],
      [toexport]:[],
      [exported]:[],
      [errored]:[],
      [rejected]:[],
    },
    [quarterly]:{
      [digitized]:[],
      [uploading]:[],
      [toreview]:[],
      [tovalidate]:[],
      [toexport]:[],
      [exported]:[],
      [errored]:[],
      [rejected]:[],
    },
    [annualy]:{
      [digitized]:[],
      [uploading]:[],
      [toreview]:[],
      [tovalidate]:[],
      [toexport]:[],
      [exported]:[],
      [errored]:[],
      [rejected]:[],
    }
  }

  function arrangeEachData(period, period_value, new_period, new_value){
    for(let index = 0; index<12; index++){
      let value = graph_data[period][index]?.count[period_value] || 0;
      let label = graph_data[period][index].label;
      data[new_period][new_value][index] = {
       [yAxis]:value,
       [new_period === 0?'Date':new_period === 1?'Week':new_period=== 2?'Month':new_period===3?'Quarter':'Year']:label
      }
   }
  }

  //Arrange Daily data
  arrangeEachData('daily','digitised',daily,digitized)
  arrangeEachData('daily','uploading',daily,uploading)
  arrangeEachData('daily','toreview',daily,toreview)
  arrangeEachData('daily','tovalidate',daily,tovalidate)
  arrangeEachData('daily','toexport',daily,toexport)
  arrangeEachData('daily','exported',daily,exported)
  arrangeEachData('daily','errored',daily,errored)
  arrangeEachData('daily','errored',daily,rejected)

  //Arrange Weekly data
  arrangeEachData('weekly','digitised',weekly,digitized)
  arrangeEachData('weekly','uploading',weekly,uploading)
  arrangeEachData('weekly','toreview',weekly,toreview)
  arrangeEachData('weekly','tovalidate',weekly,tovalidate)
  arrangeEachData('weekly','toexport',weekly,toexport)
  arrangeEachData('weekly','exported',weekly,exported)
  arrangeEachData('weekly','errored',weekly,errored)
  arrangeEachData('weekly','errored',weekly,rejected)

   //Arrange Monthly data
  arrangeEachData('monthly','digitised',monthly,digitized)
  arrangeEachData('monthly','uploading',monthly,uploading)
  arrangeEachData('monthly','toreview',monthly,toreview)
  arrangeEachData('monthly','tovalidate',monthly,tovalidate)
  arrangeEachData('monthly','toexport',monthly,toexport)
  arrangeEachData('monthly','exported',monthly,exported)
  arrangeEachData('monthly','errored',monthly,errored)
  arrangeEachData('monthly','errored',monthly,rejected)

  //Arrange Quarterly
  arrangeEachData('quarterly','digitised',quarterly,digitized)
  arrangeEachData('quarterly','uploading',quarterly,uploading)
  arrangeEachData('quarterly','toreview',quarterly,toreview)
  arrangeEachData('quarterly','tovalidate',quarterly,tovalidate)
  arrangeEachData('quarterly','toexport',quarterly,toexport)
  arrangeEachData('quarterly','exported',quarterly,exported)
  arrangeEachData('quarterly','errored',quarterly,errored)
  arrangeEachData('quarterly','errored',quarterly,rejected)

  //Arrange annualy data
  arrangeEachData('annualy','digitised',annualy,digitized)
  arrangeEachData('annualy','uploading',annualy,uploading)
  arrangeEachData('annualy','toreview',annualy,toreview)
  arrangeEachData('annualy','tovalidate',annualy,tovalidate)
  arrangeEachData('annualy','exported',annualy,exported)
  arrangeEachData('annualy','errored',annualy,errored)
  arrangeEachData('annualy','errored',annualy,rejected)
  return data;
}

export const chartDataTitle = ()=>{
  const digitized=0, uploading=1, toreview=2, tovalidate=3, toexport=4, exported=5, errored=6, rejected=7;
  const daily=0, weekly=1, monthly=2, quarterly=3, annualy=4;
  let data = {
    [daily]:{
      [digitized]:'DASHBOARD_UPLOADED_DAYS_TITLE',
      [uploading]:'DASHBOARD_UPLOADING_DAYS_TITLE',
      [toreview]:'DASHBOARD_TO_REVIEW_DAYS_TITLE',
      [tovalidate]:'DASHBOARD_TO_VALIDATE_DAYS_TITLE',
      [toexport]:'DASHBOARD_TO_EXPORT_DAYS_TITLE',
      [exported]:'DASHBOARD_EXPORTED_DAYS_TITLE',
      [errored]:'DASHBOARD_ERRORED_DAYS_TITLE',
      [rejected]:'DASHBOARD_REJECTED_DAYS_TITLE',
    },
    [weekly]:{
      [digitized]:'DASHBOARD_UPLOADED_WEEKS_TITLE',
      [uploading]:'DASHBOARD_UPLOADING_WEEKS_TITLE',
      [toreview]:'DASHBOARD_TO_REVIEW_WEEKS_TITLE',
      [tovalidate]:'DASHBOARD_TO_VALIDATE_WEEKS_TITLE',
      [toexport]:'DASHBOARD_TO_EXPORT_WEEKS_TITLE',
      [exported]:'DASHBOARD_EXPORTED_WEEKS_TITLE',
      [errored]:'DASHBOARD_ERRORED_WEEKS_TITLE',
      [rejected]:'DASHBOARD_REJECTED_WEEKS_TITLE',
    },
    [monthly]:{
      [digitized]:'DASHBOARD_UPLOADED_MONTHS_TITLE',
      [uploading]:'DASHBOARD_UPLOADING_MONTHS_TITLE',
      [toreview]:'DASHBOARD_TO_REVIEW_MONTHS_TITLE',
      [tovalidate]:'DASHBOARD_TO_VALIDATE_MONTHS_TITLE',
      [toexport]:'DASHBOARD_TO_EXPORT_MONTHS_TITLE',
      [exported]:'DASHBOARD_EXPORTED_MONTHS_TITLE',
      [errored]:'DASHBOARD_ERRORED_MONTHS_TITLE',
      [rejected]:'DASHBOARD_REJECTED_MONTHS_TITLE',
    },
    [quarterly]:{
      [digitized]:'DASHBOARD_UPLOADED_QUARTERS_TITLE',
      [uploading]:'DASHBOARD_UPLOADING_QUARTERS_TITLE',
      [toreview]:'DASHBOARD_TO_REVIEW_QUARTERS_TITLE',
      [tovalidate]:'DASHBOARD_TO_VALIDATE_QUARTERS_TITLE',
      [toexport]:'DASHBOARD_TO_EXPORT_QUARTERS_TITLE',
      [exported]:'DASHBOARD_EXPORTED_QUARTERS_TITLE',
      [errored]:'DASHBOARD_ERRORED_QUARTERS_TITLE',
      [rejected]:'DASHBOARD_REJECTED_QUARTERS_TITLE',
    },
    [annualy]:{
      [digitized]:'DASHBOARD_UPLOADED_YEARS_TITLE',
      [uploading]:'DASHBOARD_UPLOADING_YEARS_TITLE',
      [toreview]:'DASHBOARD_TO_REVIEW_YEARS_TITLE',
      [tovalidate]:'DASHBOARD_TO_VALIDATE_YEARS_TITLE',
      [toexport]:'DASHBOARD_TO_EXPORT_YEARS_TITLE',
      [exported]:'DASHBOARD_EXPORTED_YEARS_TITLE',
      [errored]:'DASHBOARD_ERRORED_YEARS_TITLE',
      [rejected]:'DASHBOARD_REJECTED_YEARS_TITLE',
    }
  }
  return data;
}

export function formatChartNumber(num){
  const t = 'K', m = 'M', b = 'B'
  let whole = 0;
  let fraction = 0;
  let suffix = t;
  if( num >= 1000 && num < 1000000 ){
    whole = Math.floor(num/1000);
    fraction = Math.floor((num/100)%10);
    suffix = t;
  }
  else if( num >= 1000000 && num < 999999999 ){
    whole = Math.floor(num/1000000);
    fraction = Math.floor((num/100000)%10);
    suffix = m;
  }
  else if( num >= 1000000000 && num < 999999999999 ){
    whole = Math.floor(num/1000000000);
    fraction = Math.floor((num/10000000)%10);
    suffix = b;
  }
  else{
    return num;
  }
  return `${whole}.${fraction} ${suffix}`;

}
