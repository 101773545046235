/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function AnnotatedIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_963_3676)" fill="#27AE60">
        <path d="M4.658 13.475L1.183 10 0 11.175l4.658 4.658 10-10-1.175-1.175-8.825 8.817zM19.658 5.833l-10 10L8.5 14.675 7.825 14 9 12.817l.658.658 8.825-8.817 1.175 1.175z" />
      </g>
      <defs>
        <clipPath id="clip0_963_3676">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AnnotatedIcon
