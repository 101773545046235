import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import { Container } from '@mui/material';

import Page from 'src/shared/screens/Page';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import Results from 'src/logs/components/DimensionLogsListResults/DimensionLogsListResults';

import authService from 'src/utils/authService';
import { axiosHeaders } from 'src/utils/helpers';
import { translateMapLogs } from 'src/config';
import ConfigContext from 'src/contexts/ConfigContext';
import theme from "src/theme";

const DimensionLogsListView = (props) => {
  const { user, enqueueSnackbar, match, history } = props;
  const {
    dim, type, code
  } = match.params;
  const { LOGIN_PANEL_URL, API, BRAND_NAME } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [fetchingLogs, setFetchingLogs] = useState(true);

  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);

  const getLogs = async (page = 0, limit = 10000, search = '', filter = '') => {
    if (!authService.validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLogs(true);

    try {
      const url = `${API.getDimensionLogsByCompany}${dim}/company/${user.companyID}${code !== undefined
        ? `/${code}` : ''}?${type ? `type=${type}` : ''}&page=${page + 1}&limit=${limit}&order=desc${search && search !== ''
        ? `&search=${search}` : ''}${filter && filter !== '' ? `&filter=${filter}` : ''}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setLogs(response.data.data);
            setTotal(response.data.total);
          } else {
            setLogs([]);
            setTotal(0);
          }
        }
      }
    } catch (error) {
      //
    }

    setFetchingLogs(false);
    setLoading(false);
  };

  const getDimLogsByID = async (id) => {
    if (!authService.validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLogs(true);

    try {
      const url = `${API.getDimensionLogsByCompany}/${dim}/${id}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setLogs(response.data.data);
            setTotal(response.data.total);
          } else {
            setLogs([]);
            setTotal(0);
            history.push(
              `${API.getDimensionLogsByCompany}${dim}/company/${encodeURIComponent(user.companyID)}`
            );
            getLogs(0, 10);
          }
        }
      }
    } catch (error) {
      history.push(
        `${API.getDimensionLogsByCompany}${dim}/company/${encodeURIComponent(user.companyID)}`
      );
    }

    setFetchingLogs(false);
    setLoading(false);
  };

  useEffect(() => {
    const id = match && match.params ? match.params.id : 0;

    if (id && id > 0) {
      getDimLogsByID(id);
    } else {
      getLogs(0, 10);
    }
  }, []);

  const dimTitle = t(translateMapLogs[dim]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Page
      style={styles.root}
      title={`${dimTitle}${type ? ` - ${type}` : ''} ${ready && t('LOG_LIST')} | ${BRAND_NAME}`}
    >
      <Container maxWidth={false} style={styles.topContainer}>
        {
          logs && (
            <Results
              {...props}
              logs={logs}
              total={total}
              getLogs={
                (p, l, s, f) => getLogs(p, l, s, f)
              }
              fetchingLogs={fetchingLogs}
              code={code}
            />
          )
        }
      </Container>
    </Page>
  );
};

export default DimensionLogsListView;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 30
  },
  topContainer: {
    paddingLeft: 40,
    paddingRight: 40
  }
};
