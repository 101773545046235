const styles = {
  container:{
    marginBlockStart:'20px',
    display:'flex',
    flexDirection:'column',
  },
  statsContent:{
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    borderRadius:'10px',
    overflow:'hidden',
  }
}

export default styles;