import styles from './style';

const LabelStatusDot = ({
  color,
  ...rest
}) => {

  return (
    <span
      style={{
        ...styles.root,
        ...styles[color]
      }}
      {...rest}
    />
  );
};

export default LabelStatusDot;
