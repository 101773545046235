import CDCDocumentEdit from 'src/cdc-document-edit/CDCDocumentEdit';
import DashboardLayout from 'src/shared/layouts/DashboardLayout';

const CDCDocumentContainer = () => {

  return (
    <DashboardLayout>
      <CDCDocumentEdit />
    </DashboardLayout>
  );
}

export default CDCDocumentContainer;
