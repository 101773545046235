import {
  useContext, useEffect, useRef, useState
} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { countries, getEmojiFlag } from 'countries-list';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

import {
  Backdrop, Box, Button, Checkbox, Fade, FormControl, FormControlLabel, FormHelperText, Grid, Grow, IconButton, Autocomplete,
  InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Paper, Popper, Select, TextField, Tooltip, Typography
} from '@mui/material';
import {
  Close as CloseIcon,
  HelpOutline as HelpOutlineIcon,
  FiberManualRecord as FiberManualRecordIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import { ERRORS, projects } from 'src/config';
import {
  axiosHeaders, getLocalisedErrorString, isActionPermitted, isBranded, permissions
} from 'src/utils/helpers';
import NewUserInviteLinkPopup from 'src/account/components/NewUserInviteLinkPopup/NewUserInviteLinkPopup';
import styles from './style';

function AddUserToCompanyForm({
  addCompanyAndUserOpen,
  handleClose,
  getAllCompanies,
}) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { API, BRAND_NAME } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;

  const [step, setStep] = useState('company');

  const [countryList, setCountryList] = useState([]);
  const [subCompanyHelpShow, setSubCompanyHelpShow] = useState(false);
  const helpIconRef = useRef(null);
  const [companyValues, setCompanyValues] = useState({});

  const [userInviteLinkOpen, setUserInviteLinkOpen] = useState(false);
  const [userInviteLink, setUserInviteLink] = useState('');

  const [isUserExist, setIsUserExist] = useState(false);

  const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/;

  const handleSubCompanyHelpClose = () => {
    setSubCompanyHelpShow(false);
  };

  const handleSubCompanyHelpOpen = () => {
    setSubCompanyHelpShow(true);
  };

  useEffect(() => {
    const countryData = [];
    Object.keys(countries).forEach((key) => {
      const data = {
        code: key,
        name: countries[key]?.name
      };
      countryData.push(data);
    });

    setCountryList(countryData);
  }, []);

  const handleEmailBlur = async (event, setErrors, handleChange, setTouched, errors, touched) => {
    setTouched({ ...touched, email: true });
    const { value } = event.target;
    if (value) {
      if (!emailRegexp.test(value)) {
        setErrors({
          ...errors,
          email: t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')
        });
        return;
      }
      try {
        const response = await Axios.post(
          `${API.companyAndUserDetails}`,
          { email: value },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success && response.data.data) {
          setIsUserExist(response.data.data.detailsRegistered);
          if (response.data.data.detailsRegistered) {
            handleChange({ target: { name: 'firstname', value: response.data.data.firstName } });
            handleChange({ target: { name: 'lastname', value: response.data.data.lastName } });
          } else {
            handleChange({ target: { name: 'firstname', value: '' } });
            handleChange({ target: { name: 'lastname', value: '' } });
          }
        }
      } catch (error) {
        setIsUserExist(false);
      }
    } else {
      setIsUserExist(false);
      setErrors({
        ...errors,
        email: t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')
      });
    }
  };

  const handleBlurOthers = (e, values, handleBlur, setErrors, setTouched, errors, touched) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
    if (values.email) {
      handleBlur(e);
    } else {
      setErrors({
        ...errors,
        email: t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')
      });
    }
  };

  const closeUserInviteLinkPopup = () => {
    setUserInviteLinkOpen(false);
    handleClose();
    getAllCompanies();
    history.push('/account', { tab: 3 });
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      style={styles.modal}
      open={addCompanyAndUserOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={addCompanyAndUserOpen}>
        <div style={styles.paper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {ready && t(step === 'company' ? 'ACCOUNT_SETTINGS_COMPANIES_ADD_NEW_COMPANY' : 'ACCOUNT_SETTINGS_COMPANIES_ADD_USER_TO_COMPANY')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          {step === 'company' ? (
            <Formik
              initialValues={{
                isSubCompany: Boolean(isBranded(BRAND_NAME) || user?.isSubCompany),
                companyType: 'company',
                project: '',
                name: '',
                vatNumber: '',
                address: '',
                postalCode: '',
                contactNumber: '',
                country: '',
                city: ''
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_COMPANIES_NAME_REQ')),
                address: Yup.string().max(255).required(t('CREATE_COMPANY_ADDR_REQ')),
                postalCode: Yup.string().max(255).required(t('CREATE_COMPANY_POSTAL_CODE_REQ')),
                contactNumber: Yup.string().required(t('CREATE_COMPANY_CONTACT_NO_REQ'))
                  .matches(/^([+\d]\d*)$/, t('CREATE_COMPANY_CONTACT_NO_INVALID'))
                  .min(9, t('CREATE_COMPANY_CONTACT_NO_MIN'))
                  .max(16, t('CREATE_COMPANY_CONTACT_NO_MAX')),
                country: Yup.string().max(255).required(t('CREATE_COMPANY_COUNTRY_REQ')),
                city: Yup.string().max(255).required(t('CREATE_COMPANY_CITY_REQ')),
              })}
              onSubmit={async (values) => {
                const body = {
                  isSubCompany: values.isSubCompany,
                  companyType: values.companyType,
                  project: values.project,
                  name: values.name,
                  email: user?.email ? user.email : '',
                  vatNumber: values.vatNumber,
                  address: values.address,
                  postalCode: values.postalCode,
                  contactNumber: values.contactNumber,
                  country: values.country,
                  city: values.city,
                };

                if (values.isSubCompany) {
                  body.parent = user.companyID;
                }

                setCompanyValues(body);
                setStep('user');
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form
                  style={styles.root}
                  onSubmit={handleSubmit}
                >
                  <Grid style={styles.userForm}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} style={styles.fieldContainerRow}>
                        <Tooltip title={t('TOOLTIP_SUB_COMPANY_CAN_NOT_CREATE_SUB_COMPANY')} disableHoverListener={!user?.isSubCompany}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                style={styles.checkBox}
                                checked={values.isSubCompany}
                                onChange={(e) => handleChange({ target: { name: 'isSubCompany', value: e.target.checked } })}
                                name="isSubCompany"
                                disabled={isBranded(BRAND_NAME) || user?.isSubCompany}
                              />
                            )}
                            label={ready && t('ACCOUNT_SETTINGS_COMPANIES_IS_SUB_COMPANY_CHECKBOX')}
                            labelPlacement="end"
                            sx={{
                              '&.MuiFormControlLabel-root': styles.checkboxContainer,
                            }}
                          />
                        </Tooltip>
                        <IconButton
                          ref={helpIconRef}
                          style={styles.helpIconBtn}
                          onMouseEnter={handleSubCompanyHelpOpen}
                          onMouseLeave={handleSubCompanyHelpClose}
                        >
                          <HelpOutlineIcon style={styles.interfaceIcon} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} style={styles.topFieldContainer}>
                        <FormControl variant="outlined" style={styles.formControl}>
                          <InputLabel id="company-type-label">{ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}`}</InputLabel>
                          <Select
                            labelId="company-type-label"
                            value={values.companyType}
                            onChange={handleChange}
                            name="companyType"
                            label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}`}
                            required
                            sx={{
                              '&. MuiSelect-select': styles.selectStyle
                            }}
                          >
                            <MenuItem value="company" sx={styles.selectStyle}>
                              {ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE_01')}`}
                            </MenuItem>
                            <MenuItem value="agency" sx={styles.selectStyle}>
                              {ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE_02')}`}
                            </MenuItem>
                            <MenuItem value="freelancer" sx={styles.selectStyle}>
                              {ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE_03')}`}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={styles.topFieldContainer}>
                        <FormControl variant="outlined" style={styles.formControl}>
                          <InputLabel id="company-project-label">{ready && `${t('ACCOUNT_SETTINGS_COMPANIES_PROJECT')}`}</InputLabel>
                          <Select
                            labelId="company-project-label"
                            value={values.project}
                            onChange={handleChange}
                            name="project"
                            label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_PROJECT')}`}
                            sx={{
                              '& .MuiSelect-select': styles.selectStyle
                            }}
                          >
                            {projects.map((project) => (
                              <MenuItem key={project.key} value={project.key} sx={styles.selectStyle}>
                                {ready && t(project.label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{t('PROJECT_SPECIFICITY_HELPER_TEXT')}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} style={styles.topFieldContainer}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label={ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={styles.topFieldContainer}>
                        <TextField
                          error={Boolean(touched.vatNumber && errors.vatNumber)}
                          fullWidth
                          helperText={touched.vatNumber && errors.vatNumber}
                          label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_VAT_NUMBER')}`}
                          name="vatNumber"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.vatNumber}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid style={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.address && errors.address)}
                        fullWidth
                        helperText={touched.address && errors.address}
                        label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_ADDRESS')}`}
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.address}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                        <TextField
                          error={Boolean(touched.postalCode && errors.postalCode)}
                          fullWidth
                          helperText={touched.postalCode && errors.postalCode}
                          label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_POSTAL_CODE')}`}
                          name="postalCode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.postalCode}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                        <TextField
                          error={Boolean(touched.contactNumber && errors.contactNumber)}
                          fullWidth
                          helperText={touched.contactNumber && errors.contactNumber}
                          label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_CONTACT_NUMBER')}`}
                          name="contactNumber"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.contactNumber}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                        <FormControl variant="outlined" style={styles.formControl}>
                          <Autocomplete
                            fullWidth
                            name="country"
                            onChange={(e, v) => handleChange({ target: { name: 'country', value: v?.code } })}
                            value={values.country ? Object.keys(countries).map((key) => ({
                              code: key,
                              name: countries[key]?.name
                            })).find((o) => o.code === values.country) : null}
                            options={countryList}
                            getOptionLabel={(option) => `${getEmojiFlag(option.code)} ${option.name}`}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                required
                                {...params}
                                label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COUNTRY')}`}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                style={styles.selectStyle}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                        <TextField
                          error={Boolean(touched.city && errors.city)}
                          fullWidth
                          helperText={touched.city && errors.city}
                          label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_CITY')}`}
                          name="city"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.city}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                    )}
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <Button
                        variant="text"
                        style={styles.closeBtn}
                        disabled={isSubmitting}
                        onClick={handleClose}
                      >
                        {ready && t('ACCOUNT_SETTINGS_COMPANIES_CANCEL')}
                      </Button>
                      <Button
                        variant="contained"
                        style={styles.submitBtn}
                        type="submit"
                        disabled={isSubmitting || !isActionPermitted(permissions.companyAdd, user.permissions)}
                      >
                        {ready && t('ACCOUNT_SETTINGS_COMPANIES_NEXT')}
                      </Button>
                    </Box>
                  </Grid>
                </form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                email: '',
                firstname: '',
                lastname: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email(t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')).max(255).required(t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')),
                firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
                lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
              })}
              onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                const body = {
                  email: values.email,
                  firstName: values.firstname,
                  lastName: values.lastname,
                  company: user && user.companyID ? user.companyID : '',
                  role: 'admin'
                };

                try {
                  const response = await Axios.post(
                    `${API.companyAndUser}`,
                    {
                      ...companyValues,
                      ...body,
                    },
                    axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
                  );
                  if (response.data.success) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);

                    enqueueSnackbar(t('ACCOUNT_SETTINGS_ADD_COMPANY_AND_USER_SUCCESS'), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    setStep('company');
                    if (isBranded(BRAND_NAME)) {
                      setUserInviteLink(response.data.data.link);
                      setUserInviteLinkOpen(true);
                    } else {
                      handleClose();
                      getAllCompanies();
                      history.push('/account', { tab: 3 });
                    }
                  }
                } catch (error) {
                  setErrors({
                    submit: getLocalisedErrorString(ERRORS[error.response.data?.i18n], t) || getLocalisedErrorString(error.response.data?.i18n, t)
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setErrors,
                setTouched,
                isSubmitting,
                touched,
                values
              }) => (
                <form
                  style={styles.root}
                  onSubmit={handleSubmit}
                >
                  <Grid style={styles.userForm}>
                    <Grid style={styles.rowItem}>
                      <Typography variant="caption" style={styles.inputLabel}>
                        {`${ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')} *`}
                      </Typography>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        name="email"
                        onBlur={(e) => handleEmailBlur(e, setErrors, handleChange, setTouched, errors, touched)}
                        onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                        required
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid container spacing={1} style={styles.fieldContainer}>
                      <Grid item xs={12} sm={6} style={styles.rowItemComp}>
                        <Typography variant="caption" style={styles.inputLabel}>
                          {`${ready && t('ACCOUNT_SETTINGS_USERS_FIRSTNAME')} *`}
                        </Typography>
                        <TextField
                          error={Boolean(touched.firstname && errors.firstname)}
                          fullWidth
                          helperText={touched.firstname && errors.firstname}
                          name="firstname"
                          onBlur={(e) => handleBlurOthers(e, values, handleBlur, setErrors, setTouched, errors, touched)}
                          onChange={handleChange}
                          required
                          value={values.firstname}
                          disabled={isUserExist}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={styles.rowItemComp}>
                        <Typography variant="caption" style={styles.inputLabel}>
                          {`${ready && t('ACCOUNT_SETTINGS_USERS_LASTNAME')} *`}
                        </Typography>
                        <TextField
                          error={Boolean(touched.lastname && errors.lastname)}
                          fullWidth
                          helperText={touched.lastname && errors.lastname}
                          name="lastname"
                          onBlur={(e) => handleBlurOthers(e, values, handleBlur, setErrors, setTouched, errors, touched)}
                          onChange={handleChange}
                          required
                          value={values.lastname}
                          disabled={isUserExist}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                    )}
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <Button
                        variant="text"
                        style={styles.closeBtn}
                        disabled={isSubmitting}
                        onClick={() => setStep('company')}
                      >
                        {ready && t('ACCOUNT_SETTINGS_COMPANIES_PREV')}
                      </Button>
                      <Button
                        variant="contained"
                        style={styles.submitBtn}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {ready && t('ACCOUNT_SETTINGS_USERS_ADD_USER')}
                      </Button>
                    </Box>
                  </Grid>
                </form>
              )}
            </Formik>
          )}
          <Popper
            open={subCompanyHelpShow}
            anchorEl={helpIconRef.current}
            transition
            style={{ zIndex: 111111 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper style={styles.scPaper} variant="outlined">
                  <Typography style={styles.scTitle}>{t('CREATE_SUB_COMPANY_TOOLTIP_TITLE')}</Typography>
                  <Typography style={styles.scSubTitle}>{t('CREATE_SUB_COMPANY_TOOLTIP_SUBTITLE')}</Typography>
                  <List style={styles.list}>
                    <ListItem style={styles.listItem}>
                      <FiberManualRecordIcon style={styles.bulletPoint} />
                      <ListItemText
                        disableTypography
                        primary={(
                          <Typography style={styles.listItemText}>
                            {t('CREATE_SUB_COMPANY_TOOLTIP_LINE_01')}
                          </Typography>
                        )}
                      />
                    </ListItem>
                    <ListItem style={styles.listItem}>
                      <FiberManualRecordIcon style={styles.bulletPoint} />
                      <ListItemText
                        disableTypography
                        primary={(
                          <Typography style={styles.listItemText}>
                            {t('CREATE_SUB_COMPANY_TOOLTIP_LINE_02')}
                          </Typography>
                        )}
                      />
                    </ListItem>
                  </List>
                  <Typography style={styles.listItemText}>
                    {t('CREATE_SUB_COMPANY_TOOLTIP_LINE_03')}
                  </Typography>
                </Paper>
              </Grow>
            )}
          </Popper>
          <NewUserInviteLinkPopup
            isOpen={userInviteLinkOpen}
            handleClose={() => closeUserInviteLinkPopup(history)}
            link={userInviteLink}
          />
        </div>
      </Fade>
    </Modal>
  );
}

// AddUserToCompanyForm.propTypes = {
//   className: PropTypes.string,
//   addCompanyAndUserOpen: PropTypes.bool.isRequired,
//   history: PropTypes.shape({
//     push: PropTypes.func
//   }),
//   handleClose: PropTypes.func.isRequired,
//   getAllCompanies: PropTypes.func.isRequired,
// };

export default AddUserToCompanyForm;
