import { useTranslation } from 'react-i18next';

import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import theme from "src/theme";

const SwitchToTFInterfaceModal = (props) => {
  const { ready, t } = useTranslation();
  const {
    modalOpen,
    setModalOpen,
    updateInterfacePreference,
  } = props;

  return (
    <Modal
      style={styles.modal}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalOpen}>
        <div style={styles.halfPaper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {ready && t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_TITLE')}
            </Typography>
            <IconButton onClick={() => setModalOpen(false)}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid>
            <Grid style={styles.fieldContainerFirst}>
              <Typography variant="caption" style={styles.primaryTextColor}>
                {ready && t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_DESCRIPTION')}
              </Typography>
            </Grid>
          </Grid>
          <Grid style={styles.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                style={styles.cancelBtn}
                onClick={() => setModalOpen(false)}
              >
                {ready && t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_CANCEL_BTN')}
              </Button>
              <Button
                style={styles.actionBtn}
                variant="contained"
                color="secondary"
                onClick={updateInterfacePreference}
              >
                {ready && t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_PROCEED_BTN')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default SwitchToTFInterfaceModal;

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  halfPaper: {
    width: '52%',
    maxHeight: '90%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  fieldContainerFirst: {
    marginTop: 10
  },
  primaryTextColor: {
    color: '#334D6E',
  },
  userForm: {
    width: '100%'
  },
  cancelBtn: {
    minWidth: 140,
    height: 42,
    textTransform: 'none',
    color: '#334D6E',
    fontSize: 12,
    padding: '0px 0px'
  },
  actionBtn: {
    minWidth: 140,
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
    fontSize: 12,
    padding: '0px 0px',
    color: 'white',
    backgroundColor: '#3E8AFF',
    '&:hover': {
      backgroundColor: '#3E8AFF'
    }
  },
};
