const style = {
  root:{
    flex:'1',
    width:'100%',
    backgroundColor:'#F3F5F9',
    padding:'20px'
  },
  wrapper:{
    width:'100%',
    backgroundColor:'#fff',
    borderRadius:'8px',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    borderLeft:'1px solid #4C4E641F',
    display:'flex',
    flexDirection:'column',
  },
  mainContainer:{
    flex:'1',
    height:'100%',
    width:'100%',
    display:'flex',
  },
}


export default style;