import styles from './style';

const LabelStatus = ({
  style,
  color,
  children,
  ...rest
}) => {

  return (
    <span
      style={{
        ...styles.root,
        ...styles[color],
        ...style
      }}
      {...rest}
    >
      {children}
    </span>
  );
}

export default LabelStatus;
