const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      // maxWidth: '50vw',
      borderRadius: '10px'
    }
  },
  container: {
    width: '600px',
    padding: '20px 24px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#4C4E64'
  },
  closeIcon: {
    color: '#6D788D',
    height: '16px',
    width: '16px',
  },
  detailContainer: {
    padding: '15px 0px 10px 0px'
  },
  tag: {
    '& .MuiChip-label': {
      fontSize: '13px',
      fontWeight: '500',
      color: '#4C4E64'
    },
    '&.MuiButtonBase-root': {
      borderRadius: '4px !important',
      marginBottom: '4px',
    }
  }
}

export default styles;
