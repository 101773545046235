import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import {ArrowBack as BackIcon }from '@mui/icons-material';


const Header = () => {
  const { ready, t } = useTranslation();
  return (
    <Box sx={styles.root}>
      <Link style={{textDecoration:'none',
        color:'#4C4E64',
        fontSize:'16px',
        fontWeight:'500',
        gap:'10px',
        display:'flex',
        alignItems:'center',
        width:'fit-content',
      }} to="/dashboard-new"
      >
        <BackIcon style={{color:'#6D788D'}} />
        <span>{ ready && t('DASHBOARD_NAV_DIMENSIONS')}</span>
      </Link>
    </Box>
  )
}

const styles = {
  root:{
    width:'100%',
    backgroundColor:'#fff',
    borderRadius:'8px 8px 0 0',
    padding:'15px 10px',
    borderBlockEnd:'1px solid #4C4E641F'
  },
  
}

export default Header