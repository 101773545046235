import Axios from "axios";
import { FETCH_COSTCENTERS_FAIL, FETCH_COSTCENTERS_START, FETCH_COSTCENTERS_SUCCESS } from "src/actionTypes";
import { axiosHeaders } from "src/utils/helpers";

const fetchCostCentersStart = () => {
  return {
    type: FETCH_COSTCENTERS_START,
  };
};

const fetchCostCentersSuccess = (costCenters, total) => {
  return {
    type: FETCH_COSTCENTERS_SUCCESS,
    payload: { costCenters, total },
  };
};

const fetchCostCentersFail = (error) => {
  return {
    type: FETCH_COSTCENTERS_FAIL,
    payload: { error },
  };
}

export const fetchCostCenters = (companyID, page = 0, limit = 10, search = '') => {
  return async dispatch => {
    dispatch(fetchCostCentersStart());
    try {
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/dimensions/costcenters/companies/${companyID}?page=${page + 1}&limit=${limit}&search=${search}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        dispatch(fetchCostCentersSuccess(response.data.data, response.data.total));
      } else {
        dispatch(fetchCostCentersFail(response.data.message));
      }
    } catch (error) {
      dispatch(fetchCostCentersFail(error.response.data.message));
    }
  };
};
