const styles = {
  pdfFrame: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
    backgroundColor: 'gray'
  },
};

export default styles;
