import theme from 'src/theme';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    zIndex: 9999999
  },
  paper: {
    width: '70%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: theme.palette.icon.close
  },
  addIcon: {
    height: 20,
    width: 20,
    marginRight: 4,
    color: theme.palette.secondary.main,
  },
  settingsIcon: {
    height: 20,
    width: 20,
    marginRight: 4,
    color: '#bebebe',
  },
  inputStyle: {
    backgroundColor: '#fff',
    borderRadius: 2,
  },
  inputRoot: {
    height: '36px',
    margin: '16px 0px'
  },
  actionBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  deleteIcon: {
    fontSize: 16,
    color: '#F7685B',
    marginLeft: 16
  },
  modalFooter: {
    marginTop: 16
  },
  cancelBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    width: 108,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
  },
  saveBtn: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 16,
    width: 108,
    height: 36,
    backgroundColor: theme.palette.button.action,
  },
  tableHeadCell: {
    borderBottom: '1px #000 solid',
  },
  tableBodyCell: {
    borderBottom: 'none',
  },
};

export default styles;
