import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import {
  Close as CloseIcon, FileCopyOutlined as CopyIcon, CheckCircleOutline as CheckIcon
} from '@mui/icons-material';

import styles from './style';

const NewUserInviteLinkPopup = (props) => {
  const { t } = useTranslation();

  const { isOpen, handleClose, link } = props;

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    copy(link, {
      onCopy: setCopied(true)
    });
  };

  return (
    <Modal
      style={styles.modal}
      open={isOpen}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Grid style={styles.paper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {t('USER_INVITE_LINK_TITLE')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Typography variant="caption" style={styles.primaryTextColor}>
            {t('USER_INVITE_LINK_INSTRUCTION')}
          </Typography>
          <Grid style={styles.detailRow}>
            <input
              fullWidth
              type="text"
              value={link}
              style={styles.inputField}
              disabled
            />
            <IconButton onClick={copyToClipboard}>
              <CopyIcon />
            </IconButton>
            {copied && <CheckIcon style={styles.checkIcon} />}
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default NewUserInviteLinkPopup;
