import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
import Axios from 'axios';

import {
  Button, CircularProgress, Grid, IconButton, Typography
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';

import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import { appendContactSupport } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from '../style';

const APIIntegration = () => {
  const { API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [appError, setAppError] = useState(false);

  const [apiAccessToken, setAPIAccessToken] = useState('');
  const [apiRefreshToken, setAPIRefreshToken] = useState('');
  const [apiKeyToken, setAPIKeyToken] = useState('');
  const [apiKeyRefreshToken, setAPIKeyRefreshToken] = useState('');

  const [copyObj, setCopyObj] = useState({
    apiAccessToken: false,
    apiRefreshToken: false,
  });

  const onChangeCopy = (prop) => {
    const iCopyObj = {
      apiAccessToken: false,
      apiRefreshToken: false,
    };

    iCopyObj[prop] = true;

    setCopyObj(iCopyObj);
  };

  const copyToClipboard = (prop, value) => {
    copy(value, {
      onCopy: onChangeCopy(prop)
    });
  };

  const getTokenPairFromAccessToken = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.getTokenPairFromAccessToken}/${localStorage.getItem('PROCYS_accessToken')}`
      );
      if (response.data.success) {
        setAPIAccessToken(response.data.data.accessToken);
        setAPIRefreshToken(response.data.data.refreshToken);
        setAPIKeyToken(response.data.data.apiToken);
        setAPIKeyRefreshToken(response.data.data.apiRefreshToken);
        setLoading(false);
      } else {
        setAppError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t));
      }
    } catch (error) {
      setLoading(false);
      setAppError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t));
    }
  };

  useEffect(() => {
    getTokenPairFromAccessToken();
  }, []);

  const onCheckConnection = async () => {
    setCheckLoading(true);
    try {
      const response = await Axios.get(
        `${API.getTokenPairFromRefreshToken}/${apiRefreshToken}`
      );
      if (response.data.success) {
        setCheckLoading(false);
        enqueueSnackbar(
          t('INTEGRATIONS_API_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      } else {
        setCheckLoading(false);
        enqueueSnackbar(
          t('INTEGRATIONS_API_FAILURE'),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setCheckLoading(false);
      enqueueSnackbar(
        t('INTEGRATIONS_API_FAILURE'),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  if (appError) {
    return (
      <Grid style={styles.errorContainer}>
        <span style={styles.labelTextError}>
          {appError}
        </span>
      </Grid>
    );
  }

  const LineBreaker = () => (
    <hr style={styles.lineBreaker} />
  );

  return (
    <Grid style={styles.detailsContainer}>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_API_ACCESS_TOKEN')}</span>
        </Grid>
        <input
          type="text"
          value={apiAccessToken}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('apiAccessToken', apiAccessToken)}>
          <CopyIcon />
        </IconButton>
        {copyObj.apiAccessToken && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_API_REFRESH_TOKEN')}</span>
        </Grid>
        <input
          fullWidth
          type="text"
          value={apiRefreshToken}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('apiRefreshToken', apiRefreshToken)}>
          <CopyIcon />
        </IconButton>
        {copyObj.apiRefreshToken && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      <LineBreaker />
      <Grid style={styles.integrationBody}>
        <Typography style={styles.integrationTitle}>
          {ready && t('INTEGRATIONS_SECONDARY_API_KEY')}
        </Typography>
        <Typography style={styles.integrationDesc}>
          {ready && t('INTEGRATIONS_SECONDARY_API_KEY_DESCRIPTION')}
        </Typography>
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_API_ACCESS_TOKEN')}</span>
        </Grid>
        <input
          type="text"
          value={apiKeyToken}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('apiKeyToken', apiKeyToken)}>
          <CopyIcon />
        </IconButton>
        {copyObj.apiKeyToken && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_API_REFRESH_TOKEN')}</span>
        </Grid>
        <input
          fullWidth
          type="text"
          value={apiKeyRefreshToken}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('apiKeyRefreshToken', apiKeyRefreshToken)}>
          <CopyIcon />
        </IconButton>
        {copyObj.apiKeyRefreshToken && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      <Grid style={styles.checkContainer}>
        <Button
          variant="outlined"
          style={styles.checkConnectionBtn}
          onClick={checkLoading ? () => {} : onCheckConnection}
        >
          {ready && t('INTEGRATIONS_CHECK_CONNECTION')}
        </Button>
        {checkLoading && <CircularProgress style={styles.loadingProgress} size={20} />}
      </Grid>
    </Grid>
  );
};

export default APIIntegration;
