/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import {
  useEffect, useState, useRef, useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

import {
  Backdrop, Button, Checkbox, CircularProgress, Fade, FormControlLabel, Grid, IconButton, MenuItem, MenuList, Modal, Radio, RadioGroup, Select, Typography
} from '@mui/material';
import {
  Close as CloseIcon,
  FiberManualRecord as DotIcon,
  FiberManualRecordOutlined as DotIconOutlined
} from '@mui/icons-material';

import {
  appendContactSupport, axiosHeaders, axiosHeadersFormData, getLocalisedErrorString
} from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import {
  allFields, dimensionImportStatusCheckTimer, dimensionsMap, translateMap
} from 'src/config';
import styles from './style';

const dimensionImportSteps = [
  {
    id: 0,
    label: 'DIMENSION_IMPORT_STEP_ONE'
  },
  {
    id: 1,
    label: 'DIMENSION_IMPORT_STEP_TWO'
  },
  {
    id: 2,
    label: 'DIMENSION_IMPORT_STEP_THREE'
  }
];

const uploadInstructions = [
  {
    id: 0,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_01'
  },
  {
    id: 1,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_02'
  },
  {
    id: 2,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_03'
  }
];

const DimensionImportModal = ({
  dimensionImportModalOpen,
  handleClose,
  dimension,
  fetchDimenstions
}) => {
  const dimensionImportOptions = [
    {
      id: 0,
      title: `${translateMap[dimension]}_IMPORT_OPTION_ONE_TITLE`,
      description: `${translateMap[dimension]}_IMPORT_OPTION_ONE_DESCR`
    },
    {
      id: 1,
      title: `${translateMap[dimension]}_IMPORT_OPTION_TWO_TITLE`,
      description: `${translateMap[dimension]}_IMPORT_OPTION_TWO_DESCR`
    }
  ];
  const { API } = useContext(ConfigContext);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [importOption, setImportOption] = useState(-1);
  const [importDimensionFile, setImportDimensionFile] = useState(null);
  const [dimensionDataColumns, setDimensionDataColumns] = useState([]);
  const [fieldsMap, setFieldsMap] = useState({});
  const [fields, setFields] = useState([]);
  const [mapFieldsStep, setMapFieldsStep] = useState(1);
  const [updateField, setUpdateField] = useState(dimension === 'supplier' ? 'creditorCode' : 'code');
  const [importStatus, setImportStatus] = useState('');
  const [importProgress, setImportProgress] = useState('0');
  const [importStatusCheckCode, setImportStatusCheckCode] = useState(null);
  const intervalId = useRef(null);

  const onModalClose = () => {
    if (!loading) {
      handleClose();
      setCurrentTab(0);
      setImportOption(-1);
      setImportDimensionFile(null);
      setDimensionDataColumns([]);
      setFieldsMap({});
      setFields([]);
      setMapFieldsStep(1);
    }
  };

  const populateFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/${dimension}/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setFields(response.data.data);
      }
    } catch (error) {
      setFields([]);
    }
  };

  useEffect(() => {
    populateFields();
  }, [dimension, user.companyID]);

  const handleImportOptionChange = (e) => {
    setImportOption(parseInt(e.target.value, 10));
    setCurrentTab(1);
  };

  const moveToTab = (tab) => {
    if (tab === 1 && importOption === -1) {
      enqueueSnackbar(t('DIMENSION_IMPORT_SELECT_IMPORT_OPTION_WARN'), {
        variant: 'error',
        persist: false
      });
    } else if (tab === 2 && importDimensionFile === null) {
      enqueueSnackbar(t('DIMENSION_IMPORT_FILE_UPLOAD_WARN'), {
        variant: 'error',
        persist: false
      });
    } else {
      setCurrentTab(tab);
    }
  };

  const uploadDimensionsFile = async (e) => {
    setLoading(true);
    const { files } = e.target;
    const url = `${API.dimensions}${dimensionsMap[dimension]}/import/map`;
    if (files.length > 0) {
      const file = files[0];
      setImportDimensionFile(file);
      if (file) {
        const data = new FormData();
        data.append('importDimensionFile', file);
        try {
          const response = await Axios.post(
            url,
            data,
            axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
          );
          if (response.data.success) {
            enqueueSnackbar(t('DIMENSION_IMPORT_FILE_UPLOAD_SUCCESS'), {
              variant: 'success',
              persist: false
            });
            if (response.data.data?.columns) {
              setDimensionDataColumns(response.data.data?.columns);
            }
            const parsedFieldsMap = JSON.parse(response.data.data?.fieldsMap);
            delete parsedFieldsMap[''];
            const newFieldsMap = {};
            Object.keys(parsedFieldsMap).forEach((key) => {
              const indexOfColumn = response.data.data?.columns.findIndex((o) => o === parsedFieldsMap[key]);
              if (indexOfColumn !== -1) {
                newFieldsMap[key] = indexOfColumn;
              }
            });
            setFieldsMap(newFieldsMap);
            setLoading(false);
            setCurrentTab(2);
          } else {
            enqueueSnackbar(response.data.message, {
              variant: 'error',
              persist: false
            });
            setLoading(false);
          }
        } catch (error) {
          enqueueSnackbar(appendContactSupport(
            window.config.support_email,
            getLocalisedErrorString(error?.response?.data?.i18n || 'DIMENSION_IMPORT_FILE_UPLOAD_FAIL', t),
            t
          ), {
            variant: 'error',
            persist: true
          });
          setLoading(false);
        }
      }
    } else {
      enqueueSnackbar(t('DIMENSION_IMPORT_FILE_UPLOAD_WARN'), {
        variant: 'warning',
        persist: false
      });
      setLoading(false);
    }
  };

  const handleChangeFieldMap = (e, key) => {
    const val = e.target.value;
    setFieldsMap((prevState) => ({ ...prevState, [key]: val === 0 ? undefined : e.target.value - 1 }));
  };

  const checkImportStatus = async (code = importStatusCheckCode) => {
    if (code) {
      const url = `${API.processes}${code}/status`;
      try {
        const response = await Axios.get(
          url,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          setImportStatus(response.data.data.status);
          setImportProgress(response.data.data.progress);
          if (response.data.data.completed) {
            clearInterval(intervalId.current);
            fetchDimenstions();
            setImporting(false);
            setLoading(false);
            setImportStatusCheckCode(null);
            setImportStatus('');
            setImportProgress('0');
            enqueueSnackbar(t(`${translateMap[dimension]}_IMPORT_SUCCESS`), {
              variant: 'success',
              persist: false
            });
            onModalClose();
          }
        } else if (!response.data.success || response.data.data.status === 'PROCESS_STATUS_ERROR') {
          clearInterval(intervalId.current);
          setImporting(false);
          setLoading(false);
          setImportStatusCheckCode(null);
          setImportStatus('');
          setImportProgress('0');
          enqueueSnackbar(`${t(`${translateMap[dimension]}_IMPORT_FAILED`)} ${response.data.data.errMsg}`, {
            variant: 'error',
            persist: false
          });
        }
      } catch (error) {
        clearInterval(intervalId.current);
        setImporting(false);
        setLoading(false);
        setImportStatusCheckCode(null);
        setImportStatus('');
        setImportProgress('0');
        enqueueSnackbar(t(`${translateMap[dimension]}_IMPORT_FAILED`), {
          variant: 'error',
          persist: false
        });
      }
    }
  };

  const onStartInterval = (code) => {
    intervalId.current = setInterval(() => {
      checkImportStatus(code);
    }, dimensionImportStatusCheckTimer);
  };

  const uploadDimensions = async () => {
    setImporting(true);
    setLoading(true);
    const url = `${API.dimensions}${dimensionsMap[dimension]}/import`;
    const data = new FormData();
    const fieldsMapArray = Object.entries(fieldsMap)
      .filter(([key, val]) => !(key === undefined || val === undefined))
      .map(([key, val]) => ({ [key]: val }));
    data.append('importDimensionFile', importDimensionFile);
    data.append('fieldsMap', JSON.stringify(fieldsMapArray));
    if (importOption === 1) {
      data.append('updateField', updateField);
    }
    try {
      const response = await Axios.post(
        url,
        data,
        axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
      );
      if (response.data.success) {
        if (response.data.data.processId) {
          setImportStatusCheckCode(response.data.data.processId);
          setTimeout(() => {
            onStartInterval(response.data.data.processId);
          }, 500);
        } else {
          fetchDimenstions();
          setImporting(false);
          setLoading(false);
          enqueueSnackbar(t(`${translateMap[dimension]}_IMPORT_SUCCESS`), {
            variant: 'success',
            persist: false
          });
          onModalClose();
        }
      } else {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
          persist: false
        });
        setImporting(false);
        setLoading(false);
      }
    } catch (e) {
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(e?.response?.data?.i18n || `${translateMap[dimension]}_IMPORT_FAILED`, t),
        t
      ), {
        variant: 'error',
        persist: true
      });
      setImporting(false);
      setLoading(false);
    }
  };

  const handleConfirmFieldsMap = () => {
    if (importOption === 0) {
      uploadDimensions();
    } else {
      setMapFieldsStep(2);
    }
  };

  const handleCheckBoxClick = (key) => {
    setUpdateField(key);
  };

  const handleUpdateFieldSelect = () => {
    uploadDimensions();
  };

  const loadingIndicator = (
    <Grid style={styles.loaderContainer}>
      <CircularProgress color="secondary" />
      {importing ? (
        <>
          <Typography style={styles.progressText}>{`${importProgress || 0} %`}</Typography>
          <Typography style={styles.loaderText}>{t(importStatus)}</Typography>
        </>
      ) : (
        <Typography style={styles.loaderText}>{t('DIMENSION_IMPORT_FILE_UPLOAD_LOADING')}</Typography>
      )}
    </Grid>
  );

  const tabs = (
    <Grid style={styles.tabsSection}>
      {dimensionImportSteps.map((step) => (
        <Grid key={step.id} style={styles.tab}>
          <Button
            onClick={() => moveToTab(step.id)}
            style={currentTab === step.id ? styles.selectedTabButton : styles.tabButton}
          >
            <Grid style={styles.tabLabelContainer}>
              {currentTab === step.id ? <DotIcon style={styles.dotIconSelected} /> : <DotIconOutlined style={styles.dotIcon} />}
              <Typography align="center" style={currentTab === step.id ? styles.selectedTabLabel : styles.tabLabel}>
                {t(step.label)}
              </Typography>
            </Grid>
          </Button>
        </Grid>
      ))}
    </Grid>
  );

  const importOptionsSection = (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={importOption}
      name="radio-buttons-group"
      value={importOption}
      onChange={handleImportOptionChange}
      style={styles.radioGroup}
    >
      {dimensionImportOptions.map((option) => (
        <FormControlLabel
          key={option.id}
          style={styles.importOptionContainer}
          value={option.id}
          control={(
            <Radio
              size="small"
              sx={{
                ':root': {
                  width: '75%',
                  minWidth: 370
                }
              }}
            />
            )}
          label={(
            <Grid key={option.id} style={styles.radioLabelContainer}>
              <Typography style={styles.optionTitle}>{t(option.title)}</Typography>
              <Typography style={styles.optionSubTitle}>{t(option.description)}</Typography>
            </Grid>
            )}
        />
      ))}
    </RadioGroup>
  );

  const fileUploadSection = (
    <>
      <Grid style={styles.uploadBtnContainer}>
        {importDimensionFile && <Typography style={styles.fileName}>{importDimensionFile.name}</Typography>}
        <Button
          color="secondary"
          variant="contained"
          component="label"
          style={styles.uploadBtn}
        >
          <input
            accept=".xlsx, .csv"
            type="file"
            onChange={uploadDimensionsFile}
            style={{ display: 'none', zIndex: 111111111 }}
            required
          />
          {t('DIMENSION_IMPORT_CHOOSE_FILE')}
        </Button>
      </Grid>
      <MenuList style={styles.instructionsContainer}>
        {uploadInstructions.map((instruction) => (
          <Grid key={instruction.id} style={styles.itemContainer}>
            <DotIcon style={styles.dotIconInstruction} />
            <Typography
              key={instruction.label}
              style={styles.instructionLabel}
            >
              {t(instruction.label)}
            </Typography>
          </Grid>
        ))}
      </MenuList>
      <Grid style={styles.modalFooter}>
        <Button
          variant="outlined"
          style={styles.backBtn}
          onClick={() => moveToTab(0)}
        >
          {t('DIMENSION_IMPORT_BACK')}
        </Button>
        {importDimensionFile && (
          <Button
            color="secondary"
            variant="contained"
            style={styles.uploadBtn}
            onClick={() => moveToTab(2)}
          >
            {t('DIMENSION_IMPORT_NEXT')}
          </Button>
        )}
      </Grid>
    </>
  );

  const mapFieldsSectionOne = (
    <>
      <Grid style={styles.mapFieldsSection}>
        <Grid style={styles.fieldContainer}>
          <Grid style={styles.fieldHeaderContainer}>
            <Typography style={styles.fieldHeader}>{t('DIMENSION_IMPORT_PROCYS_FIELDS')}</Typography>
          </Grid>
          <Grid style={styles.fieldHeaderContainer}>
            <Typography style={styles.fieldHeader}>{t('DIMENSION_IMPORT_IDENTIFIED_FIELDS')}</Typography>
          </Grid>
        </Grid>
        {fields.map((field) => (
          <Grid style={styles.fieldContainer} key={field.key}>
            <Button
              variant="outlined"
              style={styles.procysField}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              {t(field.label)}
            </Button>
            <Select
              name="interfacePreference"
              onChange={(e) => handleChangeFieldMap(e, field.key)}
              style={styles.fieldsSelect}
              value={fieldsMap[field.key] + 1}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
            >
              {
                ['-', ...dimensionDataColumns].map((column, index) => (
                  <MenuItem style={styles.fieldsSelectText} key={column} value={index}>
                    {column}
                  </MenuItem>
                ))
              }
            </Select>
          </Grid>
        ))}
      </Grid>
      <Grid style={styles.modalFooter}>
        <Button
          variant="outlined"
          style={styles.backBtn}
          onClick={() => moveToTab(1)}
        >
          {t('DIMENSION_IMPORT_BACK')}
        </Button>
        {importDimensionFile && (
          <Button
            color="secondary"
            variant="contained"
            style={styles.uploadBtn}
            onClick={handleConfirmFieldsMap}
          >
            {t('DIMENSION_IMPORT_CONFIRM')}
          </Button>
        )}
      </Grid>
    </>
  );

  const mapFieldsSectionTwo = (
    <>
      <Grid style={styles.mapFieldsSection}>
        <Typography style={styles.optionTitle}>{t(`${translateMap[dimension]}_IMPORT_MAP_FIELDS_02_TITLE`)}</Typography>
        <Typography style={styles.optionSubTitle}>{t(`${translateMap[dimension]}_IMPORT_MAP_FIELDS_02_DESCRIPTION`)}</Typography>
        <Grid style={styles.fieldsListContainer}>
          {allFields[dimension].filter((field) => field.isUpdateField).map((field) => (
            <FormControlLabel
              key={field.key}
              value="top"
              control={(
                <Checkbox
                  style={styles.checkBox}
                  onChange={() => handleCheckBoxClick(field.key)}
                  checked={field.key === updateField}
                />
            )}
              label={t(field.label)}
              labelPlacement="end"
              // classes={{
              //   root: classes.checkboxContainer,
              //   label: classes.checkBoxLabel
              // }}
            />
          ))}
        </Grid>
      </Grid>
      <Grid style={styles.modalFooter}>
        <Button
          variant="outlined"
          style={styles.backBtn}
          onClick={() => setMapFieldsStep(1)}
        >
          {t('DIMENSION_IMPORT_BACK')}
        </Button>
        {importDimensionFile && (
          <Button
            color="secondary"
            variant="contained"
            style={styles.uploadBtn}
            onClick={handleUpdateFieldSelect}
          >
            {t('DIMENSION_IMPORT_DONE')}
          </Button>
        )}
      </Grid>
    </>
  );

  const mapFieldsSection = (
    <>
      {mapFieldsStep === 1 && mapFieldsSectionOne}
      {mapFieldsStep === 2 && mapFieldsSectionTwo}
    </>
  );

  return (
    <>
      <Modal
        style={styles.modal}
        open={dimensionImportModalOpen}
        onClose={onModalClose}
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={dimensionImportModalOpen}>
          <div style={styles.halfPaper}>
            <Grid style={styles.modalHeader}>
              <Typography style={styles.headerTitle}>
                {t('DIMENSION_IMPORT_MODAL_HEADER')}
              </Typography>
              <IconButton onClick={onModalClose} disabled={loading}>
                <CloseIcon style={styles.closeIcon} />
              </IconButton>
            </Grid>
            {tabs}
            <Grid style={styles.modalContainer}>
              {loading ? loadingIndicator : (
                <>
                  {currentTab === 0 && importOptionsSection}
                  {currentTab === 1 && fileUploadSection}
                  {currentTab === 2 && mapFieldsSection}
                </>
              )}
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default DimensionImportModal;
