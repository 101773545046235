import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Badge, ClickAwayListener, Grid, Grow, MenuList, Paper, Popper, Typography
} from '@mui/material';
import {
  AttachFile as AttachmentIcon, FiberManualRecord as DotIcon
} from '@mui/icons-material';

import { AttachedDocumentsType, DocType } from 'src/types';
import styles from './style';

interface PropTypes {
  handleSplitBtnClick: () => void;
  splitButtonRef: MutableRefObject<null>;
  // eslint-disable-next-line no-unused-vars
  setMouseLeftSplitScrnBtn: (value: boolean) => void;
  handleMouseLeaveSplitScrnBtn: () => void;
  document: DocType;
  attachmentsOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setMouseLeftAttachmentsTooltip: (value: boolean) => void;
  handleMouseLeaveAttachmentsTooltip: () => void;
  handleAttachmentsClose: () => void;
  // eslint-disable-next-line no-unused-vars
  handleAttachmentClick: (attachment: AttachedDocumentsType) => void;
}

const AttachedDocuments = (props: PropTypes) => {
  const {
    handleSplitBtnClick,
    splitButtonRef,
    setMouseLeftSplitScrnBtn,
    handleMouseLeaveSplitScrnBtn,
    document,
    attachmentsOpen,
    setMouseLeftAttachmentsTooltip,
    handleMouseLeaveAttachmentsTooltip,
    handleAttachmentsClose,
    handleAttachmentClick
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <Badge badgeContent={document.attachedDocuments?.length} sx={styles.badge}>
        <Grid
          sx={styles.splitScreenBtn}
          onClick={handleSplitBtnClick}
          ref={splitButtonRef}
          onMouseEnter={() => setMouseLeftSplitScrnBtn(false)}
          onMouseLeave={handleMouseLeaveSplitScrnBtn}
        >
          <AttachmentIcon sx={styles.splitScreenIcon} />
        </Grid>
      </Badge>
      {attachmentsOpen && (
        <Popper
          open={attachmentsOpen}
          anchorEl={splitButtonRef.current}
          role={undefined}
          transition
          style={{ zIndex: 111111, width: '90%', maxWidth: 200, }}
          placement="bottom-start"
          onMouseEnter={() => setMouseLeftAttachmentsTooltip(false)}
          onMouseLeave={handleMouseLeaveAttachmentsTooltip}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleAttachmentsClose}>
                  <MenuList id="split-button-menu" autoFocusItem style={styles.attachmentsContainer}>
                    <Typography style={styles.attachmentTitle}>
                      {t('INVOICE_EDIT_FORM_LINKED_DOCUMENTS')}
                    </Typography>
                    {document.attachedDocuments?.map((attachment: AttachedDocumentsType) => (
                      <Grid sx={styles.itemContainer}>
                        <DotIcon style={styles.dotIcon} />
                        <Typography
                          key={attachment.name}
                          noWrap
                          style={styles.attachmentLabel}
                          onClick={() => handleAttachmentClick(attachment)}
                        >
                          {attachment.label}
                        </Typography>
                      </Grid>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default AttachedDocuments;
