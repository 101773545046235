import { alpha } from "@mui/material";
import theme from "src/theme";

const styles = {
  loadingContainer: {
    height: 400,
  },
  root: {},
  queryField: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: 0
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  userImage: {
    flexShrink: 0,
    height: 32,
    width: 32,
    color: theme.palette.icon.gray,
    backgroundColor: '#EFF4FA',
    '& svg': {
      width: 16,
      height: 16
    }
  },
  bulkDelete: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.fail
  },
  filterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  circularProgressIcon: {
    width: 20,
    height: 20
  },
  arrowCell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  pointer: {
    height: 64,
  },
  headerStyle: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.header,
  },
  cellStyle: {
    fontSize: 15,
    color: theme.palette.text.primary
  },
  arrow: {
    paddingLeft: 4
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  headerActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  action: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: `0px 4px 10px ${alpha(theme.palette.secondary.main, 0.25)}`,
    backgroundColor: theme.palette.button.action
  },
  actionInvite: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: `0px 4px 10px ${alpha(theme.palette.secondary.main, 0.25)}`,
    marginRight: 16
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  paper: {
    width: 540,
    maxHeight: '94%',
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  halfPaper: {
    width: '90%',
    maxWidth: 500,
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: theme.palette.icon.close,
  },
  row: {
    height: 48
  },
  checkbox: {
    width: 16,
    height: 16,
    color: '#D5D5D5',
    paddingLeft: 17
  },
  caption: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: theme.palette.text.header,
    paddingLeft: 24
  },
  roleLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    opacity: '0.5',
    border: theme.palette.border.secondary,
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    textTransform: 'capitalize',
    maxWidth: 108,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  statusLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    textTransform: 'capitalize',
    maxWidth: 89,
    fontSize: 14,
    fontWeight: 600,
  },
  invited: {
    color: '#ffc400',
    backgroundColor: 'rgba(255, 196, 0, 0.3)'
  },
  active: {
    color: '#3CB573',
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
  revoked: {
    color: '#919191',
    backgroundColor: 'rgba(199, 199, 199, 0.3)'
  },
  removed: {
    color: '#919191',
    backgroundColor: 'rgba(199, 199, 199, 0.3)'
  },
  statusDot: {
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    width: 29,
    cursor: 'pointer',
  },
  invitedDot: {
    backgroundColor: 'rgba(255, 196, 0, 0.3)'
  },
  activeDot: {
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
  revokedDot: {
    backgroundColor: 'rgba(199, 199, 199, 0.3)'
  },
  removedDot: {
    backgroundColor: 'rgba(199, 199, 199, 0.3)'
  },
  paginationComp: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  paginationActions: {
    marginLeft: 0
  },
  addUserDisabled: {
    marginBottom: 15
  },
  warningBanner: {
    width: '100%',
    paddingLeft: 20
  }
};

export default styles;
