/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function CustomRangeIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.31042 14.0833L8.34167 16.1417C8.54028 16.3403 8.6441 16.5885 8.65313 16.8865C8.66216 17.1844 8.55834 17.4417 8.34167 17.6583C8.14306 17.8569 7.89028 17.9562 7.58334 17.9562C7.2764 17.9562 7.02362 17.8569 6.82501 17.6583L2.92501 13.7583C2.70834 13.5417 2.60001 13.2889 2.60001 13C2.60001 12.7111 2.70834 12.4583 2.92501 12.2417L6.82501 8.34166C7.04167 8.125 7.29445 8.02118 7.58334 8.0302C7.87223 8.03923 8.12501 8.15208 8.34167 8.36875C8.54028 8.58541 8.6441 8.83819 8.65313 9.12708C8.66216 9.41597 8.55834 9.66875 8.34167 9.88541L6.31042 11.9167H19.6625L17.6313 9.88541C17.4146 9.66875 17.3063 9.41145 17.3063 9.11354C17.3063 8.81562 17.4146 8.55833 17.6313 8.34166C17.8479 8.125 18.1052 8.01666 18.4031 8.01666C18.701 8.01666 18.9583 8.125 19.175 8.34166L23.075 12.2417C23.2917 12.4583 23.4 12.7111 23.4 13C23.4 13.2889 23.2917 13.5417 23.075 13.7583L19.175 17.6583C18.9764 17.8569 18.7281 17.9608 18.4302 17.9698C18.1323 17.9788 17.875 17.875 17.6583 17.6583C17.4417 17.4417 17.3288 17.1844 17.3198 16.8865C17.3108 16.5885 17.4146 16.3312 17.6313 16.1146L19.6625 14.0833H6.31042Z" fill="#3E8AFF"/>
    </svg>
  )
}

export default CustomRangeIcon