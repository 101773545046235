import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';

type PropsType = {
  onChange?: any;
  reset?: boolean;
}
const Search = ({onChange, reset}: PropsType) => {
  const [query, setQuery] = useState<string>('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
    let value = e.target.value;
    setQuery(value);
    onChange(value);
  }

  useEffect(()=>{
    if(reset){
      setQuery('');
    }
  },[reset])
  
  return (
    <form style={{...styles.root,'position':'relative'}}>
      <SearchIcon style={{...styles.icon,position:'absolute'}} />
      <input value={query} onChange={handleChange} 
        style={styles.input} placeholder="Search" 
      /> 
    </form>
  )
}

const styles={
  root:{
    display:'flex',
    alignItems:'center',
    margin:'10px 0'
  },
  input:{
    width:'100%',
    padding:'8px 8px 8px 50px',
    border: '2px solid #C2CFE099',
    outline:'none',
    borderRadius:'4px',
  },
  icon:{
    left:'15px',
    color:'#6D788D',
  }
}

export default Search