import theme from "src/theme";

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: 36,
    paddingBottom: 36
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0
  },
  topContainer: {
    paddingBottom: 40,
    paddingLeft: 40,
    paddingRight: 40
  },
  miniContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: 5,
      paddingLeft: 15,
      paddingRight: 10,
    }
  },
  bottomMargin: {
    marginBottom: 12
  },
  topMargin: {
    marginTop: 24
  },
  summaryStats: {
    padding: '16px 20px',
    border: '1px solid #DDDDDD',
    borderRadius: 8,
    height: 132,
    boxShadow: '0px 2px 4px 0px #000000 8%'
  },
  addWidget: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9FAFC',
    borderRadius: 8,
    height: 132,
    cursor: 'pointer'
  },
  addWidgetIcon: {
    color: '#C2CFE0',
    width: 24,
    height: 24
  },
  addWidgetText: {
    color: '#90A0B7',
    marginLeft: 16,
    fontSize: 14
  },
  overviewText: {

  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dullText: {
    color: '#334D6E'
  },
  blueText: {
    color: '#3E8AFF'
  },
  greenText: {
    color: '#3CB573'
  },
  redText: {
    color: '#EB5757'
  },
  overviewNumber: {
    color: '#334D6E'
  },
  banner: {
    border: '1px solid #3E8AFF',
    padding: '6px 12px',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16
  },
  priorityIcon: {
    color: '#FF1100'
  },
  bannerText: {
    fontWeight: 500,
    color: '#334D6E',
    fontSize: 16,
    marginLeft: 10
  },
  connectAPILink: {
    fontSize: 16,
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#3E8AFF'
  },
};

export default styles;
