import theme from 'src/theme';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    // zIndex: 9999999
  },
  paper: {
    width: '96%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
    outline: 'none',
  },
  halfPaper: {
    width: '52%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: theme.palette.icon.close,
  },
  leftContainer: {
    paddingRight: 20
  },
  invoiceContainer: {
    padding: '0px',
    backgroundColor: 'gray'
  },
};

export default styles;
