const styles = {
  instruction: {
    color: '#4C4E64',
    fontSize: '13px',
    marginTop: '8px'
  },
  fieldContainer: {
    marginTop: '8px'
  },
  fieldLabel: {
    color: '#4C4E64',
    fontSize: '15px',
    fontWeight: '500'
  },
  userOptionsBtn: {
    color: '#4C4E64',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '8px',
    height: '36px',
    border: '1px #0000003b solid',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    '&:hover': {
      border: '1px #0000003b solid',
      backgroundColor: '#FFFFFF',
      boxShadow: 'none'
    }
  },
  validatorOption: {
    color: '#4C4E64',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  link: {
    textDecoration: 'underline',
    color: '#3E8AFF',
    cursor: 'pointer',
    margin: '0px 4px 0px 0px'
  },
  disabledLink: {
    textDecoration: 'underline',
    color: '#4C4E64',
    margin: '0px 4px 0px 0px'
  },
  addUserDisabled: {
    marginBottom: 10,
    marginTop: 10,
  },
  addUserDisabledText: {
    fontSize: 11,
  },
  warningBanner: {
    width: '100%',
    paddingLeft: 20,
  },
};

export default styles;
