const styles = {
  root:{
    flex:'1',
    height:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    position:'relative',
  },
  content:{
    display:'flex',
    justifyContent:'center',
    width:'100%',
    height:'100%',
    padding:'0px',
    position:'relative',
    backgroundColor:'transparent',
  },
  foot:{
    width:'100%',
    height:'58px',
    backgroundColor:'transparent',
    position:'absolute',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    left:'0',
    bottom:'20px',
    zIndex:'2',
  }
}

export default styles;