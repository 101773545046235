/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import authService from 'src/utils/authService';
import Axios from 'axios';

import {
  Button, Card, InputAdornment, SvgIcon, TablePagination, TextField, Typography,
  Grid, Menu, MenuItem, Hidden, useMediaQuery, Select
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import DocumentUploadLogsTable from 'src/logs/components/DocumentLogsListResults/DocumentUploadLogsTable/DocumentUploadLogsTable';
import DocumentExportLogsTable from 'src/logs/components/DocumentLogsListResults/DocumentExportLogsTable/DocumentExportLogsTable';
import {
  invoiceStatuses, recentFilterOptions
} from 'src/config';
import { axiosHeaders } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import DocumentLogsTable from 'src/logs/components/DocumentLogsListResults/DocumentLogsTable/DocumentLogsTable';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';
import theme from "src/theme";

const logTypes = {
  upload_logs: 'DOCUMENT_UPLOAD_LOGS',
  audit_logs: 'DOCUMENT_AUDIT_LOGS',
  export_logs: 'DOCUMENT_EXPORT_LOGS',
};

const uploadLogStatuses = {
  all: 'DOCUMENT_UPLOAD_LOGS_STATUS_ALL',
  success: 'DOCUMENT_UPLOAD_LOGS_STATUS_SUCCESS',
  failed: 'DOCUMENT_UPLOAD_LOGS_STATUS_FAILED',
};

function InvoiceLogsListResults({
  className, invoiceID, setInvoiceID, history, ...rest
}) {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [logType, setLogType] = useState(invoiceID === 'export' ? 'export_logs' : invoiceID ? 'audit_logs' : 'upload_logs');

  const [fetchingLogs, setFetchingLogs] = useState(true);
  const [logs, setLogs] = useState([]);

  const [uploadLogs, setUploadLogs] = useState([]);

  const [exportLogs, setExportLogs] = useState([]);

  const [displayLogs, setDisplayLogs] = useState(logs || uploadLogs || []);
  const [selectedLogs, setSelectedLogs] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({
    type: null,
    status: null,
    recent: null,
  });

  const [uploadStatus, setUploadStatus] = useState('all');

  const invoiceType = invoiceStatuses.all;

  const maxWidth = useMediaQuery('(min-width: 1500px)');
  const maxWidthRows = useMediaQuery('(max-width: 525px)');

  const getUploadLogs = async (tpage = 0, tlimit = 10, search = '', filter = '', status = '') => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLogs(true);

    try {
      let url = `${API.getUploadLogsByCompany}/${encodeURIComponent(user.companyID)}?page=${tpage + 1}&limit=${tlimit}&order=desc${search && search !== ''
        ? `&search=${search}` : ''}${filter && filter !== '' ? `&filter=${filter}` : ''}`;

      if (status !== '' && status !== 'all') {
        url = `${url}&status=${status === 'success' ? 'true' : 'false'}`;
      }

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setTotal(response.data.total);
            setUploadLogs(response.data.data);
          } else {
            setTotal(0);
            setUploadLogs([]);
          }
        } else {
          setTotal(0);
          setUploadLogs([]);
        }
      }
    } catch (error) {
      setUploadLogs([]);
    }

    setFetchingLogs(false);
  };

  const getLogs = async (tpage = 0, tlimit = 10, search = '', filter = '') => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLogs(true);

    try {
      const url = `${API.getLogsByCompany}/${encodeURIComponent(user.companyID)}?page=${tpage + 1}&limit=${tlimit}&order=desc${search && search !== ''
        ? `&search=${search}` : ''}${filter && filter !== '' ? `&filter=${filter}` : ''}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setTotal(response.data.total);
            setLogs(response.data.data);
          } else {
            setTotal(0);
            setLogs([]);
          }
        } else {
          setTotal(0);
          setLogs([]);
        }
      }
    } catch (error) {
      setLogs([]);
    }

    setFetchingLogs(false);
  };

  const getLogsByInvoice = async (id, tpage = 0, tlimit = 10, search = '', filter = '') => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLogs(true);

    try {
      const url = `${API.getLogsByInvoice}/${id}?page=${tpage + 1}&limit=${tlimit}&order=desc${search && search !== ''
        ? `&search=${search}` : ''}${filter && filter !== '' ? `&filter=${filter}` : ''}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setLogs(response.data.data);
            setTotal(response.data.total);
          } else {
            setTotal(0);
            setLogs([]);
            setInvoiceID(null);
            history.push('/documents/logs');
            getLogs(0, 10);
          }
        } else {
          setTotal(0);
          setLogs([]);
          setInvoiceID(null);
          history.push('/documents/logs');
          getLogs(0, 10);
        }
      }
    } catch (error) {
      setInvoiceID(null);
      history.push('/documents/logs');
    }

    setFetchingLogs(false);
  };

  const getExportLogs = async (tpage = 0, tlimit = 10) => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLogs(true);

    try {
      const url = `${API.getExportLogsByCompany}/${encodeURIComponent(user.companyID)}?page=${tpage + 1}&limit=${tlimit}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setTotal(response.data.total);
            setExportLogs(response.data.data);
          } else {
            setTotal(0);
            setExportLogs([]);
          }
        } else {
          setTotal(0);
          setExportLogs([]);
        }
      }
    } catch (error) {
      setExportLogs([]);
    }

    setFetchingLogs(false);
  };

  useEffect(() => {
    if (invoiceID === 'export') {
      setLogType('export_logs');
    } else if (invoiceID) {
      setLogType('audit_logs');
    } else {
      setLogType('upload_logs');
    }
  }, [invoiceID]);

  useEffect(() => {
    if (logType === 'upload_logs') {
      getUploadLogs(0, 10);
    } else if (logType === 'export_logs') {
      getExportLogs();
    } else if (invoiceID && invoiceID !== 'export' && invoiceID > 0) {
      getLogsByInvoice(invoiceID);
    } else {
      getLogs(0, 10);
    }
  }, [logType]);

  useEffect(() => {
    if (logType === 'upload_logs') {
      setDisplayLogs(uploadLogs);
    } else if (logType === 'export_logs') {
      setDisplayLogs(exportLogs);
    } else {
      setDisplayLogs(logs);
    }
  }, [logs, uploadLogs, exportLogs, logType]);

  useEffect(() => {
    getUploadLogs(0, 10, '', '', uploadStatus);
  }, [uploadStatus]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const executeGetLogs = (ty, p, l, q) => {
    if (invoiceID && invoiceID > 0) {
      getLogsByInvoice(invoiceID, p, l, q, ty);
    } else {
      getLogs(p, l, q, ty);
    }
  };

  const handleSelectLogStatus = (e) => {
    setPage(0);
    setLimit(10);
    setQuery('');
    setFilters({
      type: null,
      status: null,
      recent: null,
    });
    setUploadStatus(e.target.value);
  };

  const handleSelectLogType = (e) => {
    setUploadStatus('all');
    setPage(0);
    setLimit(10);
    setQuery('');
    setFilters({
      type: null,
      status: null,
      recent: null,
    });
    setLogType(e.target.value);
  };

  const gotoLogsList = () => {
    history.push('/documents');
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
    setPage(0);

    setDisplayLogs([]);
    if (logType === 'upload_logs') {
      getUploadLogs(0, limit, event.target.value, invoiceType, uploadStatus);
    } else if (logType === 'audit_logs') {
      executeGetLogs(invoiceType, 0, limit, event.target.value);
    }
  };

  const handleRecentFilterChange = (filter) => {
    let value = null;
    if (filter !== 0) {
      value = filter;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      recent: value
    }));
    handleClose();
  };

  const handleSelectOneLog = (event, assistantID) => {
    event.stopPropagation();
    if (selectedLogs.length < 1 || selectedLogs === undefined) {
      setSelectedLogs([assistantID]);
    } else if (!selectedLogs.includes(assistantID)) {
      setSelectedLogs((prevSelected) => [...prevSelected, assistantID]);
    } else {
      setSelectedLogs((prevSelected) => prevSelected.filter((id) => id !== assistantID));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);

    setDisplayLogs([]);
    if (logType === 'upload_logs') {
      getUploadLogs(newPage, limit, query, invoiceType, uploadStatus);
    } else if (logType === 'export_logs') {
      getExportLogs(newPage, limit);
    } else {
      executeGetLogs(invoiceType, newPage, limit, query);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);

    setDisplayLogs([]);
    if (logType === 'upload_logs') {
      getUploadLogs(0, event.target.value, query, invoiceType, uploadStatus);
    } else if (logType === 'export_logs') {
      getExportLogs(0, event.target.value);
    } else {
      executeGetLogs(invoiceType, 0, event.target.value, query);
    }
  };

  const statusFilter = (
    logType === 'upload_logs' && (
      <Grid style={styles.statusFilterContainer}>
        <Typography style={styles.statusFilterLabel}>{t('DOCUMENT_UPLOAD_LOGS_STATUS')}</Typography>
        <Select
          disabled={fetchingLogs}
          onChange={handleSelectLogStatus}
          style={styles.statusFilterSelect}
          value={uploadStatus}
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {Object.keys(uploadLogStatuses).map((status) => (
            <MenuItem style={styles.statusFilterInputText} key={status} value={status}>
              {ready && t(uploadLogStatuses[status])}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    )
  );

  const searchBar = logType !== 'export_logs' && (
    <Grid className={'search-bar'} style={styles.searchBar}>
      <TextField
        style={styles.queryField}
        onChange={handleQueryChange}
        placeholder={ready && t('DASHBOARD_SEARCH_INVOICE_LOGS_SEARCH')}
        value={query}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                fontSize="small"
                color="action"
                style={{ fill: theme.palette.icon.gray }}
              >
                <SearchIcon style={{ color: theme.palette.icon.gray }} />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );

  const backToDocuments = (
    <Grid>
      <Button style={styles.backBtn} onClick={gotoLogsList}>
        <ArrowBackIcon style={styles.icon} />
        {ready && t('INVOICE_EDIT_BACK_TO_OVERVIEW')}
      </Button>
    </Grid>
  );

  const titleText = (
    <>
      {(!invoiceID || invoiceID === 'export') && (
        <Select
          disabled={fetchingLogs}
          onChange={handleSelectLogType}
          style={styles.formInputSelect}
          value={logType}
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {Object.keys(logTypes).map((type) => (
            <MenuItem style={styles.inputText} key={type} value={type}>
              {ready && t(logTypes[type])}
            </MenuItem>
          ))}
        </Select>
      )}
      {invoiceID && invoiceID > 0 && (
        <Typography style={styles.title}>
          {` (${ready && t('LOGS_VIEW_INVOICE_NUMBER')}: ${invoiceID})`}
        </Typography>
      )}
    </>
  );

  const filter = (
    <Grid style={styles.hideFilter}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={styles.filterBtn}
      >
        <SvgIcon fontSize="small" style={{ marginRight: 8 }}>
          <FilterListIcon style={styles.filterIcon} />
        </SvgIcon>
        {ready && t('DASHBOARD_INVOICES_FILTERED')}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.default,
            borderRadius: 0,
            boxShadow: theme.shadows[2],
            padding: 0,
            width: 200
          }
        }}
        // classes={{
        //   paper: classes.filterMenu
        // }}
      >
        {
          recentFilterOptions && recentFilterOptions.length > 0 && recentFilterOptions.map((o) => (
            <MenuItem
              key={o.value}
              style={styles.filterMenuItem}
              onClick={() => handleRecentFilterChange(o.value)}
            >
              {t(o.label)}
            </MenuItem>
          ))
        }
      </Menu>
    </Grid>
  );

  const paginationTab = () => (
    <TablePagination
      component="div"
      // classes={{
      //   caption: classes.paginationCaption,
      //   selectRoot: classes.paginationCaption,
      //   menuItem: classes.paginationCaption,
      //   actions: classes.paginationActions,
      // }}
      count={total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
    />
  );

  return (
    <Grid>
      {
        invoiceID && invoiceID > 0
          ? (
            <>
              <Hidden only={['xs', 'sm']}>
                {backToDocuments}
                <Grid style={{ ...styles.header, ...styles.headerMargin }}>
                  {titleText}
                  <Grid style={{ width: 24, display: 'none' }} />
                  {searchBar}
                  <Grid style={{ width: 24, display: 'none' }} />
                  {filter}
                </Grid>
              </Hidden>
              <Hidden only={['xs', 'md', 'lg', 'xl']}>
                {backToDocuments}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header}>
                    {searchBar}
                    <Grid style={{ width: 24, display: 'none' }} />
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {backToDocuments}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header}>
                    {searchBar}
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
            </>
          )
          : (
            <>
              <Hidden only={['xs', 'sm', 'md']}>
                {backToDocuments}
                {
                  maxWidth ? (
                    <Grid style={{ ...styles.header, ...styles.headerMargin }}>
                      {titleText}
                      <Grid style={{ width: 24, display: 'none' }} />
                      {statusFilter}
                      {searchBar}
                      <Grid style={{ width: 24, display: 'none' }} />
                      {filter}
                    </Grid>
                  ) : (
                    <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                      <Grid style={styles.header}>
                        {titleText}
                      </Grid>
                      <Grid style={styles.header}>
                        {statusFilter}
                        {searchBar}
                        <Grid style={{ width: 24, display: 'none' }} />
                        {filter}
                      </Grid>
                    </Grid>
                  )
                }
              </Hidden>
              <Hidden only={['xs', 'sm', 'lg', 'xl']}>
                {backToDocuments}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header}>
                    {statusFilter}
                    {searchBar}
                    <Grid style={{ width: 24, display: 'none' }} />
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={['xs', 'md', 'lg', 'xl']}>
                {backToDocuments}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header}>
                    {statusFilter}
                    {searchBar}
                    <Grid style={{ width: 24, display: 'none' }} />
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {backToDocuments}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header}>
                    {statusFilter}
                    {searchBar}
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
            </>
          )
      }
      <Card
        style={styles.root}
        className={className}
        {...rest}
      >
        <div style={styles.bulkOperations}>
          <div style={styles.bulkActions}>
            <div />
            <>
              {paginationTab()}
            </>
          </div>
        </div>
        {
          displayLogs.length === 0 && <Grid style={styles.breakLine} />
        }
        {
          fetchingLogs ? <LoadingIndicator /> : (
            <PerfectScrollbar>
              {logType === 'upload_logs' ? (
                <DocumentUploadLogsTable
                  paginatedLogs={uploadLogs}
                  getLogs={() => getUploadLogs(page, limit, query, invoiceType)}
                />
              ) : logType === 'export_logs' ? (
                <DocumentExportLogsTable
                  paginatedLogs={exportLogs}
                />
              ) : (
                <DocumentLogsTable
                  paginatedLogs={logs}
                  selectedLogs={selectedLogs}
                  handleSelectOneLog={handleSelectOneLog}
                  status={invoiceType}
                  getLogs={() => executeGetLogs(page, limit, query, invoiceType)}
                  invoiceID={invoiceID}
                />
              )}
            </PerfectScrollbar>
          )
        }
        {filters && displayLogs.length > 0 && paginationTab()}
      </Card>
    </Grid>
  );
}

export default InvoiceLogsListResults;
