import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';

import ConfigContext from 'src/contexts/ConfigContext';
import authService from 'src/utils/authService';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import { AuthValuesType, UserDataType } from 'src/types';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchHeaderFields, fetchLineFields, fetchDocOverviewFields } from 'src/shared/fieldsActions';

const defaultProvider: AuthValuesType = {
  user: null,
};

const AuthContext = createContext(defaultProvider);

function AuthProvider({ render, ...rest }: {render: (_props: any) => React.JSX.Element}) {
  const dispatch = useDispatch();
  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user);

  const loadData = (companyID: string, documentType: string) => {
    const headerEntity = documentType === 'id' ? 'id' : 'invoice';
    dispatch(fetchVatCodes(companyID) as unknown as UnknownAction);
    dispatch(fetchGlas(companyID) as unknown as UnknownAction);
    dispatch(fetchCostCenters(companyID) as unknown as UnknownAction);
    dispatch(fetchHeaderFields(companyID, headerEntity) as unknown as UnknownAction);
    dispatch(fetchLineFields(companyID) as unknown as UnknownAction);
    dispatch(fetchDocOverviewFields(companyID) as unknown as UnknownAction);
  };

  if (!authService.isAuthenticated()) {
    authService.logout(LOGIN_PANEL_URL);
  }

  useEffect(() => {
    const checkAuth = async () => {
      if (authService.isAuthenticated()) {
        const data = {
          token: authService.getAccessToken(),
          role: authService.getUserRole(),
          userRef: authService.getUserRef()
        };
        const response = await authService.loginInWithToken(API.verifyToken, data);
        if (response.success && response.data) {
          authService.setSession(response.data.accessToken, response.data.role, response.data.email);
          const userData = response.data;
          if (response.data.avatarName && response.data.avatarName !== '') {
            userData.avatar = `${API.getProfileImage}${response.data.avatarName}`;
          }
          setUser(userData);
          loadData(userData.companyID, userData.documentType);
        } else {
          authService.logout(LOGIN_PANEL_URL);
        }
      } else {
        authService.logout(LOGIN_PANEL_URL);
      }
    };

    if (!user) {
      checkAuth();
    }
  }, [user]);

  if (!user) {
    return <LoadingScreen />;
  }

  const values = {
    user,
    setUser,
  };

  return <AuthContext.Provider value={values}>{render({ ...rest })}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
