import TopBar from 'src/shared/components/TopBar/TopBar';
import theme from 'src/theme';

const DashboardLayout = ({ children }) => {

  return (
    <div style={styles.root}>
      <TopBar />
      <div style={styles.wrapper}>
        <div style={styles.contentContainer}>
          <div className="hide-scroll" style={styles.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;

const styles = {
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 60
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
};
