import PasswordStrengthBar from 'react-password-strength-bar';
import { useTranslation } from 'react-i18next';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import styles from './style';

const PasswordStrengthComponent = (props) => {
  const { password, passwordHelper } = props;
  const { t } = useTranslation();

  const renderText = (text, colorHex) => (
    <span style={styles.passwordHelper}>
      <span
        color="inherit"
        style={{ color: colorHex, ...styles.scoreWordsStyle }}
      >
        {text}
      </span>
      <span style={styles.helperText}>
        {passwordHelper && <AlternateEmailIcon style={styles.helperIcon} />}
        {passwordHelper}
      </span>
    </span>
  );

  return (
    <PasswordStrengthBar
      password={password}
      style={styles.strengthBar}
      shortScoreWord={renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_SHORT'))}
      scoreWords={[
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_SHORT')),
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_WEAK'), '#ef4836'),
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_ALMOST_READY'), '#f6b44d'),
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_GOOD'), '#2b90ef'),
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_STRONG'), '#25c281')
      ]}
    />
  );
};

export default PasswordStrengthComponent;
