import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, FormControl, FormHelperText,
  Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Autocomplete
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  AddBox as PlusIcon,
  Edit as EditIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import {
  isActionPermitted, permissions
} from 'src/utils/helpers';
import {
  glAccountData, glaFields
} from 'src/config';
import theme from 'src/theme';
import styles from './style';

const GLAccountsTwinfield = (props) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setErrors,
    glAccount,
    handleClose,
    hasError,
    years,
    endYears,
    periods,
    handleChangeAutocomplete,
    getLabel,
    vatCodes,
    newlyAddedVal,
    newlyAddingProp,
    handleEditVATCode,
    handleAddVATCode,
  } = props;

  const { t, ready } = useTranslation();
  
  const auth = useAuth();
  const {user} = auth;

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } });
    }
  }, [newlyAddedVal]);

  return (
    <form style={styles.root}>
      <Card>
        <CardContent style={{ padding: 32 }}>
          <Grid container spacing={3}>
            {
              glaFields.map((m) => (
                <Accordion
                  key={m.title}
                  style={styles.accordionGroup}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={m.title}
                    id={m.title}
                  >
                    <Grid style={styles.accordionHeading}>
                      {ready && t(m.title)}
                      {
                        hasError(m.fields, errors) && (
                          <Chip
                            color="error"
                            variant="default"
                            size="small"
                            label="Error"
                            style={styles.errorChip}
                          />
                        )
                      }
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      {
                        m.fields.map((f) => {
                          switch (f.key) {
                            case 'fromYear':
                              return (
                                <Grid item lg={4} md={6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onClick={() => setErrors({})}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        years && years.length > 0
                                          && years.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'endYear':
                              return (
                                <Grid item lg={4} md={6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onClick={() => setErrors({})}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        endYears && endYears.length > 0
                                          && endYears.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'fromPeriod': case 'endPeriod':
                              return (
                                <Grid item lg={4} md={6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        periods && periods.length > 0
                                          && periods.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'furtherAnalysis':
                              return (
                                <Grid item lg={4} md={6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        glAccountData.furtherAnalysis && glAccountData.furtherAnalysis.length > 0
                                          && glAccountData.furtherAnalysis.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'matchable':
                              return (
                                <Grid item lg={4} md={6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      <MenuItem value="yes">
                                        {t('GLA_MATCHABLE_MATCHABLE')}
                                      </MenuItem>
                                      <MenuItem value="no">
                                        {t('GLA_MATCHABLE_NOT_MATCHABLE')}
                                      </MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'performanceType':
                              return (
                                <Grid item lg={4} md={6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        glAccountData.performanceType && glAccountData.performanceType.length > 0
                                          && glAccountData.performanceType.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'vat':
                              return (
                                <Grid item lg={4} md={6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Autocomplete
                                      fullWidth
                                      name="vat"
                                      onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'vat', v, handleChange)}
                                      value={getLabel(vatCodes, values.vat)}
                                      options={vatCodes}
                                      getOptionLabel={
                                        (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                                      }
                                      getOptionSelected={(option) => option.code === (values.vat ? values.vat : ' ')}
                                      renderInput={(params) => (
                                        <TextField
                                          fullWidth
                                          {...params}
                                          label={ready && t(f.label)}
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {values[f.key] !== glAccountData.vat[0].code && (
                                                  <InputAdornment
                                                    position="end"
                                                    onClick={() => handleEditVATCode(values[f.key])}
                                                    style={styles.editIconContainer}
                                                  >
                                                    <EditIcon style={styles.editDimensionIcon} />
                                                  </InputAdornment>
                                                )}
                                                <InputAdornment position="end" style={styles.plusIconContainer}>
                                                  <PlusIcon style={styles.plusIcon} onClick={() => handleAddVATCode(f.key)} />
                                                </InputAdornment>
                                              </>
                                            ),
                                          }}
                                          style={styles.selectStyle}
                                        />
                                      )}
                                    />
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'vatType':
                              return (
                                <Grid item lg={4} md={6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        glAccountData.vatType && glAccountData.vatType.length > 0
                                          && glAccountData.vatType.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            default:
                              return (
                                <Tooltip title={(!glAccount || f.key !== 'code') ? '' : t('DIMENSION_CODE_CANNOT_EDIT')}>
                                  <Grid item lg={4} md={6} xs={12} key={f.key}>
                                    <TextField
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      fullWidth
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      label={ready && t(f.label)}
                                      name={f.key}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      required={f.required}
                                      value={values[f.key]}
                                      variant="outlined"
                                      style={styles.inputField}
                                      disabled={glAccount && f.key === 'code'}
                                    />
                                  </Grid>
                                </Tooltip>
                              );
                          }
                        })
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))
            }
            <Grid
              item
              xs={12}
            >
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  style={{ backgroundColor: theme.palette.button.action }}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user.permissions)}
                  onClick={handleSubmit}
                >
                  {ready && t('GLAS_SAVE_CHANGES')}
                </Button>
                <Button
                  variant="text"
                  onClick={handleClose}
                  style={styles.yearField}
                >
                  {ready && t('GLAS_CANCEL')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

// GLAccountsTwinfield.propTypes = {
//   errors: PropTypes.object,
//   handleBlur: PropTypes.func,
//   handleChange: PropTypes.func,
//   handleSubmit: PropTypes.func,
//   isSubmitting: PropTypes.bool,
//   touched: PropTypes.bool,
//   values: PropTypes.object,
//   setErrors: PropTypes.func,
//   glAccount: PropTypes.object,
//   gLAccountModalOpen: PropTypes.bool,
//   selectedGLA: PropTypes.string,
//   handleClose: PropTypes.func,
//   className: PropTypes.string,
//   hasError: PropTypes.func,
//   years: PropTypes.array,
//   endYears: PropTypes.array,
//   periods: PropTypes.array,
//   handleChangeAutocomplete: PropTypes.func,
//   getLabel: PropTypes.func,
//   vatCodes: PropTypes.array,
//   onSuccess: PropTypes.func,
//   newlyAddedVal: PropTypes.string,
//   newlyAddingProp: PropTypes.string,
//   handleAddVATCode: PropTypes.func,
//   handleEditVATCode: PropTypes.func,
// };

export default GLAccountsTwinfield;
