import theme from 'src/theme';
const styles = {
  root:{
    backgroundColor: theme.palette.background.default,
    display:'flex',
    flexDirection:'column',
  },
  head:{
    display:'flex',
    alignItems:'center',
    padding:'0px 20px',
    borderBlockEnd:'1px solid #4C4E641F'
  },
  btn:{
    borderRadius:'0',
    paddingBlock:'10px',
    borderBlockEnd:'2px solid transparent',
    color:'#4C4E6499',
    fontSize:'14px',
    fontWeight:'600',
    '&.active':{
      borderBlockEndColor:'#3E8AFF',
      color:'#3E8AFF',
    }
  },
  farBtn:{
    display:'flex',
    gap:'6px',
    alignItems:'center',
    color:'#4C4E64DE',
    backgroundColor:'transparent',
    marginLeft:'auto',
    lineHeight:'1',
    '& > span:first-of-type':{
      display:'flex',
      alignItems:'center',
      fontSize:'20px'
    }
  },
  statsGroup:{
    width:'100%',
    display:'flex',
  },
  eachApprovalStats:{
    padding:'14px 20px',
    width:'25%',
    display:'flex',
    gap:'16px',
    '& > div':{
      display:'flex',
      flexDirection:'column',
      lineHeight:'1',
      '& > span:first-of-type':{
        fontSize:'20px',
        lineHeight:'32px',
        color:'#4C4E64',
      },
      '& > span:nth-of-type(2)':{
        fontSize:'12px',
        color:'#4C4E6499',
        lineHeight:'14px',
      }
    }
  },
  eachSummaryStats:{
    padding:'14px 20px',
    width:'20%',
    display:'flex',
    gap:'16px',
    '& > svg':{
      flexShrink:'0'
    },
    '& > div':{
      display:'flex',
      flexDirection:'column',
      lineHeight:'1',
      overflow:'hidden',
      '& > span:first-of-type':{
        fontSize:'20px',
        lineHeight:'32px',
        color:'#4C4E64',
      },
      '& > span:nth-of-type(2)':{
        fontSize:'12px',
        color:'#4C4E6499',
        lineHeight:'14px',
      }
    }
  },
  archivedIcon:{
    display:'flex',
    width:'40px',
    height:'40px',
    borderRadius:'8px',
    justifyContent:'center',
    alignItems:'center',
    color:'#4C4E64',
    backgroundColor:'rgba(76, 78, 100, .12)',
    fontSize:'20px !important',
    '& > svg':{
      fontSize:'20px !important'
    },
    '& > svg > path':{
      width:'16px',
      height:'16px',
      fontSize:'20px !imporant'
    }
  },
  showChartIcon:{
    color:'#C2CFE0'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    padding: '9px',
    borderRadius: '8px',
  },
  icon: {
    fontSize: '20px',
  }
}

export default styles;