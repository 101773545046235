/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.167 13.678L5.244 9.756l1.179-1.178 2.744 2.744 8.062-8.063a1.665 1.665 0 00-1.396-.759H4.167c-.921 0-1.667.746-1.667 1.667v11.666c0 .921.746 1.667 1.667 1.667h11.666c.921 0 1.667-.746 1.667-1.667V5.345l-8.333 8.333z"
        fill="#3E8AFF"
      />
    </svg>
  )
}

export default SvgComponent
