import React, { PointerEventHandler, PureComponent } from "react";
import ReactDOM from 'react-dom/client';
import debounce from "lodash.debounce";

import {
  EventBus,
  PDFViewer,
  PDFLinkService,
  PDFFindController,
} from "pdfjs-dist/legacy/web/pdf_viewer";

import "pdfjs-dist/web/pdf_viewer.css";
import "../style/pdf_viewer.css";

import "../style/PdfHighlighter.css";

import getBoundingRect from "../lib/get-bounding-rect";
import getClientRects from "../lib/get-client-rects";
import getAreaAsPng from "../lib/get-area-as-png";

import {
  asElement,
  getPagesFromRange,
  getPageFromElement,
  getWindow,
  findOrCreateContainerLayer,
  isHTMLElement,
} from "../lib/pdfjs-dom";

import TipContainer from "./TipContainer";
import MouseSelection from "./MouseSelection";

import { LTWHPToScaledPosition, scaledToLTWHP, scaledToViewport, viewportToScaled } from "../lib/coordinates";

import type {
  Position,
  ScaledPosition,
  IHighlight,
  Scaled,
  LTWH,
  LTWHP,
  NewHighlight,
} from "../types";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { isElementInViewport, isOverlapping, isWithin } from "src/document-edit/utils";
import { annotationSpecialCharacters } from "src/config";
import { FieldType } from "src/types";

type T_ViewportHighlight<T_HT> = { position: Position } & T_HT;

interface State<T_HT> {
  ghostHighlight: {
    position: ScaledPosition;
    content?: { text?: string; image?: string };
  } | null;
  isCollapsed: boolean;
  range: Range | null;
  tip: {
    highlight: T_ViewportHighlight<T_HT>;
    callback: (_highlight: T_ViewportHighlight<T_HT>) => React.JSX.Element;
  } | null;
  tipPosition: Position | null;
  tipChildren: React.JSX.Element | null;
  isAreaSelectionInProgress: boolean;
  scrolledToHighlightId: string;
}

interface Props<T_HT> {
  highlightTransform: (
    _highlight: T_ViewportHighlight<T_HT>,
    _index: number,
    _setTip: (
      _highlight: T_ViewportHighlight<T_HT>,
      _callback: (_highlight: T_ViewportHighlight<T_HT>) => React.JSX.Element
    ) => void,
    _hideTip: () => void,
    _viewportToScaled: (_rect: LTWHP) => Scaled,
    _screenshot: (_position: LTWH) => string,
    _isScrolledTo: boolean,
    _onClickHighlight: (
      _highlightID: string,
      _rects: LTWHP[],
      _pageNumber: number,
      _content: {text?: string},
      _isCandidate: boolean,
      _procysKey: string | undefined,
      _fieldSeparator: string,
    ) => void
  ) => React.JSX.Element;
  highlights: Array<T_HT>;
  findRefs: (_findPrev: () => void, _findNext: () => void) => void;
  pdfDocument: PDFDocumentProxy;
  pdfScaleValue: string;
  pdfPageNumber: string;
  pagesRotation: number;
  searchValue: string;
  onSearch: (_currentMatch: number, _totalMatchCount: number) => void;
  onSelectionFinished: (
    _highlightID: string,
    _position: ScaledPosition,
    _content: { text?: string; image?: string },
    _hideTipAndSelection: () => void,
    _transformSelection: () => void,
    // to be used when multiple highlights are selected
    _overlappingIds: string[],
    // to be used when user selects highlights with shift key pressed
    _shiftPosition: ScaledPosition,
  ) => React.JSX.Element | null;
  viewerHeight?: string;
  selectedTextField: FieldType;
  selectedTextFieldRef: React.MutableRefObject<FieldType>;
  scrollTextFieldIntoView: (_procysKey: string) => void;
  pageHeight: React.MutableRefObject<number>;
  pageWidth: React.MutableRefObject<number>;
  viewLines: boolean;
  onSelectAreaForLinesReprocessing: (_selectedArea: NewHighlight) => void;
  setPageNumber: React.Dispatch<React.SetStateAction<string>>;
}

const EMPTY_ID = "empty-id";

export class PdfHighlighter<T_HT extends IHighlight> extends PureComponent<
  Props<T_HT>,
  State<T_HT>
> {
  static defaultProps = {
    pdfScaleValue: "auto",
    pagesRotation: 0,
    searchValue: "",
    onSearch: () => {},
  };

  state: State<T_HT> = {
    ghostHighlight: null,
    isCollapsed: true,
    range: null,
    scrolledToHighlightId: EMPTY_ID,
    isAreaSelectionInProgress: false,
    tip: null,
    tipPosition: null,
    tipChildren: null,
  };

  eventBus = new EventBus();
  linkService = new PDFLinkService({
    eventBus: this.eventBus,
    externalLinkTarget: 2,
  });

  viewer!: PDFViewer;

  resizeObserver: ResizeObserver | null = null;
  containerNode?: HTMLDivElement | null = null;
  unsubscribe = () => {};
  shiftPressed: boolean;
  shiftContentText: string;
  shiftRects: LTWHP[];
  shiftOverlappingIds: string[];

  constructor(props: Props<T_HT>) {
    super(props);
    if (typeof ResizeObserver !== "undefined") {
      this.resizeObserver = new ResizeObserver(this.debouncedScaleValue);
    }
    this.shiftPressed = false;
    this.shiftContentText = "";
    this.shiftRects = [];
    this.shiftOverlappingIds = [];
  }

  componentDidMount() {
    this.init();
    this.unsubscribe();
    this.attachRef(this.containerNode || null);
  }

  attachRef = (ref: HTMLDivElement | null) => {
    const { eventBus, resizeObserver: observer } = this;
    this.containerNode = ref;
    this.unsubscribe();

    if (ref) {
      const { ownerDocument: doc } = ref;
      eventBus.on("textlayerrendered", this.onTextLayerRendered);
      eventBus.on("pagesinit", this.onDocumentReady);
      eventBus.on("updatetextlayermatches", this.calculateMatchProgress);
      doc.addEventListener("keydown", this.handleKeyDown);
      doc.addEventListener("keyup", this.handleKeyUp);
      if (this.viewer) this.viewer.container.addEventListener("scroll", this.onScrollPageChange);
      if (doc.defaultView) doc.defaultView.addEventListener("resize", this.debouncedScaleValue);
      if (observer) observer.observe(ref);

      this.unsubscribe = () => {
        eventBus.off("pagesinit", this.onDocumentReady);
        eventBus.off("textlayerrendered", this.onTextLayerRendered);
        eventBus.off("updatetextlayermatches", this.calculateMatchProgress);
        doc.removeEventListener("keydown", this.handleKeyDown);
        doc.removeEventListener("keyup", this.handleKeyUp);
        if (this.viewer) this.viewer.container.removeEventListener("scroll", this.onScrollPageChange);
        if (doc.defaultView) doc.defaultView.removeEventListener(
          "resize",
          this.debouncedScaleValue
        );
        if (observer) observer.disconnect();
      };
    }
  };

  componentDidUpdate(prevProps: Props<T_HT>) {
    if (prevProps.pdfDocument !== this.props.pdfDocument) {
      this.init();
      return;
    }
    if (prevProps.highlights !== this.props.highlights) {
      this.renderHighlights(this.props);
    }
    if (prevProps.pagesRotation != this.props.pagesRotation) {
      this.viewer.pagesRotation = this.props.pagesRotation;
    }
    if (prevProps.pdfScaleValue != this.props.pdfScaleValue) {
      this.viewer.currentScaleValue = this.props.pdfScaleValue;
    }
    if (prevProps.pdfPageNumber != this.props.pdfPageNumber) {
      const pdfPageNumberInt = parseInt(this.props.pdfPageNumber, 10);
      if (this.viewer.currentPageNumber !== pdfPageNumberInt && !Number.isNaN(pdfPageNumberInt)) {
        this.viewer.currentPageNumber = pdfPageNumberInt;
      }
    }
    if (prevProps.searchValue != this.props.searchValue) {
      this.viewer.findController.executeCommand("find", {
        query: this.props.searchValue,
        highlightAll: true,
        phraseSearch: true,
      });
    }
    if (prevProps.selectedTextField !== this.props.selectedTextField) {
      this.onSelectedTextFieldChange(this.props.selectedTextField.key);
    }
  }

  init() {
    const { pdfDocument } = this.props;

    this.viewer =
      this.viewer ||
      new PDFViewer({
        container: this.containerNode!,
        eventBus: this.eventBus,
        // enhanceTextSelection: true, // deprecated. https://github.com/mozilla/pdf.js/issues/9943#issuecomment-409369485
        textLayerMode: 2,
        removePageBorders: true,
        linkService: this.linkService,
        findController: new PDFFindController({
          eventBus: this.eventBus,
          linkService: this.linkService,
        }),
        renderer: "canvas",
        l10n: null,
      });

    this.linkService.setDocument(pdfDocument);
    this.linkService.setViewer(this.viewer);
    this.viewer.setDocument(pdfDocument);

    // debug
    (window as any).PdfViewer = this;
  }

  calculateMatchProgress = () => {
    let totalMatchCount = 0;
    let currentMatchIndex = 0;
    let hasCurrentMatchIndex = false;
    this.viewer.findController.pageMatches.forEach(
      (pageMatches: number[], pageIndex: number) => {
        if (
          !hasCurrentMatchIndex &&
          pageIndex === this.viewer.findController.selected.pageIdx
        ) {
          currentMatchIndex =
            totalMatchCount + this.viewer.findController.selected.matchIdx;
          hasCurrentMatchIndex = true;
        }
        totalMatchCount += pageMatches.length;
      }
    );
    const currentMatch = totalMatchCount === 0 ? 0 : currentMatchIndex + 1;
    this.props.onSearch(currentMatch, totalMatchCount);
  };

  goToNextMatch = () => {
    const { searchValue } = this.props;
    this.viewer.findController.executeCommand("findagain", {
      query: searchValue,
      highlightAll: true,
    });
  };

  goToPreviousMatch = () => {
    const { searchValue } = this.props;
    this.viewer.findController.executeCommand("findagain", {
      query: searchValue,
      highlightAll: true,
      findPrevious: true,
    });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  findOrCreateHighlightLayer(page: number) {
    const { textLayer } = this.viewer.getPageView(page - 1) || {};

    if (!textLayer) {
      return null;
    }

    return findOrCreateContainerLayer(
      textLayer.textLayerDiv,
      "PdfHighlighter__highlight-layer"
    );
  }

  groupHighlightsByPage(highlights: Array<T_HT>): {
    [pageNumber: string]: Array<T_HT>;
  } {
    const { ghostHighlight } = this.state;

    const allHighlights = [...highlights, ghostHighlight].filter(Boolean);

    const pageNumbers = new Set<number>();
    for (const highlight of allHighlights) {
      pageNumbers.add(highlight!.position.pageNumber);
      for (const rect of highlight!.position.rects) {
        if (rect.pageNumber) {
          pageNumbers.add(rect.pageNumber);
        }
      }
    }

    const groupedHighlights = {} as Record<number, any[]>;

    for (const pageNumber of pageNumbers) {
      groupedHighlights[pageNumber] = groupedHighlights[pageNumber] || [];
      for (const highlight of allHighlights) {
        const pageSpecificHighlight = {
          ...highlight,
          position: {
            pageNumber,
            boundingRect: highlight!.position.boundingRect,
            rects: [],
            usePdfCoordinates: highlight!.position.usePdfCoordinates,
          } as ScaledPosition,
        };
        let anyRectsOnPage = false;
        for (const rect of highlight!.position.rects) {
          if (
            pageNumber === (rect.pageNumber || highlight!.position.pageNumber)
          ) {
            pageSpecificHighlight.position.rects.push(rect);
            anyRectsOnPage = true;
          }
        }
        if (anyRectsOnPage || pageNumber === highlight!.position.pageNumber) {
          groupedHighlights[pageNumber].push(pageSpecificHighlight);
        }
      }
    }

    return groupedHighlights;
  }

  showTip(highlight: T_ViewportHighlight<T_HT>, content: React.JSX.Element) {
    const { isCollapsed, ghostHighlight, isAreaSelectionInProgress } =
      this.state;

    const highlightInProgress = !isCollapsed || ghostHighlight;

    if (highlightInProgress || isAreaSelectionInProgress) {
      return;
    }

    this.setTip(highlight.position, content);
  }

  scaledPositionToViewport({
    pageNumber,
    boundingRect,
    rects,
    usePdfCoordinates,
  }: ScaledPosition): Position {
    const viewport = this.viewer.getPageView(pageNumber - 1).viewport;

    return {
      boundingRect: scaledToViewport(
        boundingRect,
        viewport,
        usePdfCoordinates,
        this.viewer.pagesRotation
      ),
      rects: (rects || []).map((rect) =>
        scaledToViewport(
          rect,
          viewport,
          usePdfCoordinates,
          this.viewer.pagesRotation
        )
      ),
      pageNumber,
    };
  }

  viewportPositionToScaled({
    pageNumber,
    boundingRect,
    rects,
  }: Position): ScaledPosition {
    const viewport = this.viewer.getPageView(pageNumber - 1).viewport;

    return {
      boundingRect: viewportToScaled(
        boundingRect,
        viewport,
        this.viewer.pagesRotation
      ),
      rects: (rects || []).map((rect) =>
        viewportToScaled(rect, viewport, this.viewer.pagesRotation)
      ),
      pageNumber,
    };
  }

  screenshot(position: LTWH, pageNumber: number) {
    const canvas = this.viewer.getPageView(pageNumber - 1).canvas;

    return getAreaAsPng(canvas, position);
  }

  renderHighlights(nextProps?: Props<T_HT>) {
    const { highlightTransform, highlights } = nextProps || this.props;

    const { pdfDocument } = this.props;

    const { tip, scrolledToHighlightId } = this.state;

    const highlightsByPage = this.groupHighlightsByPage(highlights);

    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
      const highlightLayer = this.findOrCreateHighlightLayer(pageNumber);
      let root = highlightLayer?._reactRootContainer;

      if (highlightLayer) {
        if (!root) {
          root = ReactDOM.createRoot(highlightLayer);
          highlightLayer._reactRootContainer = root;
        }
        root.render(
          <div>
            {(highlightsByPage[String(pageNumber)] || []).map(
              ({ position, id, ...highlight }, index) => {
                // @ts-ignore
                const viewportHighlight: T_ViewportHighlight<T_HT> = {
                  id,
                  position: this.scaledPositionToViewport(position),
                  ...highlight,
                };

                if (tip && tip.highlight.id === String(id)) {
                  this.showTip(tip.highlight, tip.callback(viewportHighlight));
                }

                const isScrolledTo = Boolean(scrolledToHighlightId === id);

                return highlightTransform(
                  viewportHighlight,
                  index,
                  (highlight, callback) => {
                    this.setState({
                      tip: { highlight, callback },
                    });

                    this.showTip(highlight, callback(highlight));
                  },
                  this.hideTipAndSelection,
                  (rect) => {
                    const viewport = this.viewer.getPageView(
                      (rect.pageNumber || pageNumber) - 1
                    ).viewport;

                    return viewportToScaled(rect, viewport);
                  },
                  (boundingRect) => this.screenshot(boundingRect, pageNumber),
                  isScrolledTo,
                  this.onClickHighlight,
                );
              }
            )}
          </div>
        );
      }
    }
  }

  hideTipAndSelection = () => {
    this.setState({
      tipPosition: null,
      tipChildren: null,
    });

    this.setState({ ghostHighlight: null, tip: null }, () =>
      this.renderHighlights()
    );
  };

  setTip(position: Position, inner: React.JSX.Element | null) {
    this.setState({
      tipPosition: position,
      tipChildren: inner,
    });
  }

  renderTip = () => {
    const { tipPosition, tipChildren } = this.state;
    if (!tipPosition) return null;

    const { boundingRect, pageNumber } = tipPosition;
    const page = {
      node: this.viewer.getPageView((boundingRect.pageNumber || pageNumber) - 1)
        .div,
      pageNumber: boundingRect.pageNumber || pageNumber,
    };

    const pageBoundingClientRect = page.node.getBoundingClientRect();

    const pageBoundingRect = {
      bottom: pageBoundingClientRect.bottom,
      height: pageBoundingClientRect.height,
      left: pageBoundingClientRect.left,
      right: pageBoundingClientRect.right,
      top: pageBoundingClientRect.top,
      width: pageBoundingClientRect.width,
      x: pageBoundingClientRect.x,
      y: pageBoundingClientRect.y,
      pageNumber: page.pageNumber,
    };

    return (
      <TipContainer
        scaleValue={this.viewer.currentScaleValue}
        scrollTop={this.viewer.container.scrollTop}
        pageBoundingRect={pageBoundingRect}
        style={{
          left:
            page.node.offsetLeft + boundingRect.left + boundingRect.width / 2,
          top: boundingRect.top + page.node.offsetTop,
          bottom: boundingRect.top + page.node.offsetTop + boundingRect.height,
        }}
      >
        {tipChildren}
      </TipContainer>
    );
  };

  onTextLayerRendered = () => {
    this.renderHighlights();
  };

  scrollTo = (highlight: IHighlight) => {
    const { pageNumber, boundingRect, usePdfCoordinates } = highlight.position;
    const el = document.getElementById(`highlight-candidate-${highlight.id}`);
    if (isElementInViewport(el)) {
      return;
    }

    this.viewer.container.removeEventListener("scroll", this.onScroll);

    const pageViewport = this.viewer.getPageView(pageNumber - 1).viewport;

    const scrollMargin = this.viewer.container.offsetHeight/2;

    this.viewer.scrollPageIntoView({
      pageNumber,
      destArray: [
        null,
        { name: "XYZ" },
        ...pageViewport.convertToPdfPoint(
          0,
          scaledToViewport(boundingRect, pageViewport, usePdfCoordinates).top -
            scrollMargin
        ),
        0,
      ],
    });

    this.setState(
      {
        scrolledToHighlightId: highlight.id,
      },
      () => this.renderHighlights()
    );

    // wait for scrolling to finish
    setTimeout(() => {
      this.viewer.container.addEventListener("scroll", this.onScroll);
      this.props.setPageNumber(this.viewer.currentPageNumber.toString());
    }, 100);
  };

  onDocumentReady = () => {
    const { findRefs } = this.props;

    this.handleScaleValue();

    findRefs(this.goToPreviousMatch, this.goToNextMatch);
  };

  onSelectionChange = () => {
    const container = this.containerNode;
    const selection = getWindow(container).getSelection();

    if (!selection) {
      return;
    }

    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

    if (selection.isCollapsed) {
      this.setState({ isCollapsed: true });
      return;
    }

    if (
      !range ||
      !container ||
      !container.contains(range.commonAncestorContainer)
    ) {
      return;
    }

    this.setState({
      isCollapsed: false,
      range,
    });

    this.debouncedAfterSelection();
  };

  onScroll = () => {
    this.setState(
      {
        scrolledToHighlightId: EMPTY_ID,
      },
      () => this.renderHighlights()
    );

    this.viewer.container.removeEventListener("scroll", this.onScroll);
  };

  onScrollPageChange = () => {
    if (this.viewer.currentPageNumber !== parseInt(this.props.pdfPageNumber)) {
      this.props.setPageNumber(this.viewer.currentPageNumber.toString());
    }
  };

  onMouseDown: PointerEventHandler = (event) => {
    if (!isHTMLElement(event.target)) {
      return;
    }

    if (asElement(event.target).closest(".PdfHighlighter__tip-container")) {
      return;
    }

    const target = event.target as HTMLInputElement;
    if (target.id.includes('highlight-')) {
      return;
    }

    this.shiftContentText = "";
    this.shiftRects = [];
    this.shiftOverlappingIds = [];
    this.hideTipAndSelection();
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === "Escape") {
      this.shiftContentText = "";
      this.shiftRects = [];
      this.shiftOverlappingIds = [];
      this.hideTipAndSelection();
    }
    if (event.shiftKey) {
      this.shiftPressed = true;
    }
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      this.shiftPressed = false;
    }
  };

  afterSelection = () => {
    const { onSelectionFinished } = this.props;

    const { isCollapsed, range } = this.state;

    if (!range || isCollapsed) {
      return;
    }

    const pages = getPagesFromRange(range);

    if (!pages || pages.length === 0) {
      return;
    }

    const rects = getClientRects(range, pages);

    if (rects.length === 0) {
      return;
    }

    const boundingRect = getBoundingRect(rects);

    const viewportPosition: Position = {
      boundingRect,
      rects,
      pageNumber: pages[0].number,
    };

    const content = {
      text: range.toString(),
    };
    const scaledPosition = this.viewportPositionToScaled(viewportPosition);

    this.setTip(
      viewportPosition,
      onSelectionFinished(
        '',
        scaledPosition,
        content,
        () => this.hideTipAndSelection(),
        () =>
          this.setState(
            {
              ghostHighlight: { position: scaledPosition },
            },
            () => this.renderHighlights()
          ),
        [],
        scaledPosition,
      )
    );
  };

  onClickHighlight = (
    highlightID: string,
    rects: LTWHP[],
    pageNumber: number,
    content: { text?: string },
    isCandidate: boolean,
    procysKey: string | undefined,
    fieldSeparator: string,
  ) => {
    const {
      onSelectionFinished, selectedTextField, selectedTextFieldRef, scrollTextFieldIntoView, highlights, pageWidth, pageHeight
    } = this.props;

    this.setState(
      {
        scrolledToHighlightId: EMPTY_ID,
      },
      () => this.renderHighlights()
    );

    if (selectedTextFieldRef.current.key.includes('invoiceLineColumn_')) {
      return;
    }

    if (selectedTextFieldRef.current.key === '' && isCandidate && procysKey) {
      scrollTextFieldIntoView(procysKey);
      return;
    }

    if (rects.length === 0 || selectedTextField.key === '') {
      return;
    }

    let iRects = rects;
    let iContent = content;

    if (this.shiftPressed) {
      iRects = [...this.shiftRects, ...rects];
      iContent = {
        text: this.shiftContentText
        + `${(this.shiftContentText && content.text && !annotationSpecialCharacters.includes(content.text)) ? fieldSeparator : ""}`
        + content.text,
      };
      this.shiftContentText = iContent.text as string;
      this.shiftRects = iRects;
      this.shiftOverlappingIds.push(highlightID);
    } else {
      this.shiftContentText = content.text as string;
      this.shiftRects = rects;
      this.shiftOverlappingIds = [highlightID];
    }

    const boundingRect = getBoundingRect(iRects);

    const viewportPosition: Position = {
      boundingRect,
      rects: iRects,
      pageNumber,
    };

    const scaledPosition = this.viewportPositionToScaled(viewportPosition);

    // The position in this context is scaled to the viewport, but we need the original position (i.e, scale = 1)
    // to update the highlights. Hence the hScaledPosition prop is added to the onSelectionFinished callback
    const hRects = this.shiftOverlappingIds.map((id) => highlights.find((h) => h.id === id)?.position.rects).flat() as Scaled[];
    const LTWHPRects = hRects.map(rect => scaledToLTWHP(rect));

    const hBoundingRect = getBoundingRect(LTWHPRects);
    const hViewportPosition: Position = {
      boundingRect: hBoundingRect,
      rects: LTWHPRects,
      pageNumber,
    };
    const hScaledPosition = LTWHPToScaledPosition(hViewportPosition, pageWidth.current, pageHeight.current);

    this.setTip(
      viewportPosition,
      onSelectionFinished(
        highlightID,
        scaledPosition,
        iContent,
        () => this.hideTipAndSelection(),
        () =>
          this.setState(
            {
              ghostHighlight: { position: scaledPosition },
            },
            () => this.renderHighlights()
          ),
        this.shiftOverlappingIds,
        hScaledPosition,
      )
    );
  };

  onSelectedTextFieldChange = (key : string) => {
    this.setState(
      {
        scrolledToHighlightId: EMPTY_ID,
      },
      () => this.renderHighlights()
    );
    this.shiftContentText = "";
    this.shiftRects = [];
    this.shiftOverlappingIds = [];
    this.hideTipAndSelection();
    const { onSelectionFinished, highlights } = this.props;

    const highlight = highlights.find((highlight) => highlight.isCandidate && highlight.procysKey === key);

    if (!highlight) {
      return;
    }
    const { position, content } = highlight;
    const { rects, pageNumber } = position;

    if (rects.length === 0) {
      return;
    }

    const LTWHPRects = rects.map(rect => scaledToViewport(rect, this.viewer.getPageView(pageNumber - 1).viewport));

    const boundingRect = getBoundingRect(LTWHPRects);

    const viewportPosition: Position = {
      boundingRect,
      rects: LTWHPRects,
      pageNumber,
    };

    const scaledPosition = this.viewportPositionToScaled(viewportPosition);

    this.scrollTo(highlight);

    this.setTip(
      viewportPosition,
      onSelectionFinished(
        highlight.id,
        scaledPosition,
        content,
        () => this.hideTipAndSelection(),
        () =>
          this.setState(
            {
              ghostHighlight: { position: scaledPosition },
            },
            () => this.renderHighlights()
          ),
        [],
        scaledPosition,
      )
    );
  }

  debouncedAfterSelection: () => void = debounce(this.afterSelection, 500);

  toggleTextSelection(flag: boolean) {
    this.viewer.viewer!.classList.toggle(
      "PdfHighlighter--disable-selection",
      flag
    );
  }

  handleScaleValue = () => {
    if (this.viewer) {
      this.viewer.currentScaleValue = this.props.pdfScaleValue; //"page-width";
    }
  };

  debouncedScaleValue: () => void = debounce(this.handleScaleValue, 500);

  getNewAndOverlappingHighlights = (scaledPosition: ScaledPosition) => {
    const { highlights, selectedTextFieldRef, pageHeight, pageWidth } = this.props;
    let content = '';
    const overlappingHighlightIDs: string[] = [];
    const overlappingRects: Scaled[] = [];
    let minX1 = Number.MAX_SAFE_INTEGER;
    let minY1 = Number.MAX_SAFE_INTEGER;
    let maxX2 = -1;
    let maxY2 = -1;
    highlights.forEach((highlight) => {
      const width = highlight.position.boundingRect.width;
      const height = highlight.position.boundingRect.height;
      const widthRatio = width / scaledPosition.boundingRect.width;
      const heightRatio = height / scaledPosition.boundingRect.height;

      const newx1 = scaledPosition.boundingRect.x1 * widthRatio;
      const newx2 = scaledPosition.boundingRect.x2 * widthRatio;
      const newy1 = scaledPosition.boundingRect.y1 * heightRatio;
      const newy2 = scaledPosition.boundingRect.y2 * heightRatio;

      const isHighlightOverlapping = isOverlapping({
        x1: highlight.position.boundingRect.x1,
        y1: highlight.position.boundingRect.y1,
        x2: highlight.position.boundingRect.x2,
        y2: highlight.position.boundingRect.y2,
        width,
        height,
      }, {
        x1: newx1,
        y1: newy1,
        x2: newx2,
        y2: newy2,
        width,
        height,
      });

      if (isHighlightOverlapping) {
        content = content.concat(highlight.content.text || '', ' ');
        overlappingHighlightIDs.push(highlight.id);
        overlappingRects.push(highlight.position.boundingRect);
        if (minX1 > highlight.position.boundingRect.x1) {
          minX1 = highlight.position.boundingRect.x1;
        }
        if (minY1 > highlight.position.boundingRect.y1) {
          minY1 = highlight.position.boundingRect.y1 ;
        }
        if (maxX2 < highlight.position.boundingRect.x2) {
          maxX2 = highlight.position.boundingRect.x2;
        }
        if (maxY2 < highlight.position.boundingRect.y2) {
          maxY2 = highlight.position.boundingRect.y2;
        }
      }
    })

    const newHighlight = {
      position: {
        boundingRect: {
          x1: minX1,
          y1: minY1,
          x2: maxX2,
          y2: maxY2,
          width: pageWidth.current,
          height: pageHeight.current,
          pageNumber: scaledPosition.pageNumber,
        },
        rects: overlappingRects,
        pageNumber: scaledPosition.pageNumber,
      },
      content: { text: content.trim() },
      comment: { text: selectedTextFieldRef.current.label },
      isCandidate: true,
      procysKey: selectedTextFieldRef.current.key,
    };

    return { newHighlight, overlappingHighlightIDs };
  };

  getNewHighlightForLineReprocessing = (scaledPosition: ScaledPosition): NewHighlight => {
    const { highlights, pageHeight, pageWidth } = this.props;

    const widthRatio = pageWidth.current / scaledPosition.boundingRect.width;
    const heightRatio = pageHeight.current / scaledPosition.boundingRect.height;

    const newx1 = scaledPosition.boundingRect.x1 * widthRatio;
    const newx2 = scaledPosition.boundingRect.x2 * widthRatio;
    const newy1 = scaledPosition.boundingRect.y1 * heightRatio;
    const newy2 = scaledPosition.boundingRect.y2 * heightRatio;

    const containedRects: Scaled[] = [];

    highlights.forEach((highlight) => {
      const width = highlight.position.boundingRect.width;
      const height = highlight.position.boundingRect.height;

      const isHighlightWithin = isWithin({
        x1: newx1,
        y1: newy1,
        x2: newx2,
        y2: newy2,
        width: pageWidth.current,
        height: pageHeight.current,
      }, {
        x1: highlight.position.boundingRect.x1,
        y1: highlight.position.boundingRect.y1,
        x2: highlight.position.boundingRect.x2,
        y2: highlight.position.boundingRect.y2,
        width,
        height,
      });

      if (isHighlightWithin) {
        containedRects.push(highlight.position.boundingRect);
      }
    })

    return {
      position: {
        boundingRect: {
          x1: newx1,
          y1: newy1,
          x2: newx2,
          y2: newy2,
          width: pageWidth.current,
          height: pageHeight.current,
          pageNumber: scaledPosition.pageNumber,
        },
        rects: containedRects,
        pageNumber: scaledPosition.pageNumber,
      },
      content: { text: '' },
      comment: { text: '' },
    };
  };

  render() {
    const {
      onSelectionFinished, viewerHeight, selectedTextFieldRef, viewLines
    } = this.props;

    return (
      <div onPointerDown={this.onMouseDown}>
        <div
          id="PdfHighlighter"
          ref={this.attachRef}
          className="PdfHighlighter y-scroll"
          style={{ height: viewerHeight, marginTop: viewLines ? '84px' : '8px', marginBottom: viewLines ? '8px' : '84px' }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <div className="pdfViewer" />
          {this.renderTip()}
          <MouseSelection
              onDragStart={() => this.toggleTextSelection(true)}
              onDragEnd={() => this.toggleTextSelection(false)}
              onChange={(isVisible) =>
                this.setState({ isAreaSelectionInProgress: isVisible })
              }
              shouldStart={(event) =>
                isHTMLElement(event.target) &&
                Boolean(asElement(event.target).closest(".page"))
              }
              onSelection={(startTarget, boundingRect, resetSelection) => {
                const {onSelectAreaForLinesReprocessing} = this.props;
                const page = getPageFromElement(startTarget);

                if (!page || selectedTextFieldRef.current.key === '') {
                  return;
                }

                const pageBoundingRect = {
                  ...boundingRect,
                  top: boundingRect.top - page.node.offsetTop,
                  left: boundingRect.left - page.node.offsetLeft,
                  pageNumber: page.number,
                };

                const viewportPosition = {
                  boundingRect: pageBoundingRect,
                  rects: [],
                  pageNumber: page.number,
                };

                const scaledPosition =
                  this.viewportPositionToScaled(viewportPosition);

                if (selectedTextFieldRef.current.key.includes('invoiceLineColumn_')) {
                  onSelectAreaForLinesReprocessing(this.getNewHighlightForLineReprocessing(scaledPosition));
                  return;
                }

                const { newHighlight, overlappingHighlightIDs } = this.getNewAndOverlappingHighlights(scaledPosition);

                this.setTip(
                  viewportPosition,
                  onSelectionFinished(
                    '',
                    newHighlight.position,
                    { text: newHighlight.content.text },
                    () => this.hideTipAndSelection(),
                    () =>
                      this.setState(
                        {
                          ghostHighlight: {
                            position: newHighlight.position,
                            content: { text: newHighlight.content.text },
                          },
                        },
                        () => {
                          resetSelection();
                          this.renderHighlights();
                        }
                      ),
                    overlappingHighlightIDs,
                    newHighlight.position,
                  )
                );
              }}
            />
        </div>
      </div>
    );
  }
}
