const isNotEmpty = (s) => s && s.length > 0;

const isValidPasswordMinLength = (s, l) => s.length >= l;

const isValidPasswordMaxLength = (s, l) => s.length <= l;

const isValidLength = (s, min, max) => s.length >= min && s.length <= max;

const containsSpace = (s) => /\s/.test(s);

const containsUppercaseLetter = (s) => /[A-Z]/.test(s);

const containsLowercaseLetter = (s) => /[a-z]/.test(s);

const containsDigit = (s) => /[0-9]/.test(s);

const containsSymbol = (s, exp) => s.indexOf(exp) > -1;

const containsSpecialCharacter = (s) => /[!"#$%&'()*+,-./:;<=>?@[\\\]^_{|}~]/.test(s);

const validations = {
  isNotEmpty,
  isValidPasswordMinLength,
  isValidPasswordMaxLength,
  isValidLength,
  containsSpace,
  containsUppercaseLetter,
  containsLowercaseLetter,
  containsDigit,
  containsSymbol,
  containsSpecialCharacter
};
export default validations;
