import Dashboard from 'src/dashboard/Dashboard';
import DashboardNew from 'src/dashboard-new/DashboardNew';
import DashboardLayout from 'src/shared/layouts/DashboardLayout';
import { useAuth } from 'src/hooks/useAuth';

const DashboardView = () => {
  const { user } = useAuth();
  const isOldAnnotationInterface = user?.customisations.includes('oldAnnotationInterface');

  return (
    <>
      {
        isOldAnnotationInterface ?
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout> : <DashboardNew />
      }
    </>
  );
}

export default DashboardView;
