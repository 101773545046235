import theme from 'src/theme';

const styles = {
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: '96%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
    outline: 'none',
  },
  halfPaper: {
    width: '52%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: theme.palette.icon.close,
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    marginLeft: 16,
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
    marginBottom: 24
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px'
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconSelect: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: 5,
  },
  plusIconContainer: {
    marginRight: -40
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
};

export default styles;
