import theme from "src/theme";

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: 600,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  halfPaper: {
    width: '90%',
    maxWidth: 500,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: theme.palette.icon.close,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  inputField: {
    marginTop: 10,
    color: theme.palette.text.primary,
  },
  groupBtn: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
  },
  btnGroup: {
    width: '100%',
    marginTop: 10,
  },
  cancelButton: {
    width: 108,
    height: 42,
    textTransform: 'none',
    color: theme.palette.text.primary,
    border: theme.palette.border.cancel
  },
  fieldContainer: {
    marginTop: 25
  },
  primaryTextColor: {
    color: theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 500,
  },
  instruction: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  userForm: {
    width: '100%'
  },
  validateButton: {
    width: 108,
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
    backgroundColor: theme.palette.button.action,
  },
};

export default styles;
