import { alpha } from "@mui/material";
import theme from "src/theme";

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    border: '1px solid #E1E4E8',
    boxSizing: 'border-box',
    borderRadius: 50,
    height: 29,
    cursor: 'pointer',
    width: 29
  },
  uploading: {
    color: theme.palette.status.digitized,
    backgroundColor: alpha(theme.palette.statusbg.digitized, 0.3)
  },
  digitized: {
    color: theme.palette.status.digitized,
    backgroundColor: alpha(theme.palette.statusbg.digitized, 0.3)
  },
  toreview: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  reviewed: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  tovalidate: {
    color: theme.palette.status.tovalidate,
    backgroundColor: alpha(theme.palette.statusbg.tovalidate, 0.3)
  },
  toexport: {
    color: theme.palette.status.toexport,
    backgroundColor: alpha(theme.palette.statusbg.toexport, 0.3)
  },
  exported: {
    color: theme.palette.status.exported,
    backgroundColor: alpha(theme.palette.statusbg.exported, 0.3)
  },
  rejected: {
    color: theme.palette.status.rejected,
    backgroundColor: alpha(theme.palette.statusbg.rejected, 0.3)
  },
  archived: {
    color: theme.palette.status.archived,
    backgroundColor: alpha(theme.palette.statusbg.archived, 0.3)
  },
  outoflimit: {
    color: theme.palette.status.outoflimit,
    backgroundColor: alpha(theme.palette.statusbg.outoflimit, 0.3)
  },
  errored: {
    color: theme.palette.status.errored,
    backgroundColor: alpha(theme.palette.statusbg.errored, 0.3)
  },
  splitRequest: {
    color: theme.palette.status.splitRequest,
    backgroundColor: alpha(theme.palette.statusbg.splitRequest, 0.3)
  },
  INVOICE_EDIT_FORM_INVOICE_TYPE_INVOICE: {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.iType.invoice, 1)
  },
  INVOICE_EDIT_FORM_INVOICE_TYPE_PURCHASE_ORDER: {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.iType.purchaseOrder, 1)
  },
  INVOICE_EDIT_FORM_INVOICE_TYPE_CREDIT_INVOICE: {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.iType.creditNote, 1)
  },
  INVOICE_EDIT_FORM_INVOICE_TYPE_ATTACHMENT: {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.iType.attachment, 1)
  },
};

export default styles;
