import theme from "src/theme";

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  halfPaper: {
    width: '90%',
    maxWidth: '680px',
    minWidth: '380px',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  tabsSection: {
    width: '100%',
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    filter: 'drop-shadow(0px 2px 0px rgba(51, 77, 110, 0.1))'
  },
  tab: {
    height: '100%',
    width: '100%',
    margin: '0px 16px'
  },
  selectedTabButton: {
    border: '0px solid',
    borderBottomWidth: 3,
    borderBottomColor: theme.palette.line.primary,
    width: '100%',
    height: '100%',
    padding: '16px 16px',
    transition: 'none',
    borderRadius: 0,
    minWidth: 40
  },
  tabButton: {
    width: '100%',
    height: '100%',
    padding: '16px 16px',
    transition: 'none',
    borderRadius: 0,
    minWidth: 40,
  },
  tabLabelContainer: {},
  dotIconSelected: {
    color: theme.palette.text.highlight2,
    fontSize: 14
  },
  dotIcon: {
    color: '#C1C1C1',
    fontSize: 14
  },
  selectedTabLabel: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    color: theme.palette.text.highlight2
  },
  tabLabel: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    color: '#C1C1C1'
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    padding: '10px 0px 0px 0px'
  },
  loaderContainer: {
    padding: '22px 32px 12px 32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  progressText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
    margin: '20px 0px 0px 6px'
  },
  loaderText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
    margin: '6px 0px 0px 0px'
  },
  radioGroup: {
    width: '75%',
    minWidth: '370px'
  },
  importOptionContainer: {
    margin: '8px 0px 0px 0px',
    border: '1px solid #E4E4E4',
    borderRadius: 8,
    minHeight: 80
  },
  radioLabelContainer: {
    padding: '4px 0px 4px 8px'
  },
  radio: {
    color: '#C1C1C1',
  },
  optionTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
  },
  optionSubTitle: {
    fontSize: 13,
    color: '#334D6E',
  },
  uploadBtnContainer: {
    margin: '16px 0px 16px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.main,
    margin: '6px 0px 6px 0px'
  },
  instructionsContainer: {
    padding: 8,
    width: '75%',
    maxWidth: '600px',
    minWidth: '370px'
  },
  fileName: {
    fontSize: 13,
    color: '#334D6E',
    margin: '0px 0px 0px 0px',
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 0px 2px 0px'
  },
  dotIconInstruction: {
    color: '#334D6E',
    fontSize: 12
  },
  instructionLabel: {
    fontSize: 13,
    color: '#334D6E',
    margin: '0px 0px 0px 5px'
  },
  modalFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  backBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
    margin: '6px 0px 6px 0px'
  },
  mapFieldsSection: {
    overflow: 'scroll',
    maxHeight: 350,
    width: '90%',
    maxWidth: '600px',
    minWidth: '370px'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  fieldHeaderContainer: {
    width: 224,
  },
  fieldHeader: {
    fontSize: 15,
    color: '#334D6E',
    fontWeight: 600,
    margin: '10px 0px'
  },
  procysField: {
    fontSize: 13,
    color: '#334D6E',
    fontWeight: 400,
    minHeight: 32,
    width: 224,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
    margin: '0px 0px 10px 0px',
    cursor: 'default',
    justifyContent: 'flex-start'
  },
  fieldsSelect: {
    minHeight: 32,
    width: 224,
    margin: '0px 0px 10px 0px',
    border: '1px #e4e4e4 solid',
    color: '#334D6E',
    fontSize: 13,
    fontWeight: 400,
  },
  fieldsSelectText: {
    color: '#334D6E',
    fontSize: 13,
    fontWeight: 400,
  },
  fieldsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 240,
    overflow: 'scroll',
    padding: '16px 32px'
  },
  checkBoxLabel: {
    fontSize: 14,
    color: '#334D6E'
  },
  checkBox: {
    color: '#3E8AFF',
    paddingBottom: 0,
    paddingTop: 0
  },
  checkboxContainer: {
    marginRight: 8,
    marginBottom: 8
  },
};

export default styles;
