import Documents from 'src/documents/DocumentsListContainer';
import DocumentsNew from 'src/documents-new/DocumentsListNew';
import DashboardLayout from 'src/shared/layouts/DashboardLayout';
import { useAuth } from 'src/hooks/useAuth';

const DashboardView = () => {
  const { user } = useAuth();
  const isOldAnnotationInterface = user?.customisations.includes('oldAnnotationInterface');

  return (
    <>
      {
        isOldAnnotationInterface ?
          <DashboardLayout>
            <Documents />
          </DashboardLayout> : <DocumentsNew />
      }
    </>
  );
}

export default DashboardView;
