/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function FixedRangeIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.31043 14.0833L8.34168 16.1417C8.54029 16.3403 8.64411 16.5885 8.65314 16.8865C8.66217 17.1844 8.55835 17.4417 8.34168 17.6583C8.14307 17.8569 7.89029 17.9562 7.58335 17.9562C7.2764 17.9562 7.02363 17.8569 6.82501 17.6583L2.92501 13.7583C2.70835 13.5417 2.60001 13.2889 2.60001 13C2.60001 12.7111 2.70835 12.4583 2.92501 12.2417L6.82501 8.34166C7.04168 8.125 7.29446 8.02118 7.58335 8.0302C7.87224 8.03923 8.12501 8.15208 8.34168 8.36875C8.54029 8.58541 8.64411 8.83819 8.65314 9.12708C8.66217 9.41597 8.55835 9.66875 8.34168 9.88541L6.31043 11.9167H19.6625L17.6313 9.88541C17.4146 9.66875 17.3063 9.41145 17.3063 9.11354C17.3063 8.81562 17.4146 8.55833 17.6313 8.34166C17.8479 8.125 18.1052 8.01666 18.4031 8.01666C18.7011 8.01666 18.9583 8.125 19.175 8.34166L23.075 12.2417C23.2917 12.4583 23.4 12.7111 23.4 13C23.4 13.2889 23.2917 13.5417 23.075 13.7583L19.175 17.6583C18.9764 17.8569 18.7281 17.9608 18.4302 17.9698C18.1323 17.9788 17.875 17.875 17.6583 17.6583C17.4417 17.4417 17.3288 17.1844 17.3198 16.8865C17.3108 16.5885 17.4146 16.3312 17.6313 16.1146L19.6625 14.0833H6.31043Z" fill="#3E8AFF"/>
      <path d="M24.5 22.5C23.9477 22.5 23.5 22.0523 23.5 21.5V3.5C23.5 2.94772 23.9477 2.5 24.5 2.5C25.0523 2.5 25.5 2.94772 25.5 3.5V21.5C25.5 22.0523 25.0523 22.5 24.5 22.5Z" fill="#3E8AFF"/>
      <path d="M1.50002 22.5C0.947731 22.5 0.500015 22.0523 0.500015 21.5V3.5C0.500015 2.94772 0.947731 2.5 1.50002 2.5C2.0523 2.5 2.50002 2.94772 2.50002 3.5V21.5C2.50002 22.0523 2.0523 22.5 1.50002 22.5Z" fill="#3E8AFF"/>
    </svg>
  )
}

export default FixedRangeIcon