import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Button, Typography, SvgIcon, Grid, Tooltip, useMediaQuery, TextField, InputAdornment
} from '@mui/material';
import {
  AddCircle as PlusCircleIcon,
  Search as SearchIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import authService from 'src/utils/authService';
import {
  axiosHeaders, permissions, isActionPermitted, axiosDeleteHeaders, appendContactSupport,
  getLocalisedErrorString, isBranded
} from 'src/utils/helpers';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import CompanyEditForm from 'src/account/components/CompanyEditForm/CompanyEditForm';
import AddUserToCompanyForm from 'src/account/components/AddUserToCompanyForm/AddUserToCompanyForm';
import CompaniesTable from 'src/account/components/CompaniesTable/CompaniesTable';
import styles from './style';
import theme from 'src/theme';

function CompaniesList() {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { API, BRAND_NAME, LOGIN_PANEL_URL } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [total, setTotal] = useState(0);

  const [menuCompanyID, setMenuCompanyID] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [editCompanyOpen, setEditCompanyOpen] = useState(false);
  const [editCompany, setEditCompany] = useState({});
  const [addCompanyAndUserOpen, setAddCompanyAndUserOpen] = useState(false);

  const maxWidth = useMediaQuery('(max-width: 480px)');
  const minWidth = useMediaQuery('(min-width: 730px)');
  const maxWidthRows = useMediaQuery('(max-width: 635px)');

  const handleOpenAddCompanyAndUser = () => {
    setAddCompanyAndUserOpen(true);
  };

  const handleOpenMenu = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuCompanyID(id);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuCompanyID(null);
    setMenuAnchorEl(null);
  };

  const onEditCompany = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isActionPermitted(permissions.companyManage, user.permissions)) {
      return;
    }

    handleCloseMenu(event);

    setEditCompany(u);
    setEditCompanyOpen(true);
  };

  const onSwitchToCompany = async (event, c) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    try {
      const response = await Axios.get(
        `${API.changeCompany}/${encodeURIComponent(c.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_CHANGE_SUCCESS'), {
          variant: 'success'
        });
        if (window.location.pathname.indexOf('invoices/') >= 0) {
          window.location.href = '/documents';
        } else {
          window.location.reload();
        }
      }
    } catch (e) {
      enqueueSnackbar(
        appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(e?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_CHANGE_FAILURE', t),
          t
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  const onDeleteCompany = async (event, c) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    const body = {
      companyId: c.companyID.toString()
    };

    try {
      const response = await Axios.delete(
        `${API.deleteCompany}`,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body)
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_DELETE_SUCCESS'), {
          variant: 'success'
        });
        window.location.reload();
      }
    } catch (e) {
      enqueueSnackbar(
        appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(e?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_DELETE_FAILURE', t),
          t
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  const getAllCompanies = async (pageNo = page, pageLimit = limit, search = query) => {
    setLoading(true);
    try {
      const url = `${API.getCompaniesByUser}${user.email}?type=detailed&page=${pageNo + 1}&limit=${pageLimit}&search=${search}`;
      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success) {
        setCompanies(response.data.data);
        setTotal(response.data.total);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.email) {
      getAllCompanies();
    }
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    getAllCompanies(newPage, limit, query);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
    getAllCompanies(0, event.target.value, query);
  };

  const handleAddNewCompany = () => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error'
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    handleOpenAddCompanyAndUser();
  };

  const handleQueryChange = (e) => {
    e.persist();
    setQuery(e.target.value);
    getAllCompanies(0, limit, e.target.value);
  };

  const titleText = (
    <Typography style={styles.headerTitle}>
      {ready && t('ACCOUNT_SETTINGS_COMPANIES')}
    </Typography>
  );

  const searchBar = (
    <Grid style={styles.searchBar} className="search-bar">
      <Tooltip title={t('ACCOUNT_SETTINGS_COMPANIES_SEARCH')}>
        <TextField
          style={styles.queryField}
          sx={{ '&.MuiTextFieldRoot': styles.searchBarInput }}
          onChange={handleQueryChange}
          placeholder={ready && t('ACCOUNT_SETTINGS_COMPANIES_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: theme.palette.icon.gray }}
                >
                  <SearchIcon style={{ color: theme.palette.icon.gray }} />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </Grid>
  );

  const addButton = (
    <Tooltip title={user.isSubCompany
      ? (ready && t('ACCOUNT_SETTINGS_COMPANIES_ADD_COMPANY_DISABLE_TOOLTIP'))
      : (isActionPermitted(permissions.companyAdd, user.permissions)
        ? ready && t('ACCOUNT_SETTINGS_COMPANIES_ADD_COMPANY_TOOLTIP')
        : t('ACCOUNT_SETTINGS_COMPANIES_ADD_PERMISSION'))}
    >
      <span>
        <Button
          variant="contained"
          style={styles.action}
          onClick={handleAddNewCompany}
          disabled={
            user.isSubCompany || (isBranded(BRAND_NAME) && user.isSubCompany)
            || !isActionPermitted(permissions.companyAdd, user.permissions)
          }
        >
          <SvgIcon
            fontSize="small"
            style={styles.actionIcon}
          >
            <PlusCircleIcon />
          </SvgIcon>
          {ready && t('ACCOUNT_SETTINGS_COMPANIES_ADD_COMPANY')}
        </Button>
      </span>
    </Tooltip>
  );

  return (
    <>
      <Grid style={styles.header}>
        {
          minWidth ? (
            <Grid container style={styles.header}>
              {titleText}
              {searchBar}
              {user && addButton}
            </Grid>
          ) : (
            <Grid container style={styles.headerColumn}>
              {titleText}
              {searchBar}
              {user && addButton}
            </Grid>
          )
        }
      </Grid>
      {
        (companies && companies.length > 0) || loading
          ? (
            <CompaniesTable
              loading={loading}
              menuAnchorEl={menuAnchorEl}
              menuCompanyID={menuCompanyID}
              handleOpenMenu={handleOpenMenu}
              handleCloseMenu={handleCloseMenu}
              companies={companies}
              onEditCompany={onEditCompany}
              onSwitchToCompany={onSwitchToCompany}
              onDeleteCompany={onDeleteCompany}
              maxWidth={maxWidth}
              total={total}
              page={page}
              limit={limit}
              maxWidthRows={maxWidthRows}
              handlePageChange={handlePageChange}
              handleLimitChange={handleLimitChange}
            />
          )
          : <EmptyText str="ACCOUNT_SETTINGS_COMPANIES_NO_COMPANIES" />
      }
      <CompanyEditForm
        editCompanyOpen={editCompanyOpen}
        user={user}
        editCompany={editCompany}
        handleClose={() => setEditCompanyOpen(false)}
        getAllCompanies={getAllCompanies}
      />
      <AddUserToCompanyForm
        addCompanyAndUserOpen={addCompanyAndUserOpen}
        handleClose={() => setAddCompanyAndUserOpen(false)}
        getAllCompanies={getAllCompanies}
      />
    </>
  );
}

// CompaniesResults.propTypes = {
//   className: PropTypes.string,
//   users: PropTypes.array,
//   history: PropTypes.shape({
//     push: PropTypes.func
//   })
// };

// CompaniesResults.defaultProps = {
//   users: []
// };

export default CompaniesList;
