import { Grid } from '@mui/material';

import ProfileOverview from 'src/account/components/ProfileOverview/ProfileOverview';
import UserPreference from 'src/account/components/UserPreference/UserPreference';
import Security from 'src/account/components/Security/Security';

function GeneralSettings() {

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
      >
        <ProfileOverview />
        <UserPreference />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
      >
        <Security />
      </Grid>
    </Grid>
  );
}

export default GeneralSettings;
