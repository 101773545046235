import { useState, useEffect, useRef } from 'react';
import { Document as PDFDocument, Page } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { Box } from '@mui/material';

import PdfActions from 'src/documents-new/components/SplitMenu/PdfActions/PdfActions';
import styles from './style';

type ViewType = {
  totalPages:number;
  document:any;
  fileName?:string;
}

const PdfView = ({totalPages,document:_doc}:ViewType) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [,setNumPages] = useState<number | null>(null);
  const [,setPageNumber] = useState<number>(1);
  const [doc,setDocument] = useState<any>(_doc);
  const [scale,setScale] = useState<number>(1);
  const [zoom,setZoom] = useState<number>(50);
  const rotate = useState<number>(0);
  const [steps,setSteps] = useState<number>(0);
  const parentRef = useRef<HTMLDivElement | null>(null)
  const pageRef = useRef<HTMLDivElement | null>(null);
  const refZoom = useRef<number>(50);
  const [observer,setObserver] = useState<any>(null);

  const onDocumentLoadSuccess = ({numPage}:any)=>{
    setNumPages(numPage);
    setPageNumber(1);
    let start = setTimeout(()=>{
      if(pageRef && pageRef.current){
        let pages = window.document.querySelectorAll('.pdf-page');
        pages.forEach(ePage =>{
          observer.observe(ePage); 
        })
        clearTimeout(start);
      }
    },3000)
  }

  const onZoom = (z:number)=>{
    if(zoom > 0 && z > 0 && zoom < 100 && z <= 100){
      if(z > zoom ) {
        let tempSteps = steps * (z - zoom);
        setScale(scale + tempSteps);
      }
      else{ 
        let tempSteps = steps * (zoom - z);
        setScale(scale - tempSteps);
      }
    }
    else if(z > 100 || zoom > 100){
      setZoom(100);
      refZoom.current = 100;
      setScale(100*steps);
      return;      
    }
    else if(z < 0 || zoom < 0){
      setZoom(1)
      refZoom.current = 1;
      setScale(1*steps);
      return;      
    }
    else{
      if(z > zoom ) {
        let tempSteps = steps * (z - zoom);
        setScale(scale + tempSteps);
      }
      else{ 
        let tempSteps = steps * (zoom - z);
        setScale(scale - tempSteps);
      }
    }
    setZoom(z);
    refZoom.current = z;
  }

  const handleZoomFit = (size:string) =>{
    if(observer){
      let pages = window.document.querySelectorAll('.pdf-page');
      pages.forEach(ePage =>{
        observer.unobserve(ePage); 
      })
    }
     onZoom(Number(size));
     let start = setTimeout(()=>{
        let pages = window.document.querySelectorAll('.pdf-page');
        pages.forEach(ePage =>{
          observer.observe(ePage); 
        })
        clearTimeout(start);
      },1000)
  }

  const handleScrollToView = (pos:number) =>{
    if(parentRef && parentRef.current){
      let div = parentRef?.current?.children[0].children[0];
      let el = div.children[pos];
      el.scrollIntoView({behavior:'smooth',block:'end',inline:'nearest'})
    }
  }

  useEffect(()=>{
    if(_doc && totalPages > 0){
      setDocument(_doc);
    }
  },[_doc,totalPages])

  useEffect(()=>{
    if(parentRef && parentRef?.current){
      let maxW:number = Math.floor(parentRef?.current.clientWidth);
      let child:number = 120;
      let maxScale:number = Number((((maxW-40)/child)).toFixed(2));
      let tempStep = maxScale/100;
      let tScale = tempStep * zoom;
      setScale(tScale);
      setSteps(tempStep);  
    }
       
    const handleResize = () =>{
      try{
        if(parentRef && parentRef.current){
          let maxW:number = Math.floor(parentRef?.current.clientWidth);
          let child:number = 120;
          let maxScale:number = Number((((maxW-40)/child)).toFixed(2));
          let tempStep = maxScale/100;
          let tScale = tempStep * refZoom?.current;
          setScale(tScale);
          setSteps(tempStep);
        }
      }
      catch(e){
        console.error(e,'e')
      }
      
    }
    window.addEventListener('resize',handleResize);
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[])

  useEffect(()=>{
    if(parentRef && parentRef.current){
      setObserver(new IntersectionObserver((entries:any)=>{
          entries.forEach((eEntry:any) =>{
            let isVisble:boolean = eEntry.isIntersecting === true;
            if(isVisble){
              let el = eEntry.target.className.split('-').at(-1);
              setCurrentPage(Number(el));
            }
          })
        },{root:document.getElementById('pdf-viewer-div'),threshold:0.5})
      )
    }
    
  },[parentRef?.current])

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content} ref={parentRef} className="y-scroll">
        <div id="p-wrapper" style={{width:'fit-content',aspectRatio:'0.707/1',marginBottom:'70px',
          height:'fit-content',border:'none',padding:'5px',display:'flex',
          justifyContent:'center'}}>
          <PDFDocument className="pdf-page-wrapper" rotate={rotate[0]} file={doc?doc:''} onLoadSuccess={onDocumentLoadSuccess} >
          {totalPages > 0 ?
            Array.from(
              new Array(totalPages),
              (el:any,index:number) => (
                <Page 
                  width={120}
                  inputRef={pageRef}
                  scale={scale}
                  className={`each-pdf-page pdf-page pdf-page-${index+1}`}
                  key={`page_${index+1}`}
                  pageNumber={index+1}
                />
              )
            )
            :<></>}
          </PDFDocument>
        </div>
      </Box>
      <Box sx={styles.foot}>
        <PdfActions
          zoom={zoom}
          setZoom={onZoom}
          currentPage={currentPage}
          document={_doc}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          rotate={rotate}
          handleScrollToView={handleScrollToView}
          handleZoomFit={handleZoomFit}
        />
      </Box>
    </Box>
  )
}

export default PdfView