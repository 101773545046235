import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { DocType } from 'src/types';
import styles from './style';

type PropsType = { 
  onClose:Function;
  doc:any;
  handleProcessDocument: (_doc: DocType, _action: string) => Promise<void>
 }

const SideMenu = ({ onClose, doc, handleProcessDocument } : PropsType) => {
  const { ready, t} = useTranslation();

  return (
    <Box sx={styles.root}>
      <Box sx={styles.head}>
        <Typography sx={styles.headTitle}>{ready && t('DUPLICATE_MENU_TITLE')}</Typography>
      </Box>
      <Box sx={styles.content} className="y-scroll">
        <Box sx={styles.topSpace} />
        <Box sx={styles.rangeFoot}>
          <Button sx={styles.proceedBtn} onClick={() => handleProcessDocument(doc, 'proceed')}>
            {ready && t('INVOICE_DUPLICATE_NO_DUPLICATE')}
          </Button>
          <Button sx={styles.deleteBtn} onClick={() => handleProcessDocument(doc, 'delete')}>
            {ready && t('INVOICE_DUPLICATE_DELETE')}
          </Button>
        </Box>
      </Box>
      <Box sx={styles.bottom}>
        <Button sx={styles.cancelBtn} onClick={()=>onClose()}>
          {ready && t('INVOICE_SPLIT_CANCEL')}
        </Button>
      </Box>
    </Box>
  )
}

export default SideMenu;