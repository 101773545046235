import { useState, useEffect, useContext } from 'react';

import {
  Button, Container, Grid, Typography
} from '@mui/material';

import ConfigContext from 'src/contexts/ConfigContext';
import Page from 'src/shared/screens/Page';

const InvoiceImageNotFoundView = () => {
  const {
    BRAND_NAME, BRAND_DOMAIN, BRAND_URL, API
  } = useContext(ConfigContext);

  const [company, setCompany] = useState('');
  const [transactionNumber, setTransactionNumber] = useState('');

  useEffect(() => {
    const { search } = window.location;

    if (search && search.indexOf('&') > 0) {
      const params = search.split('&');
      if (params.length > 0) {
        for (let i = 0; i < params.length; i++) {
          if (params[i].indexOf('tx=') >= 0) {
            const parts = params[i].split('=');

            if (parts.length > 0) {
              setTransactionNumber(parts[1]);
            }
          }

          if (params[i].indexOf('company=') >= 0) {
            const parts = params[i].split('=');

            if (parts.length > 0) {
              setCompany(parts[1]);
            }
          }
        }
      }
    }
  }, []);

  return (
    <>
      <Page
        style={styles.root}
        title={`${BRAND_NAME} Statistics`}
      >
        <Container style={styles.container}>
          <Grid style={styles.innerContainer}>
            <Button onClick={() => window.open(BRAND_URL, '_blank')} style={styles.logoBtn}>
              <img src={`${API.brandLogo}${BRAND_DOMAIN}`} alt="" style={styles.logo} />
            </Button>
            <Typography style={styles.message}>
              {
                transactionNumber && company
                  ? `PDF Invoice Image Not Available for Transaction Number(${transactionNumber}) for the Company(${company}).`
                  : 'PDF Invoice Image Not Available.'
              }
            </Typography>
          </Grid>
          <Grid style={styles.copyrightContainer}>
            <Typography>
              Copyright © 2024
              {' '}
              <a
                href={BRAND_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={styles.copyrightLink}
              >
                {BRAND_NAME}
              </a>
              , All rights reserved.
            </Typography>
          </Grid>
        </Container>
      </Page>
    </>
  );
}

export default InvoiceImageNotFoundView;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100vh',
    // paddingTop: 36,
    // paddingBottom: 36,
  },
  container: {
    minHeight: 'calc(100vh - 72px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 36,
    paddingBottom: 36
  },
  innerContainer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    marginTop: 100,
    fontFamily: 'Poppins',
    fontSize: 20,
    color: '#333333',
    fontWeight: 300,
    textAlign: 'center',
  },
  logoBtn: {
    marginTop: 60,
  },
  logo: {
    width: 200,
    height: 50,
  },
  copyrightContainer: {
    textAlign: 'center',
    lineHeight: 24,
    fontStyle: 'italic',
    fontSize: 14,
    color: '#6a6a6a',
    display: 'flex',
    alignItems: 'flex-end'
  },
  copyrightLink: {
    textDecoration: 'none',
    color: '#38A0F1',
  },
  backdrop: {
    zIndex: 1111,
    color: '#ffffff',
  },
};
