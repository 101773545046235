import {
  useState, useEffect, useRef, useContext
} from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, isActionPermitted, permissions } from 'src/utils/helpers';
import EditSupplierForm from 'src/dimensions/suppliers/components/EditSupplierForm/EditSupplierForm';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

const EditSupplierModal = (props) => {
  const { t } = useTranslation();

  const {
    editSupplierOpen,
    setEditSupplierOpen,
    supplierId,
    handleEditSupplierClose,
    getAllSuppliers,
    isInvoiceEditForm,
    renderInvoiceXML,
    renderInvoicePDF,
    scrollXMLViewBy,
    company,
  } = props;

  const { API } = useContext(ConfigContext);
 
  const auth = useAuth();
  const {user} = auth;

  const [supplier, setSupplier] = useState(null);
  const [loading, setLoading] = useState(false);

  const [xmlSelectedValue, setXmlSelectedValue] = useState('');
  const [textFieldSelectedValue, _setTextFieldSelectedValue] = useState('');
  const textFieldSelectedValueRef = useRef(textFieldSelectedValue);
  const setTextFieldSelectedValue = (val) => {
    textFieldSelectedValueRef.current = val;
    _setTextFieldSelectedValue(val);
  };
  const [textFieldSelectedKey, setTextFieldSelectedKey] = useState('');

  const getSupplier = async () => {
    if (!editSupplierOpen) {
      return;
    }
    try {
      setLoading(true);
      const response = await Axios.get(
        `${API.suppliersV2}/${supplierId}/${company}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSupplier(response.data.data);
        setLoading(false);
      } else {
        setEditSupplierOpen(false);
        setLoading(false);
      }
    } catch (error) {
      setEditSupplierOpen(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user.permissions) && supplierId && supplierId !== '-1') {
      getSupplier();
    }
  }, [supplierId]);

  if (!supplier) {
    return null;
  }

  return (
    <Modal
      style={styles.modal}
      open={editSupplierOpen}
      onClose={handleEditSupplierClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={editSupplierOpen}>
        <div style={(user.interfacePreference !== 'twinfield_interface' && !isInvoiceEditForm) ? styles.halfPaper : styles.paper}>
          {loading ? (<LoadingScreen />) : (
            <>
              <Grid style={styles.modalHeader}>
                <Typography style={styles.headerTitle}>
                  {`${t('INVOICE_EDIT_FORM_SUPPLIER')} - ${supplier.creditorName ? supplier.creditorName : ''} (${supplier.creditorCode})`}
                </Typography>
                <IconButton onClick={handleEditSupplierClose}>
                  <CloseIcon style={styles.closeIcon} />
                </IconButton>
              </Grid>
              {isInvoiceEditForm ? (
                <Grid container>
                  <Grid item xs={12} md={4} style={styles.leftContainer}>
                    <EditSupplierForm
                      supplier={supplier}
                      handleEditSupplierClose={handleEditSupplierClose}
                      getAllSuppliers={getAllSuppliers}
                      isInvoiceEditForm={isInvoiceEditForm}
                      xmlSelectedValue={xmlSelectedValue}
                      textFieldSelectedKey={textFieldSelectedKey}
                      textFieldSelectedValueRef={textFieldSelectedValueRef}
                      setTextFieldSelectedKey={setTextFieldSelectedKey}
                      setTextFieldSelectedValue={setTextFieldSelectedValue}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} style={styles.invoiceContainer}>
                    {renderInvoiceXML
                      ? renderInvoiceXML(xmlSelectedValue, setXmlSelectedValue, textFieldSelectedValue, scrollXMLViewBy, false, textFieldSelectedKey)
                      : renderInvoicePDF()}
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <EditSupplierForm
                    supplier={supplier}
                    handleEditSupplierClose={handleEditSupplierClose}
                    getAllSuppliers={getAllSuppliers}
                    isInvoiceEditForm={isInvoiceEditForm}
                    xmlSelectedValue={xmlSelectedValue}
                    textFieldSelectedKey={textFieldSelectedKey}
                    textFieldSelectedValueRef={textFieldSelectedValueRef}
                    setTextFieldSelectedKey={setTextFieldSelectedKey}
                    setTextFieldSelectedValue={setTextFieldSelectedValue}
                  />
                </Grid>
              )}
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

// EditSupplierModal.propTypes = {
//   editSupplierOpen: PropTypes.bool,
//   setEditSupplierOpen: PropTypes.func,
//   supplierId: PropTypes.string,
//   handleEditSupplierClose: PropTypes.func,
//   getAllSuppliers: PropTypes.func,
//   renderInvoiceXML: PropTypes.func,
//   isInvoiceEditForm: PropTypes.bool,
//   scrollXMLViewBy: PropTypes.func,
//   renderInvoicePDF: PropTypes.func,
//   company: PropTypes.string,
// };

// EditSupplierModal.defaultProps = {};

export default EditSupplierModal;
