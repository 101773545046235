const styles = {
  submitBtn: {
    marginRight: '8px',
    marginLeft: '8px',
    backgroundColor: '#3E8AFF',
  },
  modalFooter: {
    width: '100%',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  captionBody: {
    display: 'flex',
    flexDirection: 'column'
  },
  caption: {
    color: '#4C4E64',
    fontSize: '13px',
  },
  ulStyle: {
    marginLeft: '24px',
    fontSize: '13px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  listText: {
    color: '#4C4E64',
    fontSize: '12px',
  }
};

export default styles;
