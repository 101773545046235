import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

import { Box, Modal, Backdrop, CircularProgress } from '@mui/material'

import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { axiosHeaders } from 'src/utils/helpers';
import DocumentEdit from 'src/document-edit/DocumentEdit';
import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import PreviewNavigation from './PreviewNavigation';
import styles from './style';

type PropTypes = {
  open:boolean;
  doc:any;
  docs:any;
  handleClose:Function;
  page:number | string;
  limit:number | string;
  index:number;
  total:number;
};

const PreviewDocument = ({ open, doc, docs, handleClose, page, limit, index, total } : PropTypes) => {
  const [files, setFiles] = useState<any>(null);
  const [navPos, setNavPos] = useState<number>(0);
  const [navCount, setNavCount] = useState<number>(0);
  const [totalNav, setTotalNav] = useState<number>(0);
  const [activeInvoice, setActiveInvoice] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentDocumentPos, setCurrentDocumentPos] = useState<number>(0);
  const [disablePreviewMove, setDisablePreviewMove] = useState<boolean[]>([false, false]);
  const [disableMove, setDisableMove] = useState<boolean[]>([false, false]);
  const { API } = useConfig();
  const { user } = useAuth();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const getOtherInvoice = async (invoicePage: number,query:string = '') => {
    const queryVal = new URLSearchParams(history.location.search);
    const thisSearch = query || queryVal.get('query');
    const thisFilter = queryVal.get('status');
    const thisTime = queryVal.get('time');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter') || 'false');
    const suppliersList = JSON.parse(window.sessionStorage.getItem('selectedSuppliers') || '[]');
    const ownerFilter = JSON.parse(queryVal.get('owner_filter') || 'false');
    const ownersList = JSON.parse(window.sessionStorage.getItem('selectedOwners') || '[]');
    const companyFilter = JSON.parse(queryVal.get('company_filter') || 'false');
    const companiesList = JSON.parse(window.sessionStorage.getItem('selectedCompanies') || '[]');
    const thisStartTime = queryVal.get('start_time');
    const thisEndTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export') || 'false');
    const sortBy = queryVal.get('sort_by');
    const order = queryVal.get('order');
    let thisSuppliers = '';
    if (supplierFilter && Array.isArray(suppliersList)) {
      thisSuppliers = suppliersList.map((supplier) => supplier.creditorCode).join(',');
    } else if (supplierFilter && suppliersList) {
      thisSuppliers = suppliersList;
    }
    let thisOwners = '';
    if (ownerFilter && Array.isArray(ownersList)) {
      thisOwners = ownersList.map((owner) => owner.email).join(',');
    } else if (ownerFilter && ownersList) {
      thisOwners = ownersList;
    }
    let thisCompanies = [];
    if (companyFilter && companiesList.length > 0 && companiesList[0].companyId) {
      thisCompanies = companiesList.map((company: {name: string, companyId: string}) => company.companyId);
    } else if (companyFilter && companiesList.length > 0) {
      thisCompanies = companiesList;
    }
    try {
      let url = `${API.getAllInvoices}/${encodeURIComponent(user?.companyID || '')}?page=${invoicePage}&limit=1${thisSearch && thisSearch.trim() 
        ? `&search=${thisSearch}` : ''}&order=${sortBy
        ? `${order}&sortby=${sortBy}` : 'desc'}${thisFilter !== null
        ? `&filter=${thisFilter}` : ''}${failedToExport
        ? '&failedToExport=true' : ''}`;
      if (thisStartTime !== null && thisEndTime !== null) {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime !== null ? `&time=${thisTime}` : ''}`;
      }
      const body = { suppliers: thisSuppliers, owners: thisOwners, companies: thisCompanies };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data.length > 0) {
        return response.data.data[0];
      }
      return null;
    } catch (error) {
      return null;
    }
  };
  const fetchPrevAndNextInvoice = async (invoiceNumber:number) =>{
    const href:string = window?.location?.href;
    let query = '';
    if(href.includes('query')){
      query= href.split('query')[1].split('=')[1]
    }
    return await getOtherInvoice(invoiceNumber,query);
    
  }

  const getImages = async (names:any[],invoices:any[],docPos:number) => {
    if (names && names.length === 0 ) {
      return;
    }    
    try {
      const requests = names.map(async(eName:string) => await Axios.get(
        `${API.getInvoiceImage}${eName}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      ))
      const responses:any[] = await Promise.allSettled(requests);      
      let tempFiles:any[] = [];
      for(let i = 0;i < responses.length; i++){
        const eachResponse = responses[i];
        if(eachResponse.status === 'fulfilled'){
          let blob = eachResponse?.value?.data;
          let file = window.URL.createObjectURL(blob);
          tempFiles[i] = {
            invoice:invoices[i],
            image:file,
          }
        }
        else{
          tempFiles[i] = {
            invoice:invoices[i],
            image:null
          }
        }
      }
      setFiles(tempFiles);
      setActiveInvoice(tempFiles[docPos].invoice);

    } catch (err) {
      console.error(err);
    }
  };

  const getSingeImage = async (name:string,invoice:any) =>{
    if (!name || !invoice) {
      return {
        image:'',
        invoice:invoice || {}        
      };
    } 
    try{
      let response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
          Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
        }
      })
      let blob = response.data;
      let file = window.URL.createObjectURL(blob);
      return {invoice,image:file};
    }
    catch(e){
      console.error(e);
    }
    
  }
  
  const getSingleName = async (doc:any) =>{
    let imageName = '';
    const nameParts = doc?.documentFile?.split('.');
    if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
      imageName = `${nameParts[0]}-display.pdf`;
    } else {
      imageName = doc?.documentFile
    }
    let newFile =  await getSingeImage(imageName,doc);
    return newFile;
  }

  const moveUp = async () =>{
    if(navPos > 0){
      let tempPos = navPos -1;
      setNavPos(tempPos);
      setNavCount(navCount => navCount-1);
      const tempCount = currentDocumentPos + (navCount -1);
      if(tempCount === 1){
        setDisablePreviewMove([true,false]);
      }
      else{
        setDisablePreviewMove([false,false])
      }
    }
    else if(navPos === 0){

      let tempCount = currentDocumentPos +  (navCount - 1);
      if(tempCount >= 1){
        setNavCount(navCount => navCount -1);
        if(tempCount === 1){
          setDisablePreviewMove([true,false]);
        }
        setLoading(true);

        const tempResult = await fetchPrevAndNextInvoice(tempCount);
        const tempStatus = tempResult?.status;
        if(tempStatus > 100 && tempStatus !== 200 && tempStatus !== 201 ){
          let newFile = await getSingleName(tempResult);
          let tempNewFiles = [newFile,...files.slice(0,2)];
          setFiles([...tempNewFiles]);
          setLoading(false);
          
        }
        else{
          setLoading(false);
          enqueueSnackbar(t('DOCUMENT_CANT_BE_PREVIEW_OR_ERROR_OCCURED'), {
            variant: 'error',
            autoHideDuration: 5000
          });
          handleClose();
        }
        
      }      
    }
  }

  const moveDown = async () =>{
    if(navPos < totalNav-1){
      setNavPos(navPos + 1);
      setNavCount(navCount => navCount + 1);
      const tempCount = currentDocumentPos + (navCount + 1);
      if(tempCount === total){
        setDisablePreviewMove([false,true]);
      }
      else{
        setDisablePreviewMove([false,false])
      }
    }
    else if(navPos === totalNav -1 ){
      const tempCount = currentDocumentPos + (navCount + 1);
      if(tempCount <= total){
        setNavCount(navCount => navCount + 1);
        if(tempCount === total){
          setDisablePreviewMove([false,true]);
        }
        setLoading(true);
        const tempResult = await fetchPrevAndNextInvoice(tempCount);
        const tempStatus = tempResult?.status;
        if(tempStatus > 100 && tempStatus !== 200 && tempStatus !== 201 ){
          let newFile = await getSingleName(tempResult);
          let tempNewFiles = [...files.slice(1,3),newFile];
          setFiles([...tempNewFiles]);
          setLoading(false);
        }
        else{
          setLoading(false);
          enqueueSnackbar(t('DOCUMENT_CANT_BE_PREVIEW_OR_ERROR_OCCURED'), {
            variant: 'error',
            autoHideDuration: 5000
          });
          handleClose();
        }
        
      }
    }
  }

  const moveNext = async() =>{
    if(navPos < totalNav-1){
      setLoading(true);
      let tempPos = navPos + 1;
      setNavPos(tempPos);
      setFiles([...files])
      handleSetActiveInvoice({...files[tempPos].invoice},tempPos);
      setLoading(false);
    }
    else if(navPos === totalNav-1){
      if(currentDocumentPos < total){
        setLoading(true);
        const tempResult = await fetchPrevAndNextInvoice(currentDocumentPos+1);
        const tempStatus = tempResult?.status;
        if(tempStatus > 100 && tempStatus !== 200 && tempStatus !== 201 ){
          setCurrentDocumentPos(currentDocumentPos +1);
          if(currentDocumentPos+1 >= total){
            setDisableMove([false, true])
          }
          else{
            setDisableMove([false, false])
          }
          let newFile = await getSingleName(tempResult);
          setActiveInvoice({...tempResult});
          if(newFile?.invoice?.id !== files[totalNav-1]?.invoice?.id){
            let tempNewFiles = [...files.slice(1,3),newFile];
            setFiles([...tempNewFiles]);
          }
          setLoading(false);
        }
        else{
          setLoading(false);
          enqueueSnackbar(t('DOCUMENT_CANT_BE_PREVIEW_OR_ERROR_OCCURED'), {
            variant: 'error',
            autoHideDuration: 5000
          });
          handleClose();
        }
        
      }
    }
  }

  const movePrevious = async() =>{
    if(navPos > 0){
      setLoading(true);
      let tempPos = navPos -1;
      setNavPos(tempPos);
      setFiles([...files])
      handleSetActiveInvoice({...files[tempPos].invoice},tempPos);
      setLoading(false);
    }
    else if(navPos === 0){
      if(currentDocumentPos > 1){
        setLoading(true);
        const tempResult = await fetchPrevAndNextInvoice(currentDocumentPos-1);
        const tempStatus = tempResult?.status;
        if(tempStatus > 100 && tempStatus !== 200 && tempStatus !== 201 ){
          setCurrentDocumentPos(currentDocumentPos -1);
          if(currentDocumentPos -1 <= 1){
            setDisableMove([true,false])
          }
          else{
            setDisableMove([false, false])
          }
          let newFile = await getSingleName(tempResult);
          setActiveInvoice({...tempResult});
          if(newFile?.invoice?.id !== files[0].invoice.id){
            let tempNewFiles = [newFile,...files.slice(0,2)];
            setFiles([...tempNewFiles]);
          } 
          setLoading(false);
        }
        else{
          setLoading(false);
          enqueueSnackbar(t('DOCUMENT_CANT_BE_PREVIEW_OR_ERROR_OCCURED'), {
            variant: 'error',
            autoHideDuration: 5000
          });
          handleClose();
        }
        
      }      
    }
  }

  const handleSetActiveInvoice = (invoice:any,nPos:number)=>{
    if(navPos === nPos)return;
    else if(navPos !== nPos){
      setActiveInvoice(invoice);
      setNavPos(nPos);
      setNavCount(0);
      if(navPos > nPos){
        const tempPos = currentDocumentPos - Math.abs(navPos - nPos)
        setCurrentDocumentPos(tempPos);
        if(tempPos > 1  && tempPos < total){
          setDisablePreviewMove([false,false])
          setDisableMove([false,false])
        }
        else if(tempPos > 1 && tempPos === total){
          setDisablePreviewMove([false,true])
          setDisableMove([false,true]);
        }
        else{
          setDisablePreviewMove([true,false])
          setDisableMove([true,false])
        }
      }
      else{
        const tempPos = currentDocumentPos + Math.abs(navPos - nPos)
        setCurrentDocumentPos(tempPos);
        if(tempPos < total){
          setDisablePreviewMove([false,false])
          setDisableMove([false,false])
        }
        else{
          setDisablePreviewMove([false,true])
          setDisableMove([false,true])
        }
      }
    }
    
  }

  useEffect(()=>{
    if(doc){
      let tempIndex = index+1;
      let tempPage = parseInt(page.toString(),10);
      let tempLimit = parseInt(limit.toString(),10);
      if(tempPage > 0){
        tempIndex = (tempLimit) + index+1
      }
      setCurrentDocumentPos(tempIndex);
      if(tempIndex === 1 && total > 1){
        setDisableMove([true,false])
        setDisablePreviewMove([true,false])
      }
      else if(tempIndex === total && total === 1){
        setDisableMove([true,true])
        setDisablePreviewMove([true,true]);
      }
      else if(tempIndex === total && total > 1){
        setDisableMove([false,true])
        setDisablePreviewMove([false,true])
      }
      else{
        setDisableMove([false,false])
        setDisablePreviewMove([false,false]);
      }
      let docArray:any[] = [];
      let pos = 0;
      let pickPos = 0;
      let i = 0;
      for(i = 0; i < docs.length; i++){
        if(docs[i].id === doc.id){
          pos = i;
          break;
        }
      }
      pickPos = pos;
      let tempNavPos = 0;
      if(docs.length === 1){
        setTotalNav(1);
        setNavPos(0);
        tempNavPos = 0;
      }
      else if(pos + 2 <= docs.length - 1){
          setTotalNav(3);
          setNavPos(0);
      }
      else if(pos + 1 === docs.length - 1 && docs.length >= 3 ){
          setTotalNav(3);
          setNavPos(1);
          tempNavPos = 1;
          pickPos = pos-1
      }
      else if(pos === docs.length -1 && docs.length >= 3){
        setTotalNav(3);
         setNavPos(2);
         tempNavPos = 2;
         pickPos = pos-2;
      }
      else if(pos + 1 === docs.length - 1 && docs.length === 2){
        setTotalNav(2)
        setNavPos(0);
        tempNavPos = 0;
        pickPos = 0
      }
      else if(pos === docs.length - 1 && docs.length === 2){
        setNavPos(1);
        setTotalNav(2);
        tempNavPos = 1;
        pickPos = 0;
      }
      let count = 0;
      for(let i = pickPos; i < docs.length && count < 3; i++){
          docArray.push(docs[i]);
          count++;
      }
      const getStarted = async () => {
        let docNames = [...docArray];
        docNames = docNames.map(eDoc => {
          const nameParts = eDoc?.documentFile?.split('.');
            let imageName = '';
            if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
              imageName = `${nameParts[0]}-display.pdf`;
            } else {
              imageName = eDoc?.documentFile
            }
            return imageName;
        })
        await getImages(docNames,docArray,tempNavPos);
      }
      setNavCount(0);
      getStarted()
    }
  },[doc]);


  return (
    <Modal
      style={styles.modal}
      open={open}
      onClose={()=>handleClose()}
    >
      <Box sx={styles.root}>
        <SideBarLayout navBarChildren={
          <PreviewNavigation files={files && files.length ? files?.slice(0,3):[]} 
            activeInvoice={activeInvoice} disablePreviewMove={[...disablePreviewMove]}
            handleActiveInvoice={handleSetActiveInvoice}  navPos={navPos} previousDoc={moveUp} 
            nextDoc={moveDown}
          />
        }>
          <DocumentEdit
            assistantID={activeInvoice?.assistantID || ''}
            handleClose={handleClose} 
            setLoading={setLoading}
            moveNext={moveNext}
            movePrevious={movePrevious}
            disableMove={disableMove}
          />  
          <Backdrop style={styles.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </SideBarLayout>
      </Box>
    </Modal>
  )
}

export default PreviewDocument;