import { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { countries, getEmojiFlag } from 'countries-list';
import { useHistory } from 'react-router-dom';

import {
  Backdrop, Box, Button, Fade, FormControl, FormHelperText, Grid, IconButton, Modal, TextField, Typography, Autocomplete
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import {
  appendContactSupport, axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions
} from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

function CompanyEditForm({
  editCompanyOpen,
  user,
  handleClose,
  editCompany,
  getAllCompanies,
}) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { API } = useContext(ConfigContext);

  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const countryData = [];
    Object.keys(countries).forEach((key) => {
      const data = {
        code: key,
        name: countries[key]?.name
      };
      countryData.push(data);
    });

    setCountryList(countryData);
  }, []);

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      style={styles.modal}
      open={editCompanyOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={editCompanyOpen}>
        <div style={styles.paper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {ready && t('ACCOUNT_SETTINGS_COMPANIES_MANAGE')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Formik
            initialValues={{
              id: editCompany.id,
              name: editCompany.name || '',
              email: editCompany.email || '',
              vatNumber: editCompany.vatNumber || '',
              address: editCompany.address || '',
              postalCode: editCompany.postalCode || '',
              contactNumber: editCompany.phone || '',
              country: editCompany.country || '',
              city: editCompany.city || ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_COMPANIES_NAME_REQ')),
              address: Yup.string().max(255).required(t('CREATE_COMPANY_ADDR_REQ')),
              postalCode: Yup.string().max(255).required(t('CREATE_COMPANY_POSTAL_CODE_REQ')),
              contactNumber: Yup.string().required(t('CREATE_COMPANY_CONTACT_NO_REQ'))
                .matches(/^([+\d]\d*)$/, t('CREATE_COMPANY_CONTACT_NO_INVALID'))
                .min(9, t('CREATE_COMPANY_CONTACT_NO_MIN'))
                .max(16, t('CREATE_COMPANY_CONTACT_NO_MAX')),
              country: Yup.string().max(255).required(t('CREATE_COMPANY_COUNTRY_REQ')),
              city: Yup.string().max(255).required(t('CREATE_COMPANY_CITY_REQ')),
            })}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                // Make API request
                const body = {
                  id: values.id.toString(),
                  name: values.name,
                  email: values.email,
                  vatNumber: values.vatNumber,
                  address: values.address,
                  postalCode: values.postalCode,
                  contactNumber: values.contactNumber,
                  country: values.country,
                  city: values.city,
                  companyId: editCompany.companyID.toString()
                };
                const response = await Axios.put(
                  API.company,
                  body,
                  axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
                );
                if (response.data.success) {
                  setStatus({ success: true });
                  setSubmitting(false);
                  enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_EDIT_SUCCESS'), {
                    variant: 'success'
                  });
                  handleClose();
                  getAllCompanies();
                  history.push('/account', { tab: 3 });
                } else {
                  setSubmitting(false);
                }
              } catch (error) {
                setErrors({
                  submit: appendContactSupport(
                    window.config.support_email,
                    getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_EDIT_FAILURE', t),
                    t
                  )
                });
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                style={styles.root}
                onSubmit={handleSubmit}
              >
                <Grid style={styles.userForm}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.vatNumber && errors.vatNumber)}
                        fullWidth
                        helperText={touched.vatNumber && errors.vatNumber}
                        label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_VAT_NUMBER')}`}
                        name="vatNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.vatNumber}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid style={styles.fieldContainer}>
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_ADDRESS')}`}
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.address}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.postalCode && errors.postalCode)}
                        fullWidth
                        helperText={touched.postalCode && errors.postalCode}
                        label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_POSTAL_CODE')}`}
                        name="postalCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.postalCode}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.contactNumber && errors.contactNumber)}
                        fullWidth
                        helperText={touched.contactNumber && errors.contactNumber}
                        label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_CONTACT_NUMBER')}`}
                        name="contactNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.contactNumber}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                      <FormControl variant="outlined" style={styles.formControl}>
                        <Autocomplete
                          fullWidth
                          name="country"
                          onChange={(e, v) => handleChange({ target: { name: 'country', value: v.code } })}
                          value={values.country ? Object.keys(countries).map((key) => ({
                            code: key,
                            name: countries[key]?.name
                          })).find((o) => o.code === values.country) : null}
                          options={countryList}
                          getOptionLabel={(option) => `${getEmojiFlag(option.code)} ${option.name}`}
                          isOptionEqualToValue={(option, value) => option.code === value.code}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COUNTRY')}`}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                              }}
                              style={styles.selectStyle}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.city && errors.city)}
                        fullWidth
                        helperText={touched.city && errors.city}
                        label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_CITY')}`}
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.city}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>
                      {t(errors.submit)}
                    </FormHelperText>
                  </Box>
                  )}
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                      variant="text"
                      style={styles.closeBtn}
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      {ready && t('ACCOUNT_SETTINGS_COMPANIES_CANCEL')}
                    </Button>
                    <Button
                      style={styles.submitBtn}
                      type="submit"
                      disabled={isSubmitting || !isActionPermitted(permissions.companyManage, user.permissions)}
                    >
                      {ready && t('ACCOUNT_SETTINGS_COMPANIES_EDIT_COMPANY')}
                    </Button>
                  </Box>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </Fade>
    </Modal>
  );
}

// CompanyEditForm.propTypes = {
//   className: PropTypes.string,
//   editCompanyOpen: PropTypes.bool,
//   ready: PropTypes.bool,
//   user: PropTypes.object.isRequired,
//   history: PropTypes.shape({
//     push: PropTypes.func
//   }),
//   editCompany: PropTypes.object,
//   handleClose: PropTypes.func.isRequired,
//   getAllCompanies: PropTypes.func.isRequired,
//   t: PropTypes.func,
// };

export default CompanyEditForm;
