import theme from 'src/theme';

const styles = {
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  },
};

export default styles;
