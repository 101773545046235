import { useContext, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Grid, IconButton
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';

import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from '../style';

const AssistantAPIIntegration = () => {
  const { API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  // const [checkLoading, setCheckLoading] = useState(false);
  const [appError, setAppError] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [copyObj, setCopyObj] = useState({
    username: false,
    password: false
  });

  const onChangeCopy = (prop) => {
    const iCopyObj = {
      username: false,
      password: false
    };

    iCopyObj[prop] = true;

    setCopyObj(iCopyObj);
  };

  const copyToClipboard = (prop, value) => {
    copy(value, {
      onCopy: onChangeCopy(prop)
    });
  };

  const getDetails = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.getAPIDetails}/${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setUsername(response.data.data.username);
        setPassword(response.data.data.password);
        setLoading(false);
      } else {
        setAppError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t));
      }
    } catch (error) {
      setLoading(false);
      setAppError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t));
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  // const onCheckConnection = async () => {
  //   setCheckLoading(true);
  //   try {
  //     const response = await Axios.get(
  //       `${API.getAPIDetails}/${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
  //     );
  //     if (response.data.success) {
  //       enqueueSnackbar(
  //         'Congrats! You\'ve successfully connected to API.',
  //         {
  //           variant: 'success',
  //           autoHideDuration: 5000
  //         }
  //       );
  //       setCheckLoading(false);
  //     } else {
  //       enqueueSnackbar(
  //         appendContactSupport(window.config.support_email, 'Something went wrong.'),
  //         {
  //           variant: 'error',
  //           autoHideDuration: 5000
  //         }
  //       );
  //     }
  //   } catch (error) {
  //     setCheckLoading(false);
  //     enqueueSnackbar(
  //       appendContactSupport(window.config.support_email, 'Something went wrong.'),
  //       {
  //         variant: 'error',
  //         autoHideDuration: 5000
  //       }
  //     );
  //   }
  // };

  if (loading) {
    return <LoadingIndicator />;
  }

  if (appError) {
    return (
      <Grid style={styles.errorContainer}>
        <span style={styles.labelTextError}>
          {appError}
        </span>
      </Grid>
    );
  }

  return (
    <Grid style={styles.detailsContainer}>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_ASST_API_USERNAME')}</span>
        </Grid>
        <input
          type="text"
          value={username}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('username', username)}>
          <CopyIcon />
        </IconButton>
        {copyObj.username && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_ASST_API_PASSWORD')}</span>
        </Grid>
        <input
          fullWidth
          type="password"
          value={password}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('password', password)}>
          <CopyIcon />
        </IconButton>
        {copyObj.password && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      {/* <Grid style={styles.checkContainer}>
        <Button
          variant="outlined"
          style={styles.checkConnectionBtn}
          onClick={checkLoading ? () => {} : onCheckConnection}
        >
          Check connection
        </Button>
        {checkLoading && <CircularProgress style={styles.loadingProgress} size={20} />}
      </Grid> */}
    </Grid>
  );
};

export default AssistantAPIIntegration;
