import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Backdrop, CircularProgress } from '@mui/material';

import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import { RouteParams } from 'src/document-edit/documentTypes';
import { useConfig } from 'src/hooks/useConfig';
import { RootState } from 'src/types';
import DocumentEdit from 'src/document-edit/DocumentEdit';
import LoadingScreen from 'src/shared/screens/LoadingScreen';

const DocumentEditContainer = () => {
  const match = useRouteMatch<RouteParams>();
  const { t } = useTranslation();
  const { BRAND_NAME } = useConfig();

  const fieldsLoading: boolean = useSelector((state: RootState) => state.fields.fieldsLoading);
  const [loading, setLoading] = useState<boolean>(false);

  if (fieldsLoading) {
    return <LoadingScreen />;
  }

  return (
    <SideBarLayout title={`${t('INVOICE_EDIT_PAGE_TITLE')} | ${BRAND_NAME}`}>
      <DocumentEdit
        assistantID={match && match.params ? match.params.id : '0'}
        setLoading={setLoading}
      />
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBarLayout>
  );
}

export default DocumentEditContainer;

const styles = {
  backdrop: {
    zIndex: 1111111,
    color: '#ffffff',
  },
}
