const styles = {
  pdfActionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%'
  },
  pdfActionsComponent: {
    maxWidth: 480,
    height: 60,
    backgroundColor: '#596274',
    borderRadius: 4,
    padding: '16px 20px',
    boxShadow: '0px 6px 18px -8px #4C4E648F',
    display: 'flex',
    justifycontent: 'center',
    alignItems: 'center',
  },
  zoomButtonIcon: {
    width: 20,
    height: 20,
    color: '#ffffff'
  },
  zoomButton: {
    padding: 0,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  zoomPercentageText: {
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 16,
    marginRight: 8,
    width: 32
  },
  pdfActionButton: {
    color: '#ffffff',
    marginLeft: 8,
    marginRight: 8,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionIcon: {
    width: 20,
    height: 20,
  },
  zoomOutMapIcon: {
    width: 18,
    height: 18,
  },
  pdfPageText: {
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 8,
    marginRight: 8
  },
  pdfActionsPageNumberInput: {
    width: 32,
    // textAlign: 'center',
    backgroundColor: '#777F8D',
    borderRadius: 2
  },
  pdfActionsSlider: {
    width: 115,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 6
  },
};

export default styles;
