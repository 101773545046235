import { alpha } from '@mui/material';
import theme from 'src/theme';

const styles = {
  root: {},
  popper: {
    zIndex: 111111,
    width: '90%',
    maxWidth: 500,
    maxHeight: '60%',
    overflow: 'scroll',
    padding: '0px 20px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  paper: {
    width: 540,
    maxHeight: '94%',
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  halfPaper: {
    width: '90%',
    maxWidth: 500,
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: theme.palette.icon.close,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  userForm: {
    width: '100%'
  },
  fieldContainer: {
    marginTop: 25
  },
  submitBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    textTransform: 'none',
    background: theme.palette.secondary.main,
    boxShadow: `0px 4px 10px ${alpha(theme.palette.secondary.main, 0.25)}`,
    borderRadius: 4
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    color: theme.palette.icon.close,
    textTransform: 'none',
    marginRight: 24
  },
  moreButton: {
    textTransform: 'none'
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    textTransform: 'capitalize',
  },
}

export default styles;
