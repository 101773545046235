const styles = {
  root:{
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '400px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    flexShrink: '0',
    position: 'relative',
    zIndex:2,
  },
  head:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 15px',
    height: '70px',
    borderBlockEnd: '1px solid #4C4E6433',
  },
  headTitle: {
    color: '#4C4E64',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  content:{
    height: 'calc(100% - 67.5px - 54.5px - 61px )',
    maxHeight: 'calc(100% - 67.5px - 54.5px - 61px )',
    overflow: 'clip auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    boxShadow: '0px -4px 8px -4px #4C4E6433',
    '& > div:first-of-type':{
      width: '100%',
      height: 'calc(100% - 69px)',
      overflow: 'clip auto', 
    }
  },
  bottom:{
    padding: '15px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderBlockStart: '1px solid #4C4E6433',
  },
  cancelBtn:{
    color: '#4C4E64DE',
    fontSize: '14px',
    textTransform: 'none',
    height: '38px',
    padding: '7px 20px',
  },
  topSpace: {
    flex: 1,
  },
  rangeFoot:{
    width: '100%',
    boxSizing: 'border-box',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > button':{
      paddingInline: '25px',
      fontSize: '14px',
      textTransform: 'none',
      height: '38px',
      borderRadius: '4px',
      padding: '7px 22px',
    }
  },
  proceedBtn: {
    backgroundColor: 'transparent',
    color: '#4C4E64',
    border: '1px solid #4C4E6433',
  },
  deleteBtn: {
    backgroundColor: '#F7685B',
    border: '1px solid #F7685B33',
    color: '#FFFFFF',
    '&:hover': {
      background: '#d94a3d',
    }
  },
}

export default styles;