import { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Box, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import {
  FiberManualRecord as FiberManualRecordIcon
} from '@mui/icons-material';

import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import ConfigContext from 'src/contexts/ConfigContext';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { CustomError } from 'src/types';
import authService from 'src/utils/authService';
import styles from './style';

interface PropTypes {
  open: boolean;
  handleClose: () => void;
}

const listOfFeatures = [
  'NEW_UI_MESSAGE_LIST_OF_FEATURES_01',
  'NEW_UI_MESSAGE_LIST_OF_FEATURES_02',
  'NEW_UI_MESSAGE_LIST_OF_FEATURES_03',
  'NEW_UI_MESSAGE_LIST_OF_FEATURES_04',
  'NEW_UI_MESSAGE_LIST_OF_FEATURES_05',
];

const NewUIMessageModal = ({
  open,
  handleClose,
}: PropTypes) => {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { API, BRAND_NAME, LOGIN_PANEL_URL, SUPPORT_EMAIL } = useContext(ConfigContext);

  const location = useLocation();
  const history = useHistory();

  const updateHasSeenNewUI = async () => {
    try {
      const response = await Axios.get(
        API.updateHasSeenNewUI,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        return;
      }
    } catch (e) {
      return;
    }
  };

  const updateAnnotationFlowPreference = async (old: boolean) => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        { customisation: 'oldAnnotationInterface', enabled: old },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ANNOTATION_INTERFACE_UPDATE_SUCCESS'), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t('ANNOTATION_INTERFACE_UPDATE_FAILURE'), {
          variant: 'error',
        });
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'ANNOTATION_INTERFACE_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };
  
  const handleCancel = async () => {
    await updateHasSeenNewUI();
    await updateAnnotationFlowPreference(true);
    const currentUrl = `${location.pathname}${location.search}`;
    history.push(`${currentUrl.replace('documents-new', 'documents')}`);
    window.location.reload();
  };

  const handleSubmit = async () => {
    await updateHasSeenNewUI();
    await updateAnnotationFlowPreference(false);
    handleClose();
    window.location.reload();
  };

  return (
    <CommonModal
      open={open}
      title={`🎉  ${t('NEW_UI_MESSAGE_TITLE')}`}
      cancelText={t('NEW_UI_MESSAGE_STAY_WITH_OLD_DESIGN')}
      submitText={t('NEW_UI_MESSAGE_GO_TO_NEW_DESIGN')}
      showSubmitIcon={true}
      width='500px'
      handleClose={handleClose}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <Typography style={styles.textNormal}>
        {ready && (
          <Trans i18nKey="NEW_UI_MESSAGE_LINE_01">
            We have good news, <strong>we have released a new design for annotation flow!</strong>
          </Trans>
        )}
      </Typography>
      <Box sx={{ height: '24px' }} />
      <Typography sx={styles.textNormal}>
        {ready && t('NEW_UI_MESSAGE_LIST_OF_FEATURES_TITLE')}
      </Typography>
      <List style={styles.list}>
        {listOfFeatures.map((item) => (
          <ListItem key={item} style={styles.listItem}>
            <FiberManualRecordIcon style={styles.bulletPoint} />
            <ListItemText
              disableTypography
              primary={(
                <Typography style={styles.listItemText}>
                  {ready && t(item)}
                </Typography>
              )}
            />
          </ListItem>
        ))}
      </List>
      <Box sx={{ height: '24px' }} />
      <Typography style={styles.textNormal}>
        {ready && (
          <Trans i18nKey="NEW_UI_MESSAGE_INSTRUCTIONS">
            <Link sx={styles.link} href={t('NEW_UI_MESSAGE_KB_VIDEO_LINK')} rel="noreferrer" target="_blank">Check our 3 min video</Link>
            to see how to work with new design! If you hesitant to use it, you always can stay with
            it old version and change your preferences in settings or write to us on
            <Link sx={styles.link} href={`mailto:${SUPPORT_EMAIL}`} rel="noreferrer" target="_blank">{SUPPORT_EMAIL}.</Link>
          </Trans>
        )}
      </Typography>
      <Box sx={{ height: '24px' }} />
      <Typography sx={styles.textNormal}>
        {ready && t('NEW_UI_MESSAGE_PROCYS_TEAM', { brand: BRAND_NAME })}
      </Typography>
    </CommonModal>
  );
};

export default NewUIMessageModal;
