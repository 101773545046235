const styles = {
  modal: {
    zIndex: 9999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      borderRadius: '10px'
    }
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #4C4E641F',
    padding: '16px 24px',
    marginBottom: '16px'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeBtn: {
    padding: '0px',
  },
  closeIcon: {
    height: '20px',
    width: '20px',
    color: '#6D788D',
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#4C4E64',
    lineHeight: '36px',
    letter: '0.25px',
  },
  headerSubTitle: {
    color: '#4C4E64DE',
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: '400'
  },
  modalContent: {
    padding: '0 24px'
  },
  modalFooter: {
    width: '100%',
    boxShadow: '0px -4px 8px -4px #4C4E646B',
    padding: '16px 24px',
    marginTop: '24px'
  },
  cancelBtn: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#4C4E64',
    textTransform: 'none',
    minWidth: '108px',
    height: '36px',
    border: '1px #4C4E6433 solid',
    '&:hover': {
      border: '1px #4C4E6433 solid',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 8px -4px #4C4E646B',
    }
  },
  submitBtn: {
    minWidth: '108px',
    height: '36px',
    textTransform: 'none',
    marginLeft: '10px',
    backgroundColor: '#3E8AFF',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3E8AFF',
      boxShadow: '0px 4px 8px -4px #4C4E646B',
    }
  },
  submitIcon: {
    width: '16px',
    height: '20px'
  },
};

export default styles;
