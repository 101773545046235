/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

import {
  Button, Card, InputAdornment, SvgIcon, TablePagination, TextField, Typography,
  Grid, Menu, MenuItem, Hidden, useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  recentFilterOptions, glaMsg, vatMsg, cctMsg, prjtMsg, defaultMsg
} from 'src/config';
import { applyLogsFilters } from 'src/utils/helpers';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import DimensionLogsTable from './DimensionLogsTable/DimensionLogsTable';
import styles from './style';
import theme from "src/theme";

function DimensionLogsListResults({
  className, logs, getLogs, fetchingLogs, total, history, ...rest
}) {
  const { dim, code, type } = rest.match.params;
  const selectedLogs = [];
  const { ready, t } = useTranslation();

  const [displayLogs, setDisplayLogs] = useState(logs || []);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({
    type: null,
    status: null,
    recent: null,
  });

  let dimTitle;
  let glType;
  let searchTitle;
  let filterTitle;
  let backToOverview;

  const maxWidth = useMediaQuery('(min-width: 1500px)');
  const maxWidthRows = useMediaQuery('(max-width: 525px)');

  switch (dim) {
    case 'glaccounts':
      dimTitle = glaMsg.GLA_TITLE;
      searchTitle = glaMsg.GLA_SEARCH_LOG;
      backToOverview = glaMsg.GLA_BACK_TO_OVERVIEW;
      filterTitle = glaMsg.GLA_DASHBOARD_FILTERED;
      break;
    case 'vatcodes':
      dimTitle = vatMsg.VAT_CODE_TITLE;
      searchTitle = vatMsg.VAT_CODE_SEARCH_LOG;
      backToOverview = vatMsg.VAT_CODE_BACK_TO_OVERVIEW;
      filterTitle = vatMsg.VAT_CODE_DASHBOARD_FILTERED;
      break;
    case 'costcenters':
      dimTitle = cctMsg.COST_CENTER_TITLE;
      searchTitle = cctMsg.COST_CENTER_SEARCH_LOG;
      backToOverview = cctMsg.COST_CENTER_BACK_TO_OVERVIEW;
      filterTitle = cctMsg.COST_CENTER_DASHBOARD_FILTERED;
      break;
    case 'projects':
      dimTitle = prjtMsg.PROJECT_TITLE;
      searchTitle = prjtMsg.PROJECT_SEARCH_LOG;
      backToOverview = prjtMsg.PROJECT_BACK_TO_OVERVIEW;
      filterTitle = prjtMsg.PROJECT_DASHBOARD_FILTERED;
      break;
    default:
      dimTitle = defaultMsg.DEFAULT_TITLE;
      searchTitle = defaultMsg.DEFAULT_SEARCH_LOG;
      backToOverview = defaultMsg.DEFAULT_BACK_TO_OVERVIEW;
      filterTitle = defaultMsg.DEFAULT_DASHBOARD_FILTERED;
      break;
  }

  if (type === 'pnl') {
    glType = glaMsg.GLA_PNL;
  } else if (type === 'bas') {
    glType = glaMsg.GLA_BALANCE;
  }

  useEffect(() => {
    setDisplayLogs(logs);
  }, [logs]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const executeGetLogs = (p, l, q) => {
    getLogs(p, l, q);
  };

  const gotoLogsList = () => {
    let url = `/dimensions/${dim}`;

    if (dim === 'glaccounts') {
      url = `/dimensions/${dim}/${type}`;
    }
    history.push(url);
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
    setPage(0);

    setDisplayLogs([]);
    executeGetLogs(0, limit, event.target.value);
  };

  const handleRecentFilterChange = (filter) => {
    let value = null;
    if (filter !== 0) {
      value = filter;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      recent: value
    }));
    handleClose();
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setDisplayLogs([]);

    executeGetLogs(newPage, limit, query);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
    setDisplayLogs([]);

    executeGetLogs(0, event.target.value, query);
  };

  // Usually query is done on backend with indexing solutions
  const sortedLogs = displayLogs;
  const filteredLogs = applyLogsFilters(sortedLogs, filters);
  const paginatedLogs = filteredLogs;

  const searchBar = (
    <Grid className={'search-bar'} style={styles.searchBar}>
      <TextField
        style={styles.queryField}
        onChange={handleQueryChange}
        placeholder={ready && t(searchTitle)}
        value={query}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                fontSize="small"
                color="action"
                style={{ fill: theme.palette.icon.gray }}
              >
                <SearchIcon style={{ color: theme.palette.icon.gray }} />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );

  const backToLogs = (
    <Grid>
      <Button style={styles.backBtn} onClick={gotoLogsList}>
        <ArrowBackIcon style={styles.icon} />
        {ready && t(backToOverview)}
      </Button>
    </Grid>
  );

  const titleText = (
    <Typography style={styles.title}>
      {`${ready && t(dimTitle)}
      ${(ready && glType) ? `- ${t(glType)}` : ''}${rest.code ? `: ${rest.code}` : ''}`}
    </Typography>
  );

  const filter = (
    <Grid>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={styles.filterBtn}
      >
        <SvgIcon fontSize="small" style={{ marginRight: 8 }}>
          <FilterListIcon style={styles.filterIcon} />
        </SvgIcon>
        {ready && t(filterTitle)}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // classes={{
        //   paper: classes.filterMenu
        // }}
      >
        {
          recentFilterOptions && recentFilterOptions.length > 0 && recentFilterOptions.map((o) => (
            <MenuItem
              key={o.value}
              style={styles.filterMenuItem}
              onClick={() => handleRecentFilterChange(o.value)}
            >
              {t(o.label)}
            </MenuItem>
          ))
        }
      </Menu>
    </Grid>
  );

  const paginationTab = () => (
    <TablePagination
      component="div"
      // classes={{
      //   caption: classes.paginationCaption,
      //   selectRoot: classes.paginationCaption,
      //   menuItem: classes.paginationCaption,
      //   actions: classes.paginationActions,
      // }}
      count={total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('DIMENSION_ROWS_PER_PAGE')}
    />
  );

  return (
    <Grid>
      {
        code && code > 0
          ? (
            <>
              <Hidden only={['xs', 'sm']}>
                {backToLogs}
                <Grid style={{ ...styles.header, ...styles.headerMargin }}>
                  {titleText}
                  <Grid style={{ width: 24 }} />
                  {searchBar}
                  <Grid style={{ width: 24 }} />
                  {filter}
                </Grid>
              </Hidden>
              <Hidden only={['xs', 'md', 'lg', 'xl']}>
                {backToLogs}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header}>
                    {searchBar}
                    <Grid style={{ width: 24 }} />
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {backToLogs}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header}>
                    {searchBar}
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
            </>
          )
          : (
            <>
              <Hidden only={['xs', 'sm', 'md']}>
                {backToLogs}
                {
                  maxWidth ? (
                    <Grid style={{ ...styles.header, ...styles.headerMargin }}>
                      {titleText}
                      <Grid style={{ width: 24 }} />
                      {searchBar}
                      <Grid style={{ width: 24 }} />
                      {filter}
                    </Grid>
                  ) : (
                    <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                      <Grid style={styles.header}>
                        {titleText}
                      </Grid>
                      <Grid style={styles.header}>
                        {searchBar}
                        <Grid style={{ width: 24 }} />
                        {filter}
                      </Grid>
                    </Grid>
                  )
                }
              </Hidden>
              <Hidden only={['xs', 'sm', 'lg', 'xl']}>
                {backToLogs}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header}>
                    {searchBar}
                    <Grid style={{ width: 24 }} />
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={['xs', 'md', 'lg', 'xl']}>
                {backToLogs}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header} />
                  <Grid style={styles.header}>
                    {searchBar}
                    <Grid style={{ width: 24 }} />
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {backToLogs}
                <Grid style={{ ...styles.headerColumn, ...styles.headerMargin }}>
                  <Grid style={styles.header}>
                    {titleText}
                  </Grid>
                  <Grid style={styles.header} />
                  <Grid style={styles.header}>
                    {searchBar}
                    {filter}
                  </Grid>
                </Grid>
              </Hidden>
            </>
          )
      }
      <Card
        style={styles.root}
        className={className}
        {...rest}
      >
        <div style={styles.bulkOperations}>
          <div style={styles.bulkActions}>
            <div />
            <>
              {paginationTab()}
            </>
          </div>
        </div>
        {
          filteredLogs.length === 0 && <Grid style={styles.breakLine} />
        }
        <PerfectScrollbar>
          {
            fetchingLogs ? <LoadingIndicator /> : (
              <DimensionLogsTable
                {...rest}
                paginatedLogs={paginatedLogs}
                selectedLogs={selectedLogs}
                getLogs={() => getLogs(page, limit, query)}
              />
            )
          }
        </PerfectScrollbar>
        {paginatedLogs?.length > 0 && paginationTab()}
      </Card>
    </Grid>
  );
}

export default DimensionLogsListResults;
