import theme from 'src/theme';
const styles = {
  appBar:{
    backgroundColor: '#F3F5F9',
    boxShadow: 'none',
    padding: '8px 0',
  },
  desktopBar:{
    gap: '24px',
    alignItems: 'center'
  },
  subBtn:{
    borderRadius: '8px',
    fontSize: '12px',
    color: '#3E8AFF',
    backgroundColor: '#3E8AFF26',
    padding: '3px 10px',
    textTransform: 'none',
  },
  statsContainer:{
    display: 'flex',
    color: '#4C4E64',
    alignItems: 'center',
    fontSize: '14px',
    gap: '5px',
  },
  icon:{
    fontSize: '20px',
    color:'#6D788D'
  },
  fadeText:{
    color: '#4C4E6480'
  },
  eachMenu:{
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    gap: '10px',
    padding: '3px 3px',
    color: '#4C4E64',
    fontSize:'14px'
  },
  textWrapper:{
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    maxWidth: '161px',
    fontSize: '15px'
  },
  menuContainer:{
    boxShadow:' 0px 4px 8px -4px #4C4E646B',

  },
  companyBtn:{
    display: 'flex',
    gap: '10px',
    color: '#4C4E64'
  },
  menuActionContainer: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: '0px solid #E1E4E8',
    borderTopWidth: 1
  },
  menuActionContainerTop: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  defaultMenu:{
    ':hover':{
      backgroundColor: '#fff'
    }
  },
  kbIcon: {
    padding: 0,
    color: '#90A0B7'
  },
  notificationIcon: {
    color: '#90A0B7',
  },
  rightContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}

export default styles;
