import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Axios from 'axios';
import { useDispatch, useSelector,  } from 'react-redux';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';

import { Box, Grid, Backdrop, CircularProgress } from "@mui/material";

import {
  axiosHeaders, isActionPermitted, permissions
} from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { fetchGlaList } from 'src/dimensions/glas/actions';
import { useConfig } from 'src/hooks/useConfig';
import TopDisplay from "src/dimensions-new/components/TopDisplay";
import TablePagination from 'src/dimensions-new/components/TablePagination.';
import DimensionsLayout from "src/dimensions-new/DimensionsNew";
import Search from 'src/dimensions-new/components/Search';
import GlasTable from 'src/dimensions-new/components/DimensionsTable';
import LoadingScreen from 'src/shared/screens/LoadingScreen';


const Glas = () => {
  const { ready, t } = useTranslation();
  const [showImport, setShowImport] = useState<boolean>(false);
  const [resetSearch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoading,setShowLoading] = useState<boolean>(false);
  const [limit,setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [query,setQuery] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const { user } = useAuth();
  const { API } = useConfig();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  
  const { glaList, total } = useSelector((state:any) => state.gla);
  
  const title = ready ? t('DASHBOARD_GLAS') : '';

    const handleSearchChange = (str:string) =>{
      setQuery(str);
      getAllGLAccounts(0,limit,str,false);
    }
  

  const handleDelete = (data:any) =>{
    console.log(data,'handle delete')
  }

  const handleExport = async (format:string, closeMenu:Function) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}glaccounts/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user?.company} General Ledger Accounts.${format}`;
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      closeMenu();
    } catch (error) {
      closeMenu();
      enqueueSnackbar(t('DIMENSION_EXPORT_FAIL'), {
        variant: 'error',
        persist: false
      });
    }
  };

  const getAllGLAccounts = async (page = 0, limit = 10, search = query, loadFullScreen=true) => {
    if(loadFullScreen){
      setLoading(true);
    }

    dispatch(fetchGlaList(user?.companyID, page, limit, search) as any);
    setLoading(false);
  };

  const onLimitChange = async (limit:number) =>{
    setLimit(limit);
    setShowLoading(true)
    await getAllGLAccounts(0,limit,query,false)
    setShowLoading(false);
  }
  const onPageChange = async (page:number)=>{
    setPage(page);
    setShowLoading(true);
    await getAllGLAccounts(page,limit,query,false);
    setShowLoading(false)
  }

  useEffect(()=>{
    if (isActionPermitted(permissions.dimensionRead, user?.permissions)) {
      getAllGLAccounts(page);
      setShowLoading(false)
    }
  },[])
  useEffect(()=>{
    if(glaList && Array.isArray(glaList)){
      setData(glaList.slice(0,limit))
    }
  },[glaList])
  useEffect(()=>{
    console.log(showLoading,'donfetch')
  },[showLoading])

  return (
    <DimensionsLayout {...{showImport, setShowImport}}>
      <Box sx={styles.root}  className="x-scroll">
      {
        !showLoading ?
        <Grid sx={styles.wrapper}>
          <TopDisplay 
            title={title}
            showImport={showImport} 
            setShowImport={setShowImport}
            type="general-ledger-account"
            onExport={handleExport}
          />
          <Search onChange={handleSearchChange} reset={resetSearch} />
          <GlasTable data={data} 
            type="glas" 
            onDelete={handleDelete}
            deleteText='DIMENSION_DELETE_LEDGER_BTN'
          />
          <TablePagination 
            total={total || 0}
            limit={limit}
            page={page}
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
          />      
        </Grid>  
        : <LoadingScreen />
      }
      </Box>
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DimensionsLayout>
  )
}

const styles={
  root:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-start',
    padding:'20px',
    
  },
  wrapper:{
    backgroundColor:'#fff',
    minWidth:'530px',
  },
  top:{
    display:'flex',
    alignItems:'center',
    width:'100%',
    padding:'5px 0',
    gap:'10px',
    marginBlock:'0 20px'
  },
  topLeft:{
    fontSize:'16px',
    color:'#4C4E64',
    fontWeight:'500',
    marginInlineEnd:'auto'
  },
  btns:{
    gap:'8px',
    color:'#3E8AFF',
    textTransform:'Capitalize',
    fontSize:'13px',
    paddingInline:'10px',
  },
  addBtn:{
    background:'#3E8AFF',
    color:'#fff',
    paddingInline:'15px',
    gap:'8px',
    textTransform:'Capitalize',
    fontSize:'13px',
    '&:hover':{
      backgroundColor:'#3E8AFF',
      color:'#fff'
    }
  },
  menuContainer:{
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    width:'200px',
  },
  exportIconContainer:{
    display:'flex',
    alignItems:'center',
    gap:'10px',
    fontSize:'14px',
    color:'#4C4E64DE'
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff',
  },
}
export default Glas