import { useTranslation } from 'react-i18next';

import Page from 'src/shared/screens/Page';
import MyPlan from 'src/subscription/components/MyPlan/MyPlan';
import { useConfig } from 'src/hooks/useConfig';
import styles from './style';

function Subscription() {
  const { ready, t } = useTranslation();
  const { BRAND_NAME } = useConfig();

  return (
    <Page
      style={styles.root}
      title={ready && `${t('MY_PLAN_PAGE_TITLE')} | ${BRAND_NAME}`}
    >
      <MyPlan />
    </Page>
  );
}

export default Subscription;
