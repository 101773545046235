import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, Fade, Autocomplete,
  FormControl, FormHelperText, Grid, IconButton, Modal, TextField, Typography
} from '@mui/material';
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import { invoiceHelperText, invoiceRegex } from 'src/config';
import styles from './style';

const CDCMetrics = (props) => {
  const { t, ready } = useTranslation();

  const {
    metricsOpen,
    handleMetricsClose,
    renderInvoiceXML,
    renderInvoicePDF,
    scrollXMLViewBy,
    fields,
    details,
    handleChangeMain,
    handleChangeText,
    detailsXML,
    invoice,
    onClickField,
    xmlSelectedValue,
    setXmlSelectedValue,
    textFieldSelectedValue,
    textFieldSelectedKey,
    handleSubmit,
    fieldOpen,
    validation,
    setFieldOpen,
    fieldRef,
  } = props;
  
  const noWhiteSpace = /.*\S.*$/;

  const [metricFields, setMetricFields] = useState({
    fat: [],
    protein: [],
    cell: [],
    bact: [],
    adj: [],
    lab: [],
    cuota: []
  });

  const metricLabels = {
    fat: 'CDC_DOC_METRICS_SECTION_FAT',
    protein: 'CDC_DOC_METRICS_SECTION_PROTEIN',
    cell: 'CDC_DOC_METRICS_SECTION_CELL',
    bact: 'CDC_DOC_METRICS_SECTION_BACT',
    adj: 'CDC_DOC_METRICS_SECTION_ADJ',
    lab: 'CDC_DOC_METRICS_SECTION_LAB',
    cuota: 'CDC_DOC_METRICS_SECTION_CUOTA'
  };

  const sortFields = () => {
    const newMetricFields = metricFields;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const { section } = field;
      if (section !== 'header') {
        newMetricFields[section].push(field);
      }
    }
    setMetricFields(newMetricFields);
  };

  useEffect(() => {
    sortFields();
  }, [fields]);

  const handleClickOnSave = async () => {
    try {
      await handleSubmit();
      handleMetricsClose();
    } catch (error) {
      // do nothing
    }
  };

  return (
    <Modal
      style={styles.modal}
      open={metricsOpen}
      onClose={handleMetricsClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={metricsOpen}>
        <div style={styles.paper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {t('CDC_DOC_METRICS_SECTION_TITLE')}
            </Typography>
            <IconButton onClick={handleMetricsClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} style={styles.leftContainer}>
              <Formik
                initialValues={{
                  milkFat: invoice.milkFat || '',
                  milkFatRef: invoice.milkFatRef || '',
                  milkFatAdj: invoice.milkFatAdj || '',
                  milkProt: invoice.milkProt || '',
                  milkProtRef: invoice.milkProtRef || '',
                  milkProtAdj: invoice.milkProtAdj || '',
                  milkCell: invoice.milkCell || '',
                  milkCellRef: invoice.milkCellRef || '',
                  milkCellAdj: invoice.milkCellAdj || '',
                  milkBact: invoice.milkBact || '',
                  milkBactRef: invoice.milkBactRef || '',
                  milkBactAdj: invoice.milkBactAdj || '',
                  milkQualAdj: invoice.milkQualAdj || '',
                  milkAaAdj: invoice.milkAaAdj || '',
                  milkPclAdj: invoice.milkPclAdj || '',
                  milkCuotaAdj: invoice.milkCuotaAdj || '',
                  milkHomoAdj: invoice.milkHomoAdj || '',
                  milkCompAdj: invoice.milkCompAdj || '',
                  milkFrioAdj: invoice.milkFrioAdj || '',
                  milkSaaAdj: invoice.milkSaaAdj || '',
                  milkAssocAdj: invoice.milkAssocAdj || '',
                  milkInhib: invoice.milkInhib || '',
                  milkInhibAdj: invoice.milkInhibAdj || '',
                  milkLoyalAdj: invoice.milkLoyalAdj || '',
                  milkHygiAdj: invoice.milkHygiAdj || '',
                  milkLabCost: invoice.milkLabCost || '',
                  milkLabAdj: invoice.milkLabAdj || '',
                  milkLabCostDeducted: invoice.milkLabCostDeducted || '',
                  milkCuota: invoice.milkCuota || '',
                  milkCuotaDelivered: invoice.milkCuotaDelivered || '',
                  milkCuotaRemaining: invoice.milkCuotaRemaining || '',
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                }) => (
                  <form style={styles.root}>
                    <Grid style={styles.userForm}>
                      <Grid style={styles.fieldsWrapper}>
                        {Object.keys(metricFields).map((metric) => (
                          <Accordion
                            key={metric}
                            style={styles.accordionGroup}
                            defaultExpanded
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={metricLabels[metric]}
                              id={metric}
                            >
                              <Grid style={styles.accordionHeading}>
                                {ready && t(metricLabels[metric])}
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                {
                                  metricFields[metric].map(({
                                    key, label, assistantKey, isRequired, isActive, isAvailable
                                  }) => {
                                    if (!(isAvailable && isActive)) {
                                      return null;
                                    }
                                    return (
                                      <Grid item lg={12} md={12} xs={12} key={key}>
                                        <FormControl variant="outlined" style={styles.formControl}>
                                          <Autocomplete
                                            id={key}
                                            fullWidth
                                            open={fieldOpen[key] && textFieldSelectedKey === key && !validation(
                                              details[key],
                                              invoiceRegex[key] || noWhiteSpace
                                            )}
                                            onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                                            onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                                            name={key}
                                            onChange={(e, v) => handleChangeMain(e, key, assistantKey, v)}
                                            onFocus={(e) => onClickField(e, key)}
                                            value={details[key]}
                                            options={detailsXML[assistantKey] ? detailsXML[assistantKey] : [invoice[key] ? invoice[key] : '']}
                                            getOptionLabel={(option) => (option && option.value ? option.value : option)}
                                            getOptionSelected={(option) => option === (details[key] ? details[key] : '')}
                                            renderInput={(params) => (
                                              <TextField
                                                inputRef={fieldRef[key]}
                                                fullWidth
                                                {...params}
                                                label={ready && t(label)}
                                                error={validation(
                                                  details[key],
                                                  invoiceRegex[key] || noWhiteSpace
                                                )}
                                                helperText={(validation(
                                                  details[key],
                                                  invoiceRegex[key] || noWhiteSpace
                                                ) && t(invoiceHelperText[key] || 'NO_WHITE_SPACE_HELPER_TEXT'))}
                                                variant="outlined"
                                                onChange={(e) => handleChangeText(assistantKey, e.target.value, key)}
                                                InputProps={{
                                                  ...params.InputProps,
                                                }}
                                                style={
                                                  textFieldSelectedKey === key ? styles.textFieldHighlighted
                                                    : (details[key] === values[key] || details[key] === undefined) ? styles.root
                                                      : styles.textFieldSelect
                                                }
                                                onClick={(e) => onClickField(e, key)}
                                                required={isRequired}
                                              />
                                            )}
                                          />
                                          <FormHelperText error>
                                            {touched[key] && errors[key] && ready ? t(errors[key]) : ''}
                                          </FormHelperText>
                                        </FormControl>
                                      </Grid>
                                    );
                                  })
                                }
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </Grid>
                      <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                          variant="text"
                          style={styles.closeBtn}
                          disabled={isSubmitting}
                          onClick={handleMetricsClose}
                        >
                          {ready && t('CDC_DOC_EDIT_FORM_METRICS_CANCEL')}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          style={styles.submitBtn}
                          disabled={isSubmitting}
                          onClick={handleClickOnSave}
                        >
                          {ready && t('CDC_DOC_EDIT_FORM_METRICS_SAVE')}
                        </Button>
                      </Box>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
            <Grid item xs={12} md={8} style={styles.invoiceContainer}>
              {renderInvoiceXML
                ? renderInvoiceXML(xmlSelectedValue, setXmlSelectedValue, textFieldSelectedValue, scrollXMLViewBy, false, textFieldSelectedKey)
                : renderInvoicePDF()}
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default CDCMetrics;
