import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Button, Grid
} from '@mui/material';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import { integrationNames } from 'src/config';
import styles from '../style';

const SFTP = (props) => {
  const { API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [appError, setAppError] = useState(false);
  const [isFieldChanged, setIsFieldChanged] = useState({});
  const [savedHost, setSavedHost] = useState(false);
  const [savedPort, setSavedPort] = useState(false);
  const [savedUsername, setSavedUsername] = useState(false);
  const [savedPassword, setSavedPassword] = useState(false);
  const [savedFolderPath, setSavedFolderPath] = useState(false);
  const [savedExportFormat, setSavedExportFormat] = useState(false);
  const [sftpData, setSftpData] = useState({});

  const { deliverFormats, type, isIntegrationActive } = props;

  const onSetSavedFlag = (name, value) => {
    switch (name) {
      case 'host':
        setSavedHost(value);
        break;
      case 'port':
        setSavedPort(value);
        break;
      case 'username':
        setSavedUsername(value);
        break;
      case 'password':
        setSavedPassword(value);
        break;
      case 'folderPath':
        setSavedFolderPath(value);
        break;
      case 'exportFormat':
        setSavedExportFormat(value);
        break;
      default:
        break;
    }
  };

  const getSavedDetails = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.savedDetailsSFTP}/${type}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSftpData(response.data.data);
        setLoading(false);
      } else {
        setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
    }
  };

  const onCallSaveSFTPDetails = async (data, exportFormatFlag = false) => {
    try {
      const response = await Axios.post(
        `${API.savedDetailsSFTP}/${type}`, data, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSftpData(response.data.data);
        Object.keys(isFieldChanged).forEach((key) => {
          onSetSavedFlag(key, true);
        });
        if (exportFormatFlag) {
          setSavedExportFormat(response.data.success);
        }
        getSavedDetails();
        setLoading(false);
      } else {
        setIsFieldChanged({});
        setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
        setLoading(false);
      }
    } catch (error) {
      setIsFieldChanged({});
      setLoading(false);
      setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
    }
  };

  const saveSFTPDetails = async () => {
    setLoading(true);

    const data = {
      host: sftpData?.host,
      port: sftpData?.port,
      username: sftpData?.username,
      password: sftpData?.password,
      folderPath: sftpData?.folderPath,
      exportFormat: sftpData?.exportFormat
    };

    onCallSaveSFTPDetails(data);
  };

  const onSaveSFTPExportType = async (value) => {
    setLoading(true);

    const data = {
      ...sftpData,
      exportFormat: value
    };

    onCallSaveSFTPDetails(data, true);
  };

  useEffect(() => {
    if (isIntegrationActive) {
      getSavedDetails();
    }
  }, []);

  const onCheckConnection = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.testSFTPConnection}/${type}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        Object.keys(isFieldChanged).forEach((key) => {
          onSetSavedFlag(key, false);
        });
        setSavedExportFormat(false);
        setIsFieldChanged({});
        enqueueSnackbar(
          t('INTEGRATIONS_GSTOCK_CONN_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setLoading(false);
      } else {
        setLoading(false);
        Object.keys(isFieldChanged).forEach((key) => {
          onSetSavedFlag(key, false);
        });
        setIsFieldChanged({});
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('SOMETHING_WENT_WRONG'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      Object.keys(isFieldChanged).forEach((key) => {
        onSetSavedFlag(key, false);
      });
      setSavedExportFormat(false);
      setIsFieldChanged({});
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onChangeSFTPDetails = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setSftpData({
      ...sftpData,
      [name]: value
    });

    setIsFieldChanged({
      ...isFieldChanged,
      [name]: true
    });
  };

  const onSync = async () => {
    setLoading(true);
    try {
      await Axios.get(
        API.syncSFTP,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setTimeout(() => {
        enqueueSnackbar(
          t('INTEGRATIONS_SYNC_STARTED_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  if (appError) {
    return (
      <Grid style={styles.errorContainer}>
        <span style={styles.labelTextError}>
          {appError}
        </span>
      </Grid>
    );
  }

  return (
    <Grid style={styles.detailsContainer}>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_SFTP_HOST_NAME')}</span>
          {savedHost && <Grid style={styles.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          name="host"
          value={sftpData?.host}
          style={styles.inputField}
          onChange={(e) => onChangeSFTPDetails(e)}
          onBlur={(e) => saveSFTPDetails(e.target.value)}
        />
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_SFTP_PORT_NUMBER')}</span>
          {savedPort && <Grid style={styles.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          name="port"
          value={sftpData?.port}
          style={styles.inputField}
          onChange={(e) => onChangeSFTPDetails(e)}
          onBlur={(e) => saveSFTPDetails(e.target.value)}
        />
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_SFTP_USERNAME')}</span>
          {savedUsername && <Grid style={styles.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          name="username"
          value={sftpData?.username}
          style={styles.inputField}
          onChange={(e) => onChangeSFTPDetails(e)}
          onBlur={(e) => saveSFTPDetails(e.target.value)}
        />
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_SFTP_PASSWORD')}</span>
          {savedPassword && <Grid style={styles.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="password"
          name="password"
          value={sftpData?.password}
          style={styles.inputField}
          onChange={(e) => onChangeSFTPDetails(e)}
          onBlur={(e) => saveSFTPDetails(e.target.value)}
        />
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_SFTP_FOLDER_PATH')}</span>
          {savedFolderPath && <Grid style={styles.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          name="folderPath"
          value={sftpData?.folderPath}
          style={styles.inputField}
          onChange={(e) => onChangeSFTPDetails(e)}
          onBlur={(e) => saveSFTPDetails(e.target.value)}
        />
      </Grid>
      {
        type === integrationNames.sftpSender && (
          <Grid style={styles.detailRow}>
            <Grid style={styles.labelWrapper}>
              <span style={styles.labelText}>{ready && t('INTEGRATIONS_SFTP_EXPORT_FORMAT')}</span>
              {savedExportFormat && <Grid style={styles.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
            </Grid>
            <select
              type="text"
              name="exportFormat"
              value={sftpData?.exportFormat}
              className={'export-email-style'}
              style={styles.inputField}
              onChange={(e) => onSaveSFTPExportType(e.target.value)}
            >
              {
                deliverFormats.map((format) => !format.dontExportViaEmail && (
                  <option key={format.id} value={format.key}>{format.label}</option>
                ))
              }
            </select>
          </Grid>
        )
      }
      <Grid style={styles.checkContainer}>
        <Button
          variant="outlined"
          style={styles.checkConnectionBtn}
          onClick={onCheckConnection}
          disabled={loading}
        >
          {ready && t('INTEGRATIONS_CHECK_CONNECTION')}
        </Button>
        {
          type === integrationNames.sftpReceiver && (
            <Button
              variant="outlined"
              style={styles.syncBtn}
              onClick={onSync}
              disabled={loading}
            >
              {ready && t('INTEGRATIONS_QUICKBOOKS_SYNC')}
            </Button>
          )
        }
      </Grid>
    </Grid>
  );
};

export default SFTP;
