/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.417 5.667v4.666c0 .195.083.327.25.396.166.07.32.035.458-.104l2.042-2.042a.8.8 0 00.25-.583.8.8 0 00-.25-.583L9.125 5.375c-.139-.139-.292-.174-.458-.104-.167.07-.25.201-.25.396zM2.167 15.5c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 01-.49-1.177V2.167c0-.459.163-.851.49-1.177C1.316.663 1.708.5 2.167.5h11.666c.459 0 .851.163 1.177.49.327.326.49.718.49 1.177v11.666c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.177.49H2.167zm4.166-1.667h7.5V2.167h-7.5v11.666z"
        fill="#6D788D"
      />
    </svg>
  )
}

export default SvgComponent