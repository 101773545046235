/* eslint-disable no-unused-vars */
import { useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Divider, Typography, Grid, Hidden}
from '@mui/material';

import Chart from 'src/dashboard/components/InvoicesProcessed/components/Chart/NChart';
import SimpleSelect from 'src/shared/components/SimpleSelect/SimpleSelect';
import { arrangeChartData } from 'src/utils/helpers';
import {  invoiceFilterOptions, invoiceFilterOptionsNew, timelyFilterOptions}
from 'src/config';
import styles from './style';

function Charges({user,graph_data, ...rest}) {
  const [dateFilter, setDateFilter] = useState(0);
  const [invoiceFilter, setInvoiceFilter] = useState(0);
  const { ready, t } = useTranslation();
  const [allData,setAllData]=useState([]);
  const [currentData,setCurrentData] = useState([]);
  const isToValidateStatusAllowed = user.customisations.includes('toValidateStatus');
 
  const newInvoiceFilterOptionsNew = isToValidateStatusAllowed ? 
  invoiceFilterOptionsNew : invoiceFilterOptionsNew.filter((o) => o.value !== 3);

  const handleDateFilter = (value)=>{
    setDateFilter(value);
    setCurrentData(allData[value][invoiceFilter].toReversed());
  }
  const handleInvoiceFilter = (value) =>{
    setInvoiceFilter(value);
    setCurrentData(allData[dateFilter][value].toReversed());
  }

  useEffect(()=>{
    if(graph_data && Object.keys(graph_data).length) {
      let data = arrangeChartData(graph_data,t);
      setAllData(data);
      setCurrentData(data[dateFilter][invoiceFilter].toReversed());
    }
  },[graph_data])

  return (
    <Card
      style={styles.root}
      {...rest}
    >
      <Grid container style={styles.header}>
        <Grid item xs={12} sm={4} style={styles.titleRow}>
          <Typography style={styles.headerText}>
            {ready && t('DASHBOARD_CHART_INVOICES')}
          </Typography>
          <Hidden smUp>
            <Grid style={styles.select}>
              <SimpleSelect
                label={ready && t('DASHBOARD_CHART_STATUS')}
                filter={invoiceFilter}
                filterOptions={user.role === 'company' ? invoiceFilterOptions : newInvoiceFilterOptionsNew}
                setFilter={(e) => handleInvoiceFilter(e.target.value)}
              />
            </Grid>
          </Hidden>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={8} style={styles.select}>
            <SimpleSelect
              label={ready && t('DASHBOARD_CHART_STATUS')}
              filter={invoiceFilter}
              filterOptions={user.role === 'company' ? invoiceFilterOptions : newInvoiceFilterOptionsNew}
              setFilter={(e) => handleInvoiceFilter(e.target.value)}
            />
            <SimpleSelect
              label={ready && t('DASHBOARD_CHART_SHOW')}
              filter={dateFilter}
              filterOptions={timelyFilterOptions}
              setFilter={(e) => handleDateFilter(e.target.value)}
            />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} style={styles.select}>
            <SimpleSelect
              label={ready && t('DASHBOARD_CHART_SHOW')}
              filter={dateFilter}
              filterOptions={timelyFilterOptions}
              setFilter={(e) => setDateFilter(e.target.value)}
            />
          </Grid>
        </Hidden>
      </Grid>
      <Divider />
      <CardContent className="chart-wrapper-card-content">
        <div style={{width:'100%',height:'100%'}} id="wrapper-ref" 
        className="dashboard-chart-wrapper">
          {
            currentData && currentData.length > 0?
            <Chart 
              chart_data={currentData} 
              type={dateFilter}
              invoiceType={invoiceFilter}
            />
            :
            <Chart 
              chart_data={[]}
              type={dateFilter}
              invoiceType={invoiceFilter}
            />
          }
        </div>
      </CardContent>
    </Card>
  );
}

export default Charges;
