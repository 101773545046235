import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Checkbox, Chip,
  FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select,
  TextField, Tooltip, Autocomplete
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  AddBox as PlusIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { supplierData, supplierFields, userRoles } from 'src/config';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import theme from 'src/theme';
import styles from './style';

const EditSupplierTwinfield = (props) => {
  const { t, ready } = useTranslation();
  
  const {
    isInvoiceEditForm,
    hasError,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    isSubmitting,
    handleEditSupplierClose,
    handleChange,
    handleChangeAutocomplete,
    getLabel,
    handleEditGLA,
    handleAddGLA,
    glAccounts,
    handleEditVATCode,
    handleAddVATCode,
    vatGroups,
    handleEditCostCenter,
    handleAddCostCenter,
    costCenters,
    projects,
    currencies,
    countries,
    xmlSelectedValue,
    textFieldSelectedKey,
    textFieldSelectedValueRef,
    setTextFieldSelectedKey,
    setTextFieldSelectedValue,
    newlyAddedVal,
    newlyAddingProp,
  } = props;

  const { BRAND_NAME } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;

  const supplierFieldIds = supplierFields.map((s) => s.fields.map((f) => `${f.key}Id`)).flat();

  useEffect(() => {
    if (textFieldSelectedKey && xmlSelectedValue?.trim() && xmlSelectedValue.trim() !== values[textFieldSelectedKey]) {
      handleChange({ target: { name: textFieldSelectedKey, value: xmlSelectedValue.trim() } });
    } else if (xmlSelectedValue?.trim()) {
      let selectedKey = '';
      Object.keys(values).forEach((k) => {
        if (values[k] === xmlSelectedValue.trim()) {
          if (selectedKey) {
            selectedKey = selectedKey.concat(' ').concat(k);
          } else {
            selectedKey = k;
          }
        }
      });
      setTextFieldSelectedKey(selectedKey);
    } else if (!xmlSelectedValue) {
      setTextFieldSelectedKey('');
    }
  }, [xmlSelectedValue]);

  useEffect(() => {
    if (isInvoiceEditForm) {
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          setTextFieldSelectedKey('');
        }
      });
      document.addEventListener('click', (e) => {
        if (!['canvasId2', ...supplierFieldIds].some((o) => o === e.target.id)) {
          setTextFieldSelectedKey('');
          if (textFieldSelectedValueRef.current === '') {
            setTextFieldSelectedValue(' ');
          } else {
            setTextFieldSelectedValue('');
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } });
    }
  }, [newlyAddedVal]);

  return (
    <form style={styles.root}>
      <Card>
        <CardContent style={{ padding: 32 }}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12} key="skipSupplierReview" style={styles.skipReviewContainer}>
              <Tooltip title={t('TOOLTIP_SKIP_REVIEW_FOR_SUPPLIER', { brand: BRAND_NAME })}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      style={styles.checkBox}
                      checked={values.skipSupplierReview}
                      onChange={handleChange}
                      name="skipSupplierReview"
                    />
                              )}
                  label={ready && t('SKIP_REVIEW_FOR_SUPPLIER_LABEL')}
                  labelPlacement="end"
                  sx={{
                    '&.MuiFormControlLabel-root': styles.checkboxContainer,
                    '&.MuiFormControlLabel-label': styles.checkBoxLabel
                  }}
                />
              </Tooltip>
            </Grid>
            {
              supplierFields.map((m) => (
                <Accordion
                  key={m.title}
                  style={styles.accordionGroup}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={m.title}
                    id={m.title}
                  >
                    <Grid style={styles.accordionHeading}>
                      {ready && t(m.title)}
                      {
                        hasError(m.fields, errors) && (
                          <Chip
                            color="error"
                            variant="default"
                            size="small"
                            label="Error"
                            style={styles.errorChip}
                          />
                        )
                      }
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      {
                        m.fields.map((f) => {
                          if (user.accountingSystem !== 'twinfield' && f.key === 'invoiceLineDescriptionRule') {
                            return null;
                          }

                          switch (f.key) {
                            case 'autoPaymentStatus':
                              return (
                                <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        supplierData.apStatuses && supplierData.apStatuses.length > 0
                                          && supplierData.apStatuses.map((s) => (
                                            <MenuItem key={s.code} value={s.code}>
                                              {s.name}
                                            </MenuItem>
                                          ))
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'status':
                              return (
                                <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                      style={styles.capitalizeText}
                                    >
                                      {
                                        supplierData.statuses && supplierData.statuses.length > 0
                                          && supplierData.statuses.map((s) => (
                                            <MenuItem key={s} value={s} style={styles.capitalizeText}>
                                              {s}
                                            </MenuItem>
                                          ))
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'generalLedgerAccLine': case 'generalLedgerAccount':
                              return (
                                <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Autocomplete
                                      id={`${f.key}Id`}
                                      fullWidth
                                      name={f.key}
                                      onChange={(e, v) => handleChangeAutocomplete(e.target.value, f.key, v, handleChange)}
                                      value={getLabel(glAccounts, values[f.key])}
                                      options={glAccounts}
                                      getOptionLabel={
                                        (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                                      }
                                      getOptionSelected={(option) => option.code === (values[f.key] ? values[f.key] : ' ')}
                                      renderInput={(params) => (
                                        <TextField
                                          fullWidth
                                          {...params}
                                          label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {values[f.key] !== supplierData.defaultGLA[0].code && (
                                                  <InputAdornment
                                                    position="end"
                                                    onClick={() => handleEditGLA(values[f.key])}
                                                    style={styles.editIconContainer}
                                                  >
                                                    <EditIcon style={styles.editDimensionIcon} />
                                                  </InputAdornment>
                                                )}
                                                <InputAdornment position="end" style={styles.plusIconContainer}>
                                                  <PlusIcon style={styles.plusIcon} onClick={() => handleAddGLA(f.key)} />
                                                </InputAdornment>
                                              </>
                                            ),
                                          }}
                                          style={
                                            (textFieldSelectedKey === f.key && isInvoiceEditForm) ? styles.selectStyleSelected : styles.selectStyle
                                          }
                                          onClick={() => {
                                            setTextFieldSelectedKey(f.key);
                                            setTextFieldSelectedValue(values[f.key]);
                                          }}
                                          required={f.required}
                                        />
                                      )}
                                    />
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'vatGroup': case 'vatGroupLine':
                              return (
                                <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl} sx={{ '& .MuiFormControl-root': styles.formControlRoot }}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                      IconComponent={() => (
                                        <>
                                          {values[f.key] !== supplierData.defaultVatGroup[0].code && (
                                            <EditIcon style={styles.editDimensionIcon} onClick={() => handleEditVATCode(values[f.key])} />
                                          )}
                                          <PlusIcon style={styles.plusIconSelect} onClick={() => handleAddVATCode(f.key)} />
                                        </>
                                      )}
                                    >
                                      {
                                        vatGroups && vatGroups.length > 0
                                          && vatGroups.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'costCenter':
                              return (
                                <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl} sx={{ '& .MuiFormControl-root': styles.formControlRoot }}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                      IconComponent={() => (
                                        <>
                                          {values.costCenter !== supplierData.defaultCostCenters[0].code && (
                                            <EditIcon style={styles.editDimensionIcon} onClick={() => handleEditCostCenter(values.costCenter)} />
                                          )}
                                          <PlusIcon style={styles.plusIconSelect} onClick={() => handleAddCostCenter('costCenter')} />
                                        </>
                                      )}
                                    >
                                      {
                                        costCenters && costCenters.length > 0
                                          && costCenters.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'project':
                              return (
                                <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        projects && projects.length > 0
                                          && projects.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'defaultCurrency':
                              return currencies && currencies.length > 0 && (
                                <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</InputLabel>
                                    <Select
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      value={values[f.key]}
                                      onChange={handleChange}
                                      name={f.key}
                                      label={ready && `${t(f.label)}${f.required ? '*' : ''}`}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        currencies && currencies.length > 0
                                          && currencies.map((s) => (
                                            <MenuItem key={s.code} value={s.code}>
                                              {`${s.code} | ${s.currency}`}
                                            </MenuItem>
                                          ))
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'bankCountry': case 'country':
                              let isRequired = f.required; // eslint-disable-line
                              if (f.key === 'bankCountry' && values.autoPaymentStatus === supplierData.apStatuses[2].code) {
                                isRequired = false;
                              }

                              return countries && countries.length > 0 && (
                                <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Autocomplete
                                      fullWidth
                                      name={f.key}
                                      onChange={(e, v) => handleChangeAutocomplete(e.target.value, f.key, v, handleChange)}
                                      value={values[f.key] ? countries.find((o) => o.isoCode === values[f.key]) : null}
                                      options={countries}
                                      getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
                                      getOptionSelected={(option) => option.isoCode === (values[f.key] ? values[f.key] : null)}
                                      renderInput={(params) => (
                                        <TextField
                                          fullWidth
                                          {...params}
                                          label={ready && `${t(f.label)}${isRequired ? '*' : ''}`}
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                          style={styles.selectStyle}
                                        />
                                      )}
                                    />
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            default:
                              return (
                                <Tooltip title={f.key === 'creditorCode' ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}>
                                  <Grid item lg={isInvoiceEditForm ? 12 : 4} md={isInvoiceEditForm ? 12 : 6} xs={12} key={f.key}>
                                    <TextField
                                      id={`${f.key}Id`}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      fullWidth
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      label={ready && t(f.label)}
                                      name={f.key}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      required={
                                        f.required
                                        || ((f.key === 'ascription' || f.key === 'bicSwift')
                                          && (
                                            values.autoPaymentStatus === supplierData.apStatuses[0].code
                                            || values.autoPaymentStatus === supplierData.apStatuses[1].code
                                          )
                                        )
                                        || (f.key === 'iban' && values.autoPaymentStatus === supplierData.apStatuses[0].code)
                                        || ((f.key === 'bankName' || f.key === 'bankCity' || f.key === 'bankStreet'
                                            || f.key === 'bankHouseNumber' || f.key === 'city' || f.key === 'address1')
                                          && values.autoPaymentStatus === supplierData.apStatuses[1].code
                                        )
                                      }
                                      value={values[f.key]}
                                      variant="outlined"
                                      disabled={f.key === 'creditorCode'}
                                      style={(textFieldSelectedKey === f.key && isInvoiceEditForm) ? styles.inputFieldSelected : styles.inputField}
                                      onClick={() => { setTextFieldSelectedKey(f.key); setTextFieldSelectedValue(values[f.key]); }}
                                    />
                                  </Grid>
                                </Tooltip>
                              );
                          }
                        })
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))
            }
            <Grid
              item
              xs={12}
            >
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
              {user.userRole.toLowerCase() !== userRoles.annotator && (
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    style={{ backgroundColor: theme.palette.button.action }}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user.permissions)}
                    onClick={handleSubmit}
                  >
                    {ready && t('SUPPLIERS_SAVE_CHANGES')}
                  </Button>
                  <Button
                    variant="text"
                    onClick={handleEditSupplierClose}
                    style={styles.yearField}
                  >
                    {ready && t('SUPPLIERS_CANCEL')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

// EditSupplierTwinfield.propTypes = {
//   isInvoiceEditForm: PropTypes.bool,
//   className: PropTypes.string,
//   hasError: PropTypes.func,
//   values: PropTypes.object,
//   errors: PropTypes.object,
//   touched: PropTypes.object,
//   handleBlur: PropTypes.func,
//   handleSubmit: PropTypes.func,
//   isSubmitting: PropTypes.bool,
//   handleEditSupplierClose: PropTypes.func,
//   handleChange: PropTypes.func,
//   handleChangeAutocomplete: PropTypes.func,
//   getLabel: PropTypes.func,
//   handleEditGLA: PropTypes.func,
//   handleAddGLA: PropTypes.func,
//   glAccounts: PropTypes.array,
//   handleEditVATCode: PropTypes.func,
//   handleAddVATCode: PropTypes.func,
//   vatGroups: PropTypes.array,
//   handleEditCostCenter: PropTypes.func,
//   handleAddCostCenter: PropTypes.func,
//   costCenters: PropTypes.array,
//   projects: PropTypes.array,
//   currencies: PropTypes.array,
//   countries: PropTypes.array,
//   xmlSelectedValue: PropTypes.string,
//   textFieldSelectedKey: PropTypes.string,
//   textFieldSelectedValueRef: PropTypes.object,
//   setTextFieldSelectedKey: PropTypes.func,
//   setTextFieldSelectedValue: PropTypes.func,
//   newlyAddedVal: PropTypes.string,
//   newlyAddingProp: PropTypes.string,
//   rest: PropTypes.object
// };

export default EditSupplierTwinfield;
