import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

import {
  Box, Card, Grid, Hidden, IconButton, Table, TableBody, TableCell, TableHead, TablePagination,
  TableRow, TextField, Typography, useMediaQuery
} from '@mui/material';
import { VisibilityOutlined, KeyboardReturn } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import { axiosHeaders, formatAmountInvoicesTable } from 'src/utils/helpers';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import styles from './style';

function Invoices(props) {
  const { t, ready } = useTranslation();

  const { API } = useContext(ConfigContext);
  const { header, pageLimit } = props;
  
  const auth = useAuth();
  const {user} = auth;

  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [movePage, setMovePage] = useState(1);
  const [limit, setLimit] = useState(pageLimit || 10);

  const maxWidth = useMediaQuery('(max-width: 470px)');
  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const getAllInvoices = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        API.userInvoices,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setInvoices(response.data.data);
        setTotalPages((Math.floor(response.data.data.length / limit) + 1));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllInvoices();
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    setMovePage(newPage + 1);
  };

  const handleLimitChange = (event) => {
    setPage(1);
    setMovePage(1);
    setLimit(event.target.value);
    setTotalPages((Math.floor(invoices.length / event.target.value) + 1));
  };

  const moveToPage = (toPage) => {
    if (toPage < 1) {
      return;
    }

    if (totalPages >= toPage) {
      setPage(toPage);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Grid style={styles.header}>
        <Typography style={styles.headerTitle}>
          {ready && t(header || 'ACCOUNT_SETTINGS_BILLING_INVOICES_MY_INVOICES')}
        </Typography>
      </Grid>
      {
        invoices && invoices.length > 0
          ? (
            <Card style={styles.root}>
              <PerfectScrollbar>
                <Box minWidth="100%">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={styles.columnName}>
                          #
                        </TableCell>
                        <Hidden smDown>
                          <TableCell style={styles.columnName}>
                            {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_01')}
                          </TableCell>
                        </Hidden>
                        <Hidden xsDown>
                          <TableCell style={styles.columnName}>
                            {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_02')}
                          </TableCell>
                        </Hidden>
                        <TableCell style={styles.columnName}>
                          {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_02')}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoices.slice((page - 1) * limit, (page) * limit).map((invoice) => (
                        <TableRow key={invoice.sequenceNumber} style={styles.row}>
                          <TableCell style={styles.cellText}>
                            <Grid style={styles.cardNumberRow}>
                              {`DOC-${invoice.number}`}
                            </Grid>
                          </TableCell>
                          <Hidden smDown>
                            <TableCell style={styles.cellText}>
                              {invoice.issueDate}
                            </TableCell>
                          </Hidden>
                          <Hidden xsDown>
                            <TableCell style={styles.cellText}>
                              {invoice.dueDate}
                            </TableCell>
                          </Hidden>
                          <TableCell style={styles.cellBoldText}>
                            {`${invoice.currency} ${invoice.totalAmount
                                && formatAmountInvoicesTable(invoice.totalAmount, user.monetaryDecimalSeparator)}`}
                          </TableCell>
                          <TableCell>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              style={styles.actionContainer}
                            >
                              <IconButton onClick={() => window.open(invoice.publicURL, '_blank')}>
                                <VisibilityOutlined />
                              </IconButton>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <div style={styles.caption}>
                {
                  !maxWidth && invoices.length !== 1
                    ? ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COUNT', { count: invoices.length })
                    : ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COUNT_ONE', { count: invoices.length })
                }
                <Grid style={styles.moveToPageContainer}>
                  {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_MOVE_TO_PAGE')}
                  <TextField
                    style={styles.searchInput}
                    sx={{
                      '& input[type=number]': {
                        'MozAppearance': 'textfield'
                      },
                      '& input[type=number]::WebkitOuterSpinButton': {
                        'WebkitAppearance': 'none'
                      },
                      '& input[type=number]::WebkitInnerSpinButton': {
                        'WebkitAppearance': 'none'
                      },
                      '& .MuiInputBaseInput': {
                        textAlign: 'center',
                        padding: '4px!important'
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '24px !important'
                      },
                    }}
                    type="number"
                    variant="outlined"
                    value={movePage}
                    onChange={(e) => setMovePage(e.target.value)}
                    onKeyUp={(e) => e && e.key === 'Enter' && moveToPage(movePage)}
                  />
                  {`/${totalPages}`}
                  <IconButton style={styles.moveButton} onClick={() => moveToPage(movePage)}>
                    <KeyboardReturn style={styles.moveIcon} />
                  </IconButton>
                </Grid>
                <TablePagination
                  component="div"
                  count={invoices.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page - 1}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
                />
              </div>
            </Card>
          )
          : <EmptyText str="ACCOUNT_SETTINGS_INVOICES_NO_INVOICES" />
      }
    </>
  );
}

// Invoices.propTypes = {
//   history: PropTypes.shape({
//     push: PropTypes.func
//   }),
//   parentPadding: PropTypes.bool,
//   header: PropTypes.string,
//   pageLimit: PropTypes.number
// };

export default Invoices;
