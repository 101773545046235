import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Backdrop, Button, CircularProgress, Grid, Modal, Paper, Tooltip, Typography
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

const SelectDocument = ({
  open
}) => {
  const { API } = useContext(ConfigContext);
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [documentType, setDocumentType] = useState('invoice');
  const [loading, setLoading] = useState(false);

  const documentTypes = [
    {
      key: 'invoice',
      title: 'DOCUMENT_TYPE_INVOICE',
      tooltip: '',
      points: [
        'DOCUMENT_TYPE_INVOICE_POINT_1',
        'DOCUMENT_TYPE_INVOICE_POINT_2',
      ],
      isBeta: false,
      isAvailable: true
    },
    {
      key: 'purchase_order',
      title: 'DOCUMENT_TYPE_PURCHASE_ORDER',
      tooltip: '',
      points: [
        'DOCUMENT_TYPE_PURCHASE_ORDER_POINT_1',
        'DOCUMENT_TYPE_PURCHASE_ORDER_POINT_2',
      ],
      isBeta: false,
      isAvailable: true
    },
    {
      key: 'id',
      title: 'DOCUMENT_TYPE_ID',
      tooltip: '',
      points: [
        'DOCUMENT_TYPE_ID_POINT_1',
        'DOCUMENT_TYPE_ID_POINT_2',
      ],
      isBeta: true,
      isAvailable: true
    },
  ];

  const confirmDocumentType = async () => {
    try {
      setLoading(true);

      const response = await Axios.get(
        `${API.selectDocumentType}${documentType}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('DOCUMENT_TYPE_SELECT_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('DOCUMENT_TYPE_SELECT_FAILED'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );

      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      style={styles.modal}
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Paper style={styles.paperModal}>
        <Grid>
          <Grid style={styles.modalBody}>
            <Typography
              variant="h2"
              style={styles.header}
            >
              {ready && t('SELECT_DOCUMENT_TYPE_TITLE')}
            </Typography>
            <Typography
              variant="caption"
              style={styles.caption}
            >
              {ready && t('SELECT_DOCUMENT_TYPE_CAPTION')}
            </Typography>
            <Grid container spacing={3} style={styles.listContainer}>
              {
                documentTypes.map((item) => {
                  if (!item.isAvailable) {
                    return null;
                  }

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={styles.listItemWrapper}
                      key={item.key}
                      onClick={() => setDocumentType(item.key)}
                    >
                      <Tooltip title={t(item.tooltip)}>
                        <Grid style={documentType === item.key ? styles.listItemSelected : styles.listItem}>
                          <Typography style={documentType === item.key ? styles.listItemTitleSelected : styles.listItemTitle}>
                            {ready && t(item.title)}
                            {item.isBeta && <span style={styles.beta}>{ready && t('BETA_RELEASE')}</span>}
                          </Typography>
                          <Grid>
                            {
                              item.points.map((point) => (
                                <Grid style={documentType === item.key ? styles.itemPointSelected : styles.itemPoint}>
                                  <CheckCircle style={documentType === item.key ? styles.listItemIconSelected : styles.listItemIcon} />
                                  <Typography style={documentType === item.key ? styles.listItemPointSelected : styles.listItemPoint}>
                                    {ready && t(point)}
                                  </Typography>
                                </Grid>
                              ))
                            }
                          </Grid>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  );
                })
              }
            </Grid>
          </Grid>
          <Grid style={styles.buttonContainer}>
            {
              loading ? (
                <Grid style={styles.action}>
                  <CircularProgress size={20} />
                </Grid>
              ) : (
                <Button
                  style={styles.sendBtn}
                  variant="contained"
                  onClick={confirmDocumentType}
                >
                  {ready && t('DOCUMENT_TYPE_SELECT_CONFIRM')}
                </Button>
              )
            }
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default SelectDocument;
