import { useEffect, useState, RefObject } from 'react';

function useHeight(ref: RefObject<HTMLDivElement>, viewLines: boolean, concernedArray: any[]) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current !== null) {
      setHeight(ref.current.clientHeight);
    } else {
      setHeight(0);
    }
  }, [ref.current, viewLines, concernedArray]);

  return height;
}

export default useHeight;
