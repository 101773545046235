import { alpha } from "@mui/material";
import theme from "src/theme";

const styles = {
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    alignItems: 'center',
    paddingBottom: 80
  },
  banner: {
    height: 140,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: `url('/static/images/profile_bg.png')`
  },
  profileDetails: {
    height: 151,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    paddingBottom: 10
  },
  avatarContainer: {
  },
  avatar: {
    height: 100,
    width: 100
  },
  editIconContainer: {
    position: 'absolute',
    top: 80,
    right: 12,
    backgroundColor: theme.palette.background.default,
    width: 24,
    height: 24,
    padding: 0,
    zIndex: 2,
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)'
  },
  editIcon: {
    width: '0.9rem'
  },
  nameText: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px',
    marginBottom: 0
  },
  companyText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '17px',
    marginBottom: 10
  },
  userType: {
    maxWidth: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: alpha(theme.palette.secondary.main, 0.3),
    borderRadius: 20,
    padding: '4px 12px',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    lineHeight: '21px'
  },
  tabsSection: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    filter: 'drop-shadow(0px 2px 0px rgba(51, 77, 110, 0.1))'
  },
  tab: {
    height: '100%',
    margin: '0px 16px'
  },
  tabButton: {
    width: '100%',
    height: '100%',
    padding: '16px 16px',
    transition: 'none',
    borderRadius: 0,
    minWidth: 40
  },
  selectedTabButton: {
    border: '0px solid',
    borderBottomWidth: 3,
    borderBottomColor: theme.palette.line.primary,
    width: '100%',
    height: '100%',
    padding: '16px 16px',
    transition: 'none',
    borderRadius: 0,
    minWidth: 40
  },
  tabLabelContainer: {},
  tabLabel: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    color: theme.palette.text.primary
  },
  selectedTabLabel: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    color: theme.palette.text.highlight2
  },
  container: {
    paddingTop: 30,
    paddingLeft: 16,
    paddingRight: 16
  },
}

export default styles;