const styles = {
  searchBarRow: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:'20px',
    backgroundColor:'#FFFFFF',
    height: '76px',
  },
  searchBar: {
    flex: 1,
    height: '36px',
    marginRight: '8px',
  },
  searchBarTextField: {
    width: '100%',
    backgroundColor:'#FFFFFF',
  },
  searchBarInputRoot: {
    padding: '0px 12px 0px 12px',
    height: '36px',
    borderRadius: '4px',
    width: '100%',
    backgroundColor:'#FFFFFF',
  },
  searchBarInput: {
    backgroundColor:'#FFFFFF',
  },
  searchIcon: {
    color: '#6D788D',
    width: '20px',
    height: '20px',
  },
  searchInput: {
    color: '#4C4E64',
    '&::placeholder': {
      color: '#4C4E64DE',
      fontSize: '13px',
      lineHeight: '32px'
    }
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px'
  },
  settingsBtn: {
    backgroundColor: '#3E8AFF1A',
    padding: '4px 9px 4px 9px',
    borderRadius: '4px',
    height: '36px',
    textTransform: 'none',
    color: '#3E8AFF',
    fontWeight: '600',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#3E8AFF1A',
      boxShadow: '0px 8px 16px -8px #4C4E646B',
    }
  },
  settingsIcon: {
    color: '#3E8AFF',
    width: '20px',
    height: '20px',
  },
  logsBtn: {
    backgroundColor: '#3E8AFF1A',
    padding: '4px 9px 4px 9px',
    borderRadius: '4px',
    height: '36px',
    textTransform: 'none',
    color: '#3E8AFF',
    fontWeight: '600',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#3E8AFF1A',
      boxShadow: '0px 8px 16px -8px #4C4E646B',
    }
  },
  logsIcon: {
    color: '#3E8AFF',
    width: '20px',
    height: '20px',
  },
  filterBtn: {
    padding: '4px 9px 4px 9px',
    height: '36px',
    borderRadius: '4px',
    background: '#FFFFFF',
    textTransform: 'none',
    color: '#3E8AFF',
    fontWeight: '600',
    fontSize: '14px',
  },
  filterBtnActive: {
    backgroundColor: '#3E8AFF1A',
    padding: '4px 9px 4px 9px',
    borderRadius: '4px',
    height: '36px',
    textTransform: 'none',
    color: '#3E8AFF',
    fontWeight: '600',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#3E8AFF1A',
      boxShadow: '0px 8px 16px -8px #4C4E646B',
    }
  },
  filterIcon: {
    color: '#3E8AFF',
    height: '20px',
    width: '20px',
  },
  uploadBtn: {
    padding: '4px 9px 4px 9px',
    height: '36px',
    minWidth: '188px',
    background: '#3E8AFF',
    borderRadius: '4px',
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    textTransform: 'none',
    color: '#FFFFFF',
    fontWeight: '600',
    fontSize: '14px',
    '&:hover': {
      background: '#3E8AFF',
      boxShadow: '0px 8px 16px -8px #4C4E646B',
    }
  },
  uploadIcon: {
    color: '#FFFFFF',
    height: '20px',
    width: '20px',
  },
  spinner: {
    color: '#FFFFFF',
  },
}

export default styles;