/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function AiSplitIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.6923 14.6154L5.76922 10.3846L1.53845 8.46154L5.76922 6.53846L7.6923 2.30769L9.61537 6.53846L13.8461 8.46154L9.61537 10.3846L7.6923 14.6154ZM13.8461 16.1538L12.8846 14.0385L10.7692 13.0769L12.8846 12.1154L13.8461 10L14.8077 12.1154L16.9231 13.0769L14.8077 14.0385L13.8461 16.1538Z" fill="#3E8AFF"/>
    </svg>
  )
}

export default AiSplitIcon