import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

import { Avatar, Box, Button, Container, Grid, IconButton, Typography } from '@mui/material';
import { 
  Edit as EditIcon,
  Person as UserIcon,
  SubscriptionsOutlined as SubscriptionIcon,
  PeopleAlt as UsersIcon
} from '@mui/icons-material';

import ConfigContext from 'src/contexts/ConfigContext';
import Page from 'src/shared/screens/Page';
import { useAuth } from 'src/hooks/useAuth';
import authService from 'src/utils/authService';
import { appendContactSupport, axiosHeadersFormData, getLocalisedErrorString } from 'src/utils/helpers';
import GeneralSettings from 'src/account/components/GeneralSettings/GeneralSettings';
import MyPlan from 'src/subscription/components/MyPlan/MyPlan';
import UserList from 'src/account/components/UsersList/UsersList';
import CompaniesList from 'src/account/components/CompaniesList/CompaniesList';
import BrandSettings from 'src/account/components/BrandSettings/BrandSettings';
import PartnerStats from 'src/account/components/PartnerStats/PartnerStats';
import styles from './style';

const tabs = [
  {
    value: 'user',
    label: 'ACCOUNT_SETTINGS_MY_PROFILE',
    icon: UserIcon,
    subtitle: 'ACCOUNT_SETTINGS_MY_PROFILE_SUBTITLE'
  },
  {
    value: 'subscription',
    label: 'ACCOUNT_SETTINGS_PLAN_N_BILLING',
    icon: SubscriptionIcon,
    subtitle: 'ACCOUNT_SETTINGS_PLAN_N_BILLING_SUBTITLE'
  },
  {
    value: 'users',
    label: 'ACCOUNT_SETTINGS_USERS',
    icon: UsersIcon,
    subtitle: 'ACCOUNT_SETTINGS_USERS_SUBTITLE'
  },
  {
    value: 'companies',
    label: 'ACCOUNT_SETTINGS_COMPANIES',
    icon: UsersIcon,
    subtitle: 'ACCOUNT_SETTINGS_COMPANIES_SUBTITLE'
  },
  {
    value: 'brand',
    label: 'ACCOUNT_SETTINGS_BRAND_SETTINGS',
    icon: UsersIcon,
    subtitle: 'ACCOUNT_SETTINGS_BRAND_SETTINGS_SUBTITLE'
  },
  {
    value: 'partner_stats',
    label: 'ACCOUNT_SETTINGS_PARTNER_STATS',
    icon: UsersIcon,
    subtitle: 'ACCOUNT_SETTINGS_PARTNER_STATS_SUBTITLE'
  },
];

function Account() {
  const { LOGIN_PANEL_URL, API, SUPPORT_EMAIL, BRAND_NAME } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const auth = useAuth();
  const {user, setUser} = auth;

  const queryVal = new URLSearchParams(history.location.search);
  const [currentTab, setCurrentTab] = useState(queryVal.get('tab') ? queryVal.get('tab') : tabs[0].value);

  const handleUploadClick = (event) => {
    const { files } = event.target;

    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    let file = '';
    if (files && files.length > 0) {
      [file] = files;

      if (file.size > 1 * 1024 * 1024) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_PROFILE_PIC_MAX_SIZE'), {
          variant: 'error',
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async (f) => {
        if (reader.result) {
          const image = new Image();
          image.src = f.target.result;
          image.onload = async () => {
            if (image.width > 800 && image.height > 800) {
              enqueueSnackbar(t('ACCOUNT_SETTINGS_PROFILE_PIC_MAX_DIMENSIONS'), {
                variant: 'error',
              });
              return;
            }

            const data = new FormData();
            data.append('image', files[0]);

            try {
              const response = await Axios.post(
                API.userAvatarImage,
                data,
                axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
              );
              if (response.data.success) {
                setUser({
                  ...user,
                  avatar: reader.result,
                  avatarName: 'new avatar'
                });
              }
            } catch (error) {
              enqueueSnackbar(appendContactSupport(
                SUPPORT_EMAIL,
                getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_PROFILE_PIC_UPLOAD_FAILED', t),
                t
              ), {
                variant: 'error',
                autoHideDuration: 5000
              });
            }
          };
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const handleTabsChange = (value) => {
    history.push(`/account?tab=${value}`);
    setCurrentTab(value);
  };

  return (
    <Page
      style={styles.root}
      title={ready && `${t('ACCOUNT_SETTINGS_TITLE')} | ${BRAND_NAME}`}
    >
      <>
        <Grid
          style={styles.banner}
        />
        <Grid style={styles.profileDetails}>
          <IconButton
            component="label"
            style={styles.avatarContainer}
          >
            <Avatar
              style={styles.avatar}
              src={user.avatar}
            />
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              onChange={handleUploadClick}
              style={{ display: 'none' }}
              required
            />
            <IconButton component="span" style={styles.editIconContainer}>
              <EditIcon style={styles.editIcon} />
            </IconButton>
          </IconButton>
          <Typography style={styles.nameText}>
            {`${user.firstname} ${user.lastname}`}
          </Typography>
          <Typography style={styles.companyText}>
            {user.company}
          </Typography>
          <Grid style={styles.userType}>
            <Typography variant="inherit">
              {user.role === 'company' && 'Admin'}
              {user.role === 'user' && user.userRole}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={styles.tabsSection}>
          {
            tabs.map((tab) => {
              if (!user.isCreator && (tab.value === 'billing' || tab.value === 'invoice')) {
                return null;
              }
              if (user.customisations.includes('hideBilling') && tab.value === 'subscription') {
                return null;
              }
              if ((user.isSubCompany || user.isPartnerSubCompany) && tab.value === 'brand') {
                return null;
              }
              if (user.isSubCompany && tab.value === 'partner_stats') {
                return null;
              }

              return (
                <Grid style={styles.tab} key={tab.value}>
                  <Button
                    key={tab.value}
                    onClick={() => handleTabsChange(tab.value)}
                    style={currentTab === tab.value
                      ? styles.selectedTabButton : styles.tabButton}
                  >
                    <Grid style={styles.tabLabelContainer}>
                      <Typography align="center" style={currentTab === tab.value ? styles.selectedTabLabel : styles.tabLabel}>
                        {ready && t(tab.label)}
                      </Typography>
                    </Grid>
                  </Button>
                </Grid>
              );
            })
          }
        </Grid>
      </>
      <Container maxWidth="xl">
        <Grid container style={styles.container}>
          <Grid item xs={12}>
            <Box>
              {currentTab === 'user' && <GeneralSettings />}
              {currentTab === 'subscription' && <MyPlan />}
              {currentTab === 'users' && <UserList />}
              {currentTab === 'companies' && <CompaniesList />}
              {
                user.userRole === 'admin' && !user.isSubCompany && !user.isPartnerSubCompany
                && currentTab === 'brand' && <BrandSettings />
              }
              {
                user.userRole === 'admin' && !user.isSubCompany
                && currentTab === 'partner_stats' && <PartnerStats />
              }
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Account;
