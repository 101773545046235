import { alpha } from '@mui/material';
import theme from 'src/theme';

const styles = {
  root: {},
  navigateNextIcon: {
    marginLeft: theme.spacing(1)
  },
  filterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  circularProgressIcon: {
    width: 20,
    height: 20
  },
  arrowCell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  pointer: {
    cursor: 'pointer'
  },
  arrow: {
    paddingLeft: 4
  },
  cardNumberRow: {
    display: 'flex',
    height: 42,
    alignItems: 'center'
  },
  editLink: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  deleteLink: {
    fontSize: 14,
    fontWeight: 600,
    color: '#A0AEC1',
    marginLeft: 24,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  activateLink: {
    fontSize: 14,
    fontWeight: 600,
    color: '#A0AEC1',
    marginLeft: 24,
    marginRight: 24,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  defaultCard: {
    fontSize: 13,
    color: 'gray',
    marginLeft: 24,
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  snackAction: {
    color: 'white'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    padding: '20px 20px 0px 20px'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  row: {
    height: 64
  },
  actionContainer: {
    paddingRight: 10,
    marginTop: 3
  },
  caption: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: theme.palette.text.header,
    paddingLeft: 24
  },
  cellText: {
    fontSize: 15,
    color: theme.palette.text.primary
  },
  cellBoldText: {
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  defaultLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    border: theme.palette.border.secondary,
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    marginLeft: 20,
    textTransform: 'capitalize',
    maxWidth: 108,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    backgroundColor: '#E1E4E8'
  },
  paidLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    border: '1px solid #3CB573',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    marginLeft: 20,
    textTransform: 'capitalize',
    maxWidth: 108,
    fontSize: 14,
    fontWeight: 600,
    color: '#FFFFFF',
    backgroundColor: '#3CB573'
  },
  statusDot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    border: theme.palette.border.secondary,
    boxSizing: 'border-box',
    borderRadius: 50,
    height: 29,
    cursor: 'pointer',
    width: 29
  },
  defaultDot: {
    color: '#E1E4E8',
    backgroundColor: alpha('#E1E4E8', 0.3)
  },
  paidDot: {
    color: theme.palette.status.toexport,
    backgroundColor: alpha(theme.palette.statusbg.toexport, 0.3)
  },
  statusText: {
    textTransform: 'capitalize',
  },
  componentPadding: {
    padding: '20px 20px 0px 20px'
  },
  columnName: {
    color: theme.palette.text.primary
  },
  moveButton: {
    marginLeft: 8,
    width: 24,
    height: 24
  },
  moveIcon: {
    width: 16,
    height: 16
  },
  searchInput: {
    textAlign: 'center',
    width: 60,
    height: 24,
    marginLeft: 8,
    marginRight: 4,
  },
  moveToPageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
};

export default styles;
