import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, CssBaseline } from '@mui/material';

import SideBar from '../components/SideBar/SideBar';

export default function SideBarLayout(
  { children, title, navBarChildren }: { children: React.ReactNode, title?: string, navBarChildren?: React.JSX.Element }
) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CssBaseline />
      <SideBar
        docPreview={Boolean(navBarChildren)}
        docPreviewExtra={navBarChildren}
      />
      <Box
        component="main"
        sx={styles.main}
        className="y-scroll"
      >
        {children}
      </Box>
    </Box>
  );
}

const styles = {
  main: {
    height: '100vh',
    flexGrow: 1,
    maxWidth:'calc(100% - 65px)',
    marginLeft:'auto',
    padding: '0px 0px 0px 4px',
    backgroundColor:'#F3F5F9'
  }
};
