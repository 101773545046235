import {
  ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper
} from '@mui/material';

import { dimensionExportOptions } from 'src/config';

const DimensionExportPopper = (props) => {
  const {
    exportOptionsOpen, exportBtnRef, exportOptionsClose, onOptionClick, t
  } = props;
  return (
    <Popper
      open={exportOptionsOpen}
      anchorEl={exportBtnRef.current}
      role={undefined}
      transition
      style={{ zIndex: 111111 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={exportOptionsClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {dimensionExportOptions.map((option) => (
                  <MenuItem
                    key={option.key}
                    onClick={() => onOptionClick(option.key)}
                    style={styles.menuItemBtn}
                  >
                    {t(option.label)}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default DimensionExportPopper;

const styles = {
  menuItemBtn: {
    color: '#334D6E',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
  },
};
