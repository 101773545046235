import { Document,  Page } from 'react-pdf'
import { useTranslation } from 'react-i18next';
import { Box, Button, Tooltip, Skeleton } from '@mui/material';
import { KeyboardArrowUp as PreviousIcon, KeyboardArrowDown as NextIcon} from '@mui/icons-material';


type PropTypes = {
  files:any;
  activeInvoice?:any;
  navPos:number;
  previousDoc:Function;
  nextDoc:Function;
  handleActiveInvoice:Function;
  disablePreviewMove:boolean[]
}

const PreviewNavigation = ( props : PropTypes) => {
  const { 
    files, 
    handleActiveInvoice, 
    navPos, 
    previousDoc, 
    nextDoc,
    disablePreviewMove
  } = props;

  const { ready, t } = useTranslation();
  const handleActivePos = (index:number)=>{
    if(index === navPos) return 'activeDoc'
    return '';
  }

  return (
    <Box sx={styles.root}>
      <Tooltip title={disablePreviewMove[0] ? ready && t('INVOICE_EDIT_FORM_NO_PREV_TOOLTIP') : ''}>
        <span>
          <Button disabled={disablePreviewMove[0]} sx={styles.navButton} onClick={()=> previousDoc()}>
            <PreviousIcon />
          </Button>
        </span>
      </Tooltip>
      {
        <Box sx={styles.docWrapper} className="y-scroll">
          {
            files && files?.length > 0 ?
            files.filter((e:any, index:number) => index < 3).map((eFiles:any,index:number) => 
             (eFiles?.image ?
              <div className={handleActivePos(index)}
                style={{...styles.eachDocWrapper,position:'relative'}} key={index}>
                <span onClick={()=>handleActiveInvoice(eFiles?.invoice,index)}
                  style={{...styles.eachDocWrapperSpan,position:'absolute'}}>
                </span>
                <Document file={eFiles.image}
                  loading={
                    <Skeleton variant="rectangular" 
                      animation="wave"
                      width={31} height={44} 
                    />
                  }
                >
                  <Page 
                    className="each-pdf-preview"
                    width={31}
                    height={44}
                    pageNumber={1}
                  />
                </Document>
              </div>
              :<></>) 
            ):<></>         
          }
        </Box>
      }
      <Tooltip title={disablePreviewMove[1] ? ready && t('INVOICE_EDIT_FORM_NO_NEXT_TOOLTIP') : ''}>
        <span>
          <Button disabled={disablePreviewMove[1]}
            sx={styles.navButton} onClick={()=>nextDoc()}>
            <NextIcon />
          </Button>
        </span>
      </Tooltip>
    </Box>
  )
}

const styles={
  root:{
    width:'100%',
    height:'fit-content',
    padding:'10px 12px',
    backgroundColor:'transparent',
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    gap:'10px'
  },
  navButton:{
    backgroundColor:'#fff',
    color:'#4C4E64DE',
    minWidth:'40px',
    width:'40px',
    height:'40px',
    flexGrow:'0',
    border: '1px solid #4C4E6438',
    '&:hover':{
      backgroundColor:'#fff',
    }  
  },
  docWrapper:{
    margin:'0px 0',
    display:'flex',
    flexDirection:'column',
    gap:'5px',
    // maxHeight:'130px'
  },
  eachDocWrapper:{
    width:'40px',
    height:'50px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    border: '0.54px solid #4C4E6433',
    cursor:'pointer',
    borderRadius:'5px',
    overflow:'hidden'
  },
  eachDocWrapperSpan:{
    top:'0',
    left:'0',
    width:'100%',
    height:'100%',
    display:'inline-block',
    zIndex:'3',
    cursor:'pointer',
  }

  
}

export default PreviewNavigation