import { Box } from '@mui/material';

import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import SideBar from 'src/dimensions-new/components/Sidebar';
import Header from 'src/dimensions-new/components/Header';
import styles from './style';

const DimensionsNew = (props:any) => {
  const showImport = props?.showImport || false;
  return (
    <SideBarLayout>
      <Box sx={styles.root}>
        <Box sx={styles.wrapper}>
          <Header />
          <Box sx={styles.mainContainer}>
            <SideBar showImport={showImport} />
            {props.children}
          </Box>
        </Box>
      </Box>
    </SideBarLayout>
  )
}

export default DimensionsNew