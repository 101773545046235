import { useContext } from 'react';

import { Container } from '@mui/material';

import SelectDocument from 'src/documents/components/SelectDocument/SelectDocument';
import DocumentsListView from 'src/documents/components/DocumentsListView/DocumentsListView';
import { AuthContext } from 'src/contexts/AuthContext';
import styles from './style';

const DocumentsList = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      {!user.documentType && <SelectDocument open />}
      <Container maxWidth={false} style={styles.topContainer}>
        <DocumentsListView onDataLoaded={() => {}} />
      </Container>
    </>
  );
};

export default DocumentsList;
