import theme from "src/theme";

const styles = {
  manageContainer: {},
  fileLink: {
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 4,
    color: theme.palette.text.highlight2
  },
  linkButton: {
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 16,
    color: theme.palette.text.highlight2,
    cursor: 'pointer'
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 100
  },
  labelText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E',
    width: 100
  },
  savedChip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    fontSize: 10,
    color: '#FFFFFF',
    backgroundColor: '#4caf50',
    width: 60,
    height: 16,
    padding: '0px 12px'
  },
  inputField: {
    fontSize: 14,
    color: '#334D6E',
    border: 0,
    backgroundColor: '#E9E9E9',
    padding: '8px 12px',
    flex: 1,
    margin: '0px 8px',
    borderRadius: 4
  },
  checkIcon: {
    color: '#4caf50'
  },
  checkConnectionBtn: {
    fontSize: 14,
    color: '#334D6E',
    border: theme.palette.border.manage,
    textTransform: 'none'
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16
  },
  syncBtn: {
    fontSize: 14,
    color: '#334D6E',
    border: theme.palette.border.manage,
    textTransform: 'none',
  },
  loadingProgress: {
    marginLeft: 16
  },
  errorContainer: {
    display: 'flex',
    padding: '6px 0px 0px 48px'
  },
  labelTextError: {
    fontSize: 14,
    fontWeight: 500,
    color: '#f54248',
  },
  qbConnectText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E',
  },
  qbConnectBtn: {
    fontSize: 13,
    color: '#334D6E',
    border: theme.palette.border.manage,
    textTransform: 'none',
    marginLeft: 8,
    padding: '3px 8px'
  },
  integrationBody: {
    flex: 1,
    marginTop: '5px',
    marginBottom: '-10px'
  },
  integrationTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#334D6E'
  },
  integrationDesc: {
    fontSize: 14,
    color: '#334D6E',
    minHeight: 42
  },
  lineBreaker: {
    color: 'lightgray',
    height: 2,
    marginTop: 8,
    marginBottom: 8
  }
};

export default styles;
