import theme from "src/theme";

const styles = {
  root: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: '96%',
    height: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modalHeaderLeft: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  modalHeaderRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: theme.palette.icon.close,
  },
  modalContainer: {
    height: '100%',
    padding: '20px 0px 10px 0px',
  },
  leftContainer: {
    padding: '0px 20px 0px 10px',
    height: '90%'
  },
  invoiceContainer: {
    padding: '0px',
    backgroundColor: 'gray'
  },
  pdfFrame: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
    backgroundColor: 'gray'
  },
  lineRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '24px 0px'
  },
  documentNameContainer: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
  },
  documentNameLabel: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  documentName: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  aiSplitContainer: {
    display: 'flex',
    flex: 1,
  },
  aiSplitBtn: {
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
    fontSize: 13,
    minWidth: 128,
    color: theme.palette.text.primary,
    padding: '0px 0px 0px 8px',
    border: theme.palette.border.cancel
  },
  newLabel: {
    backgroundColor: '#EC5929',
    color: '#ffffff',
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 4,
    padding: '0px 8px 2px',
    position: 'relative',
    top: -8
  },
  lineLeftContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  lineRightContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  rangeItem: {
    margin: '24px 0px'
  },
  rangeRow: {
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rangeTitleText: {
    fontSize: 15,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  contentContainer: {
    height: '100%',
  },
  rangesContainer: {
    width: '100%',
    height: '75%',
    overflow: 'scroll',
  },
  rangeText: {
    fontSize: 13,
    color: theme.palette.text.primary,
    margin: '0px 8px 0px 8px'
  },
  rangeInput: {
    color: theme.palette.text.primary,
    fontSize: 13,
    width: '25%',
    height: 28,
    justifyContent: 'center',
  },
  addRangeBtn: {
    height: 28,
    textTransform: 'none',
    fontSize: 12,
    minWidth: 132,
    backgroundColor: theme.palette.button.action
  },
  optionsContainer: {
    height: '90%',
    overflow: 'scroll',
  },
  modalLeftFooter: {},
  confirmBtn: {
    height: 28,
    textTransform: 'none',
    fontSize: 12,
    minWidth: 132,
    marginLeft: 10,
    backgroundColor: theme.palette.button.action
  },
  dontSplitBtn: {
    height: 28,
    textTransform: 'none',
    marginLeft: 10,
    fontSize: 13,
    minWidth: 132,
    color: theme.palette.text.primary,
    border: theme.palette.border.cancel
  },
  aiSplittedMsgContainer: {
    width: '100%',
    backgroundColor: '#9df2a9',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  aiSplittedMsg: {
    color: '#478050',
    fontSize: 12,
    fontWeight: 500,
  },
  rangeErrorText: {
    color: '#EC5929',
    fontSize: 12,
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff',
  },
};

export default styles;
