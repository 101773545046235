const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 24px 12px 88px',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      maxWidth: '100%',
      borderRadius: '10px',
    }
  },
  modalHeader: {
    width: 'calc(100vw - 112px)',
    padding: '16px 16px 8px 16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '48px',
    borderBottom: '1px solid #4C4E641F'
  },
  backBtn: {
    padding: '4px',
    marginRight: '8px',
  },
  backIcon: {
    color: '#6D788D',
    height: '20px',
    width: '20px'
  },
  headerTitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#4C4E64',
    lineHeight: '36px'
  },
  modalContent: {
    width: 'calc(100vw - 112px)',
    padding: '0 16px',
    height: 'calc(100vh - 140px)',
  },
  leftPanel: {
    height: '100%',
    padding: '16px 16px 16px 0px',
    borderRight: '1px solid #4C4E641F'
  },
  dataFormatText: {
    fontSize: '13px',
    fontWeight: '400',
    color: '#4C4E64DE',
    padding: '8px 0px'
  },
  selectStyle: {
    height: '36px',
    width: '100%',
    border: '2px solid #C2CFE099',
    borderRadius: '4px',
    fontSize: '13px',
    fontWeight: '500',
    color: '#4C4E64',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  selectItem: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#4C4E64',
  },
  entitiesContainer: {
    marginTop: '16px',
  },
  entityContainer: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '42px',
    padding: '8px 12px 8px 16px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer'
  },
  entityContainerActive: {
    backgroundColor: '#3E8AFF26',
    width: '100%',
    height: '42px',
    padding: '8px 12px 8px 16px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  entityText: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#4C4E64DE'
  },
  entityTextActive: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#3E8AFF'
  },
  contentContainer: {
    padding: '16px 0px 16px 16px',
  },
  contentTitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#4C4E64',
  },
  mappingsContainer: {
    overflow: 'scroll',
    height: 'calc(100vh - 256px)',
    margin: '8px 0px',
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dragIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dragIcon: {
    width: '20px',
    height: '20px',
    color: '#6D788D',
  },
  inputItem: {
    flex: 1,
    padding: '8px',
  },
  inputText: {
    color: '#4C4E64',
    fontSize: '13px',
    lineHeight: '20px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid #C2CFE099',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C2CFE099',
      }
    },
  },
  selectInput: {
    color: '#4C4E64',
    fontSize: '13px',
    lineHeight: '20px',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #C2CFE099',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #C2CFE099',
    }
  },
  menuItem: {
    color: '#4C4E64',
    fontSize: '13px',
    lineHeight: '20px'
  },
  deleteIcon: {
    width: '20px',
    height: '20px',
    color: '#6D788D',
  },
  metaFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
    borderTop: '1px solid #4C4E641F',
    paddingTop: '16px',
  },
  addMappingBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 0px 8px 0px'
  },
  addMappingBtn: {
    height: '32px',
    backgroundColor: '#C2CFE099',
    color: '#4C4E64',
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '4px 12px',
  },
  addMappingIcon: {
    color: '#6D788D',
    height: '20px',
    width: '20px',
  },  
  modalFooter: {
    height: '68px',
    width: 'calc(100vw - 112px)',
    padding: '16px',
    borderTop: '1px solid #4C4E641F',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  formControlLabel: {
    marginRight: '16px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px !important',
      color: '#4C4E64 !important',
    }
  },
  cancelBtn: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#4C4E64',
    textTransform: 'none',
    minWidth: '108px',
    height: '36px',
    border: '1px #4C4E6433 solid',
    '&:hover': {
      border: '1px #4C4E6433 solid',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 8px -4px #4C4E646B',
    }
  },
  submitBtn: {
    minWidth: '108px',
    height: '36px',
    textTransform: 'none',
    marginLeft: '16px',
    backgroundColor: '#3E8AFF',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3E8AFF',
      boxShadow: '0px 4px 8px -4px #4C4E646B',
    }
  },
  saveIcon: {
    color: '#FFFFFF',
    width: '20px',
    height: '20px',
  },
  columnHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  columnHeaderFieldsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  columnHeaderText: {
    fontSize: '15px',
    fontWeight: '500',
    color: '#4C4E64DE',
  },
};

export default styles;
