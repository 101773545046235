import {combineReducers, configureStore} from '@reduxjs/toolkit';

import costCenterReducer from 'src/dimensions/costcenters/reducer';
import vatCodeReducer from 'src/dimensions/vatcodes/reducer';
import glaReducer from 'src/dimensions/glas/reducer';
import fieldsReducer from 'src/shared/fieldsReducer';

export const reducer = combineReducers({
  gla: glaReducer,
  vatcode: vatCodeReducer,
  costcenter: costCenterReducer,
  fields: fieldsReducer,
});

const store = configureStore({
  reducer,
});

export default store;
