import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList,
  Paper, Popper, TextField, Typography
} from '@mui/material';
import {
  ArrowDropDown
} from '@mui/icons-material';

import useWidth from 'src/hooks/useWidth';
import { DocumentDeliveryFormatType } from 'src/types';
import { getDeliverFormat } from 'src/utils/helpers';
import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import styles from './style';

interface PropTypes {
  open: boolean;
  emails: string;
  selectedDataFormatToEmail: string;
  deliverFormats: DocumentDeliveryFormatType[];
  handleClose: () => void;
  setEmails: Dispatch<SetStateAction<string>>;
  setSelectedDataFormatToEmail: Dispatch<SetStateAction<string>>;
  handleSendToEmailClick: () => void;
}

const ExportToEmailModal = (props: PropTypes) => {
  const { t } = useTranslation();

  const {
    open,
    emails,
    selectedDataFormatToEmail,
    deliverFormats,
    handleClose,
    setEmails,
    setSelectedDataFormatToEmail,
    handleSendToEmailClick,
  } = props;

  const emailsInputRef = useRef(null);
  const dataFormatOptionsToEmailRef = useRef(null);
  const [dataFormatOptionsToEmailOpen, setDataFormatOptionsToEmailOpen] = useState(false);
  
  const dataFormatWidth = useWidth(dataFormatOptionsToEmailRef, 0);

  const handleDataformatOptionsToEmailToggle = () => {
    setDataFormatOptionsToEmailOpen((prevOpen) => !prevOpen);
  };

  const handleDataformatOptionsToEmailClose = () => {
    setDataFormatOptionsToEmailOpen(false);
  };

  const handleDataFormatToEmailClick = (dataFormat: DocumentDeliveryFormatType) => {
    setSelectedDataFormatToEmail(dataFormat.key);
    setDataFormatOptionsToEmailOpen(false);
  };
  
  return (
    <CommonModal
      open={open}
      title={t('EXPORT_TO_EMAIL_MODAL_TITLE')}
      cancelText={t('DASHBOARD_INVOICES_CANCEL')}
      submitText={t('SPLIT_MENU_CONFIRM')}
      onSubmit={handleSendToEmailClick}
      handleClose={handleClose}
    >
      <Grid sx={styles.fieldContainer}>
        <Typography sx={styles.inputLabel}>
          {t('EXPORT_TO_EMAIL_MODAL_EMAIL')}
        </Typography>
        <TextField
          ref={emailsInputRef}
          name="lineDescription"
          value={emails}
          variant="outlined"
          sx={styles.inputField}
          onChange={(e) => setEmails(e.target.value)}
          helperText={t('EXPORT_TO_EMAIL_MODAL_INSTRUCTION')}
        />
      </Grid>
      <Grid sx={styles.fieldContainer}>
        <Typography sx={styles.inputLabel}>
          {t('EXPORT_TO_EMAIL_MODAL_DATA_FORMAT')}
        </Typography>
        <Button
          ref={dataFormatOptionsToEmailRef}
          variant="contained"
          sx={styles.dataFormatBtn}
          onClick={handleDataformatOptionsToEmailToggle}
        >
          {getDeliverFormat(selectedDataFormatToEmail, deliverFormats).label}
          <ArrowDropDown />
        </Button>
        <Popper
          open={dataFormatOptionsToEmailOpen}
          anchorEl={dataFormatOptionsToEmailRef.current}
          role={undefined}
          transition
          style={{ zIndex: 9999999, width: dataFormatWidth }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleDataformatOptionsToEmailClose}>
                  <MenuList className="y-scroll" autoFocusItem>
                    {deliverFormats.map((option: DocumentDeliveryFormatType) => !option.dontExportViaEmail && (
                      <MenuItem
                        key={option.key}
                        selected={option.key === selectedDataFormatToEmail}
                        onClick={() => handleDataFormatToEmailClick(option)}
                        sx={styles.exportOption}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </CommonModal>
  );
};

export default ExportToEmailModal;
