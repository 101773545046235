import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import { LinearProgress, Box } from '@mui/material';
import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  IHighlight,
  Popup,
  AreaHighlight,
  LTWHP,
  NewHighlight,
  ScaledPosition,
} from 'src/pdf-highligher';

import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import { FieldType, DocType } from 'src/types';
import { axiosHeaders, formatAmountFields, formatDateCustomFormat, formatDateDDMMYYYY } from 'src/utils/helpers';
import { BoundaryType, DocumentEditFormVatEsLineType } from 'src/document-edit/documentTypes';
import { getIsError, isOverlapping } from 'src/document-edit/utils';
import PDFActions from '../PDFActions/PDFActions';


interface PropTypes {
  pdfDoc: DocType;
  details: Record<string, string>;
  xml: string;
  viewerHeight: string;
  renderedFields: FieldType[];
  renderedFieldsRef: React.MutableRefObject<FieldType[]>;
  selectedTextField: FieldType;
  selectedTextFieldRef: React.MutableRefObject<FieldType>;
  highlights: Array<IHighlight>;
  highlightsRef: React.MutableRefObject<IHighlight[]>;
  pdfViewerWidth: string;
  viewLines: boolean;
  tipOriginalValue: React.MutableRefObject<string>;
  fieldTrustScoresRef: React.MutableRefObject<Record<string, number>>;
  handleChangeMain: (_field: FieldType, _value: string | null) => void;
  setSelectedTextField: (_val: FieldType) => void;
  handleChangeVatEsLine: (
    _val: string,
    _i: number,
    _prop: keyof DocumentEditFormVatEsLineType,
    _key: string,
  ) => void;
  handleChangeLines: (_value: string, _line: number, _prop: string) => void;
  setHighlights: (_val: Array<IHighlight>) => void;
  handleLinesOnBlur: (_value: string | boolean, _line: number, _prop: string) => void;
  setFieldTrustScores: (_val: Record<string, number>) => void;
  handleLinesDataForReprocessingChange: (_highlight: IHighlight) => void;
  setLoading: (_loading: boolean) => void;
  setTipOriginalValue: (_val: string) => void;
  revertTipText: (_key: string, _dataType: string, _value: string) => void;
}

const getNextId = () => String(Math.random()).slice(2);
const HighlightPopup = () => null;

const PdfViewer = (props: PropTypes) => {
  const {
    pdfDoc,
    details,
    xml,
    viewerHeight,
    renderedFields,
    renderedFieldsRef,
    selectedTextField,
    selectedTextFieldRef,
    highlights,
    highlightsRef,
    pdfViewerWidth,
    viewLines,
    tipOriginalValue,
    fieldTrustScoresRef,
    handleChangeMain,
    setSelectedTextField,
    handleChangeVatEsLine,
    handleChangeLines,
    setHighlights,
    handleLinesOnBlur,
    setFieldTrustScores,
    handleLinesDataForReprocessingChange,
    setLoading,
    setTipOriginalValue,
    revertTipText,
  } = props;
  const { API } = useContext(ConfigContext);
  const { t } = useTranslation();
  const { user } = useAuth();

  const decimalSeparator = user?.monetaryDecimalSeparator || '.';
  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);

  const [url, setUrl] = useState<string>('');
  const [pagesRotation, setPageRotation] = useState<number>(0);
  const [scaleValue, setScaleValue] = useState<string>('1');
  const [zoomPercentage, setZoomPercentage] = useState<string>('100');
  const [pageNumber, setPageNumber] = useState<string>('1');
  const [searchValue] = useState<string>('');
  const [fieldBoundaries, setFieldBoundaries] = useState<Record<string, BoundaryType | {}>>({});

  const fitHeightScaleValue = useRef<string>('1');
  const pageWidth = useRef<number>(0);
  const pageHeight = useRef<number>(0);
  const [numPages, setNumPages] = useState<number>(1);

  const processXml = (_xml: string) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(_xml, 'text/html');
    const pdfPage = htmlDoc.getElementsByTagName('page');
    const height = parseFloat(pdfPage[0]?.getAttribute('height') || '0');
    const width = parseFloat(pdfPage[0]?.getAttribute('width') || '0');
    pageHeight.current = height;
    pageWidth.current = width;

    const all = htmlDoc.getElementsByTagName('*');
    let pageNum = 0;
    const newWordHighlights: Array<IHighlight> = [];
    for (let i = 0; i < all.length; i++) {
      const item = all[i];

      if (item.tagName === 'PAGE') {
        pageNum++;
      }

      if (item.tagName === 'WORD') {
        const xmax = parseFloat(item.getAttribute('xmax') || '0');
        const xmin = parseFloat(item.getAttribute('xmin') || '0');
        const ymax = parseFloat(item.getAttribute('ymax') || '0');
        const ymin = parseFloat(item.getAttribute('ymin') || '0');

        const highlight: IHighlight = {
          id: getNextId(),
          position: {
            boundingRect: {
              x1: xmin,
              y1: ymin,
              x2: xmax,
              y2: ymax,
              width,
              height,
              pageNumber: pageNum,
            },
            rects: [
              {
                x1: xmin,
                y1: ymin,
                x2: xmax,
                y2: ymax,
                width,
                height,
                pageNumber: pageNum,
              },
            ],
            pageNumber: pageNum,
          },
          content: {
            text: item.innerHTML,
          },
          comment: {
            text: '',
          },
          isCandidate: false,
        };
        newWordHighlights.push(highlight);
      }
    }

    setNumPages(pageNum);

    return { height, width, wordHighlights: newWordHighlights };
  };

  const processBoundariesToHighlights = (boundariesData: Record<string, BoundaryType | {}>, _xml: string) => {
    const { height: pdfHeight, width: pdfWidth, wordHighlights } = processXml(_xml);
    const newCandidateHighlights: Array<IHighlight> = [];
    for (const key in boundariesData) {
      const fieldBoundary = boundariesData[key] as BoundaryType;
      const procysKey = key.replace('Boundary', '');
      const field = renderedFields.find((f) => f.key === procysKey);
      if (field !== undefined && Object.keys(fieldBoundary).length > 0) {
        const highlightCandidate: IHighlight = {
          id: getNextId(),
          position: {
            boundingRect: {
              x1: fieldBoundary.xmin,
              y1: fieldBoundary.ymin,
              x2: fieldBoundary.xmax,
              y2: fieldBoundary.ymax,
              width: pdfWidth,
              height: pdfHeight,
              pageNumber: fieldBoundary.page,
            },
            rects: [
              {
                x1: fieldBoundary.xmin,
                y1: fieldBoundary.ymin,
                x2: fieldBoundary.xmax,
                y2: fieldBoundary.ymax,
                width: pdfWidth,
                height: pdfHeight,
                pageNumber: fieldBoundary.page,
              },
            ],
            pageNumber: fieldBoundary.page,
          },
          content: {
            text: details[procysKey] || '',
          },
          comment: {
            text: t(field.label),
          },
          isCandidate: true,
          procysKey,
        };
        newCandidateHighlights.push(highlightCandidate);
      }
    }

    const newWordHighlights = wordHighlights.filter((wh) =>
      !newCandidateHighlights.some((ch) =>
        isOverlapping(wh.position.boundingRect, ch.position.boundingRect)
      )
    );
    setHighlights([...newCandidateHighlights, ...newWordHighlights]);
  };

  const processOcrDataToBoundaries = (ocrData: Record<string, Record<string, string>>) => {
    const newFieldBoundaries: Record<string, BoundaryType | {}> = {};
    for (const key in ocrData) {
      if (ocrData[key].xmin) {
        const fieldBoundary: BoundaryType = {
          height: parseInt(ocrData[key].height) || 0,
          page: parseInt(ocrData[key].page) || 0,
          width: parseInt(ocrData[key].width) || 0,
          xmax: parseInt(ocrData[key].xmax) || 0,
          xmin: parseInt(ocrData[key].xmin) || 0,
          ymax: parseInt(ocrData[key].ymax) || 0,
          ymin: parseInt(ocrData[key].ymin) || 0
        };
        newFieldBoundaries[key] = fieldBoundary;
      } else {
        newFieldBoundaries[key] = {};
      }
    }
    setFieldBoundaries(newFieldBoundaries);
  };

  const getFieldBoundaries = async () => {
    if (!pdfDoc.assistantID) {
      return;
    }
    try {
      const resp = await Axios.get(
        `${API.getDocumentBoundaries}${pdfDoc.assistantID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success && resp.data.data) {
        processOcrDataToBoundaries(resp.data.data);
      }
    } catch (e) {
      //
    }
  };

  useEffect(() => {
    if(pdfDoc && pdfDoc.id){
      const nameParts = pdfDoc?.documentFile?.split('.');
      let imageName = '';
      if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
        imageName = `${nameParts[0]}-display.pdf`;
      } else {
        imageName = pdfDoc.documentFile || '';
      }
      setUrl(`${API.getInvoiceImage}${imageName}`);

      getFieldBoundaries();
    }
  }, [pdfDoc]);

  useEffect(() => {
    if (xml !== '') {
      processBoundariesToHighlights(fieldBoundaries, xml);
    }
  }, [fieldBoundaries, xml, renderedFields]);

  useEffect(() => {
      const viewPortHeight = Math.max((window.innerHeight - 236), 500);
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(xml, 'text/html');
      const pdfPage = htmlDoc.getElementsByTagName('page');
      const pdfHeight = parseFloat(pdfPage[0]?.getAttribute('height') || '0');
      const val = viewPortHeight / pdfHeight;
      const zoomPercentage = (val * 100).toString();
      if (!Number.isNaN(parseInt(zoomPercentage, 10))) {
        setScaleValue(val.toFixed(2));
        setZoomPercentage((val * 100).toString())
        fitHeightScaleValue.current = (val * 100).toString();
      }
  }, [xml]);

  const updateHighlight = (highlightID: string, newContent: Object) => {
    const candidateHighlights = highlightsRef.current.filter((highlight) => highlight.isCandidate);
    const oldCandidateHighlightID = candidateHighlights.find((highlight) => highlight.procysKey === selectedTextFieldRef.current.key)?.id;
    setHighlights(
      highlightsRef.current.map((highlight) =>
        highlight.id === highlightID
          ? {
              ...highlight,
              content: newContent,
              isCandidate: true,
              procysKey: selectedTextFieldRef.current.key,
              comment: { text: selectedTextFieldRef.current.label }
            }
          : highlight.id === oldCandidateHighlightID
            ? { ...highlight, isCandidate: false, procysKey: undefined, comment: { text: '' } }
            : highlight
      )
    );
  };

  const removeWordsAndAddCandidate = (highlightIDs: string[], highlight: NewHighlight) => {
    const newHighlightID = getNextId();
    const candidateHighlights = highlightsRef.current.filter((highlight) => highlight.isCandidate);
    const oldCandidateHighlightID = candidateHighlights.find((highlight) => highlight.procysKey === selectedTextFieldRef.current.key)?.id;
    const filteredHighlights = highlightsRef.current.filter((highlight) => !highlightIDs.includes(highlight.id));
    const newHighlights = filteredHighlights.map((highlight) => 
        highlight.id === oldCandidateHighlightID
          ? { ...highlight, isCandidate: false, procysKey: undefined, comment: { text: '' } }
          : highlight
    );
    setHighlights([
      ...newHighlights,
      {
        ...highlight,
        id: newHighlightID,
        isCandidate: true,
        procysKey: selectedTextFieldRef.current.key,
        comment: {
          text: selectedTextFieldRef.current.label
        }
      }
    ]);
  };

  const removeCandidateAndFocusTextField = (currKey: string) => {
    setHighlights(highlightsRef.current.map((highlight) => {
      if (highlight.procysKey === currKey) {
        highlight.isCandidate = false;
        highlight.procysKey = undefined;
      }
      return highlight;
    }));
  };

  const handleChangeTipText = (value: string) => {
    const currKey = selectedTextFieldRef.current.key;

    if (currKey.startsWith('VATLine')) {
      const vatLineSplit = currKey.split('_');
      const vatLineKey = vatLineSplit[0].replace('Line', '');
      const vatLineIndex = parseInt(vatLineSplit[1]) - 1;
      const vatLineProp = vatLineSplit[2] as keyof DocumentEditFormVatEsLineType;
      handleChangeVatEsLine(value, vatLineIndex, vatLineProp, vatLineKey);
    } else if (currKey.startsWith('ESLine')) {
      const esLineSplit = currKey.split('_');
      const esLineKey = esLineSplit[0].replace('Line', '');
      const esLineIndex = parseInt(esLineSplit[1]) - 1;
      const esLineProp = esLineSplit[2] as keyof DocumentEditFormVatEsLineType;
      handleChangeVatEsLine(value, esLineIndex, esLineProp, esLineKey);
    } else if (currKey.startsWith('line')) {
      const lineSplit = currKey.split('_');
      if (lineSplit[2] === 'lineExtraCost') {
        handleLinesOnBlur(Boolean(value), parseInt(lineSplit[1]), lineSplit[2]);
      } else {
        handleChangeLines(value, parseInt(lineSplit[1]), lineSplit[2]);
      }
    } else {
      handleChangeMain(selectedTextFieldRef.current, value);
    }
    const isError = getIsError(currKey, selectedTextFieldRef.current.dataType, value, user);
    if (!isError) {
      setFieldTrustScores({ ...fieldTrustScoresRef.current, [currKey]: 2 });
    }
  };

  const onConfirmTip = (
    highlightID: string,
    position: ScaledPosition,
    value: string,
    hideTipAndSelection: () => void,
    overlappingHighlightIDs: string[],
    shiftPosition: ScaledPosition,
  ) => {
    const currKey = selectedTextFieldRef.current.key;
    if (value === '') {
      removeCandidateAndFocusTextField(currKey);
    } else {
      if (highlightID && overlappingHighlightIDs.length <= 1) {
        updateHighlight(highlightID, { text: value });
      } else {
        let iPosition = position;
        if (highlightID) {
          iPosition = shiftPosition;
        }
        removeWordsAndAddCandidate(overlappingHighlightIDs, {
          position: iPosition,
          content: { text: value },
          comment: { text: selectedTextFieldRef.current.label }
        });
      }
    }
    handleChangeTipText(value);
    hideTipAndSelection();
    const index = renderedFieldsRef.current.findIndex((field) => field.key === currKey);
    if (index === -1 || index === renderedFieldsRef.current.length - 1) {
      setSelectedTextField({
        key: '',
        assistantKey: '',
        label: '',
        appDbField: '',
        dataType: '',
        exportMapKey: '',
        position: -1,
      });
      return;
    }
    const field = renderedFieldsRef.current[index + 1];

    const element = document.getElementById(field.key);
    if (element) element.focus();
    setSelectedTextField(field);
  };

  const onCancelTip = (hideTipAndSelection: () => void) => {
    hideTipAndSelection();
    const { key, dataType } = selectedTextFieldRef.current;
    revertTipText(key, dataType, tipOriginalValue.current);
  };

  const onSelectAreaForLinesReprocessing = (highlight: NewHighlight) => {
    const newHighlightID = getNextId();
    setHighlights([
      ...highlightsRef.current,
      {
        ...highlight,
        id: newHighlightID,
        isLinesReprocessing: true,
      }
    ]);
    handleLinesDataForReprocessingChange({ ...highlight, id:newHighlightID });
  };

  const formatValue = (_val: string) => {
    const currKey = selectedTextFieldRef.current.key;
    const dataType = selectedTextFieldRef.current.dataType;
    let formattedVal = _val;
    if (dataType === 'float' && (!amountRegex.test(_val.trim()) || decimalSeparator !== '.')) {
      formattedVal = formatAmountFields(_val.trim(), decimalSeparator);
    } else if (dataType === 'date') {
      const fieldRule = user?.fieldRules.find((fr) => fr.field === currKey);
      formattedVal = fieldRule?.format
        ? formatDateCustomFormat(_val, fieldRule?.format)
        : formatDateDDMMYYYY(_val);
    }
    return formattedVal;
  };

  const scrollTextFieldIntoView = (procysKey: string) => {
    const element = document.getElementById(procysKey);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
          inline: 'nearest'
        });
      }, 100);
      const field = renderedFields.find((field) => field.key === procysKey);
      if (field) {
        setSelectedTextField(field);
      }
    }
  };

  const handleSetLoading = (show:boolean) =>{
    setLoading(show);
  }

  return (
    <Box className="dashed-grid-paper">
      {url && <PdfLoader
        url={url}
        //@ts-ignore
        httpHeaders={axiosHeaders(localStorage.getItem('PROCYS_accessToken')).headers}
        beforeLoad={<LinearProgress />}
        setLoading={handleSetLoading}
      >
        {(pdfDocument) => (
          <PdfHighlighter
            pagesRotation={pagesRotation}
            searchValue={searchValue}
            onSearch={(_currentMatch, _totalMatchCount) => {}}
            findRefs={(_findPrev, _findNext) => {}}
            pdfDocument={pdfDocument}
            pdfScaleValue={scaleValue}
            pdfPageNumber={pageNumber}
            onSelectionFinished={(
              highlightID,
              position,
              content,
              hideTipAndSelection,
              transformSelection,
              overlappingHighlightIDs,
              shiftPosition,
            ) => (
              <Tip
                fieldLabel={t(selectedTextFieldRef.current.label)}
                value={formatValue(content.text || '')}
                onOpen={transformSelection}
                onConfirm={(value) => onConfirmTip(highlightID, position, value, hideTipAndSelection, overlappingHighlightIDs, shiftPosition)}
                onCancel={() => onCancelTip(hideTipAndSelection)}
                handleChangeTipText={handleChangeTipText}
                setTipOriginalValue={setTipOriginalValue} 
              />
            )}
            highlightTransform={(
              highlight,
              index,
              setTip,
              hideTip,
              viewportToScaled,
              screenshot,
              isScrolledTo,
              onClickHighlight,
            ) => {
              const isTextHighlight = !(highlight.content && highlight.content.image);
              const { isCandidate, isLinesReprocessing, position, procysKey } = highlight;

              const component = isTextHighlight ? (
                <Highlight
                  id={highlight.id}
                  isScrolledTo={isScrolledTo}
                  position={highlight.position}
                  comment={highlight.comment}
                  content={highlight.content}
                  onClick={onClickHighlight}
                  pageNum={position.pageNumber}
                  procysKey={procysKey}
                  fieldSeparator={selectedTextFieldRef.current.separator || ' '}
                  isCandidate={Boolean(isCandidate)}
                  isLinesReprocessing={Boolean(isLinesReprocessing)}
                />
              ) : (
                <AreaHighlight
                  isScrolledTo={isScrolledTo}
                  highlight={highlight}
                  onChange={(boundingRect: LTWHP) => {
                    updateHighlight(
                      highlight.id,
                      { image: screenshot(boundingRect) }
                    );
                  }}
                />
              );

              return (
                <Popup
                  popupContent={<HighlightPopup />}
                  onMouseOver={() => {}}
                  onMouseOut={hideTip}
                  key={index}
                  children={component}
                />
              );
            }}
            highlights={highlights}
            viewerHeight={viewerHeight}
            selectedTextField={selectedTextField}
            selectedTextFieldRef={selectedTextFieldRef}
            scrollTextFieldIntoView={scrollTextFieldIntoView}
            pageHeight={pageHeight}
            pageWidth={pageWidth}
            viewLines={viewLines}
            onSelectAreaForLinesReprocessing={onSelectAreaForLinesReprocessing}
            setPageNumber={setPageNumber}
          />
        )}
      </PdfLoader>}
      <PDFActions
        doc={pdfDoc}
        pagesRotation={pagesRotation}
        pdfViewerWidth={pdfViewerWidth}
        viewLines={viewLines}
        numPages={numPages}
        pageNumber={pageNumber}
        zoomPercentage={zoomPercentage}
        fitHeightScaleValue={fitHeightScaleValue}
        setZoomPercentage={setZoomPercentage}
        onScaleChange={(val: string) => setScaleValue(val)}
        onRotationChange={(val: number) => setPageRotation(val)}
        onChangePageNumber={(val: string) => setPageNumber(val)}
      />
    </Box>
  );
};

export default PdfViewer;
