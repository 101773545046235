const styles = {
  root: {},
  topBar: {
    padding: '12px 40px',
    color: '#ffffff'
  },
  xmlNavBtnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  searchInput: {
    textAlign: 'center',
    color: '#fff!important',
    width: '50px',
    marginRight: '5px',
  },
  buttonCls: {
    cursor: 'pointer',
    marginRight: '2px',
    minWidth: 48,
    color: '#ffffff'
  },
  pdfTop: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  instructionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#e3e3e3',
    padding: '4px 8px',
    borderRadius: 8,
  },
  instructions: {
    color: '#000000',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    margin: '2px 0px'
  },
  keyIcon: {
    border: '1px #000000 solid',
    fontSize: 12,
    margin: '0px 8px',
    borderRadius: 8,
    padding: '0px 2px',
    height: 20
  },
  pdfContainer: {
    backgroundColor: 'gray',
    height: '88%',
    padding: '0px 12px 10px 12px',
    overflow: 'scroll',
    minHeight: '360px'
  },
  documentStyle: {
    height: '100%',
    width: '100%'
  },
  navButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 12px 12px 12px',
    margin: '0px -8px',
    backgroundColor: '#ffffff'
  },
  navButton: {
    height: 42,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    padding: '0px 15px',
    border: '1px #e4e4e4 solid',
  },
  btnIconArrowLeft: {
    height: 36,
    width: 36,
    marginLeft: -16,
    marginRight: -8,
    color: '#bebebe',
  },
  btnIconArrowRight: {
    height: 36,
    width: 36,
    marginRight: -16,
    marginLeft: -8,
    color: '#bebebe',
  },
  container: {
    maxHeight: '100vh',
    overflow: 'auto'
  },
  leftContainerMobile: {
    maxHeight: '920px'
  },
  leftContainer: {
    maxHeight: '95vh',
  },
  cardContent: {
    backgroundColor: '#ffffff',
    padding: '20px 8px 0px'
  },
  inputContainerMobile: {
    padding: '0px 16px',
    maxHeight: '410px',
    overflow: 'scroll'
  },
  inputContainer: {
    padding: '0px 16px',
    maxHeight: '42vh',
    overflow: 'scroll'
  },
  inputItem: {
    padding: '8px 16px 16px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    }
  },
  inputItemsContainerRow: {
    display: 'flex',
  },
  textFieldSelect: {
    backgroundColor: '#e8e8e8'
  },
  textFieldHighlighted: {
    backgroundColor: '#d7e7fc'
  },
  manageFieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 12px 0px 12px'
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px',
    marginRight: '12px'
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
  invoiceContainer: {
    padding: '0px',
    backgroundColor: 'gray'
  },
  backdrop: {
    zIndex: 1111,
    color: '#ffffff',
  },
  metricsText: {
    fontSize: 13,
    lineHeight: '21px',
    color: '#3E8AFF',
    cursor: 'pointer',
    paddingLeft: 0,
    textTransform: 'none',
    textDecoration: 'underline',
    fontWeight: '500',
  },
  pdfFrame: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
    backgroundColor: 'gray'
  },
  formControl: {
    width: '100%'
  },
  inputText: {
    color: '#334D6E',
    fontSize: '13px',
    lineHeight: '20px'
  },
  countryCodeField: {
    marginRight: 10,
    width: '20%'
  },
  countryCodePaper: {
    width: '180px'
  },
};

export default styles;
