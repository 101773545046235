import {
  useState, useEffect, useRef, useContext
} from 'react';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import authService from 'src/utils/authService';
import { axiosHeaders } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import { useAuth } from 'src/hooks/useAuth';
import { invoicePageRefreshTimerNew, invoiceStatusesKeys } from 'src/config';
import { CompanyType, DocType, OwnerType, SupplierType, UserDataType } from 'src/types';
import DocumentsList from 'src/documents-new/components/DocumentsList/DocumentsList';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';

const DocumentsListContainer = () => {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user, setUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [containsFailedToExport, setContainsFailedToExport] = useState(false);
  const [containsIsDuplicate, setContainsIsDuplicate] = useState(false);
  const [containsPossibleDuplicate, setContainsPossibleDuplicate] = useState(false);

  const lastModifiedTime = useRef<number>(0);
  const archivedLastModifiedTime = useRef<number>(0);

  const [page, _setPage] = useState('0');
  const pageRef = useRef(page);
  const setPage = (val: string) => {
    pageRef.current = val;
    _setPage(val);
  };

  const [limit, _setLimit] = useState('10');
  const limitRef = useRef(limit);
  const setLimit = (val: string) => {
    limitRef.current = val;
    _setLimit(val);
  };

  const [search, _setSearch] = useState('');
  const searchRef = useRef(search);
  const setSearch = (val: string) => {
    searchRef.current = val;
    _setSearch(val);
  };

  const [sortBy, _setSortBy] = useState('');
  const sortByRef = useRef(sortBy);
  const setSortBy = (val: string) => {
    sortByRef.current = val;
    _setSortBy(val);
  };

  const [order, _setOrder] = useState('desc');
  const orderRef = useRef(order);
  const setOrder = (val: string) => {
    orderRef.current = val;
    _setOrder(val);
  };

  // status filter
  const [filter, _setFilter] = useState('');
  const filterRef = useRef(filter);
  const setFilter = (val: string) => {
    filterRef.current = val;
    _setFilter(val);
  };

  const [time, _setTime] = useState('');
  const timeRef = useRef(time);
  const setTime = (val: string) => {
    timeRef.current = val;
    _setTime(val);
  };

  const [suppliers, _setSuppliers] = useState('');
  const suppliersRef = useRef(suppliers);
  const setSuppliers = (val: string) => {
    suppliersRef.current = val;
    _setSuppliers(val);
  };

  const [owners, _setOwners] = useState('');
  const ownersRef = useRef(owners);
  const setOwners = (val: string) => {
    ownersRef.current = val;
    _setOwners(val);
  };

  const [startTime, _setStartTime] = useState('');
  const startTimeRef = useRef(startTime);
  const setStartTime = (val: string) => {
    startTimeRef.current = val;
    _setStartTime(val);
  };

  const [endTime, _setEndTime] = useState('');
  const endTimeRef = useRef(endTime);
  const setEndTime = (val: string) => {
    endTimeRef.current = val;
    _setEndTime(val);
  };

  const [failedToExport, _setFailedToExport] = useState(false);
  const failedToExportRef = useRef(failedToExport);
  const setFailedToExport = (val: boolean) => {
    failedToExportRef.current = val;
    _setFailedToExport(val);
  };

  const [isThisDuplicate, _setIsDuplicate] = useState(false);
  const isDuplicateRef = useRef(isThisDuplicate);
  const setIsDuplicate = (val: boolean) => {
    isDuplicateRef.current = val;
    _setIsDuplicate(val);
  };

  const [isThisPossibleDuplicate, _setIsPossibleDuplicate] = useState(false);
  const isPossibleDuplicateRef = useRef(isThisPossibleDuplicate);
  const setIsPossibleDuplicate = (val: boolean) => {
    isPossibleDuplicateRef.current = val;
    _setIsPossibleDuplicate(val);
  };

  const [companies, _setCompanies] = useState<string[]>([]);
  const companiesRef = useRef(companies);
  const setCompanies = (val: string[]) => {
    companiesRef.current = val;
    _setCompanies(val);
  };

  const [docs, setDocs] = useState<DocType[]>([]);
  // eslint-disable-next-line no-undef
  const intervalId = useRef<NodeJS.Timer | null>(null);
  let lastURL = '';

  const onClearInterval = () => {
    if (intervalId.current) clearInterval(intervalId.current);
  };

  const isParamsChanged = (
    thisPage: string,
    thisLimit: string,
    thisSearch: string,
    thisFilter: string,
    thisTime: string,
    suppliersList: SupplierType[] | string,
    ownersList: OwnerType[] | string,
    thisStartTime: string,
    thisEndTime: string,
    thisFailedToExport: boolean,
    thisDuplicate: boolean,
    thisPossibleDuplicate: boolean,
    thisSortBy: string,
    thisOrder: string,
    companiesList: CompanyType[] | string[]
  ) => {
    return (
      thisPage !== pageRef.current ||
      thisLimit !== limitRef.current ||
      thisSearch !== searchRef.current ||
      thisFilter !== filterRef.current ||
      thisTime !== timeRef.current ||
      suppliersList !== suppliersRef.current ||
      ownersList !== ownersRef.current ||
      thisStartTime !== startTimeRef.current ||
      thisEndTime !== endTimeRef.current ||
      thisFailedToExport !== failedToExportRef.current ||
      thisDuplicate !== isDuplicateRef.current ||
      thisPossibleDuplicate !== isPossibleDuplicateRef.current ||
      thisSortBy !== sortByRef.current ||
      thisOrder !== orderRef.current ||
      companiesList !== companiesRef.current
    );
  };

  const isParamsChangedArchived = (
    thisPage: string,
    thisLimit: string,
    thisSearch: string,
    thisTime: string,
    thisStartTime: string,
    thisEndTime: string,
    thisSortBy: string,
    thisOrder: string,
  ) => {
    return (
      thisPage !== pageRef.current ||
      thisLimit !== limitRef.current ||
      thisSearch !== searchRef.current ||
      thisTime !== timeRef.current ||
      thisStartTime !== startTimeRef.current ||
      thisEndTime !== endTimeRef.current ||
      thisSortBy !== sortByRef.current ||
      thisOrder !== orderRef.current
    );
  };

  const getDocuments = async (
    thisPage: string = '0',
    thisLimit: string = '10',
    thisSearch: string = '',
    thisFilter: string = '',
    thisTime: string = '',
    suppliersList: SupplierType[] | string = [],
    ownersList: OwnerType[] | string = [],
    thisStartTime: string = '',
    thisEndTime: string = '',
    thisFailedToExport: boolean = false,
    thisDuplicate: boolean = false,
    thisPossibleDuplicate: boolean = false,
    thisSortBy: string = '',
    thisOrder: string = 'desc',
    companiesList: CompanyType[] | string[] = []
  ) => {
    if (isParamsChanged(
      thisPage,
      thisLimit,
      thisSearch,
      thisFilter,
      thisTime,
      suppliersList,
      ownersList,
      thisStartTime,
      thisEndTime,
      thisFailedToExport,
      thisDuplicate,
      thisPossibleDuplicate,
      thisSortBy,
      thisOrder,
      companiesList
    )) {
      lastModifiedTime.current = 0;
    }

    let thisSuppliers = '';
    if (Array.isArray(suppliersList)) {
      thisSuppliers = suppliersList.map((supplier) => supplier.creditorCode).join(',');
    } else if (suppliersList) {
      thisSuppliers = suppliersList;
    }

    let thisOwners = '';
    if (Array.isArray(ownersList)) {
      thisOwners = ownersList.map((owner) => owner.email).join(',');
    } else if (ownersList) {
      thisOwners = ownersList;
    }

    const thisCompanies = companiesList.map((company) => typeof company !== 'string' ? company.companyId : company);

    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setPage(thisPage);
    setLimit(thisLimit);
    setSearch(thisSearch);
    setFilter(thisFilter);
    setTime(thisTime);
    setSuppliers(thisSuppliers);
    setOwners(thisOwners);
    setStartTime(thisStartTime);
    setEndTime(thisEndTime);
    setFailedToExport(thisFailedToExport);
    setIsDuplicate(thisDuplicate);
    setIsPossibleDuplicate(thisPossibleDuplicate);
    setSortBy(thisSortBy);
    setOrder(thisOrder);
    setCompanies(thisCompanies);

    try {
      let url = `${API.getAllInvoices}/${encodeURIComponent(user?.companyID || '')}?modifiedTime=${lastModifiedTime.current}`
        + `&page=${parseInt(thisPage) + 1}&limit=${thisLimit}&order=${thisOrder}${thisSortBy 
          && thisSortBy !== '' ? `&sortby=${thisSortBy}` : ''}${thisSearch
          && thisSearch !== '' ? `&search=${thisSearch}` : ''}${thisFilter
          && thisFilter !== '' ? `&filter=${thisFilter}` : ''}${thisFailedToExport
          ? `&failedToExport=${thisFailedToExport.toString()}` : ''}`;
      if (thisStartTime && thisStartTime !== '' && thisEndTime && thisEndTime !== '') {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime && thisTime !== '' ? `&time=${thisTime}` : ''}`;
      }
      lastURL = url;

      const body = {
        suppliers: thisSuppliers,
        owners: thisOwners,
        isDuplicate: thisDuplicate,
        isPossibleDuplicate: thisPossibleDuplicate,
        companies: thisCompanies
      };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response && response.config && response.config.url === lastURL) {
        if (response.status === 304) return;
        if (response.data.success) {
          setContainsFailedToExport(response.data.containsFailedToExport);
          if (response.data.data.length > 0) {
            setDocs(response.data.data);
            setTotal(response.data.total);
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].isDuplicate) {
                setContainsIsDuplicate(true);
              }
              if (response.data.data[i].status === 201) {
                setContainsPossibleDuplicate(true);
              }
            }
          } else {
            setDocs([]);
            setTotal(0);
            setContainsIsDuplicate(false);
            setContainsPossibleDuplicate(false);
          }
          if (response.data.modifiedTime) lastModifiedTime.current = response.data.modifiedTime;

          // Update invoices count
          if (user && setUser) {
            const updatedUser: UserDataType = {
              ...user,
              invoicesCount: response.data.invoicesUsed,
            };
            setUser(updatedUser);
          }
        }
      }
    } catch (error) {
      //
    }
    setLoading(false);
  };

  const getArchivedDocuments = async (
    thisPage: string = '0',
    thisLimit: string = '10',
    thisSearch: string = '',
    thisTime: string = '',
    thisStartTime: string = '',
    thisEndTime: string = '',
    thisSortBy: string = '',
    thisOrder: string = 'desc',
  ) => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    if (isParamsChangedArchived(
      thisPage,
      thisLimit,
      thisSearch,
      thisTime,
      thisStartTime,
      thisEndTime,
      thisSortBy,
      thisOrder,
    )) {
      archivedLastModifiedTime.current = 0;
    }

    setPage(thisPage);
    setLimit(thisLimit);
    setSearch(thisSearch);
    setTime(thisTime);
    setFilter(invoiceStatusesKeys.archived)
    setStartTime(thisStartTime);
    setEndTime(thisEndTime);
    setSortBy(thisSortBy);
    setOrder(thisOrder);
    setOwners('');
    setSuppliers('');
    setIsDuplicate(false);
    setIsPossibleDuplicate(false);
    setCompanies([]);

    try {
      let url = `${API.getArchivedInvoices}/${encodeURIComponent(user?.companyID || '')}`
      + `?modifiedTime=${archivedLastModifiedTime.current}`
      + `&page=${parseInt(thisPage) + 1}&limit=${thisLimit}&order=${thisOrder}`
      + `${thisSortBy ? `&sortby=${thisSortBy}` : ''}${thisSearch
        && thisSearch !== '' ? `&search=${thisSearch}` : ''}`;
      if (thisStartTime && thisStartTime !== '' && thisEndTime && thisEndTime !== '') {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime && thisTime !== '' ? `&time=${thisTime}` : ''}`;
      }
      lastURL = url;
      const response = await Axios.post(url, {}, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response && response.config && response.config.url === lastURL) {
        if (response.status === 304) return;
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setDocs(response.data.data);
            setTotal(response.data.total);
            setContainsFailedToExport(response.data.containsFailedToExport);
          } else {
            setDocs([]);
            setTotal(0);
            setContainsFailedToExport(false);
          }
        }
      }
    } catch (error) {
      //
    }
    setLoading(false);
  };

  const onStartInterval = () => {
    intervalId.current = setInterval(() => {
      if (filterRef.current === invoiceStatusesKeys.archived) {
        getArchivedDocuments(
          pageRef.current,
          limitRef.current,
          searchRef.current,
          timeRef.current,
          startTimeRef.current,
          endTimeRef.current,
          sortByRef.current,
          orderRef.current
        );
      } else {
        getDocuments(
          pageRef.current,
          limitRef.current,
          searchRef.current,
          filterRef.current,
          timeRef.current,
          suppliersRef.current,
          ownersRef.current,
          startTimeRef.current,
          endTimeRef.current,
          failedToExportRef.current,
          isDuplicateRef.current,
          isPossibleDuplicateRef.current,
          sortByRef.current,
          orderRef.current,
          companiesRef.current
        );
      }
    }, invoicePageRefreshTimerNew);
  };

  useEffect(() => {
    getDocuments('0', '10');
    onStartInterval();
    return () => onClearInterval();
  }, []);

  if (loading) {
    return (
      <Box sx={styles.loadingContainer}>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <DocumentsList
      docs={docs}
      total={total}
      time={time}
      containsFailedToExport={containsFailedToExport}
      containsIsDuplicate={containsIsDuplicate}
      containsPossibleDuplicate={containsPossibleDuplicate}
      startTimeRef={startTimeRef}
      endTimeRef={endTimeRef}
      getDocuments={getDocuments}
      getArchivedInvoices={getArchivedDocuments}
      onClearInterval={onClearInterval}
      onStartInterval={onStartInterval}
    />
  );
};

const styles = {
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    marginTop: '24px',
    backgroundColor: '#FFFFFF',
    height: '100%',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    borderRadius: '10px',
  }
};

export default DocumentsListContainer;
