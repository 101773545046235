import theme from "src/theme";

const styles = {
  root: {
    padding: '16px 20px',
    margin: '16px 0px 0px 0px'
  },
  formInputRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  annoInterfaceLabelContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  annoInterfaceLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  annoInterfaceHelp: {
    padding: '0px 8px',
    color: '#c8c8c8',
    fontSize: '12px'
  },
  interfaceIcon: {
    fontSize: '14px'
  },
  formInputSelect: {
    paddingTop: '13px!important',
    paddingBottom: '13px!important',
    width: '100%',
    color: theme.palette.text.primary,
    fontSize: 14
  },
  inputText: {
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  formInputLeft: {
    padding: '13px 13px 13px 0px !important',
  },
  formInputRight: {
    padding: '13px 0px 13px 13px !important',
  },
  formInput: {
    paddingTop: '13px!important',
    paddingBottom: '13px!important',
  },
  manageExportBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: theme.palette.button.cancel,
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    border: theme.palette.border.cancel,
    margin: '6px 0px 6px 0px'
  },
  arPaper: {
    padding: '10px',
    borderRadius: '15px',
    borderColor: '#d3d3d3',
    borderWidth: '2.3px',
    width: '400px',
    boxShadow: '0px 0px 0px #d3d3d3'
  },
  arSubTitle: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  list: {
    marginBottom: '0px',
    color: theme.palette.text.primary,
  },
  listItem: {
    margin: '0px',
    padding: '0px'
  },
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  listItemTextWithBt: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    marginBottom: '8px',
  },
  bulletPoint: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    marginRight: '10px',
    marginBottom: '23px',
  },
};

export default styles;
