import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Button, Grid, Typography
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Page from 'src/shared/screens/Page';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';

import authService from 'src/utils/authService';
import { axiosHeaders } from 'src/utils/helpers';
import {
  glaMsg, vatMsg, cctMsg, prjtMsg, defaultMsg
} from 'src/config';
import ConfigContext from 'src/contexts/ConfigContext';
import { useAuth } from 'src/hooks/useAuth';

const DimensionLogView = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { LOGIN_PANEL_URL, API, BRAND_NAME } = useContext(ConfigContext);
  const { dim } = match.params;
  let code = location?.state?.code || '', type = location?.state?.type;
  
  const company = user?.company;
  const { t, ready } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [fetchingLog, setFetchingLog] = useState(true);

  const [log, setLog] = useState(null);

  let req; // Request;
  let resp; // Response;
  let title;
  let dimLogViewTitle;

  switch (dim) {
    case 'glaccounts':
      title = glaMsg.GLA_TITLE;
      dimLogViewTitle = glaMsg.GLA_LOG_VIEW_DIMENSION_CODE;
      break;
    case 'vatcodes':
      title = vatMsg.VAT_CODE_TITLE;
      dimLogViewTitle = vatMsg.VAT_CODE_LOG_VIEW_DIMENSION_CODE;
      break;
    case 'projects':
      title = prjtMsg.PROJECT_TITLE;
      dimLogViewTitle = prjtMsg.PROJECT_LOG_VIEW_DIMENSION_CODE;
      break;
    case 'costcenters':
      title = cctMsg.COST_CENTER_TITLE;
      dimLogViewTitle = cctMsg.COST_CENTER_LOG_VIEW_DIMENSION_CODE;
      break;
    default:
      dimLogViewTitle = defaultMsg.DEFAULT_LOG_VIEW_DIMENSION_CODE;
      break;
  }

  const gotoLogsList = () => {
    if (code !== undefined) {
      history.push(`/dimensions/logs/${dim}${type ? `/${type}` : ''}/code/${code}`);
      return;
    }
    history.push(`/dimensions/logs/${dim}${type ? `/${type}` : ''}/company/${company}`);
  };

  const getDimensionLogByID = async (id) => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLog(true);

    try {
      const url = `${API.getDimensionLogsByCompany}/${dim}/${id}?code=${code !== undefined && code}&company=${company !== undefined && company}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data) {
            setLog(response.data.data);
          } else {
            gotoLogsList();
          }
        } else {
          gotoLogsList();
        }
      } else {
        gotoLogsList();
      }
    } catch (error) {
      gotoLogsList();
    }

    setFetchingLog(false);
    setLoading(false);
  };

  useEffect(() => {
    const id = match && match.params ? match.params.id : 0;

    if (id && id > 0) {
      getDimensionLogByID(id);
    } else {
      gotoLogsList();
    }
  }, []);

  if (loading || fetchingLog) {
    return <LoadingIndicator />;
  }

  try {
    req = JSON.stringify(JSON.parse(log.request), null, 2);
  } catch (err) {
    req = log.request;
  }

  try {
    resp = JSON.stringify(JSON.parse(log.response), null, 2);
  } catch (err) {
    resp = log.response;
  }

  return (
    <Page
      style={styles.root}
      title={ready && `${t(title)} | ${BRAND_NAME}`}
    >
      {
        log && ready && (
          <>
            <Grid style={styles.titleContainer}>
              <Grid>
                <Button style={styles.backBtn} onClick={gotoLogsList}>
                  <ArrowBackIcon style={styles.icon} />
                  {ready && t('LOGS_VIEW_BACK_TO_OVERVIEW')}
                </Button>
              </Grid>
              {/* <Typography style={styles.assistantID}>{`${t('LOGS_VIEW_PROCYS_NUMBER')}: ${log.assistantID}`}</Typography> */}
              <Grid style={styles.titleBox}>
                <Typography style={styles.title}>{`${ready && t(dimLogViewTitle)}: ${log.code}`}</Typography>
              </Grid>
              {/* <Grid container direction="row" alignItems="center">
                <Grid style={styles.arrowBtn}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    component="label"
                    disabled={log.nextId === undefined || log.nextId === null}
                    style={styles.action}
                    onClick={() => getInvoiceLogByID(log.nextId, paramsID)}
                  >
                    <ArrowBackIosNewIcon fontSize="small" />
                  </Button>
                </Grid>
                <Grid style={styles.arrowBtn}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    component="label"
                    disabled={log.prevId === undefined || log.prevId === null}
                    style={styles.action}
                    onClick={() => getInvoiceLogByID(log.prevId, paramsID)}
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>
            <Grid style={styles.topContainer}>
              <Grid style={styles.logViewContainer}>
                <Grid style={styles.labelsContainer}>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>#</Typography>
                    <Typography style={styles.value}>{log.id || '-'}</Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIMENSION_CODE')}</Typography>
                    <Typography style={styles.value}>{log.code || '-'}</Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIMENSION_NAME')}</Typography>
                    <Typography style={styles.value}>{log.name || '-'}</Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIMENSION_COMPANY')}</Typography>
                    <Typography style={styles.value}>{log.company || '-'}</Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIMENSION_EMAIL')}</Typography>
                    <Typography style={styles.value}>{log.email || '-'}</Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIMENSION_ACTION')}</Typography>
                    <Typography style={styles.value}>{log.action || '-'}</Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIMENSION_MODIFIED_BY')}</Typography>
                    <Typography style={styles.value}>
                      {
                        log.firstname && log.lastname
                          ? `${log.firstname} ${log.lastname}`
                          : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIMENSION_MODIFIED_AT')}</Typography>
                    <Typography style={styles.value}>
                      {
                        log.timestamp
                          ? moment.unix(log.timestamp / 1000000000).format('DD/MM/YYYY hh:mm:ss')
                          : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIEMNSION_REQUEST')}</Typography>
                    <Typography style={styles.value}>{<pre style={styles.xmlView}>{req}</pre> || '-'}</Typography>
                  </Grid>
                  <Grid style={styles.itemContainer}>
                    <Typography style={styles.label}>{ready && t('LOG_VIEW_DIEMNSION_RESPONSE')}</Typography>
                    <Typography style={styles.value}>{<pre style={styles.xmlView}>{resp}</pre> || '-'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
    </Page>
  );
};

export default DimensionLogView;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
  },
  topContainer: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40
  },
  logViewContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '6px 0px 6px 8px',
    border: 'solid rgba(0,0,0,0.075) 0px',
    borderBottomWidth: 1
  },
  label: {
    fontSize: 14,
    color: '#334D6E',
    minWidth: 140
  },
  value: {
    fontSize: 14,
    color: '#334D6E'
  },
  titleContainer: {
    backgroundColor: '#ffffff',
    padding: '24px 40px',
    boxShadow: '0px 1px 0px rgba(51, 77, 110, 0.1)',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08))'
  },
  backBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    color: '#3E8AFF',
    cursor: 'pointer',
    paddingLeft: 0,
    marginBottom: 16,
    textTransform: 'none'
  },
  icon: {
    marginRight: 12
  },
  assistantID: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: 'rgba(51, 77, 110, 0.5)',
  },
  subtitle: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: 'rgba(51, 77, 110, 0.5)',
    paddingBottom: 6
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '36px',
    color: '#334D6E',
  },
  userChip: {
    height: 19,
    marginLeft: 6
  },
  chipAvatar: {
    height: '19px!important',
    width: '19px!important',
    marginLeft: '0px!important'
  },
  chipLabel: {
    fontSize: 11,
    fontWeight: 500,
    color: '#334D6E',
    lineHeight: '16px',
    paddingBottom: 1
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  xmlView: {
    display: 'block',
    width: '100%',
    height: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    margin: '0 auto',
    lineHeight: '1.5',
    backgroundColor: 'white',
  },
  arrowBtn: {
    marginRight: 5
  }
};
