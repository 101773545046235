import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container
} from '@mui/material';

import DashboardLayout from 'src/shared/layouts/DashboardLayout';
import Page from 'src/shared/screens/Page';
import Results from 'src/logs/components/DocumentLogsListResults/DocumentLogsListResults';
import { useConfig } from 'src/hooks/useConfig';

const DocumentLogsList = (props) => {
  const { match } = props;
  const { ready, t } = useTranslation();
  const { BRAND_NAME } = useConfig();

  const [invoiceID, setInvoiceID] = useState(null);

  useEffect(() => {
    const id = match && match.params ? match.params.id : 0;
    if (id) {
      setInvoiceID(id);
    }
  }, []);

  return (
    <DashboardLayout>
      <Page
        style={styles.root}
        title={ready && `${t('DOCUMENT_LOGS_TITLE')} | ${BRAND_NAME}`}
      >
        <Container maxWidth={false} style={styles.topContainer}>
          <Results
            {...props}
            invoiceID={invoiceID}
          />
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default DocumentLogsList;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
  },
  topContainer: {
    paddingLeft: 40,
    paddingRight: 40
  }
}
