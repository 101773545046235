import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Backdrop, Button, Card, CardContent, Grid, IconButton, Modal, Paper, TextField, Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';

import Page from 'src/shared/screens/Page';
import IntegrationBox from 'src/integrations/components/IntegrationBox/IntegrationBox';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import SelectDocument from 'src/documents/components/SelectDocument/SelectDocument';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import {
  appendContactSupport, axiosDeleteHeaders, axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions
} from 'src/utils/helpers';
import {
  exportFormatTypes, integrations, nonIdApps
} from 'src/config';
import SwitchToTFInterfaceModal from 'src/integrations/components/SwitchToTFInterfaceModal/SwitchToTFInterfaceModal';
import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import theme from "src/theme";

const Integrations = () => {  
  const { BRAND_NAME, API } = useContext(ConfigContext);
  const history = useHistory();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user, setUser } = useAuth();

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [appName, setAppName] = useState('');
  const [details, setDetails] = useState('');
  const [appNameErr, setAppNameErr] = useState('');

  const [appIntegrations, setAppIntegrations] = useState(integrations);
  const [deliverFormats, setDeliverFormats] = useState([]);
  const [exportEmailPrefFormat, setExportEmailPrefFormat] = useState(user?.deliverFormat);
  const [savedExportEmailFormat, setSavedExportEmailFormat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appsError, setAppsError] = useState('');

  const [processing, setProcessing] = useState(false);
  const [processingAppName, setProcessingAppName] = useState(null);

  const [switchToTFModalOpen, setSwitchToTFModalOpen] = useState(false);

  const freeUser = user?.subscription && user.subscription.toLowerCase().indexOf('free') >= 0;
  const isPassportOrID = user.documentType === 'id';

  const getAllIntegrations = async (load) => {
    setLoading(load);
    try {
      const response = await Axios.get(
        `${API.integrations}?company=${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        const appsAr = [];
        for (let i = 0; i < integrations.length; i++) {
          const app = integrations[i];
          app.integrated = false;
          app.active = false;
          for (let j = 0; j < response.data.data.length; j++) {
            const dbApp = response.data.data[j];
            if (app.name === dbApp.app) {
              app.integrated = true;
              app.active = dbApp.active;
            }
          }
          if (!(isPassportOrID && nonIdApps.includes(app.name))) {
            appsAr.push(app);
          }
        }

        if (user.brand === 'schubec') {
          appsAr.unshift(appsAr.splice(1, 1)[0]);
        }

        setAppIntegrations(appsAr);
        setLoading(false);
      } else {
        setAppsError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS_ALL'), t));
      }
    } catch (error) {
      setLoading(false);
      setAppsError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS_ALL'), t));
    }
  };

  const getDeliverFormats = async () => {
    try {
      const response = await Axios.get(
        API.getDeliverFormats,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDeliverFormats(response.data.data);
        }
      }
    } catch (error) {
      setDeliverFormats([]);
    }
  };

  useEffect(() => {
    if (
      isActionPermitted(permissions.appsView, user.permissions)
      // && user && user.subscription && user.subscription.toLowerCase() !== 'free'
    ) {
      getAllIntegrations(true);
      getDeliverFormats();
    }
  }, []);

  useEffect(() => {
    const activeFormat = deliverFormats.find((o) => o.active);
    if (activeFormat) {
      setExportEmailPrefFormat(activeFormat.key);
    } else {
      setExportEmailPrefFormat(exportFormatTypes[0].key);
    }
  }, [deliverFormats]);

  const savePrefFormat = async (value) => {
    setExportEmailPrefFormat(value);

    if (value === '') {
      return;
    }

    try {
      const response = await Axios.post(
        `${API.saveDeliverFormat}`,
        {
          format: value,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSavedExportEmailFormat(true);
      } else {
        setSavedExportEmailFormat(false);
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('INTEGRATIONS_EXPORT_EMAIL_SAVE_ERROR'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setSavedExportEmailFormat(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('INTEGRATIONS_EXPORT_EMAIL_SAVE_ERROR'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const handleChangeFields = (value, prop, setter, errSetter) => {
    if (!value) {
      errSetter(t('INTEGRATIONS_PROP_CANNOT_BE_EMPTY', prop));
    } else {
      errSetter('');
    }

    setter(value);
  };

  const sendNotifiMeRequest = async (app) => {
    setProcessing(true);
    setProcessingAppName(app);
    try {
      const response = await Axios.post(
        API.sendNotifiRequest, { app, companyId: parseInt(user.companyID, 10) }, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_SEND_REQUEST_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setRequestModalOpen(false);
        setProcessing(false);
        setProcessingAppName(null);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('INTEGRATIONS_SEND_REQUEST_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = getLocalisedErrorString(error.response.data.i18n, t);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );

      setProcessing(false);
      setProcessingAppName(null);
    }
  };

  const sendAppRequest = async () => {
    if (!appName) {
      setAppNameErr(t('INTEGRATIONS_APP_CANNOT_BE_EMPTY'));
      return;
    }

    try {
      const body = {
        app: appName,
        details,
        companyId: parseInt(user.companyID, 10)
      };

      const response = await Axios.post(
        API.sendAppRequest, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_SEND_REQUEST_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setRequestModalOpen(false);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('INTEGRATIONS_SEND_REQUEST_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const disableAppRequest = async (name) => {
    setProcessing(true);
    setProcessingAppName(name);

    try {
      const response = await Axios.delete(
        API.integrations,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), { app: name })
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_APP_DELETE_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );

        for (let i = 0; i < appIntegrations.length; i++) {
          const integrationItem = appIntegrations[i];

          if (integrationItem.name === name) {
            integrationItem.active = false;
            integrationItem.integrated = false;
          }
        }

        setAppIntegrations(appIntegrations);

        const currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.delete('feedback-app');
        history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);

        setProcessing(false);
        setProcessingAppName(null);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('INTEGRATIONS_APP_DELETE_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );

      setProcessing(false);
      setProcessingAppName(null);
    }
  };

  const connectRequest = async (app, status, cIntegration, updateCheckBoxStatus, load) => {
    setProcessing(true);
    setProcessingAppName(app);
    try {
      const body = {
        app,
        active: status
      };

      const response = await Axios.post(
        API.integrations, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );      
      if (response.data.success) {
        enqueueSnackbar(
          status
            ? t('INTEGRATIONS_APP_CONNECT_SUCCESS', { app: t(cIntegration.title) })
            : t('INTEGRATIONS_APP_DISCONNECT_SUCCESS', { app: t(cIntegration.title) }),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );

        if (cIntegration.name === 'exportemail') {
          if (status && !cIntegration.integrated) {
            savePrefFormat(exportEmailPrefFormat);
          } else {
            setSavedExportEmailFormat(false);
          }
        }

        if (status) {
          const currentUrlParams = new URLSearchParams(window.location.search);
          currentUrlParams.set('feedback-app', 'success');
          history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);

          if (app === 'tf' && user.interfacePreference !== 'twinfield_interface') {
            setSwitchToTFModalOpen(true);
          }

          if (app === 'email') {
            const updatedUser = {
              ...user,
              ocrEmail: response.data.data.ocrEmail
            };
            setUser(updatedUser);
          }
        }

        updateCheckBoxStatus();

        if (load) {
          getAllIntegrations(true);
        } else {
          getAllIntegrations(false);
        }

        setProcessing(false);
        setProcessingAppName(null);
      }
    } catch (error) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, status ? t('INTEGRATIONS_APP_CONNECT_FAILURE') : t('INTEGRATIONS_APP_DISCONNECT_FAILURE'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
      setProcessing(false);
      setProcessingAppName(null);
    }
  };

  const updateInterfacePreference = async () => {
    const updatedUser = {
      ...user,
      interfacePreference: 'twinfield_interface'
    };
    try {
      const response = await Axios.put(
        `${API.userProfile}?&application=procys`,
        updatedUser,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        setUser(updatedUser);
        setLoading(false);
        setSwitchToTFModalOpen(false);
      } else {
        enqueueSnackbar(appendContactSupport(window.config.support_email, t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_FAILED'), t), {
          variant: 'error',
          persist: true
        });
        setLoading(false);
        setSwitchToTFModalOpen(false);
      }
    } catch (e) {
      enqueueSnackbar(appendContactSupport(window.config.support_email, t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_FAILED'), t), {
        variant: 'error',
        persist: true
      });
      setLoading(false);
      setSwitchToTFModalOpen(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  if (appsError) {
    return <EmptyText str={appsError} />;
  }

  return (
    <Page
      style={styles.root}
      title={ready && `${t('INTEGRATIONS_APP_PAGE_TITLE')} | ${BRAND_NAME}`}
    >
      {
        !user.documentType && <SelectDocument open />
      }
      <Card style={styles.container}>
        <CardContent style={styles.wrapper}>
          <Typography style={styles.headerStyle}>
            {ready && t('INTEGRATIONS_APP_PAGE_HEADING')}
          </Typography>
          {
            freeUser && (
              <Grid style={styles.warnContainer}>
                <Typography style={styles.warnTitle}>{ready && t('INTEGRATIONS_SUBSCRIBE_TO_WORK')}</Typography>
                <Typography style={styles.warnText}>
                  {ready && t('INTEGRATIONS_SUITABLE_PLAN')}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push('/account/subscription')}
                  style={styles.warnBtn}
                >
                  {ready && t('INTEGRATIONS_CHOOSE_PLAN')}
                </Button>
              </Grid>
            )
          }
          {
            !isActionPermitted(permissions.appsView, user.permissions) && (
              <Grid style={styles.warnContainer}>
                <Typography style={styles.warnTitle}>{ready && t('INTEGRATIONS_NOT_AUTH')}</Typography>
                <Typography style={styles.warnText}>
                  {ready && t('INTEGRATIONS_NOT_AUTH_DESCRIPTION')}
                </Typography>
              </Grid>
            )
          }
          <Typography style={styles.description}>
            {ready && t('INTEGRATIONS_PAGE_DESCRIPTION', { brand: BRAND_NAME })}
          </Typography>
          {
            appIntegrations.map((i) => (
              <IntegrationBox
                key={i.id}
                integration={i}
                sendNotifyMeRequest={() => sendNotifiMeRequest(i.name)}
                connectRequest={connectRequest}
                deleteApp={() => disableAppRequest(i.name)}
                processing={processing}
                processingAppName={processingAppName}
                deliverFormats={deliverFormats}
                exportEmailPrefFormat={exportEmailPrefFormat}
                savePrefFormat={savePrefFormat}
                savedExportEmailFormat={savedExportEmailFormat}
              />
            ))
          }
          <Grid style={styles.requestContainer}>
            <Typography align="center" style={styles.requestTitle}>
              {ready && t('APPS_PAGE_SEND_REQUEST_TITLE')}
            </Typography>
            <Typography style={styles.requestText}>
              {ready && t('APPS_PAGE_SEND_REQUEST_CAPTION')}
            </Typography>
            <Grid>
              <Button
                variant="outlined"
                style={styles.requestBtn}
                onClick={() => setRequestModalOpen(true)}
              >
                {ready && t('APPS_PAGE_SEND_A_REQUEST')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Modal
        open={requestModalOpen}
        style={styles.modal}
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Paper style={styles.halfPaper}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton
              style={styles.closeBtn}
              onClick={() => setRequestModalOpen(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          </Grid>
          <Grid
            style={styles.modalBody}
          >
            <Typography
              variant="h2"
              style={styles.header}
            >
              {ready && t('INTEGRATIONS_REQUEST_TITLE')}
            </Typography>
            <TextField
              style={styles.fieldStyle}
              fullWidth
              label={ready && t('INTEGRATIONS_REQUEST_APP')}
              name="appName"
              onChange={(e) => handleChangeFields(e.target.value, t('INTEGRATIONS_REQUEST_APP'), setAppName, setAppNameErr)}
              type="text"
              value={appName}
              variant="outlined"
              required
              error={Boolean(appNameErr)}
              helperText={appNameErr}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="filled"
              label={ready && t('INTEGRATIONS_REQUEST_DETAILS')}
              placeholder={ready && t('INTEGRATIONS_REQUEST_DETAILS_PLACEHOLDER')}
              InputProps={{
                style: {
                  paddingTop: 30,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                },
                classes: {
                  input: styles.noPadding
                }
              }}
              onChange={(e) => setDetails(e.target.value)}
            />
            <Grid style={styles.buttonContainer}>
              <Button
                style={styles.sendBtn}
                variant="contained"
                color="secondary"
                onClick={sendAppRequest}
              >
                {ready && t('INTEGRATIONS_REQUEST_SEND')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <SwitchToTFInterfaceModal
        modalOpen={switchToTFModalOpen}
        setModalOpen={setSwitchToTFModalOpen}
        updateInterfacePreference={updateInterfacePreference}
      />
    </Page>
  );
};

export default Integrations;

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    // height: '100%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    maxWidth: 1000,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#FFFFFF'
  },
  headerStyle: {
    fontSize: 28,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px',
    paddingBottom: 20
  },
  halfPaper: {
    width: '90%',
    maxWidth: 420,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  warnContainer: {
    padding: 16,
    backgroundColor: '#FEF4E9',
    marginBottom: 16
  },
  warnTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#614621',
    paddingBottom: 8
  },
  warnText: {
    fontSize: 14,
    color: '#4E4B48'
  },
  warnBtn: {
    textTransform: 'none',
    marginTop: 8,
    color: 'white',
    backgroundColor: '#3E8AFF',
    '&:hover': {
      backgroundColor: '#3E8AFF'
    }
  },
  description: {
    fontSize: 14,
    color: '#334D6E'
  },
  requestContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24
  },
  requestTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#334D6E'
  },
  requestText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
    marginBottom: 24
  },
  requestBtn: {
    color: '#334D6E',
    border: theme.palette.border.manage,
    textTransform: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
  },
  modalBody: {
    maxWidth: 600,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },
  header: {
    marginBottom: 24,
    color: '#334D6E',
    textAlign: 'center'
  },
  noPadding: {
    padding: '0px 16px',
    color: '#334D6E',
    fontSize: 13
  },
  closeBtn: {
    padding: '2!important',
  },
  fieldStyle: {
    paddingBottom: 24
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16
  },
  cancelBtn: {
    color: '#334D6E',
    border: '1px #e4e4e4 solid',
    textTransform: 'none',
    marginLeft: 24
  },
  sendBtn: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#3E8AFF',
    '&:hover': {
      backgroundColor: '#3E8AFF'
    }
  }
};
