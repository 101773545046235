export const FETCH_VATCODES_START = 'FETCH_VATCODES_START';
export const FETCH_VATCODES_SUCCESS = 'FETCH_VATCODES_SUCCESS';
export const FETCH_VATCODES_FAIL = 'FETCH_VATCODES_FAIL';

export const FETCH_VATCODE_LIST_START = 'FETCH_VATCODE_LIST_START';
export const FETCH_VATCODE_LIST_SUCCESS = 'FETCH_VATCODE_LIST_SUCCESS';
export const FETCH_VATCODE_LIST_FAIL = 'FETCH_VATCODE_LIST_FAIL';

export const FETCH_GLAS_START = 'FETCH_GLAS_START';
export const FETCH_GLAS_SUCCESS = 'FETCH_GLAS_SUCCESS';
export const FETCH_GLAS_FAIL = 'FETCH_GLAS_FAIL';

export const FETCH_GLA_LIST_START = 'FETCH_GLA_LIST_START';
export const FETCH_GLA_LIST_SUCCESS = 'FETCH_GLA_LIST_SUCCESS';
export const FETCH_GLA_LIST_FAIL = 'FETCH_GLA_LIST_FAIL';

export const FETCH_COSTCENTERS_START = 'FETCH_COSTCENTERS_START';
export const FETCH_COSTCENTERS_SUCCESS = 'FETCH_COSTCENTERS_SUCCESS';
export const FETCH_COSTCENTERS_FAIL = 'FETCH_COSTCENTERS_FAIL';

export const FETCH_HEADER_FIELDS_START = 'FETCH_HEADER_FIELDS_START';
export const FETCH_HEADER_FIELDS_SUCCESS = 'FETCH_HEADER_FIELDS_SUCCESS';
export const FETCH_HEADER_FIELDS_FAIL = 'FETCH_HEADER_FIELDS_FAIL';

export const FETCH_LINE_FIELDS_START = 'FETCH_LINE_FIELDS_START';
export const FETCH_LINE_FIELDS_SUCCESS = 'FETCH_LINE_FIELDS_SUCCESS';
export const FETCH_LINE_FIELDS_FAIL = 'FETCH_LINE_FIELDS_FAIL';

export const FETCH_DOC_OVERVIEW_FIELDS_START = 'FETCH_DOC_OVERVIEW_FIELDS_START';
export const FETCH_DOC_OVERVIEW_FIELDS_SUCCESS = 'FETCH_DOC_OVERVIEW_FIELDS_SUCCESS';
export const FETCH_DOC_OVERVIEW_FIELDS_FAIL = 'FETCH_DOC_OVERVIEW_FIELDS_FAIL';
