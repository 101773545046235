const styles = {
  root:{
    display:'flex',
    padding:'0',
    margin:'0',
    height:'100%',
    width:'100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px'
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff',
  },
}

export default styles;