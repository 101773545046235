import { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, FormHelperText, Grid, TextField, Tooltip
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import {
  axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions
} from 'src/utils/helpers';
import authService from 'src/utils/authService';
import { costCenterFields, ERRORS } from 'src/config';

import ConfigContext from 'src/contexts/ConfigContext';
import theme from 'src/theme';
import styles from './style';

const CostCenterTwinfield = (props) => {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    costCenter,
    getAllCostCenters,
    handleClose,
    hasError,
    onSuccess,
  } = props;

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
 
  const auth = useAuth();
  const {user} = auth;
  
  return (
    <Formik
      initialValues={{
        code: costCenter?.code || '',
        name: costCenter?.name || '',
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string()
          .max(255)
          .required('COST_CENTER_CODE_REQUIRED')
          .matches('^\\S*$', 'COST_CENTER_CODE_SPACES_NOT_ALLOWED'),
        name: Yup.string()
          .max(80, 'COST_CENTER_NAME_MAX_VALIDATION')
          .required('COST_CENTER_NAME_REQUIRED')
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          if (!authService.validateToken()) {
            enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
              variant: 'error',
            });
            setTimeout(() => {
              authService.logout(LOGIN_PANEL_URL);
            }, 2000);
            return;
          }

          const body = {
            code: values.code,
            name: values.name,
          };

          const url = `${API.costCentersByCompany}/${encodeURIComponent(user.companyID)}`;
          let response = null;
          let snackText = '';

          if (costCenter && costCenter.name) {
            response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
            snackText = 'COST_CENTERS_UPDATE_SUCCESS';
          } else {
            response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
            snackText = 'COST_CENTERS_ADD_SUCCESS';
          }
          if (response?.data?.success) {
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(ready && t(snackText), {
              variant: 'success',
              style: { maxWidth: 400 }
            });
            getAllCostCenters();
            handleClose();
            if (onSuccess) {
              onSuccess(response.data.id);
            }
          }
        } catch (error) {
          setStatus({ success: false });
          setSubmitting(false);
          if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
            const errParts = error.response.data.i18n.split('__TFMSG__');
            setErrors({
              [errParts[0]]: errParts[1]
            });
          } else {
            setErrors({
              submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
            });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setErrors,
        touched,
        values
      }) => (
        <form style={styles.root}>
          <Card>
            <CardContent style={{ padding: '32px 32px 20px 32px' }}>
              <Grid container spacing={3}>
                {
                    costCenterFields.map((m) => (
                      <Accordion
                        key={m.title}
                        style={styles.accordionGroup}
                        defaultExpanded
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={m.title}
                          id={m.title}
                        >
                          <Grid style={styles.accordionHeading}>
                            {ready && t(m.title)}
                            {
                            hasError(m.fields, errors) && (
                            <Chip
                              color="error"
                              variant="default"
                              size="small"
                              label="Error"
                              style={styles.errorChip}
                            />
                            )
                            }
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={3}>
                            {
                              m.fields.map((f) => {
                                switch (f.key) {
                                  default:
                                    return (
                                      <Tooltip title={(!costCenter || f.key !== 'code') ? '' : t('DIMENSION_CODE_CANNOT_EDIT')}>
                                        <Grid item lg={6} md={6} xs={12} key={f.key}>
                                          <TextField
                                            error={Boolean(touched[f.key] && errors[f.key])}
                                            fullWidth
                                            helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                            label={ready && t(f.label)}
                                            name={f.key}
                                            onClick={() => setErrors({})}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            required={f.required}
                                            value={values[f.key]}
                                            variant="outlined"
                                            style={styles.inputField}
                                            disabled={costCenter && f.key === 'code'}
                                          />
                                        </Grid>
                                      </Tooltip>
                                    );
                                }
                              })
                            }
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))
                }
                <Grid
                  item
                  xs={12}
                >
                  {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                  )}
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      style={{ backgroundColor: theme.palette.button.action }}
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user.permissions)}
                      onClick={handleSubmit}
                    >
                      {ready && t('COST_CENTERS_SAVE_CHANGES')}
                    </Button>
                    <Button
                      variant="text"
                      onClick={handleClose}
                      style={styles.yearField}
                    >
                      {ready && t('COST_CENTERS_CANCEL')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

// CostCenterTwinfield.propTypes = {
//   handleClose: PropTypes.func,
//   getAllCostCenters: PropTypes.func,
//   hasError: PropTypes.func,
//   onSuccess: PropTypes.func,
//   className: PropTypes.string,
//   costCenter: PropTypes.shape({
//     code: PropTypes.string,
//     name: PropTypes.string,
//   })
// };

export default CostCenterTwinfield;
