import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from 'src/hooks/useAuth';
import CreateSupplierForm from 'src/dimensions/suppliers/components/CreateSupplierForm/CreateSupplierForm';
import styles from './style';

const CreateSupplierModal = (props) => {
  const { t, ready } = useTranslation();

  const {
    addSupplierOpen,
    handleClose,
    newSupplier,
    onSupplierCreated,
    isInvoiceEditForm,
    renderInvoiceXML,
    renderInvoicePDF,
    scrollXMLViewBy,
  } = props;
  
  const auth = useAuth();
  const {user} = auth;

  const [xmlSelectedValue, setXmlSelectedValue] = useState('');
  const [textFieldSelectedValue, _setTextFieldSelectedValue] = useState('');
  const textFieldSelectedValueRef = useRef(textFieldSelectedValue);
  const setTextFieldSelectedValue = (val) => {
    textFieldSelectedValueRef.current = val;
    _setTextFieldSelectedValue(val);
  };
  const [textFieldSelectedKey, setTextFieldSelectedKey] = useState('');

  return (
    <Modal
      style={styles.modal}
      open={addSupplierOpen}
      onClose={() => handleClose()}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={addSupplierOpen}>
        <div style={(user.interfacePreference !== 'twinfield_interface' && !isInvoiceEditForm) ? styles.halfPaper : styles.paper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {ready && t('INVOICE_EDIT_FORM_ADD_NEW_SUPPLIER')}
            </Typography>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          {isInvoiceEditForm ? (
            <Grid container>
              <Grid item xs={12} md={4} style={styles.leftContainer}>
                <CreateSupplierForm
                  supplier={newSupplier}
                  onSuccess={onSupplierCreated}
                  onClose={handleClose}
                  isInvoiceEditForm={isInvoiceEditForm}
                  xmlSelectedValue={xmlSelectedValue}
                  textFieldSelectedKey={textFieldSelectedKey}
                  textFieldSelectedValueRef={textFieldSelectedValueRef}
                  setTextFieldSelectedKey={setTextFieldSelectedKey}
                  setTextFieldSelectedValue={setTextFieldSelectedValue}
                />
              </Grid>
              <Grid item xs={12} md={8} style={styles.invoiceContainer}>
                {renderInvoiceXML
                  ? renderInvoiceXML(xmlSelectedValue, setXmlSelectedValue, textFieldSelectedValue, scrollXMLViewBy, false, textFieldSelectedKey)
                  : renderInvoicePDF()}
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <CreateSupplierForm
                supplier={newSupplier}
                onSuccess={onSupplierCreated}
                onClose={handleClose}
                isInvoiceEditForm={isInvoiceEditForm}
                xmlSelectedValue={xmlSelectedValue}
                textFieldSelectedKey={textFieldSelectedKey}
                textFieldSelectedValueRef={textFieldSelectedValueRef}
                setTextFieldSelectedKey={setTextFieldSelectedKey}
                setTextFieldSelectedValue={setTextFieldSelectedValue}
              />
            </Grid>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

// CreateSupplierModal.propTypes = {
//   addSupplierOpen: PropTypes.bool,
//   handleClose: PropTypes.func,
//   newSupplier: PropTypes.object,
//   onSupplierCreated: PropTypes.func,
//   renderInvoiceXML: PropTypes.func,
//   isInvoiceEditForm: PropTypes.bool,
//   scrollXMLViewBy: PropTypes.func,
//   renderInvoicePDF: PropTypes.func
// };

export default CreateSupplierModal;
