/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function ExportNotes(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.615 13.615V15a.37.37 0 00.116.27.37.37 0 00.538 0 .37.37 0 00.115-.27v-2.308a.369.369 0 00-.115-.269.37.37 0 00-.27-.115h-2.307a.37.37 0 00-.269.115.37.37 0 000 .538.369.369 0 00.27.116h1.384l-1.885 1.884a.369.369 0 000 .539.37.37 0 00.539 0l1.884-1.885zm-.769 4.077c-1.064 0-1.97-.375-2.72-1.124-.75-.75-1.126-1.658-1.126-2.722s.375-1.971 1.125-2.722c.75-.75 1.657-1.124 2.721-1.124s1.971.375 2.722 1.124c.75.75 1.124 1.658 1.124 2.722s-.375 1.971-1.124 2.722c-.75.75-1.658 1.124-2.722 1.124zM6.154 6.923h6.154c.218 0 .4-.074.547-.222a.742.742 0 00.222-.547c0-.218-.074-.401-.222-.549a.744.744 0 00-.547-.22H6.154c-.218 0-.401.073-.549.22a.746.746 0 00-.22.549c0 .218.073.4.22.547.148.148.33.222.549.222zm2.827 9.23H3.846c-.423 0-.785-.15-1.087-.45a1.483 1.483 0 01-.451-1.088V3.846c0-.423.15-.785.451-1.087a1.483 1.483 0 011.087-.451h10.77c.422 0 .785.15 1.086.451.301.302.452.664.452 1.087V9a5.516 5.516 0 00-1.125-.404 5.112 5.112 0 00-1.183-.135c-.14 0-.272.004-.394.01a2.653 2.653 0 00-.375.048 2.27 2.27 0 00-.385-.048 8.893 8.893 0 00-.384-.01H6.154c-.218 0-.401.074-.549.221a.746.746 0 00-.22.549c0 .218.073.4.22.547.148.148.33.222.549.222h3.942a5.091 5.091 0 00-1.115 1.538H6.154c-.218 0-.401.074-.549.221a.746.746 0 00-.22.549c0 .218.073.4.22.547.148.148.33.222.549.222h2.365a2.653 2.653 0 00-.048.375c-.006.122-.01.253-.01.394 0 .423.039.818.116 1.183.077.365.211.74.404 1.125z"
        fill="#27AE60"
      />
    </svg>
  )
}

export default ExportNotes
