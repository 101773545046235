import { Suspense } from 'react';
import {
  Switch, Redirect, Route
} from 'react-router-dom';

import { AuthProvider } from 'src/contexts/AuthContext';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import VerifyLogin from 'src/shared/screens/VerifyLogin';
import DashboardLayout from 'src/shared/layouts/DashboardLayout';

import Error404 from 'src/shared/screens/Error404';
import InvoiceImage404 from 'src/shared/screens/InvoiceImageNotFound';
import InviteUserCallback from 'src/shared/screens/InviteUserCallback';
import QuickbooksOAuth2Callback from 'src/integrations/containers/QuickbooksOAuth2Callback/QuickbooksOAuth2Callback';

import StatsDashboard from 'src/dashboard/StatsDashboard/StatsDashboard';
import DashboardView from 'src/dashboard-view/DashboardView';

import DocumentsView from 'src/documents-view/DocumentsView';
import CDCDocumentEdit from 'src/cdc-document-edit/CDCDocumentContainer';

import InvoiceImage from 'src/invoice-display/InvoiceDisplay';
import Attachment from 'src/documents/DocumentDisplay/DocumentDisplay';
import ExportFileDownload from 'src/documents/DownloadExportFile/DownloadExportFile';

import Suppliers from 'src/dimensions/suppliers/Suppliers';
import GLAccounts from 'src/dimensions/glas/GLAccounts';
import CostCenters from 'src/dimensions/costcenters/CostCenters';
import VatCodes from 'src/dimensions/vatcodes/VatCodes';

import Account from 'src/account/Account';
import Subscription from 'src/subscription/Subscription';
import SubscriptionCheckout from 'src/checkout/Checkout';

import DocumentLogsList from 'src/logs/DocumentLogsList';
import DocumentLogView from 'src/logs/DocumentLogView';
import DimensionLogView from 'src/logs/DimensionLogView';
import DimensionLogsListView from 'src/logs/DimensionLogsList';
import Clients from 'src/dimensions-new/clients/Clients';
import Glas from 'src/dimensions-new/glas/Glas';
import VatCodesNew from 'src/dimensions-new/vatcodes/VatCodes';
import ImportView from 'src/dimensions-new/importView/ImportView';
import CostCentersNew from 'src/dimensions-new/costcenters/CostCenters';

import Integrations from 'src/integrations/containers/Integrations/Integrations';
import DocumentView from 'src/document-view/DocumentView';

function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route exact path="/login" component={VerifyLogin} />

        <Route exact path="/404" component={Error404} />
        <Route exact path="/404/invoice/image" component={InvoiceImage404} />
        <Route exact path="/statistics/dashboard" component={StatsDashboard} />
        <Route exact path="/account/users/invite/:token" component={InviteUserCallback} />
        <Route exact path="/integrations/quickbooks/oauth2/callback" component={QuickbooksOAuth2Callback} />

        {/* Secure URL for accessing invoice files and attachments */}
        <Route path="/files/image/:id" component={InvoiceImage} />
        <Route exact path="/files/transaction/:transaction/" component={InvoiceImage} />
        <Route exact path="/files/company/:company/transaction/:transaction/" component={InvoiceImage} />
        <Route exact path="/files/attachment/:name" component={Attachment} />
        <Route exact path="/files/export/:filename" component={ExportFileDownload} />
        {/* Secure URL for accessing invoice files */}

        <AuthProvider
          render={(props: any) => (
            <Switch>
              <Route path={['/account', '/dimensions', '/integrations']}>
                <DashboardLayout {...props}>
                  <Suspense fallback={<LoadingScreen />}>
                    <Switch>
                      <Route path="/account/subscription/checkout" component={SubscriptionCheckout} />
                      <Route path="/account/subscription" component={Subscription} />
                      <Route path="/account" component={Account} />

                      <Route path="/dimensions/suppliers" component={Suppliers} />
                      <Route path="/dimensions/glaccounts" component={GLAccounts} />
                      <Route path="/dimensions/glaccounts/bas" component={GLAccounts} />
                      <Route path="/dimensions/glaccounts/pnl" component={GLAccounts} />
                      <Route path="/dimensions/costcenters" component={CostCenters} />
                      <Route path="/dimensions/vatcodes" component={VatCodes} />
                      <Route path="/dimensions/logs/:dim/:id" component={DimensionLogView} />
                      <Route path="/dimensions/logs/:dim/:type?/code/:code" component={DimensionLogsListView} />
                      <Route path="/dimensions/logs/:dim/:type?/company/:company" component={DimensionLogsListView} />

                      <Route path="/integrations" component={Integrations} />

                      <Redirect to="/404" />
                    </Switch>
                  </Suspense>
                </DashboardLayout>
              </Route>
              <Route path="/dashboard" component={DashboardView} />
              
              <Route exact path="/documents" component={DocumentsView} />

              <Route path="/doc/:id/edit" component={DocumentView} />
              <Route path="/invoices/:id/edit" component={DocumentView} />       
              <Route path="/documents/cdc/:id/edit" component={CDCDocumentEdit} />

              <Route path="/documents/:id/logs/" component={DocumentLogsList} />
              <Route path="/documents/logs/:id" component={DocumentLogView} />
              <Route path="/documents/logs" component={DocumentLogsList} />

              <Route path="/dimensions-new/clients" component={Clients}/>
              <Route path="/dimensions-new/glaccounts" component={Glas}/>
              <Route path="/dimensions-new/import/:type/:type2" component={ImportView}/>
              <Route path="/dimensions-new/vatcodes" component={VatCodesNew}/>
              <Route path="/dimensions-new/costcenters" component={CostCentersNew}/>
              <Route path="/dimensions-new" component={Clients}/>

              <Redirect to="/404" />
            </Switch>
          )}
        />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

export default Routes;
