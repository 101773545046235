import theme from 'src/theme';

const styles = {
  root: {},
  form: {
    marginBottom: 0
  },
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    marginLeft: 16,
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
    marginBottom: 24
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  selectStyleSelected: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#d7e7fc'
  },
  formControlRoot: {
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff'
  },
  inputFieldSelected: {
    backgroundColor: '#d7e7fc'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: '#3E8AFF',
    cursor: 'pointer'
  },
  plusIconSelect: {
    height: 24,
    width: 24,
    color: '#3E8AFF',
    cursor: 'pointer',
    marginRight: 5,
  },
  plusIconContainer: {
    marginRight: -40
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px',
    marginRight: '16px',
  },
  manageProductsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px',
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
  productBtnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#000',
  },
  cardContent: {
    padding: '16px 0px 0px 0px !important'
  },
  checkBox: {
    color: '#A0AEC1',
    paddingBottom: 0,
    paddingTop: 0
  },
  checkboxContainer: {
    marginRight: '8px'
  },
  checkBoxLabel: {
    fontSize: '13px',
    color: theme.palette.text.primary
  },
  skipReviewContainer: {
    padding: '0px !important'
  }
};

export default styles;
