import theme from 'src/theme';

const styles = {
  root: {},
  navigateNextIcon: {
    marginLeft: theme.spacing(1)
  },
  cardHeader: {
    backgroundColor: ''
  },
  filterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  circularProgressIcon: {
    width: 20,
    height: 20
  },
  arrowCell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  pointer: {
    cursor: 'pointer'
  },
  arrow: {
    paddingLeft: 4
  },
  addCardBtn: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 0,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    backgroundColor: theme.palette.button.action,
  },
  cardNumber: {
    marginTop: 16
  },
  cardNumberRow: {
    display: 'flex',
    height: 42,
    alignItems: 'center'
  },
  cardType: {
    width: 30,
    marginLeft: 8,
    marginRight: 12
  },
  editLink: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  deleteLink: {
    fontSize: 14,
    fontWeight: 600,
    color: '#A0AEC1',
    marginLeft: 24,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  activateLink: {
    fontSize: 14,
    fontWeight: 600,
    color: '#A0AEC1',
    marginLeft: 24,
    marginRight: 24,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  defaultCard: {
    fontSize: 13,
    color: 'gray',
    marginLeft: 24,
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  snackAction: {
    color: 'white'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    padding: '20px 20px 0px 20px'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  headerActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  row: {
    height: 64
  },
  actionContainer: {
    paddingRight: 10,
    marginTop: 3
  },
  caption: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: theme.palette.text.header,
    paddingLeft: 24
  },
  cellText: {
    fontSize: 15,
    color: theme.palette.text.primary
  },
  defaultLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    border: theme.palette.border.secondary,
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    marginLeft: 20,
    textTransform: 'capitalize',
    maxWidth: 108,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    backgroundColor: '#E1E4E8'
  },
  paginationActions: {
    marginLeft: 0
  },
  componentPadding: {
    padding: '20px 20px 0px 20px'
  }
};

export default styles;
