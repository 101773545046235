import { useState, useContext, useEffect } from 'react';
import Axios from 'axios';

import ConfigContext from 'src/contexts/ConfigContext';
import { DocType } from 'src/types';
import styles from './style';

interface PropTypes {
  document: DocType;
}

const DocumentView = (props: PropTypes) => {
  const { API } = useContext(ConfigContext);

  const { document } = props;

  const [fileResponse, setFileResponse] = useState<string | undefined>();

  const getImage = async (name: string) => {
    try {
      const response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
    } catch (err) {
      setFileResponse(undefined);
    }
  };

  useEffect(() => {
    const nameParts = document?.documentFile?.split('.');
    let imageName = '';
    if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
      // setImgName(`${nameParts[0]}-display.pdf`);
      imageName = `${nameParts[0]}-display.pdf`;
    } else {
      // setImgName(invoice.documentFile);
      imageName = document.documentFile || '';
    }
    getImage(imageName);
  }, [document]);

  return (
    <iframe src={fileResponse} title="pdf" style={styles.pdfFrame} />
  );
};

export default DocumentView;
