import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Tooltip } from '@mui/material';
import {
  Search as SearchIcon,
  Settings as SettingsIcon,
  ListAlt as LogsIcon,
  FilterAlt as FilterIcon,
  Add as UploadIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigureOverviewFields from 'src/documents-new/components/ConfigureOverviewFields/ConfigureOverviewFields';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import styles from './style';
import { useConfig } from 'src/hooks/useConfig';

interface PropTypes {
  query: string;
  configureFieldsOpen: boolean;
  configureFieldsRef: any;
  loading: boolean;
  isFiltersApplied: boolean;
  toggleConfigureFields: () => void;
  handleFieldsMenuClose: () => void;
  handleQueryChange: (_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleOpenFilters: () => void;
  handleUpload: (_e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const history = useHistory();
  const { user } = useAuth();
  const { IS_BRAND_PROCYS } = useConfig();

  const {
    query,
    configureFieldsOpen,
    configureFieldsRef,
    loading,
    isFiltersApplied,
    handleOpenFilters,
    toggleConfigureFields,
    handleFieldsMenuClose,
    handleQueryChange,
    handleUpload
  } = props;

  const isPassportOrID = user?.documentType === 'id';

  return (
    <Grid sx={styles.searchBarRow}>
      <Box className="search-bar" sx={styles.searchBar}>
        <Tooltip title={t(isPassportOrID ? 'DASHBOARD_SEARCH_ID_SEARCH_TOOLTIP' : 'DASHBOARD_SEARCH_INVOICE_SEARCH_TOOLTIP')}>
          <TextField
            onChange={handleQueryChange}
            placeholder={t(isPassportOrID ? 'DASHBOARD_SEARCH_ID_SEARCH' : 'DASHBOARD_SEARCH_INVOICE_SEARCH')}
            value={query}
            variant="outlined"
            sx={{
              '&.MuiTextField-root': styles.searchBarTextField,
              '& .MuiInputBase-root': styles.searchBarInputRoot,
              '& .MuiInputBase-input': styles.searchBarInput,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: '0px' }}>
                  <SearchIcon sx={styles.searchIcon} />
                </InputAdornment>
              ),
              sx: {
                '& .MuiInputBase-input': styles.searchInput,
              }
            }}
          />
        </Tooltip>
      </Box>
      <Box sx={styles.btnsContainer}>
        <Button
          onClick={handleOpenFilters}
          sx={isFiltersApplied ? styles.filterBtnActive : styles.filterBtn}
          startIcon={<FilterIcon sx={styles.filterIcon} />}
        >
          {t('DASHBOARD_INVOICES_FILTERED')}
        </Button>
        <Button
          ref={configureFieldsRef}
          startIcon={<SettingsIcon sx={styles.settingsIcon} />}
          sx={styles.settingsBtn}
          onClick={toggleConfigureFields}
        >
          {t('DASHBOARD_INVOICES_CONFIGURE_FIELDS')}
        </Button>
        <ConfigureOverviewFields
          configureFieldsOpen={configureFieldsOpen}
          configureFieldsRef={configureFieldsRef}
          handleClose={handleFieldsMenuClose}
        />
        <Button
          startIcon={<LogsIcon sx={styles.logsIcon} />}
          disabled={!IS_BRAND_PROCYS}
          onClick={() => history.push('/documents/logs')}
          sx={styles.logsBtn}
        >
          {t('DASHBOARD_INVOICES_LOGS')}
        </Button>
        <Tooltip
          title={!isActionPermitted(permissions.invoiceUpload, user?.permissions)
            ? ready && t('INVOICE_UPLOAD_NO_PERMISSION')
            : user?.isUploadBlocked ? ready && t('INVOICE_UPLOAD_BLOCKED_LIMIT_REACHED_TOOLTIP')
              : ''}
        >
          <span>
            <Button
              startIcon={!loading && <UploadIcon sx={styles.uploadIcon} />}
              sx={styles.uploadBtn}
              component="label"
              disabled={!isActionPermitted(permissions.invoiceUpload, user?.permissions) || user?.isUploadBlocked}
            >
              {loading ? (
                <CircularProgress sx={styles.spinner} size={20} />
              ) : (
                <>
                  <input
                    id="upload-input"
                    accept="image/png,image/jpeg,image/jpg,image/tiff,application/pdf,image/heic"
                    type="file"
                    multiple
                    onChange={handleUpload}
                    style={{ display: 'none' }}
                    required
                  />
                  {t('DASHBOARD_INVOICES_UPLOAD_INVOICE')}
                </>
              )}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Grid>
    
  )
}

export default SearchBar