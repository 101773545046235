import { useTranslation } from 'react-i18next';

import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

import styles from './style';

const DeleteLineModal = (props) => {
  const { t, ready } = useTranslation();

  const {
    deleteLineOpen,
    deleteLine,
    onDeleteLineConfirm,
    onDeleteLineClose,
  } = props;
  return (
    <Modal
      style={styles.modal}
      open={deleteLineOpen}
      onClose={onDeleteLineClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={deleteLineOpen}>
        <div style={styles.paper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {ready && t('INVOICE_EDIT_FORM_DELETE_INVOICE_LINE')}
            </Typography>
            <IconButton onClick={onDeleteLineClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid style={styles.deleteMessageText}>
            <Typography variant="caption">
              {t('INVOICE_LINE_DELETE_CONFIRMATION_MESSAGE')}
            </Typography>
          </Grid>
          <Grid style={styles.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                style={styles.closeBtn}
                onClick={onDeleteLineClose}
              >
                {ready && t('INVOICE_EDIT_FORM_DELETE_LINE_CANCEL')}
              </Button>
              <Button
                variant="contained"
                style={styles.submitBtn}
                onClick={() => onDeleteLineConfirm(deleteLine)}
              >
                {ready && t('INVOICE_EDIT_FORM_DELETE_LINE')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default DeleteLineModal;
