import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Button, CircularProgress, Grid, IconButton
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';

import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import {
  appendContactSupport, axiosHeaders, getLocalisedErrorString
} from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from '../style';

const BCIntegration = () => {
  const { API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  // const [saved, setSaved] = useState(false);

  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [appError, setAppError] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // const [iUsedEmails, setIUsedEmails] = useState([]);
  // const [usedEmails, setUsedEmails] = useState('');
  const serviceUrl = 'https://assistant.procys.com/v1/businesscentral';
  // const forwardInbox = 'ocr@procys.com';

  const [copyObj, setCopyObj] = useState({
    username: false,
    password: false,
    serviceUrl: false,
    forwardInbox: false
  });

  const onChangeCopy = (prop) => {
    const iCopyObj = {
      username: false,
      password: false,
      serviceUrl: false,
      forwardInbox: false
    };

    iCopyObj[prop] = true;

    setCopyObj(iCopyObj);
  };

  const copyToClipboard = (prop, value) => {
    copy(value, {
      onCopy: onChangeCopy(prop)
    });
  };

  const getSavedDetails = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.savedDetailsBc}/${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setUsername(response.data.data.username);
        setPassword(response.data.data.password);

        // if (response.data.data.usedEmail && response.data.data.usedEmail.length > 0) {
        //   setUsedEmails(response.data.data.usedEmail.join(', '));
        //   setIUsedEmails(response.data.data.usedEmail);
        // }
        setLoading(false);
      } else {
        setAppError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t));
      }
    } catch (error) {
      setLoading(false);
      setAppError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t));
    }
  };

  useEffect(() => {
    getSavedDetails();
  }, []);

  // const saveNewDetails = async () => {
  //   const uEmails = usedEmails.split(',');

  //   const fullList = [];
  //   const addedEmails = [];
  //   const removedEmails = [];
  //   for (let i = 0; i < uEmails.length; i++) {
  //     if (uEmails[i] && uEmails[i].trim()) {
  //       fullList.push(uEmails[i].trim());
  //     }

  //     let isNew = true;
  //     for (let j = 0; j < iUsedEmails.length; j++) {
  //       if (uEmails[i] && iUsedEmails[j] && uEmails[i].trim() === iUsedEmails[j].trim()) {
  //         isNew = false;
  //         break;
  //       }
  //     }

  //     if (isNew && uEmails[i] && uEmails[i].trim()) {
  //       const resp = validateEmail(uEmails[i].trim());
  //       if (!resp.isValid) {
  //         enqueueSnackbar(
  //           resp.errorMessage,
  //           {
  //             variant: 'error',
  //             autoHideDuration: 5000
  //           }
  //         );
  //         return;
  //       }
  //       addedEmails.push(uEmails[i].trim());
  //     }
  //   }

  //   for (let i = 0; i < iUsedEmails.length; i++) {
  //     let isDeleted = true;
  //     for (let j = 0; j < uEmails.length; j++) {
  //       if (iUsedEmails[i] && uEmails[j] && iUsedEmails[i].trim() === uEmails[j].trim()) {
  //         isDeleted = false;
  //         break;
  //       }
  //     }

  //     if (isDeleted) {
  //       removedEmails.push(iUsedEmails[i].trim());
  //     }
  //   }

  //   if (addedEmails.length <= 0 && removedEmails.length <= 0) {
  //     return;
  //   }

  //   try {
  //     const response = await Axios.post(
  //       `${API.saveEmailBc}`,
  //       {
  //         new: addedEmails,
  //         old: removedEmails
  //       },
  //       axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
  //     );
  //     if (response.data.success) {
  //       setSaved(true);
  //       setIUsedEmails(fullList);
  //     } else {
  //       enqueueSnackbar(
  //         appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_SAVE_DETAILS'), t),
  //         {
  //           variant: 'error',
  //           autoHideDuration: 5000
  //         }
  //       );
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     enqueueSnackbar(
  //       appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_SAVE_DETAILS'), t),
  //       {
  //         variant: 'error',
  //         autoHideDuration: 5000
  //       }
  //     );
  //   }
  // };

  const onCheckConnection = async () => {
    setCheckLoading(true);
    try {
      const response = await Axios.get(
        `${API.savedDetailsBc}/${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_BC_CONNECT_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setCheckLoading(false);
      } else {
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('SOMETHING_WENT_WRONG'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setCheckLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  if (appError) {
    return (
      <Grid style={styles.errorContainer}>
        <span style={styles.labelTextError}>
          {appError}
        </span>
      </Grid>
    );
  }

  return (
    <Grid style={styles.detailsContainer}>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_BC_USERNAME')}</span>
        </Grid>
        <input
          type="text"
          value={username}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('username', username)}>
          <CopyIcon />
        </IconButton>
        {copyObj.username && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_BC_PASSWORD')}</span>
        </Grid>
        <input
          fullWidth
          type="password"
          value={password}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('password', password)}>
          <CopyIcon />
        </IconButton>
        {copyObj.password && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_BC_SERVICE_URL')}</span>
        </Grid>
        <input
          fullWidth
          type="text"
          value={serviceUrl}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('serviceUrl', serviceUrl)}>
          <CopyIcon />
        </IconButton>
        {copyObj.serviceUrl && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      {/* <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_BC_FORWARDING_INBOX')}</span>
        </Grid>
        <input
          fullWidth
          type="text"
          value={forwardInbox}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={() => copyToClipboard('forwardInbox', forwardInbox)}>
          <CopyIcon />
        </IconButton>
        {copyObj.forwardInbox && <CheckIcon style={styles.checkIcon} />}
      </Grid>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_BC_ALLOWED_EMAILS')}</span>
          {saved && <Grid style={styles.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          value={usedEmails}
          style={styles.inputField}
          onChange={(e) => setUsedEmails(e.target.value)}
          onBlur={(e) => saveNewDetails(e.target.value)}
        />
      </Grid> */}
      <Grid style={styles.checkContainer}>
        <Button
          variant="outlined"
          style={styles.checkConnectionBtn}
          onClick={checkLoading ? () => {} : onCheckConnection}
        >
          {ready && t('INTEGRATIONS_CHECK_CONNECTION')}
        </Button>
        {checkLoading && <CircularProgress style={styles.loadingProgress} size={20} />}
      </Grid>
    </Grid>
  );
};

export default BCIntegration;
