import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  Backdrop, Button, Fade, Grid, Modal, Typography
} from '@mui/material';

import styles from './style';

const SubCompanyAccessModal = ({
  openModal, parentName
}) => {
  const history = useHistory();
  const { t, ready } = useTranslation();

  const navigateToDashboard = () => {
    history.push('/dashboard');
  };

  return (
    <Modal
      style={styles.modal}
      open={openModal}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <div style={styles.paperModal}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {ready && t('SUB_COMPANY_ACCESS_HEADER_BILLING')}
            </Typography>
          </Grid>
          <Grid style={styles.descriptionMessageText}>
            {t('SUB_COMPANY_ACCESS_DESCRIPTION_BILLING', { var: parentName })}
          </Grid>
          <Grid style={styles.actionContainer}>
            <Button
              variant="contained"
              style={styles.submitBtn}
              onClick={navigateToDashboard}
            >
              {ready && t('SUB_COMPANY_ACCESS_GO_TO_DASHBOARD')}
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

// SubCompanyAccessModal.propTypes = {
//   openModal: PropTypes.bool,
//   parentName: PropTypes.string
// };

export default SubCompanyAccessModal;
