import { alpha } from "@mui/material";
import theme from "src/theme";

const styles = {
  root: {},
  header: {
    fontSize: '11px!important',
    fontWeight: 500,
    color: theme.palette.text.header,
    padding: '16px 20px 9px',
    lineHeight: '16.5px'
  },
  container: {
    marginTop: '-12px'
  },
  formContainer: {
    padding: '16px 20px'
  },
  formInput: {
    paddingTop: '13px!important',
    paddingBottom: '12px!important',
  },
  formInputLast: {
    paddingTop: '13px!important',
    paddingBottom: '0px!important',
  },
  saveBtnContainer: {
    paddingBottom: 28,
    paddingTop: 9
  },
  saveBtn: {
    width: 177,
    height: 41,
    boxShadow: `0px 4px 10px ${alpha(theme.palette.secondary.main, 0.25)}`,
    textTransform: 'none',
    backgroundColor: theme.palette.button.action,
  },
  iconWrapper: {
    position: "absolute",
    right: 15.47,
    width: 18.33,
    height: 12.5,
  },
  visibleIcon: {
    color: theme.palette.icon.gray
  },
  strengthBarWrapper: {
    width: '100%',
    paddingTop: '6px!important'
  },
  inputProps: {
    textAlign: 'center',
    height: 42,
    color: theme.palette.text.primary
  }
};

export default styles;
