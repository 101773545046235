import theme from "src/theme";

const styles = {
  container: {
    zIndex: 1200,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: theme.palette.background.default,
    filter: 'drop-shadow(0px 2px 4px rgba(51, 77, 110, 0.1))',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 60
  },
  badge: {
    marginLeft: '50%',
    padding: '8px 11px',
    fontSize: '10px',
    borderRadius: '15px',
    fontWeight: '600',
    color: 'white',
  },
  anchorOriginBottomLeftRectangle: {
    top: '5px'
  },
  logoBadgeRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  parentList: {
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    width: 'min-content',
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 2
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    height: 60,
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '&.Mui-title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    border: theme.palette.border.white,
    borderBottomWidth: 3,
  },
  active: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '&.Mui-title': {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.main
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
    fontSize: 20
  },
  iconActive: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 20
  },
  title: {
    marginRight: 8,
    whiteSpace: 'nowrap',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  titleActive: {
    marginRight: 8,
    whiteSpace: 'nowrap',
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
  menuContainer: {
    border: '1px solid #E1E4E8',
  },
  menuList: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  menuActionContainerSelected: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    border: theme.palette.border.primary,
    borderTopWidth: 1
  },
  menuActionContainer: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: theme.palette.border.primary,
    borderTopWidth: 1
  },
  subMenuIcon: {
    marginRight: 8
  },
  menuIcon: {
    color: theme.palette.secondary.main,
  },
  kbIcon: {
    color: '#90A0B7'
  },
  avatar: {
    cursor: 'pointer',
    width: 28,
    height: 28,
    borderRadius: '50%',
    marginRight: 8
  },
  profileBtn: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileBtnRoot: {
    maxWidth: '120px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis'
  },
  btnRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8
  },
  expandMoreIcon: {
    color: '#818E9B'
  },
  companyName: {
    fontSize: 10,
    color: theme.palette.text.primary,
    lineHeight: '10px',
  },
  userInfoMenuitem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 16px',
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  menuName: {
    fontSize: 14,
    fontWeight: '500',
    color: theme.palette.text.primary,
    paddingLeft: 16
  },
  menuEmail: {
    fontSize: 10,
    color: theme.palette.text.header,
    paddingLeft: 16,
    textDecoration: 'none'
  },
  menuActionContainerTop: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  userAccount: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  menuCompanyContainer: {
    fontSize: 14,
    minWidth: 300,
    padding: '24px 16px',
    border: theme.palette.border.primary,
    borderTopWidth: 1,
    backgroundColor: '#d3efff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  moreIcon: {
    cursor: 'pointer',
    color: '#334d6f',
    marginRight: 2
  },
  companyItems: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '260px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis'
  },
  menuCompanyName: {
    fontSize: 14,
    color: theme.palette.text.primary,
    fontWeight: '500'
  },
  menuCompany: {
    fontSize: 12,
    color: theme.palette.text.primary,
    fontWeight: '500'
  },
  officeMenuPadding: {
    padding: '0px',
  },
  paper: {
    marginLeft: '-2px',
  },
  logoutMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px 0px',
    border: theme.palette.border.primary,
    borderTopWidth: 1,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  menuLogout: {
    fontSize: 14,
    color: theme.palette.text.primary,
    cursor: 'pointer'
  },
  version: {
    fontSize: 10,
    color: theme.palette.text.primary,
  },
  subscriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 16,
    marginRight: 8
  },
  subscriptionName: {
    fontSize: 10,
    fontWeight: 500,
    padding: 0,
    margin: '0px 8px',
    border: 0,
    marginLeft: 0,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  subStatValue: {
    fontSize: 10,
    fontWeight: 500
  },
  subsIcon: {
    width: 14,
    height: 14,
    color: '#90A0B7',
    marginRight: 8
  },
  newUIIcon: {
    color: '#fcd200',
  },
  notificationIcon: {
    color: '#90A0B7',
  },
};

export default styles;
