const styles = {
  fieldContainer: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inputLabel: {
    color: '#4C4E64DE',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '32px',
    letter: '-0.25px'
  },
  inputField: {
    marginTop: '8px',
    width: '70%',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: '2px solid #C2CFE099',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C2CFE099',
      }
    },
    '& .MuiInputBase-root': {
      color: '#4C4E64 !important',
      fontSize: '13px !important',
      fontWeight: '400',
    },
    '& .MuiFormHelperText-root': {
      color: '#4C4E6499',
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '14px',
    }
  },
  dataFormatBtn: {
    fontSize: '13px',
    fontWeight: '400',
    marginTop: '8px',
    color: '#4C4E64',
    textTransform: 'none' as const,
    height: '36px',
    width: '70%',
    justifyContent: 'space-between',
    border: '2px #C2CFE099 solid',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    '&:hover': {
      border: '1px #C2CFE099 solid',
      backgroundColor: '#FFFFFF',
      boxShadow: 'none'
    }
  },
  exportOption: {
    color: '#4C4E64',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
};

export default styles;
