import theme from 'src/theme';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    zIndex: 9999999
  },
  paperModal: {
    maxWidth: 500,
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
  },
  modalBody: {
    minWidth: 360,
    maxWidth: 500,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },
  header: {
    marginBottom: 16,
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 500
  },
  noPadding: {
    padding: '0px 16px',
    color: theme.palette.text.primary,
    fontSize: 13
  },
  closeBtn: {
    padding: '8px!important',
  },
  fieldStyle: {
    paddingBottom: 24
  },
  applyToAllContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 16
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 16
  },
  reqButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16
  },
  cancelBtn: {
    color: theme.palette.text.primary,
    border: theme.palette.border.cancel,
    textTransform: 'none',
    marginLeft: 24
  },
  checkBoxLabel: {
    fontSize: '14px',
    color: theme.palette.text.primary
  },
  checkBox: {
    color: theme.palette.secondary.main,
    paddingBottom: 0,
    paddingTop: 0
  },
  checkboxContainer: {
    marginRight: '8px',
    marginBottom: '8px'
  },
  requestBtn: {
    color: theme.palette.text.primary,
    border: theme.palette.border.cancel,
    textTransform: 'none',
    marginRight: 16
  },
  description: {
    fontSize: 13,
    color: theme.palette.text.primary,
    marginBottom: 16
  },
  fieldsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 240,
    overflow: 'scroll'
  },
  sendBtn: {
    textTransform: 'none',
    backgroundColor: theme.palette.button.action,
  }
};

export default styles;
