const styles = {
  root:{
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '400px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    flexShrink: '0',
    position: 'relative',
    zIndex:2,
  },
  head:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 15px'
  },
  headTitle: {
    color: '#4C4E64',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  aiBtn:{
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    color: '#4C4E64',
    border: '1px solid #4C4E6433',
    fontSize: '14px',
    gap: '5px',
    padding: '7px 22px',
    height: '38px',
    textTransform: 'none',
  },
  switchHead:{
    width: '100%',
    borderBlock: '1px solid #4C4E6433',
    display: 'flex',
    '& > button':{
      flex: '1',
    }
  },
  switchBtn:{
    paddingBlock: '15px',
    borderBlockEnd: '3px solid transparent',
    borderRadius: '0',
    fontSize: '14px',
    color: '#4C4E64DE',
    gap: '8px',
    textTransform: 'none',
    '&.active':{
      borderBlockEndColor: '#3E8AFF',
    }
  },
  content:{
    height: 'calc(100% - 67.5px - 54.5px - 61px )',
    maxHeight: 'calc(100% - 67.5px - 54.5px - 61px )',
    overflow: 'clip auto',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& > div:first-of-type':{
      width: '100%',
      height: 'calc(100% - 69px)',
      overflow: 'clip auto', 
    }
  },
  bottom:{
    padding: '15px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBlockStart: '1px solid #4C4E6433',
  },
  cancelBtn:{
    color: '#4C4E64DE',
    fontSize: '14px',
    textTransform: 'none',
    height: '38px',
    padding: '7px 22px 7px 0',
  },
  confirmBtn:{
    backgroundColor: '#3E8AFF',
    color: '#fff',
    alignItems: 'center',
    gap: '5px',
    fontSize: '14px',
    fontWeight: '500',
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    textTransform: 'none',
    height: '38px',
    padding: '7px 22px',
    '&:hover':{ backgroundColor: '#3E8AFF' }
  },
  confirmIcon: {
    color: '#fff',
    height: '20px',
    width: '16px',
  },
  fRangeWrapper:{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    padding: '10px 20px',
    '& > label':{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
      fontSize: '13px',
    },
    '& > label > span':{
      flex:1,
      color: '#4C4E64DE',
      fontSize: '13px',
      fontWeight: '400'
    },
    '& > label > input':{
      outline: 'none',
      border: '2px solid #C2CFE099',
      borderRadius: '4px',
      padding: '5px',
      width: '100%',
      textAlign: 'right' as const,
      height: '34px',
      flex:1,
      '&:active, &:focus':{
        outline: 'none',
      }
    }
  },
  rangeWrapper:{
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'clip auto',
    padding: '10px 20px',
    gap: '5px'
  },
  aiNotice:{
    padding: '12px 10px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 4px 8px -2px #4C4E646B',
    gap: '10px',
    margin: '10px 0 15px 0',
    '& > p':{
      fontSize: '14px',
      color: '#4C4E64DE',
      lineHeight: '1.1'
    },
    '& > span':{
      color: 'green',
      display: 'flex',
      alignItems: 'center'
    }
  },
  rangeFoot:{
    width: '100%',
    boxSizing: 'border-box',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px -4px 8px -4px #4C4E6433',
    '& > button':{
      backgroundColor: 'transparent',
      border: '1px solid #4C4E6433',
      color: '#4C4E64',
      paddingInline: '25px',
      fontSize: '14px',
      textTransform: 'none',
      height: '38px',
      borderRadius: '4px',
      padding: '7px 22px',
    }
  },
  eachRange:{
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px'
  },
  rangeLabel:{
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flex: '1'
  },
  rangeLabel2:{
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  rangeSpan:{
    display: 'flex',
    fontSize: '13px',
    alignItems: 'center',
    color: '#4C4E64DE',
  },
  rangeInput:{
    textAlign: 'right' as const,
    maxWidth: '80px',
    width: '70px',
    height: '34px',
    border: '2px solid #C2CFE099',
    padding: '5px',
    borderRadius: '4px',
    flex:1,
    '&:active, &:focus':{
      outline: 'none',
    }
  },
  num:{
    padding: '0',
    fontSize: '13px',
    width: '20px',
    color: '#4C4E64DE'
  },
  cancel:{
    fontSize: '14px',
    padding: '0',
    color: '#4C4E64DE',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  closeIcon: {
    height: '20px',
    width: '20px',
  },
  error:{
    color: 'red',
    fontSize: '10px'
  },
  addRange:{
    color: '#fff',
    backgroundColor: '#3E8AFF',
    fontSize: '13px',
    border: 'none',
    width: 'fit-content',
    height: '30px',
    padding: '4px 13px',
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#3E8AFF',
    }
  }



  
}

export default styles;