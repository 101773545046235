import React, { Component } from "react";

import "../style/Tip.css";

interface State {
  text: string;
  originalValue: string;
}

interface Props {
  fieldLabel: string;
  value: string;
  onConfirm: (_value: string) => void;
  onOpen: () => void;
  onUpdate?: () => void;
  onCancel: () => void;
  handleChangeTipText: (_value: string) => void;
  setTipOriginalValue: (_value: string) => void;
}

export class Tip extends Component<Props, State> {
  state: State = {
    text: this.props.value,
    originalValue: this.props.value,
  };

  componentDidMount(): void {
    this.props.setTipOriginalValue(this.props.value);
    this.props.handleChangeTipText(this.props.value);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState({ text: this.props.value, originalValue: this.props.value });
      this.props.setTipOriginalValue(this.props.value);
      this.props.handleChangeTipText(this.props.value);
    }
  }

  componentWillUnmount(): void {
    this.props.setTipOriginalValue("");
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ text: event.target.value });
    this.props.handleChangeTipText(event.target.value);
  };

  handleOnCancel = () => {
    if (this.state.text !== this.state.originalValue) {
      this.props.handleChangeTipText(this.state.originalValue);
    }
    this.props.onCancel();
  };

  render() {
    const { fieldLabel, onConfirm } = this.props;
    const { text } = this.state;

    return (
      <div id="Tip" className="Tip">
        <div
          className="Tip__card"
        >
          <p>{fieldLabel}</p>
          <textarea
            id="tip__textarea"
            autoFocus
            value={text}
            onChange={this.handleChange}
            ref={(node) => {
              if (node) {
                node.focus();
              }
            }}
          />
          <div>
            <input id="Tip__submit" className="Tip__submit" type="button" value="Save" onClick={() => onConfirm(text)} />
            <input id="Tip__cancel" className="Tip__cancel" type="button" value="Cancel" onClick={this.handleOnCancel} />
          </div>
        </div>
      </div>
    );
  }
}

export default Tip;
