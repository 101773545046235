import { closeSnackbar } from 'notistack';

import { IconButton } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';

const SnackbarCloseButton = ({ snackbarKey }) => {
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose style={styles.icon} />
    </IconButton>
  );
};

export default SnackbarCloseButton;

const styles = {
  icon: {
    fontSize: 20,
    color: '#ffffff'
  }
};
